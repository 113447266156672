import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/esm/Table';
import imgDelete from '../../../../../assets/images/delete.svg';
import { BookShareUser, BookUserRole, User } from '../../../../../dorian-shared/types/user/User';
import { api } from '../../../../api';
import { SelectAsyncPaginate } from '../../../../ui/SelectAsyncPaginate/SelectAsyncPaginate';
import { showToast } from '../../../../ui/utils';
import { DeleteBookShare } from '../../../Stories/Share/DeleteBookShare';
import { getUserDisplayName } from '../../../Users';
import { getBookRoleNameByRole } from '../utils';

export interface UsersWithExclusiveAccessProps {
  bookId: number,
  bookShareUsers: BookShareUser[],
  onAddUser: (shareUser?: BookShareUser) => void;
  onDeleteUser: (shareUser?: BookShareUser) => void;
}

export function UsersWithExclusiveAccess(props: UsersWithExclusiveAccessProps) {
  const {
    bookId, bookShareUsers, onAddUser, onDeleteUser,
  } = props;

  const [selectedNewShareUsers, setSelectedNewShareUsers] = useState<BookShareUser[]>([]);
  const [deleteUser, setDeleteUser] = useState<BookShareUser>();
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const handleSelectUserSearch = async (searchString: string, page = 1) => {
    const params = {
      search: searchString,
      limit: 5,
      offset: 5 * (page - 1),
    };
    const { data } = await api.get('/v1/users', { params });
    return { options: data.users, totalCount: data.totalCount };
  };

  const handleSelectUser = (users: User[]) => {
    const newSharedUsers = users.map((user) => ({
      id: user.id,
      username: user.username,
      fullname: user.fullname,
      firstName: user.firstName,
      lastName: user.lastName,
      book_role: BookUserRole.Exclusive,
      analyticsAccess: false,
    } as BookShareUser
    ));
    setSelectedNewShareUsers(newSharedUsers);
  };

  const handleAddSelectedSharedUser = () => {
    if (selectedNewShareUsers.length === 0) {
      return;
    }
    const selectedUser = selectedNewShareUsers[0];
    const isShareUserExist = bookShareUsers
      .findIndex((bookShareUser) => bookShareUser.id === selectedUser.id) !== -1;

    if (isShareUserExist) {
      showToast({ textMessage: 'User already selected' });
      return;
    }
    onAddUser(selectedUser);
  };

  const handleDeleteConfirm = () => {
    setShowDeleteUserModal(false);
    onDeleteUser(deleteUser);
  };

  return (
    <div className="w-100">
      <h5>Users:</h5>

      <Table bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Role</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {bookShareUsers.length > 0
            ? bookShareUsers.map((storyShareUser, index) => (
              <tr key={storyShareUser.id}>
                <td>{index}</td>
                <td>{getUserDisplayName(storyShareUser)}</td>
                <td>{getBookRoleNameByRole(storyShareUser.book_role)}</td>
                <td className="text-right">
                  <Button
                    variant="secondary"
                    size="sm"
                    className="mx-1 my-0"
                    onClick={() => {
                      setDeleteUser(storyShareUser);
                      setShowDeleteUserModal(true);
                    }}
                  >
                    <img src={imgDelete} className="btnImg" alt="Delete" />
                  </Button>
                </td>
              </tr>
            ))
            : (
              <tr>
                <td colSpan={4} className="text-center">
                  No users with exclusive access
                </td>
              </tr>
            )}
        </tbody>
      </Table>
      <Row className="d-flex justify-content-center">
        <Col md={6} className="search-user-area ">
          <SelectAsyncPaginate
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            placeholder="Find user by name or email..."
            showPerPage={5}
            labelKey={(option: User) => `${option.fullname}`}
            onSearch={handleSelectUserSearch}
            onSelect={handleSelectUser}
          />
          <Button
            disabled={selectedNewShareUsers.length === 0}
            className="search-user-btn"
            size="sm"
            variant="secondary"
            onClick={handleAddSelectedSharedUser}
          >
            Add User
          </Button>
        </Col>
      </Row>
      <Row>
        <i>* Access can be granted to more users later in the Collaborators list</i>
      </Row>
      {showDeleteUserModal && (
        <DeleteBookShare
          show={showDeleteUserModal}
          onConfirm={handleDeleteConfirm}
          onCancel={() => setShowDeleteUserModal(false)}
          onError={(error: string) => {
            showToast({ textMessage: error });
            setShowDeleteUserModal(false);
          }}
          bookId={bookId}
          user={deleteUser}
        />
      )}
    </div>
  );
}
