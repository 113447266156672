import axios from 'axios';
import { logger } from '../../services/loggerService/loggerService';
import { baseURL } from '../api';

let assetsImageURL;

const requestAssetsImageURL = () => {
  axios.get(`${baseURL}v1/avatar/config`).then(
    (response) => {
      assetsImageURL = response.data.imageUrl;
    },
  ).catch((error) => {
    logger.error(error);
  });
};

export const getAssetsImageURL = () => assetsImageURL;

requestAssetsImageURL();
