import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

interface CompetitiveModeSettingsProps {
    competitiveMode: boolean;
    onChangeCompetitiveMode: (mode: boolean) => void;
    streamingByNonCreators: boolean;
    onChangeStreamingByNonCreators: (mode: boolean) => void;
    disabled?: boolean;
    disabledReason?: string;
}

export function CompetitiveModeSettings(props: CompetitiveModeSettingsProps) {
  const {
    competitiveMode,
    onChangeCompetitiveMode,
    streamingByNonCreators,
    onChangeStreamingByNonCreators,
    disabled,
    disabledReason,
  } = props;

  return (
    <>
      <InputGroup className="mt-2 mb-0 d-block">
        <Form.Check
          disabled={disabled}
          type="checkbox"
          label="Tournament game"
          checked={competitiveMode}
          onChange={(event) => onChangeCompetitiveMode(event.target.checked)}
          name="competitiveMode"
        />
        {disabled && disabledReason && (
        <div className="ml-0 text-danger">
          {disabledReason}
        </div>
        )}
        <div className="ml-0 font-italic font-weight-lighter">
          Tournament Games have right and wrong answers and can only be played in streams.
        </div>
      </InputGroup>
      {competitiveMode && (
      <InputGroup className="mt-0 mb-2 pl-4">
        <Form.Check
          type="checkbox"
          label="Allow streaming by non-creators"
          checked={streamingByNonCreators}
          onChange={(event) => onChangeStreamingByNonCreators(event.target.checked)}
          name="competitiveModeStreamingByNonCreators"
        />
      </InputGroup>
      )}
    </>
  );
}
