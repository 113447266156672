import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { AvatarToolTr } from './AvatarToolTr';

export class AvatarToolTable extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      view, properties, tabs, tab, avatar,
    } = this.props;

    if (properties.length > 0) {
      const itemsTab = tabs.find((e) => e.id === tab);
      if (itemsTab.items.length === 1 || view) {
        return null;
      }
      return (
        <Card className="view" id="main-view">
          <div className="categories p-2" id="categories-view">
            {
              properties.map((obj, i) => {
                if (!obj.values || !obj.values[avatar.type]) {
                  return null;
                }
                const id = avatar[obj.id];
                const itemsTabId = itemsTab.items.find((e) => e.id === obj.id);
                if (!itemsTabId) {
                  return null;
                }
                const item = obj.values[avatar.type].find((e) => e.id === id);
                const label = !item || !item.label ? '(none)' : item.label;
                return (
                  <AvatarToolTr
                    {...this.props}
                    id={id}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    title={obj.title}
                    label={label}
                    obj={obj}
                  />
                );
              })
            }
          </div>
        </Card>
      );
    }

    return null;
  }
}
