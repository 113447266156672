import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
// eslint-disable-next-line import/no-cycle
import { getUserName } from '.';

export class Deactivate extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      error: null,
    };
  }

  deactivateAction() {
    const { obj, onHide } = this.props;
    this.setState({ showLoading: true });
    const url = obj && obj.id ? `/v1/users/${obj.id}/deactivate` : '/v1/user/deactivate';
    api.post(url)
      .then(() => onHide(true))
      .catch((error) => this.setState({
        showLoading: false,
        error: error.response.data.error,
      }));
  }

  render() {
    const { obj, onHide } = this.props;
    const { error, showLoading } = this.state;

    const title = obj && obj.id ? `Deactivate user ${getUserName(obj)}` : 'Deactivate account';
    const body = obj && obj.id ? 'Do you really want to deactivate this account?' : 'Do you really want to deactivate account? The account can only be re-activated by contacting administrators and will take time.';

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error
            ? (
              <Alert variant="danger">
                {error}
              </Alert>
            )
            : (
              <p className="text-center">
                {body}
              </p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            disabled={(error !== null)}
            type="submit"
            variant="primary"
            onClick={() => this.deactivateAction()}
          >
            {showLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Deactivate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
