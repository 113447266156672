import { useFormikContext } from 'formik';
import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { usePerformanceRanksContext } from '../../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import { usePerformanceRanksModalContext } from '../context/PerformanceRanksModalContext';

export function PerformanceRanksModalFooter() {
  const [showExitConfirmation, setShowExitConfirmation] = React.useState(false);

  const { isLoading } = usePerformanceRanksContext();
  const {
    onHide, haveValuesChanged, isFormLoading, disabled,
  } = usePerformanceRanksModalContext();

  const {
    isSubmitting, handleSubmit, values, dirty, isValid,
  } = useFormikContext<IPerformanceRankDTO[]>();

  const handleCloseButtonClick = () => {
    const hasChanged = haveValuesChanged(values) || dirty;

    if (hasChanged) {
      setShowExitConfirmation(true);
    } else {
      onHide();
    }
  };

  return (
    <>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="button"
          onClick={handleCloseButtonClick}
          disabled={isSubmitting || isFormLoading}
        >

          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={() => handleSubmit()}
          disabled={isSubmitting || isFormLoading || isLoading || !isValid || disabled}
        >
          {(isSubmitting || isLoading || isFormLoading) && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          )}
          Save
        </Button>
      </Modal.Footer>
      <ConfirmModal
        centered
        show={showExitConfirmation}
        isLoading={false}
        title="Exit Confirmation"
        onConfirm={onHide}
        onCancel={() => setShowExitConfirmation(false)}
        className="overlay"
        backdrop="static"
      >
        <div className="text-center">
          Are you sure you want to exit without saving?
        </div>
      </ConfirmModal>
    </>
  );
}
