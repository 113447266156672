import React from 'react';
import { Modal } from 'react-bootstrap';
import { BookUser } from '../../../../dorian-shared/types/book/book';
import { BookUserRole } from '../../../../dorian-shared/types/user/User';
import classes from './MemoryBank.module.scss';
import { MemoryBankForm } from './MemoryBankForm';

type MemoryBankModalProps = {
  bookId: number,
  bookUsers: BookUser[],
  onHide: () => void,
}

export function MemoryBankModal(props: MemoryBankModalProps) {
  const {
    onHide,
    bookId,
    bookUsers,
  } = props;

  const userId = bookUsers?.find((user) => user.book_role === BookUserRole.Owner)?.id ?? 0;

  return (
    <Modal
      show
      onHide={onHide}
      size="xl"
      backdrop="static"
      keyboard={false}
      scrollable
      dialogClassName={classes.memoryBankModalDialog}
    >
      <MemoryBankForm
        onHide={onHide}
        bookId={bookId}
        userId={userId}
      />
    </Modal>
  );
}
