import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';
import styles from '../Characters/Characters.scss';

const cs = classNames.bind(styles);

export class LocationsDelete extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
    };
  }

  deleteLocationsAction() {
    const { onHide, obj, args } = this.props;

    this.setState({ showLoading: true });
    onHide();

    api.delete(`/v1/books/${args.bookId}/locations/${obj.id}`)
      .then(() => {
        onHide();
      });
  }

  render() {
    const { onHide } = this.props;

    const { showLoading } = this.state;
    return (
      <Modal
        className={cs('deleteModal')}
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to delete this Location?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteLocationsAction()}
          >
            {showLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
