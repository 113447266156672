import React, { useMemo } from 'react';
import { BadgeWithTooltip } from '../../../ui/AvatarTool/BadgeWithTooltip/BadgeWithTooltip';
import { EpisodeTextImportCommandKey } from '../types/episodeTextImportCommandTypes';
import { EpisodeTextImportTableRow } from '../types/episodeTextImportTableRow';
import { EpisodeTextColumnKey, EpisodeTextEntityType } from '../types/episodeTextImportTypes';

const nodeBackgroundColor = '#dddddd';
const errorBackgroundColor = '#ffcccc';

const commandColor = '#02780f';
const choiceColor = '#0028c3';
const answerColor = '#004a78';

function ErrorBadge(props: { error: string }) {
  const { error } = props;
  return (
    <div>
      {error && (
      <div className="position-absolute" style={{ top: '-0.5rem', right: '-0.5rem' }}>
        <BadgeWithTooltip
          id="locked-memory-badge"
          title="Validation error"
          content={error}
          hideDelay={2000}
        />
      </div>
      )}
    </div>
  );
}

function getStyle(columnKey?: EpisodeTextColumnKey) {
  return (cell: string, row: EpisodeTextImportTableRow) => {
    const error = row.errors?.find((rowError: string) => rowError.startsWith(`[${columnKey}`));
    if (error) {
      return { backgroundColor: errorBackgroundColor };
    }

    switch (row.type) {
      case EpisodeTextEntityType.Node: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Location)) {
              return { backgroundColor: nodeBackgroundColor, color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        return { backgroundColor: nodeBackgroundColor, fontWeight: 'bold' };
      }
      case EpisodeTextEntityType.GotoFromNode: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: commandColor, fontWeight: 'bold' };
          default:
            break;
        }
        break;
      }
      case EpisodeTextEntityType.Choice:
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: choiceColor, fontWeight: 'bold' };
          default:
            break;
        }
        break;
      case EpisodeTextEntityType.Answer: {
        switch (columnKey) {
          case EpisodeTextColumnKey.Text:
            return { color: answerColor, fontWeight: 'bold', fontStyle: 'italic' };
          case EpisodeTextColumnKey.Description:
            if (row.commands?.get(EpisodeTextImportCommandKey.Goto)) {
              return { color: commandColor, fontWeight: 'bold' };
            }
            break;
          default:
            break;
        }
        break;
      }
      default:
        break;
    }

    if (columnKey) {
      const commandValues = [...row.commands.values()];
      const isCommand = commandValues.some((command) => command.has(columnKey));
      if (isCommand) {
        return { color: commandColor, fontWeight: 'bold' };
      }
    }

    return {};
  };
}

function getFormatter(columnKey: EpisodeTextColumnKey = EpisodeTextColumnKey.NodeOrSpeaker) {
  return function (cell: string, row: EpisodeTextImportTableRow) {
    const { errors } = row;
    const errorMessage = errors.find((rowError: string) => rowError.startsWith(`[${columnKey}`));
    if (errorMessage) {
      return (
        <div className="d-flex position-relative">
          <ErrorBadge error={errorMessage} />
          {cell}
        </div>
      );
    }
    return cell;
  };
}

export const useImportTextColumns = () => useMemo(() => [
  {
    dataField: 'type',
    text: 'Type',
    headerStyle: { width: '8%' },
    style: getStyle(),
  },
  {
    dataField: 'nodeOrSpeaker',
    text: 'Node/Speaker',
    headerStyle: { width: '12%' },
    style: getStyle(EpisodeTextColumnKey.NodeOrSpeaker),
    formatter: getFormatter(EpisodeTextColumnKey.NodeOrSpeaker),
  },
  {
    dataField: 'text',
    text: 'Text',
    style: getStyle(EpisodeTextColumnKey.Text),
    formatter: getFormatter(EpisodeTextColumnKey.Text),
  },
  {
    dataField: 'description',
    text: 'Description',
    headerStyle: { width: '20%' },
    style: getStyle(EpisodeTextColumnKey.Description),
    formatter: getFormatter(EpisodeTextColumnKey.Description),
  },
], []);
