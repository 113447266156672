import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';

export class Payout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: null,
    };

    this.page = {
      header: {
        title: 'Payout',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: 'Back to profile',
            href: '/profile',
            action: null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    api.get('/v1/payout/key')
      .then((res) => {
        this.setState({
          key: res.data.key,
        });
      });
  };

  render() {
    const { key } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card>
          <Card.Body className="h-100">
            <div id="tipalti-iframe" height="1500" style={{ height: '1500px' }}>
              <a href={key}>TIPALTI</a>
            </div>
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
