import React, { Component } from 'react';
import { Card, Modal, Spinner } from 'react-bootstrap';
import Moment from 'react-moment';
import { api } from '../../api';
import { AddComment } from '../Article/addComment';
import { getUserDisplayName } from '../Users';

export class ChapterCommentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    const { obj } = this.props;
    api.get(`v1/stories/${obj.id}/comments`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.comments,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { obj: obj1 } = this.props;
    const {
      loading,
      data,
    } = this.state;

    return (
      <Modal
        {...this.props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Comments for
            {' '}
            {obj1.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="commentsList">
            {
              data.length > 0
                ? data.map((obj, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Card key={i} className="my-3">
                    <Card.Title className="px-3 pt-3">
                      <b>{getUserDisplayName(obj.user)}</b>
                      {' '}
                      commented on
                      <Moment
                        element="span"
                        format="YYYY-MM-DD hh:mm A"
                        date={obj.createdAt}
                      />
                    </Card.Title>
                    <Card.Body className="py-1">
                      <p>{obj.message}</p>
                    </Card.Body>
                  </Card>
                ))
                : null
            }

            {
              loading
                ? (
                  <div className="boxLoading">
                    <div className="boxSpinner"><Spinner animation="border" variant="primary" /></div>
                  </div>
                )
                : (
                  <AddComment
                    type="stories"
                    update={this.loadData}
                    {...this.props}
                  />
                )
            }
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
