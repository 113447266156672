import React from 'react';
import { OptionStatsItem } from './OptionStatsItem';

export function OptionStatsList(props) {
  const { types, stats } = props;

  return types.map((row, i) => {
    if (!row.trait) {
      return null;
    }
    return (
      <OptionStatsItem
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        index={i}
        row={row}
        stats={stats}
      />
    );
  });
}
