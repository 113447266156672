import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { api } from '../../api';
import { AvatarHelper } from './AvatarHelper';

export class AvatarCanvas extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
    };
    this.helper = new AvatarHelper();
    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      properties,
      updateLoading,
    } = this.props;
    const {
      loading,
    } = this.state;

    if (JSON.stringify(prevProps.properties) !== JSON.stringify(properties)) {
      this.updateData();
    }
    if (loading !== prevState.loading) {
      if (updateLoading) {
        updateLoading(loading);
      }
    }
  }

  bodyForced = (body) => {
    const { properties: prop } = this.props;
    const bodyLayers = [];
    Object.entries(prop).forEach((obj) => {
      if (obj[0] !== 'type') {
        body.forEach((a) => {
          if (a[0].includes(obj[1])) {
            bodyLayers.push(a);
          }
        });
      }
    });
    // eslint-disable-next-line no-param-reassign
    body = body.filter((item) => bodyLayers.includes(item));
    return body;
  };

  imgObj = () => {
    const { loading } = this.state;
    if (loading) {
      return undefined;
    }

    const { params, update } = this.props;
    const img = new Image(params.avatarWidth, params.avatarHeight);
    img.setAttribute('crossorigin', 'anonymous');
    const canvas = this.canvas.current;
    img.src = canvas.toDataURL('image/png');
    update(img.src);
    return (
      <img
        src={img.src}
        alt=""
      />
    );
  };

  draw(res) {
    const canvas = this.canvas.current;
    this.helper.draw(canvas, res, () => this.setState({
      loading: false,
    }));
  }

  updateData() {
    const { properties, forced } = this.props;
    const data = { properties };
    this.setState({
      loading: true,
    });
    api.post('/v1/avatar/prepare', data)
      .then((res) => {
        this.setState({
          data: res.data,
        }, () => {
          const { data: stateData } = this.state;
          if (forced) {
            stateData.bodyLayers = this.bodyForced(stateData.bodyLayers);
            stateData.faceLayers = [];
          }
          this.draw(stateData);
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { loading } = this.state;
    return (
      <>
        <canvas id="avatar" className="avatarCanvas" ref={this.canvas} />
        {this.imgObj()}
        <div className="text-center">
          <Spinner
            variant="primary"
            animation="border"
            className={`loadingSpinner justify-content-center ${!loading ? 'd-none' : ''}`}
          />
        </div>
      </>
    );
  }
}
