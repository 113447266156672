import React from 'react';
import { Modal } from 'react-bootstrap';
import { LocationsForm } from '../../_shared/LocationsForm/LocationsForm';

export function AddLocationModal({
  bookId,
  show,
  limits,
  onHide,
  onSuccess,
  isFirstLocationInStory,
  storyLocationToEdit,
}) {
  const handleAddLocationSuccess = (newLocation) => {
    onHide();
    onSuccess(newLocation);
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      className="deleteModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Locations</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LocationsForm
          onSuccess={handleAddLocationSuccess}
          onStopEdit={onHide}
          bookId={bookId}
          limits={limits}
          isFirstLocationInStory={isFirstLocationInStory}
          storyLocationToEdit={storyLocationToEdit}
        />
      </Modal.Body>
    </Modal>
  );
}
