// eslint-disable-next-line max-classes-per-file
import moment from 'moment';
import React, { Component } from 'react';
import Moment from 'react-moment';

// eslint-disable-next-line react/prefer-stateless-function
export class FormatDateCalendarStrings extends Component {
  render() {
    const calendarStrings = {
      lastDay: '[Yesterday at] LT',
      sameDay: '[Today at] LT',
      nextDay: '[Tomorrow at] LT',
      lastWeek: '[Last] dddd',
      nextWeek: '[Next] dddd',
      sameElse: 'L',
    };

    const { date } = this.props;
    if (moment().diff(date, 'days') >= 2) {
      return (
        <Moment className="d-inline-block" fromNow>
          {date}
        </Moment>
      );
    }
    return (
      <Moment className="d-inline-block" calendar={calendarStrings}>
        {date}
      </Moment>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export class FormatDateBox extends Component {
  render() {
    const {
      date,
      formatDate,
    } = this.props;

    if (!formatDate) {
      return (
        <FormatDateCalendarStrings
          date={date}
        />
      );
    }

    return (
      <Moment
        className="d-inline-block"
        format="YYYY-MM-DD hh:mm A"
      >
        {date}
      </Moment>
    );
  }
}

export class FormatDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formatDate: localStorage.getItem('formatChaptersDate') !== null ? JSON.parse(localStorage.getItem('formatChaptersDate')) : false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.updateStorage, { passive: false });
    window.addEventListener('storage', this.updateStorage, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.updateStorage);
    window.removeEventListener('storage', this.updateStorage);
  }

  updateStorage = () => {
    this.setState({
      formatDate: localStorage.getItem('formatChaptersDate') !== null ? JSON.parse(localStorage.getItem('formatChaptersDate')) : false,
    });
  };

  formatStoriesDate = () => {
    const { formatDate } = this.state;
    const format = !formatDate;
    localStorage.setItem('formatChaptersDate', format);
  };

  render() {
    const {
      date,
      className,
    } = this.props;
    const { formatDate } = this.state;
    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        className={className}
        onClick={(e) => this.formatStoriesDate(e)}
      >
        <FormatDateBox
          date={date}
          formatDate={formatDate}
        />
      </div>
    );
  }
}
