import React, { Component } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddTag } from './AddTag';
import './Tag.css';
import { TagTableRow } from './TagTableRow';

export class BackgroundTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      // eslint-disable-next-line react/no-unused-state
      alertText: null,
      loading: true,
      // eslint-disable-next-line react/no-unused-state
      validated: false,
      addTag: false,
      // eslint-disable-next-line react/no-unused-state
      pageTitle: 'Background Tags',
    };

    this.page = {
      header: {
        title: 'Background Tags',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            // eslint-disable-next-line react/destructuring-assignment
            title: this.props.history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            // eslint-disable-next-line react/destructuring-assignment
            href: this.props.history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            // eslint-disable-next-line react/destructuring-assignment
            action: this.props.history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Tag',
            action: () => this.initAddTag(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/backgroundtags')
      .then((res) => {
        this.setState({
          loading: false,
          addTag: false,
          tags: res.data.tags,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  initAddTag = () => {
    this.setState({
      addTag: true,
    });
  };

  cancelAddTag = () => {
    this.setState({
      addTag: false,
    });
  };

  tagTableRow() {
    const update = () => {
      this.loadData();
    };
    // eslint-disable-next-line react/destructuring-assignment,react/no-array-index-key
    return this.state.tags.map((object, i) => <TagTableRow obj={object} key={i} update={update} />);
  }

  render() {
    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card>
            <Card.Body>
              <Card.Title>List of Tags</Card.Title>

              {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <div className="storytags-content">
                      {this.tagTableRow()}
                    </div>
                  )
}

            </Card.Body>
          </Card>
        </PageWrapper>

        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.addTag
            ? (
              <AddTag
                /* eslint-disable-next-line react/destructuring-assignment */
                show={this.state.addTag}
                onHide={this.cancelAddTag}
                update={this.loadData}
                data={null}
              />
            )
            : null
        }
      </>
    );
  }
}
