import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class UnDeleteChapter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }

  deleteAction() {
    this.setState({ loading: true });
    const {
      match,
      obj,
      update,
      onHide,
    } = this.props;

    api.put(`/v1/books/${match.params.id}/chapters/${obj.id}`, { deleted: false })
      .then(() => {
        update();
        onHide();
      })
      .catch(() => {
        update();
      });
  }

  render() {
    const {
      obj,
      onHide,
      disabled,
      update,
      staticContext,
      ...props
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Un-Delete Episode:
            <span>
              &quot;
              {obj.title}
              &quot;
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to un-delete the Episode?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
            disabled={disabled}
          >
            {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Un-Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
