import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';

export class AvatarAssetsNewValue extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addValue(value, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  addValue = (value) => {
    this.setState({
      loading: true,
    });
    api.post('/v1/avatar/config/values', value)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
          // eslint-disable-next-line react/destructuring-assignment
          this.props.onHide();
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { validated } = this.state;

    return (
      <Modal
        size="lg"
        // eslint-disable-next-line react/destructuring-assignment
        show={this.props.show}
        // eslint-disable-next-line react/destructuring-assignment
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
        centered
        backdrop="static"
        keyboard={false}
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Add New Value
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            {
                // eslint-disable-next-line react/destructuring-assignment
                (this.props.property && this.props.property.id)
                // eslint-disable-next-line react/destructuring-assignment
                && <input type="hidden" value={this.props.property.id} name="propertyId" />
              }

            {
                // eslint-disable-next-line react/destructuring-assignment
                (this.props.type && this.props.type.id)
                // eslint-disable-next-line react/destructuring-assignment
                && <input type="hidden" value={this.props.type.id} name="typeId" />
              }

            <Form.Group controlId="Value_Ident">
              <Form.Label>Identifier</Form.Label>
              <Form.Control
                required
                type="text"
                name="ident"
                placeholder="Identifier"
              />
              <Form.Control.Feedback type="invalid">
                Identifier is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="Value_Label">
              <Form.Label>Label</Form.Label>
              <Form.Control
                required
                type="text"
                name="label"
                placeholder="Label"
              />
              <Form.Control.Feedback type="invalid">
                Label is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>

            <>
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                className="mx-2"
                // eslint-disable-next-line react/destructuring-assignment
                disabled={this.state.loading}
                onClick={() => {
                  // eslint-disable-next-line react/destructuring-assignment
                  this.props.onHide();
                }}
              >
                Cancel
              </Button>

              <Button
                // eslint-disable-next-line react/destructuring-assignment
                disabled={this.state.loading}
                variant="primary"
                size="sm"
                type="submit"
              >
                { /* eslint-disable-next-line react/destructuring-assignment */}
                {this.state.loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                Save
              </Button>
            </>
          </Modal.Footer>

        </Form>
      </Modal>
    );
  }
}
