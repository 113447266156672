import cs from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import playPreviewPromoImage from '../../../../assets/images/characters/charactersGroup01.png';
import classNames from './PlayPreviewPromo.module.scss';

interface PlayPreviewPromoProps {
    className?: string;
    imageUri?: string;
    onReplayClick?: () => void;
    onPromoClick?: () => void;
}

export function PlayPreviewPromo(props: PlayPreviewPromoProps) {
  const {
    className,
    imageUri,
    onReplayClick,
    onPromoClick,
  } = props;

  return (
    <div className={cs(classNames.container, className)}>
      <div className={classNames.headerContainer}>
        <div className={classNames.headerTitle}>
          What happens next?
        </div>
        <div className={classNames.headerSubTitle}>
          Download the Dorian app to continue reading free!
        </div>
      </div>
      <div className={classNames.buttonContainer}>
        <Button className={classNames.button} onClick={onPromoClick}>
          Read Free on Dorian
        </Button>
        {onReplayClick && (
          <div className={classNames.buttonSubText}>
            <button
              type="button"
              onClick={onReplayClick}
            >
              Replay episode
            </button>
          </div>
        )}
      </div>
      <div className={classNames.contentContainer}>
        <ul>
          <li>Thousands of free games!</li>
          <li>Interact directly with creators!</li>
          <li>Watch cosplay game streams!</li>
        </ul>
      </div>
      <div className={classNames.imageContainer}>
        <img
          className={classNames.image}
          src={imageUri ?? playPreviewPromoImage}
          alt="Play Preview Promo"
        />
      </div>
    </div>
  );
}
