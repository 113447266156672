import { useState } from 'react';

export function useToggle(initialValue = false) {
  const [isOn, setIsOn] = useState(initialValue);
  const switchOn = () => setIsOn(true);
  const switchOff = () => setIsOn(false);
  const toggle = () => setIsOn((prevValue) => !prevValue);
  return [
    isOn,
    switchOn,
    switchOff,
    toggle,
  ] as const;
}
