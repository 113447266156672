import React from 'react';
import { VerifiedEmailForm } from '../../../uiV2/VerifiedEmailForm/VerifiedEmailForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function VerifiedEmail() {
  return (
    <LoginLayout>
      <VerifiedEmailForm />
    </LoginLayout>
  );
}
