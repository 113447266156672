import React from 'react';
import { Button } from 'react-bootstrap';
import { DeleteStepModal } from './Steps/DeleteStep/DeleteStepModal';

export class AddStep extends React.PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      deleteStepModal: false,
    };
  }

  render() {
    const {
      updateDeleteStep,
      SelectStepsCopy,
      deleteStep,
      handleAddSteps,
      handleDelete,
      SelectStepsDelete,
      restrictedEdit,
      addStep,
      deleteStepFunc,
      copyStep,
      pasteStep,
      steps,
      updateCopyStep,
      choice,
      isCheckStep,
    } = this.props;

    if (choice || restrictedEdit || isCheckStep) {
      return null;
    }
    const getStepData = copyStep;
    const { deleteStepModal } = this.state;

    const handleSelectStepsCopyClick = () => {
      const stepsToCopy = copyStep;

      const firstStepToCopyIndex = steps.findIndex(
        (step) => stepsToCopy.some((stepToCopy) => stepToCopy.id === step.id),
      );
      const selectedStepsToCopy = steps.slice(firstStepToCopyIndex);

      localStorage.setItem('copyStep', JSON.stringify(selectedStepsToCopy));
      updateCopyStep(selectedStepsToCopy);
    };

    return (
      <div className="d-block">
        <Button
          size="sm"
          variant="secondary"
          onClick={() => addStep()}
        >
          + Add Step
        </Button>
        {(!!getStepData && getStepData.length > 0) && (
          <>
            <Button
              size="sm"
              variant="secondary"
              className="ml-2"
              onClick={pasteStep}
            >
              Paste Step(s)
            </Button>
            {SelectStepsCopy && (
              <Button
                size="sm"
                variant="secondary"
                className="ml-2"
                onClick={handleSelectStepsCopyClick}
              >
                Select following
              </Button>
            )}
          </>
        )}
        {(!!steps && steps.length > 0) && (
          <Button
            size="sm"
            variant={SelectStepsCopy ? 'primary' : 'secondary'}
            className="ml-2"
            onClick={() => {
              handleAddSteps();
            }}
          >
            {SelectStepsCopy ? 'Deselect Steps to Copy' : 'Select Steps to Copy'}
          </Button>
        )}
        {(!!deleteStep && deleteStep.length > 0) && (
          <>
            <Button
              size="sm"
              variant="secondary"
              className="ml-2"
              onClick={() => {
                this.setState({
                  deleteStepModal: true,
                });
              }}
            >
              Delete Step(s)
            </Button>
            <Button
              size="sm"
              variant="secondary"
              className="ml-2"
              onClick={() => {
                const val = JSON.parse(JSON.stringify(deleteStep));
                const max = deleteStep.reduce((acc, curr) => (acc > curr ? acc : curr));
                steps.forEach((a, i) => {
                  if (i > Number(max)) {
                    val.push(i);
                  }
                });
                updateDeleteStep(val);
              }}
            >
              Select following
            </Button>
            <DeleteStepModal
              show={deleteStepModal}
              onHide={() => this.setState({
                deleteStepModal: false,
              })}
              onDelete={() => {
                deleteStepFunc(deleteStep);
                this.setState({
                  deleteStepModal: false,
                });
              }}
              title="Delete Step"
              content="Do you really want to delete this Step(s)?"
            />
          </>
        )}
        {(!!steps && steps.length > 0) && (
          <Button
            size="sm"
            variant={SelectStepsDelete ? 'primary' : 'secondary'}
            className="ml-2"
            onClick={() => {
              handleDelete();
            }}
          >
            {SelectStepsDelete
              ? 'Deselect Steps to Delete'
              : 'Select Steps to Delete'}
          </Button>
        )}
      </div>
    );
  }
}
