import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { CommunityGuidelines } from '../Home/PrivacyPolicy/CommunityGuidelines';
import { CopyrightPolicyContent } from '../Home/PrivacyPolicy/CopyrightPolicyContent';
import { PrivacyPolicyContent } from '../Home/PrivacyPolicy/PrivacyPolicyContent';
import { TermsOfUseContent } from '../Home/PrivacyPolicy/TermsOfUseContent';
import { EditDocuments } from './EditDocuments';
import { PageDocuments } from './PageDocuments';

export class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editDocuments: false,
      documents: [],
    };

    const { auth } = this.props;

    this.user = auth.getUser();
    this.staticPages = [
      {
        id: 'terms-of-use',
        title: 'Terms of Use',
        component: <TermsOfUseContent type="documents" />,
      },
      {
        id: 'privacy-policy',
        title: 'Privacy Policy',
        component: <PrivacyPolicyContent type="documents" />,
      },
      {
        id: 'community-guidelines',
        title: 'Community Guidelines',
        component: <CommunityGuidelines type="documents" />,
      },
      {
        id: 'copyright-policy',
        title: 'Copyright Policy',
        component: <CopyrightPolicyContent type="documents" />,
      },
    ];
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    api.get('v1/documents')
      .then((res) => {
        this.setState({
          loading: false,
          documents: res.data.documents,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  editDocuments = () => {
    this.setState({
      editDocuments: true,
    });
  };

  render() {
    const { match } = this.props;

    const pageId = this.props && match?.params?.id
      ? String(match.params.id)
      : 'copyright-policy';

    const pageContent = () => {
      const page = this.staticPages.find((staticPage) => String(staticPage.id) === pageId);
      if (page) {
        return (
          <>
            <h1>{page.title}</h1>
            <div>{page.component}</div>
          </>
        );
      }
      return (
        <PageDocuments
          {...this.props}
          page_id={pageId}
        />
      );
    };

    const page = () => {
      const pageProps = {
        header: {
          title: 'Documents',
          settings: this.user.role === 'admin' ? 'admin' : null,
        },
        sidebar: {
          nav: [
            {
              title: 'Back to Stories List',
              action: '',
              href: '/books',
              variant: 'primary',
            },
            {
              title: 'Copyright Policy',
              href: '/documents/copyright-policy',
              variant: 'secondary',
              disabled: !pageId || pageId === 'copyright-policy',
            },
            {
              title: 'Privacy Policy',
              href: '/documents/privacy-policy',
              variant: 'secondary',
              disabled: pageId === 'privacy-policy',
            },
            {
              title: 'Terms of Use',
              href: '/documents/terms-of-use',
              variant: 'secondary',
              disabled: pageId === 'terms-of-use',
            },
            {
              title: 'Community Guidelines',
              href: '/documents/community-guidelines',
              variant: 'secondary',
              disabled: pageId === 'community-guidelines',
            },
          ],
        },
      };

      const {
        documents,
        loading,
      } = this.state;

      documents.forEach((obj) => {
        pageProps.sidebar.nav.push({
          title: obj.title,
          href: `/documents/${obj.alias}`,
          variant: 'secondary',
          disabled: pageId === obj.alias,
        });
      });

      if (this.user.role === 'admin' && this.user.canEditLegalText) {
        pageProps.sidebar.nav.push(
          {
            variant: 'space',
          },
          {
            title: 'Add New Documents',
            action: () => this.editDocuments(),
            variant: 'primary',
            disabled: loading || this.user.role !== 'admin',
          },
        );
      }
      return pageProps;
    };

    const addDocuments = () => {
      const { editDocuments: editDocumentsState } = this.state;

      if (editDocumentsState) {
        return (
          <EditDocuments
            {...this.props}
            show
            user={this.user}
            onHide={() => {
              this.setState({
                editDocuments: false,
              });
            }}
            update={() => {
              this.setState({
                editDocuments: false,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page()}
        >
          <Card>
            <Card.Body className="pageDocuments">
              {pageContent()}
            </Card.Body>
          </Card>
        </PageWrapper>

        {addDocuments()}
      </>
    );
  }
}
