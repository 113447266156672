import React from 'react';
import { Form } from 'react-bootstrap';
import classes from './MemoryField.module.scss';

export function MemoryNameField(props: React.ComponentProps<typeof Form.Control>) {
  const {
    id,
    value = '',
    placeholder = 'variable name',
    errorMessage,
    ...rest
  } = props;

  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Name
      </Form.Label>
      <Form.Control
        id={id}
        type="text"
        placeholder={placeholder}
        value={value}
        {...rest}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
