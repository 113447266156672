import { Form, Formik } from 'formik';
import React, { PropsWithChildren } from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { InputFormField } from '../InputFormField/InputFormField';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './ResetPasswordForm.module.scss';
import { useResetPasswordForm } from './useResetPasswordForm';

export interface resetPasswordFormValues {
    email: string;
}

const initialValues: resetPasswordFormValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter a valid email.'),
});

interface resetPasswordFormVProps extends PropsWithChildren {
  title?: string;
  message?: string;
}

export function ResetPasswordForm({
  title = 'Verify your email',
  message = 'A verification code has been sent to your email',
} : resetPasswordFormVProps) {
  const {
    isLoading,
    isError,
    statusMessage,
    handleSubmit,
  } = useResetPasswordForm();

  return (
    <Container>
      <Row>
        {isError && (
        <Alert variant="danger" className={styles.formAlert}>
          {statusMessage}
        </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <Row className={styles.formTitle}>
            <Col>
              <h3>{title}</h3>
            </Col>
          </Row>
          <Row className={styles.formMessage}>
            <Col md={10}>
              <p>{message}</p>
            </Col>
          </Row>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} label="Email Address" name="email" type="email" />
              <SubmitButton label="Submit" isLoading={isLoading} />
            </Form>
          </Formik>
        </Col>
      </Row>
      <div className={styles.formSubText}>
        <span>Remember your password? </span>
        <Link
          to="/sign-in"
        >
          Sign in.
        </Link>
      </div>
    </Container>
  );
}
