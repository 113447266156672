import async from 'async';

export class AvatarHelper {
  constructor() {
    this.state = {
      data: {},
      expression: null,
    };
    this.IMAGE_CACHE = {};
  }

  draw(canvas, res, done) {
    if (!canvas) {
      return;
    }
    const { params } = res;
    const { bodyLayers } = res;
    const { faceLayers } = res;
    const isGirl = res.properties.type === 'girl';
    /* eslint-disable no-param-reassign */
    canvas.width = params.avatarWidth;
    canvas.height = params.avatarHeight;
    /* eslint-enable no-param-reassign */
    const faceCanvas = document.createElement('canvas');
    faceCanvas.width = params.avatarWidth;
    faceCanvas.height = params.avatarHeight;

    if (canvas.getContext && faceCanvas.getContext) {
      const ctx = canvas.getContext('2d');
      const faceCtx = faceCanvas.getContext('2d');

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      faceCtx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      this.loadAllImages([...bodyLayers, ...faceLayers].map((l) => l[0])).then(() => {
        ctx.save();
        this.drawBodyLayers(ctx, params, bodyLayers, false);
        faceCtx.save();
        faceCtx.translate(params.faceX, isGirl ? params.girlFaceY : params.faceY);
        this.drawFaceLayers(faceCtx, params, faceLayers, isGirl);
        faceCtx.restore();
        ctx.drawImage(faceCanvas, 0, 0);
        ctx.restore();
        if (done) done();
      });
    }
  }

  loadImage = (src, callback) => {
    const img = new Image();
    img.setAttribute('crossorigin', 'anonymous');
    img.src = `${src}`;
    img.addEventListener('load', () => {
      callback(null, this.IMAGE_CACHE[src] = img);
    });
    // NOTE: ignore missing images. API does not check missing images from now.
    // img.addEventListener('error', () => callback(src));
    img.addEventListener('error', () => callback(null, src));
  };

  loadAllImages = async (sources) => async.every(sources, this.loadImage);

  drawBodyLayers = (ctx, params, bodyLayers) => {
    this.composite(
      ctx,
      bodyLayers.map(([src, blend]) => ({
        img: this.IMAGE_CACHE[src],
        blend,
      })),
    );
  };

  drawFaceLayers = (ctx, params, faceLayers, isGirl = false) => {
    this.composite(
      ctx,
      faceLayers.map(([src, blend]) => {
        const img = this.IMAGE_CACHE[src];
        const isFullSize = img ? img.naturalWidth > params.faceWidth : false;
        return {
          img,
          blend,
          x: isFullSize ? -params.faceX : 0,
          y: isFullSize ? -(isGirl ? params.girlFaceY : params.faceY) : 0,
        };
      }),
    );
  };

  composite = (ctx, items) => {
    items.forEach((item) => {
      const { img } = item;
      if (!img) {
        return;
      }
      img.setAttribute('crossorigin', 'anonymous');
      const blend = item.blend || 'source-over';
      const x = item.x || 0;
      const y = item.y || 0;
      ctx.globalCompositeOperation = blend;
      ctx.drawImage(img, x, y);
    });
  };
}
