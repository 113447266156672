import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import {
  Button, Modal, Row,
} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {
  GetBlobOptions,
  ImageCropper,
  ImageCropperProps,
  useImageCropper,
} from '../../../../../dorian-shared/components/ImageCropper/ImageCropper';
import { imageFileToDataURL } from '../../../../../dorian-shared/components/ImageCropper/imageFileToDataURL';
import { useToggle } from '../../../../../dorian-shared/hooks/useToggle';
import { createFileNameFromImageURL } from './createFileNameFromImageURL';
import classes from './ImageInput.module.scss';

export interface FileInputValue {
    fileName: string,
    file: Blob
}

interface ImageInputProps {
    initialURL: string
    onConfirm: (fileInputValue: FileInputValue, imageURL: string) => void
    error?: string
    imageCropperProps?: Partial<ImageCropperProps>
    getBlobOptions?: GetBlobOptions
    accept?: string
}

export function ImageInput(props: ImageInputProps) {
  const {
    initialURL,
    onConfirm,
    error,
    imageCropperProps,
    getBlobOptions,
    accept,
  } = props;

  const [isCropperModalShown, showCropperModal, hideCropperModal] = useToggle();
  const [isModalConfirmDisabled, disableConfirmButton, enableConfirmButton] = useToggle();

  const [fileInputValue, setFileInputValue] = useState<FileInputValue>();
  const [imageURLToCrop, setImageURLToCrop] = useState(initialURL);
  const handleImageChange = (newFileInputValue: FileInputValue) => {
    setFileInputValue(newFileInputValue);
    return imageFileToDataURL(newFileInputValue.file).then(setImageURLToCrop);
  };

  const lastFileNameRef = useRef(createFileNameFromImageURL(imageURLToCrop));

  const {
    handleCropperInitialized,
    getBlob,
  } = useImageCropper();

  const handleEditClick = () => {
    setImageURLToCrop(initialURL);
    showCropperModal();
    disableConfirmButton();
  };

  const handleCropClick = () => getBlob(getBlobOptions).then((blob) => {
    if (blob) {
      handleImageChange({
        file: blob,
        fileName: lastFileNameRef.current,
      }).then(enableConfirmButton);
    }
  });

  const handleModalConfirmClick = () => {
    if (fileInputValue && imageURLToCrop) {
      onConfirm(fileInputValue, imageURLToCrop);
      hideCropperModal();
    }
  };

  const handleNewFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    const fileName = event.target.value;
    if (file) {
      lastFileNameRef.current = fileName;
      disableConfirmButton();
      handleImageChange({
        file,
        fileName,
      }).then(showCropperModal);
    }
  };

  return (
    <>
      <Row className="mb-3">
        {initialURL && (
          <Col className={classes.imageContainer}>
            <img
              alt="event cover"
              src={initialURL}
              className={classes.image}
            />
            <Button
              className={classes.button}
              variant="primary"
              onClick={handleEditClick}
            >
              Edit
            </Button>
          </Col>
        )}
        <Form.Group as={Col}>
          <Form.Label>Cover</Form.Label>
          <Form.Control
            type="file"
            name="image"
            onChange={handleNewFileUpload}
            isInvalid={!!error}
            accept={accept}
          />
          <Form.Control.Feedback
            type="invalid"
          >
            {error}
          </Form.Control.Feedback>
        </Form.Group>

      </Row>
      <Modal
        size="lg"
        show={isCropperModalShown}
        onHide={hideCropperModal}
        centered
        fullscreen="sm-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>Prepare image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <ImageCropper
              style={{ width: '100%' }}
              src={imageURLToCrop}
              onInitialized={handleCropperInitialized}
              {...imageCropperProps}
            />
          </Row>
          <Modal.Footer>
            <Button
              className={classes.button}
              variant="primary"
              onClick={handleCropClick}
            >
              Crop
            </Button>
            <Button
              disabled={isModalConfirmDisabled}
              className={classes.button}
              variant="primary"
              onClick={handleModalConfirmClick}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>

    </>
  );
}
