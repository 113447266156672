import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import styles from './ScenePreviewImg.css';

const cs = classNames.bind(styles);

export class ScenePreviewImg extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { scenes, id } = this.props;
    const { show } = this.state;

    let scene = scenes.find((object) => object.isDefault === true);
    if (id) {
      scene = scenes.find((object) => Number(object.id) === Number(id));
    }
    if (scenes.length < 1) {
      return null;
    }
    if (!scene || !scene.image || !scene.image.imageUrl) {
      return null;
    }
    const src = scene.image.imageUrl;
    return (
      <>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={cs('ScenePreviewImg')}
          style={{ backgroundImage: `url(${src})` }}
          onClick={() => {
            this.setState({
              show: !show,
            });
          }}
        />
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => {
            this.setState({
              show: !show,
            });
          }}
          className={cs('ScenePreviewImgModal', show ? 'd-block' : 'd-none')}
        >
          <i style={{ backgroundImage: `url(${src})` }} />
        </div>
      </>
    );
  }
}
