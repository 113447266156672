import { Form, Formik } from 'formik';
import React from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { userApi } from '../../../services/userApi/UserApi';
import { Line } from '../../ui/Line/Line';
import { AppleSignInButton } from '../AppleSignInButton/AppleSignInButton';
import { FacebookSignInButton } from '../FacebookSignInButton/FacebookSignInButton';
import { GoogleSignInButton } from '../GoogleSignInButton/GoogleSignInButton';
import { InputFormField } from '../InputFormField/InputFormField';
import { SubmitValues } from '../SignUpForm/useSignUpForm';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './SignInForm.module.scss';
import { useSignInForm } from './useSignInForm';

export interface LoginFormValues {
    email: string;
    password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('Please enter a valid email.'),
  password: Yup.string().required('Please enter a password.'),
});

interface SignInFormProps {
  title?: string,
  textComponent?: React.ReactNode,
  initialValues?: Partial<SubmitValues>,
  submitButtonLabel?: string,
}

export function SignInForm(props : SignInFormProps) {
  const {
    title = 'Sign-In',
    textComponent = null,
    initialValues,
    submitButtonLabel = 'Submit',
  } = props;

  const {
    isLoading,
    isError,
    isSuccess,
    statusMessage,
    handleSubmit,
  } = useSignInForm();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const username = searchParams.get('username');

  const initialEmptyValues: LoginFormValues = {
    email: username ?? '',
    password: '',
  };

  return (
    <Container>
      <Row>
        {isError && (
        <Alert variant="danger" className={styles.formAlert}>
          {statusMessage ?? 'An error occurred.'}
        </Alert>
        )}
        {isSuccess && (
        <Alert variant="success" className={styles.formAlert}>
          {statusMessage ?? 'Success!'}
        </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <h3 className={styles.formTitle}>{title}</h3>
          {textComponent}
          <Formik
            initialValues={{ ...initialEmptyValues, ...initialValues }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} label="Email Address" name="email" type="email" />
              <InputFormField disabled={isLoading} label="Password" autoComplete="password" name="password" type="password" />
              <Col className={styles.externalButtonsContainer}>
                <SubmitButton label={submitButtonLabel} isLoading={isLoading} className="w-100" />
              </Col>
            </Form>
          </Formik>
          <div className={styles.formSubText}>
            <span>Don`t remember your password? </span>
            <Link
              to="/reset-password"
            >
              Reset it here
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={styles.orSection}>
          <Line width="100%" height="1px" />
          or
          <Line width="100%" height="1px" />
        </Col>
      </Row>
      <Col className={styles.externalButtonsContainer}>
        <Row className={styles.externalButtons}>
          <AppleSignInButton
            disabled={isLoading}
            onClick={() => userApi.login('SignInWithApple')}
          />
        </Row>
        <Row className={styles.externalButtons}>
          <GoogleSignInButton
            disabled={isLoading}
            onClick={() => userApi.login('Google')}
          />
        </Row>
        <Row>
          <FacebookSignInButton
            disabled={isLoading}
            onClick={() => userApi.login('Facebook')}
          />
        </Row>
      </Col>
      <div className={styles.formSubText}>
        <span>Don’t have a Dorian account? </span>
        <Link
          to="/sign-up"
        >
          Create one here.
        </Link>
      </div>
    </Container>
  );
}
