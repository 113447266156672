export const validateBookAssociation = (
  originalBookId: number | null,
  sourceBookId: number | null,
  isPrompt: boolean,
) => {
  const isOriginalEqualSource = originalBookId === sourceBookId;
  const isOriginalAndSourceNull = !originalBookId && !sourceBookId;

  const isOriginalBookIdValid = (isPrompt
      && isOriginalEqualSource
      && !isOriginalAndSourceNull
  )
    || (!isPrompt && !isOriginalEqualSource)
    || (!isPrompt && isOriginalAndSourceNull);

  const isSourceBookIdValid = (!isPrompt
      && !isOriginalAndSourceNull
      && !isOriginalEqualSource)
    || (isPrompt
      && originalBookId
      && sourceBookId
      && isOriginalEqualSource)
    || (!isPrompt && isOriginalAndSourceNull);

  const isPromptValid = (isPrompt
      && !isOriginalAndSourceNull
      && isOriginalEqualSource)
    || (!isPrompt && isOriginalAndSourceNull)
    || (!isPrompt && !isOriginalEqualSource);
  return { isOriginalBookIdValid, isSourceBookIdValid, isPromptValid };
};
