/* eslint-disable max-len,react/no-unescaped-entities */
import React, { Component } from 'react';

export class CommunityGuidelines extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="privacy-policy-content">
        <p>
          Our vision at Dorian is to create a social narrative experience that authors and readers can positively enjoy
          without fear or prejudice. We ask that everyone behave in a positive, tolerant way that allows everyone to
          enjoy a wonderful experience.
        </p>
        <p>
          To protect the integrity of our community, as the provider of the service, we at Dorian reserve the right to
          suspend any account at any time for any conduct that we determine to be inappropriate or harmful. Such actions
          may include: removal of content, a strike on the account, and/or suspension of account(s).
        </p>
        <h6>Breaking the Law</h6>
        <p>
          You must respect all applicable local, national, and international laws while using our Platform. Any content
          or activity featuring, encouraging, offering, or soliciting illegal activity is prohibited.
        </p>
        <h6>Suspension Evasion</h6>
        <p>
          All suspensions are binding until expiration or removal upon appeal. Any attempt to circumvent an account
          suspension or chat ban by using other accounts, identities, personalities, or presence on another user's
          account will also result in suspension. Suspension evasion will not only increase the length of suspension,
          but may lead to an indefinite suspension.
        </p>
        <h6>Self-Destructive Behavior</h6>
        <p>
          Any activity that may endanger your life or lead to your physical harm is prohibited. This includes, but
          is not limited to: suicide threats, intentional physical trauma, illegal use of drugs, illegal or
          dangerous consumption of alcohol, and dangerous or distracted driving. We do not make exceptions for self
          destructive behavior performed as a stunt or gag made in jest, or meant to entertain, when the behavior
          could reasonably be expected to cause physical injury.
        </p>
        <h6>Violence and Threats</h6>
        <p>
          Acts and threats of violence will be taken seriously and are considered zero-tolerance violations and
          all accounts associated with such activities will be indefinitely suspended. This includes, but is not
          limited to:
        </p>
        <ul>
          <li>Attempts or threats to physically harm or kill others</li>
          <li>Attempts or threats to hack, DDOS, or SWAT others</li>
          <li>Use of weapons to physically threaten, intimidate, harm, or kill others</li>
          <li>Hateful Conduct and Harassment</li>
        </ul>
        <p>
          Hateful conduct is any content or activity that promotes, encourages, or facilitates discrimination,
          denigration, objectification, harassment, or violence based on race, ethnicity, national origin,
          religion, sex, gender, gender identity, sexual orientation, age, disability or serious medical condition
          or veteran status, and is prohibited. Any hateful conduct is considered a zero-tolerance violation. We
          will take action on all accounts associated with such conduct with a range of enforcement actions,
          including and up to indefinite suspension.
        </p>
        <p>
          Harassment is any content or activity that attempts to intimidate, degrade, abuse, or bully others, or
          creates a hostile environment for others, and is prohibited. Depending on the severity of the offense,
          your account may be indefinitely suspended on the first violation.
        </p>
        <p>
          We prohibit using Dorian to facilitate hateful conduct or harassment, whether the targets are on or off
          the Dorian platform. Individuals, communities or organizations that do so are not allowed to use our
          Platform. We may take action against users for hateful conduct or harassment that occurs off the Dorian
          platform that is directed at Dorian users.
        </p>
        <h6>Unauthorized Sharing of Private Information</h6>
        <p>
          Do not invade the privacy of others. It is prohibited to share content that may reveal private personal
          information about individuals, or their private property, without permission. This includes but is not
          limited to:
        </p>
        <ul>
          <li>Sharing personally identifiable information (such as real name, location, or ID)</li>
          <li>Sharing restricted or protected social profiles or any information from those profiles</li>
          <li>
            Sharing content that violates another's reasonable expectation of privacy, for example streaming from a
            private space, without permission
          </li>
        </ul>
        <h6>Impersonation</h6>
        <p>
          Content or activity meant to impersonate an individual or organization is prohibited. Any attempts to
          misrepresent yourself as a member of Dorian representatives is a zero-tolerance violation and will
          result in indefinite suspension.
        </p>
        <h6>Spam, Scams, and Other Malicious Conduct</h6>
        <p>
          Any content or activity that disrupts, interrupts, harms, or otherwise violates the integrity of
          Dorian Platform or another user's experience or devices is prohibited. Such activity includes:
        </p>
        <ul>
          <li>Posting large amounts of repetitive, unwanted messages or user reports</li>
          <li>Distributing unauthorized advertisements</li>
          <li>Phishing</li>
          <li>Defrauding others</li>
          <li>Spreading malware or viruses</li>
          <li>
            Misinformation (such as feigning distress, posting misleading metadata, or intentional channel
            miscategorization)
          </li>
          <li>Tampering (such as artificially inflating analytics)</li>
          <li>Selling or sharing user accounts</li>
          <li>Reselling Dorian Platform or features</li>
          <li>
            Defacing, or attempting to deface, website pages or other Dorian Platform (such as uploading
            inappropriate or malicious content)
          </li>
          <li>Cheating a Dorian rewards system</li>
          <li>Nudity, Pornography, and Other Sexual Content</li>
        </ul>
        <p>
          All sexual content in user created stories must adhere to the following guidelines (based on a film
          rating of PG-13):
        </p>
        <ul>
          <li>No explicit/slang descriptions of sexual organs such as breasts, nipples, genitals, etc.</li>
          <li>Every encounter must include safe sex practices, written in a positive light.</li>
          <li>Enthusiastic and sober consent is a must for all characters participating in the scene.</li>
        </ul>
        <p>
          Content or activities that threaten or promote sexual violence or exploitation are strictly
          prohibited and may be reported to law enforcement. Child exploitation will be reported to authorities
          via the National Center for Missing & Exploited Children.
        </p>

        <h6>Extreme Violence, Gore, and Other Obscene Conduct</h6>
        <p>Content that exclusively focuses on extreme or gratuitous gore and violence is prohibited.</p>

        <h6>Intellectual Property Rights</h6>
        <p>
          You should only share content on Dorian that you own, or that you are otherwise authorized to share
          on Dorian. If you share content on Dorian that you do not own or otherwise do not have the rights to
          share on Dorian, you may be infringing another person's intellectual property rights. This includes
          any third party content included in your content, derivative creations, or performances of others'
          copyrighted content. We encourage you to assess your content for adherence to applicable intellectual
          property laws and the proper application of principles such as fair use, and to secure all appropriate
          rights needed, before sharing your content on Dorian.
        </p>
        <p>
          Any unauthorized content you share on Dorian may be subject to a takedown by the rights holder(s) to
          remove the infringing content from Dorian, and is a violation of our Terms of Service and Community
          Guidelines. Multiple violations of our policies may lead to a permanent suspension of your
          account.
        </p>

        <h6>Content Labeling</h6>
        <p>
          You are expected to accurately label your content to the best of your ability. When choosing a
          category or tag, please choose whichever best describes your content. Deliberate or extensive misuse
          of titles, tags, games/categories, or other metadata are prohibited.
        </p>
      </div>
    );
  }
}
