import React from 'react';
import { Modal } from 'react-bootstrap';
import { usePerformanceRanksContext } from '../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import { PerformanceRanksModalContextProvider } from './context/PerformanceRanksModalContext';
import { PerformanceRanksForm } from './PerformanceRanksForm/PerformanceRanksForm';
import { PerformanceRanksFormikWrapper } from './PerformanceRanksFormikWrapper/PerformanceRanksFormikWrapper';
import classes from './PerformanceRanksModal.module.scss';
import { PerformanceRanksModalFooter } from './PerformanceRanksModalFooter/PerformanceRanksModalFooter';
import { PerformanceRanksModalHeader } from './PerformanceRanksModalHeader/PerformanceRanksModalHeader';

export type PerformanceRanksModalProps = {
  onHide: () => void,
  disabled?: boolean,
}

export function PerformanceRanksModal(props: PerformanceRanksModalProps) {
  const { onHide, disabled = false } = props;

  const { performanceRanks } = usePerformanceRanksContext();

  const handleSubmit = () => {
    onHide();
  };

  return (
    <Modal
      show
      onHide={onHide}
      size="lg"
      backdrop="static"
      keyboard={false}
      centered
      scrollable
      contentClassName={classes.contentClassName}
    >
      <PerformanceRanksFormikWrapper onSubmit={handleSubmit} initialValues={performanceRanks}>
        <PerformanceRanksModalContextProvider ranks={performanceRanks} onHide={onHide} disabled={disabled}>
          <PerformanceRanksModalHeader />
          <PerformanceRanksForm />
          <PerformanceRanksModalFooter />
        </PerformanceRanksModalContextProvider>
      </PerformanceRanksFormikWrapper>
    </Modal>
  );
}
