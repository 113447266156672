import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Badge, Button, Form, InputGroup, Spinner,
} from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import imgEdit from '../../../assets/images/edit-branch.svg';
import Cancel from '../../../assets/images/no.svg';
import Save from '../../../assets/images/yes.svg';
import { api } from '../../api';
import './books.scss';

export class BookTitle extends Component {
  constructor(props) {
    super(props);
    const { obj } = this.props;

    this.state = {
      title: obj.title || '',
      edit: false,
      loading: false,
      validated: null,
      formError: null,
      formErrorLimit: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { obj } = this.props;
    const { edit } = this.state;

    if (prevProps.obj.title !== obj.title) {
      this.setState({
        title: obj.title || '',
      });
    }

    if ((prevState.edit !== edit) && edit) {
      this.startEditName();
      window.addEventListener('mousedown', this.handleClick, { passive: true });
    }
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  handleSubmit = (event) => {
    const { limits } = this.props;

    event.preventDefault();
    const form = event.currentTarget;
    let validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });

    if (value.title && value.title.length > Number(limits.book_title_max.value)) {
      validated = false;
      this.setState({ validated: false, formErrorLimit: true });
      event.stopPropagation();
    }
    if (validated === true) {
      this.update(value);
      this.setState({ validated: true, formErrorLimit: false });
    } else {
      this.setState({ validated, formError: 'Please choose a valid Story title.' });
    }
    event.stopPropagation();
  };

  update = (value) => {
    this.setState({
      loading: true,
    });
    const {
      obj,
      updateBooks,
      index,
    } = this.props;

    api.put(`/v1/books/${obj.id}`, value)
      .then(() => {
        this.setState({
          edit: false,
          loading: false,
          validated: false,
        }, () => {
          updateBooks(index, value.title);
          this.stopEditName();
        });
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  handleCancel = () => {
    const { obj } = this.props;

    this.setState({
      edit: false,
      title: obj.title || '',
      validated: null,
      formError: null,
      formErrorLimit: null,
    }, () => {
      this.stopEditName();
    });
  };

  escFunction = (event) => {
    const { edit } = this.state;

    if (event.keyCode === 27 && edit) {
      this.handleCancel();
    }
  };

  stopEditName = () => {
    const { editTitleAction } = this.props;

    document.removeEventListener('keydown', this.escFunction, false);
    window.removeEventListener('mousedown', this.handleClick, false);

    editTitleAction(false);
  };

  startEditName = () => {
    const { editTitleAction } = this.props;

    document.addEventListener('keydown', this.escFunction, { passive: true });

    editTitleAction(true);
  };

  handleClick = (evt) => {
    // eslint-disable-next-line react/no-find-dom-node,react/no-string-refs
    const area = ReactDOM.findDOMNode(this.refs.BookTitleForm);
    if (!area.contains(evt.target)) {
      this.handleCancel();
    }
  };

  render() {
    const {
      validated,
      formErrorLimit,
      edit,
      loading,
      title,
      formError,
    } = this.state;
    const {
      limits,
      obj,
      disabled,
    } = this.props;

    if (loading) {
      return (
        <Spinner size="sm" variant="primary" animation="border" />
      );
    }

    if (edit) {
      return (
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
          /* eslint-disable-next-line react/no-string-refs */
          ref="BookTitleForm"
        >
          <InputGroup
            size="sm"
            className="BookTitleForm"
          >
            <Form.Control
              required
              autoFocus
              type="text"
              placeholder="Story Title"
              value={title}
              onChange={(e) => {
                this.setState({
                  formErrorLimit: e.target.value
                    && e.target.value.length > Number(limits.book_title_max.value),
                  title: e.target.value,
                  formError: null,
                });
              }}
              className={
                !title
                || formError
                || (title && title.length > Number(limits.book_title_max.value))
                  ? 'text-limit' : ''
}
              pattern="^([A-Za-z]|[0-9]|_|-| |[.!?,:;()&|[\]\/@]|'|\u0022)+$"
              valid={toString((formError || formErrorLimit) && true)}
              name="title"
            />

            <InputGroup.Prepend
              style={{ position: 'relative' }}
            >
              <Form.Text className="char-limit-info-box" style={{ right: '3px' }}>
                {Number(limits.book_title_max.value) - (title ? title.length : 0)}
              </Form.Text>
            </InputGroup.Prepend>

            <InputGroup.Append>
              <Button
                variant="secondary"
                type="submit"
                disabled={!title || formErrorLimit || formError}
              >
                <img src={Save} alt="Save" />
              </Button>
              <Button
                onClick={this.handleCancel}
                variant="secondary"
              >
                <img src={Cancel} alt="Cancel" />
              </Button>
            </InputGroup.Append>

            <Form.Control.Feedback type="invalid" className={!formError ? 'd-none' : 'd-block'}>
              {formError}
            </Form.Control.Feedback>

            <Form.Control.Feedback type="invalid" className={formErrorLimit ? 'd-block' : 'd-none'}>
              Title is too long.
            </Form.Control.Feedback>

            <Form.Control.Feedback type="invalid" className={!title ? 'd-block' : 'd-none'}>
              Please choose a valid story title.
            </Form.Control.Feedback>

            <Form.Control.Feedback type="invalid">
              Please choose a valid story title.
            </Form.Control.Feedback>
          </InputGroup>
        </Form>
      );
    }

    return (
      <div className="books-list-titleBox">
        <Link
          className="books-list-link"
          to={`/book/${obj.id}`}
        >
          {title}
        </Link>
        {/* {(!this.props.obj.isPrompt && this.props.obj.sourceBookId !== null) &&
        <Badge variant={'primary'} style={{marginLeft: '.5em'}}>spinoff</Badge>} */}
        {obj.isPrompt && <Badge variant={obj.disabled ? 'dark' : 'secondary'} style={{ marginLeft: '.5em' }}>Prompt</Badge>}
        {disabled ? null
          : (
            <Button
              variant="secondary"
              size="sm"
              className="books-editNameBtn"
              onClick={() => {
                this.setState({
                  edit: true,
                });
              }}
            >
              <img src={imgEdit} className="btnImg" alt="Edit Story Name" />
            </Button>
          )}
      </div>

    );
  }
}
