import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import styles from './Steps/Steps.scss';

const cs = classNames.bind(styles);

export class BranchModalPrevNextSteps extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stepsTypeFields: [],
    };
  }

  checkItem(arr, fild, arr1, fild1) {
    let res;
    arr.forEach((object) => {
      if (object[fild] === arr1) {
        res = object[fild1];
      }
    });
    return res;
  }

  handleChangeStepsType() {
    const { stepTypes, step } = this.props;

    const fields = this.checkItem(stepTypes, 'id', step ? Number(step.stepTypeId) : 1, 'fields');
    const newFields = [];
    fields.forEach((object) => {
      newFields[object] = true;
    });
    this.setState({
      stepsTypeFields: newFields,
    });
  }

  componentDidMount() {
    this.handleChangeStepsType();
  }

  render() {
    const {
      characters,
      characterExpression: characterExpressions,
      stepTypes,
      step,
    } = this.props;
    const { stepsTypeFields } = this.state;

    const stepType = stepTypes.find((el) => el.id === step.stepTypeId);
    const character = characters.find((el) => el.id === step.characterId);
    // eslint-disable-next-line max-len
    const characterExpression = characterExpressions.find((el) => el.id === step.characterExpressionId);

    return (
      <ListGroup.Item as="li" className="py-0 pl-3 pr-0">
        <Row as={Row} className="py-1">

          <Col
            md={1}
            className={cs('mb-0 px-1')}
          >
            {stepType ? stepType.title || '' : ''}
          </Col>

          <Col
            md={8}
            className={cs('mb-0 px-1', stepsTypeFields.text ? 'd-block' : 'd-none')}
          >
            {step.text || ''}
          </Col>

          <Col
            md={1}
            className={cs('mb-0 px-1', stepsTypeFields.character ? 'd-block' : 'd-none')}
          >
            {character ? character.name || '' : ''}
          </Col>

          <Col
            md={1}
            className={cs('mb-0 px-1', stepsTypeFields.characterRelationship ? 'd-block' : 'd-none')}
          >
            {step.characterRelationship || ''}
          </Col>

          <Col
            md={1}
            className={cs('mb-0 px-1', stepsTypeFields.characterExpression ? 'd-block' : 'd-none')}
          >
            {characterExpression ? characterExpression.title || '' : ''}
          </Col>

          <Col
            md={1}
            className={cs('mb-0 px-1', stepsTypeFields.subType ? 'd-block' : 'd-none')}
          >
            {step.subType}
          </Col>

        </Row>
      </ListGroup.Item>
    );
  }
}
