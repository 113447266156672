import React from 'react';
import { Alert, Card } from 'react-bootstrap';
// @ts-ignore: no def file
import { Link } from 'react-router-dom';
import classNames from '../AdminSupportPanel.module.scss';
import { LINK_HOW_TO_CHECK_ISSUE, LINK_SENTRY_PERFORMANCE } from '../BugTracking/BugTracking';
import { PerformanceAsyncLongCalculations } from './PerformanceAsyncLongCalculations';
import { PerformanceLongCalculations } from './PerformanceLongCalculations';

export function PerformanceTest() {
  return (
    <div>
      <h2 className={classNames.header}>Performance test section</h2>
      <Card>
        <Card.Body>
          <Alert variant="secondary">
            See short description in
            {' '}
            <Link
              to={{
                pathname: LINK_HOW_TO_CHECK_ISSUE,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              the Notion page
            </Link>
            {' '}
            how to check occurred errors in a bug tracking service:
            <br />
            Or go to
            {' '}
            <Link
              to={{
                pathname: LINK_SENTRY_PERFORMANCE,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Sentry performance
            </Link>
            {' '}
            directly.
            <br />
            Further instructions in development.
          </Alert>
        </Card.Body>
      </Card>
      <PerformanceLongCalculations />
      <PerformanceAsyncLongCalculations />
    </div>
  );
}
