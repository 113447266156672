import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';
import './ConfirmTermModal.scss';

/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */

export function ConfirmOriginalModal({ close, update }) {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    const obj = {
      type: 'original',
      version: 0,
    };
    api.post('/v1/agreements', obj)
      .catch(() => {
        setLoading(false);
      })
      .then((res) => {
        setLoading(false);
        if (res) {
          update();
        }
      });
  };

  return (
    <Modal
      show
      size="xl"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <div className="terms ConfirmOriginal">

          <h2>
            “Blair Witch Fest”
            <br />
            OFFICIAL RULES
          </h2>

          <h2>
            NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE YOUR CHANCE OF WINNING. VOID WHERE
            PROHIBITED. THIS CONTEST IS NOT SPONSORED OR ENDORSED BY VISA.
          </h2>

          <h3>
            Please read these official rules (the “Official Rules”) before entering Blair Witch Fest (the “Contest”).
          </h3>

          <p>
            <b>
              By participating in the Contest, you agree to be bound by these Official Rules as well as the Dorian
              Terms of Use (
              <a
                href="https://dorian.live/terms-of-use"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://dorian.live/terms-of-use
              </a>
              )
              (the “Terms of Use”), and represent that you satisfy
              all of the eligibility requirements included in Section 2 below.
            </b>
            {' '}
            In the event of an inconsistency
            between these Official Rules and the Terms of Use with respect to your Entry, these Official Rules govern.
          </p>

          <ol>
            <li>
              <b>PROMOTION DESCRIPTION:</b>
              {' '}
              The Contest begins on or about October 15, 2021 at 9:01 a.m. pacific
              time (
              <b>“PT”</b>
              ) and ends on or about October 31, 2021, at 11:59 p.m. PT (the
              {' '}
              <b>
                “Promotion
                Period”
              </b>
              ). The
              Promotion Period and all other submission dates in these Official Rules may be extended as indicated on
              the
              “Website” (as
              defined below) and as permitted by Section 14 of these Official Rules. During the Promotion Period,
              entrants
              will
              have the opportunity to create and submit an interactive story inspired by the world of the Blair Witch
              (i.e., the motion pictures titled Book of Shadows: Blair Witch 2 [2000] and Blair Witch [2016]) (the
              <b>“Properties”</b>
              ), as more fully described below and on the Website and App (each
              an
              <b>“Entry”</b>
              {' '}
              and
              collectively
              the
              <b>“Entries”</b>
              ). At the end of the Promotion Period, one (1) Grand Prize winner will be selected
              as set
              forth
              below. By participating in the Contest, each entrant unconditionally accepts and agrees to comply with
              and
              abide by these Official Rules, the Terms of Use, and the decisions of Dorian Inc., 340 S. Lemon Ave.
              #5571,
              Walnut, CA 91789-2706 (
              <b>“Sponsor”</b>
              ), which shall be final and binding in all respects. Whenever
              these
              Official
              Rules indicate that a determination will be made by Sponsor, Sponsor shall be entitled to make that
              determination in its sole and absolute, unfettered discretion. Whether an entrant receives a prize is
              contingent upon fulfilling all requirements set forth herein.
            </li>

            <li>
              <b>ELIGIBILITY:</b>
              {' '}
              This Contest is open only to legal residents of the fifty (50) United States
              (including the
              District of Colombia), Canada (excluding Quebec), the United Kingdom, Australia, New Zealand, India, and
              the
              Philippines who are thirteen (13) years of age or older at the time of entry. Anyone under eighteen (18)
              years of age or under the age of majority in their state of residence must get permission from their
              parent
              or legal guardian to enter, and their parent or legal guardian must read and accept these Official Rules
              on
              their child’s behalf. Parents and legal guardians are responsible for the acts of their minor children
              who
              participate in the Contest. Officers, directors and employees of Sponsor, Lions Gate Entertainment Inc.
              (
              <b>“Lionsgate”</b>
              ), and the “Dorian Fan Ambassadors” (as defined below), and each of their respective
              parent,
              subsidiaries, affiliates, members, distributors, sales representatives, advertising and promotion
              agencies
              (all such individuals and entities referred to collectively, the
              {' '}
              <b>“Contest Entities”</b>
              ), and each of
              their
              immediate family members and/or people living in the same household are NOT eligible to enter the
              Contest or
              win a prize.
              {' '}
              <b>“Immediate family members”</b>
              {' '}
              shall mean parents, step-parents, legal guardians,
              children,
              step-children, siblings, step-siblings, or spouses.
              {' '}
              <b>“People living in the same household”</b>
              {' '}
              shall
              mean
              those
              people who share the same residence at least three (3) months a year. Void where prohibited or
              restricted by
              law.
            </li>

            <li><b>HOW TO ENTER:</b></li>
          </ol>

          <ol type="A">
            <li>
              <b><u>How to Enter:</u></b>
              {' '}
              To enter the Contest, potential entrants must:
            </li>
          </ol>

          <ol>
            <li>
              Have a writer account on the Dorian platform through the mobile application “Dorian: The Future of
              Fiction!” (the
              <b>“App”</b>
              ) or the website at
              <a
                href="https://dorian.live/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://dorian.live/
              </a>
              {' '}
              (the
              <b>“Website”</b>
              );
            </li>
            <li>
              Submit their Entry through Sponsor’s creation engine on the App or Website during the Promotion
              Period.
              Entrants may edit their Entry through the App or the Website at any time throughout the Promotion
              Period;
              provided that any edits made to an Entry after the Promotion Period ends shall not be included as part
              of
              the final Entry;
            </li>
            <li>Accept all terms and conditions of these Official Rules; and</li>
            <li>
              Comply with all of the following submission requirements (collectively, the
              <b>
                “Submission
                Requirements”
              </b>
              ),
              and any Entry that violates the following criteria, as determined by Sponsor, may be disqualified:
            </li>
          </ol>

          <ul>
            <li>Entries must be written in English;</li>
            <li>Entries must be original, inventive, engaging, and dynamic;</li>
            <li>
              Entries must remain faithful to the Contest Property Guidelines
              <a
                href="https://home.dorian.live/property-guidelines"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://home.dorian.live/property-guidelines
              </a>
              ;
            </li>
            <li>
              Entries must be based on the Contest prompt
              <a
                href="https://home.dorian.live/blair-witch-fest-prompt"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://home.dorian.live/blair-witch-fest-prompt
              </a>
              , and must
              additionally include at least the following plot points, all as they may further be described within the
              Contest prompt:
              <ul>
                <li> The Episodes must take place at “Blair Witch Fest”;</li>
                <li>The main character must be a social media influencer at “Blair Witch Fest”;</li>
                <li>
                  There must be at least three (3) individual characters included within each Entry that are
                  provided with
                  dialogue; and
                </li>
                <li>The characters must go into the Black Hills Forest for a “Survive the Witch” challenge.</li>
              </ul>
            </li>
            <li>
              Entries may not include characters directly from the Properties (i.e., the individual characters that
              appeared within the films) and/or any from other intellectual properties (e.g., no crossover fiction);
            </li>
            <li>Entries must be created using only the assets provided by Sponsor for use in the Contest;</li>
            <li>
              Entries must include three (3) continuous, cohesive, chronological episodes, each of which must be
              between
              two thousand (2,000) and ten thousand (10,000) words (the
              <b>“Episodes”</b>
              {' '}
              and each an
              <b>“Episode”</b>
              );
            </li>
            <li>
              Each Episode must include at least five (5) choices for readers to make as playable options (as such
              playable options are further described in the Terms of Use), with at least one (1) premium choice;
            </li>
            <li>
              The third (3rd) Episode of each Entry should include at least two (2) and no more than five (5)
              different
              possible endings for readers to choose from;
            </li>
            <li>
              Entries must adhere to Dorian’s Community Guidelines
              (
              <a
                href="https://dorian.live/documents/community-guidelines"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://dorian.live/documents/community-guidelines
              </a>
              ) and may not include
              obscenity, pornography, libelous content, hate speech, disparagement, overly graphic
              or sexual content, and/or otherwise objectionable content;
            </li>
            <li>Entries must be submitted during the Promotion Period. Late Entries will not be considered;</li>
            <li>
              Entries may not contain personally identifiable information, phone numbers, personal addresses
              (physical,
              email or otherwise), social media handles, websites and/or web page links, except that social media
              handles
              may only be included on a standalone page at the end of the Entry, which page may not include any
              element of
              or related to the Properties or this Contest; and
            </li>
            <li>
              Each Entry must be the submitting entrant’s own, previously unpublished work.
            </li>
          </ul>

          <p>
            Additional optional resources and recommendations for writing on the Dorian platform are available on
            Sponsor's Writer's Corner, which may be accessed with a writer account on
            the Dorian platform at:
            {' '}
            <a
              href="https://dorian.live/articles"
              rel="noopener noreferrer"
              target="_blank"
            >
              https://dorian.live/articles.
            </a>
          </p>

          <p>
            Prospective entrants may submit only one (1) Entry per person. Any Entries that, in Sponsor's sole
            discretion, violate the Submission Requirements and/or these Official Rules may be disqualified. The
            Website’s database clock will be the official time keeper for this Contest. For the avoidance of doubt,
            each
            Entry’s cover will be considered part of that Entry. Once submitted as part of this Contest, Sponsor shall
            have the unfettered right to exploit any Entry in its sole discretion, including publishing such Entry
            (edited or otherwise) on the Website and/or App at any time, including after the end of the Promotion
            Period, in perpetuity.
          </p>

          <p>
            In the event a dispute regarding the identity of the individual who actually submitted an Entry cannot be
            resolved to Sponsor’s satisfaction, the affected Entry may be disqualified. The Contest Entities shall not
            be responsible for incorrect or inaccurate Entry information whether caused by Internet users or by any of
            the equipment or programming associated with or utilized in the Contest or by any technical or human error
            which may occur in the processing or transmission of the Entries in the Contest. The Contest Entities
            assume
            no responsibility or liability for any error, omission, interruption, deletion, theft or destruction, or
            unauthorized access to, or alteration of Entries.
          </p>

          <p>
            <u>IMPORTANT NOTE:</u>
            {' '}
            If Sponsor is duly notified that any element of an entrant’s Entry infringes upon
            the rights
            of another person and/or receives a legally valid request to remove the affected Entry from the Website
            and/or the App because of such infringement, such Entry may be removed from the Website and App, and/or
            disqualified from the Contest, as Sponsor may determine in its unfettered discretion. Once an Entry is
            provided to Sponsor and made available on the Website and/or App as set forth herein for viewing by the
            general public, such posting will be deemed made at the direction of the entrant within the meaning of the
            Digital Millennium Copyright Act and the Communications Decency Act.
          </p>

          <ol start="4">
            <li>
              <b>JUDGING AND WINNER SELECTION</b>
            </li>
          </ol>

          <ol type="A">
            <li>
              <b><u>Compliance Review.</u></b>
              {' '}
              Prior to the start of the Promotion Period, Sponsor will offer select
              individuals the
              opportunity to participate in the review of Entries. Up to thirty (30) such selected individuals
              (the
              {' '}
              <b>“Dorian Fan Ambassadors”</b>
              ) and Sponsor or its designee will concurrently review submitted
              Entries from on or about November 1, 2021 to on or about November 9, 2021 for compliance with these
              Official Rules. The Entries determined by the aforementioned process to be compliant with these Official
              Rules will be deemed “Compliant Entries”.
            </li>
            <li>
              <b><u>Entry Review Period.</u></b>
              {' '}
              Compliant Entries will be posted on the Website and the App from on
              or about
              November 10, 2021 to on or about November 17, 2021 at 11:59 p.m. PT (the
              {' '}
              <b>“Entry Review Period”</b>
              ).
              During
              the
              Entry Review Period, visitors to the App will have the opportunity to read and comment on the Compliant
              Entries. At the end of the Entry Review Period, the top five (5) Compliant Entries with
              the highest
              {' '}
              <b>“Completion Rate”</b>
              {' '}
              (i.e., the number of readers who read the Entry from start to
              finish) will be deemed
              {' '}
              <b>“Final Round Finalists”</b>
              .
            </li>
            <li>
              <b><u>Winning Entry Selection.</u></b>
              {' '}
              Prior to the start of the Promotion Period, Sponsor will select
              a panel
              of five
              (5) judges, comprised of two (2) designees from Sponsor, two (2) designees from Lionsgate, and one (1)
              individual to be chosen by Sponsor and Lionsgate together (collectively, the
              {' '}
              <b>“Judging Panel”</b>
              ).
              The
              Judging
              Panel will review the Final Round Finalists from on or about November 18, 2021 to on or about November
              29,
              2021 to determine the Grand Prize winner based on the following criteria: (i) overall fit within the
              lore of
              the Properties, including as may be identified within these Official Rules, the Contest Property
              Guidelines
              and the Contest prompt (40%); (ii) the quality and cohesiveness of the Entry (e.g., concept/originality,
              structure, plot, pacing, characters, dialogue, spelling and grammar, etc.) (20%); (iii) quality of
              choices
              within the Entry, optimization for live sessions and performability (20%); and (iv) Completion Rate
              (20%).
              The decisions of Sponsor and the Judging Panel with respect to the Contest are final and binding in all
              respects and are not subject to challenge or appeal.
            </li>
          </ol>

          <ol start="5">
            <li>
              <b>WINNER/FINALIST NOTIFICATION:</b>
              {' '}
              Once selected by the Judging Panel, the potential Grand Prize
              winner will
              be notified by e-mail, mail, and/or through the Website and/or App using the contact information
              provided at
              the time of entry. Sponsor shall have no liability for any winner notification that is lost, intercepted
              or
              not received by the potential winner for any reason. If, despite reasonable efforts, the potential prize
              winner does not respond within five (5) days of the first notification attempt, or if the prize or prize
              notification is returned as unclaimed or undeliverable to such potential prize winner, such potential
              prize
              winner may forfeit the applicable prize and an alternate prize winner may be selected. If the potential
              prize winner is found to be ineligible, or if they have not complied with these Official Rules or
              declines
              the prize for any reason prior to award, such potential prize winner may be disqualified and an
              alternate
              winner may be selected. Sponsor may successively attempt to contact up to two (2) potential prize
              winners in
              accordance with such procedure, and if there is still no confirmed prize winner after such attempts have
              been made, if any, the prize may go unawarded.
              <br />
              <br />

              Once a Grand Prize winner has been confirmed, Sponsor will update the winning Entry to ensure optimum
              performance and/or end user experience within the Website and App (as determined by Sponsor in its
              unfettered discretion). Once the winning Entry has been updated, the winning Entry will be announced on
              the
              Website and the App on or about the week of December 13, 2021.
            </li>
          </ol>

          <ol start="6">
            <li>
              <b>PRIZE:</b>
              {' '}
              One (1) Grand Prize is available. The Grand Prize consists of:
            </li>
          </ol>

          <ol type="a">
            <li>
              The updated winning Entry will be featured within the App as the winner of the Contest, which may
              include
              placement of the winning Entry on the homepage of the Website and/or the App and a custom cover provided
              by
              Sponsor, which will be included with any updates that Sponsor may make to such winning Entry;
            </li>
            <li>
              A virtual “coffee meetup” with a Lionsgate Executive, the details of which shall be determined in
              Lionsgate’s unfettered discretion, including as further described below (the
              <b>“Virtual Coffee”</b>
              );
            </li>
            <li>One thousand (1,000) virtual “Hearts” for use on the Dorian Platform; and</li>
            <li>
              A Visa Gift Card valued at Five Hundred United States Dollars ($500).
            </li>
          </ol>

          <p>
            With respect to the Grand Prize, the total approximate retail value (
            <b>“ARV”</b>
            ) of the prizes described
            in
            sections (a) and (b) have no retail value, may not be transferred, sold, or assigned to another person.
            Any
            monetary value of (a) is dependent on the incremental increase in revenue obtained by virtue of the
            Entry’s
            promotion by Sponsor. The ARV of the one thousand virtual “Hearts” is One Hundred and Forty-Two United
            States Dollars (USD$142), and the ARV of the Visa Gift Card is Five Hundred United States Dollars
            (USD$500).
            The total ARV for the Grand Prize is Six Hundred and Forty-Two United States Dollars (USD$642).
          </p>
          <p>
            All aspects of the Virtual Coffee shall be determined by Lionsgate in its unfettered discretion, including
            the identity of the Lionsgate Executive, the date and time for which the Virtual Coffee shall take place,
            and the manner in which the coffee will be handled (e.g., Zoom, WebEx, phone call, etc.). For the
            avoidance
            of doubt, the Grand Prize winner may not record the Virtual Coffee or invite any third party to
            participate
            in the Virtual Coffee (i.e., only the Grand Prize winner may participate). The Virtual Coffee will be
            further subject to any other restrictions imposed by Lionsgate in its unfettered discretion and is subject
            to availability. In the event the Grand Prize winner engages in behavior that, as determined by Sponsor in
            its sole discretion, is obnoxious or threatening, illegal or that is intended to annoy, abuse, threaten or
            harass any other person, Lionsgate reserves the right to immediately withdraw and/or terminate any portion
            of the Grand Prize (including the Website and/or App placement, the Virtual Coffee, etc.), in whole or in
            part, with no further compensation to the Grand Prize winner. In the event that the Virtual Coffee does
            not
            or cannot take place as scheduled or at all, for reasons included but not limited to scheduling conflicts,
            cancellations, postponement, an event of "Force Majeure" (defined below), or for any other reason, then in
            lieu of the Virtual Coffee, the Grand Prize winner will receive the remaining components, if any, of the
            Grand Prize, which shall constitute full satisfaction of Sponsor's prize obligation to the Grand Prize
            winner, and no other or additional compensation will be awarded.
          </p>

          <ol start="7">
            <li>
              <b>GENERAL PRIZE CONDITIONS:</b>
              {' '}
              Except as set forth herein, no cash alternative or substitution of
              prizes will
              be allowed, except Sponsor reserves the right in its sole discretion to substitute prizes of comparable
              value if any prize listed is unavailable, in whole or in part, for any reason. Prizes will be awarded
              only
              if the potential prize winner fully complies with these Official Rules. All portions of the prize(s) are
              non-assignable and non-transferable. Any prizes pictured in point-of-sale, online, television and print
              advertising, promotional packaging, and other Contest materials are for illustrative purposes only. All
              ARV
              are subject to price fluctuations in the consumer marketplace based on, among other things, any gap in
              time
              between the date the ARV is estimated for purposes of these Official Rules and the date the prize is
              awarded
              or redeemed. In the event there is a discrepancy or inconsistency between guidelines, disclosures or
              other
              statements contained in any such promotional materials and the terms and conditions of these Official
              Rules,
              these Official Rules shall prevail, govern and control. All details and other restrictions of the
              prize(s)
              not specified in these Official Rules will be determined by Sponsor in its sole discretion. The Grand
              Prize
              winner shall be solely responsible for all federal, state, local and/or provincial taxes, and the
              reporting
              consequences thereof, and for any other fees or costs associated with the applicable prize. If required
              by
              law, Sponsor reserves the right to withhold and remit to the appropriate taxing authorities the amount
              of
              any tax or taxes due. With respect to non-cash prizes, it may be legally necessary under the United
              States
              Internal Revenue Code (as determined by Sponsor in its sole discretion) for the Grand Prize winner to
              pay
              the amount of any tax before receiving the prize. Each potential winner (or, if the potential winner is
              a
              minor, their parent or legal guardian) may be required to execute an Affidavit of Eligibility, a
              Liability
              Release, and where imposing such condition is legal, a Publicity Release, and any other documents as
              required by Sponsor to deliver the prize (collectively, the
              <b>“Prize Claim Documents”</b>
              ). Sponsor
              reserves
              the
              right to conduct background checks on the potential winner prior to granting any award. Sponsor also
              reserves the right, in its sole and absolute discretion, to disqualify any individual who has been
              charged
              with or convicted of a felony or misdemeanor, is delinquent on child support, spousal support, tax
              payments
              or any other government ordered payment, has or is suspected of failing to comply with any provision of
              these Official Rules or the Terms of Use, including, but not limited to, having made any false
              statements in
              connection with his/her participation in the Contest. Should it be determined that a potential winner
              has
              made a false statement in any document required by Sponsor, Sponsor reserves the right to disqualify the
              potential winner and select an alternate winner. If a potential winner (or a minor potential winner’s
              parent
              or legal guardian) fails or refuses to sign and return all the Prize Claim Documents within five (5)
              days
              after being notified that they have been selected as the potential winner (or a shorter time if required
              by
              exigencies), the potential winner may be disqualified and an alternate winner may be selected.
              <br />
              <br />

              Sponsor makes no warranties, and hereby disclaims any and all warranties, express or implied, concerning
              any
              prize furnished in connection with the Contest. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SUCH
              PRIZES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, AND SPONSOR HEREBY
              DISCLAIMS ALL SUCH WARRANTIES, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE AND/OR NON-INFRINGEMENT.
            </li>
          </ol>

          <ol start="8">
            <li>
              <b>REPRESENTATIONS AND WARRANTIES/INDEMNIFICATION</b>
              : Each person who enters this Contest (and any
              minor
              entrant’s parent or legal guardian) represents and warrants as follows: (i) except as set forth herein,
              the
              Entry is the entrant’s own, previously unpublished, and previously unproduced work; (ii) the Entry does
              not
              contain any computer virus (as applicable), is not otherwise corrupted, is wholly original with entrant
              except to the extent it is based on or otherwise derivative of the Properties, and as of the date of
              submission, is not the subject of any actual or threatened litigation or claim; (iii) the Entry does not
              and
              will not violate or infringe upon the intellectual property rights or other rights of any third party;
              (iv)
              the Entry does not and will not violate any applicable laws, and is not and will not be defamatory or
              libelous; and (v) the use of the Entry as set forth herein will not give rise to any claims for any
              payment
              whatsoever, including, but not limited to, claims for royalties, re-use fees or residuals. Each entrant
              (and
              any minor entrant’s parent or legal guardian) hereby agrees to indemnify and hold the Contest Entities
              harmless from and against any and all third party claims, actions or proceedings of any kind and from
              any
              and all damages, liabilities, costs and expenses relating to or arising out of (x) any breach or alleged
              breach of any of the warranties, representations, covenants, obligations or agreements of entrant
              hereunder,
              and (y) any third party claims made in connection with the entrant’s Entry.
            </li>
          </ol>

          <ol start="9">
            <li>
              <b>
                GRANT OF RIGHTS:
              </b>
            </li>
          </ol>

          <ol type="A">
            <li>
              For good and valuable consideration, the receipt and legal sufficiency of which is hereby
              acknowledged,
              each person who makes an Entry for the Contest (and any minor entrant’s parent or legal guardian) hereby
              irrevocably grants to each of Sponsor and Lionsgate a non-exclusive, royalty-free, worldwide, perpetual,
              fully transferable, sub-licensable right and license to use, copy, modify, create derivative works based
              upon, distribute, publicly display, and publicly perform your Entry for any purpose, in whole or in
              part, in
              edited, altered or modified form, in perpetuity, by means of any and all media and devices whether now
              known
              or hereafter devised including to use entrant’s Entry, without separate compensation to the entrant or
              any
              other person or entity. Each entrant irrevocably waives any and all so-called moral rights they may have
              in
              their Entry to the fullest extent permitted by law.
            </li>
            <li>
              {' '}
              By entering the Contest, each entrant acknowledges and agrees that: (a) the Contest Entities are
              granting
              entrants a limited, revocable, non-exclusive license to use materials from the Properties solely in
              connection with, and solely as a part of, the Contest, (b) entrants shall have no right, title or
              interest
              in the Properties, and (c) any use of the Properties other than as permitted by these Official Rules may
              infringe upon Contest Entities’ intellectual property rights. The rights granted to Sponsor in these
              Official Rules may be freely assigned and licensed by Sponsor in whole or in part to any other person or
              entity.
            </li>
          </ol>

          <ol start="10">
            <li>
              <b>PUBLICITY RELEASE:</b>
              {' '}
              By participating in the Contest, in addition to any other grants which may be
              granted
              in any other agreement entered into between Sponsor and any entrant in and/or winner of the Contest,
              each
              entrant (and any minor entrants parent or legal guardian) hereby irrevocably grants the Contest Entities
              and
              their respective successors, assigns and licensees, the right to use such entrant’s name, photograph,
              likeness, statements, biographical information, and any other personal characteristics, in any and all
              media
              in connection with the Contest, the Entry, and the advertising and promotion thereof, and each entrant
              and/or prize winner hereby releases the Contest Entities from any liability with respect thereto.
            </li>
          </ol>

          <ol start="11">
            <li>
              <b>GENERAL LIABILITY RELEASE/FORCE MAJEURE:</b>
              {' '}
              IN NO EVENT WILL THE CONTEST ENTITIES BE LIABLE FOR ANY
              SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS
              OR
              PROFITS) ARISING OUT OF OR IN CONNECTION WITH YOUR PARTICIPATION IN THE CONTEST OR USE OR MISUSE OF ANY
              PRIZE, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING
              NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WHETHER OR NOT THE CONTEST ENTITIES HAVE BEEN ADVISED OF
              THE
              POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE CONTEST
              ENTITIES’
              AGGREGATE LIABILITY TO YOU ARISING WITH RESPECT TO THESE OFFICIAL RULES WILL NOT EXCEED $5.00. Some
              jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential
              damages,
              so the above limitation or exclusion may not apply. The Contest Entities assume no responsibility for
              any
              damage to an entrant’s, or any other person’s, computer system which is occasioned by accessing the
              Website,
              the App or otherwise participating in the Contest, or for any computer system, phone line, hardware,
              software or program malfunctions, or other errors, failures, delayed computer transmissions or network
              connections that are human or technical in nature. Without limiting the generality of the foregoing, The
              Contest Entities are not responsible for incomplete, illegible, misdirected, misprinted, late, lost,
              damaged, stolen, or postage-due prize notifications; or for lost, interrupted, inaccessible or
              unavailable
              networks, servers, satellites, Internet service providers, websites, or other connections; or for
              miscommunications, failed, jumbled, scrambled, delayed, or misdirected computer, telephone or cable
              transmissions; or for any technical malfunctions, failures, difficulties or other errors of any kind or
              nature; or for the incorrect or inaccurate capture of information, or the failure to capture any
              information. Sponsor reserves the right in its sole discretion to disqualify any individual who is found
              to
              be tampering with the entry process or the operation of the Contest, the Website, the App, or to be
              acting
              in violation of these Official Rules, or to be acting in an unsportsmanlike or disruptive manner, or
              with
              the intent to disrupt or undermine the legitimate operation of the Contest, or to annoy, abuse, threaten
              or
              harass any other person, and Sponsor reserves the right to seek damages and other remedies from any such
              person to the fullest extent permitted by law. No mechanically reproduced, illegible, incomplete,
              forged,
              software-generated or other automated multiple Entries will be accepted. Sponsor reserves the right to
              modify, extend, suspend, or terminate the Contest if it determines, in its sole discretion, that the
              Contest
              is technically impaired or corrupted or that fraud or technical problems, failures or malfunctions or
              other
              causes beyond Sponsor’s control have destroyed or severely undermined or to any degree impaired the
              integrity, administration, security, proper play and/or feasibility of the Contest as contemplated
              herein.
              In the event an insufficient number of eligible Entries are received and/or Sponsor is prevented from
              awarding the Grand Prize or continuing with the Contest as contemplated herein by any event beyond its
              control, including but not limited to fire, flood, natural or man-made epidemic of health of other
              means,
              earthquake, explosion, labor dispute or strike, act of God or public enemy, satellite or equipment
              failure,
              riot or civil disturbance, terrorist threat or activity, war (declared or undeclared) or any federal
              state
              or local government law, order, or regulation, public health crisis, order of any court or jurisdiction,
              or
              other cause not reasonably within Sponsor’s control (each a
              <b>“Force Majeure”</b>
              {' '}
              event or occurrence),
              then
              subject to any governmental approval which may be required, Sponsor shall have the right to modify,
              suspend,
              extend or terminate the Contest. Only the type and quantity of prizes described in these Official Rules
              will
              be awarded. Except as otherwise set forth herein, these Official Rules cannot be modified or amended in
              any
              way except in a written document issued in accordance with law by a duly authorized representative of
              Sponsor. The invalidity or unenforceability of any provision of these Official Rules shall not affect
              the
              validity or enforceability of any other provision. In the event that any provision in these Official
              Rules
              is determined to be invalid or otherwise unenforceable or illegal, these Official Rules shall otherwise
              remain in effect and shall be construed in accordance with their terms as if the invalid or illegal
              provision were not contained herein.
            </li>
          </ol>

          <ol start="12">
            <li>
              <b> NATURE OF RELATIONSHIP/WAIVER OF EQUITABLE RELIEF:</b>
              {' '}
              Each entrant (and any minor entrant’s parent
              or legal
              guardian) hereby acknowledges and agrees that the relationship between the entrant and the Contest
              Entities
              is not a confidential, fiduciary, or other special relationship, and that the entrant’s decision to
              provide
              the entrant’s Entry to Sponsor for purposes of the Contest does not place the Contest Entities in a
              position
              that is any different from the position held by members of the general public with regard to elements of
              the
              entrant’s Entry. Each entrant understands and acknowledges that the Contest Entities have wide access to
              ideas, stories, designs, and other literary materials, and that new ideas are constantly being submitted
              to
              it or being developed by their own employees. Each entrant also acknowledges that many ideas or stories
              may
              be competitive with, similar or identical to the Entry and/or each other in theme, idea, plot, format or
              other respects. Each entrant acknowledges and agrees that such entrant will not be entitled to any
              compensation as a result of any Contest Entity’s use of any such similar or identical material. Each
              entrant
              acknowledges and agrees that the Contest Entities do not now and shall not have in the future any duty
              or
              liability, direct or indirect, vicarious, contributory, or otherwise, with respect to the infringement
              or
              protection of the copyright in and to the Entry. Finally, each entrant acknowledges that, with respect
              to
              any claim by entrant relating to or arising out of a Contest Entity’s actual or alleged exploitation or
              use
              of any Entry or other material submitted in connection with the Contest, the damage, if any, thereby
              caused
              to the applicable entrant will not be irreparable or otherwise sufficient to entitle such entrant to
              seek
              injunctive or other equitable relief or in any way enjoin the production, distribution, exhibition or
              other
              exploitation of any of the Entries, any part of the Properties or any other production based on or
              allegedly
              based on the Entry, and entrant’s rights and remedies in any such event shall be strictly limited to the
              right to recover damages, if any, in an action at law.
            </li>
          </ol>

          <ol start="13">
            <li>
              <b>NO OBLIGATION TO USE:</b>
              {' '}
              Sponsor shall have no obligation (express or implied) to use any Entry or
              to
              otherwise exploit any Entry, or if commenced, to continue the distribution or exploitation thereof, and
              Sponsor may at any time abandon the use of the Entry for any reason, with or without legal justification
              or
              excuse, and entrants shall not be entitled to any damages or other relief by reason thereof.
            </li>
          </ol>

          <ol start="14">
            <li>
              <b>DATES & DEADLINES/ANTICIPATED NUMBER OF CONTESTANTS:</b>
              {' '}
              Because of the unique nature and scope of
              the
              Contest, Sponsor reserves the right, in addition to those other rights reserved herein, to extend any
              date(s) or deadline(s) set forth in these Official Rules or otherwise governing the Contest. Sponsor
              cannot
              accurately predict the number of entrants who will participate in the Contest.
            </li>
          </ol>

          <ol start="15">
            <li>
              <b>FURTHER DOCUMENTATION:</b>
              {' '}
              If Sponsor shall desire to secure additional certificates of engagement
              for any
              Entry or other documents as Sponsor may reasonably require in order to evidence or effectuate the rights
              granted in these Official Rules, then each entrant agrees to sign, authenticate and deliver the same
              upon
              Sponsor’s request therefor.
            </li>
          </ol>

          <ol start="16">
            <li>
              <b>GOVERNING LAW/JURISDICTION:</b>
              {' '}
              ALL ISSUES AND QUESTIONS CONCERNING THE CONSTRUCTION, VALIDITY,
              INTERPRETATION AND ENFORCEABILITY OF THESE OFFICIAL RULES OR THE RIGHTS AND OBLIGATIONS OF ENTRANTS OR
              SPONSOR IN CONNECTION WITH THE CONTEST SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE LAWS OF
              THE
              UNITED STATES OF AMERICA AND THE INTERNAL LAWS OF THE STATE OF CALIFORNIA WITHOUT GIVING EFFECT TO ANY
              CHOICE OF LAW OR CONFLICT OF LAW RULES OR PROVISIONS THAT WOULD CAUSE THE APPLICATION OF ANY OTHER
              STATE’S
              LAWS.
            </li>
          </ol>

          <ol start="17">
            <li>
              <b>ARBITRATION PROVISION for US entrants only:</b>
            </li>
          </ol>

          <ol type="A">
            <li>
              Sponsor and each entrant hereby agree that any dispute, claim or controversy arising out of or
              relating to
              these Official Rules or the breach, termination, enforcement, interpretation or validity thereof
              (collectively,
              <b>"Disputes"</b>
              ) will be resolved solely by confidential, binding, individual
              arbitration and
              not
              in a class, representative or consolidated action or proceeding. You and Sponsor agree that the U.S.
              Federal
              Arbitration Act governs the interpretation and enforcement of these Official Rules, and that you and
              Sponsor
              are each waiving the right to a trial by jury or to participate in a class action. This arbitration
              provision shall survive termination or conclusion of the Contest.
            </li>
            <li>
              <b>Exceptions.</b>
              {' '}
              As limited exceptions to Section 17(A) above: (i) we both may seek to resolve a
              Dispute in
              small claims court if it qualifies; and (ii) we each retain the right to seek injunctive or other
              equitable
              relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual
              property
              rights.
            </li>
            <li>
              <b>Conducting Arbitration and Arbitration Rules.</b>
              {' '}
              The arbitration will be conducted by the American
              Arbitration Association ("AAA") under its Consumer Arbitration Rules (the
              {' '}
              <b>"AAA Rules"</b>
              ) then in
              effect,
              except as modified by these Official Rules. The AAA Rules are available at
              {' '}
              <a
                href="http://www.adr.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.adr.org
              </a>
              {' '}
              or
              by calling
              1-800-778-7879. A party who wishes to start arbitration must submit a written Demand for Arbitration to
              AAA
              and give notice to the other party as specified in the AAA Rules. The AAA provides a form Demand for
              Arbitration at
              {' '}
              <a
                href="http://www.adr.org"
                rel="noopener noreferrer"
                target="_blank"
              >
                www.adr.org
              </a>
              .
              <br />
              <br />

              Any arbitration hearings will take place in Los Angeles County, unless we both agree to a different
              location. Sponsor and each entrant agree that the arbitrator shall have exclusive authority to decide
              all
              issues relating to the interpretation, applicability, enforceability and scope of these Official Rules.
            </li>
            <li>
              <b>Arbitration Costs.</b>
              {' '}
              Payment of all filing, administration and arbitrator fees will be governed
              by the AAA
              Rules, and we won’t seek to recover the administration and arbitrator fees we are responsible for
              paying,
              unless the arbitrator finds your Dispute frivolous. If we prevail in arbitration, we’ll pay all of our
              attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration, you
              will
              be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.
            </li>
            <li>
              <b>Injunctive and Declaratory Relief.</b>
              {' '}
              Except as provided in Section 17(B) above, the arbitrator
              shall
              determine all issues of liability on the merits of any claim asserted by either party and may award
              declaratory or injunctive relief only in favor of the individual party seeking relief and only to the
              extent
              necessary to provide relief warranted by that party’s individual claim. To the extent that you or we
              prevail
              on a claim and seek public injunctive relief (that is, injunctive relief that has the primary purpose
              and
              effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and
              extent of such relief must be litigated in a civil court of competent jurisdiction and not in
              arbitration.
              The parties agree that litigation of any issues of public injunctive relief shall be stayed pending the
              outcome of the merits of any individual claims in arbitration.
            </li>
            <li>
              <b>Class Action Waiver.</b>
              {' '}
              YOU AND SPONSOR AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN
              YOUR OR
              ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not
              consolidate another person’s claims with your claims, and may not otherwise preside over any form of a
              representative or class proceeding. If this specific provision is found to be unenforceable, then the
              entirety of this Dispute Resolution section shall be null and void.
            </li>
            <li>
              <b>Severability.</b>
              {' '}
              With the exception of any of the provisions in Section 17(F) of these Official
              Rules (
              <b>
                "Class Action Waiver"
              </b>
              ), if an arbitrator or court of competent jurisdiction decides that any part of
              these Official
              Rules is invalid or unenforceable, the other parts of these Official Rules will still apply.
            </li>
          </ol>

          <ol start="18">
            <li>
              <b>WINNER’S LIST/OFFICIAL RULES:</b>
              {' '}
              To obtain a copy of any legally-required winners list, send a
              self-addressed stamped envelope to: “Blair Witch Fest” Contest Winners List, Dorian Inc., 340 S. Lemon
              Ave.
              #5571, Walnut, CA 91789-2706. All such requests must be received within six (6) weeks after the end of
              the
              Promotion Period. These Official Rules will be posted on the Website during the Promotion Period.
            </li>
          </ol>

          <ol start="19">
            <li>
              <b>DATA COLLECTION:</b>
              {' '}
              Sponsor collects personal information from you when you enter the Contest. See
              Sponsor's privacy policy at
              {' '}
              <a
                href="https://dorian.live/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://dorian.live/privacy-policy
              </a>
              {' '}
              for information about how personal
              information may be used. By participating in the Contest, you hereby agree that Sponsor may share your
              information with Lionsgate, which will use such information in accordance with its independent privacy
              policy found at
              {' '}
              <a
                href="http://www.lionsgate.com/corporate/privacy/"
                rel="noopener noreferrer"
                target="_blank"
              >
                http://www.lionsgate.com/corporate/privacy/
              </a>
              .
            </li>
          </ol>

          <ol start="20">
            <li>
              <b>SPONSOR:</b>
              {' '}
              The Sponsor of the Contest is Dorian Inc., 340 S. Lemon Ave. #5571, Walnut, CA
              91789-2706.
              Lionsgate is not responsible for the administration of the Contest, the collection of Entries, or prize
              fulfillment.
            </li>
          </ol>

          <p>
            The Properties and all associated logos, characters, and elements are ®, TM & © 2021 Lions Gate
            Entertainment Inc. All Rights Reserved.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={handleSubmit}
        >
          {loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          )}
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
