import React, { HTMLProps, ReactNode } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

interface NumberFieldProps extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'onChange'> {
    label: ReactNode
    name: string
    value: number
    onChange: (event: React.ChangeEvent) => void
    placeholder?: string
    error?: string
}

export function NumberField(props: NumberFieldProps) {
  const {
    label,
    name,
    value,
    onChange,
    error,
    placeholder,
  } = props;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="number"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!error}
          placeholder={placeholder}
        />
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
