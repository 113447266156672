import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';

export function MenuSettings(props) {
  const { settingsItems, showSettings } = props;
  if (!showSettings) {
    return null;
  }

  const user = JSON.parse(localStorage.getItem('user'));

  const list = {
    branches: [],
    admin: [
      { href: '/users', title: 'Users' },
      user.adminShowApproved ? { href: '/usersnotapproved', title: 'Not Approved Users' } : null,
      { href: '/mail-templates', title: 'eMail Templates' },
      { href: '/preferences', title: 'Preferences' },
      /* {href: "/prompts", title: "Story Prompts",}, */
      /* {href: "/genres", title: "Genres",}, */
      { href: '/textlimits', title: 'Text Limits' },
      /* {href: '/beta-signup-limits', title: 'Beta Signup Limits',}, */
      {
        title: 'Answer parameters',
        drop: 'left',
        items: [
          !window.$traits ? { href: '/stats', title: 'Traits' } : null,
          { href: '/tags', title: 'Tags' },
          { href: '/modifiers', title: 'Modifiers' },
          { href: '/answertypes', title: 'Types' },
        ],
      },
      {
        title: 'Character parameters',
        drop: 'left',
        items: [
          { href: '/expressions', title: 'Expressions' },
        ],
      },
      { href: '/backgrounds', title: 'Backgrounds' },
      { href: '/character-art-library', title: 'Character Art Library' },
      { href: '/backgroundtags', title: 'Background Tags' },
      { href: '/maintenance', title: 'Maintenance' },
      {
        title: 'Episode parameters',
        drop: 'left',
        items: [
          /* {href: "/storytags", title: "Episode Tags",}, */
          { href: '/chapter-requirements', title: 'Episode Requirements' },
        ],
      },
      /* {href: "/signupcodes", title: "Sign Up Codes",},
       {href: "/signupcodesrequests", title: "Code Requests",}, */
      { href: '/avatarassets', title: 'Avatar Assets' },
      { href: '/global-invitation-status', title: 'Global Invitation Status' },
      { href: '/featured-stories', title: 'Featured Stories' },
      { href: '/recently-uploaded', title: 'Recently Uploaded' },
      { href: '/client-variables', title: 'Client Variables Panel' },
      { href: '/events', title: 'Events' },
      { href: '/admin-support-panel', title: 'Admin support panel' },
    ],
  };

  return (
    <NavDropdown title="Settings" drop="down" id="SettingsNavDropdown">
      {list[showSettings].map((obj, i) => {
        if (!obj) {
          return null;
        }

        return obj.items ? (
        // eslint-disable-next-line react/no-array-index-key
          <NavDropdown key={i} title={obj.title} drop={obj.drop} id={`SettingsNavDropdown-${i}`}>
            {obj.items.map((object, j) => {
              if (object) {
                return (
                  <NavDropdown.Item
                        // eslint-disable-next-line react/no-array-index-key
                    key={j}
                    to={object.href}
                    as={RouterLink}
                  >
                    {object.title}
                  </NavDropdown.Item>
                );
              }
              return null;
            })}
          </NavDropdown>
        ) : (
          <NavDropdown.Item
                  // eslint-disable-next-line react/no-array-index-key
            key={i}
            to={obj.href}
            as={RouterLink}
          >
            {obj.title}
          </NavDropdown.Item>
        );
      })}

      {settingsItems && settingsItems.map((obj, i) => (obj.action ? (
        <NavDropdown.Item
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          disabled={obj.disabled === true}
          onClick={obj.action}
        >
          {obj.title}
        </NavDropdown.Item>
      ) : (
        <NavDropdown.Item
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          to={obj.href}
          as={RouterLink}
        >
          {obj.title}
        </NavDropdown.Item>
      )))}
    </NavDropdown>
  );
}
