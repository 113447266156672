import async from 'async';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { AvatarCanvasWithCustomCharacterSupport } from '../../../ui/AvatarTool/AvatarCanvasWithCustomCharacterSupport';
import { CoverBgImg } from './CoverBgImg';

export class CoverEditor extends Component {
  constructor(...args) {
    super(...args);
    const { obj } = this.props;

    this.state = {
      validated: false,
      formError: null,
      characters: [],
      expression: [],
      locations: [],
      saveLoading: false,
      coverCharacterId: '',
      coverCharacterExpressionId: '',
      coverLocationId: obj.story.coverLocationId || '',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { obj } = this.props;
    const { coverCharacterExpressionId } = this.state;

    async.parallel({
      locations: (callback) => {
        api.get(`/v1/books/${obj.story.book.id}/locations`)
          .then((res) => {
            callback(null, res.data.locations);
          }).catch((error) => {
            callback(error, null);
          });
      },
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      characters: (callback) => {
        api.get(`/v1/books/${obj.story.book.id}/characters`)
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (!err) {
          let chars = res.characters.characters.map((character) => {
            if (character.properties) {
              // eslint-disable-next-line no-param-reassign,max-len
              character.avatar = { properties: character.properties, imageName: character.imageName };
              // eslint-disable-next-line no-param-reassign
              character.avatar.alias = character.imageAlias;
              // eslint-disable-next-line no-param-reassign
              character.avatar.book_uuid = obj.story.book.uuid;
            }
            return character;
          });
          // chars = chars.filter(el => el.isPlayer === false);
          chars = chars.filter((el) => el.type !== 'custom');
          this.setState({
            characters: chars,
            expression: res.expressions,
            locations: res.locations,
          });

          if (!coverCharacterExpressionId) {
            const defExpression = res.expressions.find((ex) => ex.def === true);
            this.setState({
              coverCharacterExpressionId: defExpression ? defExpression.id : '',
            });
          }
        }
      } catch (e) {
        // TODO: report to sentry
      }
    });
  };

  locationOptions() {
    const { locations } = this.state;

    if (locations.length > 0) {
      const res = [<option value="" key="def">Select Location</option>];
      // eslint-disable-next-line max-len,react/no-array-index-key
      res.push(...locations.map((object, i) => <option value={object.id} key={i}>{object.title}</option>));
      return res;
    }
    return <option disabled value="">No Locations</option>;
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.saveData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  saveData(data, validated) {
    const { obj, update, onHide } = this.props;

    if (validated === true) {
      this.setState({
        saveLoading: true,
      });
      api.put(`/v1/stories/${obj.story.id}`, data)
        .then(() => {
          this.setState({
            saveLoading: false,
          }, () => {
            update();
            onHide();
          });
        })
        .catch(() => {
          this.setState({
            saveLoading: false,
          });
        });
    }
  }

  propsCanvas = () => {
    const {
      coverCharacterId,
      characters,
      coverCharacterExpressionId,
      expression: expressions,
    } = this.state;

    if (!coverCharacterId || characters.length < 1) {
      return null;
    }
    const character = characters.find((obj) => Number(obj.id) === Number(coverCharacterId));
    // eslint-disable-next-line max-len
    const characterProperties = !character || !character.properties || character.properties.length < 0 ? null : { ...character.properties };
    if (coverCharacterExpressionId && characterProperties) {
      // eslint-disable-next-line max-len
      const expression = expressions.find((obj) => Number(obj.id) === Number(coverCharacterExpressionId));
      if (expression.value) {
        characterProperties.expression = expression.value;
      }
    }
    return characterProperties;
  };

  render() {
    const {
      onHide, obj, viewOnly, className,
    } = this.props;
    const {
      validated, saveLoading, coverLocationId, formError,
    } = this.state;

    const propsCanvas = this.propsCanvas();
    return (
      <Modal
        className={className || ''}
        onHide={onHide}
        show={obj.editCoverActive}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Episode Cover</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Col className="coverEditor-imgBox">
                <CoverBgImg obj={this.state} />
                {propsCanvas && <AvatarCanvasWithCustomCharacterSupport properties={propsCanvas} />}
              </Col>
              <Col>
                <Row>
                  <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
                    <Alert variant="danger">
                      {formError}
                    </Alert>
                  </Col>
                </Row>

                <Row>
                  <Form.Group
                    as={Col}
                    controlId="coverLocationId"
                  >
                    <Form.Label>Background</Form.Label>
                    <Form.Control
                      required
                      disabled={viewOnly}
                      size="sm"
                      as="select"
                      name="coverLocationId"
                      value={coverLocationId || ''}
                      onChange={(e) => {
                        this.setState({
                          coverLocationId: e.target.value,
                        });
                      }}
                    >
                      {this.locationOptions()}
                    </Form.Control>
                  </Form.Group>
                </Row>

              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              {viewOnly ? 'Close' : 'Cancel'}
            </Button>
            <Button
              type="submit"
              variant="primary"
              className={viewOnly ? 'd-none' : null}
            >
              {saveLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
