import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Spinner,
} from 'react-bootstrap';
import { UserAction, getActionLabelByAction } from '../../../dorian-shared/types/user/User';
import { api } from '../../api';
// eslint-disable-next-line import/no-cycle
import { getUserName } from '.';

export function UsersAdditionalAccess(props) {
  const { data } = props;
  const actions = data?.actions || [];
  const userActions = Object.values(UserAction);

  return (
    <Form.Row>
      <Form.Group as={Col} md="12" controlId="Email">
        <Form.Label>Users Additional Access</Form.Label>
        <Form.Row>
          {
            userActions.map((action) => (
              <Form.Group as={Col} controlId={action} key={action}>
                <Form.Check
                  custom
                  type="checkbox"
                  label={getActionLabelByAction(action)}
                  name={`actions[${action}]`}
                  defaultChecked={actions.includes(action) || false}
                />
              </Form.Group>
            ))
          }
        </Form.Row>
      </Form.Group>
    </Form.Row>
  );
}

export class AddUser extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
      password: '',
      adding: false,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    obj.actions = obj.actions ? Object.keys(obj.actions) : [];
    obj.showAnalytic = !(!obj.showAnalytic || obj.showAnalytic !== 'on');
    obj.isVerified = !(!obj.isVerified || obj.isVerified !== 'on');
    obj.monetized = !(!obj.monetized || obj.monetized !== 'on');
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addUser(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  handlePassValidation = () => {
    const { password } = this.state;
    const lowercase = password.match(/[a-z]+/g);
    const uppercase = password.match(/[A-Z]+/g);
    const digits = password.match(/[\d]+/g);
    const lenghtPas = password.match(/[A-Za-z\d]{8,}/g);
    if (password === '') {
      return (<>Password is required</>);
    }
    if (lowercase === null) {
      return <>Password must include at least one lowercase letter</>;
    }
    if (uppercase === null) {
      return <>Password must include at least one uppercase letter</>;
    }
    if (digits === null) {
      return <>Password must include at least one digit from 0 to 9</>;
    }
    if (lenghtPas === null) {
      return <>Password must include at least 8 characters</>;
    }
    return null;
  };

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addUser(value, validated) {
    const { update, data } = this.props;

    if (validated === true) {
      // eslint-disable-next-line no-param-reassign
      value.email = value.email && value.email.trim().toLowerCase();
      this.setState({ adding: true });
      if (data !== null) {
        api.put(`/v1/users/${data.id}`, value)
          .then(() => {
            update();
            this.setState({ adding: false });
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
            this.setState({ adding: false });
          });
      } else {
        api.post('/v1/users', value)
          .then(() => {
            update();
            this.setState({ adding: false });
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
            this.setState({ adding: false });
          });
      }
    }
  }

  render() {
    const {
      validated, password, adding, formError,
    } = this.state;
    const { onHide, data } = this.props;

    let title = 'Add New User';
    let defUsername = '';
    let defFirstName = '';
    let defLastName = '';
    let defRole = 'basic_user';
    let defEmail = '';
    if (data !== null) {
      title = `Edit User: "${getUserName(data)}"`;
      defUsername = data.username;
      defRole = data.role;
      defFirstName = data.firstName;
      defLastName = data.lastName;
      defEmail = data.email;
    }

    const { update, ...other } = this.props;

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
          autoComplete="off"
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} md="5" controlId="Username">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  disabled={(data)}
                  type="text"
                  placeholder="Name"
                  defaultValue={defUsername}
                  pattern="^([A-Za-z]|[0-9]|_|-| )+$"
                  name="username"
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="2" controlId="SelectRole">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="role"
                  defaultValue={defRole}
                >
                  <option value="basic_user">basic_user</option>
                  <option value="admin">admin</option>
                  <option value="user" disabled>user</option>
                  <option value="deactivated" disabled>deactivated</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md="5" controlId="Password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required={(!data)}
                  type="password"
                  placeholder="Password"
                  pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                  name="password"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => {
                    this.setState({
                      password: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {this.handlePassValidation()}
                </Form.Control.Feedback>
              </Form.Group>

            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="First Name"
                  defaultValue={defFirstName}
                  name="firstName"
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Last Name"
                  defaultValue={defLastName}
                  name="lastName"
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a name.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md="12" controlId="Email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  disabled={(data)}
                  type="email"
                  placeholder="Email"
                  defaultValue={defEmail}
                  name="email"
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a email.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="isVerified">
                <Form.Check
                  custom
                  type="checkbox"
                  label="Verified Creator"
                  name="isVerified"
                  defaultChecked={(data && data.isVerified) || false}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="showAnalytic">
                <Form.Check
                  custom
                  type="checkbox"
                  label="Show analytics"
                  name="showAnalytic"
                  defaultChecked={data && ('showAnalytic' in data) ? data.showAnalytic : true}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="Monetized">
                <Form.Check
                  custom
                  type="checkbox"
                  label="Monetized"
                  name="monetized"
                  defaultChecked={data && ('monetized' in data) ? data.monetized : true}
                />
              </Form.Group>
            </Form.Row>

            <UsersAdditionalAccess
              {...this.props}
            />

          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
              disabled={adding}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={adding}
            >
              {adding ? (
                <Spinner
                  variant="secondary"
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}

              Save
            </Button>

          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
