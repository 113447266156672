import React from 'react';
import {
  Col, Form, ListGroup, Row,
} from 'react-bootstrap';

type BackgroundListHeaderProps = {
  checked: boolean;
  handleCheck: (checked: boolean) => void;
}

export function CharacterListHeader(props: BackgroundListHeaderProps) {
  const { handleCheck, checked } = props;

  return (
    <ListGroup.Item
      as="div"
      key="characters-header"
    >
      <Row className="align-items-center">
        <Col sm="auto">
          <Form.Check
            custom
            id="characters-check-all"
            checked={checked}
            onChange={(e) => {
              handleCheck?.(e.target.checked);
            }}
          />
        </Col>
        <Col sm="auto" className="pr-2">
          Image
        </Col>
        <Col>
          Name
        </Col>
        <Col sm={2} className="text-right px-0">
          Actions
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
