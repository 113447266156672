import classnames from 'classnames';
import React from 'react';
import './Pagination.scss';
import { DOTS, usePagination } from './usePagination';

interface PaginationProps {
  onPageChange: (newPage: number) => void,
  totalCount: number
  currentPage: number
  pageSize: number
  className?: string
  nearbyCount?: number
}

export function Pagination(props: PaginationProps) {
  const {
    onPageChange,
    totalCount,
    currentPage,
    pageSize,
    className,
    nearbyCount = 1,
  } = props;

  const paginationRange = usePagination(
    currentPage,
    totalCount,
    nearbyCount,
    pageSize,
  );

  if (currentPage === 0 || totalCount === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', className)}
    >
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" data-testid="leftArrow" />
      </li>
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          // eslint-disable-next-line react/no-array-index-key
          return <li key={`dots-${idx}`} className="pagination-item dots">&#8230;</li>;
        }

        return (
          // eslint-disable-next-line max-len
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
          <li
            key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" data-testid="rightArrow" />
      </li>
    </ul>
  );
}
