import React from 'react';
import { FILTER_TYPES, customFilter, textFilter } from 'react-bootstrap-table2-filter';
import { FormatDate } from '../../../Book/FormatDate';
import { DateFilter } from '../DateFilter';
import { PositiveNumberFilter } from '../PositiveNumberFilter';
import { bookTitleFormatter, storyTitleFormatter } from '../tableFormatters';

export const completionColumns = [{
  dataField: 'bookTitle',
  text: 'Story',
  formatter: bookTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'title',
  text: 'Episode',
  formatter: storyTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'revision',
  text: 'Version',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'createdAt',
  text: 'Released',
  sort: true,
  formatter: (cell) => <FormatDate className="d-inline-block p-1" date={cell} />,
  filter: customFilter({
    type: FILTER_TYPES.DATE,
  }),
  filterRenderer: (onFilter, column) => (
    <DateFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'users',
  text: 'Users',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'dropoff',
  text: 'Completion Rate %',
  sort: true,
  formatter: (cell) => {
    const val = cell || 0;
    const ret = Number.isInteger(val) ? val : val.toFixed(2);
    // eslint-disable-next-line no-nested-ternary
    return `${(100 - (ret < 0 ? 0 : ret > 100 ? 100 : ret)).toFixed(2)}%`;
  },
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}];

export const completionHeaders = [
  { label: 'Story', key: 'bookTitle' },
  { label: 'Episode', key: 'title' },
  { label: 'Version', key: 'revision' },
  { label: 'Released', key: 'createdAt' },
  { label: 'Users', key: 'users' },
  { label: 'Completion Rate %', key: 'dropoff' },
];
