import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import linkIcon from '../../../../assets/images/link.svg';
import { StepTypeMode } from '../../../../dorian-shared/types/branch/Branch';
import { api } from '../../../api';
import { ConnectionModal } from './ConnectionModal';

export class ConnectionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnectionModalShown: false,
    };
  }

  setFromEvent = (e) => {
    const {
      branchNode,
      mainContent,
      branchesScale,
      savePositionLoading,
    } = this.props;

    const boundingClientRect = mainContent.current.getBoundingClientRect();
    const fromElm = document.getElementById(`branches_${branchNode.id}`);
    const wf = fromElm.clientWidth / 2;
    const hf = fromElm.clientHeight / 2;

    const styleStart = {
      left: `${branchNode.x + wf}px`,
      top: `${branchNode.y + hf}px`,
    };

    // eslint-disable-next-line max-len
    const dy = (e.clientY - 60 + mainContent.current.scrollTop) - ((fromElm.offsetTop * branchesScale) + (hf * branchesScale));
    // eslint-disable-next-line max-len
    const dx = (e.clientX - boundingClientRect.x + mainContent.current.scrollLeft) - ((fromElm.offsetLeft * branchesScale) + (wf * branchesScale));
    const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
    const length = Math.sqrt(dx * dx + dy * dy);

    const styleLine = {
      position: 'absolute',
      left: `${branchNode.x + wf}px`,
      top: `${branchNode.y + hf}px`,
      width: `${length / branchesScale - 10}px`,
      transform: `rotate(${angle}deg)`,
      transformOrigin: '0 0',
    };
    if (!savePositionLoading) {
      ReactDOM.render(
        <>
          <div style={styleStart} id="bolInitConnection" className={branchNode.type} />
          <div style={styleLine} id="lineConnection" className={branchNode.type} />
        </>,
        document.getElementById('boxConnection'),
      );
    }
  };

  escFunction = (event) => {
    const { updateConnection } = this.props;

    if (event.keyCode === 27) {
      updateConnection(null, null);
      window.removeEventListener('mousemove', this.setFromEvent, true);
    }
  };

  arrowToMousePosition = (isDrawArrow) => {
    if (isDrawArrow) {
      window.addEventListener('keydown', this.escFunction, true);
      return window.addEventListener('mousemove', this.setFromEvent, true);
    }
    ReactDOM.render(null, document.getElementById('boxConnection'));
    window.removeEventListener('keydown', this.escFunction, true);
    return window.removeEventListener('mousemove', this.setFromEvent, true);
  };

  componentDidUpdate(prevProps) {
    const {
      branchNode,
      sourceNodeId,
      destinationNodeId,
      updateConnection,
    } = this.props;

    const isSourceNode = branchNode.id === sourceNodeId;
    const isDestinationNode = branchNode.id === destinationNodeId;

    if (sourceNodeId !== prevProps.sourceNodeId) {
      this.arrowToMousePosition(isSourceNode);
    }

    if (prevProps.destinationNodeId !== destinationNodeId) {
      if (!destinationNodeId) {
        updateConnection(null, null);
        return;
      }

      if (isSourceNode && !isDestinationNode) {
        if (branchNode.type === 'choice' && branchNode.stepTypeMode !== StepTypeMode.Elimination) {
          this.arrowToMousePosition(false);
          this.setState({ isConnectionModalShown: true });
          return;
        }
        this.updateData();
      }
    }
  }

  updateData = () => {
    const {
      updateConnection,
      branchNode,
      destinationNodeId,
      update,
      storyId,
      updateLoading,
    } = this.props;

    if (destinationNodeId === branchNode.id) {
      return;
    }
    const arr = { gotoBranchId: destinationNodeId };
    updateLoading(true);
    window.removeEventListener('mousemove', this.setFromEvent, true);
    api.patch(`/v1/stories/${storyId}/branches/${branchNode.id}`, arr)
      .then(() => {
        update();
        updateLoading(false);
      })
      .catch(() => {
        updateConnection(null, null);
        updateLoading(false);
      });
  };

  render() {
    const {
      branchNode,
      updateConnection,
      disabled,
      sourceNodeId,
      storyId,
      update,
      destinationNodeId,
    } = this.props;

    const {
      isConnectionModalShown,
    } = this.state;

    const isConnectionButtonDisabled = disabled
        || branchNode.type === 'ending'
        || sourceNodeId;

    return (
      <>
        {isConnectionModalShown && (
          <ConnectionModal
            show
            storyId={storyId}
            branchNode={branchNode}
            destinationNodeId={destinationNodeId}
            update={update}
            onHide={() => {
              this.setState({ isConnectionModalShown: false });
              updateConnection(null, null);
            }}
          />
        )}
        <Button
          variant="outline-dark"
          size="sm"
          className="mx-1 ConnectionButton"
          disabled={isConnectionButtonDisabled}
          onClick={() => updateConnection(branchNode.id, null)}
        >
          <img
            src={linkIcon}
            className="ConnectionButtonImg"
            alt="linkIcon"
          />
        </Button>
      </>
    );
  }
}
