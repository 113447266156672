import { NavHistoryObject } from '@dorian/creation-tools-ui';
import { StepTypes } from '../../../../dorian-shared/types/branch/BranchStep';
import { ChoiceMemory } from '../../../../services/choiceMemory/ChoiceMemory';

export function onGoBackStack(
  choiceMemory: ChoiceMemory | undefined,
  historyItem: NavHistoryObject,
): number | 'goAhead' | 'goBack' | undefined {
  if (!choiceMemory) {
    return undefined;
  }
  choiceMemory.pop();
  const currentStackItem = choiceMemory.last;
  if (currentStackItem?.variables) {
    choiceMemory.setCurrentVariables(currentStackItem.variables);
  }
  const isSameStep = currentStackItem
      && Number(historyItem.branchId) === Number(currentStackItem.step?.branchId)
      && Number(historyItem.episodeId) === Number(currentStackItem.episodeId)
      && Number(historyItem.stepNum) === Number(currentStackItem.stepNum);
  if (isSameStep
      && (currentStackItem?.step.stepTypeId === StepTypes.Remember
          || currentStackItem?.step.stepTypeId === StepTypes.Check)) {
    return 'goBack';
  }
  return undefined;
}
