import React, { useState } from 'react';
import {
  Button, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import imgEye from '../../../assets/images/eye.svg';
import { useShowModal } from '../../../hooks/useShowModal';
import { api } from '../../api';
import { SelectAsyncPaginate } from '../../ui/SelectAsyncPaginate/SelectAsyncPaginate';

const itemsPerPage = 10;

export function ChangeUser(props) {
  const { customCharacter } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [userIdToReassign, setUserIdToReassign] = useState(null);
  const [isReassign, setIsReassign] = useState(false);
  const { isModalShown, showModal, hideModal } = useShowModal(false);

  const handleSaveClick = () => {
    setIsLoading(false);
    const params = {
      reassignImage: isReassign,
      userId: userIdToReassign,
    };
    api.put(`/v1/customcharacters/${customCharacter.id}`, params)
      .then(() => {
        setIsLoading(false);
        const { update } = props;
        if (update) {
          update();
        }
        hideModal();
      })
      .finally(() => setIsLoading(false));
  };

  const handleSelectUserSearch = async (searchString, page = 1) => {
    const params = {
      search: searchString,
      limit: itemsPerPage,
      offset: itemsPerPage * (page - 1),
    };
    const { data } = await api.get('/v1/users', { params });
    return { options: data.users, totalCount: data.totalCount };
  };

  return (
    <>
      <Button
        onClick={showModal}
        variant="secondary"
        size="sm"
        className="mx-1"
      >
        <img src={imgEye} className="btnImg" alt="Delete" />
      </Button>
      {isModalShown && (
      <Modal
        show
        className="deleteModal"
        onHide={hideModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Reassign or Copy Character:
            {customCharacter.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <SelectAsyncPaginate
              placeholder="Find user by name or email..."
              showPerPage={itemsPerPage}
              labelKey={(option) => option.fullname}
              renderMenuItem={(user) => (user.email
                ? `${user.fullname} (${user.email})`
                : `${user.fullname}`
              )}
              onSearch={handleSelectUserSearch}
              onSelect={(item) => setUserIdToReassign(item[0]?.id || null)}
            />
          </Row>
          <Row>
            <Form.Check
              custom
              id="reassign-image"
              type="checkbox"
              label="Reassign"
              name="reassignImage"
              checked={isReassign}
              onChange={() => setIsReassign(!isReassign)}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={hideModal}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!userIdToReassign}
            onClick={handleSaveClick}
          >
            {isLoading && (
              <Spinner
                show
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </>
  );
}
