import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import styles from './Characters.scss';

const cs = classNames.bind(styles);

export class CharactersReplace extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      replace_id: '',
    };
  }

  replaceCharactersAction() {
    const { replace_id: replaceId } = this.state;

    if (!replaceId) return;

    this.setState({ showLoading: true });
    const {
      onHide,
      obj,
      args,
    } = this.props;

    onHide();

    api.put(`/v1/books/${args.id}/characters/${obj.id}/replace/${replaceId}`)
      .then(() => {
        onHide();
      });
  }

  optionCharacters() {
    const charactersList = [];
    const {
      obj,
      characters,
    } = this.props;

    characters.map((c) => c.list.map((d) => charactersList.push(d)));
    const chars = charactersList.filter((c) => c.id !== obj.id).slice();
    chars.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    const res = [];
    res.push(<option key="" />);
    res.push(...chars.map((object) => (
      <option
        value={object.id}
        key={object.id}
      >
        {`${object.name} (${object.alias})`}
      </option>
    )));
    return res;
  }

  render() {
    const {
      obj,
      characters,
      onHide,
    } = this.props;

    const {
      replace_id: replaceId,
      showLoading,
    } = this.state;

    return (
      <Modal
        className={cs('deleteModal')}
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Replace Character:
            {obj.name}
            {' '}
            (
            {obj.alias}
            )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col
              sm={3}
              className="text-right"
            >
              Replace with:
            </Col>
            <Col
              sm={9}
            >
              <Form.Control
                disabled={characters.length === 0}
                size="sm"
                as="select"
                value={replaceId}
                onMouseDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  this.setState({
                    replace_id: e.target.value,
                  });
                }}
              >
                {this.optionCharacters()}
              </Form.Control>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            onClick={() => this.replaceCharactersAction()}
          >
            {showLoading ? (
              <Spinner
                variant="primary"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Replace
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
