import classNames from 'classnames/bind';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export function AnswerType(props) {
  const {
    answerIndex,
    disabled,
    answerTypeId,
    handleChange,
    xs,
    answerTypes,
    stepIndex,
    activeStepsFunc,
    xl,
    sm,
    lg,
  } = props;

  return (
    <Form.Group
      xs={xs || 12}
      sm={sm || 5}
      lg={lg || 4}
      xl={xl || 4}
      as={Col}
      controlId={`answerTypeId${answerIndex}`}
      className={cs('mb-0')}
    >
      <Row>
        <Form.Label
          className={cs('text-right text-sm-left px-1')}
          column
          sm={5}
          xl={4}
        >
          Type
        </Form.Label>
        <Col className={cs('mb-0 px-1')}>
          <Form.Control
            disabled={disabled}
            size="sm"
            as="select"
            value={answerTypeId || ''}
            name={`steps[${stepIndex}][answers][${answerIndex}][answerTypeId]`}
            onFocus={() => {
              if (activeStepsFunc) {
                activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-answerTypeId`);
              }
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onChange={(e) => handleChange(String(e.target.value))}
          >
            <option value={null} />
            {answerTypes.map((answerType) => (
              <option value={answerType.id} key={answerType.id}>
                {answerType.title}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
}
