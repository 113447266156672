import { storyTitleFormatter } from '../tableUtils/tableFormatters';

export const monetizationTopStoriesColumns = [{
  dataField: 'story.title',
  text: 'Episode / Story',
  sort: false,
  formatter: storyTitleFormatter,
}, {
  dataField: 'started',
  text: 'Started',
  sort: false,
},
];

export const monetizationTopStoriesHeaders = [
  { label: 'Started', key: 'started' },
  { label: 'Story', key: 'book.title' },
];
