import { sortBy } from 'lodash-es';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAchievementsContext } from '../../../contexts/AchievementsContext/AchievementsContext';
import { AchievementModalFooter } from './AchievementModalFooter/AchievementModalFooter';
import { AchievementModalHeader } from './AchievementModalHeader/AchievementModalHeader';
import { AchievementsForm } from './AchievementsForm/AchievementsForm';
import { AchievementsFormikWrapper } from './AchievementsFormikWrapper/AchievementsFormikWrapper';
import classes from './AchievementsModal.module.scss';
import { AchievementsModalProps } from './AchievementsModalTypes';
import { AchievementsModalContextProvider } from './context/AchievementsModalContext';
import { convertAchievementGetDTOToModal } from './utils';

export function AchievementsModal(props: AchievementsModalProps) {
  const { onHide } = props;

  const { achievements, fetchAchievements } = useAchievementsContext();
  const achievementSorted = sortBy(achievements, 'order');
  const initialAchievements = achievementSorted.map((item) => convertAchievementGetDTOToModal(item));

  const handleSubmit = () => {
    const abortController = new AbortController();
    fetchAchievements(abortController);
    onHide();
    return () => {
      abortController.abort();
    };
  };

  return (
    <Modal
      show
      onHide={onHide}
      size="xl"
      backdrop="static"
      keyboard={false}
      centered
      scrollable
      contentClassName={classes.contentClassName}
    >
      <AchievementsFormikWrapper onSubmit={handleSubmit} initialValues={initialAchievements}>
        <AchievementsModalContextProvider achievements={initialAchievements} onHide={onHide}>
          <AchievementModalHeader />
          <AchievementsForm />
          <AchievementModalFooter />
        </AchievementsModalContextProvider>
      </AchievementsFormikWrapper>
    </Modal>
  );
}
