import React, { PureComponent } from 'react';
import {
  Badge, Button, Dropdown, FormControl,
} from 'react-bootstrap';
import { noop } from '../../../helpers/noop';

const CustomToggle = React.forwardRef(function CustomToggle(props, ref) {
  const {
    children, onClick, disabled, formControl,
  } = props;

  const formControlStyle = formControl
    ? `form-control-${formControl}`
    : 'form-control-sm';

  return (
    <Button
      ref={ref}
      disabled={disabled}
      size={formControl ? formControlStyle : 'sm'}
      variant="outline-secondary"
      className={`form-control ${formControl} text-left border arr-right no-overflow`}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children}
    </Button>
  );
});

const CustomMenu = React.forwardRef(function CustomMenu(props, ref) {
  const {
    children, style, className, 'aria-labelledby': labeledBy, disabled, hideFilter,
  } = props;
  const [value, setValue] = React.useState('');

  const handleChange = (e) => {
    setValue(e.target.value.toLowerCase().trimStart());
  };

  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      {!hideFilter && (
      <div className="mx-3 my-2">
        <FormControl
          disabled={disabled}
          autoFocus
          className="form-control"
          placeholder="Type to filter..."
          onChange={handleChange}
          value={value}
        />
      </div>
      )}
      <ul className="list-unstyled">
        {React.Children.toArray(children).filter(
          (child) => !value || child.props.children[0].toLowerCase().includes(value),
        )}
      </ul>
    </div>
  );
});

export class CustomSelect extends PureComponent {
  constructor(props, context) {
    super(props, context);
    const { value } = this.props;
    this.state = {
      value: value || '',
      title: '',
    };
  }

  componentDidMount() {
    const { args } = this.props;
    const { value } = this.state;

    const res = args.find((object) => String(object.id) === String(value));
    if (res) {
      this.setState({ title: res.title });
    }
  }

  componentDidUpdate(prevProps) {
    const { args, value } = this.props;

    if (
      prevProps.value !== value
      || prevProps.args !== args
    ) {
      args.forEach((object) => {
        if (Number(object.id) === Number(value)) {
          this.setState({
            title: object.title,
            value: value || '',
          });
        }
      });
    }
  }

  branchOptions() {
    const {
      user,
      args,
      data,
      isPrompt,
      badge,
      value,
      bookRole,
    } = this.props;

    const cur = data && data;
    return args.map((object, i) => {
      const disabled = (bookRole === 'owner' && isPrompt && object.id === null) || (user && user.role === 'admin')
        ? false
        : Number(object.id) === Number(cur);
      const active = Number(object.id) === Number(value);
      const title = object.title || '';
      const id = object.id || null;
      const objectBadge = (badge && object.badge)
        && <Badge variant="light" className="float-right">{object.badge}</Badge>;

      return (
        <Dropdown.Item
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          disabled={disabled}
          as="button"
          eventKey={id}
          active={active}
          onMouseDown={(e) => e.stopPropagation()}
          className={object.title === 'intro' ? 'intro-last' : ''}
        >
          {title}
          {' '}
          {objectBadge}
        </Dropdown.Item>
      );
    });
  }

  handleSelect(e, event) {
    const { args } = this.props;

    event.stopPropagation();
    event.preventDefault();
    args.forEach((object) => {
      if (Number(object.id) === Number(e)) {
        this.setState({
          value: e || '',
          title: object.title,
        });
      }
    });
  }

  render() {
    const {
      disabled, onChange, value, onFocus, name, hideFilter, formControl,
    } = this.props;
    const { title } = this.state;

    return (
      <>
        <input
          className="d-none"
          type="text"
          value={String(value)}
          disabled={disabled}
          name={name}
          onChange={noop}
        />
        <Dropdown
          disabled={disabled}
          className="w-auto CustomSelect"
          onSelect={(e, event) => {
            this.handleSelect(e, event);
            onChange(e);
          }}
          onFocus={onFocus}
        >
          <Dropdown.Toggle
            as={CustomToggle}
            className="w-100"
            disabled={disabled}
            formControl={formControl}
          >
            {title}
          </Dropdown.Toggle>

          <Dropdown.Menu
            as={CustomMenu}
            className="w-100 mw-75 overflow-hidden"
            hideFilter={hideFilter}
          >
            {this.branchOptions()}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}
