// copies from dorian-client/source/www/lib/avatar-system.js
// which is copied from repo dorian-avatar-renderer
// TODO: https://dorian.atlassian.net/browse/DOR-3142
/* eslint-disable */

export var AvatarSystem = (function () {
    'use strict';

    function isObject(val) {
        return !!val && typeof val === 'object';
    }

    function keyArray(arr, getKey) {
        for (const item of arr) {
            const k = getKey(item);
            if (k in arr) { throw new Error(`Duplicate key: ${k}`); }
            arr[k] = item;
        }
        return arr;
    }

    function mapEntries(obj, fn) {
        return Object.entries(obj).map(([k, v]) => fn(k, v));
    }

    function getDeep(obj, ...keys) {
        return keys.reduce((o, k) => o && o[k], obj);
    }

    function setAll(obj, keys, getVal) {
        for (const k of keys) { obj[k] = getVal(k); }
        return obj;
    }

    const template_expr = /\{(\w+)\}/;

    function template_fill(obj) {
        let out = "";
        for (let i = 0; i < this.length; i++) {
            const part = this[i];
            if (i % 2) {
                if (!obj[part]) { return null; }
                out += obj[part];
            } else {
                out += part;
            }
        }
        return out;
    }

    function template(str) {
        let match;

        const parts = [];
        const fn = template_fill.bind(parts);
        fn.props = [];

        while ((match = template_expr.exec(str))) {
            parts.push(str.slice(0, match.index));
            parts.push(match[1]);
            fn.props.push(match[1]);
            str = str.slice(match.index + match[0].length);
        }
        parts.push(str);

        return fn;
    }

    class AvatarLayer {
        constructor(avatarSystem, {
            name,
            src,
            blend = 'source-over',
            obscures = [],
            rect,
        }) {
            this.name = name;
            this.src = template(src);
            this.srcRaw = src;
            this.blend = blend;
            this.obscures = new Set(obscures);

            if (Array.isArray(rect)) {
                this.rect = setAll({}, avatarSystem.types, () => rect.slice(0, 4));
            } else if (isObject(rect)) {
                this.rect = setAll({}, avatarSystem.types, k => Array.isArray(rect[k]) ? rect[k].slice(0, 4) : [0, 0, ...avatarSystem.size]);
            } else {
                this.rect = setAll({}, avatarSystem.types, () => [0, 0, ...avatarSystem.size]);
            }
        }
    }

    class AvatarPropertyValue {
        constructor({
            property,
            id,
            label = '',
            price = -1,
            specialLabel = '',
            discount = 0,
            hidden = false,
            availability
        }) {
            this.property = property;
            this.id = id;
            this.label = label;
            this.price = price;
            this.specialLabel = specialLabel;
            this.discount = discount;
            this.hidden = hidden;
            this.availability = availability;
        }

        get purchaseId() {
            return this.property.name + ':' + this.id;
        }

        get isUserOwned() {
            return this.price === 0 || app.purchases.has(this.purchaseId);
        }
    }

    class AvatarProperty {
        constructor(avatarSystem, {
            name,
            icon = '',
            title = name,
            isRequired = false,
            isCustomizable = false,
            isConfigurable = false,
            isHeadFeature = false,
            defaultValue = null,
            values = {},
            iconRect = {},
            iconSrc,
            compute = null,
        }) {
            this.name = name;
            this.icon = icon;
            this.title = title;
            this.isRequired = isRequired;
            this.isCustomizable = isCustomizable;
            this.isConfigurable = isConfigurable;
            this.isHeadFeature = isHeadFeature;
            this.iconSrc = template(iconSrc);
            this.iconSrcRaw = iconSrc;
            this.iconRect = iconRect;
            this.compute = compute;

            this.values = setAll({}, avatarSystem.types, k => {
                const arr = (values[k] || []).map(o => new AvatarPropertyValue({ property: this, ...o }));
                keyArray(arr, o => o.id);
                return arr;
            });

            if (isObject(defaultValue)) {
                this.defaultValue = setAll({}, avatarSystem.types, k => {
                    let v = defaultValue[k];
                    if (Array.isArray(v)) { v = v[0]; }
                    return v || null;
                });
            } else {
                this.defaultValue = setAll({}, avatarSystem.types, () => defaultValue || null);
            }
        }
    }

    class AvatarOverride {
        constructor({
            name,
            defaultValue = null,
            values = {},
        }) {
            this.name = name;
            this.defaultValue = defaultValue;
            this.values = values;
        }
    }

    const { min, max } = Math;

    class AvatarSystem {

        constructor({ config, assetManifest, localAssetManifest, localAssetURL }) {
            this.url = new URL(config.url);
            this.size = config.size;
            this.types = config.types;


            this.layers = keyArray(
                config.layers.map(l => new AvatarLayer(this, l)),
                l => l.name,
            );
            this.properties = keyArray(
                mapEntries(config.properties, (name, p) => new AvatarProperty(this, { name, ...p })),
                p => p.name,
            );
            this.overrides = keyArray(
                mapEntries(config.overrides, (name, o) => new AvatarOverride({ name, ...o })),
                o => o.name,
            );

            this.assetManifest = assetManifest;

            this.localAssetManifest = localAssetManifest;
            this.localAssetURL = localAssetURL;

            this.defaults = setAll({}, config.types, type => {
                const obj = { type };

                for (const p of this.properties) {
                    if (p.isConfigurable && p.defaultValue[type]) { obj[p.name] = p.defaultValue[type]; }
                }
                for (const o of this.overrides) {
                    if (o.defaultValue) { obj[o.name] = o.defaultValue; }
                }

                return obj;
            });
        }

        resolvePropertyValues(props) {
            const type = props.type;

            // check type is valid
            if (!this.types.includes(type)) { throw new Error(`Bad avatar type. Got ${type}, expected one of: "${this.types.join('", "')}".`); }

            // apply defaults where property is undefined, but not null
            const defaults = this.defaults[type];
            for (const k of Object.keys(defaults)) { if (props[k] === undefined) { props[k] = defaults[k]; } }

            for (const o of this.overrides) {
                const v = props[o.name];
                if (v in o.values) {
                    Object.assign(props, o.values[v]);
                } else {
                    console.warn(`Undefined override value: ${o.name} = ${v}`);
                }
            }

            const computed = {};
            for (const p of this.properties) { if (p.compute) { computed[p.name] = p.compute(props); } }
            Object.assign(props, computed);

            return props;
        }

        /**
         * @param {CharacterProperties} props
         * @return {{rect: number[], src: string, blend: string, name: string}[]}
         */
        getComposition(props) {
            if (props.type === 'custom') {
                let image = props.image || '';
                if (props.expression && props.expressions && props.expressions[props.expression]) {
                    image = props.expressions[props.expression].imageURL || '';
                }
                image = image.replace('assets/img/', '');
                return [{
                    name: 'body.torso',
                    src: image,
                    blend: 'source-over',
                    rect: [0, 0, ...this.size],
                }];
            }

            props = this.resolvePropertyValues(props);

            const out = [];
            const obscured = new Set();
            const layers = this.layers;

            if (props.outfitstyle) {
                props.topinnerstyle = null;
            }

            // iterate backwards thru group
            for (let i = layers.length - 1; i >= 0; i--) {
                let layer = layers[i];

                // get src from properties
                let src;

                // HACK
                if (
                    layer.name === "facialhairupper" &&
                    (props.facialhairupperstyle === "thick" || props.facialhairupperstyle === "thin")
                ) {
                    src = layer.src(Object.assign({}, props, { facialhaircolor: "all" }));
                } else {
                    src = layer.src(props);
                }

                if (!src || obscured.has(layer.name) || !this.isKnownImageSrc(src)) { continue; }

                // now that we know the layer is active,
                // add the names of the layers it obscures to [obscured]
                for (const n of layer.obscures) { obscured.add(n); }

                // unshift, because reverse
                out.unshift({
                    name: layer.name,
                    src: src,
                    blend: layer.blend,
                    rect: layer.rect[props.type]
                });
            }

            return out;
        }

        isKnownImageSrc(src) {
            const path = src.split('/');
            return !!(getDeep(this.localAssetManifest, ...path) || getDeep(this.assetManifest, ...path));
        }

        getImageURL(src) {
            const path = src.split('/');
            if (getDeep(this.localAssetManifest, ...path)) {
                return this.localAssetURL + src;
            }
            return new URL(src, this.url);
        }

        async draw(ctx, config, sRect = [0, 0, ...this.size], dRect = [0, 0, ...this.size], { signal } = {}) {

            if (signal && signal.aborted) { throw new Error('Aborted'); }

            let comp = this.getComposition(config);

            const imgs = await Promise.all(comp.map(async (l) => {
                const img = new Image();
                img.src = this.getImageURL(l.src);
                await img.decode();
                return img;
            }));

            if (signal && signal.aborted) { throw new Error('Aborted'); }

            ctx.save();
            ctx.clearRect(...dRect);
            let bodyFill;

            for (let i = 0; i < comp.length; i++) {
                const layer = comp[i];
                const img = imgs[i];
                if (layer.name === 'head') {
                    bodyFill = ctx.createPattern(ctx.canvas, 'no-repeat');
                    ctx.clearRect(...dRect);
                }
                this._drawImageLayer(ctx, img, layer, sRect, dRect);
            }

            if (bodyFill) {
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = bodyFill;
                ctx.fillRect(...dRect);
            }

            ctx.restore();

        }

        _drawImageLayer(ctx, img, { blend, rect: [x, y, w, h] }, [sx, sy, sw, sh], [dx, dy, dw, dh]) {
            ctx.globalCompositeOperation = blend;
            const mw = (dw / sw);
            const mh = (dh / sh);
            dx -= min(0, sx - x) * mw;
            dy -= min(0, sy - y) * mh;
            sx = max(0, sx - x);
            sy = max(0, sy - y);
            ctx.drawImage(img, sx, sy, w, h, dx, dy, w * mw, h * mh);
        }

    }

    return AvatarSystem;

})();
