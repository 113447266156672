import React from 'react';

export function Line({
  width = '10px',
  height = '1px',
  fill = 'none',
  stroke = 'black',
  strokeWidth = '1px',
  className = '',
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1={width} y1="50%" x2="0%" y2="50%" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>

  );
}
