import { CSSProperties, useMemo } from 'react';

export const phoneScreenAspectRatio = 890 / 450;

const backgroundAspectRatio = 1118 / 592;

export function useSizes(width: number, isDetached: boolean) {
  return useMemo(() => {
    const height = width * phoneScreenAspectRatio;
    const unit = width / 100;
    return {
      sizeConfig: {
        width,
        height,
      },
      mobileBackgroundStyle:
        isDetached
          ? {
            width,
            '--font-size': `${unit / 2}px`,
            '--space': `${unit}px`,
          } : {
            width: width * 1.3,
            height: width * 1.3 * backgroundAspectRatio,
            '--font-size': `${unit / 2}px`,
            '--space': `${unit}px`,
          } as CSSProperties,
    };
  }, [isDetached, width]);
}
