// @ts-ignore: no def file
import {
  has, mapKeys, merge, omit,
} from 'lodash-es';
import React from 'react';
import { ExpressionCard } from '../ExpressionCard/ExpressionCard';
import classNames from './ExpressionList.module.scss';

const changeValueExpressionByName = (oldValue, newValue, allExpressionsByName) => {
  const oldExpressionName = Object.keys(oldValue)[0];
  if (!newValue) return omit(allExpressionsByName, oldExpressionName);

  const newExpressionName = Object.keys(newValue)[0];

  if (oldExpressionName === newExpressionName) {
    return merge({}, allExpressionsByName, newValue);
  }

  if (has(allExpressionsByName, newExpressionName)) return allExpressionsByName;

  // Changed expression name
  const newCharacterExpressions = mapKeys(
    allExpressionsByName,
    (_, mapKey) => (mapKey === oldExpressionName ? newExpressionName : mapKey),
  );

  return merge({}, newCharacterExpressions, newValue);
};

/**
 * Render expressions list
 * @param value - HashMap. Expressions
 * @param onChange(newValue) - Callback. Return new expressions
 * @returns {JSX.Element}
 */
export function ExpressionList({
  value: expressionValuesByName,
  possibleExpressions,
  onChange,
}) {
  const handleExpressionCardChanged = (oldValue, newValue) => {
    const newExpressions = changeValueExpressionByName(
      oldValue,
      newValue,
      expressionValuesByName,
    );
    onChange(newExpressions);
  };

  const handleApplyImageAreaToAll = (portraitCoordinates) => {
    const newExpressions = Object.fromEntries(
      Object.entries(expressionValuesByName).map(
        ([key, expressionValue]) => [key, { ...expressionValue, portraitCoordinates }],
      ),
    );
    onChange(newExpressions);
  };

  return (
    <ul className={classNames.list}>
      {
        Object.entries(expressionValuesByName).map(([expressionName, expressionValue]) => {
          const expression = {
            [expressionName]: expressionValue,
          };
          const possibleExpressionsFiltered = possibleExpressions.filter(
            (possibleExpression) => {
              const isExpressionExist = Object
                .keys(expressionValuesByName)
                .includes(possibleExpression.value);
              const isExpressionCurrent = possibleExpression.value === expressionName;
              return !isExpressionExist || isExpressionCurrent;
            },
          );

          return (
            <li key={expressionName}>
              <ExpressionCard
                key={expressionName}
                value={expression}
                possibleExpressions={possibleExpressionsFiltered}
                onChange={handleExpressionCardChanged}
                onApplyImageAreaToAll={handleApplyImageAreaToAll}
              />
            </li>
          );
        })
      }
    </ul>
  );
}
