import { useEffect, useRef, useState } from 'react';

export function useAudioPlayer(audioURL, audioSettings) {
  const audioPlayer = useRef(new Audio());

  const [audioVolume, setAudioVolume] = useState(audioSettings?.volume ?? 1);
  const [isLoopAudio, setIsLoopAudio] = useState(audioSettings?.loop ?? false);

  const [isPlayingAudio, setIsPlayingAudio] = useState(false);

  const togglePlayAudio = () => setIsPlayingAudio((oldValue) => !oldValue);

  useEffect(() => {
    audioPlayer.current.volume = audioVolume;
  }, [audioVolume]);

  useEffect(() => {
    const player = audioPlayer.current;
    player.pause();
    if (isPlayingAudio && audioURL) {
      player.src = audioURL;
      player.play();
      player.onended = togglePlayAudio;
    }
    return () => {
      player.pause();
    };
  }, [isPlayingAudio, audioURL]);

  useEffect(() => {
    audioPlayer.current.loop = isLoopAudio;
  }, [isLoopAudio]);

  return {
    audioVolume,
    setAudioVolume,
    isLoopAudio,
    setIsLoopAudio,
    isPlayingAudio,
    togglePlayAudio,
  };
}
