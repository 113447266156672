import React from 'react';
import { Modal } from 'react-bootstrap';
import { useCharacterExpressionsContext } from 'contexts/CharacterExpressionsContext/CharacterExpressionsContext';
import { Character } from '../../../dorian-shared/types/character/Character';
import { AvatarTool } from './index';

export type AvatarToolModalProps = {
  show: boolean;
  bookId: number;
  charactersGroups: string[];
  update: (value: Character) => void;
  onHide: () => void;
  character?: Character;
};

export function AvatarToolModal(props: AvatarToolModalProps) {
  const {
    update,
    charactersGroups,
    character,
    show,
    bookId,
    onHide,
    ...otherProps
  } = props;

  const characterExpressions = useCharacterExpressionsContext();

  if (!show) {
    return null;
  }

  return (
    <Modal
      {...otherProps}
      show={show}
      bookid={bookId}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modalBig"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {character ? `Edit Character - ${character.name}` : 'Add Character'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {show ? (
          <AvatarTool
            charactersGroups={charactersGroups}
            characterExpressions={characterExpressions}
            updateAvatarModal={update}
            bookid={bookId}
            onHide={onHide}
            obj={character}
          />
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
