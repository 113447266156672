import React from 'react';
import { ResetPasswordForm } from '../../../uiV2/ResetPasswordForm/ResetPasswordForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function ResetPassword() {
  const title = 'Reset your password';
  const message = 'Please enter your email address.  '
      + 'You will receive an email message with instructions on how to reset your password.';
  return (
    <LoginLayout>
      <ResetPasswordForm title={title} message={message} />
    </LoginLayout>
  );
}
