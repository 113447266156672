export function PremiumIpDisabledApprovedEdit(role, book) {
  if (!role || !book) {
    return true;
  }

  return (
    role !== 'admin'
        && !!book
        && book.original
        && ('approved_count' in book)
        && book.approved_count > 0
        && (!book.isPrompt && book.sourceBookId !== null)
  );
}
