import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export function DeleteStepModal(props) {
  const {
    show, onDelete, onHide, content, title, ...other
  } = props;

  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      content={content}
      title={title}
      {...other}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="deleteModal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {content}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="reset"
          variant="secondary"
          onClick={onHide}
        >
          Cancel
        </Button>

        <Button
          type="submit"
          variant="primary"
          onClick={onDelete}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
