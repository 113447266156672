import async from 'async';
import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { EditPrompt } from './EditPrompt';
import { PromptTableRow } from './PromptTableRow';
import './Templates.css';

export class BookPrompts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limits: [],
      prompts: [],
      editPromptActive: false,
      editPromptData: null,
      // eslint-disable-next-line react/no-unused-state
      pageTitle: 'Story Prompts',
      listPromptsActive: false,
      loading: true,
    };

    const {
      history,
      auth,
    } = this.props;

    this.user = auth.getUser();

    this.page = {
      header: {
        title: 'Story Prompts',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      listPromptsActive: true,
    });
    this.loadPrompts();
  }

  loadPrompts = () => {
    this.setState({
      loading: true,
    });
    async.parallel({
      settings: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings);
          }).catch((error) => {
            callback(error, null);
          });
      },
      prompts: (callback) => {
        api.get('/v1/bookprompts?all=1')
          .then((res) => {
            callback(null, res.data.prompts);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            limits: res.settings.limits,
            prompts: res.prompts,
            listPromptsActive: true,
            editPromptActive: false,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  promptsTable = () => {
    const { user } = this;
    const action = this.initAddPrompt;
    const updatePrompt = () => {
      this.loadPrompts();
    };
    const { prompts } = this.state;

    if (prompts.length < 1) {
      return (
        <tr>
          <td colSpan={3}>There are no prompts</td>
        </tr>
      );
    }

    return prompts.map((object, i) => (
      <PromptTableRow
        user={user}
        obj={object}
        /* eslint-disable-next-line react/no-array-index-key */
        key={i}
        actionEdit={action}
        actionUpdate={updatePrompt}
      />
    ));
  };

  initAddPrompt = (obj) => {
    let editPromptData = null;
    if (obj) {
      editPromptData = obj;
    }
    this.setState({
      editPromptData,
      editPromptActive: true,
    });
  };

  cancelAddPrompt = () => {
    this.setState({
      editPromptData: null,
      editPromptActive: false,
    });
  };

  render() {
    const {
      editPromptActive,
      listPromptsActive,
      limits,
      loading,
      editPromptData,
    } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card className={listPromptsActive ? 'd-block' : 'd-none'}>
            <Card.Body>
              <Card.Title>List of Story Prompts</Card.Title>
              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Original Story</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Disabled</th>
                          <th>Active Contest</th>
                          <th>Trending</th>
                          <th>Priority</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.promptsTable()}
                      </tbody>
                    </Table>
                  )
}
            </Card.Body>
          </Card>
        </PageWrapper>

        {editPromptActive
        && (
        <EditPrompt
          limits={limits}
          data={editPromptData}
          show={editPromptActive}
          onHide={this.cancelAddPrompt}
          update={this.loadPrompts}
        />
        )}
      </>
    );
  }
}
