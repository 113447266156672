import React, { useContext } from 'react';
import { CardMemorySlotData } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { ChoiceMemoryContext } from '../../../../contexts/ChoiceMemory/ChoiceMemoryContext';
import { isMemorySlotVariableLink } from '../../../utils/choiceMemoryUtils';

type BranchNodeCardCheckInfoProps = {
  memorySlotData: CardMemorySlotData,
}

export function BranchNodeCardCheckInfo(props: BranchNodeCardCheckInfoProps) {
  const { memorySlotData } = props;
  const choiceMemory = useContext(ChoiceMemoryContext);

  const value = isMemorySlotVariableLink(memorySlotData?.value)
    ? choiceMemory.getVariableById(Number(memorySlotData?.value.variableId))?.name
    : memorySlotData?.value;

  const messageText = `${memorySlotData?.name} ${memorySlotData?.operator} ${value}`;

  return (
    <div className="check_card_info">
      {memorySlotData && (
      <>
        <div title={messageText}>
          {messageText}
        </div>
        <div className="switch_info">
          <div className="switch_true">true</div>
          <div className="switch_false">false</div>
        </div>
      </>
      )}
    </div>
  );
}
