import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export function CancelAddBranch(props) {
  const { update, onHide, ...otherProps } = props;
  return (
    <Modal
      {...otherProps}
      onHide={onHide}
      size="sm"
      className="deleteModal"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Close editing?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          Any unsaved changes will be lost.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="reset"
          variant="secondary"
          onClick={onHide}
        >
          Do not close
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={update}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
