import React from 'react';

export function CloseIcon({
  fill = 'white',
  height = '25px',
  width = '25px',
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M8.77122 8.52498L14.8701 2.06873C15.1004 1.82498 15.1004 1.43123 14.8701 1.18748C14.6399 0.943726 14.2679 0.943726 14.0376 1.18748L7.93875 7.64373L1.83986 1.18123C1.6096 0.937476 1.23174 0.937476 1.00148 1.18123C0.771224 1.42498 0.771224 1.82498 1.00148 2.06873L7.10037 8.52498L1.04871 14.9312C0.818457 15.175 0.818457 15.5687 1.04871 15.8125C1.1668 15.9375 1.3144 15.9937 1.4679 15.9937C1.62141 15.9937 1.76901 15.9312 1.88709 15.8125L7.94466 9.39997L14.0022 15.8125C14.1203 15.9375 14.2679 15.9937 14.4214 15.9937C14.5749 15.9937 14.7225 15.9312 14.8406 15.8125C15.0709 15.5687 15.0709 15.175 14.8406 14.9312L8.78303 8.51873L8.77122 8.52498Z" />
    </svg>
  );
}
