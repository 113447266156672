import { EpisodeStorePublicInterface } from '@dorian/creation-tools-ui';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import {
  ChoiceMemoryContext,
  ChoiceMemoryProvider,
} from '../../../contexts/ChoiceMemory/ChoiceMemoryContext';
import { api } from '../../api';
import { Preview } from '../../ui/Preview/Preview';
import { phoneScreenAspectRatio } from '../../ui/Preview/useSizes';
import { convertMemoryDTOToFormDTO } from '../Book/MemoryBank/memoryBankUtils';
import { DorianLogo } from '../LoginLayout/DorianLogo';
import { CloseIconButton } from './CloseIconButton/CloseIconButton';
import { InfoIconButton } from './InfoIconButton/InfoIconButton';
import classNames from './PlayPreview.module.scss';
import { PlayPreviewInfoSection } from './PlayPreviewInfoSection/PlayPreviewInfoSection';
import { PlayPreviewPromo } from './PlayPreviewPromo/PlayPreviewPromo';

const EMULATOR_PROMO_LINK = 'https://dorian.app.link/3QZKsytQCDb';

const getGameWidth = (maxHeight) => {
  const maxWidth = maxHeight / phoneScreenAspectRatio;
  if (maxWidth * phoneScreenAspectRatio > maxHeight) {
    return maxHeight / phoneScreenAspectRatio;
  }

  if (maxWidth > window.innerWidth) {
    return window.innerWidth;
  }

  return maxWidth;
};

export function PlayPreview(props) {
  const { match } = props;
  const [story, setStory] = useState(null);
  const headerContainerRef = useRef(null);
  const [gameWidth, setGameWidth] = useState(null);
  const [isPreviewExit, setIsPreviewExit] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [bookVariables, setBookVariables] = useState(null);
  const [store, setStore] = useState(null);

  const choiceMemory = useContext(ChoiceMemoryContext);

  const handleResize = useDebouncedCallback(() => {
    const headerHeight = headerContainerRef.current?.offsetHeight ?? 0;
    const maxHeight = window.innerHeight - headerHeight;
    setGameWidth(getGameWidth(maxHeight));
  }, 1000);

  useEffect(() => {
    setStore(new EpisodeStorePublicInterface());
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    api.get(`/v1/preview/${match.params.uuid}`)
      .then((res) => {
        const { story: fetchedStory } = res.data;
        setStory(fetchedStory);

        const variables = {};
        fetchedStory?.variables?.forEach((variable) => {
          variables[variable.name] = convertMemoryDTOToFormDTO(variable);
        });
        if (variables) {
          setBookVariables(variables);
          choiceMemory.setCurrentVariables(variables);
        }
      });
  }, [choiceMemory, match.params.uuid]);

  const handlePreviewExit = useCallback(() => {
    setIsPreviewExit(true);
  }, []);

  const handlePromoClick = () => {
    window.open(EMULATOR_PROMO_LINK, '_blank');
  };

  const handleReplayClick = async () => {
    choiceMemory.setCurrentVariables(bookVariables);
    setIsPreviewExit(false);
  };

  return (
    <ChoiceMemoryProvider>
      <div className={classNames.container}>
        <div
          ref={headerContainerRef}
          className={classNames.headerContainer}
        >
          <a href="/" className={classNames.logoContainer}>
            <div className={classNames.logoIcon}>
              <DorianLogo stroke="#67237E" height="100%" width="100%" />
            </div>
            <div className={classNames.logoText}>DORIAN</div>
          </a>
          <Button
            className={classNames.buttonContainer}
            size="sm"
            onClick={handlePromoClick}
          >
            Get the App
          </Button>
        </div>
        <div className={classNames.mainContainer}>
          {gameWidth && (
          <>
            <div className={classNames.leftSection}>
              {story && (
              <PlayPreviewInfoSection
                coverImage={story.coverImage?.imageUrl}
                title={story.title}
                description={story.summary}
                authors={story.book?.authors ?? []}
              />
              )}
            </div>
            <div
              className={classNames.previewContainer}
              style={{
                width: `${gameWidth}px`,
                height: `${gameWidth * phoneScreenAspectRatio}px`,
              }}
            >
              <div className={classNames.overflowContainer}>
                {isPreviewExit && (
                <PlayPreviewPromo
                  onReplayClick={handleReplayClick}
                  onPromoClick={handlePromoClick}
                />
                )}
                {!isPreviewExit && (
                <Preview
                  episodeUuid={match.params.uuid}
                  width={gameWidth}
                  isDetached
                  onPreviewExit={handlePreviewExit}
                  store={store.internalStore}
                  bookId={story?.book?.id}
                />
                )}
                {isInfoOpen && (
                <div className={classNames.leftSectionMobile}>
                  <PlayPreviewInfoSection
                    coverImage={story.coverImage.imageUrl}
                    title={story.title}
                    description={story.summary}
                    authors={story.book?.authors ?? []}
                  />
                </div>
                )}
                <div className={classNames.infoIconContainer}>
                  {isInfoOpen && (
                  <div className={classNames.infoIcon}>
                    <CloseIconButton onClick={() => setIsInfoOpen(false)} />
                  </div>
                  )}
                  {!isInfoOpen && (
                  <div className={classNames.infoIcon}>
                    <InfoIconButton onClick={() => setIsInfoOpen(true)} />
                  </div>
                  )}
                </div>
              </div>
            </div>
            <div className={classNames.rightSection}>
              Right
            </div>
          </>
          )}
        </div>
      </div>
    </ChoiceMemoryProvider>
  );
}
