import { Book, BookTypes } from '../../../dorian-shared/types/book/book';
import { Character } from '../../../dorian-shared/types/character/Character';

export const getBookType = (book: Book) => {
  if (book.originalBookId === null && book.sourceBookId === null) {
    return BookTypes.Original;
  }

  if (book.isPrompt) {
    return BookTypes.Prompt;
  }

  if (!!book.sourceBookId && book.sourceBookId !== book.originalBookId) {
    return BookTypes.SpinOff;
  }

  return BookTypes.Unknown;
};

export const getCharacterLockingVisible = (userRole: string, bookType: BookTypes) => {
  const isCharacterLockingForAdminVisible = userRole === 'admin' && bookType !== BookTypes.SpinOff;
  const isCharacterLockingForNonAdminVisible = bookType === BookTypes.Prompt;
  return { isCharacterLockingForAdminVisible, isCharacterLockingForNonAdminVisible };
};

export const getCharacterLockingDisabled = (
  userRole: string,
  bookType: BookTypes,
  character: Character,
) => {
  const isAdminRole = userRole === 'admin';
  const isSpinOff = bookType === BookTypes.SpinOff;
  const isCharacterLockedByNonAdmin = character.lockName || character.lockArt;

  if (isAdminRole) {
    return false;
  }

  if (isSpinOff && isCharacterLockedByNonAdmin) {
    return true;
  }

  return false;
};
