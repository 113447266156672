import serialize from 'form-serialize';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, InputGroup, Spinner,
} from 'react-bootstrap';
import ImgEye from '../../../assets/images/eye-black.svg';
import { api } from '../../api';

export class Reset extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: '',
      validated: null,
      loading: false,
      formData: {
        password: '',
        token: '',
        name: '',
      },
      // eslint-disable-next-line react/no-unused-state
      showLoading: false,
    };
  }

  componentDidMount() {
    const { formData } = this.state;
    const { location } = this.props;

    const values = queryString.parse(location.search);
    const signUpEmail = localStorage.getItem('signUpEmail');
    formData.token = values.token ? values.token : formData.token;
    formData.name = values.name
      ? values.name
      : signUpEmail || formData.name;
    this.setState({
      formData,
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      const { formData } = this.state;
      const values = queryString.parse(location.search);
      if (values.token) {
        formData.token = values.token;
        this.setState({
          formData,
        });
      }
    }
  }

  loadData = (obj, validated) => {
    if (validated === true) {
      this.setState({
        error: false,
        loading: true,
      });
      // eslint-disable-next-line no-param-reassign
      obj.login = obj.name.trim().toLowerCase();
      const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));
      if (createTemplate) {
        createTemplate.setpassword = true;
        // eslint-disable-next-line no-param-reassign
        obj.createTemplate = createTemplate;
      }

      api.post('/v1/users/resetdone', obj)
        .catch((error) => {
          this.setState({
            error: error.response.data.error,
            loading: false,
          });
        })
        .then((res) => {
          if (res && res.status === 200) {
            localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
            this.setState({
              error: false,
              loading: false,
            });
            const { auth } = this.props;
            auth.login(res.data);
          }
        });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.loadData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  renderError() {
    const {
      error,
      loading,
    } = this.state;

    if (error) {
      return (
        <Alert
          show
          variant="danger"
        >
          {error}
        </Alert>
      );
    } if (loading) {
      return (
        <Alert
          show
          variant="success"
        >
          Loading...
        </Alert>
      );
    }
    return null;
  }

  handlePassValidation = () => {
    const { formData } = this.state;
    const { password } = formData;
    const lowercase = password.match(/[a-z]+/g);
    const uppercase = password.match(/[A-Z]+/g);
    const digits = password.match(/[\d]+/g);
    const lenghtPas = password.match(/[A-Za-z\d]{8,}/g);
    if (password === '') {
      return (<>Password is required</>);
    }
    if (lowercase === null) {
      return <>Password must include at least one lowercase letter</>;
    }
    if (uppercase === null) {
      return <>Password must include at least one uppercase letter</>;
    }
    if (digits === null) {
      return <>Password must include at least one digit from 0 to 9</>;
    }
    if (lenghtPas === null) {
      return <>Password must include at least 8 characters</>;
    }
    return null;
  };

  handleChange = (event) => {
    const { formData, validated } = this.state;
    formData[event.target.name] = event.target.value;

    this.setState({
      formData,
      error: '',
      validated: event.target.name === 'password' ? true : validated,
    });
  };

  render() {
    const { location } = this.props;
    if (location.pathname !== '/setpassword' && location.pathname !== '/reset') return null;

    const {
      formData,
      validated,
      loading,
      showPass,
    } = this.state;

    return (
      <div className="mainPage">
        <div className="mainPage-content active">
          <div className="mainPage-content-wrapper slide-3">
            <div>
              <Button
                className="closeBtn"
                variant="outline-primary"
                href="/#"
              >
                X
              </Button>

              <div className="mainPage-right-form-box-content">
                {location.pathname === '/setpassword'
                  ? (
                    <h2 className="text-center py-3 modal-title">Set Password</h2>
                  )
                  : (
                    <h2 className="text-center py-3 modal-title">
                      Forgot Password
                      <small className="text-center pb-4">
                        Check your email for the confirmation code.
                      </small>
                    </h2>
                  )}

                {this.renderError()}

                <Form
                  noValidate
                  onSubmit={this.handleSubmit}
                  validated={validated}
                >
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md={12}
                      controlId="formCode"
                      className="mt-input"
                    >
                      <Form.Label>Code</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="token"
                        placeholder="Code"
                        value={formData.token || ''}
                        disabled={loading}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Code.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={12}
                      controlId="formUserName"
                      className="mt-input"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder="Email"
                        value={formData.name || ''}
                        disabled={loading}
                        onChange={this.handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid Email.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={12}
                      className="mt-input"
                      controlId="formPassword"
                    >
                      <Form.Label>Password</Form.Label>
                      <InputGroup>
                        <Form.Control
                          required
                          type={!showPass ? 'password' : 'text'}
                          name="password"
                          placeholder="Password"
                          autoComplete="password"
                          pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
                          value={formData.password || ''}
                          disabled={loading}
                          onChange={this.handleChange}
                        />

                        <InputGroup.Prepend
                          className="passEyeBtn"
                          onClick={() => {
                            this.setState({
                              showPass: !showPass,
                            });
                          }}
                        >
                          <img src={ImgEye} alt="" />
                        </InputGroup.Prepend>

                        <Form.Control.Feedback type="invalid">
                          {this.handlePassValidation()}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Col md={12} className="text-center">
                      <Button
                        variant="outline-primary"
                        type="submit"
                        className="mt-4 btnMainPage"
                      >
                        {loading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        Submit
                      </Button>
                    </Col>
                  </Form.Row>

                </Form>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
