import React from 'react';
import { BranchNodeCard } from './BranchNodeCards/BranchNodeCard';
import { BranchNodeCardWithAnalytics } from './BranchNodeCards/BranchNodeCardWithAnalytics';

export function BranchContent({
  branchNode,
  isAnalyticsVisible,
  storyGroup,
  memorySlotData,
}) {
  return (
    <div>
      {isAnalyticsVisible
        ? (
          <BranchNodeCardWithAnalytics
            branchNode={branchNode}
          />
        )
        : (
          <BranchNodeCard
            storyGroup={storyGroup}
            branchNode={branchNode}
            memorySlotData={memorySlotData}
          />
        )}
    </div>
  );
}
