import moment from 'moment';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import imgDelete from '../../../../../assets/images/delete.svg';
import { listColumnsSize } from '../Audios/constants';
import { DeleteAudioModal } from '../DeleteAudio/DeleteAudioModal';
import classNames from './Audio.module.scss';

const convertDurationToTime = (value) => moment.utc(value * 1000).format('mm:ss');

export function Audio({
  audio,
  onChange,
}) {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);

  const handleAudioDelete = () => {
    setIsDeleteModalShown(false);
    onChange();
  };

  const handleAudioCancel = () => {
    setIsDeleteModalShown(false);
  };

  const handleDeleteClick = () => setIsDeleteModalShown(true);

  return (
    <>
      <Row className={classNames.item}>
        <Col sm={listColumnsSize.title}>
          <Col className={classNames.fieldTitle}>
            {audio.title}
          </Col>
          <Col className={classNames.fieldAlias}>
            {audio.alias}
          </Col>
        </Col>
        <Col sm={listColumnsSize.duration} className={classNames.fieldDuration}>
          {convertDurationToTime(audio.duration)}
        </Col>
        <Col sm={listColumnsSize.type} className={classNames.fieldType}>
          {audio.type}
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.fieldActions}>
          <Button
            onClick={handleDeleteClick}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
        </Col>
      </Row>
      { isDeleteModalShown && (
        <DeleteAudioModal
          audio={audio}
          onDelete={handleAudioDelete}
          onCancel={handleAudioCancel}
        />
      )}
    </>
  );
}
