import {
  MemoryShowIn,
  MemoryValue,
  StepCheck,
  StepCheckSwitch,
} from '../../../components/pages/Book/MemoryBank/memoryBankTypes';
import { Character } from '../character/Character';
import { ExpressionValue } from '../character/ExpressionValue';
import { AnswerStep } from './AnswerStep';

export enum StepTypes {
    Dialogue = 1,
    Narrator = 2,
    Choice = 3,
    Ending = 5,
    Result = 6,
    PlayerSelect = 7,
    Texting = 8,
    Thinking = 9,
    Reaction = 10,
    Check = 11,
    Remember = 12,
    Achieve = 13,
}

export enum BranchStepMemoryActionType {
    Set = 'set',
    Increase = 'increase',
    Decrease = 'decrease',
    View = 'view',
}

export type BranchStepMemoryAction =
  | { variableId: number; type: BranchStepMemoryActionType.Set; value: MemoryValue }
  | { variableId: number; type: BranchStepMemoryActionType.Increase; value: MemoryValue }
  | { variableId: number; type: BranchStepMemoryActionType.Decrease; value: MemoryValue }
  | { variableId: number; type: BranchStepMemoryActionType.View; value: Array<MemoryShowIn> };

export type BranchStep = {
    id: number;
    index: null;
    stepTypeId: StepTypes;
    subType: null;
    text: string;
    characterId: number | null;
    characterRelationship: null;
    characterExpressionId: number;
    character?: Character;
    characterPlacementSide: 'default' | 'left' | 'right';
    branchId: number;
    data: Record<string, unknown>; // {} | [];
    expression?: {
        id: number;
        title: string;
        value: ExpressionValue;
        def: boolean;
        createdAt: string; // UTC format date
        updatedAt: string; // UTC format date
    };
    answers: AnswerStep[];
    wordCount: number;
    switch?: StepCheckSwitch[];
    action?: BranchStepMemoryAction;
    check?: StepCheck;
    // Used for StepTypes.Achieve (13)
    achievementId?: number;
}
