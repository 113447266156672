import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { Options, useHotkeys } from 'react-hotkeys-hook';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';

export function usePerformanceRanksModalHotkeys() {
  const { handleSubmit } = useFormikContext<IPerformanceRankDTO[]>();

  // const handleArrowKey = (event: KeyboardEvent) => {
  //   event.preventDefault();
  //   console.log('handleArrowKey', event.key);
  // };

  // const arrowHotKeyOption: Options = useMemo(() => ({
  //   enabled: (event) => {
  //     if (event.target instanceof HTMLElement) {
  //       const isModal = event.target.className.includes('modal');
  //       const isNameInput = (/\[[0-9]].name/).test(event.target.id);
  //
  //       console.log('isModal', isModal, event.target);
  //     }
  //     return true;
  //   },
  //   enableOnFormTags: ['input'],
  //   preventDefault: false,
  // }), []);

  // useHotkeys(
  //   'ArrowUp, ArrowDown',
  //   handleArrowKey,
  //   arrowHotKeyOption,
  //   [],
  // );

  const handleSaveHotKey = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const saveHotKeyOption: Options = useMemo(() => ({
    enableOnFormTags: true,
    preventDefault: true,
  }), []);

  useHotkeys(
    'Mod+s',
    handleSaveHotKey,
    saveHotKeyOption,
    [],
  );
}
