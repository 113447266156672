export enum UserAction {
    Backgrounds = 'backgrounds',
    Characters = 'characters',
    ChoiceMemory = 'choice-memory',
    Achievements = 'achievements',
}

export const getActionLabelByAction = (action: UserAction) => {
  switch (action) {
    case UserAction.Backgrounds:
      return 'Backgrounds';
    case UserAction.Characters:
      return 'Characters';
    case UserAction.ChoiceMemory:
      return 'Choice Memory';
    case UserAction.Achievements:
      return 'Achievements';
    default:
      throw new Error(`Unknown user action: ${action}`);
  }
};

export interface User {
    'id': number,
    'username': string | null,
    'role': string,
    'fullname': string,
    'about': string | null,
    'email': string,
    'email_verified': boolean,
    'contract': boolean,
    'receive_email': boolean,
    'social_media': string,
    'device': string | null,
    'country': string | null,
    'adminShowApproved': boolean,
    'showAnalytic': boolean,
    'isVerified': boolean,
    'allowedFanSignups': number,
    'allowedCreatorSignups': number,
    'privateAppAccess': boolean,
    'firstName': string,
    'lastName': string,
    'accepted': boolean,
    'yourWriting': [],
    'monetized': boolean,
    'latestActivity': string,
    'firstPublishData': string,
    'appAccessDate': null,
    'appWizard': boolean,
    'actions': UserAction[]
}

export enum BookUserRole {
    Owner = 'owner',
    Editor = 'editor',
    CoAuthor = 'co-author',
    Exclusive = 'exclusive-access'
}

export interface BookShareUser {
    id: number,
    username?: string,
    fullname?: string,
    firstName?: string,
    lastName?: string,
    book_role: BookUserRole,
    analyticsAccess: boolean,
}

export interface UserDocument {
    acceptRequired: number,
    alias: string,
    body: string,
    createdAt: string,
    hidden: number,
    history_uuid: string,
    id: number,
    summary: string,
    title: string
    updatedAt: string,
    uuid: string,
}
