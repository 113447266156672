import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class AddTagType extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addTagType(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addTagType(obj, validated) {
    const { update, data: type } = this.props;
    if (validated === true) {
      const data = {
        title: obj.title,
        value: obj.value,
      };

      if (type !== null) {
        api.put(`/v1/answers/tags/types/${type.id}`, data)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/answers/tags/types', data)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  render() {
    const { show, onHide, data } = this.props;

    if (!show) {
      return null;
    }
    const { formError, validated } = this.state;
    let title = 'Add New Tag';
    let defTitle = '';
    let defValue = '';
    if (data !== null) {
      title = `Edit Tag ${data.title}`;
      defTitle = data.title;
      defValue = data.value;
    }

    const { update, ...other } = this.props;

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  defaultValue={defTitle}
                  name="title"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose Title.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="Value">
                <Form.Label>ID</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="ID"
                  defaultValue={defValue}
                  name="value"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose ID.
                </Form.Control.Feedback>
              </Form.Group>

            </Form.Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>

          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
