import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { AvatarCanvas } from './AvatarCanvas';
import styles from './PreviewImg.css';

const cs = classNames.bind(styles);

function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export class PreviewImg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgLoading: false,
      imgBigLoading: false,
      show: false,
      move: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      iw: 0,
      ih: 0,
      imgSrc: null,
    };
  }

  componentDidMount() {
    this.updateImageUrl();
  }

  componentDidUpdate(prevProps) {
    const { character } = this.props;
    if (JSON.stringify(prevProps.character) !== JSON.stringify(character)) {
      this.updateImageUrl();
    }
  }

  updateImageUrl = () => {
    const { aws, character } = this.props;

    if (!character || !character.image) {
      return;
    }
    const { properties } = character.image;
    if (properties && !isEmpty(properties) && properties.type !== 'custom') return;

    const src = aws
      ? `${character.image.imageUrl}?updated=${encodeURIComponent(character.image.updatedAt)}`
      : character.image.imageUrl;
    this.setState({ imgSrc: src });
  };

  render() {
    const {
      character,
      trigger,
      label,
      style,
      className,
      alias,
    } = this.props;

    if (!character || !character.image) {
      return null;
    }
    const { properties: imageProps } = character.image;

    const {
      imgSrc,
      show,
      move,
      imgLoading,
      imgBigLoading,
      h: hState,
      y: yState,
      x: xState,
      w: wState,
      iw: iwState,
      ih: ihState,
    } = this.state;

    return (
      <>
        {(imageProps && !isEmpty(imageProps) && (imageProps.type !== 'custom')) && (
        <AvatarCanvas
          properties={imageProps}
          update={(data) => {
            this.setState({ imgSrc: data });
          }}
        />
        )}

        <Card
          className={cs('CharPreviewImg text-center', className)}
          onMouseMove={(e) => {
            if (trigger === 'hover') {
              const o = 15;
              const s = 0.4;
              const p = iwState / ihState;
              const x = e.clientX + o;
              const y = e.clientY + o;
              const wx = window.innerWidth;
              const wy = window.innerHeight;
              let sx = (wx * s) + o;
              let sy = (wy * s) + o;
              if (iwState >= ihState) {
                sx = sy * p;
              }
              if (iwState <= ihState) {
                sy = sx * p;
              }
              this.setState({
                move: true,
                x: wx > x + sx ? x : x - sx - (o * 2),
                y: wy > y + sy ? y : y - sy - (o * 2),
                w: sx,
                h: sy,
              });
            }
          }}
          onMouseOut={() => {
            if (trigger === 'hover') {
              this.setState({
                move: false,
                x: 0,
                y: 0,
                w: 0,
                h: 0,
              });
            }
          }}
          onClick={(e) => {
            if (trigger !== 'hover') {
              e.stopPropagation();
              this.setState({
                show: !show,
              });
            }
          }}
        >
          <div style={style}>
            <span
              className={cs('CharPreviewImgSpinner', imgLoading ? 'd-none' : 'd-block')}
            >
              <Spinner
                variant="primary"
                animation="border"
                size="sm"
              />
            </span>

            <Card.Img
              variant="top"
              style={style}
              className={cs(imgLoading ? 'd-inline-block' : 'd-none')}
              alt={alias || 'IMG'}
              src={imgSrc}
              onError={() => this.setState({ imgLoading: true })}
              onLoad={() => this.setState({ imgLoading: true })}
            />
          </div>
          {alias
            ? (
              <Card.Body>
                <Card.Text>
                  {label || alias}
                </Card.Text>
              </Card.Body>
            )
            : null}
        </Card>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
            if (trigger !== 'hover') {
              this.setState({
                show: !show,
              });
            }
          }}
          className={cs(
            trigger === 'hover'
              ? 'CharPreviewImgModalHover'
              : 'CharPreviewImgModal',
            show || move ? 'd-block' : 'd-none',
          )}
          style={
            move ? {
              top: `${yState}px`,
              left: `${xState}px`,
            }
              : null
          }
        >
          <span className={cs('CharPreviewImgSpinner', imgBigLoading ? 'd-none' : 'd-block')}>
            <Spinner
              variant="primary"
              animation="border"
            />
          </span>
          {alias
            ? (
              <h3 className="CharPreviewImgTitle">
                <span>
                  {alias}
                </span>
              </h3>
            )
            : null}
          <img
            className={cs(imgBigLoading ? 'd-block' : 'd-none')}
            style={
              move ? {
                width: `${wState}px`,
                height: `${hState}px`,
              }
                : null
            }
            alt={alias || 'IMG'}
            src={imgSrc}
            onLoad={(e) => {
              this.setState({
                imgBigLoading: true,
                iw: e.target.width,
                ih: e.target.height,
              });
            }}
          />
        </div>
      </>
    );
  }
}
