import React from 'react';

/**
 * Allow to select of expression type
 * @param value - String. Expression name
 * @param possibleExpressions - Array. [{id, value, title}]
 * @param disabled - Bool.
 * @param onChange(newValue) - callback with new expression value
 * @returns {JSX.Element}
 */
export function ExpressionSelect({
  value: characterExpressionName,
  possibleExpressions,
  disabled,
  onChange,
}) {
  const handleSelectChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select
      value={characterExpressionName}
      disabled={disabled}
      onChange={handleSelectChange}
    >
      {
        possibleExpressions.map((expression) => (
          <option
            key={expression.id}
            value={expression.value}
          >
            {expression.title}
          </option>
        ))
      }
    </select>
  );
}
