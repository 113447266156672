import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PageWrapper } from '../../ui/PageWrapper';
import { LibraryType } from '../UploadFilesModal/types';
import { UploadFilesModal } from '../UploadFilesModal/UploadFilesModal';
import { BackgroundsList } from './BackgroundsList';
import { UploadImage } from './UploadImage';

export class BackgroundsPage extends Component {
  constructor(props) {
    super(props);
    const { history, auth } = this.props;

    this.state = {
      uploadImage: null,
    };

    this.user = auth.getUser();

    this.pageLayout = {
      header: {
        title: 'Backgrounds',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Upload Image',
            action: () => this.initUpload('single'),
            variant: 'primary',
            disabled: false,
          },
          {
            title: 'Bulk Upload Images',
            action: () => this.initUpload('bulk'),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  initUpload = (type) => {
    this.setState({ uploadImage: type });
  };

  cancelUpload = () => {
    this.setState({ uploadImage: null });
  };

  onUpload = () => {
    this.setState({ uploadImage: null });
    this.BackgroundsListEl.loadImages();
  };

  render() {
    const { uploadImage } = this.state;
    const acceptExt = { 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] };

    return (
      <>
        <PageWrapper {...this.props} page={this.pageLayout}>
          <BackgroundsList
            user={this.user}
            ref={(BackgroundsListEl) => { this.BackgroundsListEl = BackgroundsListEl; }}
          />
        </PageWrapper>
        <UploadImage
          show={uploadImage === 'single'}
          onHide={this.cancelUpload}
          update={this.onUpload}
          user={this.user}
          type="background"
        />
        {uploadImage === 'bulk' && (
          <UploadFilesModal
            onClose={this.cancelUpload}
            onUploaded={this.onUpload}
            accept={acceptExt}
            libraryType={LibraryType.Background}
          />
        )}
      </>
    );
  }
}

BackgroundsPage.propTypes = {
  auth: PropTypes.shape({
    getUser: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    length: PropTypes.number.isRequired,
  }).isRequired,
};
