import React from 'react';
import FrontImg001 from '../../../assets/images/01_neutral_reducedheight.png';
import FrontImg001rightBox from '../../../assets/images/01_reducedheight.png';
import FrontImg002rightBox from '../../../assets/images/03_champ_opensmile.png';
import FrontImg002 from '../../../assets/images/03_champ_smile.png';

export function HomeLeftImages(props) {
  const { rightBox } = props;

  return (
    <div className="mainPage-left-box">
      <div
        className={`frontImgBox FrontImg001 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
      >
        <img src={rightBox > 1 ? FrontImg001rightBox : FrontImg001} alt="" />
      </div>
      <div
        className={`frontImgBox FrontImg002 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
      >
        <img src={rightBox > 1 ? FrontImg002rightBox : FrontImg002} alt="" />
      </div>
    </div>
  );
}
