import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export class AnswerFormTags extends PureComponent {
  deleteAnswerTag = (i) => {
    const { tags, handleChangeAnswerTags } = this.props;

    const tagsCopy = tags.slice();
    tagsCopy.splice(i, 1);
    handleChangeAnswerTags(tagsCopy);
  };

  addAnswerTag = () => {
    const { tags, handleChangeAnswerTags } = this.props;
    const newTag = {
      tagTypeId: '',
      value: '',
    };

    const tagsCopy = tags.slice();
    tagsCopy.push(newTag);
    handleChangeAnswerTags(tagsCopy);
  };

  changeAnswerTag = (e, index, name) => {
    const { tags, handleChangeAnswerTags } = this.props;

    const tagsCopy = tags.slice();
    tagsCopy[index][name] = e.target.value;
    handleChangeAnswerTags(tagsCopy);
  };

  tagsOptions = () => {
    const { tagsTypes } = this.props;

    return tagsTypes.map((object) => (
      <option value={object.id} key={object.id}>
        {object.title}
      </option>
    ));
  };

  tagsList = () => {
    const {
      answerIndex,
      activeSteps,
      tags,
      restrictedEdit,
      stepIndex,
      activeStepsFunc,
    } = this.props;

    return tags.map((tag, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ListGroup.Item key={i} as="li" className={cs('py-0 pl-3 pr-1 branchesList-li')}>
        <Row
          className={cs('py-1 justify-content-between', activeSteps === `steps-${stepIndex}-answers-${answerIndex}-tags-${i}` ? 'active-item' : null)}
        >

          <Form.Group
            as={Col}
            sm={10}
            controlId={`answer_${answerIndex}_tags${i}_tagTypeId`}
            className={cs('mb-0')}
          >
            <Row>
              <Form.Label className={cs('text-right px-1')} column sm={1}>Tag</Form.Label>
              <Col className={cs('mb-0 px-1')}>
                <Form.Control
                  disabled={restrictedEdit}
                  size="sm"
                  as="select"
                  value={tag.tagTypeId || ''}
                  name={`steps[${stepIndex}][answers][${answerIndex}][tags][${i}][tagTypeId]`}
                  onFocus={() => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-tags-${i}`)}
                  onMouseDown={(e) => e.stopPropagation()}
                  onChange={(e) => this.changeAnswerTag(e, i, 'tagTypeId')}
                >
                  {this.tagsOptions()}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>

          <Col sm={2} xl={1} className="text-right">
            {!restrictedEdit && (
              <Button
                className="ml-1 btnDeleteImgSm"
                size="sm"
                variant="secondary"
                onClick={() => this.deleteAnswerTag(i)}
                onFocus={() => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-tags-${i}`)}
                onMouseDown={(e) => e.stopPropagation()}
              />
            )}
          </Col>

        </Row>
      </ListGroup.Item>
    ));
  };

  render() {
    const { showAnswerTags, restrictedEdit } = this.props;

    return (
      <Row className={cs('py-0 justify-content-between')}>
        <Col
          md={12}
          className={cs('mx-0 pb-0 pt-2 border-top', showAnswerTags === true ? 'd-block' : 'd-none')}
        >
          <Row>
            <Col md={12}>
              <h6>
                Tags
                {!restrictedEdit && (
                  <Button
                    size="sm"
                    variant="secondary"
                    className="ml-3"
                    onClick={() => this.addAnswerTag()}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    + Add Tag
                  </Button>
                )}
              </h6>
            </Col>
          </Row>

          <ListGroup as="ul" start="0" variant="flush" className="my-1 mx-1 px-0 branchesList">
            {this.tagsList()}
          </ListGroup>
        </Col>
      </Row>
    );
  }
}
