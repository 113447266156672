import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Modal, Row,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import ImgArr from '../../../assets/images/arr-002.svg';
import { api } from '../../api';
import { showToast } from '../../ui/utils';
import styles from '../Characters/Characters.scss';
import { AddLocationModal } from '../StoryBranches/AddLocationModal/AddLocationModal';
import { LibraryType, UploadFilesModalStatus } from '../UploadFilesModal/types';
import { UploadFilesModal } from '../UploadFilesModal/UploadFilesModal';
import { LocationsTableRow } from './LocationsTableRow';

const cs = classNames.bind(styles);

export class Locations extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      locations: [],
      addLocation: false,
      loading: false,
      editLocation: null,
      editDisabled: false,
      deleteDisabled: false,
      wizardStep: null,
      showUploadBackgroundModal: false,
      redirectTo: null,
    };
  }

  componentDidMount() {
    const { user, book } = this.props;

    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 9) {
      this.setState({
        wizardStep,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }

    if (book.book_role === 'brancheditor' && user.role !== 'admin') {
      this.setState({
        editDisabled: true,
        deleteDisabled: true,
      });
    }
    this.loadLocations();
  }

  loadLocations = () => {
    const { onHide, bookId } = this.props;
    const { wizardStep, locations } = this.state;

    this.setState({
      loading: true,
      editLocation: null,
      addLocation: false,
    });
    api.get(`/v1/books/${bookId}/locations`)
      .then((res) => {
        this.setState({
          locations: res.data.locations,
        }, () => {
          if (wizardStep === 9 && locations.length >= 1) {
            localStorage.setItem('wizardStep', wizardStep + 1);
            onHide();
          }
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  locationsAlert() {
    const { loading, locations } = this.state;

    if (!(locations.length < 1 && loading === true)) {
      return null;
    }

    return (
      <Alert variant="info">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Location still don't exist. Please Add One.
      </Alert>
    );
  }

  onStopEdit = () => {
    this.setState({
      addLocation: false,
      editLocation: null,
    });
  };

  onStartEdit(location) {
    this.setState({
      editLocation: location,
    });
  }

  locationsTableRow() {
    const {
      disabledEdit, bookId, limits, disabled,
    } = this.props;
    const {
      loading, locations, deleteDisabled, editDisabled,
    } = this.state;

    if (locations.length < 1) {
      // eslint-disable-next-line react/no-unescaped-entities
      return <Alert variant="info" className="my-3">Locations still don't exist. Please add location.</Alert>;
    }

    const args = {
      loading,
      onStartEdit: this.onStartEdit.bind(this),
      update: this.loadLocations.bind(this),
      bookId,
      editDisabled,
      deleteDisabled,
    };
    return locations.map((object, i) => (
      <LocationsTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        obj={object}
        args={args}
        disabled={disabled || disabledEdit}
        limits={limits}
      />
    ));
  }

  handleUploadedBackground = async (files) => {
    const { bookId, limits } = this.props;
    const { locations } = this.state;

    this.setState({ showUploadBackgroundModal: false });

    const isDefaultLocationExists = locations.some((location) => location.isDefault);

    const backgrounds = files.reduce((acc, file) => {
      if (file.status === UploadFilesModalStatus.Uploaded) {
        const isDefaultLocation = isDefaultLocationExists ? false : acc.length === 0;

        acc.push({
          title: file.name.substring(0, limits.scene_title_max.value),
          backgroundId: file.id,
          isDefault: isDefaultLocation,
          audioId: null,
          audioSettings: {
            volume: 1,
            loop: false,
          },
        });
      }
      return acc;
    }, []);

    // TODO: Use apiService from context
    const backgroundPromises = backgrounds.map((background) => api.post(`/v1/books/${bookId}/locations`, background));
    try {
      await Promise.all(backgroundPromises);
      this.loadLocations();
    } catch (error) {
      showToast({ textMessage: 'Error uploading background' });
    }
  };

  render() {
    const {
      update,
      bookId,
      bookTitle,
      disabledEdit,
      disabled,
      context,
      limits,
      ...other
    } = this.props;
    const {
      loading, editLocation, wizardStep, locations, addLocation, showUploadBackgroundModal, redirectTo,
    } = this.state;

    if (redirectTo) {
      return <Redirect push to={redirectTo} />;
    }

    const modalTitle = bookTitle ? `: ${bookTitle}` : `# ${bookId}`;
    const isAddingDisabled = addLocation
      || !!editLocation
      || disabled
      || disabledEdit;

    return (
      <>
        <Modal
          {...other}
          disabled={disabled}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Locations
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.locationsAlert()}
            <div className={cs('wrapperTable')}>
              <Container>
                <Row>
                  <Col className="text-right">
                    <Button
                      disabled={isAddingDisabled}
                      size="sm"
                      variant="outline-primary"
                      onClick={() => {
                        this.setState({ showUploadBackgroundModal: true });
                      }}
                      className="ml-2 px-3"
                    >
                      Upload art
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="px-3 my-0 ml-2"
                      onClick={() => this.setState({ redirectTo: '/backgrounds' })}
                    >
                      Art Library
                    </Button>
                    <Button
                      disabled={isAddingDisabled}
                      size="sm"
                      variant="primary"
                      className="ml-2"
                      onClick={() => {
                        this.setState({
                          addLocation: true,
                        });
                      }}
                    >
                      + Add Location
                    </Button>

                  </Col>
                </Row>
                <Row className="py-2 border-bottom align-items-center">
                  <Col md={1} className="h6 mb-0 text-nowrap text-center">Default</Col>
                  <Col md={4} className="h6 mb-0 text-nowrap">Title</Col>
                  <Col md={3} className="h6 mb-0 text-nowrap">Alias</Col>
                  <Col md={1} className="h6 mb-0 text-nowrap">Image</Col>
                  <Col md={3} className="h6 mb-0 text-nowrap text-right">Actions</Col>
                </Row>
                {this.locationsTableRow()}
                <AddLocationModal
                  loading={loading}
                  disabled={disabled}
                  show={addLocation || !!editLocation}
                  onHide={this.onStopEdit}
                  onSuccess={this.loadLocations}
                  bookId={bookId}
                  limits={limits}
                  isFirstLocationInStory={locations.length === 0}
                  storyLocationToEdit={addLocation ? null : editLocation}
                />
              </Container>
              <div
                className={cs({ overlay: loading || showUploadBackgroundModal })}
              />
              <span
                className={`wizardBtnBox ${wizardStep === 9 && locations.length < 1 ? 'wizardActive' : ''}`}
              >
                {(wizardStep === 9 && locations.length < 1) && (
                <div className="sidebarArr">
                  <img src={ImgArr} alt="" />
                  <span>please create one Location</span>
                </div>
                )}
              </span>
            </div>
          </Modal.Body>
        </Modal>
        {showUploadBackgroundModal && (
          <UploadFilesModal
            onClose={() => this.setState({ showUploadBackgroundModal: false })}
            onUploaded={this.handleUploadedBackground}
            accept={{ 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'] }}
            libraryType={LibraryType.Background}
          />
        )}
      </>
    );
  }
}
