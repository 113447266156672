import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { api } from '../../../api';
import { Panel } from '../Panel';
import { FaqForm } from './FaqForm';
import styles from './HelpSection.css';

const cs = classNames.bind(styles);

export class FaqPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loading: false,
      addForm: false,
      editPage: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show && show !== prevProps.show) {
      this.loadPage();
    }
  }

  loadPage() {
    const { pageTitle } = this.props;

    this.setState({
      loading: true,
    });

    api.get(`/v1/help/pages/${pageTitle}`)
      .then((res) => {
        this.setState({
          page: res.data.page,
          loading: false,
        });
      });
  }

  formHide = () => {
    this.setState({
      editPage: false,
      addForm: false,
    });
  };

  formUpdate = () => {
    this.formHide();
    this.loadPage();
  };

  render() {
    const {
      show, user, onHide, pageTitle,
    } = this.props;
    const {
      addForm, loading, page, editPage,
    } = this.state;

    const showEdit = user !== undefined ? user.role === 'admin' : false;

    return (
      <Panel
        pageTitle={pageTitle === 'faq' ? 'Help' : pageTitle}
        show={show}
        onHide={onHide}
        loading={loading}
      >
        {showEdit && (
          <Row className={cs('my-1')}>
            <Col className={cs('text-right')}>
              <Button
                disabled={(addForm)}
                variant="secondary"
                size="sm"
                onClick={() => {
                  this.setState({
                    editPage: true,
                  });
                }}
              >
                Edit
              </Button>
            </Col>
          </Row>
        )}
        {addForm && (
          <FaqForm
            formHide={this.formHide}
            update={this.formUpdate}
            user={user}
          />
        )}
        {editPage ? (
          <FaqForm
            formHide={this.formHide}
            update={this.formUpdate}
            user={user}
            obj={page}
          />
        ) : (
          <div
            className="pageFAQContent"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: page ? page.content : '' }}
          />
        )}

      </Panel>
    );
  }
}
