import { StepCheck, StepCheckSwitch } from '../../../components/pages/Book/MemoryBank/memoryBankTypes';
import { BranchAnalytics } from './BranchAnalytics';
import { BranchStep } from './BranchStep';

export enum BranchDetailTypes {
    ENDING = 'ending',
    STORY = 'story',
    CHOICE = 'choice',
}

export enum StepTypeMode {
    Regular = 'regular',
    Elimination = 'elimination',
}

export type Branch = {
    id: number;
    title: string;
    description: string;
    uuid: string;
    gotoBranchId: number;
    x: number;
    y: number;
    locationId: number;
    bgColor: null;
    pos: null;
    location_title: string;
    bonus_content: boolean;
    links: [];
    traits: [];
    answers: [];
    type: BranchDetailTypes;
    steps_count: number;
    words_count: number;
    steps?: BranchStep[]
    analytics?: BranchAnalytics;
    switch?: StepCheckSwitch[],
    check?: StepCheck,
    stepTypeMode?: StepTypeMode;
}
