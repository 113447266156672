import React, { PureComponent } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { recStats } from '../constants';

export class OptionStatsItem extends PureComponent {
  constructor(props) {
    super(props);
    const { row, stats } = this.props;

    this.state = {
      value: !stats || !stats[row.id] || Number(stats[row.id]) === 0 ? recStats : stats[row.id],
    };
  }

  render() {
    const { index, row } = this.props;
    const { value } = this.state;

    return (
      <Form.Group key={index} as={Col} sm={4} xs={12}>
        <InputGroup size="sm">
          <InputGroup.Prepend>
            <InputGroup.Text>{row.title}</InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="number"
            placeholder={row.title}
            name={`recommendations[stats][${row.id}]`}
            value={value}
            onChange={(e) => {
              this.setState({
                value: e.target.value >= 0 ? e.target.value : '',
              });
            }}
          />
        </InputGroup>
      </Form.Group>
    );
  }
}
