import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

export class CheckedApprove extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      checked: false,
    };
  }

  componentDidMount() {
    this.checked();
  }

  componentDidUpdate(prevProps) {
    const { checkedApprove } = this.props;
    if (prevProps.checkedApprove !== checkedApprove) {
      this.checked();
    }
  }

  checked = () => {
    const { row, checkedApprove } = this.props;
    const check = Boolean(checkedApprove.find((a) => a.id === row.id));
    this.setState({
      checked: check,
    });
  };

  render() {
    const { checked } = this.state;

    const handleClick = () => {
      const { update, row, checkedApprove } = this.props;
      const check = Boolean(checkedApprove.find((a) => a.id === row.id));
      const val = [];
      checkedApprove.forEach((a) => {
        if (a.id !== row.id) {
          val.push(a);
        }
      });
      if (!check) {
        val.push(row);
      }
      update(val);
    };

    return (
      <Form.Group
        onClick={handleClick}
      >
        <Form.Check
          custom
          name="Approve"
          autoFocus
          as="input"
          checked={checked}
          type="checkbox"
          label=""
        />
      </Form.Group>
    );
  }
}
