import { BugTrackerType, IFrontendBugTrackerConfig } from '@dorian/frontend-bug-tracker';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

interface BugTrackingSettingsFormProps {
    bugTrackerConfig: IFrontendBugTrackerConfig,
    onChange: (config: IFrontendBugTrackerConfig) => void
    onReload: () => void
    disabled?: boolean
}

export function BugTrackingSettingsForm(props: BugTrackingSettingsFormProps) {
  const {
    bugTrackerConfig, onChange, onReload, disabled,
  } = props;

  const [config, setConfig] = useState(bugTrackerConfig);

  const handleSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    onChange(config);
  };

  return (
    <Form inline onSubmit={handleSubmit}>
      <Form.Label className="my-1 mr-2" htmlFor="bugTrackerType">
        Service type:
      </Form.Label>
      <Form.Control
        disabled={disabled}
        as="select"
        className="my-1 mr-sm-2"
        id="bugTrackerType"
        custom
        value={config.bugTrackerType}
        onChange={(event) => setConfig(
          { ...config, bugTrackerType: event.target.value as BugTrackerType },
        )}
      >
        <option value={BugTrackerType.Null}>{BugTrackerType.Null}</option>
        <option value={BugTrackerType.Console}>{BugTrackerType.Console}</option>
        <option value={BugTrackerType.Sentry}>{BugTrackerType.Sentry}</option>
      </Form.Control>
      <Form.Label className="my-1 mr-2" htmlFor="bugTrackerSampleRate">
        Sample rate:
      </Form.Label>
      <Form.Control
        disabled={disabled}
        className="my-1 mr-sm-2"
        style={{ width: '80px' }}
        id="bugTrackerSampleRate"
        value={config.init.sampleRate}
        type="number"
        min={0}
        max={1}
        step={0.1}
        onChange={(event) => {
          const newConfig: IFrontendBugTrackerConfig = {
            ...config,
            init: {
              ...config.init,
              sampleRate: Number(event.target.value),
            },
          };
          setConfig(newConfig);
        }}
        onKeyPress={(event: { preventDefault: () => void; }) => event.preventDefault()}
      />
      <Form.Label className="my-1 mr-2" htmlFor="bugTrackerTraceSampleRate">
        Trace sample rate:
      </Form.Label>
      <Form.Control
        disabled={disabled}
        className="my-1 mr-sm-2"
        style={{ width: '80px' }}
        id="bugTrackerTraceSampleRate"
        type="number"
        min={0}
        max={1}
        step={0.1}
        value={config.init.tracesSampleRate}
        onChange={(event) => {
          const newConfig: IFrontendBugTrackerConfig = {
            ...config,
            init: {
              ...config.init,
              tracesSampleRate: Number(event.target.value),
            },
          };
          setConfig(newConfig);
        }}
        onKeyPress={(event: { preventDefault: () => void; }) => event.preventDefault()}
      />
      <Button
        disabled={disabled}
        size="sm"
        type="submit"
        className="m-2"
      >
        Save
      </Button>
      <Button
        size="sm"
        className="m-2"
        variant="info"
        onClick={onReload}
      >
        Reload
      </Button>
    </Form>
  );
}
