import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { Options, useHotkeys } from 'react-hotkeys-hook';
import { IAchievementModal } from '../AchievementsModalTypes';
import { newAchievement } from '../utils';

export function useAchievementModalHotkeys() {
  const { setValues, handleSubmit } = useFormikContext<IAchievementModal[]>();

  const handleAchievementCreate = useCallback(() => {
    setValues((prev) => {
      const index = prev.length;
      setTimeout(() => {
        document.getElementById(`[${index}].name`)?.focus();
      }, 0);
      return [...prev, newAchievement()];
    });
  }, [setValues]);

  const handleCreateHotKey = () => {
    // Used in options because bootstrap dropdown doesn't work if enabled is false
    // handleAchievementCreate();
  };

  const createHotKeyOption: Options = useMemo(() => ({
    enabled: (event) => {
      if (event.target instanceof HTMLElement) {
        const isModal = event.target.className.includes('modal');
        const isNameInput = (/\[[0-9]].name/).test(event.target.id);
        const isCheckValueInput = (/\[[0-9]].check.value/).test(event.target.id);
        const isDisplayNameInput = (/\[[0-9]].displayName/).test(event.target.id);
        const isDescriptionInput = (/\[[0-9]].description/).test(event.target.id);
        if (isModal
          || isNameInput
          || isCheckValueInput
          || isDisplayNameInput
          || isDescriptionInput
        ) {
          // Used here because bootstrap dropdown doesn't work if enabled is false
          handleAchievementCreate();
        }
      }
      return true;
    },
    enableOnFormTags: ['input'],
    preventDefault: false,
  }), [handleAchievementCreate]);

  useHotkeys(
    'Mod+Shift+A, Enter',
    handleCreateHotKey,
    createHotKeyOption,
    [],
  );

  const handleSaveHotKey = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const saveHotKeyOption: Options = useMemo(() => ({
    enableOnFormTags: true,
    preventDefault: true,
  }), []);

  useHotkeys(
    'Mod+s',
    handleSaveHotKey,
    saveHotKeyOption,
    [],
  );

  return handleAchievementCreate;
}
