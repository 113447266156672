import React, { Component } from 'react';
import {
  Button, Col, FormControl, InputGroup, Modal, Row,
} from 'react-bootstrap';
import { api } from '../../../api';
import './NodePositioningModal.css';

export class NodePositioningModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      x: '1.0',
      y: '1.0',
    };
  }

  update = () => {
    const { branch } = this.props;
    const { y, x } = this.state;

    const branches = branch.map((obj) => ({
      id: obj.id,
      data: {
        x: Math.round(Number(obj.x) * Number(x)),
        y: Math.round(Number(obj.y) * Number(y)),
      },
    }));
    this.saveChanges({ branches });
  };

  saveChanges(data) {
    const { update, storyId, onHide } = this.props;

    api.patch(`/v1/stories/${storyId}/branches`, data)
      .then(() => {
        update();
        onHide();
      });
  }

  render() {
    const { show, onHide } = this.props;
    const { y, x } = this.state;

    if (!show) {
      return null;
    }

    return (
      <Modal.Dialog className="NodePositioningModal">

        <Modal.Header closeButton onHide={onHide}>
          <Modal.Title>Node Positioning</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="NodePositioning-X">X</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="NodePositioning-X"
                  value={x}
                  placeholder="1.0"
                  step="0.1"
                  min="0.1"
                  type="number"
                  pattern="\d+(\.\d{1})?"
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      this.setState({
                        x: e.target.value,
                      });
                    }
                  }}
                />
              </InputGroup>
            </Col>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="NodePositioning-Y">Y</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="NodePositioning-Y"
                  value={y}
                  placeholder="1.0"
                  step="0.1"
                  min="0.1"
                  type="number"
                  pattern="\d+(\.\d{1})?"
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      this.setState({
                        y: e.target.value,
                      });
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </Row>

        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => this.update()}
          >
            Update Positioning
          </Button>
        </Modal.Footer>

      </Modal.Dialog>
    );
  }
}
