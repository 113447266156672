import async from 'async';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useApiService } from '../../../../../contexts/ApiServiceContext/ApiServiceContext';
import { useMemoryBankContext } from '../../../../../contexts/MemoryBankContext/MemoryBankContext';
import { useMemoryBankServiceContext } from '../../../../../contexts/MemoryBankContext/MemoryBankServiceContext';
import { useMemoryIconsContext } from '../../../../../contexts/MemoryIconsContext/MemoryIconsContext';
import { useAsyncOperationState } from '../../../../../dorian-shared/hooks/useAsyncOperationState';
import { Character } from '../../../../../dorian-shared/types/character/Character';
import { bugTracker } from '../../../../../services/bugTracker/BugTrackerService';
import { MemoryBankPostRequest, MemoryIconPostRequest } from '../../../../../services/memoryBankService/types';
import { showToast } from '../../../../ui/utils';
import { memoryBankFormValidationSchema } from '../memoryBankFormValidationSchema';
import {
  ChangeMemoryEvent, MemoryDTO, MemoryFormDTO, MemoryIcon, MemoryType,
} from '../memoryBankTypes';
import { convertMemoryDTOToFormDTO, convertMemoryFormDTOToMemoryDTO, isMemoryDTOEqual } from '../memoryBankUtils';

export function useMemoryBankForm(bookId: number, userId: number, onHide: () => void) {
  const [memoryBank, setMemoryBank] = useState<MemoryDTO[]>([]);
  const [characters, setCharacters] = useState<Character[] | null>(null);
  const [memoryIcons, setMemoryIcons] = useState<MemoryIcon[]>([]);
  const [usedMemoryIdsInLiveEpisodes, setUsedMemoryIdsInLiveEpisodes] = useState<number[]>([]);

  const memoryBankContext = useMemoryBankContext();
  const memoryIconsContext = useMemoryIconsContext();

  const [
    ,
    {
      isLoading,
      setToLoading,
      setToSuccess,
      isError,
      setToError,
    },
  ] = useAsyncOperationState();

  const memoryBankService = useMemoryBankServiceContext();
  const apiService = useApiService();

  const updateMemory = async (memory: MemoryFormDTO) => {
    const originalMemory = memoryBank.find((s) => s.id === memory.id);
    if (!originalMemory) {
      showToast({ textMessage: 'Can\'t find original memory', variant: 'danger' });
      return;
    }
    const newMemory = convertMemoryFormDTOToMemoryDTO(memory);

    if (!isMemoryDTOEqual(newMemory, originalMemory)) {
      try {
        await memoryBankService.updateMemory(newMemory);
      } catch (error) {
        if (error instanceof Error) {
          bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
        } else {
          bugTracker().reportError({ name: 'MemoryBankForm', message: `Can't update memory: ${newMemory.name}` });
        }
        showToast({ textMessage: `Can't update memory: ${newMemory.name}`, variant: 'danger' });
      }
    }
  };

  const submitHandle = (sendValues: { memoryBankSlots: MemoryFormDTO[] }) => {
    const memoryValues = sendValues.memoryBankSlots;
    const promises = memoryValues.map((memory) => async () => await updateMemory(memory));
    async.parallelLimit(promises, 1)
      .then(() => {
        showToast({ textMessage: 'Memory bank updated', variant: 'success' });
        onHide();
      })
      .catch((error) => {
        if (error instanceof Error) {
          bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
        } else {
          bugTracker().reportError({ name: 'MemoryBankForm', message: 'Can\'t update memory' });
        }
        showToast({ textMessage: 'Can\'t update memory', variant: 'danger' });
      });
  };

  const formik = useFormik({
    validationSchema: memoryBankFormValidationSchema,
    initialValues: {
      memoryBankSlots: memoryBank.map((memory) => convertMemoryDTOToFormDTO(memory)),
    },
    onSubmit: submitHandle,
  });

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleChange,
  } = formik;

  useEffect(() => {
    memoryBankContext.setBookId(bookId);
    memoryIconsContext.setBookId(bookId);
  }, [bookId, memoryBankContext, memoryIconsContext]);

  useEffect(() => {
    setMemoryBank(memoryBankContext.memories);
    setFieldValue('memoryBankSlots', memoryBankContext.memories.map((slot) => convertMemoryDTOToFormDTO(slot)));
    setMemoryIcons(memoryIconsContext.memoryIcons);
  }, [memoryBankContext.memories, memoryIconsContext.memoryIcons, setFieldValue]);

  const loadData = useCallback(async () => {
    setToLoading();
    try {
      const charactersData = await apiService.fetchCharactersByBookId(bookId);
      setCharacters(charactersData);
    } catch (e) {
      setToError();
      showToast({ textMessage: 'Can\'t load characters', variant: 'danger' });
    }
    if (!isError) {
      setToSuccess();
    }
  }, [apiService, bookId, isError, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleMemoryChange = async (
    event: ChangeMemoryEvent,
    memoryIndex: number,
  ) => {
    const targetId = event?.target?.id;

    const typeId = `memoryBankSlots[${memoryIndex}].type`;
    const memoryBankValues = [...values.memoryBankSlots];
    const newMemory = memoryBankValues[memoryIndex];

    switch (targetId) {
      case typeId:
        // Clear value if type is changed
        // 'id' is defined in <MemoryTypeField>
        newMemory.value = '';
        break;
      default:
        break;
    }

    memoryBankValues[memoryIndex] = newMemory;
    setFieldValue('memoryBankSlots', memoryBankValues);
    handleChange(event);
  };

  const handleMemoryDelete = async (memoryIndex: number) => {
    setToLoading();
    try {
      const memoryBankValues = values.memoryBankSlots[memoryIndex];
      const memoryToDelete = convertMemoryFormDTOToMemoryDTO(memoryBankValues);
      await memoryBankService.deleteMemory(memoryToDelete);
      const newMemoryBank = memoryBank.filter((memory) => memory.id !== memoryToDelete.id);
      setMemoryBank(newMemoryBank);
      setFieldValue('memoryBankSlots', values.memoryBankSlots.filter((_, index) => index !== memoryIndex));
      setToSuccess();
    } catch (error) {
      setToError();
      if (error instanceof Error) {
        bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
      } else {
        bugTracker().reportError({ name: 'MemoryBankForm', message: 'An unknown error occurred in handleMemoryDelete' });
      }
      showToast({ textMessage: 'Can\'t delete memory', variant: 'danger' });
    }
  };

  const handleMemoryCreate = useCallback(async () => {
    const memoryName = `var_${Math.floor(Math.random() * 1000)}`;
    const requestData: MemoryBankPostRequest = {
      name: memoryName,
      type: MemoryType.String,
      defaultValue: memoryName,
      showIn: [],
      displayName: '',
      icon: -1,
      defaultChangeDescription: '',
    };

    setToLoading();
    try {
      const newMemory: MemoryDTO = await memoryBankService.createMemory(bookId, requestData);
      setMemoryBank([...memoryBank, newMemory]);
      setFieldValue('memoryBankSlots', [...values.memoryBankSlots, convertMemoryDTOToFormDTO(newMemory)]);
      setToSuccess();
    } catch (error) {
      setToError();
      if (error instanceof Error) {
        bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
      } else {
        bugTracker().reportError({
          name: 'MemoryBankForm',
          message: 'An unknown error occurred in handleMemoryCreate',
        });
      }
      showToast({ textMessage: 'Can\'t add memory', variant: 'danger' });
    }
  }, [
    bookId,
    memoryBank,
    memoryBankService,
    setFieldValue,
    setToError,
    setToLoading,
    setToSuccess,
    values.memoryBankSlots,
  ]);

  const handleMemoryIconAdd = async (image: File, label: string): Promise<MemoryIcon> => {
    const memoryIconPostRequest: MemoryIconPostRequest = {
      label,
      bookId,
      image,
    };
    const memoryIconResponse = await apiService.createMemoryIconByUserId(userId, memoryIconPostRequest);
    setMemoryIcons([...memoryIcons, memoryIconResponse]);
    return memoryIconResponse;
  };

  const handleKeyDown = useCallback(async (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey) {
        handleSubmit();
        return;
      }

      if (event.target instanceof HTMLDivElement) {
        if (event.target.role === 'dialog') {
          await handleMemoryCreate();
        }
      }

      if (event.target instanceof HTMLInputElement) {
        const elementId = event.target.id;

        const namePattern = /memoryBankSlots\[(\d+)\]\.name/;
        const namePatternMatch = elementId.match(namePattern);

        const valuePattern = /memoryBankSlots\[(\d+)\]\.value/;
        const valuePatternMatch = elementId.match(valuePattern);

        const descriptionPattern = /memoryBankSlots\[(\d+)\]\.defaultChangeDescription/;
        const descriptionPatternMatch = elementId.match(descriptionPattern);

        if (
          valuePatternMatch
          || descriptionPatternMatch
          || namePatternMatch
        ) {
          await handleMemoryCreate();
        }
      }
    }
  }, [handleMemoryCreate, handleSubmit]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setToLoading();
    apiService.fetchUsedMemoryIdsInLiveEpisodesByBookId(bookId).then((usedMemories: number[]) => {
      setUsedMemoryIdsInLiveEpisodes(usedMemories);
      setToSuccess();
    }).catch((error) => {
      if (error instanceof Error) {
        bugTracker().reportError({ name: 'MemoryBankForm', message: error.message });
      } else {
        bugTracker().reportError({ name: 'MemoryBankForm', message: `Can't fetch used memories in live episodes: ${error}` });
      }
      showToast({ textMessage: 'Can\'t fetch used memories in live episodes' });
      setToError('Can\'t fetch used memories in live episodes');
    });
  }, [apiService, bookId, setToError, setToLoading, setToSuccess]);

  return {
    characters,
    isLoading,
    handleSubmit,
    values,
    errors,
    handleMemoryChange,
    handleMemoryDelete,
    handleMemoryCreate,
    handleMemoryIconAdd,
    memoryIcons,
    usedMemoryIdsInLiveEpisodes,
  };
}
