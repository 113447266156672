import { isEqual } from 'lodash-es';
import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { IAchievementModal } from '../AchievementsModalTypes';
import { AchievementsModalContextProviderProps, AchievementsModalContextType } from './AchievementsModalContextTypes';

export const AchievementsModalContext = createContext<AchievementsModalContextType | undefined>(undefined);

export function AchievementsModalContextProvider(props: AchievementsModalContextProviderProps) {
  const { children, achievements: initialAchievements, onHide } = props;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [achievements, setAchievements] = useState<IAchievementModal[]>(initialAchievements);

  useEffect(() => {
    setAchievements(initialAchievements);
  }, [initialAchievements]);

  const hasValueChanged = useCallback((value: IAchievementModal) => {
    const hasChanged = value.isAdded || !isEqual(value, achievements.find((a) => a.id === value.id));
    return hasChanged;
  }, [achievements]);

  const haveValuesChanged = useCallback((values: IAchievementModal[]) => values.some((value) => hasValueChanged(value)), [hasValueChanged]);

  const value: AchievementsModalContextType = useMemo(() => ({
    isFormLoading,
    setIsFormLoading,
    onHide,
    achievements,
    setAchievements,
    hasValueChanged,
    haveValuesChanged,
  }), [achievements, hasValueChanged, haveValuesChanged, isFormLoading, onHide]);

  return (
    <AchievementsModalContext.Provider value={value}>
      {children}
    </AchievementsModalContext.Provider>
  );
}

export const useAchievementsModalContext = () => {
  const context = React.useContext(AchievementsModalContext);
  if (context === undefined) {
    throw new Error('useAchievementsModalContext must be used within a AchievementsModalContextProvider');
  }
  return context;
};
