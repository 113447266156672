import { InitEditorOptions } from '@dorian/creation-tools-ui';
import { useEffect, useState } from 'react';
import { getRuntimeEnv } from '../../../../helpers/runtimeEnv';
import { RuntimeEnv } from '../../../../helpers/RuntimeEnvEnum';
import { AvatarSystem } from './avatar-system';
import { getBaseUrl, getEnvPrefix } from './getManifestUrlByRuntimeEnv';
import { loadAvatarSystemConfig } from './loadAvatarSystem';

type GameplayRendererOptions = InitEditorOptions['gameplayRendererOptions'];
type AvatarResolver = GameplayRendererOptions['avatarResolver']
const runtimeEnv = getRuntimeEnv();
const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';
const envPrefix = getEnvPrefix(envLabel);
const assetsUrl = getBaseUrl(envLabel);

export function useAvatarResolver() {
  const [avatarResolver, setAvatarResolver] = useState<AvatarResolver | null>(null);
  useEffect(() => {
    (async () => {
      // I assume Date.now() was used here to avoid cache strategy of AWS
      // TODO: fix AWS and remove this hack from here and client

      const avatarSystemConfig = await loadAvatarSystemConfig(assetsUrl, envPrefix);
      const avatarSystem = new AvatarSystem({
        ...avatarSystemConfig,
        // in client there are also local assets which are preferred, in engine we don't have it
        localAssetManifest: {},
        localAssetURL: '',
      });
      setAvatarResolver(avatarSystem);
    })();
  }, []);
  return avatarResolver;
}
