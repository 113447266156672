import React, { Component } from 'react';
import {
  Button, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import Moment from 'react-moment';
import imgDelete from '../../../assets/images/delete.svg';
import { api } from '../../api';
import { getUserDisplayName } from '../Users';
import { AddComment } from './addComment';
import './comments.css';
import { DeleteComment } from './DeleteComment/DeleteComment';

export class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      deleteComment: null,
      data: {},
    };
    // eslint-disable-next-line react/destructuring-assignment
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.get(`v1/blog/posts/${this.props.match.params.id}/comments`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.comments,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const deleteComment = () => {
      const { deleteComment: deleteCommentState } = this.state;
      const { match } = this.props;
      // eslint-disable-next-line react/destructuring-assignment
      if (deleteCommentState && this.user.role === 'admin') {
        return (
          <DeleteComment
            {...this.props}
            show
            onHide={() => {
              this.setState({
                deleteComment: null,
              });
            }}
            postId={match.params.id}
            obj={deleteCommentState}
            update={() => {
              this.setState({
                deleteComment: null,
              });
              this.loadData();
            }}
          />
        );
      }
      return null;
    };

    return (
      <div className="commentsList">

        <h3 className="pt-3">Comments</h3>

        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.data.length > 0
            // eslint-disable-next-line react/destructuring-assignment
            ? this.state.data.map((obj, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Card key={i} className="my-3">
                <Card.Title className="px-3 pt-3">
                  <Row>
                    <Col>
                      <b>{getUserDisplayName(obj.user)}</b>
                      {' '}
                      commented on
                      {' '}
                      <Moment
                        element="span"
                        format="YYYY-MM-DD hh:mm A"
                        date={obj.createdAt}
                      />
                    </Col>
                    {this.user.role === 'admin'
                      ? (
                        <Col className="justify-content-end text-right">
                          <span className="tooltip-box">
                            <Button
                              variant="secondary"
                              size="sm"
                              className="mx-1"
                              onClick={() => {
                                this.setState({
                                  deleteComment: obj,
                                });
                              }}
                            >
                              <img src={imgDelete} className="btnImg" alt="Delete" />
                            </Button>
                            <div className="tooltip">
                              <div className="arrow" />
                              <div className="tooltip-inner">Delete</div>
                            </div>
                          </span>
                        </Col>
                      )
                      : null}
                  </Row>
                </Card.Title>
                <Card.Body className="py-1">
                  <p>{obj.message}</p>
                </Card.Body>
              </Card>
            ))
            : null
        }

        {

            // eslint-disable-next-line react/destructuring-assignment
            this.state.loading

              ? (
                <div className="boxLoading">
                  <div className="boxSpinner"><Spinner animation="border" variant="primary" /></div>
                </div>
              )
              : (
                <AddComment
                  update={this.loadData}
                  {...this.props}
                />
              )
        }

        {deleteComment()}
      </div>
    );
  }
}
