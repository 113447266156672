import cs from 'classnames';
import React, { Component } from 'react';
import {
  Alert,
  Button, Card, Col, Form, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import imgDelete from '../../../../assets/images/delete.svg';
import { ApiService } from '../../../../services/apiService/ApiService';
import { api } from '../../../api';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { showToast } from '../../../ui/utils';
import { getImageOwnerDisplayName } from '../../CharacterArtLibrary/CharacterArtLibraryItem';
import { ImagePageSizeSelector } from '../../PageSelector/ImagePageSizeSelector';
import { SearchBar } from '../../SearchBar/SearchBar';
import { DeleteImageModal } from '../DeleteImageModal/DeleteImageModal';
import { EditAlias } from '../EditAlias';
import { UploadImage } from '../UploadImage';
import { BackgroundListHeader } from './BackgroundListHeader';
import './BackgroundsList.scss';

export class BackgroundsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      selectedImage: null,
      selectedImageIds: [],
      loading: true,
      uploadImage: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 7,
      searchString: '',
      showDeleteModal: false,
      showOnlyUserImages: true,
    };
    this.apiService = new ApiService(api);
  }

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const {
      currentPage, searchString, pageSize, showOnlyUserImages, images,
    } = this.state;

    if ((prevState.currentPage !== currentPage)
        || (prevState.searchString !== searchString)
        || (prevProps.user !== user)
        || (prevState.pageSize !== pageSize)
      || (prevState.showOnlyUserImages !== showOnlyUserImages)
    ) {
      this.loadImages();
    }
    if (prevState.images !== images) {
      const selectedImage = images.find((image) => image.id === prevState.selectedImage?.id);
      this.setState({ selectedImage });
    }
  }

  loadImages = () => {
    const { user } = this.props;
    const {
      currentPage, pageSize, searchString, showOnlyUserImages,
    } = this.state;

    this.setState({
      loading: true,
    });

    const params = {
      all: true, // show also disabled backgrounds in this list so admin can enable/disable them
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      search: searchString || undefined,
      order: 'createdAt:desc',
      authorId: showOnlyUserImages ? user?.id : undefined,
    };

    api.get('/v1/backgrounds', { params })
      .then((res) => {
        const { images } = res.data;

        this.setState({
          loading: false,
          images,
          totalCount: res.data.totalCount,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  cancelUpload = () => {
    this.setState({ uploadImage: false });
  };

  updateUpload = () => {
    this.setState({ uploadImage: false });
    this.loadImages();
  };

  handleImageClick(selectedImage) {
    this.setState({ selectedImage });
  }

  async handleReplaceImageClick() {
    const { selectedImage } = this.state;
    const { user } = this.props;

    this.setState({ loading: true });
    const isAdmin = user.role === 'admin';

    try {
      const usedArtBooks = await this.apiService.fetchUsedArtBooksByBackgroundId(selectedImage.id);
      if (usedArtBooks.length === 0 || isAdmin) {
        this.setState({ uploadImage: true });
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
    showToast({ textMessage: 'This image is used in Books and cannot be replaced', variant: 'danger' });
  }

  renderLoadingSpinner() {
    const { loading } = this.state;

    if (!loading) return null;

    return (
      <>
        <div className="text-center">
          <Spinner
            variant="primary"
            animation="border"
            className="loadingSpinner justify-content-center"
          />
        </div>
        <div className="overlay" />
      </>
    );
  }

  renderImageItems() {
    const { user } = this.props;
    const {
      images, selectedImage, selectedImageIds, loading,
    } = this.state;

    const isAdmin = user.role === 'admin';

    return images.map((image) => {
      const isSelected = selectedImageIds.includes(image.id);
      const isActive = selectedImage?.id === image.id;

      return (
        <ListGroup.Item
          as="div"
          key={image.id}
          className={cs({
            activeImage: isActive,
            selectedImage: isActive ? undefined : isSelected,
          })}
          onClick={(event) => {
            if (event.ctrlKey || event.metaKey) {
              const isExist = selectedImageIds.includes(image.id);
              this.setState((prevState) => ({
                selectedImageIds: isExist
                  ? prevState.selectedImageIds.filter((el) => el !== image.id)
                  : [...prevState.selectedImageIds, image.id],
              }));
            }
            this.handleImageClick(image);
          }}
        >
          <Row className="align-items-center">
            {/* Select checkbox */}
            <Col sm="auto">
              <Form.Check
                custom
                id={image.id}
                checked={isSelected}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState({ selectedImageIds: [...selectedImageIds, image.id] });
                  } else {
                    this.setState({
                      selectedImageIds: selectedImageIds.filter((el) => el !== image.id),
                    });
                  }
                }}
              />
            </Col>
            {/* Image preview */}
            <Col sm="auto" className="pr-2">
              <img
                style={{
                  height: 50,
                  width: 50,
                  objectFit: 'contain',
                }}
                src={image.imageUrl}
                alt={image.alias}
              />
            </Col>
            {/* Label */}
            <Col className="text-truncate">
              {image.label}
              <br />
              {isAdmin && (
                <i>{getImageOwnerDisplayName(image.user)}</i>
              )}
            </Col>
            {/* Disabled */}
            <Col sm={1}>
              {image.disabled}
              <div className={`${image.disabled === true ? 'checked' : ''} circleBox`} />
            </Col>
            {/* Actions */}
            <Col sm={2} className="text-right px-0">
              {selectedImageIds.length === 0 && (
                <>
                  <EditAlias
                    obj={selectedImage}
                    update={this.loadImages}
                    type="Backgrounds"
                    user={user}
                  />
                  <Button
                    onClick={() => this.setState({ selectedImage: image, showDeleteModal: true })}
                    variant="secondary"
                    size="sm"
                    className="ml-1"
                    disabled={loading}
                  >
                    <img src={imgDelete} className="btnImg" alt="Delete" />
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </ListGroup.Item>
      );
    });
  }

  renderPagination() {
    const { totalCount, currentPage, pageSize } = this.state;

    return (
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={(page) => {
          this.setState({ currentPage: page, selectedImage: null, selectedImageIds: [] });
        }}
      />
    );
  }

  renderImagePreview() {
    const { selectedImage, selectedImageIds, loading } = this.state;

    if (!selectedImage) return null;
    return (
      <div className="stickyImgBox">
        <h5 className="text-truncate">
          {selectedImage.label}
        </h5>
        <Row float="center">
          <Col>
            <img
              style={{ maxHeight: 500, maxWidth: '100%' }}
              src={selectedImage.imageUrl}
              alt="Preview"
            />
          </Col>
        </Row>
        <Row float="center">
          <Col style={{ padding: '10px' }}>
            {
              selectedImage?.alias
                ? (
                  <Button
                    onClick={() => this.handleReplaceImageClick()}
                    variant="secondary"
                    className="mx-1"
                    hidden={selectedImageIds.length > 0}
                    disabled={loading}
                  >
                    Replace image
                  </Button>
                )
                : <Alert variant="danger">This image does not have an alias</Alert>
            }
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const {
      uploadImage, selectedImage, selectedImageIds, showDeleteModal,
      images, loading, searchString, showOnlyUserImages, pageSize,
    } = this.state;

    const isAdmin = user.role === 'admin';
    const activeSelectedImage = selectedImage ? [selectedImage] : [];
    const selectedImagesFiltered = images.filter((image) => selectedImageIds.includes(image.id));
    const imagesTodDelete = selectedImagesFiltered.length === 0
      ? activeSelectedImage
      : selectedImagesFiltered;

    return (
      <Card className="d-block">
        <Card.Body>
          <Card.Title className="title">
            Backgrounds list
          </Card.Title>
          {this.renderLoadingSpinner()}
          <Row>
            <Col md={8}>
              <div className="d-flex">
                <SearchBar
                  value={searchString}
                  onChange={(searchValue) => {
                    this.setState({ searchString: searchValue, currentPage: 1 });
                  }}
                />
              </div>
              {this.renderPagination()}
              <Row>
                <Col sm="auto" className="d-flex align-items-center">
                  <ImagePageSizeSelector
                    size="sm"
                    value={pageSize}
                    onChange={(value) => {
                      this.setState({ pageSize: value, currentPage: 1 });
                    }}
                  />
                  <span className="ml-2">per page</span>
                </Col>
                {isAdmin && (
                <Col className="text-right mt-3">
                  <Form.Check
                    type="switch"
                    id="showOnlyUserImages"
                    label="Show only my Art"
                    checked={showOnlyUserImages}
                    onChange={(event) => {
                      this.setState({ currentPage: 1, showOnlyUserImages: event.target.checked });
                    }}
                  />
                </Col>
                )}
              </Row>
              <ListGroup>
                <BackgroundListHeader
                  checked={!images.some((image) => !selectedImageIds.includes(image.id))}
                  handleCheck={(checked) => {
                    this.setState({
                      selectedImageIds: checked ? images.map((image) => image.id) : [],
                    });
                  }}
                />
                {this.renderImageItems()}
              </ListGroup>
              {this.renderPagination()}
              <Row>
                <Col className="text-right">
                  <Button
                    onClick={() => this.setState({ showDeleteModal: true })}
                    variant="danger"
                    className="ml-1"
                    disabled={loading}
                    hidden={selectedImageIds.length === 0}
                  >
                    Delete images
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={4} className="text-center">
              {this.renderImagePreview()}
            </Col>
          </Row>
        </Card.Body>
        <DeleteImageModal
          show={showDeleteModal}
          type="background"
          images={imagesTodDelete}
          onHide={() => {
            this.setState({ showDeleteModal: false });
          }}
          onDelete={() => {
            this.setState({ showDeleteModal: false });
            this.loadImages();
          }}
        />
        <UploadImage
          show={uploadImage}
          onHide={this.cancelUpload}
          update={this.updateUpload}
          obj={selectedImage}
          user={user}
          type="background"
        />
      </Card>
    );
  }
}
