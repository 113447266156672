import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { FormDocuments } from './FormDocument';

export class EditDocuments extends Component {
  constructor(...args) {
    super(...args);
    this.state = {};
  }

  render() {
    const {
      update,
      obj,
      onHide,
      ...props
    } = this.props;

    const title = obj && obj.id ? 'Edit Documents' : 'Create Documents';

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormDocuments
            {...this.props}
            update={update}
            onHide={onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
