import serialize from 'form-serialize';
import JoditEditor from 'jodit-react';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap';
import { api } from '../../api';

export class FormDocuments extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
      data: {},
    };
    this.contentRef = React.createRef();
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.summaryRef = React.createRef();
    const { auth } = this.props;
    this.user = auth.getUser();
  }

  componentDidMount() {
    const { obj } = this.props;
    const data = obj
      ? JSON.parse(JSON.stringify(obj))
      : {
        title: '',
        body: '',
        alias: '',
        acceptRequired: false,
        hidden: false,
      };
    this.setState({
      data,
    });
  }

  componentDidUpdate(prevProps) {
    const { obj } = this.props;
    if (
      JSON.stringify(obj) !== JSON.stringify(prevProps.obj)
      && obj.length > 0
    ) {
      this.setState({
        data: JSON.parse(JSON.stringify(obj)),
      });
    }
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addDocument(value, validated) {
    if (validated === true) {
      const {
        obj,
        update,
      } = this.props;

      if (obj && obj.alias) {
        api.put(`/v1/documents/${obj.alias}`, value)
          .then((res) => {
            if (obj.alias !== res.data.post.alias) {
              return window.location.assign(`/documents/${res.data.post.alias}`);
            }
            return update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/documents', value)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });
    obj.acceptRequired = Boolean(obj.acceptRequired);
    obj.hidden = Boolean(obj.hidden);
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addDocument(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const { onHide } = this.props;

    const {
      validated,
      data,
      loading,
      formError,
    } = this.state;

    const toolbarButtons = [
      'bold', 'italic', 'underline', 'strikethrough', '|',
      'eraser', '|',
      'ul', 'ol', '|',
      'font', 'fontsize', 'paragraph', 'lineHeight', 'superscript', 'subscript', '|',
      'file', 'image', 'video', '|', '\n',
      'source', '|',
      'undo', 'redo',
    ];
    const config = {
      readonly: false,
      toolbarButtonSize: 'medium',
      disablePlugins: 'table,tablekeyboardnavigation,tooltip,symbols,sticky,stat,search,resizer,media,limit,justify,iframe',
      buttons: toolbarButtons,
      buttonsMD: toolbarButtons,
      buttonsSM: toolbarButtons,
      buttonsXS: toolbarButtons,
      askBeforePasteHTML: false,
    };

    return (
      <Form
        noValidate
        validated={validated}
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
          <Alert variant="danger">
            {formError}
          </Alert>
        </Col>

        {
            !loading && (
            <>
              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  value={data.title || ''}
                  onChange={(e) => {
                    data.title = e.target.value;
                    this.setState({
                      data,
                    });
                  }}
                  name="title"
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Label>Slug:</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Page slug"
                  value={data.alias || ''}
                  onChange={(e) => {
                    data.alias = e.target.value;
                    this.setState({
                      data,
                    });
                  }}
                  name="alias"
                />
              </Form.Group>

              {
                  (this.user.role === 'admin')
                  && (
                  <Form.Group as={Col} sm={12} xs={12}>
                    <Form.Label>Summary:</Form.Label>
                    <JoditEditor
                      ref={this.contentRef}
                      value={data.summary || ''}
                      placeholder="Summary"
                      name="summary"
                      config={config}
                      tabIndex={0}
                      onBlur={(e) => {
                        data.summary = e;
                        this.setState({
                          data,
                        });
                      }}
                    />
                  </Form.Group>
                  )
                }

              <Form.Group as={Col} controlId="hidden">
                <Form.Check
                  custom
                  type="checkbox"
                  label="this document is hidden from users by default"
                  name="hidden"
                  value
                  checked={data.hidden || false}
                  onChange={(e) => {
                    data.hidden = e.target.checked;
                    this.setState({
                      data,
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="disabled">
                <Form.Check
                  custom
                  type="checkbox"
                  label="this document requires a read confirmation"
                  name="acceptRequired"
                  value
                  checked={data.acceptRequired || false}
                  onChange={(e) => {
                    data.acceptRequired = e.target.checked;
                    this.setState({
                      data,
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Label>Content:</Form.Label>
                <JoditEditor
                  ref={this.contentRef}
                  value={data.body || ''}
                  name="body"
                  config={config}
                  tabIndex={0}
                  onBlur={(e) => {
                    data.body = e;
                    this.setState({
                      data,
                    });
                  }}
                />
              </Form.Group>
            </>
            )
          }

        <Row>
          <Col md={12} className="text-right">
            <Button
              className="mr-2"
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
