import { RuntimeEnv } from './RuntimeEnvEnum';

const prodHostnames = [
  'dorianplatform.com',
  'dorianlive.tv',
  'dorian.watch',
  'dorian.live',
  'live.4dorian.net',
];

export const getRuntimeEnv = () => {
  const { hostname } = window.location;
  if (hostname.startsWith('local')) {
    return RuntimeEnv.Local;
  }
  if (prodHostnames.some((prodHostname) => hostname.endsWith(prodHostname))) {
    return RuntimeEnv.Prod;
  }
  if (hostname.endsWith('.test.4dorian.net')) {
    return RuntimeEnv.Dev;
  }
  // fallback
  return RuntimeEnv.Local;
};

export const isProd = () => getRuntimeEnv() === RuntimeEnv.Prod;
export const isDev = () => getRuntimeEnv() === RuntimeEnv.Dev;
export const isLocal = () => getRuntimeEnv() === RuntimeEnv.Local;
