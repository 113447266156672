import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Form, Row } from 'react-bootstrap';
import { StepTypeMode } from '../../../../../dorian-shared/types/branch/Branch';
import { NodeEditContext } from '../context/NodeEditContext';
import { AnswerActionsBox } from './AnswerActionsBox';
import { AnswerEliminationText } from './AnswerEliminationText';
import { AnswerEliminationType } from './AnswerEliminationType';
import { AnswerFormData } from './AnswerFormData';
import {
  AnswerFormMemorySlotRequirement,
  VisualTreatmentIfNotMet,
} from './AnswerFormMemorySlotRequirement';
import { AnswerFormModifiers } from './AnswerFormModifiers';
import { AnswerFormTags } from './AnswerFormTags';
import { AnswerGotoBranch } from './AnswerGotoBranch';
import { AnswerPrice } from './AnswerPrice';
import { AnswerTextFields } from './AnswerTextFields';
import { AnswerType } from './AnswerType';
import styles from './Steps.scss';
import { count } from './utils';

const cs = classNames.bind(styles);

export class AnswerForm extends PureComponent {
  showAnswerTags = (i) => {
    const { handleChangeAnswer, object } = this.props;

    const obj = { ...object };
    obj.showAnswerTags = !obj.showAnswerTags;
    if (obj.showAnswerTags === false && obj.showAnswerData === true) {
      obj.showAnswerData = false;
    }
    handleChangeAnswer(i, obj);
  };

  showAnswerModifiers = (i) => {
    const { handleChangeAnswer, object } = this.props;

    const obj = { ...object };
    obj.showAnswerModifiers = !obj.showAnswerModifiers;
    if (obj.showAnswerModifiers === false && obj.showAnswerData === true) {
      obj.showAnswerData = false;
    }
    handleChangeAnswer(i, obj);
  };

  showAnswerData = (i) => {
    const { handleChangeAnswer, object } = this.props;

    const obj = { ...object };
    obj.showAnswerData = !obj.showAnswerData;
    if (obj.showAnswerData === false && obj.showAnswerTags === true) {
      obj.showAnswerTags = false;
    }
    if (obj.showAnswerData === false && obj.showAnswerModifiers === true) {
      obj.showAnswerModifiers = false;
    }
    handleChangeAnswer(i, obj);
  };

  showSlotRequirement = (index) => {
    const { handleChangeAnswer, object } = this.props;

    const answerObject = { ...object };
    answerObject.isSlotRequirementActive = !answerObject.isSlotRequirementActive;

    if (!answerObject.requirement) {
      answerObject.requirement = {
        check: {
          variableId: 0,
          value: 0,
          operator: 0,
        },
        visualTreatmentIfNotMet: VisualTreatmentIfNotMet.Disable,
      };
    }
    handleChangeAnswer(index, answerObject);
  };

  handleChange = (key, value) => {
    const { handleChangeAnswer, object, answerIndex } = this.props;

    const obj = { ...object };
    obj[key] = value;
    handleChangeAnswer(answerIndex, obj);
  };

  handleGoToChange = (value) => {
    this.handleChange('gotoBranchId', value);
  };

  getBonusModifier = (modifiers) => (modifiers ? modifiers.find((mod) => {
    const { modifiersTypes } = this.context;

    if (!mod.answerModifierTypeId && mod.answerModifierTypeId !== 0) {
      return true;
    }
    const mType = this.context && modifiersTypes
      ? modifiersTypes.find((m) => m.id === parseInt(mod.answerModifierTypeId, 10))
      : null;
    return mType && mType.value === 'bonus_content';
  }) : null);

  removeBonusModifiers = (modifiers) => (modifiers ? modifiers.filter((mod) => {
    const { modifiersTypes } = this.context;
    if (!mod.answerModifierTypeId && mod.answerModifierTypeId !== 0) {
      return false;
    }
    const mType = this.context && modifiersTypes
      ? modifiersTypes.find((m) => m.id === parseInt(mod.answerModifierTypeId, 10))
      : null;
    return !mType || mType.value !== 'bonus_content';
  }) : []);

  // Price change can result in type and modifier change. Handling explicitly.
  handleAnswerPriceChange = (value) => {
    const { handleChangeAnswer, object, answerIndex } = this.props;
    const { answerType } = this.context;

    const obj = { ...object };
    const price = parseInt(value, 10);
    obj.answerPrice = price;
    if (price === 0) {
      const type = answerType.find((t) => t.value === 'passive');
      obj.answerTypeId = String(type.id);
      obj.modifiers = this.removeBonusModifiers(object.modifiers);
    } else {
      const type = answerType.find((t) => t.id === parseInt(object.answerTypeId, 10));
      if (!type || type.value === 'passive') {
        const newType = answerType.find((t) => t.value === 'active' || t.value === 'paid');
        obj.answerTypeId = String(newType.id);
      }
    }
    handleChangeAnswer(answerIndex, obj);
  };

  // Type change can result in price and modifier change. Handling explicitly.
  handleAnswerTypeChange = (value) => {
    const { handleChangeAnswer, object, answerIndex } = this.props;
    const { answerType } = this.context;

    const obj = { ...object };
    obj.answerTypeId = String(value);
    const type = answerType.find((t) => t.id === parseInt(value, 10));
    if (type && type.value === 'passive') {
      obj.answerPrice = 0;
      obj.modifiers = this.removeBonusModifiers(object.modifiers);
    }
    handleChangeAnswer(answerIndex, obj);
  };

  handleChangeAnswerRequirementCheck = (values) => {
    const { object } = this.props;

    let requirementObj = {};
    if (object.requirement) {
      requirementObj = { ...object.requirement };
    }

    if (!requirementObj.check) {
      requirementObj.check = {
        operator: 'equal',
        value: 0,
        variableId: 0,
      };
    }

    values.forEach((valueObj) => {
      requirementObj.check[valueObj.name] = valueObj.value;
    });
    this.handleChange('requirement', requirementObj);
  };

  handleChangeAnswerRequirementVisual = (value) => {
    const { object } = this.props;

    let requirementObj = {};
    if (object.requirement) {
      requirementObj = { ...object.requirement };
    }
    requirementObj.visualTreatmentIfNotMet = value;
    this.handleChange('requirement', requirementObj);
  };

  handleDeleteAnswerRequirement = () => {
    const { object, answerIndex, handleChangeAnswer } = this.props;
    const answerObject = { ...object };
    delete answerObject.requirement;
    answerObject.isSlotRequirementActive = false;
    handleChangeAnswer(answerIndex, answerObject);
  };

  // Modifier change can result in type and price change. Handling explicitly.
  handleChangeAnswerModifiers = (value) => {
    const { handleChangeAnswer, object, answerIndex } = this.props;
    const { answerType } = this.context;

    const obj = { ...object };
    obj.modifiers = value;
    const bonus = this.getBonusModifier(value);
    const type = answerType.find((t) => t.id === parseInt(object.answerTypeId, 10));
    if (bonus && (!type || type.value === 'passive')) {
      const newType = answerType.find((t) => t.value === 'active' || t.value === 'paid');
      obj.answerTypeId = String(newType.id);
    }
    handleChangeAnswer(answerIndex, obj);
  };

  addNewAnswer = (index) => {
    const { addAnswer } = this.props;
    if (index !== undefined) {
      addAnswer(index);
    }
  };

  deleteAnswer = (i) => {
    const { deleteAnswer: deleteAnswerFromProps } = this.props;

    deleteAnswerFromProps(i);
  };

  getCurrentPrice = (object, context) => {
    // context.answerType.forEach((t) => console.log(t.id, t.value));
    const type = context.answerType.find((t) => t.id === parseInt(object.answerTypeId, 10));
    const bonus = this.getBonusModifier(object.modifiers);
    if (type) {
      // eslint-disable-next-line no-param-reassign
      object.data = object.data ? object.data.filter((datum) => {
        if (datum.key === 'level') {
          const dataPrice = parseInt(datum.value, 10);
          if (dataPrice && dataPrice > 0 && type.value !== 'passive') {
            // eslint-disable-next-line no-param-reassign
            object.answerPrice = dataPrice;
          }
          return false;
        }
        return true;
      }) : [];
    }
    const price = object.answerPrice;
    if (price && price > 0) {
      return price;
    } if (bonus) {
      // eslint-disable-next-line no-param-reassign
      object.answerPrice = 12;
      return 12;
    } if (type && (type.value === 'active' || type.value === 'paid')) {
      // eslint-disable-next-line no-param-reassign
      object.answerPrice = 2;
      return 2;
    } if (type && type.value === 'erotic') {
      // eslint-disable-next-line no-param-reassign
      object.answerPrice = 6;
      return 6;
    } if (type && type.value === 'romantic') {
      // eslint-disable-next-line no-param-reassign
      object.answerPrice = 6;
      return 6;
    }
    // eslint-disable-next-line no-param-reassign
    object.answerPrice = 0;
    return 0;
  };

  render() {
    const {
      stepIndex,
      answerIndex,
      activeSteps,
      disabledSortableAction,
      activeStepsFunc,
      restrictedEdit,
      object,
      user,
      index: indexFromProps,
      memoryBankSlots,
      characters,
      isEliminationCorrectOptionDisabled,
    } = this.props;
    const {
      tagsTypes, currentBranchId, branches, modifiersTypes, answerType, limits, stepTypeMode, story,
    } = this.context;

    const answerData = object.data ? count(object.data) : 0;
    const answerTags = object.tags ? count(object.tags) : 0;
    const answerModifiers = object.modifiers ? count(object.modifiers) : 0;
    const activeClass = activeSteps ? activeSteps.startsWith(`steps-${stepIndex}-answers-${answerIndex}`) : false;

    const slotRequirement = object.requirement && object.requirement.check ? 1 : 0;

    const isCompetitiveMode = (story?.book?.competitiveMode
      && stepTypeMode === StepTypeMode.Elimination)
        ?? false;

    return (
      <>
        <Row
          className={cs('py-1 justify-content-between', activeClass ? 'active-item' : null)}
        >
          {restrictedEdit && (
            <Form.Control
              type="hidden"
              name={`steps[${stepIndex}][answers][${answerIndex}][id]`}
              value={object.id}
            />
          )}

          <AnswerTextFields
            answerIndex={answerIndex}
            stepIndex={stepIndex}
            object={object}
            limits={limits}
            text={object.text}
            handleTextChange={(value) => this.handleChange('text', value)}
            addNewAnswer={this.addNewAnswer}
            activeSteps={activeSteps}
            activeStepsFunc={activeStepsFunc}
          />
          {!isCompetitiveMode && (
            <>
              <AnswerGotoBranch
                sm={4}
                lg={4}
                xl={3}
                disabled={restrictedEdit}
                answerIndex={answerIndex}
                stepIndex={stepIndex}
                gotoBranchId={object.gotoBranchId}
                index={indexFromProps}
                handleChange={this.handleGoToChange}
                branches={branches}
                currentBranch={currentBranchId}
                activeStepsFunc={activeStepsFunc}
              />

              <AnswerPrice
                sm={3}
                lg={3}
                xl={2}
                disabled={restrictedEdit}
                answerIndex={answerIndex}
                stepIndex={stepIndex}
                answerPrice={this.getCurrentPrice(object, this.context)}
                handleChange={this.handleAnswerPriceChange}
                activeStepsFunc={activeStepsFunc}
              />

              <AnswerType
                sm={4}
                lg={3}
                xl={2}
                disabled={restrictedEdit}
                answerIndex={answerIndex}
                stepIndex={stepIndex}
                answerTypeId={object.answerTypeId}
                answerTypes={answerType}
                handleChange={this.handleAnswerTypeChange}
                activeStepsFunc={activeStepsFunc}
              />
            </>
          )}

          {isCompetitiveMode && (
            <>
              <AnswerEliminationType
                disabled={restrictedEdit}
                answerIndex={answerIndex}
                stepIndex={stepIndex}
                answerTypeId={object.answerTypeId}
                answerTypes={answerType}
                onChange={(value) => this.handleChange('eliminationResultType', value)}
                activeStepsFunc={activeStepsFunc}
                value={object.eliminationResultType}
                isCorrectOptionDisabled={isEliminationCorrectOptionDisabled}
              />

              <AnswerEliminationText
                disabled={restrictedEdit}
                answerIndex={answerIndex}
                stepIndex={stepIndex}
                answerTypeId={object.answerTypeId}
                answerTypes={answerType}
                onChange={(text) => this.handleChange('eliminationResultText', text)}
                activeStepsFunc={activeStepsFunc}
                value={object.eliminationResultText ?? ''}
                textLengthLimit={limits?.step_1_text_max?.value}
              />
            </>
          )}

          <AnswerActionsBox
            sm={12}
            md={5}
            lg={12}
            xl={12}
            user={user}
            restrictedEdit={restrictedEdit}
            object={object}
            answerIndex={answerIndex}
            stepIndex={stepIndex}
            answerModifiers={answerModifiers}
            answerTags={answerTags}
            answerData={answerData}
            deleteAnswer={this.deleteAnswer}
            answeredDataShowed={object.showAnswerData}
            showAnswerData={this.showAnswerData}
            showAnswerTags={this.showAnswerTags}
            showAnswerModifiers={this.showAnswerModifiers}
            disabledSortableAction={disabledSortableAction}
            activeStepsFunc={activeStepsFunc}
            slotRequirement={slotRequirement}
            showSlotRequirement={this.showSlotRequirement}
            isSlotRequirementActive={object.isSlotRequirementActive}
            isSlotRequirementVisible={memoryBankSlots && memoryBankSlots.length > 0}
          />
        </Row>

        <AnswerFormTags
          restrictedEdit={restrictedEdit}
          answerIndex={answerIndex}
          stepIndex={stepIndex}
          activeSteps={activeSteps}
          activeStepsFunc={activeStepsFunc}
          showAnswerTags={object.showAnswerTags}
          tags={object.tags}
          tagsTypes={tagsTypes}
          handleChangeAnswerTags={(value) => this.handleChange('tags', value)}
        />

        <AnswerFormModifiers
          restrictedEdit={restrictedEdit}
          answerIndex={answerIndex}
          stepIndex={stepIndex}
          activeSteps={activeSteps}
          activeStepsFunc={activeStepsFunc}
          showAnswerModifiers={object.showAnswerModifiers}
          modifiers={object.modifiers}
          modifiersTypes={modifiersTypes}
          handleChangeAnswerModifiers={this.handleChangeAnswerModifiers}
        />

        <AnswerFormData
          restrictedEdit={restrictedEdit}
          answerIndex={answerIndex}
          stepIndex={stepIndex}
          activeSteps={activeSteps}
          activeStepsFunc={activeStepsFunc}
          limits={limits}
          showAnswerData={object.showAnswerData}
          data={object.data}
          handleChangeAnswerData={(value) => this.handleChange('data', value)}
        />

        {object.requirement
        && (
        <AnswerFormMemorySlotRequirement
          isSlotRequirementActive={object.isSlotRequirementActive}
          requirement={object.requirement}
          answerIndex={answerIndex}
          stepIndex={stepIndex}
          characters={characters}
          memoryBankSlots={memoryBankSlots}
          onChangeCheckValue={this.handleChangeAnswerRequirementCheck}
          onChangeVisualEffectValue={this.handleChangeAnswerRequirementVisual}
          onDeleteRequirement={this.handleDeleteAnswerRequirement}
        />
        )}
      </>
    );
  }
}

AnswerForm.contextType = NodeEditContext;
