import React from 'react';
import { api } from '../../../api';
import { PerformanceTabContentProps } from '../ChaptersPerformanceTabContent/ChaptersPerformanceTabContent';
import { PerformanceTabContent } from '../PerformanceTabContent/PerformanceTabContent';
import { FetchParams, PerformanceTabs } from '../performanceUtils';
import { completionColumns, completionHeaders } from '../tableUtils/columns/completionsColumns';

export function CompletionPerformanceTabContent(props: PerformanceTabContentProps) {
  const { pageSize } = props;

  const fetchPerformanceData = async (
    params: FetchParams,
    signal: AbortSignal,
  ) => {
    const response = await api.get(
      '/v2/analytics/performance/completion',
      { params, signal },
    );
    return response.data;
  };

  return (
    <PerformanceTabContent
      tabTitle={PerformanceTabs.Completion}
      csvHeaders={completionHeaders}
      columns={completionColumns}
      getData={fetchPerformanceData}
      pageSize={pageSize}
    />
  );
}
