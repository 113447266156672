import SparkMD5 from 'spark-md5';
import { ExpressionConfig } from '../../../dorian-shared/types/character/ExpressionConfig';

export const extractExpressionNameFromFileName = (alias: string): string | undefined => {
  const match = alias.match(/_([^_]+?)(?:\.\w+)?$/);
  return match?.[1];
};

export const findExpression = (value: string, expressions: ExpressionConfig[]) => {
  if (!value) {
    return undefined;
  }
  const searchValue = value.toLowerCase().trim();
  return expressions.find((expression) => {
    const isAsValue = searchValue.includes(`_${expression.value.toLowerCase().trim()}`);
    const isAsTitle = searchValue.includes(`_${expression.title.toLowerCase().trim()}`);
    return isAsValue || isAsTitle;
  });
};

export const getExpression = (
  name: string,
  expressions: ExpressionConfig[],
): ExpressionConfig | undefined => expressions.find((exprNane) => {
  const isAsValue = exprNane.value.toLowerCase().trim() === name?.toLowerCase().trim();
  const isAsTitle = exprNane.title.toLowerCase().trim() === name?.toLowerCase().trim();
  const isAsId = exprNane.id.toString() === name;
  return isAsValue || isAsTitle || isAsId;
});

export const getUploadFileHash = async (file: File, userId: number): Promise<string> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  const spark = new SparkMD5();
  spark.append(userId.toString());
  reader.onload = () => {
    const sparkArrayBuffer = new SparkMD5.ArrayBuffer();
    sparkArrayBuffer.append(reader.result as ArrayBuffer);
    spark.append(sparkArrayBuffer.end());
    const hash = spark.end();
    resolve(hash);
  };
});
