import axios from 'axios';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import {
  Alert, Col, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { CustomCharacterSelectContext } from '../../../../contexts/CustomCharacterSelect/CustomCharacterSelectProvider';
import { api } from '../../../api';
import { DebouncedSearch } from '../../DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../Pagination/Pagination';
import { CharacterImageItem } from './CharacterImageItem';
import classNames from './CustomCharacterSelect.module.scss';

export function CustomCharacterSelect({
  title,
  activeCharacterId,
  onClick,
  pageSize = 9,
  bulkSelectionLimit,
  onSearch = undefined,
}) {
  const [
    searchCharacterTermContext,
    setSearchCharacterTermContext,
  ] = useContext(CustomCharacterSelectContext);
  const [loading, setLoading] = useState(false);
  const [
    searchCharacterTerm,
    setSearchCharacterTerm,
  ] = React.useState(searchCharacterTermContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCountCharacters, setTotalCountCharacters] = useState(0);
  const [customCharacters, setCustomCharacters] = useState([]);

  const isBulkSelectionLimit = Boolean(bulkSelectionLimit);

  const loadCustomCharacters = useCallback((cancelToken) => {
    setLoading(true);
    const params = {
      order: 'createdAt:desc',
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      search: searchCharacterTerm || undefined,
    };
    api.get('/v1/customcharacters', { params, cancelToken: cancelToken.token })
      .then((res) => {
        const { images: downloadedCustomCharacters, totalCount } = res.data;
        setCustomCharacters(downloadedCustomCharacters);
        setTotalCountCharacters(totalCount);
        onSearch?.(downloadedCustomCharacters);
      }).finally(() => {
        setLoading(false);
      });
  }, [currentPage, onSearch, pageSize, searchCharacterTerm]);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    loadCustomCharacters(cancelToken);
    return () => {
      cancelToken.cancel('CustomCharacterSelect unmount');
    };
  }, [loadCustomCharacters]);

  const handleSearchSubmit = (searchTerm) => {
    setCurrentPage(1);
    setSearchCharacterTerm(searchTerm);
  };

  const paginationProps = {
    pageSize,
    currentPage,
    totalCount: totalCountCharacters,
    onPageChange: setCurrentPage,
  };

  const handleCharacterImageItemClick = (pickedCharacter) => {
    setSearchCharacterTermContext(searchCharacterTerm);
    onClick(pickedCharacter);
  };

  return (
    <>
      <Row>
        <Col className={classNames.title}>
          <h4>
            {title}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col xs={2}>
          { loading && (
            <Spinner
              variant="primary"
              animation="border"
              size="ms"
            />
          )}
        </Col>
        <Col xs={8}>
          <DebouncedSearch
            initialValue={searchCharacterTerm}
            onSearchSubmit={handleSearchSubmit}
          />
        </Col>
        <Col xs={2} />
        <Col xs={12}>
          {!isBulkSelectionLimit && (
            <Pagination {...paginationProps} />
          )}
          {isBulkSelectionLimit && totalCountCharacters > bulkSelectionLimit && (
            <Col>
              <Alert variant="danger">
                {`Showing ${bulkSelectionLimit} of ${totalCountCharacters} characters. Please use the search to filter no more than ${bulkSelectionLimit} characters.`}
              </Alert>
            </Col>
          )}
          <ListGroup horizontal>
            <Row className={classNames.listGroup}>
              {customCharacters.map((character) => (
                <CharacterImageItem
                  key={character.id}
                  character={character}
                  activeCharacterId={activeCharacterId}
                  onClick={handleCharacterImageItemClick}
                />
              ))}
            </Row>
          </ListGroup>
        </Col>
      </Row>
    </>
  );
}
