import React, { useState } from 'react';
import {
  Col, Dropdown, DropdownButton, Form, InputGroup, Row,
} from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import { Book } from 'dorian-shared/types/book/book';
import { showToast } from '../../../ui/utils';
import { fetchBookById } from '../bookAPI';
import { validateBookAssociation } from './bookAssociationUtils';

// eslint-disable-next-line no-shadow
enum AssociationChangeType {
  Initial = 'Association',
  Original = 'As Original',
  Prompt = 'As Prompt',
  SpinOff = 'As SpinOff',
  Manual = 'Manually',
  Restore = 'Restore',
}

export interface BookAssociationProps {
    book: Book;
    setLoading: (value: boolean) => void;
}

export function BookAssociation(props: BookAssociationProps) {
  const { book, setLoading } = props;

  const [sourceBookId, setSourceBookId] = useState<number | null>(book.sourceBookId);
  const [originalBookId, setOriginalBookId] = useState<number| null>(book.originalBookId);
  const [isPrompt, setIsPrompt] = useState<boolean>(book.isPrompt);

  const [
    associationChangeType,
    setAssociationChangeType,
  ] = useState<AssociationChangeType>(AssociationChangeType.Initial);

  const {
    isOriginalBookIdValid,
    isSourceBookIdValid,
    isPromptValid,
  } = validateBookAssociation(originalBookId, sourceBookId, isPrompt);

  const setDebouncedIdsBySourceId = useDebouncedCallback(async () => {
    if (!sourceBookId) {
      return;
    }
    setLoading(true);

    switch (associationChangeType) {
      case AssociationChangeType.SpinOff: {
        try {
          const sourceBook = await fetchBookById(sourceBookId);
          if (sourceBook?.isPrompt) {
            setOriginalBookId(sourceBook.originalBookId);
            showToast({ textMessage: `Setting up ${sourceBook.title} as original story`, variant: 'info', timeout: 8000 });
          } else {
            showToast({ textMessage: `Story ${sourceBook.title} is not a prompt`, timeout: 8000 });
          }
        } catch (exception) {
          showToast({ textMessage: 'An error occurred while fetching the book' });
        }
        break;
      }
      case AssociationChangeType.Prompt:
        setOriginalBookId(sourceBookId);
        break;
      default:
        break;
    }
    setLoading(false);
  }, 500);

  const handleAssociationTypeChanged = (preset: AssociationChangeType) => {
    switch (preset) {
      case AssociationChangeType.Original:
        if (isPrompt) {
          showToast({ textMessage: 'Prompt can\'t be stand alone story ' });
          return;
        }
        setOriginalBookId(null);
        setSourceBookId(null);
        setAssociationChangeType(preset);
        break;
      case AssociationChangeType.Prompt:
        if (!isPrompt) {
          showToast({ textMessage: 'Story can\'t be prompt ' });
          return;
        }
        setAssociationChangeType(preset);
        break;
      case AssociationChangeType.SpinOff:
        if (isPrompt) {
          showToast({ textMessage: 'Prompt can\'t be spin-off story ' });
          return;
        }
        setAssociationChangeType(preset);
        setDebouncedIdsBySourceId();
        break;
      case AssociationChangeType.Manual:
        showToast({ textMessage: 'Be careful when using manual mode' });
        setAssociationChangeType(preset);
        break;
      case AssociationChangeType.Restore: {
        try {
          const { originalAssociation } = JSON.parse(JSON.stringify(book.data));
          if (originalAssociation) {
            setOriginalBookId(Number(originalAssociation.originalBookId));
            setSourceBookId(Number(originalAssociation.sourceBookId));
            setIsPrompt(Boolean(originalAssociation.isPrompt));
          } else {
            setOriginalBookId(Number(book.originalBookId));
            setSourceBookId(Number(book.sourceBookId));
            setIsPrompt(Boolean(book.isPrompt));
          }
          showToast({ textMessage: 'Ids restored', variant: 'info' });
        } catch (exception) {
          showToast({ textMessage: 'An error occurred while restoring' });
        }
        setAssociationChangeType(AssociationChangeType.Initial);
        break;
      }
      default:
        break;
    }
  };

  const handleOriginalBookIdChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/\D/g, ''));
    setOriginalBookId(value || null);
  };

  const handleSourceBookIdChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value.replace(/\D/g, ''));
    setSourceBookId(value || null);
    setDebouncedIdsBySourceId();
  };

  const isOriginalBookIdDisabled = associationChangeType !== AssociationChangeType.Manual;
  const isSourceBookIdDisabled = associationChangeType === AssociationChangeType.Initial
  || associationChangeType === AssociationChangeType.Original;
  const isPromptDisabled = associationChangeType !== AssociationChangeType.Manual;

  return (
    <Row>
      <Col md={4}>
        <InputGroup className="flex-nowrap">
          <InputGroup.Text as="div">Original id:</InputGroup.Text>
          <div className="w-100 text-left ml-2 my-auto">
            <Form.Control
              required
              disabled={isOriginalBookIdDisabled}
              type="text"
              placeholder="null"
              value={originalBookId ?? ''}
              onChange={handleOriginalBookIdChanged}
              name="originalBookId"
              isInvalid={!isOriginalBookIdValid}
            />
          </div>
        </InputGroup>
      </Col>

      <Col md={4}>
        <InputGroup className="flex-nowrap">
          <InputGroup.Text as="div">Source id:</InputGroup.Text>
          <div className="w-100 text-left ml-2 my-auto">
            <Form.Control
              required
              disabled={isSourceBookIdDisabled}
              type="text"
              placeholder="null"
              name="sourceBookId"
              isInvalid={!isSourceBookIdValid}
              value={sourceBookId ?? ''}
              onChange={handleSourceBookIdChanged}
            />
          </div>
        </InputGroup>
      </Col>

      <Col md="auto" className="my-auto">
        <div className="text-left ml-2">
          <Form.Check
            disabled={isPromptDisabled}
            className="my-auto ml-2"
            custom
            type="checkbox"
            checked={isPrompt}
            onChange={() => setIsPrompt(!isPrompt)}
            name="isPrompt"
            isInvalid={!isPromptValid}
            feedbackTooltip
            feedback={<div style={{ width: '100%' }}>Invalid</div>}
            id="isPrompt"
            label="Is prompt"
          />
        </div>
      </Col>

      <Col className="text-right my-auto w-100">
        <DropdownButton
          size="sm"
          title={associationChangeType}
          variant="outline-primary"
        >
          <Dropdown.Item
            onClick={() => handleAssociationTypeChanged(AssociationChangeType.Original)}
          >
            Set as original
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAssociationTypeChanged(AssociationChangeType.Prompt)}
          >
            Set as prompt
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAssociationTypeChanged(AssociationChangeType.SpinOff)}
          >
            Set as spinoff
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAssociationTypeChanged(AssociationChangeType.Manual)}
          >
            Set manual
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleAssociationTypeChanged(AssociationChangeType.Restore)}
          >
            Restore
          </Dropdown.Item>
        </DropdownButton>
      </Col>

    </Row>
  );
}
