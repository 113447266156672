import React from 'react';
import { api } from '../../../api';
import { PerformanceTabContentProps } from '../ChaptersPerformanceTabContent/ChaptersPerformanceTabContent';
import { PerformanceTabContent } from '../PerformanceTabContent/PerformanceTabContent';
import { FetchParams, PerformanceTabs } from '../performanceUtils';
import { monetizationColumns, monetizationHeaders } from '../tableUtils/columns/monetizationsColumns';

export function MonetizationPerformanceTabContent(props: PerformanceTabContentProps) {
  const { pageSize } = props;

  const fetchPerformanceData = async (
    params: FetchParams,
    signal: AbortSignal,
  ) => {
    const response = await api.get(
      '/v2/analytics/performance/monetization',
      { params, signal },
    );
    return response.data;
  };

  return (
    <PerformanceTabContent
      tabTitle={PerformanceTabs.Monetization}
      csvHeaders={monetizationHeaders}
      columns={monetizationColumns}
      getData={fetchPerformanceData}
      pageSize={pageSize}
    />
  );
}
