import { User, UserAction } from '../../dorian-shared/types/user/User';
import { auth } from '../Auth/Auth';

export function isUserActionEnabled(user: User, action: UserAction) {
  const authUser = auth.getUser();

  const checkUser = user || authUser;

  if (checkUser?.role === 'admin') {
    return true;
  }
  return !!checkUser?.actions.includes(action);
}

export function isMemoryBankEnabled(user: User) {
  return isUserActionEnabled(user, UserAction.ChoiceMemory);
}

export function isAchievementsEnabled(user: User) {
  return isUserActionEnabled(user, UserAction.Achievements);
}
