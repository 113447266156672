import React from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { getShowInSelectOptions, getShowInSelectValue } from '../../../StoryBranches/BranchEdit/Steps/utils';
import { MemoryShowIn } from '../memoryBankTypes';
import classes from './MemoryField.module.scss';

type MemoryViewFiledProps = {
  id: string,
  disabled?: boolean,
  value: Array<MemoryShowIn>,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export function MemoryViewFiled(props: MemoryViewFiledProps) {
  const {
    id,
    disabled,
    onChange,
    value,
  } = props;

  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Default view
      </Form.Label>
      {disabled
        ? '-'
        : (
          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            isMulti
            closeMenuOnSelect={false}
            menuShouldBlockScroll
            backspaceRemovesValue
            tabSelectsValue={false}
            placeholder="Hide"
            options={getShowInSelectOptions()}
            value={getShowInSelectValue(value)}
            onChange={(selectedOptions) => {
              const newValue = selectedOptions.length === 0
                ? [MemoryShowIn.Hide]
                : selectedOptions
                  .filter((v) => v.value !== MemoryShowIn.Hide)
                  .map((v) => v.value);
              const newEvent = {
                target: {
                  id,
                  value: newValue,
                } as unknown as EventTarget,
              } as unknown as React.ChangeEvent<HTMLInputElement>;
              onChange(newEvent);
            }}
          />
        )}
    </Form.Group>
  );
}
