import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddAnswerType } from './AddAnswerType';
import './AnswerTypes.css';
import { AnswerTypesTableRow } from './AnswerTypesTableRow';

export class AnswerTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      editAnswerTypeData: null,
      // eslint-disable-next-line react/no-unused-state
      pageTitle: 'Answer Types',
      listAnswerTypesActive: false,
      addAnswerTypeActive: false,
      // eslint-disable-next-line react/no-unused-state
      editAnswerTypeActive: false,
      loading: true,
    };

    // eslint-disable-next-line max-len
    // eslint-disable-next-line react/no-unused-class-component-methods, react/destructuring-assignment
    this.user = this.props.auth.getUser();

    this.page = {
      header: {
        title: 'Answer Types',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            // eslint-disable-next-line react/destructuring-assignment
            title: this.props.history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            // eslint-disable-next-line react/destructuring-assignment
            href: this.props.history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            // eslint-disable-next-line react/destructuring-assignment
            action: this.props.history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Answer Type',
            action: () => this.initAddAnswerType(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      listAnswerTypesActive: true,
    });
    this.loadAnswerTypesList();
  }

  initAddAnswerType = (obj) => {
    let editAnswerTypeData = null;
    if (obj) {
      editAnswerTypeData = obj;
    }
    this.setState({
      editAnswerTypeData,
      addAnswerTypeActive: true,
    });
  };

  cancelAddAnswerType = () => {
    this.setState({
      editAnswerTypeData: null,
      addAnswerTypeActive: false,
    });
  };

  answerTypesTableRow() {
    const action = this.initAddAnswerType;
    const updateAnswerTypes = () => {
      this.loadAnswerTypesList();
    };
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.types.length < 1) {
      return (
        <tr>
          <td colSpan={3}>There are no answer types</td>
        </tr>
      );
    }

    // eslint-disable-next-line max-len, react/destructuring-assignment, react/no-array-index-key
    return this.state.types.map((object, i) => <AnswerTypesTableRow obj={object} key={i} actionEditAnswerType={action} update={updateAnswerTypes} />);
  }

  loadAnswerTypesList() {
    api.get('/v1/answers/types?all=1')
      .then((res) => {
        this.setState({
          types: res.data.types,
          listAnswerTypesActive: true,
          addAnswerTypeActive: false,
          // eslint-disable-next-line react/no-unused-state
          editAnswerTypeActive: false,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const {
      loading, listAnswerTypesActive, addAnswerTypeActive, editAnswerTypeData,
    } = this.state;
    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card className={listAnswerTypesActive ? 'd-block' : 'd-none'}>
            <Card.Body>
              <Card.Title>List of Answer Types</Card.Title>

              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>ID</th>
                          <th>Default</th>
                          <th>Disabled</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.answerTypesTableRow()}
                      </tbody>
                    </Table>
                  )
              }

            </Card.Body>
          </Card>
        </PageWrapper>

        {addAnswerTypeActive
          ? (
            <AddAnswerType
              data={editAnswerTypeData}
              show={addAnswerTypeActive}
              // eslint-disable-next-line react/jsx-no-bind
              onHide={this.cancelAddAnswerType.bind(this)}
              // eslint-disable-next-line react/jsx-no-bind
              update={this.loadAnswerTypesList.bind(this)}
            />
          )
          : null}
      </>
    );
  }
}
