import React from 'react';
import { Button } from 'react-bootstrap';
import imgRestrict from '../../../../assets/images/deactivate.svg';
import imgReplace from '../../../../assets/images/replace.svg';
import imgCheck from '../../../../assets/images/ui/checked.png';

export const deleteImageColumns = (
  removeCallback: (id: number) => void,
  updateCallback: (id: number) => void,
  disabled = false,
) => [
  {
    dataField: 'title',
    text: 'Image name',
  },
  {
    dataField: 'canBeDeleted',
    text: 'Status',
    headerStyle: { width: '10%' },
    headerAlign: 'center',
    align: 'center',
    formatter: (canBeDeleted: boolean) => {
      if (canBeDeleted) {
        return (
          <img
            src={imgCheck}
            alt="Can be deleted"
            title="Can be deleted"
            style={{ width: '1rem' }}
          />
        );
      }
      return (
        <img
          src={imgRestrict}
          alt="Can't be deleted"
          title="Can't be deleted"
          style={{ width: '1rem' }}
        />
      );
    },
  },
  {
    dataField: 'Check',
    isDummyField: true,
    text: 'Check',
    headerStyle: { width: '10%' },
    headerAlign: 'center',
    align: 'center',
    formatter: (id: number, row: any) => (
      <Button
        as="div"
        size="sm"
        onClick={(e) => {
          e.stopPropagation();
          updateCallback(row.id);
        }}
        style={{ backgroundColor: 'transparent', border: 'none' }}
      >
        <img
          src={imgReplace}
          alt="Can't be deleted"
          title="Can't be deleted"
          style={{ width: '1rem', color: 'white' }}
        />
      </Button>
    ),

  },
  {
    dataField: 'Remove',
    isDummyField: true,
    text: 'Remove',
    headerStyle: { width: '10%' },
    headerAlign: 'center',
    align: 'center',
    formatter: (id: number, row: any, extraData: any) => (
      <Button
        size="sm"
        onClick={() => removeCallback(row.id)}
        disabled={disabled}
      >
        X
      </Button>
    ),
    formatExtraData: disabled,
  },
];
