import React, { Component } from 'react';
import {
  Button, Col, Form, Modal, Row,
} from 'react-bootstrap';
import { api } from '../../api';
import { PreviewImage } from './PreviewImage';

export class ValuesFormImageModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      validated: false,
      avatarImages: [],
      activeImage: '',
      file: null,
      // eslint-disable-next-line react/no-unused-state
      fileUrl: null,
      urlImg: null,
    };
  }

  componentDidMount() {
    const { src, data } = this.props;

    if (data) {
      this.loadData();
    } else {
      const avatarImages = [];
      avatarImages.push(src);
      this.setState({
        avatarImages,
        loading: false,
        activeImage: src,
        urlImg: src.src,
      });
    }
  }

  handleSubmit(event) {
    const { file, activeImage } = this.state;

    this.setState({
      loading: true,
    });
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData();

    formData.append('image', file);
    formData.append('path', activeImage.id);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({
        loading: false,
      });
    } else {
      api.post('/v1/avatar/assets/upload', formData, config)
        .then(() => {
          const { update, onHide } = this.props;
          update();
          onHide();
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }
    this.setState({
      validated: true,
    });
    event.stopPropagation();
  }

  onChangeFile(e) {
    const file = e.target.files[0];
    this.setState((prevState) => (
      {
        file,
        // eslint-disable-next-line react/no-unused-state
        fileUrl: file && file.type.startsWith('image') ? URL.createObjectURL(file) : null,
        urlImg: file && file.type.startsWith('image') ? URL.createObjectURL(file) : prevState.urlImg,
      }
    ));
  }

  loadData = () => {
    const { src, data } = this.props;
    api.get(`/v1/avatar/config/values/${data.id}`)
      .then((res) => {
        const img = [];
        img.push(src);
        if (res.data.value.avatarImages.length > 0) {
          img.push(...res.data.value.avatarImages.map((o) => o));
        }
        this.setState({
          avatarImages: img,
          loading: false,
          activeImage: img[0],
          urlImg: img[0].src,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  imageOptions() {
    const { avatarImages } = this.state;
    if (avatarImages.length > 0) {
      const res = [];
      res.push(
        ...avatarImages.map(
          (avatarImage) => (
            <option
              value={avatarImage.id}
              key={avatarImage.id}
            >
              {avatarImage.id}
            </option>
          ),
        ),
      );
      return res;
    }
    return <option disabled value="">No Images</option>;
  }

  render() {
    const { show, onHide } = this.props;
    const {
      loading, urlImg, file, activeImage, validated,
    } = this.state;

    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
        centered
        backdrop="static"
        keyboard={false}
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Edit Image(s)
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Form.Group as={Col} controlId="ValuesFormUploadImg">
              <Row>
                <Col sm={3}>
                  <Form.Label>Select Image</Form.Label>
                </Col>
                <Col sm={9}>
                  <Form.Control
                    as="select"
                    placeholder="Image"
                    name="templateId"
                    value={activeImage.id}
                    onChange={(e) => {
                      e.persist();
                      this.setState((prevState) => {
                        const v = prevState.avatarImages.find((a) => a.id === e.target.value);
                        return (
                          {
                            activeImage: v,
                            urlImg: v.src,
                          }
                        );
                      });
                    }}
                  >
                    {this.imageOptions()}
                  </Form.Control>
                </Col>
              </Row>

              <div className="ValuesForm-img-preview-wrap">
                <div className="ValuesForm-img-preview">
                  <PreviewImage src={urlImg} />
                </div>
                <Form.Control
                  required
                  type="file"
                  placeholder="Image"
                  name="image"
                  accept={urlImg && urlImg.includes('img/avatar/icon/') ? '.jpg' : '.png'}
                  onChange={(e) => this.onChangeFile(e)}
                />
              </div>

            </Form.Group>

          </Modal.Body>

          <Modal.Footer>

            <>
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                className="mx-2"
                disabled={loading}
                onClick={() => {
                  onHide();
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={loading || !file}
                variant="primary"
                size="sm"
                type="submit"
              >
                Save
              </Button>
            </>
          </Modal.Footer>

        </Form>
      </Modal>
    );
  }
}
