import async from 'async';
import React, { PureComponent } from 'react';
import {
  Button, ButtonGroup, CardColumns, Col, Dropdown, DropdownButton, Row, ToggleButton,
} from 'react-bootstrap';
import { api } from '../../api';
import { AvatarAssetsNewValue } from './AvatarAssetsNewValue';
import { PropertyForm } from './PropertyForm';
import './style.scss';
import { TypeForm } from './TypeForm';
import { ValuesForm } from './ValuesForm';

export class Properties extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      property: null,
      types: [],
      properties: [],
      values: [],
      activeValues: [],
      loading: false,
      imageUrl: null,
      isEdit: false,
      newValue: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevState.type !== this.state.type || prevState.property !== this.state.property) {
      this.activeValues();
    }
  }

  activeValues = () => {
    const { values } = this.state;
    if (values && values.length > 0) {
      const activeValues = [];
      values.forEach((r) => {
        if (
          !r
          // eslint-disable-next-line react/destructuring-assignment
          || r.typeId !== this.state.type.id
          // eslint-disable-next-line react/destructuring-assignment
          || r.propertyId !== this.state.property.id
        ) {
          return;
        }

        activeValues.push(r);
      });
      this.setState({
        activeValues,
      });
    }
  };

  loadData = () => {
    this.setState({
      loading: true,
    });
    async.parallel({
      types: (callback) => {
        api.get('/v1/avatar/config/types')
          .then((res) => {
            callback(null, res.data.typess);
          }).catch((error) => {
            callback(error, null);
          });
      },
      properties: (callback) => {
        api.get('/v1/avatar/config/properties')
          .then((res) => {
            callback(null, res.data.propertiess);
          }).catch((error) => {
            callback(error, null);
          });
      },
      values: (callback) => {
        api.get('/v1/avatar/config/values')
          .then((res) => {
            callback(null, res.data.values);
          }).catch((error) => {
            callback(error, null);
          });
      },
      config: (callback) => {
        api.get('/v1/avatar/config')
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          // eslint-disable-next-line max-len
          // eslint-disable-next-line max-len, react/destructuring-assignment, react/no-access-state-in-setstate
          const type = !this.state.type && res.types && res.types.length > 0 ? res.types[0] : this.state.type;
          // eslint-disable-next-line max-len
          // eslint-disable-next-line max-len, react/destructuring-assignment, react/no-access-state-in-setstate
          const property = !this.state.property && res.properties && res.properties.length > 0 ? res.properties[0] : this.state.property;
          this.setState({
            type,
            property,
            imageUrl: res.config.imageUrl,
            types: res.types,
            properties: res.properties,
            values: res.values,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  loadType = () => {
    this.setState({
      isEdit: true,
    });
    api.get('/v1/avatar/config/types')
      .then((res) => {
        this.setState((prevState) => (
          {
            type: res.data.typess.find((e) => e.id === prevState.type.id),
            types: res.data.typess,
            isEdit: false,
          }
        ));
      })
      .catch(() => {
        this.setState({
          isEdit: false,
        });
      });
  };

  loadProperties = () => {
    this.setState({
      isEdit: true,
    });
    api.get('/v1/avatar/config/properties')
      .then((res) => {
        this.setState((prevState) => (
          {
            property: res.data.propertiess.find((e) => e.id === prevState.property.id),
            properties: res.data.propertiess,
            isEdit: false,
          }
        ));
      })
      .catch(() => {
        this.setState({
          isEdit: false,
        });
      });
  };

  loadValues = () => {
    this.setState({
      isEdit: true,
    });
    api.get('/v1/avatar/config/values')
      .then((res) => {
        this.setState({
          values: res.data.values,
          isEdit: false,
        }, () => {
          this.activeValues();
        });
      })
      .catch(() => {
        this.setState({
          isEdit: false,
        });
      });
  };

  render() {
    return (
      <div>
        <h1>Properties</h1>
        <br />
        <br />

        <Row>
          <Col>
            {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.property
                && (
                <DropdownButton
                  // eslint-disable-next-line react/destructuring-assignment
                  disabled={this.state.isEdit}
                  as={ButtonGroup}
                  id="propertyBtn"
                  // eslint-disable-next-line react/destructuring-assignment, max-len
                  title={this.state.property.label ? this.state.property.label : this.state.property.ident}
                  variant="secondary"
                >
                  {
                    // eslint-disable-next-line react/destructuring-assignment
                    (this.state.properties && this.state.properties.length > 0)
                    // eslint-disable-next-line react/destructuring-assignment
                    && this.state.properties.map((newProperty) => (
                      <Dropdown.Item
                        key={newProperty.id}
                        // eslint-disable-next-line react/destructuring-assignment
                        active={JSON.stringify(this.state.property) === JSON.stringify(newProperty)}
                        onClick={() => this.setState({ property: newProperty })}
                      >
                        {newProperty.label ? newProperty.label : newProperty.ident}
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
                )
              }

          </Col>
          <Col className="text-right">
            <ButtonGroup
              // eslint-disable-next-line react/destructuring-assignment
              disabled={this.state.isEdit}
              toggle
            >
              {
                  // eslint-disable-next-line react/destructuring-assignment
                  (this.state.types && this.state.types.length > 0)
                  // eslint-disable-next-line react/destructuring-assignment
                  && this.state.types.map((newProperty) => (
                    <ToggleButton
                      // eslint-disable-next-line react/destructuring-assignment
                      disabled={this.state.isEdit}
                      key={newProperty.id}
                      type="radio"
                      variant="secondary"
                      name="type"
                      value={newProperty}
                      // eslint-disable-next-line react/destructuring-assignment
                      checked={JSON.stringify(this.state.type) === JSON.stringify(newProperty)}
                      onChange={() => this.setState({ type: newProperty })}
                    >
                      {newProperty.label ? newProperty.label : newProperty.ident}
                    </ToggleButton>
                  ))
                }
            </ButtonGroup>
          </Col>
        </Row>

        <Row>
          <PropertyForm
            // eslint-disable-next-line react/destructuring-assignment
            data={this.state.property}
            // eslint-disable-next-line react/destructuring-assignment
            disabled={this.state.isEdit}
            update={this.loadProperties}
            isEdit={(e) => {
              this.setState({
                isEdit: e,
              });
            }}
          />

          <TypeForm
            // eslint-disable-next-line react/destructuring-assignment
            data={this.state.type}
            // eslint-disable-next-line react/destructuring-assignment
            disabled={this.state.isEdit}
            update={this.loadType}
            isEdit={(e) => {
              this.setState({
                isEdit: e,
              });
            }}
          />
        </Row>

        <Row>
          <Col>
            <h2 className="pt-4">
              Available images for
              {' '}
              { /* eslint-disable-next-line react/destructuring-assignment */}
              {(this.state.property && this.state.property.label) && this.state.property.label}
              (
              { /* eslint-disable-next-line react/destructuring-assignment */}
              {(this.state.property && this.state.property.ident) && this.state.property.ident}
              )
              /
              {' '}
              { /* eslint-disable-next-line react/destructuring-assignment */}
              {(this.state.type && this.state.type.label) && this.state.type.label}
            </h2>
          </Col>
          { /* eslint-disable-next-line react/destructuring-assignment */}
          {!this.state.loading
            && (
            <Col className="text-right align-self-end">
              <Button
                // eslint-disable-next-line react/destructuring-assignment
                disabled={this.state.isEdit}
                size="sm ml-2"
                onClick={() => {
                  this.setState({
                    newValue: true,
                  });
                }}
              >
                Add New
              </Button>
              <AvatarAssetsNewValue
                // eslint-disable-next-line react/destructuring-assignment
                property={this.state.property}
                // eslint-disable-next-line react/destructuring-assignment
                type={this.state.type}
                // eslint-disable-next-line react/destructuring-assignment
                show={this.state.newValue}
                update={this.loadValues}
                onHide={() => {
                  this.setState({
                    newValue: false,
                  });
                }}
              />
            </Col>
            )}
        </Row>
        <CardColumns className="pt-4 CardColumns-avatar-prop">
          {
              // eslint-disable-next-line react/destructuring-assignment
              (this.state.activeValues && this.state.activeValues.length > 0)
              // eslint-disable-next-line react/destructuring-assignment
              && this.state.activeValues.map((r, i) => (
                <ValuesForm
                  loadValues={this.loadValues}
                  // eslint-disable-next-line react/destructuring-assignment
                  imageUrl={this.state.imageUrl}
                  // eslint-disable-next-line react/destructuring-assignment
                  type={this.state.type}
                  // eslint-disable-next-line react/destructuring-assignment
                  property={this.state.property}
                  // eslint-disable-next-line react/destructuring-assignment
                  key={`${this.state.type.ident}_${this.state.property.ident}_${r.ident}`}
                  // eslint-disable-next-line react/destructuring-assignment
                  i={`${this.state.type.ident}_${this.state.property.ident}_${r.ident}`}
                  index={i}
                  data={r}
                  // eslint-disable-next-line react/destructuring-assignment
                  disabled={this.state.isEdit}
                  update={(data, index) => {
                    const { activeValues } = this.state;
                    activeValues[index] = data;
                    this.setState({
                      activeValues,
                    });
                  }}
                  isEdit={(e) => {
                    this.setState({
                      isEdit: e,
                    });
                  }}
                />
              ))
            }
        </CardColumns>

      </div>
    );
  }
}
