import serialize from 'form-serialize';
import React from 'react';
import {
  Alert, Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { BookGroupId } from '../../../../dorian-shared/types/book/book';
import { api } from '../../../api';
import './style.scss';

export function PromptFeaturing(props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [alertText, setAlertText] = React.useState(null);
  const [tableVal, setTableVal] = React.useState([]);
  const [books, setbooks] = React.useState([]);
  const [tableC, setTableC] = React.useState(false);

  const loadData = () => {
    if (!loading) {
      setLoading(true);
      // eslint-disable-next-line react/destructuring-assignment
      api.get(`/v1/books/?promptId=${props.id}`)
        .then((res) => {
          const releasedLiveBooks = res.data.books.filter(
            (book) => {
              const isReleased = book.approved_count > 0;
              const isLive = book.groupId !== BookGroupId.Archived;
              return isReleased && isLive;
            },
          ) ?? [];

          const data = releasedLiveBooks.map(
            (book) => {
              const owner = book.users.find((user) => user.story_role === 'owner');
              return {
                id: book.id,
                title: book.title,
                authors: owner?.fullname ?? '-',
                similarSort: book.data?.similarSort ?? '',
                label: book.data?.label ?? '',
                spinoffEndDate: book.data?.spinoffEndDate
                  ? new Date(book.data.spinoffEndDate)
                  : new Date(),
                addStartDate: Boolean(book.data?.spinoffEndDate
                        && !!(book.data?.similarSort || book.data?.label)),
              };
            },
          );
          setTableVal(data);
          setbooks(res.data.books);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const showAlert = (status, text) => {
    setSuccess(status);
    setAlertText(text);
    setTimeout(() => {
      setAlertText(null);
      setLoading(false);
      // eslint-disable-next-line react/destructuring-assignment
      if (status) props.onHide();
    }, 3000);
  };

  const saveData = (data) => {
    setLoading(true);
    const newData = { ...data };
    Object.keys(newData).forEach((key) => {
      const x = newData[key];
      newData[key] = {
        ...x.similarSort && { similarSort: parseInt(x.similarSort, 10) },
        ...x.label && { label: x.label },
        ...x.spinoffEndDate && { spinoffEndDate: x.spinoffEndDate },
      };
    });
    api.post('v1/prioritization/data/spinoff', newData)
      .then(() => {
        showAlert(true, 'Spinoff featuring Saved');
      })
      .catch((err) => {
        showAlert(false, err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const obj = serialize(form, { hash: true, empty: true });
    saveData(obj);
    e.stopPropagation();
  };

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formDatePicker = (cell, obj) => {
    const setAddStartDate = (v, name) => {
      // eslint-disable-next-line no-param-reassign
      obj[name] = v;
      const d = [];
      tableVal.forEach((o) => {
        if (o.id === obj.id) {
          d.push(obj);
        } else {
          d.push(o);
        }
      });
      setTableVal(d);
      setTableC(!tableC);
    };

    if (
      obj.addStartDate && (!!obj.similarSort || !!obj.label)
    ) {
      return (
        <div className="PFR-DatePicker-box">
          <DatePicker
            size="sm"
            className="form-control form-control-sm"
            disabled={loading}
            name={`${obj.id}[spinoffEndDate]`}
            placeholder="End Date"
            selected={obj.spinoffEndDate || new Date()}
            onChange={(date) => setAddStartDate(date, 'spinoffEndDate')}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
          />
          <Button
            size="sm"
            className="PFR-DatePicker-close"
            onClick={() => setAddStartDate(false, 'addStartDate')}
          >
            <span>×</span>
          </Button>
        </div>
      );
    }
    return (
      <Button
        size="sm"
        onClick={() => setAddStartDate(true, 'addStartDate')}
        disabled={!obj.similarSort && !obj.label}
      >
        Add End Date
      </Button>
    );
  };

  const formControl = (cell, obj, name, placeholder, type) => (
    <Form.Control
      autoFocus={obj.focus}
      size="sm"
      type="text"
      placeholder={placeholder}
      disabled={loading}
      value={cell}
      onChange={(e) => {
        // eslint-disable-next-line no-param-reassign,no-nested-ternary
        obj[name] = type === 'number'
          ? e.target.value >= 1
            ? Number(Math.round(e.target.value))
            : ''
          : e.target.value;
        // eslint-disable-next-line no-param-reassign
        obj.focus = true;
        const d = [];
        tableVal.forEach((o) => {
          if (o.id === obj.id) {
            d.push(obj);
          } else {
            d.push(o);
          }
        });
        setTableVal(d);
        setTableC(!tableC);
      }}
      name={`${obj.id}[${name}]`}
    />
  );

  const columns = [{
    dataField: 'title',
    text: 'Story',
    sort: true,
    // eslint-disable-next-line react/no-unstable-nested-components
    formatter: (cell, obj) => (
      <Link
        to={`/book/${obj.id}`}
        target="_blank"
      >
        {cell}
      </Link>
    ),
  },
  {
    dataField: 'authors',
    text: 'Authors',
    sort: true,
  },

  {
    dataField: 'similarSort',
    text: 'Spinoff Rank',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formControl(cell, obj, 'similarSort', 'Spinoff Rank', 'number'),
  },
  {
    dataField: 'label',
    text: 'Tag Text',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formControl(cell, obj, 'label', 'Tag Text', 'text'),
  },
  {
    dataField: 'spinoffEndDate',
    text: 'End Date',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formDatePicker(cell, obj),
  },
  ];

  return (
    <Modal
      {...props}
      size="xl"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header
        closeButton={(!loading)}
      >
        <Modal.Title>Spinoff featuring</Modal.Title>
      </Modal.Header>

      <Form
        noValidate
        onSubmit={handleSubmit}
      >
        <Modal.Body className="overflow-visible" />

        <Alert
          variant={success ? 'success' : 'danger'}
          show={alertText != null}
          style={{ margin: '1em 2em' }}
        >
          {alertText}
        </Alert>

        {
            tableVal && tableVal.length > 0
              ? (
                <div
                  style={{
                    padding: '1em 2em',
                  }}
                >
                  <BootstrapTable
                    hover
                    striped
                    condensed
                    bootstrap4
                    keyField="id"
                    bordered={false}
                    columns={columns}
                    data={tableVal}
                  />
                </div>
              )
              : (
                <h2 style={{
                  fontSize: '1.2em',
                  padding: '.5em 2em 2em 2em',
                }}
                >
                  This Prompt does not have any spinoffs yet.
                </h2>
              )
          }

        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            /* eslint-disable-next-line react/destructuring-assignment */
            onClick={props.onHide}
            disabled={loading}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            disabled={
                loading
                || (!books || books.length < 1)
              }
          >
            {
                loading
                && (
                <Spinner
                  size="sm"
                  animation="border"
                />
                )
              }
            Save
          </Button>
        </Modal.Footer>
      </Form>

    </Modal>
  );
}
