import cs from 'classnames';
import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import classNames from './DebouncedSearch.module.scss';

interface DebouncedSearchProps {
    initialValue: string
    onSearchSubmit: (value: string) => void
    showButton?: boolean
    className?: string
    placeholder?: string
}

export function DebouncedSearch(props: DebouncedSearchProps) {
  const {
    initialValue,
    onSearchSubmit,
    showButton = true,
    className,
    placeholder = 'Type search substring',
  } = props;
  const [searchString, setSearchString] = useState(initialValue);

  const onSearchSubmitDebounced = useDebouncedCallback(
    (newSearchString) => onSearchSubmit(newSearchString),
    1000,
  );

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    onSearchSubmit(searchString);
  };

  const onChangeSearchString = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchString = event.target.value;
    setSearchString(newSearchString);
    onSearchSubmitDebounced(newSearchString);
  };

  return (
    <div className={cs(classNames.searchContainer, className)}>
      <FormControl
        type="search"
        className="search-form_input"
        size="sm"
        placeholder={placeholder}
        aria-label="Search"
        value={searchString}
        onChange={onChangeSearchString}
      />
      {showButton && (
      <Button
        className={classNames.button}
        type="submit"
        size="sm"
        variant="secondary"
        onClick={handleSubmit}
      >
        Search
      </Button>
      )}
    </div>
  );
}
