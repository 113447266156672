import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { doubleQuote } from '../../../../helpers/html';
import { api } from '../../../api';
import { getUserDisplayName } from '../../Users';

export class DeleteBookShare extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      allBooks: false,
    };
  }

  deleteAction() {
    const {
      user,
      bookId,
      onError,
      onConfirm,
    } = this.props;
    const { allBooks } = this.state;

    const stories = allBooks || null;
    this.setState({ showLoading: true });

    api.delete(`v1/books/${bookId}/share/${user.id}`, {
      params: {
        stories,
      },
    })
      .then(() => onConfirm())
      .catch((error) => onError(error.response.data.error));
  }

  render() {
    const { user, onCancel, show } = this.props;
    const { showLoading } = this.state;

    return (
      <Modal
        onHide={onCancel}
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        className="zIndexModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Stop sharing with:
            <span>
              {doubleQuote}
              {getUserDisplayName(user)}
              {doubleQuote}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="text-center">
              Do you really want to cancel sharing the story?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {showLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
