// eslint-disable-next-line no-shadow
export enum ButtonAction {
    Story = 'story',
    Chat = 'chat'
}
export interface Event {
    id: number;
    title: string;
    description: string;
    imageUrl: string;
    active: boolean;
    priority: number;
    buttonText: string;
    startTime: string; // Date
    endTime: string; // Date
    label: string;
    colors: string;

    prize: null | string; // JSON.stringify({prizeType: "hearts" | "", prizeValue: string})
    buttonAction: ButtonAction;
    buttonTarget: string;

    specialRequirements: string;
    authorLabel: string;
    createdAt: string; // Date
    updatedAt: string; // Date
}
