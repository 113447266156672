import { Cropper } from 'react-cropper';

export const defaultQualityCompression = 1;

// Found BUG in cropper
// https://github.com/fengyuanchen/cropper/issues/966
export const calculateMaxSideByAspectRatio = (
  aspectRatio: number,
  maxOutputImageWidth = 1000,
  maxOutputImageHeight = 1000,
) => {
  if (aspectRatio < 1) {
    const maxHeight = maxOutputImageWidth / aspectRatio;
    const maxWidth = maxOutputImageHeight / aspectRatio;
    return { maxWidth, maxHeight };
  }
  const maxHeight = maxOutputImageWidth * aspectRatio;
  const maxWidth = maxOutputImageHeight * aspectRatio;

  return { maxWidth, maxHeight };
};

export const convertCanvasToBlob = (
  canvas: HTMLCanvasElement,
  type: 'image/png' | 'image/jpeg',
  quality = defaultQualityCompression,
) => new Promise((resolve, reject) => {
  canvas.toBlob((blob) => {
    if (blob) resolve(blob);
    else reject(new Error('Error: Blob is null'));
  }, type, quality);
});

export const convertCanvasToFileWithCropper = async (
  cropper: Cropper,
  options: Cropper.GetCroppedCanvasOptions,
  fileName: string,
  fileType: 'image/png' | 'image/jpeg',
  quality = defaultQualityCompression,
) => {
  try {
    const canvas = await cropper.getCroppedCanvas(options);
    const blob = await convertCanvasToBlob(canvas, fileType, quality) as Blob;
    return new File([blob], fileName);
  } catch (error) {
    throw new Error('Error: Can\'t convert canvas to file');
  }
};
