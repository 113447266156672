export function isNodeInRoot(node, root) {
  while (node) {
    if (node === root) {
      return true;
    }
    // eslint-disable-next-line no-param-reassign
    node = node.parentNode;
  }
  return false;
}
