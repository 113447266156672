import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddCode } from './AddCode';
import './Code.css';
import { CodeTableRow } from './CodeTableRow';

export class SignUpCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      loading: true,
      addCode: false,
    };

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Sign Up Codes',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Code',
            action: () => this.initAddCode(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/signup/codes')
      .then((res) => {
        this.setState({
          loading: false,
          addCode: false,
          codes: res.data.codes,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  initAddCode = () => {
    this.setState({
      addCode: true,
    });
  };

  cancelAddCode = () => {
    this.setState({
      addCode: false,
    });
  };

  codeTableRow() {
    const { codes } = this.state;

    const update = () => {
      this.loadData();
    };
    return codes.map((object, i) => (
      <CodeTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        obj={object}
        update={update}
      />
    ));
  }

  render() {
    const { addCode, loading } = this.state;
    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card className="d-block">
            <Card.Body>
              <Card.Title>List of Codes</Card.Title>
              {loading ? (
                <div className="text-center">
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="loadingSpinner justify-content-center"
                  />
                </div>
              ) : (
                <Table striped hover size="md">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>User</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {this.codeTableRow()}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>

        </PageWrapper>

        {addCode && (
        <AddCode
          show={addCode}
          onHide={this.cancelAddCode}
          update={this.loadData}
        />
        )}
      </>
    );
  }
}
