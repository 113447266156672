import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';

export class AvatarJSONEditor extends Component {
  constructor(props, context) {
    super(props, context);
    const { obj } = this.props;
    this.state = {
      object: JSON.stringify(obj || {}, null, 2),
      error: null,
    };
  }

  editDone = () => {
    const { update } = this.props;
    const { object } = this.state;
    if (!update) {
      return;
    }
    try {
      update(JSON.parse(object));
    } catch (e) {
      this.setState({ error: e.message });
    }
  };

  render() {
    const { onHide, title, uuid } = this.props;
    const { error, object, loading } = this.state;
    return (
      <Card className="view p-3">

        <div className="menu my-3" style={{ justifyContent: 'flex-start' }}>
          <Button
            className="mx-1"
            size="sm"
            type="reset"
            variant="primary"
            onClick={onHide}
            disabled={(loading)}
          >
            Back
          </Button>
          <h3 className="my-0 pl-3">{title || 'JSON Editor'}</h3>
        </div>
        <hr />

        <Row>
          <Col md={uuid ? 9 : 12}>
            <Form.Group controlId="Title">
              <Form.Control
                as="textarea"
                size="sm"
                rows={15}
                value={object}
                isInvalid={!!error}
                onChange={(e) => {
                  this.setState({
                    object: e.target.value,
                    error: null,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="menu-right my-3">
          <Button
            className="mx-2"
            size="sm"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            size="sm"
            variant="primary"
            onClick={this.editDone}
          >
            Done
          </Button>
        </div>
      </Card>
    );
  }
}
