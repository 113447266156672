import React, { PureComponent } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { recBonusContent, recChoices, recDecisionPoints } from '../constants';
import { OptionStatsList } from './OptionStatsList';

export class OptionAdminRequirements extends PureComponent {
  constructor(props) {
    super(props);
    const { public: isPublic, recommendations } = this.props;
    this.state = {
      decisionPoints: recommendations.decisionPoints || recDecisionPoints,
      choices: recommendations.choices || recChoices,
      bonus_content: recommendations.bonus_content || recBonusContent,
      public: Boolean(isPublic),
    };
  }

  componentDidUpdate(prevProps) {
    const { public: isPublic, recommendations } = this.props;
    if (prevProps.public !== isPublic) {
      this.setState({
        public: isPublic,
      });
    }
    if (
      prevProps.recommendations !== recommendations
      || JSON.stringify(prevProps.recommendations) !== JSON.stringify(recommendations)
    ) {
      this.setState({
        decisionPoints: recommendations.decisionPoints || recDecisionPoints,
        choices: recommendations.choices || recChoices,
        bonus_content: recommendations.bonus_content || recBonusContent,
      });
    }
  }

  render() {
    const {
      hideTemplate, user, types, recommendations,
    } = this.props;
    const {
      public: isPublic,
      decisionPoints,
      choices,
      bonus_content: bonusContent,
    } = this.state;

    if (user.role !== 'admin') {
      return null;
    }

    return (
      <>
        {!hideTemplate && (
          <>
            <hr />
            <Form.Row>
              <Form.Group as={Col} controlId="AdminRecommendations">
                <Form.Label>Admin Requirements</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} sm={4} controlId="isPublic">
                <Form.Check
                  custom
                  type="checkbox"
                  label="Public"
                  name="public"
                  checked={isPublic}
                  onChange={
                    () => {
                      this.setState({
                        public: !isPublic,
                      });
                    }
                  }
                />
              </Form.Group>
            </Form.Row>
          </>
        )}

        <Form.Row>

          <Form.Group as={Col} controlId="decisionPoints" sm={6} xs={12}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text>Decision Points</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                placeholder="Decision Points"
                name="recommendations[decisionPoints]"
                value={decisionPoints}
                onChange={(e) => {
                  this.setState({
                    decisionPoints: e.target.value >= 0 ? e.target.value : '',
                  });
                }}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Please choose a Decision Points.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} controlId="Choices" sm={6} xs={12}>
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text>Choices</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                placeholder="Choices"
                name="recommendations[choices]"
                value={choices}
                onChange={(e) => {
                  this.setState({
                    choices: e.target.value >= 0 ? e.target.value : '',
                  });
                }}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Please choose a Choices.
            </Form.Control.Feedback>
          </Form.Group>

        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="BonusContent">
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text>Bonus Content</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                placeholder="Bonus Content"
                name="recommendations[bonus_content]"
                value={bonusContent}
                onChange={(e) => {
                  this.setState({
                    bonus_content: e.target.value >= 0 ? e.target.value : '',
                  });
                }}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Please choose a Bonus Content.
            </Form.Control.Feedback>
          </Form.Group>
        </Form.Row>

        {!window.$traits && (
        <Form.Row>
          <OptionStatsList
            types={types}
            stats={recommendations.stats}
          />
        </Form.Row>
        )}
      </>
    );
  }
}
