import React from 'react';
import { FormControl } from 'react-bootstrap';

export function Search({
  value,
  onChange,
}) {
  return (
    <FormControl
      value={value}
      className="search-form_input"
      size="sm"
      type="search"
      placeholder="Type search substring"
      aria-label="Search"
      onChange={(event) => onChange(event.target.value)}
    />
  );
}
