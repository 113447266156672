import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './Earnings.css';


const EarningsPopup = ({ user, transactions, onClose, show, parentModal }) => {

  useEffect(() => {
    if(parentModal) {
      const parentElement = document.getElementById(parentModal);

      if (parentElement) {
        if (show) {
          parentElement.style.display = 'none';  // Hide AuthorProfile when Earnings is open
          parentElement.style.pointerEvents = 'none';  // Disable interactions
        } else {
          parentElement.style.display = 'block';  // Restore visibility when Earnings is closed
          parentElement.style.pointerEvents = 'auto';  // Enable interactions
        }
      }

      // Cleanup function to ensure styles are reset when component unmounts
      return () => {
        if (parentElement) {
          parentElement.style.display = 'block';  // Restore visibility
          parentElement.style.pointerEvents = 'auto';  // Restore interactions
        }
      };
    }
  }, [show]);

  const formatDate = (date, index, transactions) => {
    if (date === 0) {
      if (index > 0) {
        return `pre-${formatDateToYYYYMM(transactions[index - 1].date)}`;
      } else {
        return 'pre-2024-07';
      }
    }
    return formatDateToYYYYMM(date);
  };

  const formatDateToYYYYMM = (date) => {
    const dateString = date.toString();
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    return `${year}-${month}`;
  };

  const formatState = (state) => {
    switch (state) {
      case 'reviewing':
        return 'Processing payment receipts';
      case 'payment_pending':
        return 'Payment is on the way!';
      case 'paid':
        return 'Paid';
      default:
        return '';
    }
  };


  return (
    <Modal show={show} onHide={onClose} size="lg" centered backdrop={true} backdropClassName={parentModal ? "earnings-backdrop" : ""}>
      <Modal.Header closeButton>
        <Modal.Title>Earnings Breakdown</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <div className="earnings-row">
          <p>
            <b>
              Estimated Hearts Earned This Month:
              {user.hearts ? user.hearts.estimatedHeartsEarned : 0}
              {' '}
              <span role="img" aria-label="heart">❤️</span>
            </b>
          </p>
        </div>
        
        <div className="earnings-row">
          <p>
            <b>
              Hearts Under Threshold: 
              {user.hearts ? user.hearts.heartsPayable : 0} 
              {' '}
              <span role="img" aria-label="heart">❤️</span>
            </b>
          </p>
        </div>

        {transactions && transactions.length > 0 && (
          <>
            <div className="earnings-table-container">
              <table className="earnings-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hearts</th>
                    <th>Status</th>
                  </tr>
                </thead>
              </table>

              <div className="earnings-scrollable-tbody">
                <table className="earnings-table">
                  <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{formatDate(transaction.date, index, transactions)}</td>
                        <td>{transaction.hearts} ❤️</td>
                        <td>{formatState(transaction.state)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}

        <div className="earnings-info-section">
          <br></br>
          <h6>How do I read this page?</h6>
          <p>Your profile shows you the status of your heart earnings. Please keep these definitions in mind:</p>
          <br></br>
          <ul>
            <li>
              <strong>Estimated Hearts Earned this month:</strong>
              This is a record of hearts you have earned for the current month. 
              Please note that due to potential chargebacks, this number is not final 
              until it is marked as “Payment is on the way!”{transactions && transactions.length > 0 && " in the table above."}
            </li>
            <li>
              <strong>Hearts under threshold:</strong>
              You will see hearts here from past months until you reach the 500 Heart threshold.
            </li>
            <li>
              <strong>Processing payment receipts:</strong>
              The Dorian team is reviewing your hearts for chargebacks to finalize your payment.
            </li>
            <li>
              <strong>Payment is on the way!:</strong>
              Your payment has been sent to our payment provider.
            </li>
            <li>
              <strong>Paid:</strong>
              Your money should be paid out!
            </li>
          </ul>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default EarningsPopup;

