import React, { Component } from 'react';
import { PageWrapper } from '../../ui/PageWrapper';
import { Properties } from './Properties';
import { Validate } from './Validate';

export class AvatarAssets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'properties',
    };

    this.page = {
      header: {
        title: 'Manage Avatar Asset',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            // eslint-disable-next-line react/destructuring-assignment
            title: this.props.history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            // eslint-disable-next-line react/destructuring-assignment
            href: this.props.history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            // eslint-disable-next-line react/destructuring-assignment
            action: this.props.history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            variant: 'space',
          },
          {
            title: 'Properties',
            action: () => this.setState({ view: 'properties' }),
            variant: 'primary',
            disabled: false,
          },
          {
            title: 'Validate',
            action: () => this.setState({ view: 'validate' }),
            variant: 'primary',
            disabled: false,
          },

        ],
      },
    };
  }

  renderView() {
    // eslint-disable-next-line react/destructuring-assignment
    switch (this.state.view) {
      case 'validate':
        return (<Validate />);
      case 'properties':
        return (<Properties />);
      default:
    }
    return (<div> Comming Soon</div>);
  }

  render() {
    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        {this.renderView()}
      </PageWrapper>
    );
  }
}
