import React, { Component } from 'react';
import { SelectableGroupContext } from './Context';
import { getBoundsForNode } from './utils';

export const createSelectable = (WrappedComponent) => {
  class SelectableItem extends Component {
    constructor(...args) {
      super(...args);
      const { selectableId, isSelected } = this.props;

      this.state = {
        isSelected,
        isSelecting: false,
      };
      this.node = null;
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.bounds = null;
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.id = selectableId || null;
    }

    componentDidMount() {
      this.registerSelectable();
    }

    componentWillUnmount() {
      const { selectable } = this.context;

      selectable.unregister(this);
    }

    registerSelectable = (containerScroll) => {
      const { selectable } = this.context;

      // eslint-disable-next-line react/no-unused-class-component-methods
      this.bounds = getBoundsForNode(this.node, containerScroll);
      selectable.register(this);
    };

    getSelectableRef = (ref) => {
      this.node = ref;
    };

    render() {
      return (
        <WrappedComponent {...this.props} {...this.state} selectableRef={this.getSelectableRef} />
      );
    }
  }

  SelectableItem.contextType = SelectableGroupContext;

  return SelectableItem;
};
