import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Button, Card, Col, Form, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { useApiService } from '../../../contexts/ApiServiceContext/ApiServiceContext';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { Pagination } from '../../ui/Pagination/Pagination';
import { showToast } from '../../ui/utils';
import { DeleteImageModal } from '../Backgrounds/DeleteImageModal/DeleteImageModal';
import { UploadImage } from '../Backgrounds/UploadImage';
import { ImagePageSizeSelector } from '../PageSelector/ImagePageSizeSelector';
import { SearchBar } from '../SearchBar/SearchBar';
import { LibraryType } from '../UploadFilesModal/types';
import { UploadFilesModal } from '../UploadFilesModal/UploadFilesModal';
import { CharacterArtLibraryItem } from './CharacterArtLibraryItem';
import { CharacterListHeader } from './CharacterListHeader';
import './index.scss';

export function CharacterArtLibrary(props) {
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [images, setImages] = useState([]);
  const [uploadImage, setUploadImage] = useState(false);
  const [editImage, setEditImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCountImages, setTotalCountImages] = useState(0);
  const [searchImageString, setSearchImageString] = React.useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOnlyUserImages, setShowOnlyUserImages] = useState(true);
  const [pageSize, setPageSize] = useState(7);

  const {
    history,
    auth,
  } = props;

  const apiService = useApiService();

  const user = auth.getUser();
  const isAdmin = user.role === 'admin';

  const initUpload = (type) => {
    setUploadImage(type);
    setEditImage(null);
  };

  const page = {
    header: {
      title: 'Character Art Library',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
          href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
          disabled: false,
        },
        {
          title: 'Upload Character Art',
          action: () => initUpload('single'),
          variant: 'primary',
          disabled: false,
        },
        {
          title: 'Bulk Upload Character Art',
          action: () => initUpload('bulk'),
          variant: 'primary',
          disabled: false,
        },
      ],
    },
  };

  const loadImages = useCallback(() => {
    setLoading(true);

    const params = {
      order: 'createdAt:desc',
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      search: searchImageString || undefined,
      authorId: showOnlyUserImages ? user.id : undefined,
    };
    api.get('/v1/customcharacters', { params })
      .then((response) => {
        const { images: loadedImages, totalCount } = response.data;

        setImages(loadedImages);
        setTotalCountImages(totalCount);
        setSelectedImageIds((prevIds) => prevIds.filter(
          (id) => loadedImages.some((image) => image.id === id),
        ));
        setSelectedImage((prevImage) => loadedImages.find((image) => image.id === prevImage?.id));
      }).finally(() => setLoading(false));
  }, [currentPage, pageSize, searchImageString, showOnlyUserImages, user.id]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  const cancelUpload = () => {
    setUploadImage(null);
  };

  const confirmUpload = () => {
    setUploadImage(null);

    if (uploadImage === 'single' && editImage) {
      loadImages();
      return;
    }

    if (currentPage > 1) {
      setCurrentPage(1);
    } else {
      loadImages();
    }
  };

  const handleReplaceImage = async () => {
    setLoading(true);

    try {
      const usedBooks = await apiService.fetchUsedArtBooksByCharacterId(selectedImage.id);
      if (usedBooks.length === 0 || isAdmin) {
        setUploadImage('single');
        setEditImage(selectedImage);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    showToast({ textMessage: 'This image is used in Books and cannot be replaced', variant: 'danger' });
  };

  const paginationProps = {
    pageSize,
    currentPage,
    totalCount: totalCountImages,
    onPageChange: (newPage) => {
      setCurrentPage(newPage);
      setSelectedImageIds([]);
    },
  };

  const acceptExt = { 'image/png': ['.png'] };

  const activeSelectedImage = selectedImage ? [selectedImage] : [];
  const selectedImagesFiltered = images.filter((image) => selectedImageIds.includes(image.id));
  const imagesTodDelete = selectedImagesFiltered.length === 0
    ? activeSelectedImage
    : selectedImagesFiltered;

  return (
    <>
      <PageWrapper
        {...props}
        page={page}
      >
        <Card className="d-block">
          <Card.Body>
            <Card.Title className="text-center">
              Character Art Library
              {loading && (
                <>
                  <div className="spinner">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="ml-2"
                      size="lg"
                    />
                  </div>
                  <div className="overlay" />
                </>
              )}
            </Card.Title>
            {
              (!loading || images.length > 0)
              && (
              <Row>
                <Col sm={8}>
                  <SearchBar
                    value={searchImageString}
                    onChange={(searchStringValue) => {
                      setSearchImageString(searchStringValue);
                      setCurrentPage(1);
                    }}
                  />
                  <Pagination {...paginationProps} />
                  <Row>
                    <Col sm="auto" className="d-flex align-items-center">
                      <ImagePageSizeSelector
                        size="sm"
                        value={pageSize}
                        onChange={(value) => {
                          setPageSize(value);
                          setCurrentPage(1);
                        }}
                      />
                      <span className="ml-2">per page</span>
                    </Col>
                    {isAdmin && (
                    <Col className="text-right mt-2">
                      <Form.Check
                        type="switch"
                        id="showOnlyUserImages"
                        label="Show only my Art"
                        checked={showOnlyUserImages}
                        onChange={(event) => {
                          setCurrentPage(1);
                          setShowOnlyUserImages(event.target.checked);
                        }}
                      />
                    </Col>
                    )}
                  </Row>
                  <ListGroup>
                    <CharacterListHeader
                      checked={images.length === selectedImageIds.length && images.length > 0}
                      handleCheck={(checked) => {
                        setSelectedImageIds(checked ? images.map((image) => image.id) : []);
                      }}
                    />
                    {images.map((image) => {
                      const isSelected = selectedImageIds.includes(image.id);
                      const isActive = selectedImage?.id === image.id;

                      return (
                        <CharacterArtLibraryItem
                          key={image.id}
                          user={user}
                          image={image}
                          isLoading={loading}
                          isActive={isActive}
                          onClick={(event) => {
                            if (event.ctrlKey || event.metaKey) {
                              const isExist = selectedImageIds.includes(image.id);
                              setSelectedImageIds((prevIds) => (isExist
                                ? prevIds.filter((el) => el !== image.id)
                                : [...prevIds, image.id]));
                            }
                            setSelectedImage(image);
                          }}
                          isSelected={isSelected}
                          onSelected={() => {
                            setSelectedImageIds((prevIds) => (prevIds.includes(image.id)
                              ? prevIds.filter((id) => id !== image.id)
                              : [...prevIds, image.id]));
                          }}
                          update={loadImages}
                          onDelete={() => {
                            setSelectedImage(image);
                            setShowDeleteModal(true);
                          }}
                          isHideActions={selectedImageIds.length > 0}
                        />
                      );
                    })}
                  </ListGroup>
                  <Pagination {...paginationProps} />
                  <Row>
                    <Col className="text-right">
                      <Button
                        onClick={() => setShowDeleteModal(true)}
                        variant="danger"
                        className="ml-1"
                        disabled={loading}
                        hidden={selectedImageIds.length === 0}
                      >
                        Delete images
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col sm={4} className="text-center">
                  {!!selectedImage && (
                  <div className="stickyImgBox">
                    <h5 className="text-truncate">
                      {selectedImage.title}
                    </h5>
                    <Row float="center">
                      <Col>
                        <img
                          style={{ maxHeight: 500, maxWidth: '100%' }}
                          src={selectedImage.imageUrl ?? ''}
                          alt="Preview"
                        />
                      </Col>
                    </Row>
                    <Row float="center">
                      <Col style={{ padding: '10px' }}>
                        {
                          selectedImage?.alias
                            ? (
                              <Button
                                onClick={handleReplaceImage}
                                variant="secondary"
                                className="mx-1"
                                hidden={selectedImageIds.length > 0}
                                disabled={loading}
                              >
                                Replace image
                              </Button>
                            )
                            : <Alert variant="danger">This image does not have an alias</Alert>
                        }
                      </Col>
                    </Row>
                  </div>
                  )}
                </Col>
              </Row>
              )
            }
          </Card.Body>
        </Card>
      </PageWrapper>

      <DeleteImageModal
        show={showDeleteModal}
        type="character"
        images={imagesTodDelete}
        onHide={() => setShowDeleteModal(false)}
        onDelete={() => {
          setShowDeleteModal(false);
          loadImages();
        }}
      />

      {
        uploadImage === 'single' && (
          <UploadImage
            type="character"
            show
            onHide={cancelUpload}
            update={confirmUpload}
            obj={editImage}
            user={user}
          />
        )
      }
      {
        uploadImage === 'bulk' && (
        <UploadFilesModal
          onClose={cancelUpload}
          onUploaded={confirmUpload}
          accept={acceptExt}
          libraryType={LibraryType.Character}
        />
        )
      }
    </>
  );
}
