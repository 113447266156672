import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import { api } from '../../api';

export class AddComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: null,
      // eslint-disable-next-line react/no-unused-state
      formError: null,
      data: '',
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addComment(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  addComment(value, validated) {
    if (validated === true) {
      // eslint-disable-next-line react/destructuring-assignment
      const url = this.props.type === 'stories' ? `v1/stories/${this.props.obj.id}/comments` : `v1/blog/posts/${this.props.match.params.id}/comments`;
      api.post(url, value)
        .then(() => {
          this.setState({
            validated: null,
            // eslint-disable-next-line react/no-unused-state
            formError: null,
            data: '',
          });
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
        });
    }
  }

  render() {
    const { disabled } = this.props;
    const { validated, data } = this.state;

    return (
      <Card className="my-3 p-3">

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Row>
            <Col>
              <Form.Control
                required
                size="sm"
                as="textarea"
                rows="4"
                name="message"
                placeholder="Add New Comment"
                value={data}
                onChange={(e) => {
                  this.setState({
                    data: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col className="text-right mt-3">
              <Button
                size="sm"
                type="submit"
                variant="primary"
                className="ml-2"
                disabled={disabled}
              >
                Add
              </Button>
            </Col>
          </Row>

        </Form>
      </Card>
    );
  }
}
