import React, { useState } from 'react';
import { BranchAnalytics } from '../BranchAnalytics/BranchAnalytics';
import classNames from './BranchNodeCardWithAnalytics.module.scss';
import { TraitImages } from './TraitImages';

let analyticPosition = { top: 0, left: 0 };

const getAnalyticsCount = (analytics, property) => {
  if (!analytics) {
    return 0;
  }
  switch (property) {
    case 'users':
      return analytics.branch_users ?? 0;
    case 'dropOff':
      if (!analytics.branch_dropoff) {
        return 0;
      }
      if (analytics.branch_dropoff > 100) {
        return 100;
      }
      return analytics.branch_dropoff;
    case 'hearts':
      return analytics.branch_hearts ?? 0;
    default:
      return 0;
  }
};

export function BranchNodeCardWithAnalytics({
  branchNode,
}) {
  const [isShowBranchAnalytics, setIsShowBranchAnalytics] = useState(false);

  const handleMouseEnter = (e) => {
    // The entire canvas is scaled by zoom.
    const zoom = parseFloat(localStorage.getItem('zoom') ?? 1);
    const ancestor = e.currentTarget;
    const rects = ancestor?.getClientRects();
    const top = rects[0].height / (zoom * 3);
    const left = rects[0].width / zoom + 80;

    analyticPosition = { top, left };
    setIsShowBranchAnalytics(true);
  };

  const handleMouseOut = () => setIsShowBranchAnalytics(false);

  const analytics = branchNode.analytics || null;
  const decisionPoint = branchNode.links?.length > 0;

  return (
    <div
      className={classNames.dropOffContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
    >
      <div>
        Users:
        {` ${getAnalyticsCount(analytics, 'users')}`}
      </div>
      <br />
      <div className={classNames.dropOff}>
        <span className="dropOff-span">
          {`${getAnalyticsCount(analytics, 'dropOff')}%`}
        </span>
      </div>
      <div>
        Drop-Off Rate
      </div>
      <br />
      {decisionPoint && (
      <>
        <div>
          Hearts:
          {` ${getAnalyticsCount(analytics, 'hearts')}`}
        </div>
        <TraitImages
          answers={branchNode.answers}
        />
      </>
      )}
      {analytics && isShowBranchAnalytics && (
      <div className={classNames.branchAnalytics} style={analyticPosition}>
        <BranchAnalytics
          steps={analytics.steps}
        />
      </div>
      )}
    </div>
  );
}
