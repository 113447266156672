import async from 'async';
import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddExpression } from './AddExpression';
import './Expressions.css';
import { ExpressionTableRow } from './ExpressionTableRow';

export class Expressions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      editExpressionData: null,
      // eslint-disable-next-line react/no-unused-state
      listExpressionsActive: false,
      addExpressionActive: false,
      // eslint-disable-next-line react/no-unused-state
      editExpressionActive: false,
      loading: true,
      values: [],
      properties: [],
    };

    const {
      history,
    } = this.props;

    this.page = {
      header: {
        title: 'Expressions',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Expression',
            action: () => this.initAddExpression(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      listExpressionsActive: true,
    });
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    async.parallel({
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      properties: (callback) => {
        api.get('/v1/avatar/config/properties')
          .then((res) => {
            callback(null, res.data.propertiess);
          }).catch((error) => {
            callback(error, null);
          });
      },
      values: (callback) => {
        api.get('/v1/avatar/config/values')
          .then((res) => {
            callback(null, res.data.values);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      if (!err) {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          expressions: res.expressions,
          loading: false,
          types: res.expressions,
          // eslint-disable-next-line react/no-unused-state
          listExpressionsActive: true,
          addExpressionActive: false,
          // eslint-disable-next-line react/no-unused-state
          editExpressionActive: false,
          properties: res.properties,
          values: res.values,
        });
      }
    });
  };

  ExpressionsTableRow() {
    const action = this.initAddExpression;
    const updateExpressions = () => {
      this.loadData();
    };
    const { types } = this.state;
    return types.map((object, i) => (
      <ExpressionTableRow
        obj={object}
        /* eslint-disable-next-line react/no-array-index-key */
        key={i}
        actionEditExpression={action}
        update={updateExpressions}
      />
    ));
  }

  initAddExpression = (obj) => {
    let editExpressionData = null;
    if (obj) {
      editExpressionData = obj;
    }
    this.setState({
      editExpressionData,
      addExpressionActive: true,
    });
  };

  cancelAddExpression = () => {
    this.setState({
      editExpressionData: null,
      addExpressionActive: false,
    });
  };

  render() {
    const {
      properties,
      loading,
      editExpressionData,
      addExpressionActive,
      values,
    } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card>
            <Card.Body>
              <Card.Title>List of Character Expressions</Card.Title>

              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Value</th>
                          <th>Default</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.ExpressionsTableRow()}
                      </tbody>
                    </Table>
                  )
              }

            </Card.Body>
          </Card>
        </PageWrapper>

        {addExpressionActive
          ? (
            <AddExpression
              data={editExpressionData}
              properties={properties}
              values={values}
              show={addExpressionActive}
              onHide={this.cancelAddExpression}
              update={this.loadData}
            />
          )
          : null}

      </>
    );
  }
}
