import { logger } from '../../services/loggerService/loggerService';
import { MemoryCheckValueType, MemoryLinkValue, MemoryValue } from '../pages/Book/MemoryBank/memoryBankTypes';

export const isMemorySlotVariableLink = (
  value: MemoryValue | MemoryLinkValue,
): value is MemoryLinkValue => {
  if (!value) {
    return false;
  }
  if (typeof value === 'string') {
    try {
      return isMemorySlotVariableLink(JSON.parse(value));
    } catch (e) {
      return false;
    }
  }
  const memoryLinkValue = value as MemoryLinkValue;
  return memoryLinkValue.type === MemoryCheckValueType.Variable || memoryLinkValue.type === MemoryCheckValueType.VariableArray;
};

export const getMemorySlotValue = (
  value: MemoryValue | MemoryLinkValue,
): MemoryValue => (isMemorySlotVariableLink(value) ? '' : value);

export const getMemorySlotVariableLink = (
  value: MemoryValue | MemoryLinkValue,
): MemoryLinkValue | undefined => (isMemorySlotVariableLink(value) ? value : undefined);

export const parseMemorySlotVariableLink = (value: string): MemoryLinkValue | undefined => {
  if (!value) {
    return undefined;
  }
  try {
    return JSON.parse(value) as MemoryLinkValue;
  } catch (e) {
    logger.error('Error parsing memory slot variable link', e, value);
  }
  return undefined;
};
