import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Modal from 'react-bootstrap/esm/Modal';
import { showToast } from '../../../ui/utils';

interface ShareEmulatorLinkModalProps {
    show: boolean
    onHide: () => void
    linkUrl: string
    storyInternalUuid: string
}

export function ShareEmulatorLinkModal(props: ShareEmulatorLinkModalProps) {
  const {
    show, linkUrl, onHide, storyInternalUuid,
  } = props;

  const handleCopy = () => {
    const htmlInputElement = document.getElementById('linkShare') as HTMLInputElement;
    const clipboardItem = new ClipboardItem({
      'text/plain': new Blob(
        [htmlInputElement.value],
        { type: 'text/plain' },
      ),
    });
    navigator.clipboard.write([clipboardItem])
      .then(() => {
        showToast({ textMessage: 'Link copied to clipboard', variant: 'success' });
      })
      .catch(() => {
        showToast({ textMessage: 'An error occurred while copying' });
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Share emulator link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-3">
          <FormControl
            id="linkShare"
            aria-label="Share"
            defaultValue={`${linkUrl}/play/${storyInternalUuid}`}
          />
          <InputGroup.Append>
            <Button
              variant="dark"
              onClick={handleCopy}
            >
              Copy
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Modal.Body>
    </Modal>
  );
}
