import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Comparator } from 'react-bootstrap-table2-filter';

const legalComparators = [
  Comparator.EQ,
  Comparator.NE,
  Comparator.GT,
  Comparator.GE,
  Comparator.LT,
  Comparator.LE,
];

export class PositiveNumberFilter extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      value: '',
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  filter = () => {
    const { onFilter } = this.props;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const { value } = this.state;

    const filterValue = value;
    const { comparator: comparator1 } = this.props;

    const comparator = comparator1 || Comparator.GE;

    this.timeout = setTimeout(() => {
      onFilter({ comparator, number: filterValue });
    }, 500);
  };

  onChangeNumber = (e) => {
    let { value } = e.target;
    value = value && Math.abs(value) >= 0 ? Math.abs(value) : '';
    this.setState({ value }, this.filter);
  };

  render() {
    const { column, placeholder } = this.props;
    const { value } = this.state;

    const inputElmId = `number-filter-column-${column.dataField}`;

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={(e) => e.stopPropagation()}
        className="filter number-filter"
      >
        <label htmlFor={inputElmId}>
          <span className="sr-only">{`Enter ${column.text}`}</span>
          <input
            id={inputElmId}
            type="number"
            min="0"
            className="number-filter-input form-control"
            placeholder={placeholder || `Enter ${column.text}...`}
            onChange={this.onChangeNumber}
            value={value}
          />
        </label>
      </div>
    );
  }
}

PositiveNumberFilter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  onFilter: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  comparator: PropTypes.oneOf([...legalComparators, '']),
};
