import { Form, useFormikContext } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { usePerformanceRanksContext } from '../../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';
import { usePerformanceRanksModalContext } from '../context/PerformanceRanksModalContext';
import { PerformanceRanksRow } from '../PerformanceRanksRow/PerformanceRanksRow';

export function PerformanceRanksForm() {
  const {
    values, handleSubmit, isSubmitting,
  } = useFormikContext<IPerformanceRankDTO[]>();
  const { isFormLoading } = usePerformanceRanksModalContext();
  const { isLoading } = usePerformanceRanksContext();

  return (
    <Modal.Body>
      <Form onSubmit={handleSubmit}>
        <fieldset disabled={isSubmitting || isFormLoading || isLoading}>
          {values.map((value, index) => (
            <PerformanceRanksRow
              key={value.variable.id}
              index={index}
            />
          ))}
        </fieldset>
      </Form>
    </Modal.Body>
  );
}
