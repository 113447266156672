import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../../assets/images/dorian-white.svg';
import { CopyrightPolicyContent } from './CopyrightPolicyContent';
import './PrivacyPolicyPage.scss';

export class CopyrightPolicyPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="privacy-policy-page">
        <Container className="">
          <div className="logo">
            <Logo />
          </div>
          <h2 className="privacy-policy-page-title">COPYRIGHT POLICY</h2>
          <CopyrightPolicyContent type="external" />
        </Container>
      </div>
    );
  }
}
