import { Formik } from 'formik';
import React, { PropsWithChildren } from 'react';
import { usePerformanceRanksContext } from '../../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { performanceRanksSchema } from './performanceRanksSchema';

export interface PerformanceRanksFormikWrapperProps extends PropsWithChildren {
  initialValues: IPerformanceRankDTO[];
  onSubmit: () => void;
}

export function PerformanceRanksFormikWrapper(props: PerformanceRanksFormikWrapperProps) {
  const { children, onSubmit, initialValues } = props;

  const {
    postPerformanceRanks,
    setIsLoading,
  } = usePerformanceRanksContext();

  const handleSubmit = async (values: IPerformanceRankDTO[]) => {
    setIsLoading(true);

    // Temporary solution to define max value in ranks
    const fixedMaxValues = values.map((value) => {
      const newValue = { ...value };
      const filteredRanks = newValue.ranks.filter((rank) => rank.rank !== 'starMax');
      const maxRankValue = Math.max(...filteredRanks.map((rank) => Number(rank.minRequirement)));
      const newRanks = newValue.ranks.map((rank) => {
        if (rank.rank === 'starMax') {
          return { ...rank, minRequirement: maxRankValue };
        }
        return rank;
      });
      return { ...newValue, ranks: newRanks };
    });

    await postPerformanceRanks(fixedMaxValues)
      .then(() => {
        setIsLoading(false);
        showToast({ textMessage: 'Episode Goals saved', variant: 'success' });
        onSubmit();
      })
      .catch((error) => {
        setIsLoading(false);
        showToast({ textMessage: 'Failed to save Episode Goals' });
        logger.error(error);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={performanceRanksSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
}
