import async from 'async';
import moment from 'moment';
import React, { Component } from 'react';
import {
  Alert, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import Moment from 'react-moment';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';

const dateFormatter = (cell) => (
  <Moment
    element="span"
    format="YYYY-MM-DD hh:mm A"
    date={cell}
  />
);

export class ApprovedFan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approved: [],
      invites: [],
      loading: true,
      error: null,
    };

    const {
      history,
    } = this.props;

    this.page = {
      header: {
        title: 'Author Signups',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Author Signups',
            href: '/fan',
            variant: 'primary',
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  loadData = () => {
    this.setState({
      loading: true,
    });

    async.parallel({
      approved: (callback) => {
        api.get('/v1/invites/approved')
          .then((res) => {
            callback(null, res.data.invites);
          }).catch((error) => {
            this.errorAlert(error.response.data.error);
            callback(error, null);
          });
      },
      users: (callback) => {
        api.get('/v1/users')
          .then((res) => {
            callback(null, res.data.users);
          }).catch((error) => {
            this.errorAlert(error.response.data.error);
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            invites: res.approved,
            approved: this.invitesList(res.approved, res.users),
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  invitesList = (list, users) => {
    const newList = [];
    list.forEach((obj) => {
      const user = users.find((e) => Number(e.id) === Number(obj.userId));
      if (newList.length < 1) {
        newList.push({
          name: obj.userFullName || user.username,
          id: obj.userId,
          invites: [obj],
          email: user.email,
        });
      } else {
        const a = obj.userFullName ? obj.userFullName : obj.userName;
        const b = newList.find((e) => e.name === a);
        if (!b) {
          newList.push({
            name: obj.userFullName || user.username,
            id: obj.userId,
            invites: [obj],
            email: user.email,
          });
        } else {
          b.invites.push(obj);
        }
      }
    });
    return newList;
  };

  render() {
    const columns = [
      {
        dataField: 'firstName',
        sort: false,
        text: 'First Name',
      },
      {
        dataField: 'lastName',
        sort: false,
        text: 'Last Name',
      },
      {
        dataField: 'email',
        sort: false,
        text: 'Email',
      },
      {
        dataField: 'country',
        sort: false,
        text: 'Country',
      },
      {
        dataField: 'phone',
        sort: false,
        text: 'Phone OS',
      },
      {
        dataField: 'social',
        sort: false,
        text: 'Social Media Handle',
      },
      {
        dataField: 'createdAt',
        sort: true,
        text: 'Created Date',
        type: 'date',
        formatter: dateFormatter,
      },
    ];

    const headers = [
      { label: 'id of the user', key: 'userId' },
      { label: 'username of the user', key: 'userFullName' },
      { label: 'email of the user', key: 'userEmail' },
      { label: 'signup first name', key: 'firstName' },
      { label: 'signup last name', key: 'lastName' },
      { label: 'signup email', key: 'email' },
      { label: 'signup phone os', key: 'phone' },
      { label: 'signup country', key: 'country' },
      { label: 'social media handle', key: 'social' },
      { label: 'timestamp was approved', key: 'approvedDate' },
      { label: 'timestamp signup was submitted', key: 'createdAt' },
    ];

    const {
      error,
      loading,
      approved,
      invites,
    } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col />
                <Col className="text-right">
                  {invites
                    && (
                    <CSVLink
                      filename={`Approved-Fan-Signups-${moment()}.csv`}
                      headers={headers}
                      data={invites}
                    >
                      Download CSV
                    </CSVLink>
                    )}
                </Col>
              </Row>
            </Card.Title>

            <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
              <Col md={12}>
                <Alert variant="danger">
                  {error}
                </Alert>
              </Col>
            </Row>

            {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <>
                      {

                      approved.map((obj, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={i} className="py-3">
                          <h5>
                            {obj.name}
                            {' '}
                            (
                            {obj.email || ''}
                            )
                          </h5>
                          <BootstrapTable
                            striped
                            hover
                            size="md"
                            bordered={false}
                            bootstrap4
                            keyField="id"
                            data={obj.invites}
                            columns={columns}
                          />
                        </div>
                      ))
                    }
                    </>
                  )
              }
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
