import React from 'react';
import { Route } from 'react-router-dom';

export function MakeRouteWithSubRoutes(route) {
  const { path, auth, routes } = route;
  return (
    <Route
      path={path}
      render={(props) => (
        <route.component {...props} auth={auth} routes={routes} />
      )}
    />
  );
}
