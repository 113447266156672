import React, { Component } from 'react';
import {
  Button, Col, Form, FormControl, InputGroup,
} from 'react-bootstrap';
import './InputWriting.scss';

export class InputWriting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      item: '',
    };
  }

  addTag = () => {
    const { items, item } = this.state;
    const { update } = this.props;
    const newItems = [...items, item];
    if (item.length > 0) {
      this.setState({
        items: newItems,
        item: '',
      }, () => {
        update(newItems);
      });
    }
  };

  deleteTag(i) {
    const { update } = this.props;
    const { items } = this.state;
    items.splice(i, 1);
    this.setState({
      items,
    }, () => {
      update(items);
    });
  }

  activeTagsList() {
    const { items } = this.state;
    items.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    if (items.length < 1) {
      return null;
    }
    return items.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Form.Check type="checkbox" key={i} className="tagItem">
        <Form.Check.Label>{object}</Form.Check.Label>
        <Button
          variant="secondary"
          size="sm"
          className="close"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
          <Form.Check.Input
            name="tags[]"
            type="checkbox"
            checked
            value={object}
            onChange={() => {
              this.deleteTag(i);
            }}
          />
        </Button>
      </Form.Check>
    ));
  }

  render() {
    const { item } = this.state;
    return (
      <Form.Group
        as={Col}
        md={12}
        className="mt-input"
      >
        <Form.Label>Where can we see your writing?</Form.Label>
        <div className="InputWritingBox">
          {this.activeTagsList()}
          <div>
            <InputGroup>
              <FormControl
                placeholder="add your URL"
                value={item}
                onChange={(e) => {
                  this.setState({
                    item: e.target.value,
                  });
                }}
              />
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  onClick={this.addTag}
                >
                  Add
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
      </Form.Group>
    );
  }
}
