import cs from 'classnames';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FileToUpload, ManageListCallbacks } from '../../../types';
import { getClassNameAndText, getRemoveButtonStateByStatus } from '../uploadFilesItemUtils';
import classNames from './UploadFilesBackgroundItem.module.scss';

export interface UploadFilesBackgroundItemProps {
    fileToUpload: FileToUpload;
    manageListCallbacks?: ManageListCallbacks;
    isLoading?: boolean;
    disabled?: boolean;
}

export function UploadFilesBackgroundItem(props: UploadFilesBackgroundItemProps) {
  const {
    fileToUpload, manageListCallbacks, isLoading, disabled,
  } = props;

  const isRemoveButtonDisabled = isLoading || getRemoveButtonStateByStatus(fileToUpload.status);
  const { statusClassName, statusText } = getClassNameAndText(fileToUpload);

  return (
    <li className={classNames.listItem}>
      <Row>
        <Col md={6} className={cs(classNames.column, classNames.fileName)}>
          <div className={classNames.label}>
            File:
          </div>
          <div className={cs(classNames.text, classNames.fileNameText)}>
            {fileToUpload.file.name}
          </div>
        </Col>
        <Col md={5} className={cs(classNames.column, classNames.status)}>
          <div className={classNames.label}>
            Status:
          </div>
          <div
            className={cs(classNames.text, statusClassName)}
            title={statusText}
          >
            {statusText}
          </div>
        </Col>
        <Col md={1} className={cs(classNames.column, classNames.action)}>
          <Button
            disabled={isRemoveButtonDisabled || disabled}
            size="sm"
            onClick={() => manageListCallbacks?.onDelete?.(fileToUpload)}
          >
            X
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={6} className={cs(classNames.column, classNames.characterName)}>
          <div className={classNames.label}>
            Character:
          </div>
          <div className={cs(classNames.text, classNames.characterNameText)}>
            {fileToUpload.name}
          </div>
        </Col>
        <Col md={6} className={cs(classNames.column, classNames.aliasName)}>
          <div className={classNames.label}>
            Alias:
          </div>
          <div className={cs(classNames.text, classNames.aliasNameText)}>
            {fileToUpload.aliasName}
          </div>
        </Col>
      </Row>
    </li>
  );
}
