import axios from 'axios';
import packageJson from '../../../../package.json';

export async function internalAnalytics({ event, data }, eventGroup = 'mobile_tools') {
  if (!process.env.REACT_APP_ANALYTICS_URL || !event || !data) {
    return;
  }
  // eslint-disable-next-line no-param-reassign
  delete data.description;
  try {
    const params = {
      event,
      data: {
        ...data,
        engine: true,
        sent_at: Date.now(),
        engine_version: packageJson.version,
        event_group: eventGroup,
      },
    };
    axios.get(`${process.env.REACT_APP_ANALYTICS_URL}event`, { params });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`analytics error - event ${event}`);
    // eslint-disable-next-line no-console
    console.error(`data: ${data}`);
  }
}
