import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import imgDeactivate from '../../../assets/images/deactivate.svg';
import imgDelete from '../../../assets/images/delete.svg';
import imgEditStory from '../../../assets/images/edit-story.png';
import imgInfo from '../../../assets/images/info.svg';
import imgMerge from '../../../assets/images/merge.svg';
// eslint-disable-next-line import/no-cycle
import { Deactivate } from './Deactivate';
// eslint-disable-next-line import/no-cycle
import { DeleteUser } from './DeleteUser';
// eslint-disable-next-line import/no-cycle
import { Merge } from './Merge';

export class Actions extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteUserShow: false,
      modalDeactivateShow: false,
      modalMergeShow: false,
    };
  }

  modalDeactivateClose = () => {
    this.setState({ modalDeactivateShow: false });
    const { obj, update } = this.props;
    if (obj) {
      update(obj);
    }
  };

  modalMergeClose = () => {
    this.setState({ modalMergeShow: false });
    const { obj, update } = this.props;
    if (obj) {
      update(obj);
    }
  };

  render() {
    const {
      update, actionUserProfile, obj, actionEditUsers,
    } = this.props;
    const { modalDeactivateShow, modalDeleteUserShow, modalMergeShow } = this.state;

    const modalDeleteUserClose = () => {
      this.setState({ modalDeleteUserShow: false });
      update(obj);
    };

    return (
      <div className="text-right">
        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              User Profile
            </Tooltip>
            )}
        >
          <Button
            onClick={() => actionUserProfile(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgInfo} className="btnImg" alt="User Profile" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              Edit User
            </Tooltip>
            )}
        >
          <Button
            onClick={() => actionEditUsers(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEditStory} className="btnImg" alt="Edit User" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              Deactivate User
            </Tooltip>
            )}
        >
          <Button
            onClick={() => this.setState({ modalDeactivateShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
            disabled={obj?.role === 'deactivated'}
          >
            <img src={imgDeactivate} className="btnImg" alt="Deactivate User" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              Merge With User
            </Tooltip>
            )}
        >
          <Button
            onClick={() => this.setState({ modalMergeShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgMerge} className="btnImg" alt="Merge With User" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              Delete User
            </Tooltip>
            )}
        >
          <Button
            onClick={() => this.setState({ modalDeleteUserShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete User" />
          </Button>
        </OverlayTrigger>

        {modalDeleteUserShow === true && (
          <DeleteUser
            show={modalDeleteUserShow}
            onHide={modalDeleteUserClose}
            obj={obj}
          />
        )}
        {modalDeactivateShow === true && (
          <Deactivate
            show={modalDeactivateShow}
            onHide={this.modalDeactivateClose}
            obj={obj}
          />
        )}
        {modalMergeShow === true && (
          <Merge
            show={modalMergeShow}
            onHide={this.modalMergeClose}
            obj={obj}
          />
        )}

      </div>
    );
  }
}
