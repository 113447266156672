import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class DeleteArticle extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }

  deleteAction() {
    this.setState({ loading: true });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onHide();
    // eslint-disable-next-line react/destructuring-assignment
    api.delete(`v1/blog/posts/${this.props.obj.id}`)
      .then(() => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.update();
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onHide();
      });
  }

  render() {
    const {
      update, staticContext, obj, onHide, ...props
    } = this.props;
    const { loading } = this.state;
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Article:
            <span>
              &quot
              {obj.title}
              &quot
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to delete the Article?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

DeleteArticle.propTypes = {
  onHide: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  obj: PropTypes.object.isRequired,
};
