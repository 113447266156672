import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Book } from '../../../../dorian-shared/types/book/book';
import { DebouncedSearch } from '../../../ui/DebouncedSearch/DebouncedSearch';
import { Pagination } from '../../../ui/Pagination/Pagination';
import { PromptSelectItem } from './PromptSelectItem';

interface PromptSelectProps {
    bookId: number,
    prompts: Book[],
    selectedPromptId: number,
    onPromptSelect: (fixedPreselectedPromptId: number) => void
}

const pageSize = 8;

export function PromptSelect(props: PromptSelectProps) {
  const {
    bookId, prompts, selectedPromptId, onPromptSelect,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const filteredTemplates = prompts.filter((prompt) => {
    if (!prompt.title) {
      return false;
    }
    return prompt.title.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const offset = pageSize * (currentPage - 1);
  const paginatedFilteredTemplates = filteredTemplates.slice(offset, offset + pageSize);

  const paginationProps = {
    onPageChange: (newPage: number) => setCurrentPage(newPage),
    totalCount: filteredTemplates.length,
    currentPage,
    pageSize,
  };

  return (
    <>
      <DebouncedSearch
        initialValue={searchTerm}
        onSearchSubmit={(newSearch: string) => setSearchTerm(newSearch)}
      />
      <Pagination {...paginationProps} />
      <Row>
        {filteredTemplates.length > 0
          ? paginatedFilteredTemplates.map(
            (filteredTemplate) => (
              <PromptSelectItem
                key={filteredTemplate.id}
                book={filteredTemplate}
                bookId={bookId}
                selectedPromptId={selectedPromptId}
                update={onPromptSelect}
              />
            ),
          )
          : (
            <Col className="text-center">
              <h4>No prompts found</h4>
            </Col>
          )}
      </Row>
      <Pagination {...paginationProps} />
    </>
  );
}
