import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddStatType } from './AddStatType';
import './StatTypes.css';
import { StatTypesTableRow } from './StatTypesTableRow';

export class StatTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      editStatTypeData: null,
      addStatTypeActive: false,
      loading: true,
    };

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Traits',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Stat Type',
            action: () => this.initAddStatType(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadStatTypesList();
  }

  loadStatTypesList = () => {
    api.get('/v1/stats/types')
      .then((res) => {
        this.setState({
          types: res.data.types,
          addStatTypeActive: false,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  statTypesTableRow() {
    const { types } = this.state;

    const action = this.initAddStatType;
    const updateStatTypes = () => {
      this.loadStatTypesList();
    };
    return types.map((object, i) => (
      <StatTypesTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        obj={object}
        actionEditStatType={action}
        update={updateStatTypes}
      />
    ));
  }

  initAddStatType = (obj) => {
    let editStatTypeData = null;
    if (obj) {
      editStatTypeData = obj;
    }
    this.setState({
      editStatTypeData,
      addStatTypeActive: true,
    });
  };

  cancelAddStatType = () => {
    this.setState({
      editStatTypeData: null,
      addStatTypeActive: false,
    });
  };

  render() {
    const { addStatTypeActive, loading, editStatTypeData } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card>
            <Card.Body>
              <Card.Title>List of Traits</Card.Title>
              {loading ? (
                <div className="text-center">
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="loadingSpinner justify-content-center"
                  />
                </div>
              ) : (
                <Table striped hover size="md">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Value</th>
                      <th>Trait</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.statTypesTableRow()}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </PageWrapper>

        {addStatTypeActive && (
        <AddStatType
          data={editStatTypeData}
          show={addStatTypeActive}
          onHide={this.cancelAddStatType}
          update={this.loadStatTypesList}
        />
        )}
      </>
    );
  }
}
