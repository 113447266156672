import { getBoundsForNode } from './getBoundsForNode';

function areBoundsCollide(a, b, _a) {
  const aTolerance = _a.tolerance;
  const tolerance = aTolerance === undefined ? 0 : aTolerance;
  const cUseOffsetSize = _a.useOffsetSize;
  const useOffsetSize = cUseOffsetSize === undefined ? false : cUseOffsetSize;

  const aHeight = useOffsetSize ? a.offsetHeight : a.height;
  const bHeight = useOffsetSize ? b.offsetHeight : b.height;
  const aWidth = useOffsetSize ? a.offsetWidth : a.width;
  const bWidth = useOffsetSize ? b.offsetWidth : b.width;
  return !(a.top + aHeight - tolerance < b.top
    || a.top + tolerance > b.top + bHeight
    || a.left + aWidth - tolerance < b.left
    || a.left + tolerance > b.left + bWidth);
}

export function doObjectsCollide(a, b, tolerance, delta) {
  if (tolerance === undefined) {
    // eslint-disable-next-line no-param-reassign
    tolerance = 0;
  }
  if (delta === undefined) {
    // eslint-disable-next-line no-param-reassign
    delta = 1;
  }
  const aObj = a instanceof HTMLElement ? getBoundsForNode(a) : a;
  const bObj = b instanceof HTMLElement ? getBoundsForNode(b) : b;
  return areBoundsCollide(aObj, bObj, { tolerance, useOffsetSize: delta === 1 });
}
