import React from 'react';

export function DefaultFallback({ error }: { error: Error }) {
  const { message } = error;
  return (
    <>
      <h3>
        Sorry, an unexpected error has occurred.
      </h3>
      <p>{message}</p>
    </>
  );
}
