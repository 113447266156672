import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styles from './Panel.css';

const cs = classNames.bind(styles);

export class Panel extends Component {
  escFunction = (event) => {
    const { onHide } = this.props;

    if (event.keyCode === 27) {
      onHide();
    }
  };

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, { passive: true });
  }

  render() {
    const {
      show, pageTitle, onHide, children, loading,
    } = this.props;

    return (
      <>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={cs('validatePanelBg', show ? 'active' : null)}
          onClick={() => { onHide(); }}
        />

        <div className={cs('validatePanelBox', show ? 'active' : null)}>
          <div className="validatePanelHeader">
            <h2>{pageTitle}</h2>
            <Button
              className="closeBtn"
              variant="secondary"
              onClick={() => {
                onHide();
              }}
            >
              X
            </Button>
          </div>

          {show ? children : null}

          <Spinner
            animation="border"
            variant="primary"
            className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
          />

        </div>
      </>
    );
  }
}
