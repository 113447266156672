import React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import Moment from 'react-moment';
import imgEditStory from '../../../assets/images/edit-story.png';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { MailTemplatesEdit } from './MailTemplatesEdit';
import './style.scss';

export function MailTemplates(props) {
  const { auth } = props;

  const user = auth.getUser();
  const isAdmin = user.role === 'admin';

  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [edit, setEdit] = React.useState(null);

  const page = {
    header: {
      title: 'Mail Templates',
      settings: isAdmin ? 'admin' : null,
    },
    sidebar: {
      nav: [
        {
          title: 'Back to Stories List',
          action: '',
          href: '/books',
          variant: 'secondary',
          disabled: false,
        },
      ],
    },
  };

  const loadData = () => {
    if (!loading) {
      setLoading(true);
      api.get('/v1/mailtemplates')
        .then((res) => {
          setTemplates(res && res.data && res.data.templates ? res.data.templates : []);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (isAdmin) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <PageWrapper
        {...props}
        page={page}
      >
        {!loading && templates.map((template, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Card key={i} className="my-3">
            <Card.Title className="px-3 pt-3">
              <Row>
                <Col md={10} className="articles-box">
                  <h3>
                    {template.title}
                  </h3>

                  <div className="text-content">
                    {template.body}
                    {template.body && <>...</>}
                  </div>
                </Col>
                <Col className="justify-content-end text-right">
                  <span className="tooltip-box">
                    <Button
                      variant="secondary"
                      size="sm"
                      className="mx-1"
                      onClick={() => setEdit(template)}
                    >
                      <img src={imgEditStory} className="btnImg" alt="Edit" />
                    </Button>
                    <div className="tooltip">
                      <div className="arrow" />
                      <div className="tooltip-inner">Edit</div>
                    </div>
                  </span>
                </Col>
              </Row>
            </Card.Title>
            <Card.Footer>
              <Row>
                <Col />
                <Col className="justify-content-end text-right">
                  <Moment
                    element="span"
                    format="YYYY-MM-DD hh:mm A"
                    date={template.createdAt}
                  />
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        ))}
      </PageWrapper>

      {!!edit && (
        <MailTemplatesEdit
          show
          user={user}
          onHide={() => setEdit(null)}
          obj={edit}
          update={() => {
            setEdit(null);
            loadData();
          }}
        />
      )}
    </>
  );
}
