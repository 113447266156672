import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import './BookCover.css';

export class BookPush extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      users: [],
      confirmPush: false,
      confirmPushLoading: false,
      formData: [],
      error_users: [],
      allChecked: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;
    if (show && prevProps.show !== show) {
      this.loadUsersList();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: false });
    if (validated === false || !obj.users) {
      event.stopPropagation();
    } else {
      this.setState({
        confirmPush: true,
        formData: obj,
        error_users: [],
      });
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  loadUsersList = () => {
    this.setState({
      loading: true,
      error_users: [],
    });
    api.get('/v1/users')
      .then((res) => {
        const { auth } = this.props;
        const user = auth.getUser();

        const users = res.data.users.filter((usr) => usr.id !== user.id);

        users.forEach((item, i) => {
          // eslint-disable-next-line no-param-reassign
          item.isChecked = false;
          // eslint-disable-next-line no-param-reassign
          item.name = `users[${i}]`;
        });
        this.setState({
          users,
          loading: false,
        });
      })
      .catch((error) => {
        this.errorAlert(!error.response || !error.response.data || !error.response.data.error ? 'Unknown error' : error.response.data.error);
        this.setState({
          loading: false,
        });
      });
  };

  createData = () => {
    this.setState({
      confirmPushLoading: true,
      error_users: [],
    });
    const {
      show,
      update,
    } = this.props;

    const {
      users: users1,
      formData,
    } = this.state;

    api.post(`/v1/books/${show.id}/push`, formData)
      .then((res) => {
        const errorUsers = res.data.error_users.filter((el) => el !== null);
        const users = [];
        users.push(...errorUsers.map((el) => users1.find((obj) => String(obj.id) === String(el))));
        this.setState({
          loading: false,
          confirmPush: false,
          confirmPushLoading: false,
          error_users: users,
        }, () => {
          setTimeout(() => {
            update();
          }, users.length > 0 ? 2500 : 0);
        });
      })
      .catch((error) => {
        this.errorAlert(!error.response || !error.response.data || !error.response.data.error ? 'Unknown error' : error.response.data.error);
        this.setState({
          loading: false,
          confirmPush: false,
          confirmPushLoading: false,
        });
      });
  };

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  handleChange = (e) => {
    const { users } = this.state;
    let { allChecked } = this.state;
    if (e.target.value === 'checkAll') {
      users.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.isChecked = e.target.checked;
        allChecked = e.target.checked;
      });
    } else {
      users.find((item) => item.name === e.target.name).isChecked = e.target.checked;
    }
    this.setState({ users, allChecked });
  };

  render() {
    const {
      show,
      onHide,
    } = this.props;

    if (!show) {
      return null;
    }

    const { validated } = this.state;

    const {
      users,
      loading,
      error,
      confirmPush,
      error_users: errorUsers,
      confirmPushLoading,
      allChecked,
    } = this.state;

    return (
      <>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          show={!!show}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Push Book:
                {' '}
                {show.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {errorUsers.length < 1
                ? (
                  <div className="BookPushContentBox">
                    {
                    loading
                      ? (
                        <div className="text-center loadingSpinner">
                          <Spinner
                            variant="primary"
                            animation="border"
                            className="justify-content-center"
                          />
                        </div>
                      )
                      : null
                  }
                    <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
                      <Col md={12}>
                        <Alert variant="danger">
                          {error}
                        </Alert>
                      </Col>
                    </Row>

                    <Row className="py-2 border-bottom d-flex align-items-center">
                      <Col md={12} className="text-right">
                        <Form.Check
                          type="checkbox"
                          label="Check all"
                          value="checkAll"
                          checked={allChecked}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>

                    {
                    users && users.map((obj, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Row key={i} className="py-2 border-bottom d-flex align-items-center">
                        <Col md={2} className="text-center">
                          <Form.Check
                            type="checkbox"
                            id={`checkbox_${i}`}
                            name={`users[${i}]`}
                            value={obj.id}
                            checked={obj.isChecked}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col md={10} className="text-left">
                          <label htmlFor={`checkbox_${i}`}>{obj.username || obj.fullname}</label>
                        </Col>
                      </Row>
                    ))
                  }
                  </div>
                )
                : (
                  <Alert variant="danger">
                    this book cannot be pushed for user(s):
                    <span className="BookPush-err-names">
                      {
                  errorUsers.map((obj) => (
                    // eslint-disable-next-line react/jsx-key
                    <b>
                      {obj.username || obj.fullname}
                    </b>
                  ))
                }
                    </span>
                  </Alert>
                )}
            </Modal.Body>
            {(errorUsers.length < 1)
            && (
            <Modal.Footer>
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                disabled={loading}
                onClick={onHide}
              >
                Cancel
              </Button>

              <Button
                size="sm"
                type="submit"
                variant="primary"
                className="mx-1"
                disabled={loading}
              >
                Create
              </Button>
            </Modal.Footer>
            )}
          </Form>
        </Modal>

        <Modal
          size="sm"
          centered
          backdrop="static"
          keyboard={false}
          className="deleteModal"
          show={!!confirmPush}
        >
          {!confirmPushLoading
          && (
          <Modal.Header closeButton>
            <Modal.Title>
              Confirm Book Push
            </Modal.Title>
          </Modal.Header>
          )}
          <Modal.Body>
            {
              confirmPushLoading
                ? (
                  <div className="text-center">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="justify-content-center"
                    />
                  </div>
                )
                : <p>You confirm the book push?</p>
            }
          </Modal.Body>
          {!confirmPushLoading
          && (
          <Modal.Footer>
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              onClick={
                () => {
                  this.setState({
                    confirmPush: false,
                  });
                }
              }
            >
              Cancel
            </Button>

            <Button
              size="sm"
              type="submit"
              variant="primary"
              className="mx-1"
              onClick={this.createData}
            >
              Confirm
            </Button>
          </Modal.Footer>
          )}
        </Modal>
      </>
    );
  }
}
