import moment from 'moment';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ReactComponent as IconYes } from '../../../../assets/images/yes.svg';
import { api } from '../../../api';
import { PageWrapper } from '../../../ui/PageWrapper';
import '../style.scss';

export class EpisodeDailyReads extends Component {
  constructor(props) {
    super(props);
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - 90);
    this.state = {
      loading: true,
      data: [],
      story: {},
      csvData: [],
      startDate,
    };
    this.sortConfig = {};
  }

  componentDidMount() {
    const { match } = this.props;

    this.story_id = match.params.story_id;
    this.loadData();
  }

  addIdx = (arr) => arr.map((val, i) => {
    // eslint-disable-next-line no-param-reassign
    val.idx = i;
    return val;
  });

  setStartDate = (date) => {
    this.setState({ startDate: date }, this.loadData);
  };

  loadData = () => {
    this.setState({
      loading: true,
    });
    const params = {};

    const { startDate } = this.state;

    if (startDate) {
      // eslint-disable-next-line max-len
      params.start = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
    }

    api.get(`/v1/analytics/performance/episodedailyreads/${this.story_id}`, { params })
      .then((res) => {
        const data = res.data.data ? this.addIdx(res.data.data) : [];

        this.setState({
          data,
          story: res.data.story || {},
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  // eslint-disable-next-line react/sort-comp
  renderLoading() {
    return (
      <div className="text-center">
        <Spinner
          variant="primary"
          animation="border"
          className="loadingSpinner justify-content-center"
        />
      </div>
    );
  }

  // CVS
  prepareCSVData = (ev, done) => {
    const data = [];
    const { data: data1 } = this.state;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const i in data1) {
      const elem = { ...data1[i] };
      data.push(elem);
    }
    this.setState({ csvData: data }, done);
  };
  // CSV

  sortFixValue = (field, val) => {
    if (field === 'owner') {
      return val.name;
    }
    if (field === 'co-author') {
      return val.map((e) => e.name).join(' ');
    }
    return val;
  };

  comparator = (a, b) => {
    let result;
    if (typeof b === 'string') {
      result = b.localeCompare(a);
    } else {
      // eslint-disable-next-line no-nested-ternary
      result = a > b ? -1 : a < b ? 1 : 0;
    }
    return result;
  };

  sortFunc = (a, b) => {
    let result = 0;
    if (typeof a === 'object' && typeof b === 'object') {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in this.sortConfig) {
        result = this.sortConfig[key] === 'desc'
          ? this.comparator(this.sortFixValue(key, a[key]), this.sortFixValue(key, b[key]))
          : this.comparator(this.sortFixValue(key, b[key]), this.sortFixValue(key, a[key]));
        if (result !== 0) {
          break;
        }
      }
      return result;
    }

    // result = (order === 'asc')? this.comparator(a, b):this.comparator(b, a);
    return result;
  };

  onSort = (field) => {
    if (!(field in this.sortConfig)) {
      this.sortConfig[field] = 'asc';
    } else if (this.sortConfig[field] === 'asc') {
      this.sortConfig[field] = 'desc';
    } else {
      delete this.sortConfig[field];
    }
  };

  sortValue = (cell, row) => (Object.keys(this.sortConfig).length > 0 ? row : cell);

  markFormatter = (cell) => (parseInt(cell, 10) > 0 ? <IconYes className="icon-yes" /> : '');

  renderData() {
    const {
      loading,
      csvData,
      data,
    } = this.state;
    if (loading) {
      return this.renderLoading();
    }

    const columns = [{
      dataField: 'day',
      text: 'Date',
      sort: true,
    }, {
      dataField: 'reads',
      text: 'Reads',
      sort: true,
    }, {
      dataField: 'first',
      text: 'New Episodes',
      sort: true,
      formatter: this.markFormatter,
    }, {
      dataField: 'released',
      text: 'Episode Updated',
      sort: true,
      formatter: this.markFormatter,
    }, {
      dataField: 'cover',
      text: 'Cover Updated',
      sort: true,
      formatter: this.markFormatter,
    }];

    // eslint-disable-next-line no-restricted-syntax
    for (const i in columns) {
      if (columns[i].sort === true) {
        columns[i].onSort = this.onSort;
        columns[i].sortValue = this.sortValue;
      }
    }
    if (data.length < 1) {
      return (<h4 className="py-4 px-2">No data</h4>);
    }
    const headers = [
      { label: 'Date', key: 'day' },
      { label: 'Reads', key: 'reads' },
      { label: 'New Episodes', key: 'first' },
      { label: 'Episode Updated', key: 'released' },
      { label: 'Cover Updated', key: 'cover' },
      /* { label: "Delta", key: "delta" } */
    ];

    const sortOption = {
      // No need to configure sortFunc per column
      sortFunc: this.sortFunc,
      // No need to configure sortCaret per column
      // eslint-disable-next-line react/no-unstable-nested-components
      sortCaret: (order, column) => {
        const index = Object.keys(this.sortConfig).indexOf(column.dataField);
        // eslint-disable-next-line no-param-reassign
        order = null;
        if (index !== -1) {
          // eslint-disable-next-line no-param-reassign
          order = this.sortConfig[column.dataField];
        }
        if (!order) return (<span className="order-4" />);
        if (order === 'asc') {
          return (
            <>
              <span className="caret-4-asc" />
              <sup>{index !== -1 ? index + 1 : ''}</sup>
            </>
          );
        }
        if (order === 'desc') {
          return (
            <>
              <span className="caret-4-desc" />
              <sup>{index !== -1 ? index + 1 : ''}</sup>
            </>
          );
        }
        return null;
      },
    };

    return (
      <>
        {data
        && (
        <div className="text-right p-2">
          <CSVLink
            filename={`performance-choices-${moment()}.csv`}
            headers={headers}
            data={csvData}
            asyncOnClick
            onClick={this.prepareCSVData}
          >
            Download CSV
          </CSVLink>
        </div>
        )}
        <BootstrapTable
          bootstrap4
          keyField="idx"
          data={data}
          columns={columns}
          pagination={paginationFactory()}
          sort={sortOption}
          filterPosition="top"
        />
      </>
    );
  }

  render() {
    const {
      startDate,
      story,
    } = this.state;

    const { history } = this.props;

    const page = {
      header: {
        title: `Episode Daily Reads: ${story.title}`,
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };

    return (
      <PageWrapper
        {...this.props}
        page={page}
      >
        <DatePicker selected={startDate} onChange={(date) => this.setStartDate(date)} maxDate={new Date()} dateFormat="yyyy-MM-dd" />
        {this.renderData()}
      </PageWrapper>
    );
  }
}
