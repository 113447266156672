import React, { Component } from 'react';
import {
  Button, Col, Form, InputGroup, Spinner,
} from 'react-bootstrap';

export class CreateNodeButton extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      addBranchNumber: 1,
    };
  }

  render() {
    const {
      action,
      modeEdit,
      title,
      addBranchActive,
    } = this.props;
    const { addBranchNumber } = this.state;

    return (
      <Form.Row className="pt-3">
        <Form.Group as={Col} xs={8}>
          <Button
            block
            variant="primary"
            onClick={() => {
              action(addBranchNumber);
            }}
            disabled={(addBranchActive || modeEdit)}
          >
            {addBranchActive && (
              <Spinner
                variant="primary"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {title}
          </Button>
        </Form.Group>

        <Form.Group as={Col} xs={4}>
          <InputGroup className="addBranchNumberBox">
            <Form.Control
              type="text"
              pattern="^[0-9]+$"
              name="addBranchNumber"
              value={addBranchNumber || ''}
              disabled={(addBranchActive || modeEdit)}
              onChange={(e) => {
                this.setState({
                  addBranchNumber: e.target.value >= 1 && e.target.value <= 10 ? Number(Math.round(e.target.value)) : '',
                });
              }}
            />

            <InputGroup.Append>
              <Button
                className="numInpBtn position-absolute"
                disabled={(addBranchNumber < 2)}
                variant="primary"
                onClick={() => {
                  this.setState({
                    addBranchNumber: addBranchNumber <= 1 ? 1 : Number(addBranchNumber) - 1,
                  });
                }}
              >
                &#9660;
              </Button>
              <Button
                className="numInpBtn position-absolute"
                variant="primary"
                disabled={(addBranchNumber > 9)}
                onClick={() => {
                  this.setState({
                    addBranchNumber: Number(addBranchNumber) + 1,
                  });
                }}
              >
                &#9650;
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    );
  }
}
