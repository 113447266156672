import classNames from 'classnames/bind';
import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { CustomSelect } from '../../CustomSelect';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export function AnswerGotoBranch(props) {
  const {
    answerIndex,
    disabled,
    gotoBranchId,
    currentBranch,
    activeStepsFunc,
    handleChange,
    stepIndex,
    xl,
    sm,
    branches,
    lg,
  } = props;

  return (
    <Form.Group
      sm={sm || 2}
      lg={lg || 2}
      xl={xl || 2}
      as={Col}
      controlId={`answerGotoBranchI${answerIndex}`}
      className={cs('mb-0')}
    >
      <Row>
        <Form.Label
          className={cs('text-right px-1')}
          column
          sm={6}
          xl={5}
        >
          Goto node
        </Form.Label>
        <Col className={cs('mb-0 px-1')}>
          <CustomSelect
            disabled={disabled}
            name={`steps[${stepIndex}][answers][${answerIndex}][gotoBranchId]`}
            value={gotoBranchId || ''}
            onFocus={() => {
              activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-gotoBranchId`);
            }}
            onChange={(a) => {
              const e = { target: { value: a } };
              handleChange(e.target.value);
            }}
            args={[
              { id: null, title: '' },
              { id: -1, title: '[Create New Node]' },
              ...branches,
            ]}
            data={currentBranch}
          />
        </Col>
      </Row>
    </Form.Group>
  );
}
