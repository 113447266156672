// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import { round } from 'lodash-es';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { Cropper } from 'react-cropper';
import { uuid } from 'short-uuid';
import dialogPreview from '../../../assets/images/preview/dialog-preview.png';
import { api } from '../../api';
import './UploadImage.scss';

export const IMAGE_MIN_WIDTH = 400;
export const IMAGE_MIN_HEIGHT = 400;
export const IMAGE_WIDTH_UPLOAD = 843;
export const IMAGE_HEIGHT_UPLOAD = 1200;
export const IMAGE_ASPECT_RATIO = IMAGE_WIDTH_UPLOAD / IMAGE_HEIGHT_UPLOAD;
export const IMAGE_CROP_MIN_HEIGHT = 100;
export const IMAGE_CROP_MIN_WIDTH = 100;

function RenderSpinner({ isVisible }) {
  if (!isVisible) return null;
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  );
}

/**
 * Shows error message on submit form
 * @errorMessage string. If null - don't show error message
 */
function RenderAlert({ errorMessage }) {
  if (errorMessage === null) return null;
  return (
    <Col md={12}>
      <Alert variant="danger">
        {errorMessage}
      </Alert>
    </Col>
  );
}

function RenderAliasField({ show, defaultLabelName, defaultAliasValue }) {
  if (!show) return null;
  return (
    <Form.Group as={Col} md="4" controlId="alias">
      <Form.Label>
        {defaultLabelName}
        {' '}
        alias
      </Form.Label>
      <Form.Control
        size="sm"
        type="text"
        required
        disabled={defaultAliasValue}
        defaultValue={defaultAliasValue ?? uuid()}
        placeholder="Alias"
        name="alias"
        pattern="^([A-Za-z]|[0-9]|_|-|)+$"
        title={defaultAliasValue}
      />
      <Form.Control.Feedback type="invalid">
        Please choose an Alias.
      </Form.Control.Feedback>
    </Form.Group>
  );
}

function RenderNameField({
  show, defaultLabelName, defaultLabelValue, name,
}) {
  if (!show) return null;
  return (
    <Form.Group as={Col} md="4" controlId="label">
      <Form.Label>
        {defaultLabelName}
        {' '}
        label
      </Form.Label>
      <Form.Control
        size="sm"
        type="text"
        required
        disabled={defaultLabelValue}
        defaultValue={defaultLabelValue}
        placeholder={defaultLabelName}
        name={name}
        ref={(input) => input && input.focus()}
      />
    </Form.Group>
  );
}

function RenderFileField({ show, acceptFiles, onChangeCallback }) {
  if (!show) return null;
  return (
    <Form.Group as={Col} md="4" controlId="image">
      <Form.Label>Image File</Form.Label>
      <Form.Control
        required
        type="file"
        placeholder="Image"
        name="image"
        accept={acceptFiles}
        onChange={(e) => onChangeCallback(e)}
      />
      <Form.Control.Feedback type="invalid">
        Please choose an Image.
      </Form.Control.Feedback>
    </Form.Group>
  );
}

const getCropperParams = (cropper) => {
  const cropBoxData = cropper.getCropBoxData();
  const canvasData = cropper.getCanvasData();

  const canvasRatio = cropBoxData.height / cropBoxData.width;

  const minOriginalWidth = canvasData.naturalHeight / canvasRatio;
  const minOriginalHeight = canvasData.naturalHeight / canvasRatio;

  const minScaleByWidth = cropBoxData.width / minOriginalWidth;
  const minScaleByHeigh = cropBoxData.height / minOriginalHeight;

  const minScale = Math.min(minScaleByWidth, minScaleByHeigh);
  const currentScale = canvasData.width / canvasData.naturalWidth;

  return {
    currentScale: round(currentScale, 6),
    minScale: round(minScale, 6),
  };
};

export const getCanvasBlob = (canvas, type, quality = 1) => new Promise((resolve, reject) => {
  canvas.toBlob((blob) => {
    if (blob) resolve(blob);
    else reject(new Error('Error: Blob is null'));
  }, type, quality);
});

export class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      validated: false,
      formError: null,
      cropper: null,
      scaleValue: 0.25,
      isCharacter: props?.type === 'character',
    };

    this.imageFile = null;
    this.imageFileSrc = null;
    this.cropScaleRangeMin = 0.01;
    this.cropScaleRangeMax = 1;
    this.cropScaleStep = 0.0025;
    this.cropSavePosition = '';
    this.cropInitailRatio = 1;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      this.setState({ validated: true });
      return;
    }

    this.setState({ loading: true });

    const {
      obj,
      user,
      update,
      type,
    } = this.props;

    const label = event.target.elements.label.value;

    const formData = new FormData();

    formData.append(type === 'character' ? 'title' : 'label', label);
    formData.append('disabled', false);
    formData.append('authorId', user.id);
    formData.append('policyId', user.role === 'admin' ? 1 : 2);

    if (obj && obj.alias) {
      const aliasFromForm = event.target.elements.alias?.value;
      const alias = aliasFromForm ?? obj.alias;
      formData.append('alias', alias);
      formData.append('replace', true);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    try {
      const image = await this.getCroppedFile();
      formData.append('image', image);
      await api.post(type === 'character'
        ? '/v1/customcharacters'
        : '/v1/backgrounds', formData, config);
    } catch (error) {
      const errorMessage = error?.response?.data
        ? error?.response?.data.error
        : 'Error: Can\'t send image to server';
      this.errorAlert(errorMessage);
      this.setState({ loading: false });
      return;
    }
    this.setState({ loading: false });
    this.imageFile = null;
    this.imageFileSrc = null;
    update();
  };

  onChangeFile(e) {
    const file = e.target.files[0];

    if (!file) {
      this.errorAlert('File not selected');
      return;
    }

    let validFileType = 'image/jpeg';

    const { type } = this.props;
    if (type === 'character') {
      validFileType = 'image/png';
    }
    if (file.type.indexOf(validFileType) === -1) {
      this.errorAlert('File type not supported');
      return;
    }

    const reader = new FileReader();
    this.setState({ loading: true });
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width < IMAGE_MIN_WIDTH) {
          this.errorAlert(`Image width must be bigger than ${IMAGE_MIN_WIDTH} pixels`);
          this.setState({ loading: false });
          return;
        }

        if (img.height < IMAGE_MIN_HEIGHT) {
          this.errorAlert(`Image height must be bigger than ${IMAGE_MIN_HEIGHT} pixels`);
          this.setState({ loading: false });
          return;
        }
        this.imageFile = file;
        this.imageFileSrc = img.src;
        this.setState({ loading: false });
      };
      img.src = reader.result.toString();
    };
    reader.readAsDataURL(file);
  }

  getCroppedFile = async () => {
    const { type } = this.props;

    const { cropper } = this.state;

    if (cropper === null || !cropper.getCroppedCanvas()
    ) {
      this.errorAlert('Error: Can\'t get crop data');
      return null;
    }
    const canvas = await cropper.getCroppedCanvas(
      {
        width: IMAGE_WIDTH_UPLOAD,
        height: IMAGE_HEIGHT_UPLOAD,
        imageSmoothingQuality: 'high',
        imageSmoothingEnabled: true,
      },
    );
    const fileType = type === 'character' ? 'image/png' : 'image/jpeg';
    let blob = new Blob();
    try {
      blob = await getCanvasBlob(canvas, fileType, 1);
    } catch (error) {
      this.errorAlert(error);
    }

    return new File([blob], this.imageFile.name);
  };

  setScaleValue() {
    const { cropper, isCharacter } = this.state;
    const params = getCropperParams(cropper);
    if (!isCharacter) {
      this.cropScaleRangeMin = params.minScale;
    }
    this.cropScaleRangeMax = this.cropInitailRatio * 3;
    this.cropScaleStep = (this.cropScaleRangeMax - this.cropScaleRangeMin) / 100;

    this.setState({ scaleValue: params.currentScale });
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
      loading: false,
    });

    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  renderPreview = () => {
    const cropReadyHandler = () => {
      const { cropper } = this.state;
      const param = getCropperParams(cropper);
      this.cropInitailRatio = param.currentScale;
      this.setScaleValue();
    };

    const cropEndHandler = () => {
      const { isCharacter, cropper } = this.state;
      const moveOut = this.chackMoveOutCropImage(cropper, isCharacter);
      if (moveOut.result) {
        cropper.setCanvasData(moveOut.canvasData);
        this.setScaleValue();
      }
    };

    const cropZoomHandler = (event) => {
      const { isCharacter, cropper } = this.state;
      const cropParams = getCropperParams(cropper);

      if (cropParams.minScale > event.detail.ratio && !isCharacter) {
        event.preventDefault();
      }
      this.setScaleValue();
    };

    const getViewMode = () => {
      const { isCharacter } = this.state;
      return isCharacter ? 0 : 1;
    };

    return (
      <>
        <Col sm={6} className="cropper-source">
          <Col className="cropper-source-area">
            <Cropper
              style={{ height: '100%', maxWidth: '320px' }}
              viewMode={getViewMode()}
              zoomTo={0}
              dragMode="move"
              aspectRatio={IMAGE_ASPECT_RATIO}
              minCanvasHeight={IMAGE_CROP_MIN_HEIGHT}
              minCropBoxHeight={260}
              minCropBoxWidth={280}
              autoCropArea={1}
              restore
              modal
              guides
              zoomOnWheel={false}
              center={false}
              highlight
              cropBoxMovable={false}
              cropBoxResizable={false}
              toggleDragModeOnDblclick={false}
              initialAspectRatio={IMAGE_ASPECT_RATIO}
              preview=".img-preview"
              src={this.imageFileSrc}
              background
              wheelZoomRatio={0.05}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => { this.setState({ cropper: instance }); }}
              ready={cropReadyHandler}
              cropend={cropEndHandler}
              zoom={cropZoomHandler}
            />
          </Col>
        </Col>
        <Col sm={6} className="cropper-preview">
          <div className="cropper-preview-left">
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <div className={`preview-area-phone-left${this.state.isCharacter ? ' character' : ''}`}>
              <div className="preview-area-phone-wrapper">
                {/* eslint-disable-next-line react/destructuring-assignment */}
                <div className={`img-preview${this.state.isCharacter ? ' img-character-left' : ''}`}>
                  {/* Cropper preview area */}
                </div>
              </div>
              <div className="preview-area-phone-dialog">
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {this.state.isCharacter && this.imageFile
                  ? <img className="img-dialog" src={dialogPreview} alt="dialog" />
                  : null}
              </div>
            </div>
            <div className="preview-area-phone-image" />
          </div>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {!this.state.isCharacter
            ? null
            : (
              <div className="cropper-preview-right">
                {/* eslint-disable-next-line react/destructuring-assignment */}
                <div className={`preview-area-phone-right${this.state.isCharacter ? ' character' : ''}`}>
                  <div className="preview-area-phone-wrapper">
                    {/* eslint-disable-next-line react/destructuring-assignment */}
                    <div className={`img-preview${this.state.isCharacter ? ' img-character-right' : ''}`} />
                  </div>
                  <div className="preview-area-phone-dialog">
                    {/* eslint-disable-next-line react/destructuring-assignment */}
                    {this.state.isCharacter && this.imageFile
                      ? <img className="img-dialog" src={dialogPreview} alt="dialog" />
                      : null}
                  </div>
                </div>
                <div className="preview-area-phone-image" />
              </div>
            )}
        </Col>
      </>
    );
  };

  RenderUploadButtons = () => (
    <>
      <Button
        className="btn"
        /* eslint-disable-next-line react/destructuring-assignment */
        disabled={this.state.loading}
        type="reset"
        variant="secondary"
        /* eslint-disable-next-line react/destructuring-assignment */
        onClick={() => this.props.onHide()}
      >
        Cancel
      </Button>
      <Button
        className="btn"
        type="submit"
        variant="primary"
        /* eslint-disable-next-line react/destructuring-assignment */
        disabled={this.state.loading}
      >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        <RenderSpinner isVisible={this.state.loading} />
        Upload
      </Button>
    </>
  );

  RenderPreviewButtons = () => {
    const copyToClipboard = () => {
      if (!this.imageFile) {
        this.errorAlert('Error: Image not uploaded yet');
        return;
      }
      const { cropper } = this.state;
      const data = cropper.getCanvasData();
      this.cropSavePosition = data;
      navigator.clipboard.writeText(JSON.stringify(data));
    };

    const copyFromClipboard = async () => {
      if (!this.imageFile) {
        this.errorAlert('Error: Image not uploaded yet');
        return;
      }
      const { cropper } = this.state;
      try {
        navigator.clipboard.readText()
          .then((text) => {
            cropper.setCanvasData(JSON.parse(text));
          });
      } catch (error) {
        cropper.setCanvasData(this.cropSavePosition);
      } finally {
        this.setScaleValue();
      }
    };

    const sliderHandler = (event) => {
      if (this.imageFile) {
        const { isCharacter, cropper } = this.state;
        const zoomValue = event.target.value;

        const containerData = cropper.getContainerData();
        const cropBoxData = cropper.getCropBoxData();

        const x = containerData.width / 2;
        const y = cropBoxData.height + (containerData.height - cropBoxData.height) / 2;

        cropper.zoomTo(zoomValue, { x, y });
        const moveOut = this.chackMoveOutCropImage(cropper, isCharacter);

        if (moveOut.result) {
          cropper.setCanvasData(moveOut.canvasData);
        }
        this.setScaleValue();
      }
    };

    const toolTipHandler = (value) => {
      if (!this.imageFile) return null;
      const newValue = (value / this.cropInitailRatio) * 100;
      return `${newValue.toFixed()}%`;
    };

    return (
      <>
        <Col className="cropper-source-btn">
          <Row className="cropper-source-btn-position">
            <Button
              size="sm"
              className="btn"
              /* eslint-disable-next-line react/destructuring-assignment */
              disabled={this.state.loading}
              type="button"
              variant="secondary"
              onClick={copyToClipboard}
            >
              Save
              <br />
              {' '}
              position
            </Button>
            <Button
              size="sm"
              className="btn"
              /* eslint-disable-next-line react/destructuring-assignment */
              disabled={this.state.loading}
              type="button"
              variant="secondary"
              onClick={copyFromClipboard}
            >
              Use saved
              <br />
              position
            </Button>
          </Row>
          <Row className="cropper-source-btn-scale">
            <Col sm={2} className="cropper-source-btn-scale--text">
              SCALE
            </Col>
            <Col>
              <RangeSlider
                disabled={!this.imageFile}
                /* eslint-disable-next-line react/destructuring-assignment */
                value={this.state.scaleValue}
                /* eslint-disable-next-line react/destructuring-assignment */
                min={this.cropScaleRangeMin}
                /* eslint-disable-next-line react/destructuring-assignment */
                max={this.cropScaleRangeMax}
                /* eslint-disable-next-line react/destructuring-assignment */
                step={this.cropScaleStep}
                size="lg"
                tooltipLabel={toolTipHandler}
                onChange={sliderHandler}
              />
            </Col>
          </Row>
        </Col>
        <Col className="cropper-preview-btn" />
      </>
    );
  };

  /**
  * @typedef {Object} moveOutCropData
  * @property {boolean} result - boolean - true if crop image out of the crop borders
  * @property {Object} canvasData - new canvasData for cropperjs
  */
  /**
   * Checks if an image fits within the crop size and write new parameters into canvasData
   * @param {Cropper} cropper - The cropperjs instance
   * @param {boolean} isCharacter - check for characters upload type
   * @returns {moveOutCropData}
   */
  chackMoveOutCropImage(cropper, isCharacter) {
    const cropBoxData = cropper.getCropBoxData();
    const canvasData = cropper.getCanvasData();

    let minYSize = cropBoxData.height;
    let minXSize = cropBoxData.width;
    let isMinSize = false;

    if (isCharacter) {
      minYSize = IMAGE_CROP_MIN_HEIGHT;
      minXSize = IMAGE_CROP_MIN_WIDTH;
    }

    const minTopPos = canvasData.top + canvasData.height - cropBoxData.top;
    const isOutMinTopPos = minTopPos < minYSize;

    if (isOutMinTopPos) {
      canvasData.top = -(canvasData.height - cropBoxData.top - minYSize);
    }

    const minBottomPos = -canvasData.top + cropBoxData.height + cropBoxData.top;
    const isOutMinBottomPos = minBottomPos < minYSize;

    if (isOutMinBottomPos) {
      canvasData.top = cropBoxData.height + cropBoxData.top - minYSize;
    }

    const minLeftPos = canvasData.left + canvasData.width - cropBoxData.left;
    const isOutMinLeftPos = minLeftPos < minXSize;

    if (isOutMinLeftPos) {
      canvasData.left = -(canvasData.width - cropBoxData.left - minXSize);
    }

    const minRightPos = -canvasData.left + cropBoxData.width + cropBoxData.left;
    const isOutMinRightPos = minRightPos < minXSize;

    if (isOutMinRightPos) {
      canvasData.left = cropBoxData.width + cropBoxData.left - minXSize;
    }

    if (minXSize > canvasData.width) {
      canvasData.width = minXSize;
      isMinSize = true;
    }

    if (minYSize > canvasData.height) {
      canvasData.height = minYSize;
      isMinSize = true;
    }

    const result = isOutMinTopPos
      || isOutMinBottomPos
      || isOutMinLeftPos
      || isOutMinRightPos
      || isMinSize;

    return { result, canvasData };
  }

  render() {
    const { validated } = this.state;
    const {
      update, type: typePreview, user, obj, show, ...other
    } = this.props;

    if (!show) {
      return null;
    }

    let defaultLabelName = 'Image';
    const defaultAliasValue = obj?.alias;
    let defaultLabelValue = obj?.label;
    let defaultName = 'label';
    let acceptFiles = '.jpg,.jpeg';
    const isAdminRole = user.role === 'admin';

    if (typePreview === 'character') {
      defaultLabelName = 'Character';
      defaultLabelValue = obj?.title;
      defaultName = 'title';
      acceptFiles = '.png';
    }

    return (
      <Modal
        show={show}
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form noValidate validated={validated} onSubmit={(event) => { this.handleSubmit(event); }}>
          <Modal.Header>
            <Modal.Title>
              {
                  typePreview === 'character'
                    ? 'Upload Character Art'
                    : 'Upload Backgrounds'
                }
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* eslint-disable-next-line react/destructuring-assignment */}
            <RenderAlert errorMessage={this.state.formError} />
            <Form.Row>
              <RenderAliasField
                show={isAdminRole}
                defaultLabelName={defaultLabelName}
                defaultAliasValue={defaultAliasValue}
              />
              <RenderNameField
                show
                defaultLabelName={defaultLabelName}
                defaultLabelValue={defaultLabelValue}
                name={defaultName}
              />
              <RenderFileField
                show
                acceptFiles={acceptFiles}
                onChangeCallback={(e) => this.onChangeFile(e)}
              />
            </Form.Row>
            <Form.Row className="cropper-area-btn">
              {this.RenderPreviewButtons()}
            </Form.Row>
            <Form.Row className="cropper">
              {/* renderPreview as func because react-cropper hooks class.. */}
              {this.renderPreview(typePreview)}
            </Form.Row>

          </Modal.Body>

          <Modal.Footer>
            <Form.Row className="upload-image-form-submit-btn">
              {this.RenderUploadButtons()}
            </Form.Row>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
