import React from 'react';
import {
  Dropdown,
} from 'react-bootstrap';
import { BookStyle } from '../../../../dorian-shared/types/book/book';
import classNames from './BookStyleSelector.module.scss';

export interface BookStyleSelectorProps {
    loading: boolean
    bookStyles: BookStyle[];
    bookStyleId: number
    onStyleSelected: (bookStyleId: number) => void;
}

export function BookStyleSelector(props: BookStyleSelectorProps) {
  const {
    loading, bookStyles, onStyleSelected, bookStyleId,
  } = props;

  const currentBookStyle = bookStyles.find((bookStyle) => bookStyle.id === bookStyleId);

  const bookStyleTitle = currentBookStyle
    ? currentBookStyle.title
    : `Id: ${bookStyleId} not found`;

  const title = loading ? 'Please wait...' : `${bookStyleTitle}'s style`;

  return (
    <Dropdown>
      <Dropdown.Toggle
        className={classNames.toggle}
        disabled={loading}
        variant="outline-secondary"
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          bookStyles.map((bookStyle) => (
            <Dropdown.Item
              key={bookStyle.id}
              eventKey={bookStyle.id}
              active={(bookStyle.id === bookStyleId)}
              onClick={() => { onStyleSelected(bookStyle.id); }}
            >
              {bookStyle.title}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}
