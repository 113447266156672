import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import { DeleteRequest } from './DeleteRequest';

export class RequestTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteRequestShow: false,
    };
  }

  render() {
    const { update, obj } = this.props;
    const { modalDeleteRequestShow } = this.state;

    const modalDeleteRequestClose = () => {
      this.setState({ modalDeleteRequestShow: false });
      update();
    };

    return (
      <tr>
        <td>
          {obj.fullname}
        </td>
        <td>
          {obj.email}
        </td>
        <td>
          {obj.country}
        </td>
        <td>
          {obj.device}
        </td>
        <td className="text-right">
          <OverlayTrigger
            overlay={(
              <Tooltip id="tooltip-top">
                Delete Request
              </Tooltip>
            )}
          >
            <Button
              onClick={() => this.setState({ modalDeleteRequestShow: true })}
              variant="secondary"
              size="sm"
              className="mx-1"
            >
              <img src={imgDelete} className="btnImg" alt="Delete Request" />
            </Button>
          </OverlayTrigger>
          {modalDeleteRequestShow && (
          <DeleteRequest
            show={modalDeleteRequestShow}
            onHide={modalDeleteRequestClose}
            obj={obj}
          />
          )}
        </td>
      </tr>
    );
  }
}
