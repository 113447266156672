import React, { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { SelectedFilesToUpload, UploadFilesModalStatus } from '../types';
import classNames from './DropZone.module.scss';

interface DropZoneProps {
    disabled?: boolean;
    accept?: {
      [key: string]: string[],
    },
  onChange: (files: SelectedFilesToUpload[]) => void;
  className?: string;
  InstructionComponent?: React.ComponentType;
  InstructionActiveComponent?: React.ComponentType;
}

function DefaultDropZoneInstruction() {
  return <span>Drag &quot;n&quot; drop some files here, or click to select files</span>;
}

function DefaultActiveDropZoneInstruction() {
  return <span>Drop the files here ...</span>;
}

export function DropZone(props: DropZoneProps) {
  const {
    disabled,
    accept = {
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/png': ['.png'],
    },
    onChange,
    className = classNames.dropZone,
    InstructionComponent = DefaultDropZoneInstruction,
    InstructionActiveComponent = DefaultActiveDropZoneInstruction,
  } = props;

  const onDrop = useCallback((
    acceptedFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    const newRejectedFiles: SelectedFilesToUpload[] = rejectedFiles.map((rejectedFile) => ({
      file: rejectedFile.file,
      status: UploadFilesModalStatus.Error,
      errors: rejectedFile.errors,
    } as SelectedFilesToUpload));

    const newAcceptedFiles: SelectedFilesToUpload[] = acceptedFiles.map((acceptedFile) => ({
      file: acceptedFile,
      status: UploadFilesModalStatus.Ready,
      errors: undefined,
    }) as SelectedFilesToUpload);

    onChange([...newRejectedFiles, ...newAcceptedFiles]);
  }, [onChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled,
  });

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? <InstructionActiveComponent />
          : <InstructionComponent />
      }
    </div>
  );
}
