import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';

type ImageSearchProps = {
  value: string;
  onChange: (searchString: string) => void;
};

export function SearchBar(props: ImageSearchProps) {
  const { value, onChange } = props;

  const [searchString, setSearchString] = useState(value);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const setStateWithDebounce = useDebouncedCallback((searchValue) => {
    onChange(searchValue);
  }, 1000);

  useEffect(() => {
    setStateWithDebounce(searchString);
  }, [searchString, setStateWithDebounce]);

  const handleSearchBarSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStateWithDebounce.flush();
  };

  const handleSearchBarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setStateWithDebounce.flush();
  };

  const onChangeSearchString = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setSearchString(searchValue);
  };

  return (
    <Form onSubmit={handleSearchBarSubmit} className="search-form">
      <FormControl
        className="search-form_input"
        size="sm"
        type="search"
        placeholder="Type search string"
        aria-label="Search"
        value={searchString}
        onChange={onChangeSearchString}
      />
      <Button
        type="button"
        size="sm"
        variant="secondary"
        onClick={handleSearchBarClick}
      >
        Search
      </Button>
    </Form>
  );
}
