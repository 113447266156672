import React, { Component } from 'react';

export class SelectBox extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      y: 0,
      x: 0,
      width: 0,
      height: 0,
      isSelecting: false,
    };
    this.selectbox = null;
  }

  // TODO: pass internal ref via ForwardRef pattern rather than this construction
  // eslint-disable-next-line react/no-unused-class-component-methods
  getRef = () => this.selectbox; // used via ref of SelectBox

  getSelectboxRef = (ref) => {
    this.selectbox = ref;
  };

  render() {
    const { fixedPosition, className } = this.props;
    const {
      isSelecting, width, y, x, height,
    } = this.state;

    const boxStyle = {
      left: x,
      top: y,
      width,
      height,
      zIndex: 9000,
      position: fixedPosition ? 'fixed' : 'absolute',
      cursor: 'default',
    };

    return (
      <div>
        {isSelecting && (
          <div ref={this.getSelectboxRef} style={boxStyle} className={className} />
        )}
      </div>
    );
  }
}

SelectBox.defaultProps = {
  className: 'selectable-selectbox',
};
