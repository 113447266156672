import { isEmpty } from 'lodash-es';
import React, { Component } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { ReactComponent as IconError } from '../../../assets/images/error.svg';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';
import { ApproveDocumentModal } from '../../ui/PageWrapper/ApproveDocumentModal/ApproveDocumentModal';
import { ConfirmTermModal } from '../../ui/PageWrapper/ConfirmTermModal';
import { fetchAgreementForPrompt, getDocumentIdsForPrompt } from '../_shared/agreementForPromptUtils';
import { getUserFullName } from '../Users';
import { internalAnalytics } from './internalAnalytics';
import './style.scss';

const auth = new Auth();

export class BookCreateLink extends Component {
  constructor(...args) {
    super(...args);
    this.user = auth.getUser();
    this.deviceId = new URLSearchParams(window.location.search).get('deviceId');

    this.state = {
      data: [],
      redirect: false,
      error: false,
      acceptedOriginal: false,
      // eslint-disable-next-line react/no-unused-state
      acceptedOriginalModal: null,
    };
    if (!this.user && this.deviceId) {
      api.post('/v1/login', { deviceSecret: this.deviceId })
        .catch(() => {
          this.setState({
            // eslint-disable-next-line react/no-unused-state
            showErrorAlert: true,
            // eslint-disable-next-line react/no-unused-state
            showLoading: false,
          });
        })
        .then((res) => {
          if (res && res.status === 200) {
            // localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              showErrorAlert: false,
              // eslint-disable-next-line react/no-unused-state
              showLoading: false,
            });
            sessionStorage.setItem('delayRegistration', '1');
            localStorage.setItem('deviceId', this.deviceId);
            auth.login(res.data);
          }
        });
    }
  }

  componentDidMount() {
    const { match } = this.props;

    if (this.user) {
      this.getTemplates(match.params.id);
    } else {
      localStorage.setItem('createTemplate', JSON.stringify({
        id: match.params.id,
        writeWithUs: false,
        lostPassword: false,
        signIn: false,
        setpassword: false,
      }));
      // eslint-disable-next-line no-unused-expressions
      !this.deviceId && this.setState({ redirect: '/write-with-us-app' });
    }
  }

  getTemplates = (templateId) => {
    api.get('/v1/bookprompts')
      .then((res) => {
        if (res.data
          && res.data.prompts
          && res.data.prompts.find((el) => String(el.uuid) === String(templateId))) {
          this.setState({
            data: res.data.prompts.find((el) => String(el.uuid) === String(templateId)),
          }, () => {
            this.getTemplatesResult(templateId);
          });
        } else {
          this.setState({ error: 'wrong template id' });
        }
      })
      .catch(() => {
        this.setState({ error: 'error get templates' });
      });
  };

  getTemplatesResult = (templateId) => {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      acceptedOriginalModal: null,
    });
    const {
      acceptedOriginal,
      data: prompt,
    } = this.state;
    const createTemplate = JSON.parse(localStorage.getItem('createTemplate'));

    if (acceptedOriginal || prompt) {
      const data = {
        original: false,
        title: this.user.email ? `${getUserFullName(this.user)} Story` : 'New Story',
        prompt_uuid: templateId,
        promptData: {
          episodeTitle: 'Episode 1',
          episodeSummary: 'Episode 1',
        },
        createTemplate,
      };
      this.addBook(data);
    } else {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        acceptedOriginalModal: templateId,
      });
    }
  };

  addBook = (newBook) => {
    const { data } = this.state;
    if (!this.bookToCreateAfterStoryRelatedAgreementAccepted) {
      const documentIdsForPrompt = getDocumentIdsForPrompt(data);
      if (!isEmpty(documentIdsForPrompt)) {
        this.bookToCreateAfterStoryRelatedAgreementAccepted = newBook;
        const onAgreementFetched = (storyRelatedAgreementToAccept) => this.setState({
          storyRelatedAgreementToAccept,
        });
        fetchAgreementForPrompt(documentIdsForPrompt, onAgreementFetched);
        return;
      }
    }

    api.post('/v1/books', newBook)
      .then((res) => {
        if (res.data.documents) {
          localStorage.setItem('documents', JSON.stringify(res.data.documents));
          this.setState({
            formValue: newBook,
            acceptedOriginal: false,
          });
          return;
        }

        localStorage.removeItem('createTemplate');
        const { book } = res.data;
        const { data: prompt } = this.state;

        internalAnalytics({
          event: 'pressed_use_this_prompt_button',
          data: {
            prompt_title: prompt.title,
            story_id: book.id,
            story_uuid: book.uuid,
            user_id: this.user.id,
            location: 'app_use_this_prompt_button',
            description: 'pressed use this prompt button, this event is captured during creation',
          },
        });
        if (book.stories && book.stories.length > 0) {
          this.setState({ redirect: `/stories/${book.stories[0].id}/branches` });
        } else {
          this.setState({ redirect: `/book/${book.id}` });
        }
      })
      .catch((error) => {
        const agreements = error?.response?.data?.documents ?? [];
        if (agreements.length > 0) {
          this.setState({
            storyRelatedAgreementToAccept: agreements[0],
          });
          this.bookToCreateAfterStoryRelatedAgreementAccepted = newBook;
        } else {
          this.setState({ error: 'error create book' });
        }
      });
  };

  render() {
    const {
      redirect,
      acceptedOriginal,
      formValue,
      error,
      storyRelatedAgreementToAccept,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }
    if (error) {
      return (
        <div className="pageAddBook">
          <Alert variant="danger">
            <IconError className="IconError" />
            <Alert.Heading>
              Story creation error
            </Alert.Heading>
            <Button
              onClick={() => {
                localStorage.removeItem('createTemplate');
                this.setState({ redirect: '/' });
              }}
            >
              Back to home page
            </Button>
          </Alert>
        </div>
      );
    }
    if (!this.user && this.deviceId) {
      return null;
    }
    return (
      <>
        <div className="pageAddBook">
          <Alert variant="success">
            <Alert.Heading>
              Please wait
            </Alert.Heading>
            <p>Story in progress creating</p>
            <hr />
            <div>
              <Spinner
                style={{ width: '3rem', height: '3rem' }}
                role="status"
                variant="info"
                animation="border"
                className="justify-content-center"
              />
            </div>
          </Alert>
        </div>
        {storyRelatedAgreementToAccept && (
          <ApproveDocumentModal
            document={storyRelatedAgreementToAccept}
            onCancel={() => this.setState({ storyRelatedAgreementToAccept: null })}
            onSuccess={() => {
              this.setState({ storyRelatedAgreementToAccept: null });
              this.addBook(this.bookToCreateAfterStoryRelatedAgreementAccepted);
            }}
            onError={() => this.errorAlert('There was an issue with signing agreement. Please contact support.')}
          />
        )}

        {
          (
            !!formValue
            && !acceptedOriginal
          )
          && (
            <ConfirmTermModal
              auth={auth}
              close={() => {
                this.setState({
                  error: 'agreement not accepted',
                  acceptedOriginal: false,
                  // eslint-disable-next-line react/no-unused-state
                  acceptedOriginalModal: null,
                });
              }}
              update={() => {
                this.setState({
                  acceptedOriginal: true,
                }, () => {
                  this.addBook(formValue);
                });
              }}
            />
          )
        }
      </>
    );
  }
}
