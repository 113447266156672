import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgComments from '../../../assets/images/comments.svg';
import { ChapterCommentsModal } from './ChapterCommentsModal';

export class ChapterCommentsBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const comments = () => {
      const { show } = this.state;
      const {
        obj,
        disabled,
      } = this.props;

      if (show) {
        return (
          <ChapterCommentsModal
            obj={obj}
            disabled={disabled}
            show
            onHide={() => {
              this.setState({ show: false });
            }}
          />
        );
      }
      return null;
    };

    return (
      <>
        <span className="tooltip-box">
          <Button
            onClick={() => {
              this.setState({
                show: true,
              });
            }}
            variant="secondary"
            size="sm"
            className="ml-1"
          >
            <img
              src={imgComments}
              className="btnImg"
              alt="Comments Episode"
            />
          </Button>
          <div className="tooltip">
            <div className="arrow" />
            <div className="tooltip-inner">Comments</div>
          </div>
        </span>
        {comments()}
      </>
    );
  }
}
