import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { showToast } from '../../../ui/utils';

export class ConnectionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branch: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { branchNode, storyId } = this.props;

    this.setState({ loading: true });
    api.get(`/v1/stories/${storyId}/branches/${branchNode.id}`)
      .then((res) => {
        this.setState({
          branch: res.data.branch.steps[0].answers,
        });
      }).catch((error) => showToast({
        textMessage: error.message,
      }))
      .finally(() => this.setState({ loading: false }));
  };

  update = (id) => {
    const {
      branchNode,
      destinationNodeId,
      onHide,
      update,
      storyId,
    } = this.props;

    this.setState({ loading: true });
    const arr = { gotoBranchId: destinationNodeId };
    api.put(`/v1/stories/${storyId}/branches/${branchNode.id}/answers/${id}`, arr)
      .then(() => update())
      .catch((error) => showToast({
        textMessage: error.message,
      }))
      .finally(() => onHide());
  };

  ButtonList = () => {
    const { branch } = this.state;

    if (!branch.length) {
      return (
        <Alert variant="info">
          No answer options found.
        </Alert>
      );
    }

    return branch.map((branchNode) => (
      <Button
        key={branchNode.id}
        block
        variant="primary"
        onClick={() => {
          this.update(branchNode.id);
        }}
      >
        {branchNode.text}
      </Button>
    ));
  };

  render() {
    const { show, onHide } = this.props;
    const { loading } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please select Answer</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner
                variant="primary"
                animation="border"
                className="justify-content-center"
              />
            </div>
          ) : this.ButtonList()}
        </Modal.Body>

      </Modal>
    );
  }
}
