import React from 'react';
import { ConfirmEmailForm } from '../../../uiV2/ConfirmEmailForm/ConfirmEmailForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function ConfirmEmail() {
  return (
    <LoginLayout>
      <ConfirmEmailForm />
    </LoginLayout>
  );
}
