import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash-es';
import React, { ChangeEvent } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { Character } from 'dorian-shared/types/character/Character';
import { BadgeWithTooltip } from '../../../ui/AvatarTool/BadgeWithTooltip/BadgeWithTooltip';
import classes from './MemoryBank.module.scss';
import {
  MemoryFormDTO, MemoryIcon, MemoryShowIn, MemoryType,
} from './memoryBankTypes';
import { MemoryDefaultChangeDescriptionField } from './MemoryFields/MemoryDefaultChangeDescriptionField';
import { MemoryDisplayNameField } from './MemoryFields/MemoryDisplayNameField';
import { MemoryIconField } from './MemoryFields/MemoryIconField';
import { MemoryNameField } from './MemoryFields/MemoryNameField';
import { MemoryShowInAlertField } from './MemoryFields/MemoryShowInAlertField';
import { MemoryTypeField } from './MemoryFields/MemoryTypeField';
import { MemoryValueField } from './MemoryFields/MemoryValueField';
import { MemoryViewFiled } from './MemoryFields/MemoryViewField';

type MemoryBankSlotsFormProps = {
  memoryIndex: number,
  memoryFormDTO: MemoryFormDTO,
  errors: FormikErrors<MemoryFormDTO>,
  onChange: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>, slotIndex: number) => void,
  onDelete: (slotIndex: number) => void,
  onIconAdd: (image: File, label: string) => Promise<MemoryIcon>,
  characters: Character[],
  memoryIcons: MemoryIcon[],
  disabled?: boolean,
  isLocked?: boolean,
}

export function MemoryForm(props: MemoryBankSlotsFormProps) {
  const {
    onChange, onDelete, memoryFormDTO, memoryIndex, errors,
    characters, memoryIcons, onIconAdd, disabled, isLocked,
  } = props;

  const {
    type, value, name, displayName, defaultChangeDescription, icon, showIn,
  } = memoryFormDTO;

  const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
    onChange(event, memoryIndex);
  };

  const handleDeleteClick = () => {
    onDelete(memoryIndex);
  };

  const visible = !isEmpty(showIn);
  const isShowInDisabled = type !== MemoryType.Number;
  const isMemoryVisionDisabled = !visible || isShowInDisabled;

  return (
    <Row>
      {/* Name */}
      <Col xl lg={2} md={3} className="">
        <MemoryNameField
          required
          id={`memoryBankSlots[${memoryIndex}].name`}
          value={name}
          onChange={handleChange}
          errorMessage={errors?.name}
          isInvalid={!!errors?.name}
          disabled={disabled || isLocked}
          /* Autofocus when a new memory is created */
          autoFocus={name === value}
        />
        {isLocked && (
          <div className={classes.lockedTooltip}>
            <BadgeWithTooltip
              id="locked-memory-badge"
              title="Memory variable is locked"
              content="Used in live episodes and therefore can't be changed name, type, or deleted."
            />
          </div>
        )}
      </Col>
      {/* Type */}
      <Col md="auto">
        <MemoryTypeField
          id={`memoryBankSlots[${memoryIndex}].type`}
          value={type as MemoryType}
          onChange={handleChange}
          disabled={disabled || isLocked}
        />
      </Col>
      {/* Value */}
      <Col>
        <MemoryValueField
          id={`memoryBankSlots[${memoryIndex}].value`}
          type={type}
          value={value}
          onChange={handleChange}
          characters={characters}
          errorMessage={errors?.value}
          isInvalid={!!errors?.value}
          disabled={disabled}
        />
      </Col>
      {/* Visible */}
      <Col md="auto" style={{ minWidth: '80px' }}>
        <MemoryShowInAlertField
          custom
          id={`memoryBankSlots[${memoryIndex}].visible`}
          checked={visible}
          onChange={(event) => {
            const isChecked = event.target.checked;
            const newEvent = {
              target: {
                name: `memoryBankSlots[${memoryIndex}].showIn`,
                value: isChecked ? [MemoryShowIn.Hide] : [],
              },
            } as unknown as ChangeEvent<HTMLInputElement>;
            handleChange(newEvent);
          }}
          disabled={isShowInDisabled || disabled}
        />
      </Col>
      {/* View */}
      <Col md={2} style={{ minWidth: '140px' }}>
        <MemoryViewFiled
          id={`memoryBankSlots[${memoryIndex}].showIn`}
          value={showIn}
          onChange={handleChange}
          disabled={isMemoryVisionDisabled || disabled}
        />
      </Col>
      {/* Display Name */}
      <Col md={2}>
        <MemoryDisplayNameField
          id={`memoryBankSlots[${memoryIndex}].displayName`}
          value={displayName}
          onChange={handleChange}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.displayName && !isMemoryVisionDisabled}
          errorMessage={errors?.displayName}
        />
      </Col>
      {/* Icon */}
      <Col md={1}>
        <MemoryIconField
          id={`memoryBankSlots[${memoryIndex}].icon`}
          value={icon}
          onChange={handleChange}
          memoryIcons={memoryIcons}
          onSave={onIconAdd}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.icon && !isMemoryVisionDisabled}
          errorMessage={errors?.icon}
        />
      </Col>
      {/* Description */}
      <Col xl lg={2} md={11} sm={11}>
        <MemoryDefaultChangeDescriptionField
          id={`memoryBankSlots[${memoryIndex}].defaultChangeDescription`}
          value={defaultChangeDescription}
          onChange={handleChange}
          disabled={isMemoryVisionDisabled || disabled}
          isInvalid={!!errors?.defaultChangeDescription && !isMemoryVisionDisabled}
          errorMessage={errors?.defaultChangeDescription}
        />
      </Col>
      {/* Delete button */}
      <Form.Group className="mt-auto">
        <Button
          onClick={handleDeleteClick}
          variant="secondary"
          className={classes.memoryBankRemoveButton}
          disabled={disabled || isLocked}
        >
          X
        </Button>
      </Form.Group>
    </Row>
  );
}
