import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Table,
} from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import { api } from '../../api';

const headProps = ['headstate', 'eyebrowstate', 'eyestate', 'mouthstate', 'blushstate'];

export function PropertiesList(props) {
  const {
    disabled,
    edit: editProp,
    index,
    delete: deleteProp,
    properties,
    update,
    values,
    config,
    addNew,
  } = props;

  const [edit, setEdit] = React.useState(addNew);
  const [prop, setProp] = React.useState([]);
  const [val, setVal] = React.useState([]);
  const [propObj, setPropObj] = React.useState([]);
  const [valObj, setValObj] = React.useState([]);
  const configProps = Object.keys(config);

  React.useEffect(() => {
    const objProps = index ? properties.find((el) => String(el.id) === String(index)) : null;
    const objValues = index ? values.find((el) => String(el.id) === String(config[index])) : null;
    setProp(objProps);
    setVal(objValues);
    setPropObj(objProps);
    setValObj(objValues);
  }, [config, index, properties, values]);

  return (
    <tr>
      <td>
        {
          edit
            ? (
              <Form.Control
                size="sm"
                as="select"
                name="property"
                defaultValue={(prop && prop.id) && String(prop.id)}
                onChange={(e) => {
                  const v = properties.find((a) => String(a.id) === String(e.target.value));
                  setProp(v);
                }}
              >
                <option>undefined</option>
                {
                properties.filter(
                  (el) => headProps.includes(el.ident) || (prop && prop.id
                    ? String(el.id) === String(prop.id)
                    : false),
                )
                  .filter(
                    (el) => !configProps.includes(String(el.id)) || (prop && prop.id
                      ? String(el.id) === String(prop.id)
                      : false),
                  ).map((o) => (
                    <option
                      key={o.id}
                      value={String(o.id)}
                      disabled={(prop
                        && String(prop.id) !== String(o.id)
                        && configProps.includes(String(o.id)))}
                    >
                      {o.ident}
                    </option>
                  ))
              }
              </Form.Control>
            )
            : prop.ident
        }
      </td>
      <td>
        {
          edit
            ? (
              <Form.Control
                size="sm"
                as="select"
                name="value"
                defaultValue={(val && val.id) && val.id}
                onChange={(e) => {
                  const v = values.find((a) => String(a.id) === String(e.target.value));
                  setVal(v);
                }}
              >
                <option>undefined</option>
                {
                values.filter(
                  (el) => (
                    String(el.propertyId) === (prop ? String(prop.id) : null))
                    && el.typeId === 1,
                ).map((o) => (
                  <option
                    key={o.id}
                    value={o.id}
                  >
                    {o.ident}
                  </option>
                ))
              }
              </Form.Control>
            )
            : val.ident
        }
      </td>
      <td style={{ textAlign: 'right' }}>
        {
          edit
            ? (
              <>
                <Button
                  type="reset"
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                  onClick={() => {
                    setProp(propObj);
                    setVal(valObj);
                    setEdit(false);
                    editProp(false);
                    if (addNew) {
                      deleteProp();
                    }
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  className="mx-1"
                  onClick={() => {
                    update((propObj && propObj.id) && propObj.id, prop.id, val.id);
                    setEdit(false);
                    editProp(false);
                  }}
                  disabled={(!prop || !val)}
                >
                  Ok
                </Button>
              </>
            )
            : (
              <>
                <Button
                  onClick={() => {
                    setEdit(true);
                    editProp(true);
                  }}
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                  disabled={disabled}
                >
                  <img src={imgEdit} className="btnImg" alt="Edit" />
                </Button>
                <Button
                  onClick={() => {
                    deleteProp(prop.id);
                    editProp(false);
                  }}
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                  disabled={disabled}
                >
                  <img src={imgDelete} className="btnImg" alt="Delete" />
                </Button>
              </>
            )
        }
      </td>
    </tr>
  );
}

export class AddExpression extends Component {
  constructor(...args) {
    super(...args);
    const { data } = this.props;

    this.state = {
      validated: false,
      formError: null,
      editProperties: false,
      addNew: false,
      config: data && data.config ? data.config : {},
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addExpression(obj, validated) {
    if (validated === true) {
      const { config } = this.state;

      const requestData = {
        title: obj.title,
        value: obj.value,
        def: !!obj.def,
        config,
      };

      const {
        update,
        data,
      } = this.props;

      if (data !== null) {
        api.put(`/v1/characters/expressions/${data.id}`, requestData)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/characters/expressions', requestData)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addExpression(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  renderAddNew() {
    const {
      addNew,
      config,
    } = this.state;

    if (!addNew) {
      return null;
    }
    const {
      properties,
      values,
    } = this.props;

    const configClone = { ...config };

    return (
      <PropertiesList
        addNew
        config={configClone}
        properties={properties}
        values={values}
        edit={(e) => {
          this.setState({
            editProperties: e,
          });
        }}
        update={(o, p, v) => {
          if (o) {
            delete configClone[o];
          }
          configClone[p] = v;
          this.setState({
            config: configClone,
            addNew: false,
            editProperties: false,
          });
        }}
        delete={() => {
          this.setState({
            addNew: false,
            editProperties: false,
          });
        }}
      />
    );
  }

  renderProperties() {
    const ret = [];
    const {
      properties,
      values,
    } = this.props;

    const {
      editProperties,
      config,
    } = this.state;

    const configClone = { ...config };
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in configClone) {
      ret.push(
        <PropertiesList
          key={key}
          index={key}
          config={configClone}
          disabled={editProperties}
          properties={properties}
          values={values}
          edit={(e) => {
            this.setState({
              editProperties: e,
            });
          }}
          update={(o, p, v) => {
            delete configClone[o];
            configClone[p] = v;
            this.setState({
              config: configClone,
            });
          }}
          delete={(p) => {
            delete configClone[p];
            this.setState({
              config: configClone,
            });
          }}
        />,
      );
    }
    return ret;
  }

  render() {
    const {
      show,
      onHide,
      data,
      update,
      ...other
    } = this.props;

    if (!show) {
      return null;
    }

    const {
      validated,
      config,
      addNew,
      formError,
      editProperties,
    } = this.state;

    let title = 'Add New Expression';
    let defTitle = '';
    let defValue = '';
    if (data !== null) {
      title = `Edit Expression ${data.title}`;
      defTitle = data.title;
      defValue = data.value;
    }

    const isDef = data ? data.def : null;

    return (
      <Modal
        {...other}
        show
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} md="5" controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  defaultValue={defTitle}
                  name="title"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose Title.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="5" controlId="Value">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Value"
                  defaultValue={defValue}
                  name="value"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="Value">
                <Form.Label>Default</Form.Label>
                <Form.Check
                  custom
                  id="def"
                  name="def"
                  defaultChecked={isDef}
                  label=""
                />
              </Form.Group>
            </Form.Row>
            <Table striped hover size="md">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Value</th>
                  <th style={{ textAlign: 'right' }}>
                    <Button
                      variant="primary"
                      size="sm"
                      className="mx-1"
                      disabled={(editProperties
                        || addNew
                        || (headProps.length <= Object.keys(config).length)
                      )}
                      onClick={() => {
                        this.setState({
                          addNew: true,
                          editProperties: true,
                        });
                      }}
                    >
                      + Add New
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.renderAddNew()}
                {this.renderProperties()}
              </tbody>
            </Table>

          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => {
                this.setState({
                  editProperties: false,
                  config: data && data.config ? data.config : {},
                }, () => {
                  onHide();
                });
              }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={editProperties}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
