import cs from 'classnames';
import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import imgDelete from '../../../../assets/images/delete.svg';
import imgSave from '../../../../assets/images/export.svg';
import imgRestore from '../../../../assets/images/replace.svg';
import classNamesColumnsWidth from '../ClientVariablesColumnsWidth.module.scss';
import classNames from './ClientVariableRow.module.scss';
import { ClientVariableValueInput } from './ClientVariableValueInput';

export function ClientVariableRow({
  clientVariable,
  onClientVariableChange,
  isChanged,
  onRestore,
  onDelete,
}) {
  const handleFieldChange = (fieldName, value) => onClientVariableChange({
    ...clientVariable,
    [fieldName]: value,
  });

  const isInvalidExperiment = clientVariable.type === 'experiment'
    ? !clientVariable.experiment
    : !!clientVariable.experiment;

  const isInvalidPath = clientVariable.type === 'gate'
    ? !clientVariable.path
    : !!clientVariable.path;

  return (
    <Row className={classNames.row}>
      <Col sm={12} md className={classNamesColumnsWidth.name}>
        <Form.Control
          required
          size="sm"
          type="text"
          name="name"
          onChange={(event) => handleFieldChange('name', event.target.value)}
          value={clientVariable.name}
        />
      </Col>
      <Col sm={12} md className={classNamesColumnsWidth.type}>
        <Form.Control
          required
          size="sm"
          as="select"
          custom
          name="type"
          onChange={(event) => handleFieldChange('type', event.target.value)}
          value={clientVariable.type}
        >
          <option value="">Select source</option>
          <option value="experiment">experiment</option>
          <option value="gate">gate</option>
          <option value="constant">constant</option>
        </Form.Control>
      </Col>
      <Col sm={12} md className={classNamesColumnsWidth.experiment}>
        <Form.Control
          required={clientVariable.type === 'experiment'}
          size="sm"
          type="text"
          name="experiment"
          onChange={(event) => handleFieldChange('experiment', event.target.value)}
          isInvalid={isInvalidExperiment}
          value={clientVariable.experiment}
        />
      </Col>
      <Col className={classNamesColumnsWidth.path}>
        <Form.Control
          required={clientVariable.type === 'gate'}
          size="sm"
          type="text"
          name="path"
          onChange={(event) => handleFieldChange('path', event.target.value)}
          value={clientVariable.path}
          isInvalid={isInvalidPath}
        />
      </Col>
      <Col className={classNamesColumnsWidth.dataType}>
        <Form.Control
          required
          size="sm"
          as="select"
          name="dataType"
          onChange={(event) => handleFieldChange('dataType', event.target.value)}
          value={clientVariable.dataType}
        >
          <option value="">Select type</option>
          <option value="number">Number</option>
          <option value="string">String</option>
          <option value="boolean">Boolean</option>
        </Form.Control>
      </Col>
      <Col className={classNamesColumnsWidth.value}>
        <ClientVariableValueInput
          dataType={clientVariable.dataType}
          onChange={(event) => handleFieldChange('value', event.target.value)}
          value={clientVariable.value}
        />
      </Col>
      <Col sm={12} md className={cs(classNamesColumnsWidth.actions, classNames.actions)}>
        {isChanged && (
          <>
            <Button
              size="sm"
              variant={isChanged ? 'secondary' : 'outline-secondary'}
              type="submit"
            >
              <img src={imgSave} className={classNames.btnImg} alt="Save" />
            </Button>
            {' '}
            <Button
              size="sm"
              variant={isChanged ? 'secondary' : 'outline-secondary'}
              onClick={onRestore}
            >
              <img src={imgRestore} className={classNames.btnImg} alt="Restore" />
            </Button>
          </>
        )}
        {' '}
        <Button
          size="sm"
          variant="secondary"
          onClick={onDelete}
        >
          <img src={imgDelete} className={classNames.btnImg} alt="Delete" />
        </Button>
      </Col>
    </Row>
  );
}
