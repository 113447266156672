import moment from 'moment';
import * as QueryString from 'query-string';
import React, { Component } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import filterFactory, { Comparator, numberFilter } from 'react-bootstrap-table2-filter';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import { Link, Redirect } from 'react-router-dom';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { StoryCreationCheckBox } from './StoryCreationCheckBox';

export class StoryCreation extends Component {
  constructor(props) {
    super(props);
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    startDate.setDate(startDate.getDate() - 90);

    this.state = {
      loading: true,
      data: [],
      redirect: false,
      startDate,
    };

    const { location, history } = this.props;

    this.params = location?.search && QueryString.parse(location.search, { arrayFormat: 'index' });

    this.headers = [
      { label: 'Date', key: 'date' },
      { label: 'Created', key: 'created' },
      { label: 'Released', key: 'completed' },
      { label: 'Spinoffs', key: 'spinoff' },
      { label: 'Team Releases', key: 'team' },
    ];

    this.page = {
      header: {
        title: 'Episode Creation',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    const search = location?.search;
    if (search && JSON.stringify(search) !== JSON.stringify(prevProps.location.search)) {
      this.params = search && QueryString.parse(search, { arrayFormat: 'index' });
      this.setState({
        loading: true,
        redirect: false,
      }, () => {
        this.loadData();
      });
    }
  }

  addIdx = (arr) => arr.map((val, i) => ({
    idx: i,
    ...val,
  }));

  loadData = () => {
    const { location } = this.props;
    const { startDate } = this.state;

    const search = location?.search && QueryString.parse(location.search, { arrayFormat: 'index' });

    const limitV1 = (search.limitV1 && search.limitV1 !== 'false') && search.limitV1;
    const formattedStartDate = search.startDate === undefined ? startDate : search.startDate;

    this.setState({
      loading: true,
    });

    const params = {};
    if (limitV1) {
      params.revlimit = 1;
    }
    if (formattedStartDate) {
      const date = new Date(formattedStartDate);
      params.date = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    }

    api.get('/v1/analytics/creation', { params })
      .then((res) => {
        const data = this.addIdx(res.data.data);
        this.setState({
          data,
          loading: false,
        });
      })
      .catch(() => this.setState({ loading: false }));
  };

  setStartDate = (date) => {
    const { location } = this.props;

    const obj = location?.search ? QueryString.parse(location.search) : [];
    obj.startDate = date;
    const redirect = `/creation?${QueryString.stringify(obj, { arrayFormat: 'index' })}`;
    this.setState({
      redirect,
    });
  };

  renderLoading() {
    return (
      <div className="text-center">
        <Spinner
          variant="primary"
          animation="border"
          className="loadingSpinner justify-content-center"
        />
      </div>
    );
  }

  handleChangeCheckBox = (e) => {
    const { location } = this.props;

    const obj = location?.search ? QueryString.parse(location.search) : [];
    obj[e.target.name] = e.target.checked;
    const redirect = `/creation?${QueryString.stringify(obj, { arrayFormat: 'index' })}`;
    this.setState({
      redirect,
    });
  };

  renderData() {
    const { location } = this.props;
    const { loading, data } = this.state;

    const columns = [{
      dataField: 'date',
      text: 'Data',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cell, row) => {
        if (!(row.completed > 0 || row.created > 0)) {
          return cell;
        }

        return (
          <Link
            className="books-list-link"
            to={{
              pathname: `/creation/${cell}`,
              search: location?.search,
            }}
          >
            {cell}
          </Link>
        );
      },
    }, {
      dataField: 'created',
      text: 'Created',
      sort: true,
      filter: numberFilter({
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.GE],
        comparatorStyle: { display: 'none' },
      }),

    }, {
      dataField: 'completed',
      text: 'Released',
      sort: true,
      filter: numberFilter({
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.GE],
        comparatorStyle: { display: 'none' },
      }),
    }, {
      dataField: 'spinoff',
      text: 'Spinoffs',
      sort: true,
      filter: numberFilter({
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.GE],
        comparatorStyle: { display: 'none' },
      }),

    }, {
      dataField: 'team',
      text: 'Team releases',
      sort: true,
      filter: numberFilter({
        withoutEmptyComparatorOption: true,
        comparators: [Comparator.GE],
        comparatorStyle: { display: 'none' },
      }),
    }];

    return (
      <div>
        {loading
          ? this.renderLoading()
          : (
            <BootstrapTable
              bootstrap4
              keyField="idx"
              data={data}
              columns={columns}
              filter={filterFactory()}
            />
          )}
      </div>
    );
  }

  render() {
    const {
      redirect, loading, startDate, data,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const limitV1Param = this.params.limitV1;
    const limitV1 = Boolean(limitV1Param && (limitV1Param === 'true' || limitV1Param === true));

    const startDateParam = this.params.startDate;
    const formatStartDate = new Date(startDateParam === undefined ? startDate : startDateParam);

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Row className="py-3">
          <StoryCreationCheckBox
            handleChangeCheckBox={this.handleChangeCheckBox}
            params={this.params}
          />
          <Col className="text-right">
            {!loading
              && (
              <CSVLink
                filename={`Creating${limitV1 ? '-V1' : ''}-${moment()}.csv`}
                headers={this.headers}
                data={data}
              >
                Download CSV
              </CSVLink>
              )}
          </Col>
        </Row>
        <Row className="py-3">
          <span style={{ marginRight: '10px' }}>Start Date:</span>
          <DatePicker
            selected={formatStartDate}
            onChange={(date) => this.setStartDate(date)}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
          />
        </Row>
        {this.renderData()}
      </PageWrapper>
    );
  }
}
