import moment from 'moment';
import React, { Component } from 'react';
import {
  Card, Col, Form, Nav, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { FormatDate } from '../Book/FormatDate';
import { getUserDisplayName } from '../Users';

export class BookActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      csv_data: [],
      currentGroup: null,
      curated: false,
      instant: true,
      chapters: [],
      listActive: false,
      loading: true,
    };

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Story Activity',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadGroups();
  }

  loadGroups = () => {
    api.get('/v1/groups/chapters')
      .then((res) => {
        const groups = res.data.groups.filter((el) => ['active', 'submitted', 'approved', 'pending', 'live'].includes(el.type));
        const current = groups ? res.data.groups.find((el) => el.type === 'active') : null;
        this.setState({
          groups,
          currentGroup: current ? current.id : null,
          loading: false,
        });
        if (current) {
          this.loadChaptersList(current.id);
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  loadChaptersList = (group) => {
    const {
      curated,
      instant,
    } = this.state;

    if (!curated && !instant) {
      this.setState({
        chapters: [],
        listActive: true,
      }, () => {
        this.createCsvData();
      });
      return;
    }

    this.setState({
      loading: true,
    });
    const params = { groupId: group };
    if (!(curated && instant)) {
      params.original = curated ? 1 : 0;
    }
    api.get('/v1/chapters', { params })
      .then((res) => {
        this.setState({
          chapters: res.data.chapters,
          listActive: true,
          loading: false,
        }, () => {
          this.createCsvData();
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  changeGroup = (group) => {
    this.setState({
      currentGroup: group,
    });
    this.loadChaptersList(group);
  };

  updateList = () => {
    const { currentGroup } = this.state;

    if (!currentGroup) {
      return;
    }
    this.loadChaptersList(currentGroup);
  };

  createCsvData = () => {
    const csvData = [];
    const { chapters } = this.state;

    csvData.push(...chapters.map((el) => ({
      title: String(el.title),
      writer: String(getUserDisplayName(el.writer)),
      coAuthors: String(el.coAuthors ? el.coAuthors.map((author) => getUserDisplayName(author)).join(',') : ''),
      story: String(el.book ? el.book.title : ''),
      version: el.revision,
      modified: moment(el.updatedAt).format('YYYY-MM-DD hh:mm A'),
    })));
    this.setState({ csv_data: csvData });
  };

  renderGroups = () => {
    const {
      currentGroup,
      groups,
    } = this.state;

    return (
      <Nav
        variant="pills"
        activeKey={currentGroup}
        onSelect={(selectedKey) => this.changeGroup(selectedKey)}
      >
        {
          groups.map((obj, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Nav.Item key={i}>
              <Nav.Link eventKey={obj.id}>{obj.title}</Nav.Link>
            </Nav.Item>
          ))
        }
      </Nav>
    );
  };

  render() {
    const {
      loading,
      listActive,
      instant,
      currentGroup,
      curated,
      chapters,
      csv_data: csvData,
      groups,
    } = this.state;

    const group = groups.find((item) => Number(item.id) === Number(currentGroup));
    const headers = [
      { label: 'Title', key: 'title' },
      { label: 'Lead Author', key: 'writer' },
      { label: 'Co-Authors', key: 'coAuthors' },
      { label: 'Story', key: 'story' },
      { label: 'Version', key: 'version' },
      { label: 'Modified', key: 'modified' },
    ];

    const columns = [{
      dataField: 'title',
      text: 'Title',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cell, row) => (
        <Link
          className="books-list-link"
          to={`/stories/${row.id}/branches`}
        >
          {cell}
        </Link>
      ),
    }, {
      dataField: 'writer',
      text: 'Lead Author',
      sort: true,
      formatter: (cell) => getUserDisplayName(cell),
      sortValue: (cell) => getUserDisplayName(cell),
    }, {
      dataField: 'coAuthors',
      text: 'Co-Authors',
      sort: true,
      formatter: (cell) => cell.map((el) => getUserDisplayName(el)).join(','),
      sortValue: (cell) => cell.map((el) => getUserDisplayName(el)).join(','),
    }, {
      dataField: 'book',
      text: 'Story',
      sort: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cell) => (
        <Link
          className="books-list-link"
          to={`/book/${cell.id}`}
        >
          {cell.title}
        </Link>
      ),
      sortValue: (cell) => cell.title,

    }, {
      dataField: 'revision',
      text: 'Version',
      headerStyle: () => ({ width: '9%' }),
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cell) => <span className="d-inline-block p-1">{cell}</span>,

    }, {
      dataField: 'updatedAt',
      sort: true,
      text: 'Modified',
      // eslint-disable-next-line react/no-unstable-nested-components
      formatter: (cell) => <FormatDate className="d-inline-block p-1" date={cell} />,
    }];

    const defaultSorted = [{
      dataField: 'updatedAt',
      order: 'desc',
    }];

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card className={listActive ? 'd-block' : 'd-none'}>
          <Card.Body>
            {this.renderGroups()}
            <Row className="py-3">
              <Col md={3}>
                <Form.Check
                  custom
                  id="checkboxCurated"
                  type="checkbox"
                  checked={curated}
                  onChange={() => {
                    this.setState({
                      curated: !curated,
                    }, this.updateList);
                  }}
                  label="Premium IP"
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  custom
                  id="checkboxInstant"
                  type="checkbox"
                  checked={instant}
                  onChange={() => {
                    this.setState({
                      instant: !instant,
                    }, this.updateList);
                  }}
                  label="Instant"
                />
              </Col>
              <Col className="text-right">
                {(!loading && group && group.title) && (
                  <CSVLink
                    filename={`${group.title}${curated && '-Premium_IP'}${instant ? '-Instant' : ''}-${moment()}.csv`}
                    headers={headers}
                    data={csvData}
                  >
                    Download CSV
                  </CSVLink>
                )}
              </Col>
            </Row>
            {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <BootstrapTable bootstrap4 keyField="id" data={chapters} columns={columns} striped hover condensed bordered={false} defaultSorted={defaultSorted} />
                  )
}
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
