import serialize from 'form-serialize';
import React, { useCallback } from 'react';
import {
  Alert, Badge, Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { api } from '../api';
import { PageWrapper } from '../ui/PageWrapper';

function formatTitle(cell, obj) {
  return (
    <>
      <Link
        to={`/book/${obj.id}`}
        target="_blank"
      >
        {cell}
      </Link>
      {obj.isPrompt && <Badge variant="secondary" style={{ marginLeft: '.5em' }}>Prompt</Badge>}
    </>
  );
}

export function FeaturedStories(props) {
  const {
    history,
    auth,
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [alertText, setAlertText] = React.useState(null);
  const [books, setBooks] = React.useState([]);
  const [tableVal, setTableVal] = React.useState([]);
  const [tableC, setTableC] = React.useState(false);

  const loadData = useCallback(() => {
    setLoading(true);
    api.get('/v1/books/?featured=1')
      .then((res) => {
        const data = [];
        res.data.books.forEach((o) => {
          const authors = [];
          o.users.forEach((obj) => (obj.story_role === 'owner')
              && authors.push(obj.fullname));
          const val = {
            id: o.id,
            title: o.title,
            isPrompt: o.isPrompt,
            authors: authors[0],
            sort: o.data.sort,
            forYouSort: o.data.forYouSort,
            label: o.data.label,
            featuredEndDate: o.data.featuredEndDate
              ? new Date(o.data.featuredEndDate)
              : new Date(),
            addStartDate:
                o.data.featuredEndDate
                && !!(o.data.sort || o.data.forYouSort || o.data.label)
            ,
          };
          data.push(val);
        });
        setTableVal(data);
        setBooks(res.data.books);
      }).finally(() => setLoading(false));
  }, []);

  const showAlert = (status, text) => {
    setSuccess(status);
    setAlertText(text);
    setTimeout(() => {
      setAlertText(null);
      setLoading(false);
      if (status) loadData();
    }, 3000);
  };

  const page = {
    header: {
      title: 'Featured Stories',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
          href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
          disabled: false,
        },
      ],
    },
  };

  const users = auth.getUser();

  const saveData = (data) => {
    setLoading(true);
    const newData = {
      ...data,
    };
    Object.keys(data).forEach((key) => {
      const x = data[key];
      newData[key] = {
        ...x.sort && { sort: parseInt(x.sort, 10) },
        ...x.forYouSort && { forYouSort: parseInt(x.forYouSort, 10) },
        ...x.label && { label: x.label },
        ...x.featuredEndDate && { featuredEndDate: x.featuredEndDate },
      };
    });
    api.post('v1/prioritization/data/featured', newData)
      .then(() => {
        showAlert(true, 'Featured Stories Saved');
      })
      .catch((err) => {
        showAlert(false, err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const obj = serialize(form, { hash: true, empty: true });
    saveData(obj);
    e.stopPropagation();
  };

  React.useEffect(() => {
    loadData();
  }, [loadData]);

  const formDatePicker = (cell, obj) => {
    const setAddStartDate = (v, name) => {
      // eslint-disable-next-line no-param-reassign
      obj[name] = v;
      const d = [];
      tableVal.forEach((o) => {
        if (o.id === obj.id) {
          d.push(obj);
        } else {
          d.push(o);
        }
      });
      setTableVal(d);
      setTableC(!tableC);
    };

    if (
      !!obj.addStartDate && !!(obj.forYouSort || obj.label || obj.sort)
    ) {
      return (
        <div className="PFR-DatePicker-box">
          <DatePicker
            size="sm"
            className="form-control form-control-sm"
            disabled={loading}
            name={`${obj.id}[featuredEndDate]`}
            placeholder="End Date"
            selected={obj.featuredEndDate || new Date()}
            onChange={(date) => setAddStartDate(date, 'featuredEndDate')}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
          />
          <Button
            size="sm"
            className="PFR-DatePicker-close"
            onClick={() => setAddStartDate(false, 'addStartDate')}
          >
            <span>×</span>
          </Button>
        </div>
      );
    }
    return (
      <Button
        size="sm"
        onClick={() => setAddStartDate(true, 'addStartDate')}
        disabled={!!(
          !obj.forYouSort
          && !obj.label
          && !obj.sort
        )}
      >
        Add End Date
      </Button>
    );
  };

  const formControl = (cell, obj, name, placeholder, type) => (
    <Form.Control
      autoFocus={obj.focus}
      size="sm"
      type="text"
      placeholder={placeholder}
      disabled={loading}
      value={cell}
      onChange={(e) => {
        if (type === 'number') {
          // eslint-disable-next-line no-param-reassign
          obj[name] = e.target.value >= 1
            ? Number(Math.round(e.target.value))
            : '';
        } else {
          // eslint-disable-next-line no-param-reassign
          obj[name] = e.target.value;
        }
        // eslint-disable-next-line no-param-reassign
        obj.focus = true;
        const d = [];
        tableVal.forEach((o) => {
          if (o.id === obj.id) {
            d.push(obj);
          } else {
            d.push(o);
          }
        });
        setTableVal(d);
        setTableC(!tableC);
      }}
      name={`${obj.id}[${name}]`}
    />
  );

  const columns = [{
    dataField: 'title',
    text: 'Story',
    sort: true,
    formatter: formatTitle,
  },
  {
    dataField: 'authors',
    text: 'Authors',
    sort: true,
  },
  {
    dataField: 'sort',
    text: 'Discovery Rank',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formControl(cell, obj, 'sort', 'Discovery Rank', 'number'),
  },
  {
    dataField: 'forYouSort',
    text: 'For You Rank',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formControl(cell, obj, 'forYouSort', 'For You Rank', 'number'),
  },
  {
    dataField: 'label',
    text: 'Tag Text',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formControl(cell, obj, 'label', 'Tag Text', 'text'),
  },
  {
    dataField: 'featuredEndDate',
    text: 'End Date',
    sort: true,
    formatExtraData: { tableC, loading, tableVal },
    formatter: (cell, obj) => formDatePicker(cell, obj),
  },
  ];

  return (
    <PageWrapper
      {...props}
      page={page}
    >
      <Card className={users ? 'd-block' : 'd-none'}>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col>
                Featured Stories
              </Col>
            </Row>
          </Card.Title>

          <Alert
            variant={success ? 'success' : 'danger'}
            show={alertText != null}
            style={{ margin: '1em 2em' }}
          >
            {alertText}
          </Alert>

          {
              books && books.length > 0
                ? (
                  <div
                    style={{
                      padding: '1em 0',
                    }}
                  >
                    <Form
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <BootstrapTable
                        hover
                        striped
                        condensed
                        bootstrap4
                        keyField="id"
                        bordered={false}
                        columns={columns}
                        data={tableVal}
                      />

                      <div className="text-right">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={
                          loading
                          || (!books || books.length < 1)
                        }
                        >
                          {
                          loading
                          && (
                          <Spinner
                            size="sm"
                            animation="border"
                          />
                          )
                        }
                          Save
                        </Button>
                      </div>
                    </Form>
                  </div>
                )
                : (
                  <h2 style={{
                    fontSize: '1.2em',
                    padding: '.5em 2em 2em 2em',
                  }}
                  >
                    This Prompt does not have any spinoffs yet.
                  </h2>
                )
            }
        </Card.Body>
      </Card>
    </PageWrapper>
  );
}
