import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class AvatarAssetsDeleteValue extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
    };
  }

  deleteAction = () => {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.delete(`/v1/avatar/config/values/${this.props.data.id}`)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
          // eslint-disable-next-line react/destructuring-assignment
          this.props.onHide();
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    return (
      <Modal
        size="lg"
        // eslint-disable-next-line react/destructuring-assignment
        show={this.props.show}
        // eslint-disable-next-line react/destructuring-assignment
        onHide={this.props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete:
            {' '}
            { /* eslint-disable-next-line react/destructuring-assignment */ }
            {this.props.data.label}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            Do you really want to delete
            <b>
              { /* eslint-disable-next-line react/destructuring-assignment */ }
              {this.props.data.label}
              ?
            </b>
          </p>
        </Modal.Body>

        <Modal.Footer>
          <>
            <Button
              size="sm"
              type="reset"
              variant="secondary"
              className="mx-2"
              // eslint-disable-next-line react/destructuring-assignment
              disabled={this.state.loading}
              onClick={() => {
                // eslint-disable-next-line react/destructuring-assignment
                this.props.onHide();
              }}
            >
              Cancel
            </Button>

            <Button
              // eslint-disable-next-line react/destructuring-assignment
              disabled={this.state.loading}
              variant="primary"
              size="sm"
              type="submit"
              onClick={() => this.deleteAction()}
            >
              { /* eslint-disable-next-line react/destructuring-assignment */ }
              {this.state.loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Delete
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    );
  }
}
