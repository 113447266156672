import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert, Button, OverlayTrigger, Popover,
} from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import YouTube from 'react-youtube';
import { ReactComponent as AppStore } from '../../../assets/images/App_Store_Badge_US-UK.svg';
import pause from '../../../assets/images/pause.svg';
import play from '../../../assets/images/play.svg';
import redo from '../../../assets/images/redo.svg';
import { api } from '../../api';
import { LostPasswordForm } from '../../ui/LostPasswordForm/LostPasswordForm';
import { PageHeader } from '../../ui/PageHeader';
import { Credits } from './Credits/Credits';
import './Home.scss';
import { HomeLeftImages } from './HomeLeftImages';
import { HomeRightImages } from './HomeRightImages';
import { Invite } from './invite';
import { CongratulationsContent } from './PrivacyPolicy/CongratulationsContent';
import { CopyrightPolicyContent } from './PrivacyPolicy/CopyrightPolicyContent';
import { PrivacyPolicyContent } from './PrivacyPolicy/PrivacyPolicyContent';
import { TermsOfUseContent } from './PrivacyPolicy/TermsOfUseContent';
import { Reset } from './reset';

const popover = (
  <Popover id="popover-contact">
    <Popover.Content>
      <Button
        block
        variant="link"
        target="_blank"
        href="mailto: support@dorian.live"
      >
        Email us: support@dorian.live
      </Button>
    </Popover.Content>
  </Popover>
);

export class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: '',
      checkToken: '',
      checkTokenType: '',
      activeTabNumber: 1,
      hash: '',
      videoStatus: null,
      redirect: false,
    };
    this.cElement = null;
  }

  componentDidMount() {
    const { history } = this.props;

    this.checkToken();
    if (history.location.hash) {
      this.handleTab();
    }
  }

  componentDidUpdate() {
    const { history } = this.props;
    const { hash } = this.state;

    if (history.location.hash !== hash) {
      this.handleTab();
    }
  }

  handleTab = () => {
    const { history } = this.props;
    const tab = history.location.hash;
    let box;

    if (
      tab === '#info-collect'
            || tab === '#info-use'
            || tab === '#info-shared'
            || tab === '#cookies-tracking'
            || tab === '#social-logins'
            || tab === '#third-party'
            || tab === '#info-time-kept'
            || tab === '#info-safety'
            || tab === '#info-minors'
            || tab === '#privacy-rights'
            || tab === '#do-not-track'
            || tab === '#do-not-track'
            || tab === '#privacy-updates'
            || tab === '#contact') {
      box = 5;
    } else {
      switch (tab) {
        case '#lost-password':
          box = 4;
          break;
        case '#privacy-policy':
          box = 5;
          break;
        case '#':
          box = 6;
          break;
        case '#congratulations':
          box = 7;
          break;
        case '#terms-of-use':
          box = 8;
          break;
        case '#copyright-policy':
          box = 9;
          break;
        case '#credits':
          box = 10;
          break;
        default:
          box = 1;
      }
    }

    this.setState({
      activeTabNumber: box,
      hash: tab,
    });
  };

  checkToken = () => {
    const { location } = this.props;

    const values = queryString.parse(location.search);
    if (location.pathname === '/verify') {
      if (values.token) {
        const val = { token: values.token };
        this.setState({
          data: '',
          checkToken: 'Please wait',
          checkTokenType: 'warning',
        });
        api.post('v1/signup/verification', val)
          .then((res) => {
            this.setState({
              checkToken: res.data.message,
              checkTokenType: 'success',
            }, () => {
              setTimeout(() => {
                this.setState({
                  redirect: '/',
                });
              }, 5000);
            });
          })
          .catch((error) => {
            this.setState({
              checkToken: error?.response?.data?.error || error.message,
              checkTokenType: 'danger',
            }, () => {
              setTimeout(() => {
                this.setState({
                  redirect: '/#',
                });
              }, 5000);
            });
          });
      }
    }
  };

  render() {
    const { location } = this.props;
    const {
      checkTokenType,
      data,
      activeTabNumber,
      videoStatus,
      redirect,
      checkToken: checkToken1,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const onReady = (event) => {
      this.cElement = event;
    };

    return (
      <div
                /* eslint-disable-next-line no-nested-ternary */
        className={`HomePage active-rightBox-${activeTabNumber} ${activeTabNumber === 4
          ? 'HomePage-sm'
          : activeTabNumber === 1
            ? 'HomePage-sm-1'
            : ''}`}
      >
        <div className="HomePageWrapper">
          <PageHeader
            rightBox={activeTabNumber}
          />

          <div className="mainPage">
            {
                location.pathname === '/reset'
                || location.pathname === '/setpassword'
                || location.pathname === '/invite'

                  ? (
                    <>
                      <Reset
                        {...this.props}
                      />

                      <Invite
                        {...this.props}
                      />
                    </>
                  )
                  : (
                    <>
                      <div className={`mainPage-content ${activeTabNumber === 1 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-1">
                          <div className="">

                            <div className={`videoYouTube-Box ${videoStatus}`}>
                              {/* eslint-disable-next-line max-len */}
                              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                              <div
                                className="videoYouTube-img-box"
                                onClick={() => {
                                  this.cElement.target.playVideo();
                                }}
                              >
                                <img
                                  className="videoYouTube-img"
                                  src="https://img.youtube.com/vi/92eC9kWLZb0/mqdefault.jpg"
                                  alt=""
                                />
                                <div className="videoYouTube-nav play">
                                  <img src={play} alt="play" />
                                </div>
                                <div className="videoYouTube-nav pause">
                                  <img src={pause} alt="pause" />
                                </div>
                                <div className="videoYouTube-nav end">
                                  <img src={redo} alt="redo" />
                                </div>
                              </div>
                              <YouTube
                                opts={{ playerVars: { autoplay: 0 } }}
                                onReady={onReady}
                                onEnd={() => {
                                  this.setState({
                                    videoStatus: 'end',
                                  });
                                }}
                                onPlay={() => {
                                  this.setState({
                                    videoStatus: 'play',
                                  });
                                }}
                                onPause={() => {
                                  this.setState({
                                    videoStatus: 'pause',
                                  });
                                }}
                                className="videoYouTube"
                                videoId="92eC9kWLZb0"
                              />
                            </div>

                            <h4 className="YouTube-text-1">
                              Download Dorian to enjoy hundreds of interactive stories!
                            </h4>
                            <div className="badge-row">
                              <a href="https://apps.apple.com/us/app/dorian-the-future-of-fiction/id1529215455">
                                <AppStore role="img" alt="Get it on App Store" />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.dorian.playtogether"
                              >
                                <img
                                  alt="Get it on Google Play"
                                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                />
                              </a>
                            </div>

                            <h4 className="text-center pb-3 YouTube-text YouTube-text-2">
                              {/* eslint-disable-next-line max-len */}
                              Want to create engaging interactive stories fast, connect with your fans and make money from
                              your hobby?
                            </h4>

                            <p className="text-center">
                              <Button
                                className="btnMainPage"
                                variant="outline-primary"
                                href="/write-with-us-app"
                              >
                                Get exclusive writer access!
                              </Button>
                            </p>

                          </div>
                        </div>
                      </div>

                      <div className={`mainPage-content ${activeTabNumber === 7 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-7">
                          <div className="">
                            <Button
                              className="closeBtn"
                              variant="outline-primary"
                              as={Link}
                              to="/setpassword"
                            >
                              X
                            </Button>
                            <div className="mainPage-right-form-box-content">
                              <div className="CongratulationsContent">
                                <CongratulationsContent />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`mainPage-content ${activeTabNumber === 4 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-4">
                          <div className="">

                            <Button
                              className="closeBtn"
                              variant="outline-primary"
                              href="/#"
                            >
                              X
                            </Button>
                            <div className="mainPage-right-form-box-content">
                              <h2 className="text-center py-3 modal-title">Lost Password</h2>
                              <LostPasswordForm
                                {...this.props}
                                type="tab"
                              />
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className={`mainPage-content ${activeTabNumber === 5 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-5">
                          <div className="">

                            <div className="mainPage-right-text-box-title">
                              <Button
                                className="closeBtn"
                                variant="outline-primary"
                                href="/#"
                              >
                                X
                              </Button>

                              <Button
                                className="backBtn"
                                variant="outline-primary"
                                href="/write-with-us-app"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.history.back();
                                }}
                              >
                                {' '}
                                {'< Back'}
                                {' '}

                              </Button>

                              <h2 className="text-center py-3 modal-title">PRIVACY POLICY</h2>
                            </div>

                            <div className="mainPage-right-text-box-content">
                              <PrivacyPolicyContent
                                rightBox={activeTabNumber}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className={`mainPage-content ${activeTabNumber === 8 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-5">
                          <div className="">

                            <div className="mainPage-right-text-box-title">
                              <Button
                                className="closeBtn"
                                variant="outline-primary"
                                href="/#"
                              >
                                X
                              </Button>

                              <Button
                                className="backBtn"
                                variant="outline-primary"
                                href="/write-with-us-app"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.history.back();
                                }}
                              >
                                {' '}
                                {'< Back'}
                                {' '}

                              </Button>

                              <h2 className="text-center py-3 modal-title">TERMS OF USE</h2>
                            </div>

                            <div className="mainPage-right-text-box-content">
                              <TermsOfUseContent
                                rightBox={activeTabNumber}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className={`mainPage-content ${activeTabNumber === 9 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-5">
                          <div className="">

                            <div className="mainPage-right-text-box-title">
                              <Button
                                className="closeBtn"
                                variant="outline-primary"
                                href="/#"
                              >
                                X
                              </Button>

                              <Button
                                className="backBtn"
                                variant="outline-primary"
                                href="/write-with-us-app"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.history.back();
                                }}
                              >
                                {' '}
                                {'< Back'}
                                {' '}

                              </Button>

                              <h2 className="text-center py-3 modal-title">COPYRIGHT POLICY</h2>
                            </div>

                            <div className="mainPage-right-text-box-content">
                              <CopyrightPolicyContent
                                rightBox={activeTabNumber}
                              />
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className={`mainPage-content ${activeTabNumber === 6 ? 'active' : ''}`}>
                        <div className="mainPage-content-wrapper slide-6">
                          <div className="" />
                        </div>
                      </div>

                      <div className={`mainPage-content ${activeTabNumber === 10 ? 'active' : ''}`}>
                        <Credits
                          onBackClick={() => window.history.back()}
                        />
                      </div>

                      <HomeLeftImages
                        rightBox={activeTabNumber}
                      />

                      <HomeRightImages
                        rightBox={activeTabNumber}
                      />

                      <div className="mainPage-AlertBox">
                        <Alert show={!!data} variant="success" className="my-5">
                          {data}
                        </Alert>

                        <Alert
                          show={!!checkToken1}
                          variant={checkTokenType}
                          className="my-5 emailVerifiedAlert"
                        >
                          {checkToken1}
                        </Alert>

                      </div>

                    </>
                  )
            }
          </div>

          <div className="HomePageFooterBg" />
          <footer className="HomePageFooter text-center">
            <Button
              variant="link"
              href="/#privacy-policy"
            >
              Privacy Policy
            </Button>
            <Button
              variant="link"
              href="/#credits"
            >
              Credits
            </Button>
            <Button
              variant="link"
              href="/#copyright-policy"
            >
              Copyright Policy
            </Button>
            <Button
              variant="link"
              href="/#terms-of-use"
            >
              Terms of Use
            </Button>
            <OverlayTrigger
              trigger="click"
              placement="top"
              overlay={popover}
              rootClose
            >
              <Button
                variant="link"
              >
                Contact Us
              </Button>
            </OverlayTrigger>
          </footer>
        </div>
      </div>
    );
  }
}
