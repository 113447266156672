import React, { Component } from 'react';
import {
  Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';
import Moment from 'react-moment';
import CheckIconG from '../../../assets/images/check-g.svg';
import CheckIcon from '../../../assets/images/check.svg';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { ApproveUser } from './ApproveUser';
import { CheckedApprove } from './CheckedApprove';
import './Users.css';
import { UsersTableRow } from './UsersTableRow';

function fullNameFormatter(cell, row) {
  return (
    <>
      <OverlayTrigger
        overlay={(
          <Tooltip id="tooltip-top">
            {row.receive_email ? 'receive updates' : 'not receive updates'}
          </Tooltip>
            )}
      >
        <img src={row.receive_email ? CheckIconG : CheckIcon} className="UserMailIcon" alt="" />
      </OverlayTrigger>
      {cell}
    </>
  );
}

const dateFormatter = (cell) => (
  <Moment
    element="span"
    format="YYYY-MM-DD hh:mm A"
    date={cell}
  />
);

export class UsersNotApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      listUsersActive: false,
      loading: true,
      checkedApprove: [],
      modalApproveUserShow: false,
    };

    const { history, auth } = this.props;
    this.user = auth.getUser();

    this.page = {
      header: {
        title: 'Users',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      listUsersActive: true,
      loading: this.user.adminShowApproved,
    });

    if (this.user.adminShowApproved) {
      this.loadUsersList();
    }
  }

  updateApprove = (val) => {
    this.setState({
      checkedApprove: val,
    });
  };

  loadUsersList = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/users?filter=notapproved')
      .then((res) => {
        this.setState({
          users: res.data.users,
          listUsersActive: true,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      listUsersActive, users, loading, checkedApprove,
    } = this.state;

    const approveUser = () => {
      const { modalApproveUserShow, checkedApprove: _checkedApprove } = this.state;

      if (modalApproveUserShow !== true) {
        return null;
      }

      return (
        <ApproveUser
          show={modalApproveUserShow}
          update={this.loadUsersList}
          onHide={() => {
            this.setState({
              modalApproveUserShow: false,
            });
          }}
          obj={_checkedApprove}
        />
      );
    };

    const userRowFormatter = (cell, row) => {
      const updateUsers = () => {
        this.loadUsersList();
      };
      return (
        <UsersTableRow
          obj={row}
          update={updateUsers}
        />
      );
    };

    const approveFormatter = (cell, row) => (
      <CheckedApprove
        checkedApprove={checkedApprove}
        row={row}
        update={this.updateApprove}
      />
    );

    const columns = [
      {
        dataField: 'fullname',
        sort: false,
        text: 'Name',
        formatter: fullNameFormatter(),
      }, {
        dataField: 'email',
        sort: false,
        text: 'Email',
      }, {
        dataField: 'country',
        sort: false,
        text: 'Country',
      }, {
        dataField: 'social_media',
        sort: false,
        text: 'Social Media Handle',
      }, {
        dataField: 'createdAt',
        sort: true,
        text: 'Signup Date',
        type: 'date',
        formatter: dateFormatter,
      }, {
        dataField: 'id',
        sort: false,
        text: '',
        formatter: userRowFormatter,
      }, {
        dataField: 'row',
        sort: false,
        text: 'Approve',
        className: 'text-center',
        style: { textAlign: 'center' },
        formatExtraData: checkedApprove,
        formatter: approveFormatter,
      },
    ];

    const expandRow = {
      renderer: (row) => {
        const yourWriting = row.yourWriting && JSON.parse(row.yourWriting);
        return (
          <div>
            <Row>
              {row.device && (
              <Col>
                <p>
                  <b>Device:</b>
                  {' '}
                  {row.device}
                </p>
              </Col>
              )}
            </Row>

            {yourWriting && (
            <p>
              <b>Where can we see your writing:</b>
              {yourWriting.map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <i key={i}>
                  {' '}
                  {e}
                  ,
                  {' '}
                </i>
              ))}
            </p>
            )}

          </div>
        );
      },
      showExpandColumn: true,
    };

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card className={listUsersActive ? 'd-block' : 'd-none'}>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>
                  List of Not Approved Users
                </Col>
                <Col className="text-right">
                  {this.user.adminShowApproved
                    && <CSVLink data={users}>Download CSV</CSVLink>}
                </Col>
              </Row>
            </Card.Title>
            {loading
              ? (
                <div className="text-center">
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="loadingSpinner justify-content-center"
                  />
                </div>
              ) : (
                <>
                  <BootstrapTable
                    hover
                    striped
                    bootstrap4
                    keyField="id"
                    bordered={false}
                    columns={columns}
                    expandRow={expandRow}
                    data={users}
                  />
                  {checkedApprove.length > 0 && (
                    <div
                      style={{ textAlign: 'right' }}
                    >
                      <Button
                        onClick={() => this.setState({ modalApproveUserShow: true })}
                        variant="secondary"
                        size="sm"
                        className="mx-1"
                      >
                        Approval of all selected users
                      </Button>
                    </div>
                  )}
                  {approveUser()}
                </>
              )}
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
