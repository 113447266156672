export function getDocumentScroll() {
  const documentScrollTop = Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop,
  );
  const documentScrollLeft = Math.max(
    window.pageXOffset,
    document.documentElement.scrollLeft,
    document.body.scrollLeft,
  );
  return { documentScrollTop, documentScrollLeft };
}

export function getBoundsForNode(node, containerScroll) {
  if (!containerScroll) {
    // eslint-disable-next-line no-param-reassign
    containerScroll = { scrollTop: 0, scrollLeft: 0 };
  }
  const { scrollTop } = containerScroll;
  const { scrollLeft } = containerScroll;

  const documentScroll = getDocumentScroll();
  const { documentScrollTop } = documentScroll;
  const { documentScrollLeft } = documentScroll;
  const rect = node.getBoundingClientRect();
  return {
    top: rect.top + documentScrollTop + scrollTop,
    left: rect.left + documentScrollLeft + scrollLeft,
    offsetWidth: node.offsetWidth,
    offsetHeight: node.offsetHeight,
    width: rect.width,
    height: rect.height,
  };
}
