import { BranchStep } from '@dorian/creation-tools-ui';
import { Character } from '../../../../dorian-shared/types/character/Character';
import { ChoiceMemory } from '../../../../services/choiceMemory/ChoiceMemory';

interface OnBeforeTextRenderParams {
  choiceMemory: ChoiceMemory | undefined;
  characters?: Character[];
  text: string;
  step: BranchStep;
}

export function onBeforeTextRender(
  {
    choiceMemory, characters, text, step,
  }: OnBeforeTextRenderParams,
) {
  const vars = choiceMemory?.extractVariablesFromString(text);
  if (vars?.length) {
    let newText = text;
    vars.forEach((variableName) => {
      let value;
      // Used getVariableFromStackByVariableName because onBeforeTextRender
      // is called before onNavHistoryStackChanged
      const variable = choiceMemory?.getVariableFromLastStackItem(step.id, variableName)
          ?? choiceMemory?.getVariableByName(variableName);
      if (variable) {
        switch (variable.type) {
          case 'character': {
            if (characters) {
              const character = characters?.find((c) => Number(c.id) === Number(variable.value));
              if (character) {
                // Now we use only the name of the character,
                // because we don't know how to get host character var
                value = character.name;
              }
            } else {
              value = 'N/A yet';
            }
            break;
          }
          case 'boolean':
            value = variable.value === 'true' ? 'Yes' : 'No';
            break;
          case 'number':
            value = variable.value?.toString();
            break;
          default:
            value = variable.value;
            break;
        }

        if (value !== undefined) {
          newText = newText.replace(`{${variableName}}`, String(value));
        }
      }
    });
    return newText;
  }
  return text;
}
