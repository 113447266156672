import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class ChapterRestoreModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    const {
      obj,
      update,
      book,
      onHide,
    } = this.props;

    api.post(`/v1/books/${book.id}/chapters/${obj.id}/restoreactive`)
      .then(() => {
        this.setState(
          { loading: false },
          () => {
            update();
            onHide();
          },
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const {
      show,
      obj,
      onHide,
    } = this.props;

    const { loading } = this.state;

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Restore this version (#
            {obj.revision}
            ) for &quot;
            {obj.title}
            &quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center">
            {/* eslint-disable-next-line max-len */}
            Performing this action will overwrite your active version of this episode with this version. This action
            can&apos;t be undone. Do you want to proceed?
          </h5>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.loadData()}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Restore
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
