import React from 'react';
// @ts-ignore: no def file
import { RouteComponentProps } from 'react-router';
// @ts-ignore: no def file
import { Route, Switch } from 'react-router-dom';
import { EventItemPage } from './EventItemPage/EventItemPage';
import { EventListPage } from './EventListPage/EventListPage';
import { NewEventItemPage } from './NewEventItemPage/NewEventItemPage';

export function EventsRouter(props: RouteComponentProps) {
  const { match } = props;
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        component={EventListPage}
      />
      <Route
        exact
        path={`${match.path}/new`}
        component={NewEventItemPage}
      />
      <Route
        path={`${match.path}/:id`}
        component={EventItemPage}
      />
    </Switch>
  );
}
