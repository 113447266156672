import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { auth } from '../../Auth/Auth';
import { InputFormField } from '../InputFormField/InputFormField';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './SetPasswordForm.module.scss';
import { SetPasswordFormValues, useSetPasswordForm } from './useSetPasswordForm';
import { useSetPasswordFormParams } from './useSetPasswordFormParams';

// This message require a design and QA
export const INVALID_PASSWORD_MESSAGE = 'Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number and one special character.';

export function SetPasswordForm() {
  const {
    isLoading,
    isError,
    statusMessage,
    handleSubmit,
    setToError,
  } = useSetPasswordForm();

  const formikRef = useRef<FormikProps<SetPasswordFormValues>>(null);
  const {
    setPasswordInitialValues, code, username, isParameterValidEmail,
  } = useSetPasswordFormParams();

  const setPasswordValidationSchema = Yup.object().shape({
    code: Yup.string()
      .required('Please enter a code.')
      .min(6, 'Please enter a valid code.')
      .max(6, 'Please enter a valid code.'),
    username: isParameterValidEmail
      ? Yup.string()
        .email('Please enter a valid email.')
        .required('Please enter a valid email.')
      : Yup.string()
        .required('Please enter a valid username.'),
    password: Yup.string()
      .required('Please enter a password.')
      .min(8, INVALID_PASSWORD_MESSAGE)
      .matches(/[a-z]/, INVALID_PASSWORD_MESSAGE)
      .matches(/[A-Z]/, INVALID_PASSWORD_MESSAGE)
      .matches(/\d/, INVALID_PASSWORD_MESSAGE)
      .matches(/[!@#$%^&*(),.?":{}|<>]/, INVALID_PASSWORD_MESSAGE),
    confirmPassword: Yup.string()
      .required('Please confirm your password.')
      .oneOf([Yup.ref('password')], 'Passwords do not match.  Please check and try again.'),
  });

  const resendCode = async () => {
    const userName = formikRef.current?.values.username ?? '';
    if (userName) {
      try {
        await userApi.reset({ username: userName, mobile: false });
        setToError('New code sent to your email.');
      } catch (e) {
        const message = auth.getErrorMessage(e) ?? 'Error occurred while trying to resend code.';
        setToError(message);
        logger.error(message);
      }
    } else {
      setToError('Email or username is required.');
    }
  };

  return (
    <Container>
      <Row>
        {isError && (
          <Alert variant="danger" className={styles.formAlert}>
            {statusMessage}
          </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <h3 className={styles.formTitle}>Set Your Password</h3>
          <Formik
            innerRef={formikRef}
            initialValues={setPasswordInitialValues}
            validationSchema={setPasswordValidationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} label="Code" name="code" type="text" />
              <InputFormField disabled={isLoading} hidden={username} label={isParameterValidEmail ? 'Email Address' : 'Username'} name="username" type="email" />
              <InputFormField disabled={isLoading} label="Password" name="password" type="password" autoComplete="on" />
              <InputFormField disabled={isLoading} label="Confirm password" name="confirmPassword" type="password" autoComplete="on" />
              <SubmitButton label="Submit" isLoading={isLoading} />
            </Form>
          </Formik>
        </Col>
      </Row>
      {!code && (
        <div className={styles.formSubText}>
          <span>Don’t get a code? </span>
          <button
            disabled={isLoading}
            type="button"
            onClick={() => resendCode()}
          >
            Try again.
          </button>
        </div>
      )}
    </Container>
  );
}
