import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export class AvatarToolTr extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      obj, forced, id, title, itemPickerShow,
    } = this.props;

    const val = [];
    val.id = id;
    val.obj = obj;
    const convertedForced = !!forced[obj.id];
    return (
      <div className={`catBtnContainer p-2 ${convertedForced && 'catBtnContainer-forced'}`}>
        <Button
          block
          variant="primary"
          onClick={() => {
            itemPickerShow(val);
          }}
        >
          {title}
          {convertedForced && <span className="forced">Forced</span>}
        </Button>
      </div>
    );
  }
}
