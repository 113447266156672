import {
    getMemoriesByStoryFlow,
    type StoryFlow,
    type StoryFlowDraft
} from './AnalyseStoryFlowsEconomyManager';
import {Branches, MemoryBank, StepType, Story} from './bookEconomy';
import {applyMemoryAction, evaluateCheck, selectBranchFromSwitches} from './index';

export const isAnswerPaid = (type: string): boolean => ['erotic', 'romantic', 'paid'].includes(type);

export function* iterateFlows(
    story: Story,
    storyBranches: Branches,
    affectionPointsMemories: string[],
    initialMemoryBank: MemoryBank,
    onlyFree: boolean
): Generator<StoryFlow> {
    if (storyBranches.intro === undefined) {
        return;
    }

    let queue: StoryFlowDraft[] = [];
    queue.push({
        branches: ['intro'],
        memoryBank: initialMemoryBank,
        paid: false,
        isDone: false,
    });
    let iter = 0;
    let alive = queue.length;

    do {
        for (const flow of queue) {
            if (flow.isDone) {
                continue;
            }
            iter++;
            if (iter % 1000 === 0) {
                // clean memory
                queue = queue.filter(flow => !flow.isDone);
                alive = queue.length;
                // const heapSize = process.memoryUsage().heapUsed / 1024 / 1024;
                // const paidCount = queue.filter(flow => flow.paid).length;
                // console.log(`iteration: ${iter}; queue size: ${queue.length}; paid %: ${(paidCount / queue.length * 100).toFixed(0)}%; memory heap size:', ${heapSize.toFixed(2)}MB`);
            }
            const branchName = flow.branches[flow.branches.length - 1];
            const branch = storyBranches[branchName];
            if (!branch) {
                flow.isDone = true;
                alive--;
                continue;
            }
            const firstStep = branch.steps[0];
            if (!firstStep) {
                flow.isDone = true;
                alive--;
                continue;
            }

            switch (firstStep.type) {
                case StepType.Ending:
                    flow.isDone = true;
                    let {paid, branches} = flow;
                    const memories = getMemoriesByStoryFlow(story, branches, affectionPointsMemories);
                    yield {
                        branches,
                        memories,
                        paid,
                    };
                    alive--;
                    continue;
                case StepType.Choice: {
                    const answers = firstStep.answers;
                    if (!answers) {
                        flow.isDone = true;
                        alive--;
                        continue;
                    }
                    const nextBranches = answers.map(answer => {
                        const requirement = answer.requirement;
                        if (requirement?.check) {
                            if (!evaluateCheck(flow.memoryBank!, requirement.check)) {
                                return undefined;
                            }
                        }
                        const branch = answer.goto?.branch;
                        if (branch && storyBranches[branch]) {
                            const paid = isAnswerPaid(answer.type);
                            return {branch, paid};
                        }
                        return undefined;
                    })
                        .filter(br => br !== undefined && !flow.branches.includes(br.branch)) as {branch: string, paid: boolean}[];

                    const newFlows: StoryFlowDraft[] = nextBranches
                        .filter(({paid}) => onlyFree ? !paid : true)
                        .map(({branch, paid}) => ({
                            branches: [...flow.branches, branch],
                            memoryBank: {...flow.memoryBank},
                            paid: flow.paid || paid,
                            isDone: false,
                        }));

                    if (newFlows.length > 0) {
                        queue.push(...newFlows);
                        alive += newFlows.length;
                    }
                    flow.isDone = true;
                    alive--;
                    continue;
                }
                case StepType.Check: {
                    const {switch: switches, check} = firstStep;
                    if (!switches || !check) {
                        flow.isDone = true;
                        alive--;
                        continue;
                    }

                    const nextBranchName = selectBranchFromSwitches(flow.memoryBank!, check, switches);
                    if (nextBranchName && storyBranches[nextBranchName] && !flow.branches.includes(nextBranchName)) {
                        flow.branches.push(nextBranchName);
                    } else {
                        flow.isDone = true;
                        alive--;
                    }
                    continue;
                }
            }

            for (const step of branch.steps) {
                if (step.type === StepType.Remember) {
                    if (step.action) {
                        applyMemoryAction(flow.memoryBank!, step.action);
                    }
                }
            }

            const lastStep = branch.steps[branch.steps.length - 1];
            const nextBranch = lastStep.goto?.branch;
            if (nextBranch && !flow.branches.includes(nextBranch)) {
                flow.branches.push(nextBranch);
            } else {
                flow.isDone = true;
                alive--;
            }
        }
    } while (alive > 0);
}
