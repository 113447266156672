import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import { DeleteStatType } from './DeleteStatType';
import styles from './StatTypes.css';

const cs = classNames.bind(styles);

export class StatTypesTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const { update, actionEditStatType, obj } = this.props;
    const { modalDeleteShow } = this.state;

    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      update();
    };

    return (
      <tr>
        <td>
          {obj.title}
        </td>
        <td>
          {obj.value}
        </td>
        <td>
          {obj.trait}
          <div className={cs(obj.trait === true ? 'checked' : null, 'circleBox')} />
        </td>
        <td className="text-right">
          <Button
            onClick={() => actionEditStatType(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEdit} className="btnImg" alt="Edit" />
          </Button>
          <Button
            onClick={() => this.setState({ modalDeleteShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
          {modalDeleteShow && (
          <DeleteStatType
            show={modalDeleteShow}
            onHide={modalDeleteClose}
            obj={obj}
          />
          )}
        </td>
      </tr>
    );
  }
}
