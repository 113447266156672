import { isArray } from 'lodash-es';
import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { showToast } from '../../components/ui/utils';
import { IPerformanceRankDTO } from '../../dorian-shared/types/performanceRank/performanceRank';
import { useApiService } from '../ApiServiceContext/ApiServiceContext';

type PerformanceRanksContextType = {
  episodeId: number,
  episodeTitle: string,
  isLoading: boolean,
  setIsLoading: (value: boolean) => void,
  performanceRanks: IPerformanceRankDTO[],
  fetchPerformanceRanks: () => Promise<IPerformanceRankDTO[]>,
  postPerformanceRanks: (performanceRank: IPerformanceRankDTO[]) => Promise<void>,
};

const initialContext: PerformanceRanksContextType = {
  episodeId: -1,
  episodeTitle: '',
  isLoading: false,
  setIsLoading: () => null,
  performanceRanks: [],
  fetchPerformanceRanks: () => Promise.reject('Not initialized'),
  postPerformanceRanks: () => Promise.reject('Not initialized'),
};

export const PerformanceRanksContext = createContext<PerformanceRanksContextType>(initialContext);

export function PerformanceRanksContextProvider({ children, episodeId, episodeTitle }: { children: ReactNode, episodeId: number, episodeTitle: string }) {
  const [isLoading, setIsLoading] = useState(false);
  const [performanceRanks, setPerformanceRanks] = useState<IPerformanceRankDTO[]>([]);

  const apiService = useApiService();

  const fetchPerformanceRanks = useCallback(async () => {
    const response = await apiService.fetchPerformanceRanksByEpisodeId(episodeId);
    if (!isArray(response)) {
      throw new Error('Invalid performance ranks response');
    }
    setPerformanceRanks(response);
    return response;
  }, [apiService, episodeId]);

  const postPerformanceRanks = useCallback(async (values: IPerformanceRankDTO[]) => {
    await apiService.postPerformanceRank(episodeId, values);
  }, [apiService, episodeId]);

  useEffect(() => {
    if (!episodeId) {
      return;
    }
    setIsLoading(true);
    fetchPerformanceRanks()
      .catch(() => {
        showToast({ textMessage: 'Failed to fetch Performance Ranks' });
      })
      .finally(() => setIsLoading(false));
  }, [episodeId, fetchPerformanceRanks]);

  const value: PerformanceRanksContextType = useMemo(() => ({
    episodeId,
    episodeTitle,
    isLoading,
    setIsLoading,
    performanceRanks,
    fetchPerformanceRanks,
    postPerformanceRanks,
  }), [isLoading, performanceRanks, fetchPerformanceRanks, postPerformanceRanks]);

  return (
    <PerformanceRanksContext.Provider value={value}>
      {children}
    </PerformanceRanksContext.Provider>
  );
}

export const usePerformanceRanksContext = () => useContext(PerformanceRanksContext);
