import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import imgInfo from '../../../assets/images/info.svg';
import { DeletePrompt } from './DeletePrompt';
import { DetailsPrompt } from './DetailsPrompt';
import { PromptCover } from './PromptCover';
import styles from './Templates.css';

const cs = classNames.bind(styles);

export class PromptTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteShow: false,
      modalDetailsShow: false,
      showPromptCover: false,
    };
  }

  render() {
    const {
      actionUpdate,
      obj,
      user,
      actionEdit,
    } = this.props;

    const {
      modalDeleteShow,
      showPromptCover,
      modalDetailsShow,
    } = this.state;

    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      actionUpdate();
    };

    const deleteRow = () => {
      if (modalDeleteShow === true) {
        return (
          <DeletePrompt
            show={modalDeleteShow}
            onHide={modalDeleteClose}
            obj={obj}
          />
        );
      }
      return null;
    };

    const modalDetailsClose = () => {
      this.setState({ modalDetailsShow: false });
    };

    const renderPromptCover = () => {
      if (showPromptCover) {
        return (
          <PromptCover
            user={user}
            book={obj}
            show={showPromptCover}
            onHide={() => {
              this.setState({
                modalDetailsShow: true,
                showPromptCover: false,
              });
            }}
            update={() => {
              actionUpdate();
            }}
          />
        );
      }
      return null;
    };

    const rowDetails = () => {
      if (modalDetailsShow === true) {
        return (
          <DetailsPrompt
            show={modalDetailsShow}
            onHide={modalDetailsClose}
            obj={obj}
            editCover={() => {
              this.setState({
                modalDetailsShow: false,
                showPromptCover: true,
              });
            }}
          />
        );
      }
      return null;
    };

    return (
      <tr>
        <td>
          {obj.originalBook ? (
            <Link
              className="books-list-link"
              style={{ overflowWrap: 'break-word' }}
              to={`/book/${obj.originalBook.id}`}
            >
              {obj.originalBook.title}
            </Link>
          ) : null}
        </td>
        <td>
          {obj.title}
        </td>
        <td>
          {obj.description}
        </td>
        <td>
          {obj.disabled}
          <div className={cs(obj.disabled === true ? 'checked' : null, 'circleBox')} />
        </td>
        <td>
          {obj.activeContest}
          <div className={cs(obj.activeContest === true ? 'checked' : null, 'circleBox')} />
        </td>
        <td>
          {obj.trending}
          <div className={cs(obj.trending === true ? 'checked' : null, 'circleBox')} />
        </td>
        <td>
          {obj.priority}
        </td>
        <td className={cs('text-right')}>
          <Button
            onClick={() => this.setState({ modalDetailsShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgInfo} className="btnImg" alt="Details" />
          </Button>
          <Button
            onClick={() => actionEdit(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEdit} className="btnImg" alt="Edit" />
          </Button>
          <Button
            onClick={() => this.setState({ modalDeleteShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
          {deleteRow()}
          {rowDetails()}
          {renderPromptCover()}
        </td>
      </tr>
    );
  }
}
