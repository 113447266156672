import React, { Component } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

// eslint-disable-next-line react/prefer-stateless-function
export class Loading extends Component {
  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Modal.Body>
          <div className="text-center">
            <Spinner
              variant="primary"
              animation="border"
              className="justify-content-center"
            />
            <div>Copying…</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
