import async from 'async';
import React, { PureComponent } from 'react';
import {
  Button, Card, Spinner, Table,
} from 'react-bootstrap';
import { api } from '../../api';
import { ValuesFormImageModal } from './ValuesFormImageModal';

export class Validate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      loading: false,
      img: null,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    }, () => {
      this.loadData();
    });
  }

  loadData() {
    async.parallel({
      errors: (callback) => {
        api.get('/v1/avatar/config/validate')
          .then((res) => {
            callback(null, res.data.errors);
          }).catch((error) => {
            callback(error, null);
          });
      },
      config: (callback) => {
        api.get('/v1/avatar/config')
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
            errors: res.errors,
            imageUrl: res.config.imageUrl,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  renderActions(error) {
    if (error.error === 'missed') {
      return (
        <Button
          // eslint-disable-next-line react/destructuring-assignment
          disabled={this.props.loading}
          size="sm ml-1"
          onClick={() => {
            this.setState({
              img: error.path,
            });
          }}
        >
          Edit
        </Button>
      );
    } if (error.error === 'unused') {
      // let src = `${this.state.imageUrl}${error.path}`;
      // console.log(src);
    }
    return null;
  }

  render() {
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Title>Error images:</Card.Title>
            {
              // eslint-disable-next-line react/destructuring-assignment
              this.state.loading
                ? (
                  <div className="text-center">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="loadingSpinner justify-content-center"
                    />
                  </div>
                )
                : (
                  <Table striped bordered hover>
                    <tbody>
                      {
                    // eslint-disable-next-line react/destructuring-assignment
                    this.state.errors.map((data) => (
                      <tr key={data.id}>
                        <td>
                          {data.error}
                        </td>
                        <td>
                          {data.path}
                        </td>
                        <td className="text-center">
                          {this.renderActions(data)}
                        </td>
                      </tr>
                    ))
                  }
                    </tbody>
                  </Table>
                )
            }
          </Card.Body>
        </Card>

        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.img
          && (
          <ValuesFormImageModal
            src={{
              // eslint-disable-next-line react/destructuring-assignment
              id: this.state.img,
              // eslint-disable-next-line react/destructuring-assignment
              src: `${this.state.imageUrl}${this.state.img}`,
            }}
            // eslint-disable-next-line react/destructuring-assignment
            show={!!this.state.img}
            onHide={
              () => {
                this.setState({
                  img: null,
                });
              }
            }
          />
          )
        }
      </>
    );
  }
}
