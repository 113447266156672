import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../../assets/images/dorian-white.svg';
import './PrivacyPolicyPage.scss';
import { TermsOfUseContent } from './TermsOfUseContent';

export class TermsOfUsePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="privacy-policy-page">
        <Container className="">
          <div className="logo">
            <Logo />
          </div>
          <h2 className="privacy-policy-page-title">TERMS OF USE</h2>
          <TermsOfUseContent type="external" />
        </Container>
      </div>
    );
  }
}
