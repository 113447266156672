import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import { MinMaxPoints } from './MinMaxPoints/MinMaxPoints';
import {MemoriesStorySummary, TotalMemoryEconomy} from './economics/AnalyseStoryFlowsEconomyManager';

enum BookEconomyTab {
  MinMaxPoints = 'minMaxPoints',
  Choices = 'Choices',
}

type BookEconomyTabsProps = {
  bookId: string;
  memoriesEconomyStats: Record<number, TotalMemoryEconomy> | null;
  memoriesStorySummary: MemoriesStorySummary | null;
  affectionPointsList: string[] | null;
}

export function BookEconomyTabs(props: BookEconomyTabsProps) {
  const {
    memoriesEconomyStats, memoriesStorySummary, affectionPointsList,
  } = props;
  const [key, setKey] = useState<EventKey>(BookEconomyTab.MinMaxPoints);

  return (
    <Tabs
      id="book-economy-tabs"
      activeKey={key}
      onSelect={(k) => setKey(k ?? BookEconomyTab.MinMaxPoints)}
      variant="pills"
    >
      <Tab eventKey={BookEconomyTab.MinMaxPoints} title="Min/Max points">
        {memoriesEconomyStats && memoriesStorySummary && affectionPointsList && (
        <MinMaxPoints
          memoriesEconomyStats={memoriesEconomyStats}
          memoriesStorySummary={memoriesStorySummary}
          affectionPointsList={affectionPointsList}
        />
        )}
      </Tab>
    </Tabs>
  );
}
