export function PremiumIpDisabledEdit(role, book, group) {
  if (!role || !book) {
    return true;
  }

  // eslint-disable-next-line no-param-reassign
  group = group || 'active';

  if (role === 'admin') return false;

  return (
    !['active', 'prompt', 'live', 'liveprompt'].includes(group)
        || (
          book.book_role
            && !['editor', 'owner', 'co-author'].includes(book.book_role)
        )
        || (
          book.original
            && (['live', 'liveprompt'].includes(group) || book?.approved_count > 0)
        )
  );
}
