import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Col, Dropdown, Form, Row,
} from 'react-bootstrap';
import { AvatarCanvasWithCustomCharacterSupport } from '../../../../ui/AvatarTool/AvatarCanvasWithCustomCharacterSupport';
import { defaultExpressionName } from '../../../../ui/AvatarTool/CharacterExpressions/constants';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

const debounceEvent = (callback, time) => {
  let interval;
  return (...args) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      interval = null;
      callback(...args);
    }, time);
  };
};

export class StepsTypeFieldsExpressions extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      active: null,
      activeName: '',
      activeValue: '',
      expression: '',
    };
  }

  componentDidMount() {
    this.checkActive();
  }

  componentDidUpdate(prevProps) {
    const { availableExpressions, characterExpressionId } = this.props;
    if (
      availableExpressions !== prevProps.availableExpressions
      || characterExpressionId !== prevProps.characterExpressionId
    ) {
      this.checkActive();
    }
  }

  checkActive() {
    const {
      availableExpressions,
      characterExpressionId,
    } = this.props;

    const selectedExpression = availableExpressions
      .find((expression) => expression.id === characterExpressionId);

    const defaultCharacterExpression = availableExpressions
      .find((expression) => expression.value === defaultExpressionName);

    const activeExpression = selectedExpression
      || defaultCharacterExpression
      || availableExpressions[0];

    if (activeExpression) {
      this.setState({
        active: activeExpression.id,
        activeName: activeExpression.title,
        expression: activeExpression.value,
      });
    }
  }

  expressionsMenuItems() {
    const {
      stepIndex,
      availableExpressions,
      handleChangeSteps,
    } = this.props;
    const { active } = this.state;

    const { characters, characterId } = this.props;

    const character = characters.find(
      (item) => item.id === Number(characterId),
    );

    if (!character) return null;

    const handleDropDownItemMouseOver = debounceEvent((expressionValue) => {
      this.setState({
        show: true,
        activeValue: expressionValue,
      });
    }, 300);

    return availableExpressions.map((expression) => (
      <Dropdown.Item
        key={expression.id}
        active={(active === expression.id)}
        eventKey={expression.id}
        onMouseOver={() => handleDropDownItemMouseOver(expression.value)}
        onClick={() => {
          this.setState({
            active: expression.id,
            activeName: expression.title,
            activeValue: expression.value,
          }, () => {
            handleChangeSteps(expression.id, stepIndex, 'characterExpressionId');
          });
        }}
      >
        {expression.title}
      </Dropdown.Item>
    ));
  }

  avatar = () => {
    const { characters, characterId } = this.props;
    const { show, expression: expressionFromState, activeValue } = this.state;

    const character = characters.find((o) => Number(o.id) === Number(characterId));
    if (character && character.properties) {
      const expression = JSON.parse(JSON.stringify(character.properties));
      const expressionActive = JSON.parse(JSON.stringify(character.properties));
      expression.expression = expressionFromState;
      expressionActive.expression = activeValue;

      return (
        <span style={{ opacity: 1, zIndex: 1 }}>
          <AvatarCanvasWithCustomCharacterSupport
            properties={show ? expressionActive : expression}
          />
        </span>
      );
    }
    return null;
  };

  render() {
    const {
      characterExpressionId,
      disabled,
      disabledSortableAction,
      activeStepsFunc,
      stepClass,
      stepIndex,
      displayControl,
    } = this.props;
    const { activeName } = this.state;

    if (displayControl !== true) {
      return null;
    }

    return (
      <Form.Group
        md={1}
        as={Col}
        controlId={`characterExpressionId${stepIndex}`}
        className={cs('mb-0 px-1', 'd-block')}
        onMouseDown={(e) => {
          disabledSortableAction(e);
          e.stopPropagation();
        }}
      >
        <Dropdown
          className="characterExpressionDropdown"
          onToggle={(e) => {
            disabledSortableAction(e);
            activeStepsFunc(stepClass);
          }}
        >
          <Dropdown.Toggle
            disabled={disabled}
            size="sm"
            onMouseDown={(e) => e.stopPropagation()}
          >
            {activeName || ''}
          </Dropdown.Toggle>
          <Dropdown.Menu
            onMouseOut={() => {
              this.setState({
                show: false,
              });
            }}
          >
            <Row>
              <Col md={6} className="col">
                <div className="previewActive">
                  {this.avatar()}
                </div>
              </Col>
              <Col md={6} className="col">
                <div className="previewActiveScroll">
                  {this.expressionsMenuItems()}
                </div>
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown>
        <input
          type="hidden"
          name={`steps[${stepIndex}][characterExpressionId]`}
          value={characterExpressionId || ''}
        />
      </Form.Group>
    );
  }
}
