import React, { createContext, useMemo, useState } from 'react';

export const CustomCharacterSelectContext = createContext(null);

export function CustomCharacterSelectProvider({ children }) {
  const [
    customCharacterSearchTerm,
    setCustomCharacterSearchTerm,
  ] = useState('');

  const contextValue = useMemo(
    () => [
      customCharacterSearchTerm,
      setCustomCharacterSearchTerm,
    ],
    [customCharacterSearchTerm],
  );
  return (
    <CustomCharacterSelectContext.Provider value={contextValue}>
      { children }
    </CustomCharacterSelectContext.Provider>
  );
}
