import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import '../../ValidatePanel.scss';
import { Panel } from '../index';
import { BranchesListPanelForm } from './BranchesListPanelForm';
import { BranchesListPass } from './BranchesListPass';

export class BranchesListPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeKey: 'AllNodes',
    };
  }

  loadingFunc = (e) => {
    this.setState({
      loading: e,
    });
  };

  render() {
    const { show, onHide } = this.props;
    const { loading, activeKey } = this.state;

    return (
      <Panel
        pageTitle=""
        show={show}
        onHide={onHide}
        loading={loading}
      >
        <div className="infoPanelContent">
          <div
            className="BranchesListBox"
            style={{ paddingTop: 0, marginTop: '-2.5em' }}
          >

            <Tabs
              defaultActiveKey="AllNodes"
              transition={false}
              id="TabNodes"
              activeKey={activeKey}
              onSelect={(k) => {
                this.setState({
                  activeKey: k,
                });
              }}
            >

              <Tab
                eventKey="AllNodes"
                title="All Nodes"
                id="TabAllNodes"
                style={{ padding: '1em 0' }}
              >
                {(activeKey === 'AllNodes') && (
                <BranchesListPanelForm
                  loadingFunc={this.loadingFunc}
                  disabledRow
                  sortType="name"
                  {...this.props}
                />
                )}
              </Tab>
              <Tab
                eventKey="DecisionPoints"
                title="Decision Points"
                id="TabDecisionPoints"
                style={{ padding: '1em 0' }}
              >
                {(activeKey === 'DecisionPoints') && (
                <BranchesListPanelForm
                  type="choice"
                  loadingFunc={this.loadingFunc}
                  disabledRow={false}
                  analytics
                  {...this.props}
                />
                )}
              </Tab>
              <Tab
                eventKey="ChapterPass"
                title="Episode Pass"
                id="TabChapterPass"
                style={{ padding: '1em 0' }}
              >
                {(activeKey === 'ChapterPass') && (
                <BranchesListPass
                  loadingFunc={this.loadingFunc}
                  disabledRow
                  {...this.props}
                />
                )}
              </Tab>

            </Tabs>

          </div>
        </div>
      </Panel>
    );
  }
}
