import { EpisodeTextColumnKey } from './episodeTextImportTypes';

export type EpisodeTextImportCommandKeyValue = {
  pattern: RegExp,
  scope: EpisodeTextColumnKey[],
};

export const COMMAND_KEY_PATTERN = /(#\w+|#-+.*? )/g;

export const episodeTextImportCommandKeyGotoValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#GOTO|#->)(?:[:\s]+)?([A-Za-z0-9_]+)?/i,
    scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyStepChoiceValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#CHOICE)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
  {
    pattern: /(#-\?)(:)?[:\s]?/,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyLocationValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#LOCATION|#-L)(?:[:\s]+)?([^#]*)/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyStepChatValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#CHAT|#-C)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyStepThinkValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#THINK|#-T)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyStepReactValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#REACT|#-R)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionAnxiousValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#ANXIOUS)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionBlushValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#BLUSH)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionBoredValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#BORED)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionDelightValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#DELIGHT)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionDisgustValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#DISGUST)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionFlirtingValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#FLIRTING)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionHappyValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#HAPPY)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionNeutralValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#NEUTRAL)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionRageValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#RAGE)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionSadValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#SAD)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionScaredValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#SCARED)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionShockValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#SHOCK)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionSmileValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#SMILE)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionThinkingValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#THINKING)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyExpressionWickedValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#WICKED)[:\s]?/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyStepRememberValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#REMEMBER)[:\s]*(\w*)\s*([-+=])?\s*(.*)/i,
    scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
  },
];

/*
  Groups:
  Single: if: 2, memory: 3, symbol operator: 4, text operator: 5, compareMemory: 6, true node: 8, false node: 10
  Multi: min/max/lowest/highest: 2, memories: 5, gotoNodes: 6
 */
export const episodeTextImportCommandKeyStepCheckValue: EpisodeTextImportCommandKeyValue[] = [
  {
    // pattern: /(#CHECK)[:\s]*(if)\s*\((\w*)?\s*(=|<>|>=|<=|<|>)?(Exactly|Not exactly|More than|At least|Less than|At most)?\s*(.*)?\)\s*(then)?\s(\w*)?\s*(else)?\s*(#?\w*)?/i,
    pattern: /(#CHECK):*\s*(if\s+(\w*)\s*(=|<>|>=|<=|<|>)?(Exactly|Not exactly|More than|At least|Less than|At most)?\s*['"]?(.*?)['"]?\s*)\s*,\s*((?:if\s+)?(?:yes(?:\s+goto)?\s+)?(\w*))\s*,\s*((?:if\s+)?(?:no(?:\s+goto)?\s+)?(\w*))/i,
    scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
  },
  {
    // pattern: /(#CHECK)[:\s]*(Min|Max)?(Lowest|Highest)?\(([^)]+)\)\s*(.*)/i,
    pattern: /(#CHECK):*\s*((Min|Max)?(Lowest|Highest)?)(.*?),\s*(if.*)/i,
    scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
  },
  {
    pattern: /(#CHECK)[:\s]*/i,
    scope: [EpisodeTextColumnKey.Text, EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyPremiumAnswerValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#PREMIUM):?\s*(\w*)/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];

export const episodeTextImportCommandKeyBonusContentValue: EpisodeTextImportCommandKeyValue[] = [
  {
    pattern: /(#BONUS):?\s*/i,
    scope: [EpisodeTextColumnKey.Description],
  },
];
