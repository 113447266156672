import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { Switch } from 'react-router-dom';
import { logger } from '../../services/loggerService/loggerService';
import { userApi } from '../../services/userApi/UserApi';
import { BackgroundsPage } from '../pages/Backgrounds';
import { CharacterArtLibrary } from '../pages/CharacterArtLibrary';
import { ProvideName } from '../pages/Logins/ProvideName/ProvideName';
import { SignUp } from '../pages/Logins/SignUp/SignUp';
import { PageNotFound } from '../pages/PageNotFound';
import { MakeRouteWithSubRoutes } from './makeRouteWithSubRoutes';
import { routesConfirmTerm } from './routesConfirmTerm';
import { routesLogged } from './routesLogged';
import { routesLoggedAdmin } from './routesLoggedAdmin';
import { routesNotLogged } from './routesNotLogged';

const isConfirmDocumentsExists = () => {
  try {
    const documents = JSON.parse(localStorage.getItem('documents'));
    return documents?.length > 0;
  } catch (e) {
    logger.error(e);
    return false;
  }
};

export function Routes({ auth }) {
  const initialIsAuthenticated = userApi.isAuthenticated();
  const [isAuthenticated, setIsAuthenticated] = useState(initialIsAuthenticated);
  const [routes, setRoutes] = useState(null);

  const defineRoutes = useCallback(() => {
    if (!isAuthenticated) {
      setRoutes(routesNotLogged);
      return;
    }

    const user = userApi.getProfileLegacy();
    const extRoutes = [];

    if (user.actions?.includes('backgrounds')) {
      extRoutes.push({
        path: '/backgrounds',
        component: BackgroundsPage,
      });
    }
    if (user.actions?.includes('characters')) {
      extRoutes.push({
        path: '/character-art-library',
        component: CharacterArtLibrary,
      });
    }
    if (!user.email) {
      extRoutes.push({
        path: '/write-with-us-app',
        component: SignUp,
      });
    }

    extRoutes.push({
      path: '**',
      redirectTo: '/',
      component: PageNotFound,
    });

    const roleRoutes = user.role !== 'admin' ? routesLogged : routesLoggedAdmin;

    const isProvideNameNeeded = !user.firstName || !user.lastName;
    const isConfirmDocumentsNotAccepted = !user.accepted;
    const isConfirmDocumentsNeeded = isConfirmDocumentsExists();

    if (isProvideNameNeeded) {
      const provideNameRoutes = [
        {
          path: '/',
          exact: true,
          component: ProvideName,
        },
        {
          path: '**',
          component: ProvideName,
        },
      ];
      setRoutes(provideNameRoutes);
      return;
    }

    if (isConfirmDocumentsNotAccepted || isConfirmDocumentsNeeded) {
      if (isConfirmDocumentsNeeded) {
        setRoutes(routesConfirmTerm);
        return;
      }
      logger.error('User is not confirmed documents, but documents not found');
      setRoutes(routesNotLogged);
      return;
    }

    const newRoutes = [...roleRoutes, ...extRoutes];
    setRoutes(newRoutes);
  }, [isAuthenticated]);

  useEffect(() => {
    userApi.checkAuthentication()
      .then((isAuth) => {
        if (isAuth) {
          return userApi.fetchDocuments();
        }
        return Promise.reject('no_auth');
      })
      .then(() => {
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
      })
      .finally(() => {
        defineRoutes();
      });
  }, [defineRoutes]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        { routes
          ? routes.map(
            (
              route,
              index,
            ) => (
              <MakeRouteWithSubRoutes
                      /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                auth={auth}
                {...route}
              />
            ),
          )
          : null}
      </Switch>
    </Suspense>
  );
}
