import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { getUserName } from '../Users';
import { AuthorProfile } from '../Users/AuthorProfile';
import './books.scss';

export class BookAuthors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUserShow: null,
    };
  }

  render() {
    const { obj, user } = this.props;
    const { modalUserShow } = this.state;

    const authorProfile = () => {
      if (modalUserShow) {
        return (
          <AuthorProfile
            show={!!modalUserShow}
            onHide={() => {
              this.setState({ modalUserShow: null });
            }}
            user={modalUserShow}
          />
        );
      }
      return null;
    };

    const writers = obj && obj.writers
      ? obj.writers.slice()
      : [];

    const wrIds = writers.map((el) => el.id);

    const bookWriters = obj.users.filter(
      (el) => (el.story_role === 'owner' || el.story_role === 'co-author') && !wrIds.includes(el.id)
      ,
    );

    return (
      <div className="BookAuthorsList">
        {
          writers.map((value, i) => (
            <Button
              disabled={(user.role !== 'admin')}
              className="p-1"
              variant="link"
              size="sm"
              /* eslint-disable-next-line react/no-array-index-key */
              key={i}
              onClick={() => {
                this.setState({ modalUserShow: value.id });
              }}
            >
              {getUserName(value)}
            </Button>
          ))
        }
        {
          bookWriters.map((value, i) => (
            <Button
              disabled={(user.role !== 'admin')}
              className="p-1"
              variant="link"
              size="sm"
                /* eslint-disable-next-line react/no-array-index-key */
              key={i}
              onClick={() => {
                this.setState({ modalUserShow: value.id });
              }}
            >
              {getUserName(value)}
            </Button>
          ))
        }
        {authorProfile()}
      </div>
    );
  }
}
