import React from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import { BookShareUser } from '../../../../../dorian-shared/types/user/User';
import { PublishType } from '../PublishModal';
import { UsersWithExclusiveAccess } from '../UsersWithExclusiveAccess/UsersWithExclusiveAccess';

interface FirstPublishCardProps {
  bookId: number
  publishType: PublishType,
  bookShareUsers: BookShareUser[]
  onPublishTypeChange: (publishType: PublishType) => void,
  onAddShareUser: (shareUser?: BookShareUser) => void,
  onDeleteShareUser: (shareUser?: BookShareUser) => void,
}

export function FirstPublishCard(props: FirstPublishCardProps) {
  const {
    onPublishTypeChange, publishType, bookId, bookShareUsers, onAddShareUser, onDeleteShareUser,
  } = props;
  return (
    <Row>
      <Col>
        <Card>
          <Card.Title className="text-center">
            Choose your publish variant
          </Card.Title>
          <Row className="mb-3 ml-5">
            <Col sm="auto" className="">
              <Form.Check
                custom
                type="radio"
                id="general-publish"
                checked={publishType === PublishType.General}
                onChange={() => onPublishTypeChange(PublishType.General)}
              />
            </Col>
            <Col sm="auto">
              <Card.Title>General release</Card.Title>
              <Card.Subtitle>Allow all creators to use your template</Card.Subtitle>
            </Col>
          </Row>
          <Row className="mb-3 ml-5">
            <Col sm="auto" className="">
              <Form.Check
                custom
                type="radio"
                id="exclusive-publish"
                checked={publishType === PublishType.Exclusive}
                onChange={() => onPublishTypeChange(PublishType.Exclusive)}
              />
            </Col>
            <Col sm="auto">
              <Card.Title>Exclusive release</Card.Title>
              <Card.Subtitle>Select specific creators to see your template</Card.Subtitle>
            </Col>
          </Row>
          {publishType === PublishType.Exclusive && (
            <Row className="mb-3 mx-5">
              <UsersWithExclusiveAccess
                bookId={bookId}
                onAddUser={onAddShareUser}
                bookShareUsers={bookShareUsers}
                onDeleteUser={onDeleteShareUser}
              />
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
}
