import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import { getUserDisplayName } from '../Users';
import { DeleteCode } from './DeleteCode';

export class CodeTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteCodeShow: false,
    };
  }

  render() {
    const { update, obj } = this.props;
    const { modalDeleteCodeShow } = this.state;

    const modalDeleteCodeClose = () => {
      this.setState({ modalDeleteCodeShow: false });
      update();
    };

    return (
      <tr>
        <td>
          {obj.code}
        </td>
        <td>
          {obj.user ? getUserDisplayName(obj.user) : ''}
        </td>
        <td className="text-right">
          <OverlayTrigger
            overlay={(
              <Tooltip id="tooltip-top">
                Delete User
              </Tooltip>
            )}
          >
            <Button
              onClick={() => this.setState({ modalDeleteCodeShow: true })}
              variant="secondary"
              size="sm"
              className="mx-1"
            >
              <img src={imgDelete} className="btnImg" alt="Delete User" />
            </Button>
          </OverlayTrigger>
          {modalDeleteCodeShow && (
          <DeleteCode
            show={modalDeleteCodeShow}
            onHide={modalDeleteCodeClose}
            obj={obj}
          />
          )}
        </td>
      </tr>
    );
  }
}
