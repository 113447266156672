import cs from 'classnames';
import React from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import { User } from '../../../dorian-shared/types/user/User';
import { ChangeUser } from '../Backgrounds/ChangeUser';
import { EditAlias } from '../Backgrounds/EditAlias';
import classNames from './CharacterArtLibraryItem.module.scss';

type CharacterImageItemProps = {
  user: User;
  image: any;
  isLoading: boolean;
  isActive: boolean;
  onClick: () => void;
  isSelected: boolean;
  onSelected: (value: boolean) => void;
  update: () => void;
  onDelete: () => void;
  isHideActions: boolean;
};

export const getImageOwnerDisplayName = (imageOwner: Pick<User, 'email' | 'firstName' | 'lastName'>) => {
  if (!imageOwner) {
    return '';
  }
  const imageOwnerEmail = imageOwner.email;
  const imageOwnerName = `${imageOwner.firstName} ${imageOwner.lastName}`;
  return imageOwnerEmail ? `${imageOwnerName} (${imageOwnerEmail})` : imageOwnerName;
};

export function CharacterArtLibraryItem(props: CharacterImageItemProps) {
  const {
    user,
    isActive,
    onClick,
    isSelected,
    onSelected,
    image,
    update,
    isLoading,
    onDelete,
    isHideActions,
  } = props;

  const isAdmin = user.role === 'admin';

  return (
    <ListGroup.Item
      as="div"
      active={isActive}
      onClick={onClick}
      className={cs(isActive && classNames.activeImage, isSelected && classNames.selectedImage)}
    >
      <Row className="align-items-center">
        {/* Select checkbox */}
        <Col sm="auto">
          <Form.Check
            custom
            id={image.id}
            checked={isSelected}
            onChange={(e) => onSelected(e.target.checked)}
          />
        </Col>
        {/* Image */}
        <Col sm="auto" className="pr-2">
          <img
            style={{
              height: 50,
              width: 50,
              objectFit: 'contain',
            }}
            src={image.imageUrl}
            alt={image.title}
          />
        </Col>
        {/* Title and alias and user */}
        <Col className="text-truncate">
          <b>{image.title}</b>
          <br />
          {isAdmin && (
            <i>{getImageOwnerDisplayName(image.user)}</i>
          )}
        </Col>
        {/* Actions */}
        {!isHideActions && (
          <Col sm={3} className="text-right px-0">
            {isAdmin && (
            <ChangeUser
              customCharacter={image}
              update={update}
            />
            )}
            <EditAlias
              type="character"
              obj={image}
              update={update}
              user={user}
            />
            <Button
              onClick={onDelete}
              variant="secondary"
              size="sm"
              className="ml-1"
              disabled={isLoading}
              aria-label="Delete"
            >
              <img src={imgDelete} className="btnImg" alt="Delete button" role="presentation" />
            </Button>
          </Col>
        )}

      </Row>
    </ListGroup.Item>
  );
}
