import React from 'react';
import { api } from '../../../api';
import { PerformanceTabContentProps } from '../ChaptersPerformanceTabContent/ChaptersPerformanceTabContent';
import { PerformanceTabContent } from '../PerformanceTabContent/PerformanceTabContent';
import { FetchParams, PerformanceTabs } from '../performanceUtils';
import { choicesColumns, choicesHeaders } from '../tableUtils/columns/choicesColumns';

export function ChoicesPerformanceTabContent(props: PerformanceTabContentProps) {
  const { pageSize } = props;

  const fetchPerformanceData = async (
    params: FetchParams,
    signal: AbortSignal,
  ) => {
    const response = await api.get(
      '/v2/analytics/performance/choices',
      { params, signal },
    );
    return response.data;
  };

  return (
    <PerformanceTabContent
      tabTitle={PerformanceTabs.Choices}
      csvHeaders={choicesHeaders}
      columns={choicesColumns}
      getData={fetchPerformanceData}
      pageSize={pageSize}
    />
  );
}
