import React from 'react';

export function BrokenEntireComponent({ isErrorThrow }) {
  if (isErrorThrow) {
    throw new Error('Error during trying to call error of entire broken component');
  }

  return (
    <h3>This component is working</h3>
  );
}
