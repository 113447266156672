import async from 'async';
import { Formik } from 'formik';
import { isEqual } from 'lodash-es';
import React, { PropsWithChildren } from 'react';
import * as Yup from 'yup';
import { useAchievementsContext } from '../../../../contexts/AchievementsContext/AchievementsContext';
import { IAchievementGetDTO } from '../../../../dorian-shared/types/achievement/Achievement';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { IAchievementModal } from '../AchievementsModalTypes';
import { convertAchievementToPostDTO } from '../utils';

const achievementSchema = Yup.array().of(Yup.object().shape({
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .max(254, 'Must be 254 characters or less')
    .required('Required')
    .matches(/^[a-zA-Z0-9_\-.$&]*$/, 'Only letters, numbers, and special characters are allowed'),
  displayName: Yup.string()
    .max(25, 'Must be 25 characters or less'),
  description: Yup.string()
    .max(254, 'Must be 254 characters or less'),
  icon: Yup.string()
    .required('Required'),
  check: Yup.object().shape({
    variableId: Yup.number()
      .min(1, 'Required')
      .required('Required'),
    operator: Yup.string()
      .required('Required'),
    value: Yup.number()
      .required('Required')
      .max(999999999, 'Must be 999999999 or less'),
  }),
}));

export interface AchievementsFormikWrapperProps extends PropsWithChildren {
  initialValues: IAchievementModal[];
  onSubmit: () => void;
}

export function AchievementsFormikWrapper(props: AchievementsFormikWrapperProps) {
  const { children, onSubmit, initialValues } = props;

  const {
    achievements, createAchievement, updateAchievement, deleteAchievement, updateOrder,
    setIsLoading,
  } = useAchievementsContext();

  const handleSubmit = async (values: IAchievementModal[]) => {
    const createPromises: (() => Promise<IAchievementGetDTO | null>)[] = [];
    const updatePromises: (() => Promise<IAchievementGetDTO | null>)[] = [];
    const deletePromises: (() => Promise<void>)[] = [];

    achievements.forEach((achievement) => {
      const isExist = values.find((value) => value.id === achievement.id);
      if (!isExist) {
        deletePromises.push(() => deleteAchievement(achievement.id));
      }
    });

    values.forEach((value) => {
      const achievementDTO = achievements.find((a) => a.id === value.id);

      const valueToCompare = convertAchievementToPostDTO(value);
      const achievementToCompare = achievementDTO ? convertAchievementToPostDTO(achievementDTO) : null;

      if (value.isAdded || !achievementDTO) {
        createPromises.push(async () => await createAchievement(valueToCompare));
      } else if (!isEqual(achievementToCompare, valueToCompare)) {
        updatePromises.push(async () => await updateAchievement(achievementDTO.id, valueToCompare));
      }
    });

    setIsLoading(true);
    const LIMIT = 1;
    async.parallel({
      create: (callback: { (error: unknown, result: unknown): void }) => {
        const createLimitRequests = createPromises.map((promise) => async () => await promise());
        async.parallelLimit(createLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
      update: (callback: { (error: unknown, result: unknown): void }) => {
        const updateLimitRequests = updatePromises.map((promise) => async () => await promise());
        async.parallelLimit(updateLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
      delete: (callback: { (error: unknown, result: unknown): void }) => {
        const deleteLimitRequests = deletePromises.map((promise) => async () => await promise());
        async.parallelLimit(deleteLimitRequests, LIMIT)
          .then((result) => {
            callback(null, result);
          })
          .catch((error) => {
            callback(error, null);
          });
      },
    })
      .then(async (results) => {
        const responseResults = results as { create: IAchievementModal[]; update: IAchievementModal[]; delete: void[] };
        const order = values.map((value) => {
          if (value.isAdded || Number.isNaN(Number(value.id))) {
            const createdId = responseResults.create.find((c: IAchievementModal) => c.name === value.name)?.id;
            const updatedId = responseResults.update.find((u: IAchievementModal) => u.name === value.name)?.id;
            const newId = createdId ?? updatedId;
            if (!newId) {
              logger.error('Failed to create achievement');
              return 0;
            }
            return Number(createdId);
          }
          return Number(value.id);
        });
        try {
          const isOrderChanged = initialValues.length !== order.length || !initialValues.every((a, i) => a.id === order[i]);
          if (isOrderChanged) {
            await updateOrder(order);
          }
          setIsLoading(false);
          showToast({ textMessage: 'Achievements saved', variant: 'success' });
          onSubmit();
        } catch (error) {
          showToast({ textMessage: 'Failed to save order of achievements' });
          logger.error(error);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast({ textMessage: 'Failed to save achievements' });
        logger.error(error);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={achievementSchema}
      onSubmit={handleSubmit}
    >
      {children}
    </Formik>
  );
}
