import {
  EditorStore, EngineAuth, GenericPopup, InitEditorOptions, initEditor,
} from '@dorian/creation-tools-ui';
import cs from 'classnames';
import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import Spinner from 'react-bootstrap/esm/Spinner';
import shortUUID from 'short-uuid';
import { ChoiceMemoryContext } from 'contexts/ChoiceMemory/ChoiceMemoryContext';
import { logger } from 'services/loggerService/loggerService';
import { useApiService } from '../../../contexts/ApiServiceContext/ApiServiceContext';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { Character } from '../../../dorian-shared/types/character/Character';
import {
  DefaultCharacterVisualProperties,
} from '../../../dorian-shared/types/character/DefaultCharacterVisualProperties';
import { noop } from '../../../helpers/noop';
import { getRuntimeEnv } from '../../../helpers/runtimeEnv';
import { RuntimeEnv } from '../../../helpers/RuntimeEnvEnum';
import { baseURL } from '../../api';
import { MemoryIcon } from '../../pages/Book/MemoryBank/memoryBankTypes';
import { showToast } from '../utils';
import { getBaseUrl, getEnvPrefix } from './getManifestUrlByRuntimeEnv';
import classNames from './Preview.module.scss';
import { useAvatarResolver } from './useAvatarResolver/useAvatarResolver';
import { useChoiceMemoryCallbacks } from './useChoiceMemoryCallbacks/useChoiceMemoryCallbacks';
import { useSizes } from './useSizes';

const letterboxViewportSettings = {
  viewportSize: {
    width: 1080,
    height: 1920,
  },
  viewportBgColor: 0x000000,
};

type GameplayRendererOptions = InitEditorOptions['gameplayRendererOptions'];

type CorrectedInitEditorOptions = Omit<InitEditorOptions, 'episodeUuid' | 'container' | 'engineUrl' | 'gameplayRendererOptions'> & {
  gameplayRendererOptions: Omit<GameplayRendererOptions, 'localAssetsPath' | 'avatarResolver'>
};

// TODO: fix CT, force to export enum
// eslint-disable-next-line no-shadow
export declare enum GameUIStyle {
  Default = 'default',
  Arcana = 'arcana'
}

interface PreviewProps {
  bookId?: number,
  episodeUuid: string,
  store: EditorStore,
  bookStyle?: GameUIStyle,
  isDetached?: boolean,
  scrollToBranch?: (branchId: number) => void,

  width?: number,
  onPreviewExit?: (returnCode: string, params?: {episodeUuid: string}) => void
  isBranchDetailsCacheDisabled?: boolean,
  editorProps?: Partial<CorrectedInitEditorOptions>,
  characters?: Character[],
}

const getAuth = () => {
  if (localStorage.getItem('user') && localStorage.getItem('token')) {
    return undefined;
  }

  if (!localStorage.getItem('deviceId') || localStorage.getItem('deviceId') === 'null') {
    localStorage.setItem('deviceId', shortUUID.uuid());
  }

  return { // probably not need if engine token in local storage
    type: 'deviceId',
    deviceId: localStorage.getItem('deviceId'),
  };
};

export function Preview(props: PreviewProps) {
  const {
    episodeUuid,
    store,
    bookStyle,
    isDetached = false,
    scrollToBranch,
    characters,

    isBranchDetailsCacheDisabled,
    width = 450,
    onPreviewExit = noop,
    editorProps,
    bookId,
  } = props;

  const [storyCharacters, setStoryCharacters] = useState<Character[]>(characters ?? []);
  const [memoryIcons, setMemoryIcons] = useState<MemoryIcon[]>([]);

  const { mobileBackgroundStyle, sizeConfig } = useSizes(width, isDetached);
  const avatarResolver = useAvatarResolver();
  const apiService = useApiService();

  const containerRef = useRef(null);

  const choiceMemory = useContext(ChoiceMemoryContext);
  const [,
    {
      isLoading,
      setToLoading,
      setToSuccess,
      setToError,
    },
  ] = useAsyncOperationState();

  const loadData = useCallback(async () => {
    if (!bookId) {
      return;
    }
    setToLoading();
    try {
      if (!characters) {
        const charactersData = await apiService.fetchCharactersByBookId(bookId) ?? [];
        setStoryCharacters(charactersData);
        setToSuccess();
      }
    } catch (e) {
      setToError();
      showToast({ textMessage: 'Can\'t load characters', variant: 'danger' });
    }
    try {
      const memoryIconsData = await apiService.fetchMemoryIconsByBookId(bookId);
      setMemoryIcons(memoryIconsData);
      setToSuccess();
    } catch (e) {
      setToError();
      showToast({ textMessage: 'Can\'t load memory icons', variant: 'danger' });
    }
  }, [apiService, bookId, characters, setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (choiceMemory && bookId) {
      if (!choiceMemory.currentVariables) {
        choiceMemory.fetchVariables(bookId);
      }
    }
  }, [bookId, choiceMemory]);

  const popupComponent = useCallback(async (
    text: string,
    primaryButtonText?: string,
    iconId?: number,
  ) => {
    if (!store) {
      return;
    }

    await new Promise<void>((resolve) => {
      // // TODO: Change GenericPopup to internal component
      store.setEpisodeEditorPopupComponent(() => {
        const iconUrl = memoryIcons.find((icon) => Number(icon.id) === Number(iconId))?.url;
        return (
          <div style={{ color: 'white' }}>
            {/* Will be ignore because we have different react version on CT !== engine */}
            {/* @ts-ignore */}
            <GenericPopup
              text={text}
              primaryButtonText={primaryButtonText ?? 'OK'}
              onPrimaryButtonClick={() => {
                store.setEpisodeEditorPopupComponent(() => null);
                resolve();
              }}
              iconData={iconUrl}
            />
          </div>
        );
      });
    });
  }, [store, memoryIcons]);

  const { handleExternalCallbacks } = useChoiceMemoryCallbacks(
    choiceMemory,
    popupComponent,
    scrollToBranch,
    storyCharacters,
  );

  useEffect(() => {
    if (!store) {
      logger.debug('store is null. Returning...');
      return () => null;
    }

    if (!containerRef.current) {
      logger.debug('containerRef.current is null. Returning...');
      return () => null;
    }
    if (!avatarResolver) {
      logger.debug('avatarResolver is null. Returning...');
      return () => null;
    }

    // Legacy. Just set any string, because we use credentials  instead bearer token
    localStorage.setItem('engineToken', 'credential');

    const runtimeEnv = getRuntimeEnv();
    const envLabel = runtimeEnv === RuntimeEnv.Prod ? 'prod' : 'staging';
    const assetsEnvironment = getEnvPrefix(envLabel);
    const assetsUrl = getBaseUrl(envLabel);

    const unmountEpisodeEditor = initEditor({
      letterboxViewportSettings,
      currentUserCharacterProps: {} as DefaultCharacterVisualProperties,
      exitCallback: onPreviewExit,
      logAnalyticsEvent: noop,
      assetsUrl,
      assetsEnvironment,
      ...editorProps,
      gameplayRendererOptions: {
        mouseEventsEnabled: true,
        ...sizeConfig,
        ...editorProps?.gameplayRendererOptions,
        localAssetsPath: `${process.env.PUBLIC_URL}/`,
        avatarResolver,
        viewPortSize: sizeConfig,
      },
      auth: getAuth() as EngineAuth,
      episodeUuid,
      container: containerRef.current,
      engineUrl: baseURL.slice(0, -1),
      externalStore: store,
      isReadOnly: true,
      isBranchDetailsCacheDisabled,
      isLetterboxForArcanaActive: true,
      isLetterboxForAllStoriesActive: false,
      externalCallbacks: handleExternalCallbacks,
    });

    return () => {
      unmountEpisodeEditor?.();
      localStorage.removeItem('engineToken');
    };
  }, [
    avatarResolver,
    bookStyle,
    editorProps,
    episodeUuid,
    onPreviewExit,
    sizeConfig,
    store,
    isBranchDetailsCacheDisabled,
    handleExternalCallbacks,
    choiceMemory,
  ]);

  return (
    <div
      className={isDetached ? undefined : classNames.wrapper}
      style={mobileBackgroundStyle}
    >
      <div id="errorsBox" />
      <div
        ref={containerRef}
        className={isDetached ? classNames.previewContainerDetached : classNames.previewContainer}
        style={sizeConfig}
      />
      {isLoading
        && (
          <>
            <div className={cs({ overlay: isLoading })} />
            <Spinner
              variant="primary"
              animation="border"
              className="spinner"
            />
          </>
        )}

    </div>
  );
}
