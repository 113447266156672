import cs from 'classnames';
import React from 'react';
import { InfoIcon } from '../../../ui/icons/InfoIcon/InfoIcon';
import classNames from './InfoIconButton.module.scss';

interface InfoButtonIcon {
    className?: string;
    onClick?: () => void;
    width?: string;
    height?: string;
    color?: string;
}

export function InfoIconButton(props: InfoButtonIcon) {
  const {
    className,
    onClick,
    width = '100%',
    height = '100%',
    color = 'white',
  } = props;

  return (
    <button
      type="button"
      className={cs(classNames.button, className)}
      onClick={onClick}
    >
      <InfoIcon width={width} height={height} fill={color} />
    </button>
  );
}
