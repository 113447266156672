import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as Replace } from '../../../assets/images/replace.svg';
import { ChapterRestoreModal } from './ChapterRestoreModal';

export class ChapterRestore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const {
      obj,
      disabled,
    } = this.props;
    const { show } = this.state;

    const restore = () => {
      if (show) {
        return (
          <ChapterRestoreModal
            {...this.props}
            obj={obj}
            show
            onHide={() => {
              this.setState({ show: false });
            }}
          />
        );
      }
      return null;
    };

    return (
      <>
        <span className="tooltip-box">
          <Button
            disabled={disabled}
            onClick={() => {
              this.setState({
                show: true,
              });
            }}
            variant="secondary"
            size="sm"
            className="ml-1"
          >
            <Replace className="btnImg" />
          </Button>
          <div className="tooltip">
            <div className="arrow" />
            <div className="tooltip-inner">Restore this version</div>
          </div>
        </span>
        {restore()}
      </>
    );
  }
}
