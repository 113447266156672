import { isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ExpressionValue } from 'dorian-shared/types/character/ExpressionValue';
import {
  useCharacterExpressionsContext,
} from '../../../../contexts/CharacterExpressionsContext/CharacterExpressionsContext';
import { CustomCharacterSelectProvider } from '../../../../contexts/CustomCharacterSelect/CustomCharacterSelectProvider';
import { ExpressionConfig } from '../../../../dorian-shared/types/character/ExpressionConfig';
import { ExpressionList } from '../ExpressionList/ExpressionList';
import classNames from './CharacterExpressions.module.scss';
import { defaultExpressionName, defaultExpressionValue, emptyKey } from './constants';

export type CharacterExpressionsType = {
  [key in ExpressionValue as string]: typeof defaultExpressionValue
}

export type CharacterExpressionsProps = {
  value: CharacterExpressionsType,
  onChange: (value: CharacterExpressionsType) => void,
}

export function CharacterExpressions(props: CharacterExpressionsProps) {
  const { value, onChange } = props;

  const expressionValuesByName = isEmpty(value)
    ? {}
    : value;

  const [possibleExpressions, setPossibleExpressions] = useState<ExpressionConfig[]>([]);
  const characterExpressions = useCharacterExpressionsContext();

  useEffect(() => {
    // For new expression
    const emptyExpression: ExpressionConfig = {
      id: 0,
      value: emptyKey,
      title: 'Select...',
      def: false,
      config: {},
    };
    setPossibleExpressions([emptyExpression, ...characterExpressions]);
  }, [characterExpressions]);

  const handleAddExpression = () => {
    const expressionKey = expressionValuesByName[defaultExpressionName]
      ? emptyKey
      : defaultExpressionName;

    const newExpression = {
      ...expressionValuesByName,
      [expressionKey]: defaultExpressionValue,
    };
    onChange(newExpression);
  };

  const handleExpressionListChange = (newValue: CharacterExpressionsType) => {
    onChange(newValue);
  };

  // Used possibleExpressions.length - 1 because emptyExpression is added
  const canAddNewExpression = Object.keys(expressionValuesByName).length < possibleExpressions.length - 1;

  return (
    <CustomCharacterSelectProvider>
      <div className={classNames.container}>
        <Row>
          <Col>
            <ExpressionList
              value={expressionValuesByName}
              possibleExpressions={possibleExpressions}
              onChange={handleExpressionListChange}
            />
          </Col>
        </Row>
        {canAddNewExpression && (
          <Row>
            <Col xs={12} className={classNames.addExpressionButton}>
              <Button
                className={classNames.button}
                variant="primary"
                onClick={handleAddExpression}
              >
                Add expression
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </CustomCharacterSelectProvider>
  );
}
