import React, { ReactNode, createContext, useContext } from 'react';
import { MemoryBankService } from '../../services/memoryBankService/MemoryBankService';

export const MemoryBankServiceContext = createContext<MemoryBankService>(new MemoryBankService());

export function MemoryBankProvider({ children }: { children: ReactNode }) {
  const memoryBankContext = useContext(MemoryBankServiceContext);

  return (
    <MemoryBankServiceContext.Provider value={memoryBankContext}>
      {children}
    </MemoryBankServiceContext.Provider>
  );
}

export const useMemoryBankServiceContext = () => useContext(MemoryBankServiceContext);
