import React, { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';

export function PerformanceAsyncLongCalculations() {
  const performanceName = 'Performance of the async long run function with 5sec.';

  const [isMeasured, setIsMeasured] = useState(false);

  const longAsyncRunFunc = async (sleepDuration) => new Promise((resolve) => {
    setTimeout(() => resolve(), sleepDuration);
  });

  const handleThrowErrorClick = () => {
    const timeout = 5000;
    setIsMeasured(true);
    showToast({
      textMessage: 'The long run async function has been started.',
      variant: 'info',
    });
    const transaction = bugTracker().startTransaction({ name: performanceName });
    longAsyncRunFunc(timeout).then(() => {
      transaction.finish();
      setIsMeasured(false);
      showToast({
        textMessage: 'The long run async function has been ended.',
        variant: 'info',
      });
    });
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Measuring the performance of the long run async function with 5sec.
        </Card.Title>
        <Alert variant="info">
          Transaction name:
          {' '}
          <b>{performanceName}</b>
        </Alert>
        <Button
          size="sm"
          variant="primary"
          disabled={isMeasured}
          onClick={handleThrowErrorClick}
          aria-controls="example-collapse-text"
        >
          Measure it
        </Button>
      </Card.Body>
    </Card>
  );
}
