import { BookShareUser, BookUserRole } from '../../../../dorian-shared/types/user/User';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { api } from '../../../api';
import { Roles } from '../../Stories/Share/roles';

export const getBookRoleNameByRole = (role: string) => {
  const bookRoles = Roles.book;
  const existBookRole = bookRoles.find((bookRole: {role: string}) => bookRole.role === role);
  if (existBookRole) {
    return existBookRole.label;
  }
  bugTracker().reportError({ name: '[AT] Missing role', message: `Role ${role} is missing in book roles` });
  return '-';
};

export const fetchShareUsersWithExclusiveAccess = async (bookId: number) => {
  const response = await api.get(`/v1/books/${bookId}/share`);
  const responseStoryShareUsers = response.data.users as BookShareUser[];
  const usersWithExclusiveAccess = responseStoryShareUsers.filter(
    (responseStoryShareUser) => responseStoryShareUser.book_role === BookUserRole.Exclusive,
  );
  return usersWithExclusiveAccess as BookShareUser[];
};
