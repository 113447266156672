import axios from 'axios';
import React, { Component } from 'react';
import {
  Alert, Button, Card, Col, Form, Row, Table,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { getUserDisplayName } from '../Users';
import { AuthorProfile } from '../Users/AuthorProfile';

export class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      books: [],
      stories: [],
      users: [],
      modalUserShow: null,
    };

    const { auth } = this.props;

    this.user = auth.getUser();
    this.cancelToken = null;
  }

  debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  debouncedSearch = this.debounce((text) => {
    api.get(`/v1/search/${text}`, { cancelToken: this.cancelToken.token })
      .then((resp) => {
        this.setState({
          books: resp.data.data.books,
          stories: resp.data.data.stories,
          users: resp.data.data.users,
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
        this.setState({
          formError: 'Error occurred while searching. Please try again later.',
        });
      });
  }, 300);

  search(text) {
    if (!text) {
      this.setState({
        books: [],
        stories: [],
        users: [],
      });
      return;
    }
    if (text.length < 3) {
      return;
    }

    if (this.cancelToken != null) {
      this.cancelToken.cancel('Canceled due to new request.');
    }

    this.cancelToken = axios.CancelToken.source();
    

    this.debouncedSearch(text);
  }

  renderBooks() {
    const { books } = this.state;

    if (books.length < 1) {
      return null;
    }
    return (
      <Table striped hover size="md">
        <thead>
          <tr>
            <th>Story Title</th>
            <th>Group</th>
          </tr>
        </thead>
        <tbody>
          {books.map((book) => (
            <tr key={book.id}>
              <td>
                <Link
                  to={`/book/${book.id}`}
                >
                  {book.title}
                </Link>
              </td>
              <td>
                {book.group && <span style={{ color: book.group.color }}>{book.group.title}</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderStories() {
    const { stories } = this.state;

    if (stories.length < 1) {
      return null;
    }
    return (
      <Table striped hover size="md">
        <thead>
          <tr>
            <th>Episode Title</th>
            <th>Group</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody>
          {stories.map((story) => (
            <tr key={story.id}>
              <td>
                <Link
                  to={`/stories/${story.id}`}
                >
                  {story.title}
                </Link>
              </td>
              <td>
                {story.group && (
                  <span style={{ color: story.group.color }}>
                    {story.group.title}
                  </span>
                )}
              </td>
              <td>
                {story.revision}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  renderUsers() {
    const { users, modalUserShow } = this.state;

    if (users.length < 1) {
      return null;
    }

    return (
      <Table striped hover size="md">
        <thead>
          <tr>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>
                <Button
                  className="p-1"
                  variant="link"
                  size="sm"
                  disabled={this.user && this.user.role !== 'admin'}
                  onClick={() => this.setState({ modalUserShow: user.id })}
                >
                  {getUserDisplayName(user)}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        {modalUserShow && (
          <AuthorProfile
            show={!!modalUserShow}
            onHide={() => {
              this.setState({ modalUserShow: null });
            }}
            user={modalUserShow}
          />
        )}
      </Table>
    );
  }

  render() {
    const { search, formError } = this.state;

    const page = {
      header: {
        title: 'Search',
        type: 'search',
        settings: this.user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to Stories List',
            action: '',
            href: '/books',
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };

    return (
      <PageWrapper
        {...this.props}
        page={page}
      >
        <Card>
          <Card.Body>
            <Row className={!formError ? 'd-none' : 'd-block'}>
              <Col md={12}>
                <Alert variant="danger">
                  {formError}
                </Alert>
              </Col>
            </Row>
            <Form
              className="my-3"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Form.Control
                type="text"
                placeholder="Enter story title / episode title / user"
                value={search}
                onChange={(e) => {
                  const val = e.target.value;
                  this.setState({ search: val });
                  this.search(val);
                }}
              />
            </Form>
            {this.renderBooks()}
            {this.renderStories()}
            {this.renderUsers()}
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
