import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { DetailedPrizeCard, DetailedPrizeCardProps } from './DetailedPrizeCard/DetailedPrizeCard';
import classes from './DetailedPrizes.module.scss';
import { DetailedPrize } from './DetailedPrizeType';

export const emptyPrize = {
  label: '',
  imageUrl: '',
};

export interface DetailedPrizesProps {
    detailedPrizes: DetailedPrize[]
    onChange: (prizes: DetailedPrize[]) => void,
    touched?: DetailedPrizeCardProps['touched'][]
    errors?: DetailedPrizeCardProps['error'][]
}

export function DetailedPrizes(props: DetailedPrizesProps) {
  const {
    onChange,
    detailedPrizes,
    touched,
    errors,
  } = props;

  const handlePrizeChange = (detailedPrize: DetailedPrize, index: number) => {
    const newDetailedPrizes = [...detailedPrizes];
    newDetailedPrizes.splice(index, 1, detailedPrize);
    onChange(newDetailedPrizes);
  };

  const handlePrizeDelete = (detailedPrize: DetailedPrize) => {
    const index = detailedPrizes.indexOf(detailedPrize);
    const newDetailedPrizes = [...detailedPrizes];
    newDetailedPrizes.splice(index, 1);
    onChange(newDetailedPrizes);
  };

  const isAddPrizeVisible = detailedPrizes.length < 3;

  return (
    <>
      <div className={classes.title}>Prizes:</div>
      <Row className="mb-3">
        {detailedPrizes.map((detailedPrize, index) => (
          <Col
          // eslint-disable-next-line react/no-array-index-key
            key={index}
            md="3"
            className={classes.prizeContainer}
          >
            <DetailedPrizeCard
              detailedPrize={detailedPrize}
              onChange={(changedDetailedPrize) => handlePrizeChange(changedDetailedPrize, index)}
              error={errors ? errors[index] : undefined}
              touched={touched ? touched[index] : undefined}
              onDelete={() => handlePrizeDelete(detailedPrize)}
            />
          </Col>
        ))}
        {isAddPrizeVisible && (
          <Col>
            <Button
              onClick={() => onChange([...detailedPrizes, emptyPrize])}
            >
              Add prize
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}
