import classNames from 'classnames/bind';
import React from 'react';
import imgErotic from '../../../../assets/images/Erotic.png';
import imgHeart from '../../../../assets/images/heart.png';
import imgStar from '../../../../assets/images/ui/icon-star.png';
import styles from '../StoryBranches.scss';

const cs = classNames.bind(styles);

const getTraitImg = (type) => {
  switch (type) {
    case 'romantic':
      return imgHeart;
    case 'erotic':
      return imgErotic;
    case 'paid' || type:
      return imgStar;
    default:
      return null;
  }
};

export function TraitImages({
  answers,
}) {
  if (window.$traits) {
    return null;
  }

  return (
    <div className={cs('traitImgContainer')}>
      {answers?.map((answer, index) => {
        const traitImg = getTraitImg(answer.type);
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={cs('traitImg')}
          >
            {traitImg && (
              <img src={traitImg} alt="" />
            )}
          </div>
        );
      })}
    </div>
  );
}
