import async from 'async';
import React, { Component } from 'react';
import {
  Alert, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';

export class FanSignups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      invites: [],
      approved: [],
      loading: true,
      error: null,
      // eslint-disable-next-line react/no-unused-state
      granted_cnt: 0,
      // eslint-disable-next-line react/no-unused-state
      granted: 5,
      // eslint-disable-next-line react/no-unused-state
      grantedDisabled: true,
    };

    const {
      history,
      auth,
    } = this.props;

    this.user = auth.getUser();

    this.page = {
      header: {
        title: 'Author Signups',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Author Signups',
            href: '/approvedfan',
            variant: 'primary',
            disabled: this.user.role !== 'admin',
            NotAdminPermissions: !this.user || this.user.role !== 'admin',
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  errorAlert = (error) => {
    this.setState({
      error,
    });
    setTimeout(() => {
      this.setState({
        error: null,
      });
    }, 1500);
  };

  loadData = () => {
    this.setState({
      loading: true,
    });

    async.parallel({
      info: (callback) => {
        api.get('/v1/user/info')
          .then((res) => {
            callback(null, res.data.user);
          }).catch((error) => {
            this.errorAlert(error?.response?.data?.error);
            callback(error, null);
          });
      },
      invites: (callback) => {
        api.get('/v1/invites')
          .then((res) => {
            callback(null, res.data.invites);
          }).catch((error) => {
            this.errorAlert(error?.response?.data?.error);
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          const approved = [];
          const invites = [];
          let grantedCount = 0;
          res.invites.forEach((obj) => {
            if (obj.granted === true) {
              approved.push(obj);
              grantedCount += 1;
            } else {
              invites.push(obj);
            }
          });
          this.setState({
            approved,
            // eslint-disable-next-line react/no-unused-state
            invites,
            loading: false,
            // eslint-disable-next-line react/no-unused-state
            granted: res.info.allowedCreatorSignups,
            // eslint-disable-next-line react/no-unused-state
            grantedDisabled: (Number(res.info.allowedCreatorSignups) - Number(grantedCount)) < 1,
            // eslint-disable-next-line react/no-unused-state
            granted_cnt: grantedCount,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  approvedTable = () => {
    const columns = [
      {
        dataField: 'firstName',
        sort: false,
        text: 'First Name',
      },
      {
        dataField: 'lastName',
        sort: false,
        text: 'Last Name',
      },
      {
        dataField: 'email',
        sort: false,
        text: 'Email',
      },
      {
        dataField: 'country',
        sort: false,
        text: 'Country',
      },
      {
        dataField: 'social',
        sort: false,
        text: 'Social Media Handle',
      },
      {
        dataField: 'createdAt',
        sort: true,
        text: 'Created Date',
        type: 'date',
        // eslint-disable-next-line react/no-unstable-nested-components
        formatter: (cell) => (
          <Moment
            element="span"
            format="YYYY-MM-DD hh:mm A"
            date={cell}
          />
        ),
      },
    ];

    const { approved } = this.state;

    return approved.length > 0
      ? (
        <div className="pb-5">
          <Card.Title>Author Signups</Card.Title>
          <BootstrapTable
            striped
            hover
            size="md"
            bordered={false}
            bootstrap4
            keyField="id"
            data={approved}
            columns={columns}
          />
        </div>
      )
      : (
        <div className="pb-5">
          <Card.Title>Author Signups</Card.Title>
        </div>
      );
  };

  render() {
    const {
      error,
      loading,
    } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card>
          <Card.Body>

            {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <>
                      <Row className={error === null ? 'd-none' : 'd-block pt-2'}>
                        <Col md={12}>
                          <Alert variant="danger">
                            {error}
                          </Alert>
                        </Col>
                      </Row>

                      {this.approvedTable()}
                    </>
                  )
              }
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
