import * as Yup from 'yup';

export const performanceRanksSchema = Yup.array().of(
  Yup.object().shape({
    ranks: Yup.array().of(
      Yup.object().shape({
        minRequirement: Yup.number()
          .integer('Required integer')
          .required('Required')
          .min(Number.MIN_SAFE_INTEGER, 'Too small')
          .max(Number.MAX_SAFE_INTEGER, 'Too big'),
      }),
    ).test('isRankValuesValidated', 'Ranks must be validated', (values, context) => {
      if (!values) {
        return true;
      }
      const star1Value = Number(values[0].minRequirement);
      const star2Value = Number(values[1].minRequirement);
      const star3Value = Number(values[2].minRequirement);
      if (star2Value < star1Value) {
        return context.createError({ path: `${context.path}[1].minRequirement`, message: `Must be greater or equal to ${star1Value}` });
      }
      if (star3Value < star2Value) {
        return context.createError({ path: `${context.path}[2].minRequirement`, message: `Must be greater or equal to ${star2Value}` });
      }
      return true;
    }),
  }),
);
