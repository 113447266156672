import React, { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';
import { DefaultFallback } from '../../ErrorBoundary/FallBacks/DefaultFallback';
import { BrokenEntireComponent } from './BrokenEntireComponent';

export function BrokenEntireComponentWrapper() {
  const [isBrokenComponentShown, setIsBrokenComponentShown] = useState(false);
  const { ErrorBoundary } = bugTracker();

  const handleThrowErrorClick = () => {
    setIsBrokenComponentShown(true);
    setTimeout(() => setIsBrokenComponentShown(false), 4000);
    showToast({
      textMessage: 'Error thrown. Check the error on the bug tracker service',
      variant: 'info',
      timeout: 4000,
    });
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Throw error in completely broken component through ErrorBoundary</Card.Title>
        <Alert variant="info">
          Error name:
          {' '}
          <b>Error during trying to call error of entire broken component</b>
        </Alert>
        <Button
          size="sm"
          variant="primary"
          disabled={isBrokenComponentShown}
          onClick={handleThrowErrorClick}
        >
          Throw error
        </Button>
        {isBrokenComponentShown && (
        <ErrorBoundary fallback={DefaultFallback}>
          <BrokenEntireComponent isErrorThrow={isBrokenComponentShown} />
        </ErrorBoundary>
        )}
        {!isBrokenComponentShown && (
        <ErrorBoundary fallback={DefaultFallback}>
          <BrokenEntireComponent isErrorThrow={isBrokenComponentShown} />
        </ErrorBoundary>
        )}
      </Card.Body>
    </Card>
  );
}
