import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { useApiService } from 'contexts/ApiServiceContext/ApiServiceContext';
import { BackgroundArtImage } from '../../../../dorian-shared/types/artImage/BackgroundArtImage';
import { CharacterArtImage } from '../../../../dorian-shared/types/artImage/CharacterArtImage';
import { auth } from '../../../Auth/Auth';
import { LibraryType } from '../../UploadFilesModal/types';
import { deleteImageColumns } from './columns';
import { CheckedImage, UsedArtBook } from './types';

export type DeleteImageProps = {
  type: LibraryType;
  images: BackgroundArtImage[] | CharacterArtImage[];
  show: boolean;
  onHide: () => void;
  onDelete: () => void;
}

export function DeleteImageModal(props: DeleteImageProps) {
  const {
    images, type, onDelete, show,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const apiService = useApiService();

  const [checkedImages, setCheckedImages] = useState<CheckedImage[]>([]);
  const [usedBooks, setUsedBooks] = useState<Record<string, UsedArtBook[]>>({});

  const user = auth.getUser();
  const isAdmin = user?.role === 'admin';

  const fetchUsedBooks = useCallback(async (id: number) => {
    const fallbackUsedArtBook: UsedArtBook = {
      id: 0,
      title: 'Error fetching books...',
    };

    try {
      switch (type) {
        case LibraryType.Character:
          return apiService.fetchUsedArtBooksByCharacterId(id);
        case LibraryType.Background:
          return apiService.fetchUsedArtBooksByBackgroundId(id);
        default:
          return [fallbackUsedArtBook];
      }
    } catch (error) {
      console.error(error);
    }
    return [fallbackUsedArtBook];
  }, [apiService, type]);

  useEffect(() => {
    if (!show) {
      return;
    }
    (async () => {
      setIsLoading(true);
      const newUsedBooks: Record<string, UsedArtBook[]> = {};
      switch (type) {
        case LibraryType.Character: {
          const characterArtImages = images as CharacterArtImage[];
          const imagesSorted: CharacterArtImage[] = characterArtImages.sort(
            (a, b) => a.title.localeCompare(b.title),
          );
          const newCheckedImages: CheckedImage[] = await Promise.all(imagesSorted.map(
            async (image) => {
              const books = await fetchUsedBooks(image.id);
              newUsedBooks[image.id] = books;
              return {
                id: image.id,
                title: image.title,
                canBeDeleted: books?.length === 0,
              };
            },
          ));
          setCheckedImages(newCheckedImages);
          break;
        }
        case LibraryType.Background: {
          const backgroundArtImages = images as BackgroundArtImage[];
          const imagesSorted: BackgroundArtImage[] = backgroundArtImages.sort(
            (a, b) => a.label.localeCompare(b.label),
          );
          const newCheckedImages: CheckedImage[] = await Promise.all(imagesSorted.map(
            async (image) => {
              const books = await fetchUsedBooks(image.id);
              newUsedBooks[image.id] = books;
              return {
                id: image.id,
                title: image.label,
                canBeDeleted: books?.length === 0,
              };
            },
          ));
          setCheckedImages(newCheckedImages);
          break;
        }
        default:
          break;
      }

      setUsedBooks(newUsedBooks);
      setIsLoading(false);
    })();
  }, [fetchUsedBooks, images, show, type]);

  const handleHide = () => {
    const { onHide } = props;
    setCheckedImages([]);
    onHide();
  };

  const handleDelete = (force = false) => {
    setIsLoading(true);
    const imagesToDelete = force
      ? checkedImages
      : checkedImages.filter((image) => image.canBeDeleted);
    const deletePromises = imagesToDelete.map((image) => {
      switch (type) {
        case LibraryType.Character:
          return apiService.deleteUsedArtBooksByCharacterId(image.id, force);
        case LibraryType.Background:
          return apiService.deleteUsedArtBooksByBackgroundId(image.id, force);
        default:
          return Promise.resolve();
      }
    });
    Promise.all(deletePromises)
      .then(() => {
        onDelete();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemoveFromTable = (id: number) => {
    setCheckedImages((prev) => prev.filter((image) => image.id !== id));
  };

  const handleUpdateFromTable = async (id: number) => {
    setIsLoading(true);
    try {
      const books = await fetchUsedBooks(id);
      setUsedBooks((prev) => ({ ...prev, [id]: books }));
      setCheckedImages((prev) => prev.map((image) => {
        if (image.id === id) {
          return {
            ...image,
            canBeDeleted: books.length === 0,
          };
        }
        return image;
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const willDisableDeleteButton = checkedImages.some((image) => !image.canBeDeleted)
    || isLoading
    || checkedImages.length === 0;

  const expandImagesRow = {
    renderer: (row: CheckedImage) => {
      const usedBook = usedBooks[row.id];
      if (!usedBook) {
        return (
          <Alert variant="danger">
            Error fetching books...
          </Alert>
        );
      }
      const books = usedBook.slice(0, 10);
      return (
        <>
          <div>
            {`Used in ${usedBook.length ?? '-'} books:`}
          </div>
          <ul>
            {books.map((book: { id: number, title: string}) => (
              <li key={book.id}>
                <Link
                  to={`/book/${book.id}`}
                  target="_blank"
                >
                  {`${book.title}`}
                </Link>
              </li>
            ))}
            {usedBook.length !== books.length && (
            <div>...</div>
            )}
          </ul>
        </>
      );
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
  };

  const isForcedDeleteVisible = checkedImages.some((image) => !image.canBeDeleted) && isAdmin;

  return (
    <Modal
      className="deleteModal"
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleHide}
      disabled={isLoading}
    >
      <Modal.Header closeButton={!isLoading}>
        <Modal.Title>Delete Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BootstrapTable
          hover
          condensed
          bootstrap4
          keyField="id"
          bordered={false}
          columns={
            deleteImageColumns(
              handleRemoveFromTable,
              handleUpdateFromTable,
              checkedImages.length < 2 || isLoading,
            )
          }
          data={checkedImages}
          expandRow={expandImagesRow}
        />

      </Modal.Body>
      <Modal.Footer>
        {isLoading && (
          <Spinner
            variant="primary"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        <Button
          type="reset"
          variant="secondary"
          onClick={handleHide}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => handleDelete(false)}
          hidden={isForcedDeleteVisible}
          disabled={willDisableDeleteButton}
        >
          Delete
        </Button>
        <Button
          type="button"
          variant="danger"
          onClick={() => handleDelete(true)}
          hidden={!isForcedDeleteVisible}
          disabled={isLoading}
        >
          Force Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
