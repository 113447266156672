import React, { Component } from 'react';
import {
  Alert, Button, Col, Modal, Spinner,
} from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { api } from '../../api';

export class DeleteBook extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      formError: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  deleteAction() {
    this.setState({ loading: true });
    const {
      obj,
      update,
      onHide,
    } = this.props;

    api.delete(`/v1/books/${obj.id}`)
      .then(() => {
        update();
        onHide();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        this.errorAlert(error.response.data.error);
      });
  }

  render() {
    const {
      update,
      obj,
      onHide,
      ...props
    } = this.props;
    const {
      loading,
      formError,
    } = this.state;

    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete:
            <span>
              &quot;
              {obj.title}
              &quot;
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row className={!formError ? 'd-none' : 'd-block'}>
            <Col md={12}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>
          </Row>

          <p className="text-center">
            Do you really want to delete the story?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
