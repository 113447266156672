import React from 'react';
import defaultAvatarIcon from '../../../../assets/images/avatar.png';
import { Author } from '../../../../dorian-shared/types/author/author';
import classNames from './PlayPreviewInfoSection.module.scss';

interface PlayPreviewInfoSectionProps {
    coverImage: string;
    title: string;
    authors: Author[];
    description: string;
}

export function PlayPreviewInfoSection(props: PlayPreviewInfoSectionProps) {
  const {
    coverImage, title, authors, description,
  } = props;

  return (
    <div className={classNames.container}>
      <div className={classNames.imageContainer}>
        <img src={coverImage ?? ''} alt="cover" className={classNames.image} />
      </div>
      <div className={classNames.titleContainer}>
        {title}
      </div>
      <div className={classNames.authorsContainer}>
        {authors.map((author) => (
          <div key={author.id} className={classNames.authorContainer}>
            <div className={classNames.authorIconContainer}>
              <img className={classNames.authorIcon} src={author.image?.imageUrl ?? defaultAvatarIcon} alt="icon" />
            </div>
            {`by ${author.username ?? `${author.firstName} ${author.lastName}`}`}
          </div>
        ))}
      </div>
      <div className={classNames.descriptionContainer}>
        {description}
      </div>
    </div>
  );
}
