import React, { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';

export function SampleRateChanger() {
  const [isLoading, setIsLoading] = useState(false);
  const [lastTransactionName, setLastTransactionName] = useState();

  const sleep = async (time = 500) => {
    await new Promise((resolve) => {
      setTimeout(resolve, time);
    });
    return undefined;
  };

  const doTransactions = async () => {
    const randomTransactionName = `Test sampleRate : ${Math.floor(Math.random() * 1000000)}`;
    for (let i = 0; i < 9; i++) {
      bugTracker().reportError({ name: randomTransactionName, message: `Report error with rate #${i}` });
      // eslint-disable-next-line no-await-in-loop
      await sleep(300);
    }
    return randomTransactionName;
  };

  const handleThrowErrorClick = (rate) => {
    setIsLoading(true);
    showToast({
      textMessage: 'The long run function has been started.',
      variant: 'info',
    });

    doTransactions(rate).then(
      (resultTransactionName) => {
        setLastTransactionName(resultTransactionName);
        showToast({
          textMessage: 'Test sampleRate has been done.',
          variant: 'info',
        });
      },
    ).finally(() => setIsLoading(false));
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Send error reports 9 times with SampleRate values.</Card.Title>
          <Alert variant="info">
            Transaction name:
            {' '}
            <b>{lastTransactionName || 'Click \'Do it...\''}</b>
          </Alert>
          <Button
            size="sm"
            variant="primary"
            disabled={isLoading}
            onClick={() => handleThrowErrorClick()}
            aria-controls="example-collapse-text"
          >
            Do it
          </Button>
          <br />
          You should see errors in the service.
        </Card.Body>
      </Card>
      {isLoading && (
      <div className="overlay" />
      )}
    </>
  );
}
