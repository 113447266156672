import { IFrontendBugTrackerConfig } from '@dorian/frontend-bug-tracker';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { bugTrackerInstance } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';
import classNames from '../AdminSupportPanel.module.scss';
import { BugTrackingSettingsForm } from './BugTrackingSettingsForm';

export function BugTrackingSettings() {
  const [bugTrackerConfig, setBugTrackerConfig] = useState<IFrontendBugTrackerConfig>();

  const [, {
    isError,
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const fetchConfig = useCallback(() => {
    setToLoading();
    bugTrackerInstance.getBugTrackerConfig().then((config) => {
      setBugTrackerConfig(config);
      setToSuccess();
    }).catch(() => {
      setToError();
    });
  }, [setToError, setToLoading, setToSuccess]);

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  const handleConfigChanged = (config: IFrontendBugTrackerConfig) => {
    bugTrackerInstance.updateBugTrackerConfig(config).then(() => {
      fetchConfig();
      showToast({ textMessage: 'Configuration saved.', variant: 'info' });
    }).catch(() => {
      showToast({ textMessage: 'Error saving configuration.', variant: 'error' });
    });
  };

  const handleConfigReload = () => {
    fetchConfig();
    showToast({ textMessage: 'Configuration reloaded.', variant: 'info' });
  };

  return (
    <div>
      <h2 className={classNames.header}>Bug tracking settings</h2>
      <Card>
        <Card.Body>
          {!isLoading && bugTrackerConfig && (
            <Alert variant="info">
              <div className={`${isLoading ? 'overlay' : ''}`} />
              <BugTrackingSettingsForm
                disabled
                bugTrackerConfig={bugTrackerConfig}
                onChange={handleConfigChanged}
                onReload={handleConfigReload}
              />
            </Alert>
          )}
          {isLoading && (
            <Alert variant="info">
              Loading configuration...
            </Alert>
          )}
          {isError && (
            <Alert variant="error">
              Error loading configuration
            </Alert>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
