import React, { PureComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DeleteStepModal } from './DeleteStepModal';

export class DeleteStep extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showModal: false,
    };
  }

  onHide = () => {
    this.setState({
      showModal: false,
    });
  };

  deleteStep = () => {
    const { deleteFunc, index: index1 } = this.props;

    const index = [];
    index.push(index1);
    deleteFunc(index);
    this.onHide();
  };

  render() {
    const { content, title, disabled } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Dropdown.Item
          disabled={disabled}
          onClick={() => {
            this.setState({
              showModal: true,
            });
          }}
        >
          Delete
        </Dropdown.Item>
        <DeleteStepModal
          show={showModal}
          onHide={this.onHide}
          onDelete={this.deleteStep}
          title={title}
          content={content}
        />
      </>
    );
  }
}
