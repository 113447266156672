import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { Redirect } from 'react-router-dom';
import imgWarning from '../../../../assets/images/ui/icon-shelf-newreleases.svg';
import { api } from '../../../api';
import classNames from './SaveAsPrompt.module.scss';

export class SaveAsPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      loading: false,
      errorMsg: null,
      successfulMsg: null,
      fromTemplate: false,
      title: '',
      titleValid: null,
      description: '',
      descriptionValid: null,
      redirect: null,
      isSpinOffRevenueShare: false,
      spinOffRevenueShareValue: 0,
    };
  }

  componentDidMount() {
    const { book, limits } = this.props;

    this.setState({
      fromTemplate: false,
      title: book.title,
      description: '',
      titleValid: limits?.story_temlate_title_max?.value,
    });
  }

  componentDidUpdate(prevProps) {
    const { show, book, limits } = this.props;

    if (
      book.title !== prevProps.book.title
      || show !== prevProps.show
    ) {
      this.setState({
        title: book.title,
        titleValid: limits?.story_temlate_title_max?.value,
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { spinOffRevenueShareValue, isSpinOffRevenueShare } = this.state;

    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      obj.spinOffRevenue = isSpinOffRevenueShare;
      obj.spinOffRevenueShare = spinOffRevenueShareValue;
      this.saveData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  saveData = (obj, validated) => {
    const { book, onValidationError } = this.props;

    if (validated === true) {
      this.setState({
        loading: true,
      });
      api.post(`/v1/books/${book.id}/saveasprompt`, obj)
        .then((res) => {
          this.successfulAlert('New story prompt created', res.data);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          if (!error || !error.response) {
            this.successfulAlert(null, null);
          }

          if (error && error.response && String(error.response.status) === '418') {
            onValidationError();
          } else if (error?.response?.data?.error) this.errorAlert(error.response.data.error);
        });
    }
  };

  successfulAlert = (val, data) => {
    this.setState({
      successfulMsg: data?.prompt?.id
        ? val
        : 'We are creating your prompt now. You will see it in your story list shortly',
    }, () => {
      setTimeout(() => {
        if (data && data.prompt && data.prompt.id) {
          window.location.assign(`/book/${data.prompt.id}`);
          return;
        }
        this.setState({
          successfulMsg: null,
        }, () => window.location.reload());
      }, 15000);
    });
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    }, () => {
      setTimeout(() => {
        this.setState({
          errorMsg: null,
        });
      }, 3000);
    });
  };

  render() {
    const {
      show, onHide, limits, book,
    } = this.props;

    const {
      isSpinOffRevenueShare,
      spinOffRevenueShareValue,
      loading,
      validated,
      titleValid,
      errorMsg,
      description,
      successfulMsg,
      redirect,
      descriptionValid,
      fromTemplate,
      title,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Save As Story Prompt</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row className={!errorMsg ? 'd-none' : 'd-block'}>
              <Col md={12}>
                <Alert variant="danger">
                  {errorMsg}
                </Alert>
              </Col>
            </Row>

            {
                successfulMsg
                  ? (
                    <Row>
                      <Col md={12}>
                        <Alert variant="success">
                          {successfulMsg}
                        </Alert>
                      </Col>
                    </Row>
                  )
                  : (
                    <>
                      <Row>
                        <Form.Group
                          as={Col}
                          controlId="templateName"
                        >
                          <Form.Label>Story Prompt Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            disabled={loading}
                            placeholder="Story Prompt Name"
                            defaultValue={book.title}
                            name="title"
                            onChange={(e) => {
                              this.setState({
                                title: e.target.value,
                                titleValid: limits?.story_temlate_title_max?.value,
                              });
                            }}
                            className={
                              title && title.length > Number(limits?.story_temlate_title_max?.value
                                ? limits.story_temlate_title_max.value
                                : 0) ? 'text-limit' : null
}
                          />
                          <Form.Text className="char-limit-info-box">
                            {Number(limits?.story_temlate_title_max?.value
                              ? limits.story_temlate_title_max.value
                              : 0) - (title ? title.length : 0)}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            Story Prompt Name is required.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group
                          as={Col}
                          controlId="templateName"
                        >
                          <Form.Label>Story Prompt Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            disabled={loading}
                            placeholder="Description"
                            defaultValue=""
                            name="description"
                            onChange={(e) => {
                              this.setState({
                                description: e.target.value,
                                descriptionValid: limits?.story_temlate_description_max?.value,
                              });
                            }}
                            className={(
                              description
                              && limits?.story_temlate_description_max?.value
                              // eslint-disable-next-line max-len
                              && description.length > Number(limits.story_temlate_description_max.value)
                                ? limits.story_temlate_description_max.value
                                : 0) ? 'text-limit' : ''}
                          />
                          <Form.Text className="char-limit-info-box">
                            {Number(limits?.story_temlate_description_max?.value
                              ? limits.story_temlate_description_max.value
                              : 0) - (description ? description.length : 0)}
                          </Form.Text>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          controlId="spinoffrevenue"
                        >
                          <div key="spinoffrevenue-1">
                            <Form.Check
                              label="The community is free to use this and claim all revenue from it"
                              name="sprv"
                              type="radio"
                              id="spinoffrevenue-1"
                              checked={(!isSpinOffRevenueShare)}
                              onChange={() => this.setState({ isSpinOffRevenueShare: false })}
                            />
                            <Form.Check
                              label="I claim ownership of this work and a percent of revenue of work based on it"
                              name="sprv"
                              type="radio"
                              id="spinoffrevenue-2"
                              checked={(isSpinOffRevenueShare)}
                              onChange={() => this.setState({ isSpinOffRevenueShare: true })}
                            />
                            {
                              isSpinOffRevenueShare && (
                                <div className={classNames.revenueShare}>
                                  <Form.Group>
                                    <Form.Label className={classNames.revenueShareLabel}>
                                      Percent of spin-off revenue claimed by author:
                                    </Form.Label>
                                    <RangeSlider
                                      min={0}
                                      max={100}
                                      step={5}
                                      size="sm"
                                      tooltip="on"
                                      name="spinOffRevenueShare"
                                      tooltipLabel={(currentValue) => `${Math.round(currentValue)}%`}
                                      value={spinOffRevenueShareValue * 100}
                                      onChange={(e) => {
                                        this.setState({
                                          spinOffRevenueShareValue: e.target.value / 100,
                                        });
                                      }}
                                    />
                                    <div className={classNames.revenueAnnounce}>
                                      You can change this later in the Story Settings menu.
                                    </div>
                                  </Form.Group>
                                </div>
                              )
                            }

                            <div className="d-flex align-items-center mt-2 mb-0" role="alert">
                              <img
                                src={imgWarning}
                                className={classNames.imgWarning}
                                alt="info"
                              />
                              <div className={classNames.warningText}>
                                When you select “Save As Story Prompt” your Prompt will be licensed
                                according to the
                                {' '}
                                <a
                                  href="/documents/Independent%20Writer%20Agreement"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Creator Agreement
                                </a>
                              </div>
                            </div>
                          </div>
                        </Form.Group>
                      </Row>
                    </>
                  )
}

          </Modal.Body>
          {
              !successfulMsg
              && (
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={onHide}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={
                    loading
                    || fromTemplate
                    || (description && description.length > Number(descriptionValid))
                    || (title && title.length > Number(titleValid))
                  }
                >
                  {loading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  )}
                  Save As Story Prompt
                </Button>
              </Modal.Footer>
              )
            }
        </Form>
      </Modal>
    );
  }
}
