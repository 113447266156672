import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class DeleteTag extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
    };
  }

  deleteAction() {
    this.setState({ showLoading: true });
    // eslint-disable-next-line react/destructuring-assignment
    this.props.onHide();
    // eslint-disable-next-line react/destructuring-assignment
    api.delete(`/v1/backgroundtags/${this.props.obj.id}`)
      .then(() => {
        // eslint-disable-next-line react/destructuring-assignment
        this.props.onHide();
      });
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {this.props.obj.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to delete this tag?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            /* eslint-disable-next-line react/destructuring-assignment */
            onClick={this.props.onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {/* eslint-disable-next-line react/destructuring-assignment */}
            {this.state.showLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
