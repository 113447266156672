import React from 'react';
import { Button } from 'react-bootstrap';
import imgDelete from '../../../../../../../assets/images/delete.svg';
import { imageFileToDataURL } from '../../../../../../../dorian-shared/components/ImageCropper/imageFileToDataURL';
import { TextField } from '../../../../../../ui/_fields/TextField/TextField';
import { FileInputValue, ImageInput } from '../../../ImageInput/ImageInput';
import { DetailedPrize } from '../DetailedPrizeType';

export const prizeLabelMaxLength = 50;
export interface DetailedPrizeCardProps {
    detailedPrize: DetailedPrize
    onChange: (detailedPrize: DetailedPrize) => void
    onDelete: () => void
    touched?: {
        label?: boolean,
        imageUrl?: boolean,
    }
    error?: {
        label: string,
        imageUrl: string,
    }
}

export function DetailedPrizeCard(props: DetailedPrizeCardProps) {
  const {
    detailedPrize,
    onChange,
    onDelete,
    touched,
    error,
  } = props;

  const handleImageChange = (fileInputValue: FileInputValue) => {
    imageFileToDataURL(fileInputValue.file)
      .then((dataURL) => onChange({
        ...detailedPrize,
        imageUrl: dataURL,
        image: fileInputValue,
      }));
  };

  return (
    <>
      <TextField
        label="Event Prize`s Label"
        placeholder="Label for the prize"
        value={detailedPrize.label}
        onChange={(event) => onChange({
          ...detailedPrize,
          label: event.target.value,
        })}
        error={touched?.label ? error?.label : ''}
        maxLength={prizeLabelMaxLength}
      />
      <ImageInput
        initialURL={detailedPrize.imageUrl}
        onConfirm={handleImageChange}
        getBlobOptions={{
          width: 100,
          height: 100,
          imageSmoothingQuality: 'high',
        }}
        imageCropperProps={{
          viewMode: 0,
          dragMode: 'move',
          minCropBoxHeight: 0,
        }}
        accept="image/png"
        error={touched?.imageUrl ? error?.imageUrl : ''}
      />
      <Button
        onClick={onDelete}
        variant="secondary"
        size="sm"
        className="mx-1"
      >
        <img src={imgDelete} className="btnImg" alt="Delete" />
      </Button>
    </>
  );
}
