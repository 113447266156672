import moment from 'moment';
import React from 'react';
// @ts-ignore: no def file
import { Link } from 'react-router-dom';
import imgInfo from '../../../../assets/images/info.svg';
import { Auth } from '../../../Auth/Auth';
import { Authors } from '../Authors';

const auth = new Auth();
const user = auth.getUser();

const getBookIdByRow = (row) => {
  if (row.bookId) {
    return row.bookId;
  }
  if (row.book && row.book.id) {
    return row.book.id;
  }
  // TODO: Add sentry
  return undefined;
};

const getEpisodeIdByRow = (row) => {
  const bookTitle = row.book ? row.book.title : '';

  if (row.id) {
    return { id: row.id, bookTitle };
  }
  if (row.storyId) {
    return { id: row.storyId, bookTitle };
  }
  if (row.story && row.story.id) {
    return { id: row.story.id, bookTitle };
  }
  // TODO: Add sentry
  return { id: undefined, bookTitle };
};

export const bookTitleFormatter = (cell, row) => {
  const id = getBookIdByRow(row);
  const { missingStoryData } = row;
  return (
    <div>
      {missingStoryData
        ? (
          <div title="Missing Story data">
            -
          </div>
        )
        : (
          <>
            <Link
              className="books-list-link"
              style={{ overflowWrap: 'break-word' }}
              to={`/book/${id}`}
              target="_blank"
            >
              {cell}
            </Link>
            <Link
              className="books-list-link"
              style={{ overflowWrap: 'break-word' }}
              to={`/performance/dailyreads/${id}`}
              target="_blank"
            >
              {' '}
              <img
                src={imgInfo}
                className="dailyReads"
                alt="Story Daily Reads"
              />
            </Link>
          </>
        )}
    </div>
  );
};

export const storyTitleFormatter = (cell, row) => {
  const { id, bookTitle } = getEpisodeIdByRow(row);
  const popupTitle = bookTitle ? `Story: ${bookTitle}` : '';
  const { missingStoryData } = row;
  return (
    <div>
      {missingStoryData
        ? (
          <div title="Missing Story data">
            -
          </div>
        )
        : (
          <>
            <Link
              className="books-list-link"
              style={{ overflowWrap: 'break-word' }}
              to={`/stories/${id}/branches`}
              target="_blank"
              title={popupTitle}
            >
              {cell}
            </Link>
            <Link
              className="books-list-link"
              style={{ overflowWrap: 'break-word' }}
              to={`/performance/episodedailyreads/${id}`}
              target="_blank"
              title={popupTitle}
            >
              {' '}
              <img
                src={imgInfo}
                className="dailyReads"
                alt="Episode Daily Reads"
              />
            </Link>
          </>
        )}
    </div>
  );
};

export const authorsFormatter = (cell) => <Authors authors={cell} user={user} />;

export const ownerFormatter = (cell) => <Authors authors={[cell]} user={user} />;

export const nodeTitleFormatter = (cell) => (
  <span style={{ overflowWrap: 'break-word' }}>
    {cell}
  </span>
);

export const floatFormatter = (cell) => {
  const value = cell || 0;
  return (
    <span>{value.toFixed(2)}</span>
  );
};

export const dateFormatter = (cell) => {
  const value = moment(cell).format('YYYY-MM-DD');
  return (
    <span>{value}</span>
  );
};
