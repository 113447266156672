/* eslint-disable max-len */
import React, { Component } from 'react';

export class PrivacyPolicyContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="privacy-policy-content">

        <h6>Last updated January 4, 2021</h6>

        <p>
          Thank you for choosing to be part of our community at Dorian Inc. (“
          <strong>Company</strong>
          ”,
          “
          <strong>we</strong>
          ”, “
          <strong>us</strong>
          ”, or “
          <strong>our</strong>
          ”). We are
          committed to protecting your personal information and your right to privacy. If you have any questions or
          concerns about our policy, or our practices with regards to your personal information, please contact us
          at support@dorian.live.
        </p>

        <p>
          When you visit our website www.dorian.live, mobile application, and use our services, you trust us with your
          personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy
          policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and
          what rights you have in relation to it. We hope you take some time to read through it carefully, as it is
          important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of
          our Sites or Apps and our services.
        </p>

        <p>
          This privacy policy applies to all information collected through our website (such as www.dorian.live),
          mobile
          application, (“
          <strong>Apps</strong>
          “), and/or any related services, sales, marketing or events (we refer to
          them collectively in
          this privacy policy as the “
          <strong>Sites</strong>
          “).
        </p>

        <h6>
          Please read this privacy policy carefully as it will help you make informed decisions about sharing your
          personal information with us.
        </h6>

        <h3>TABLE OF CONTENTS</h3>
        <ol>
          <li><a className="mainPageLinks" href="#info-collect">WHAT INFORMATION DO WE COLLECT?</a></li>
          <li><a className="mainPageLinks" href="#info-use">HOW DO WE USE YOUR INFORMATION?</a></li>
          <li><a className="mainPageLinks" href="#info-shared">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
          <li>
            <a className="mainPageLinks" href="#cookies-tracking">
              DO WE USE COOKIES AND OTHER TRACKING
              TECHNOLOGIES?
            </a>
          </li>
          <li><a className="mainPageLinks" href="#social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></li>
          <li><a className="mainPageLinks" href="#third-party">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</a></li>
          <li><a className="mainPageLinks" href="#info-time-kept">HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
          <li><a className="mainPageLinks" href="#info-safety">HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
          <li><a className="mainPageLinks" href="#info-minors">DO WE COLLECT INFORMATION FROM MINORS?</a></li>
          <li><a className="mainPageLinks" href="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
          <li><a className="mainPageLinks" href="#do-not-track">CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
          <li>
            <a className="mainPageLinks" href="#do-not-track">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
              RIGHTS?
            </a>
          </li>
          <li><a className="mainPageLinks" href="#privacy-updates">DO WE MAKE UPDATES TO THIS POLICY?</a></li>
          <li><a className="mainPageLinks" href="#contact">HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a></li>
        </ol>

        <h3 id="info-collect">1. WHAT INFORMATION DO WE COLLECT?</h3>
        <h6>Personal information you disclose to us</h6>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We collect personal information that you provide to us such as name, address, contact
            information, passwords and security data, payment information, and social media login data.
          </i>
        </p>

        <p>
          We collect personal information that you voluntarily provide to us when registering at the Sites or Apps,
          expressing an interest in obtaining information about us or our products and services, when participating in
          activities on the Sites or Apps (such as posting messages in our online forums or entering competitions,
          contests or giveaways) or otherwise contacting us.
        </p>

        <p>
          The personal information that we collect depends on the context of your interactions with us and the Sites or
          Apps, the choices you make and the products and features you use. The personal information we collect can
          include the following:
        </p>

        <p>
          <b>Name and Contact Data.</b>
          {' '}
          We collect your first and last name, email address, postal address, phone
          number, and other similar contact data.
        </p>

        <p>
          <b>Credentials.</b>
          {' '}
          We collect passwords, password hints, and similar security information used for
          authentication and account access.
        </p>

        <p>
          <b>Payment Data.</b>
          {' '}
          We collect data necessary to process your payment if you make purchases, such as your
          payment instrument number (such as a credit card number), and the security code associated with your payment
          instrument. All payment data is stored by our payment processor and you should review its privacy policies and
          contact the payment processor directly to respond to your questions.
        </p>

        <p>
          <b>Social Media Login Data.</b>
          {' '}
          We provide you with the option to register using social media account
          details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we
          will collect the Information described in the section called “HOW DO WE HANDLE YOUR SOCIAL LOGINS” below.
        </p>

        <p>
          All personal information that you provide to us must be true, complete and accurate, and you must notify us
          of any changes to such personal information.
        </p>

        <h6>Information automatically collected</h6>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            Some information – such as IP address and/or browser and device characteristics – is
            collected automatically when you visit our Sites or Apps.
          </i>
        </p>

        <p>
          We automatically collect certain information when you visit, use or navigate the Sites or Apps. This
          information does not reveal your specific identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and device characteristics, operating system,
          language preferences, referring URLs, device name, country, location, information about how and when you use
          our Sites or Apps and other technical information. This information is primarily needed to maintain the
          security and operation of our Sites or Apps, and for our internal analytics and reporting purposes.
        </p>

        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>

        <h6>Information collected through our Apps</h6>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We may collect information regarding your mobile device, push notifications, when you use
            our apps.
          </i>
        </p>

        <p>If you use our Apps, we may also collect the following information:</p>

        <ul>
          <li>
            <i>Mobile Device Access.</i>
            {' '}
            We may request access or permission to certain features from your mobile
            device, including your mobile device’s camera, contacts, microphone, reminders, social media accounts, and
            other features. If you wish to change our access or permissions, you may do so in your device’s settings.
          </li>
          <li>
            <i>Mobile Device Data.</i>
            {' '}
            We may automatically collect device information (such as your mobile device ID,
            model and manufacturer), operating system, version information and IP address.
          </li>
          <li>
            <i>Push Notifications.</i>
            {' '}
            We may request to send you push notifications regarding your account or the
            mobile application. If you wish to opt-out from receiving these types of communications, you may turn them
            off in your device’s settings.
          </li>
        </ul>

        <h6>Information collected from other sources</h6>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We may collect limited data from public databases, marketing partners, social media
            platforms, and other outside sources.
          </i>
        </p>

        <p>
          We may obtain information about you from other sources, such as public databases, joint marketing partners,
          social media platforms (such as Facebook), as well as from other third parties. Examples of the information we
          receive from other sources include: social media profile information (your name, gender, birthday, email,
          current city, state and country, user identification numbers for your contacts, profile picture URL and any
          other information that you choose to make public); marketing leads and search results and links, including
          paid listings (such as sponsored links).
        </p>

        <h3 id="info-use">2. HOW DO WE USE YOUR INFORMATION?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We process your information for purposes based on legitimate business interests, the
            fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
          </i>
        </p>

        <p>
          We use personal information collected via our Sites or Apps for a variety of business purposes described
          below. We process your personal information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance
          with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed
          below.
        </p>

        <p>We use the information we collect or receive:</p>

        <ul>
          <li>
            <b>To facilitate account creation and logon process.</b>
            {' '}
            If you choose to link your account with us to a
            third party account *(such as your Google or Facebook account), we use the information you allowed us to
            collect from those third parties to facilitate account creation and logon process. See the section below
            headed “HOW DO WE HANDLE YOUR SOCIAL LOGINS” for further information.
          </li>
          <li>
            <b>To send administrative information to you.</b>
            {' '}
            We may use your personal information to send you product,
            service and new feature information and/or information about changes to our terms, conditions, and policies.
          </li>
          <li>
            <b>Fulfill and manage your orders.</b>
            {' '}
            We may use your information to fulfill and manage your orders,
            payments, returns, and exchanges made through the Sites or Apps.
          </li>
          <li>
            <b>Deliver targeted advertising to you.</b>
            {' '}
            We may use your information to develop and display content and
            advertising (and work with third parties who do so) tailored to your interests and/or location and to
            measure its effectiveness.
          </li>
          <li>
            <b>Request Feedback.</b>
            {' '}
            We may use your information to request feedback and to contact you about your use
            of our Sitesor Apps.
          </li>
          <li>
            <b>To protect our Sites.</b>
            {' '}
            We may use your information as part of our efforts to keep our Sites or Apps
            safe and secure (for example, for fraud monitoring and prevention).
          </li>
          <li>
            <b>To enable user-to-user communications.</b>
            {' '}
            We may use your information in order to enable user-to-user
            communications with each user’s consent.
          </li>
          <li>
            <b>For other Business Purposes.</b>
            {' '}
            We may use your information for other Business Purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Sites or Apps, products, services, marketing and your experience.
          </li>
        </ul>

        <h3 id="info-shared">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We only share information with your consent, to comply with laws, to protect your rights,
            or to fulfill business obligations.
          </i>
        </p>

        <p>We may process or share data based on the following legal basis:</p>

        <ul>
          <li>
            <b>Consent:</b>
            {' '}
            We may process your data if you have given us specific consent to use your personal
            information in a specific purpose.
          </li>
          <li>
            <b>Legitimate Interests:</b>
            {' '}
            We may process your data when it is reasonably necessary to achieve our
            legitimate business interests.
          </li>
          <li>
            <b>Performance of a Contract:</b>
            {' '}
            Where we have entered into a contract with you, we may process your
            personal information to fulfill the terms of our contract.
          </li>
          <li>
            <b>Legal Obligations:</b>
            {' '}
            We may disclose your information where we are legally required to do so in order
            to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
            such as in response to a court order or a subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </li>
          <li>
            <b>Vital Interests:</b>
            {' '}
            We may disclose your information where we believe it is necessary to investigate,
            prevent, or take action regarding potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal activities, or as evidence in litigation
            in which we are involved.
          </li>
        </ul>

        <p>
          More specifically, we may need to process your data or share your personal information in the following
          situations:
        </p>

        <ul>
          <li>
            <b>Vendors, Consultants and Other Third-Party Service Providers.</b>
            {' '}
            We may share your data with third party
            vendors, service providers, contractors or agents who perform services for us or on our behalf and require
            access to such information to do that work. Examples include: payment processing, data analysis, email
            delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to
            use tracking technology on the Sites or Apps, which will enable them to collect data about how you interact
            with the Sites or Apps over time. This information may be used to, among other things, analyze and track
            data, determine the popularity of certain content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your information with third parties for their
            promotional purposes.
          </li>
          <li>
            <b>Business Transfers.</b>
            {' '}
            We may share or transfer your information in connection with, or during
            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
          <li>
            <b>Third-Party Advertisers.</b>
            {' '}
            We may use third-party advertising companies to serve ads when you visit the
            Sites or Apps. These companies may use information about your visits to our Website(s) and other websites
            that are contained in web cookies and other tracking technologies in order to provide advertisements about
            goods and services of interest to you.
          </li>
          <li>
            <b>Business Partners.</b>
            {' '}
            We may share your information with our business partners to offer you certain
            products, services or promotions.
          </li>
          <li>
            <b>Offer Wall.</b>
            {' '}
            Our Apps may display a third-party hosted “offer wall.” Such an offer wall allows
            third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance
            and completion of an advertisement offer. Such an offer wall may appear in our mobile application and be
            displayed to you based on certain data, such as your geographic area or demographic information. When you
            click on an offer wall, you will leave our mobile application. A unique identifier, such as your user ID,
            will be shared with the offer wall provider in order to prevent fraud and properly credit your account.
          </li>
        </ul>

        <h3 id="cookies-tracking">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We may use cookies and other tracking technologies to collect and store your
            information.
          </i>
        </p>

        <p>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy.
        </p>

        <h3 id="social-logins">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            If you choose to register or log in to our websites using a social media account, we may
            have access to certain information about you.
          </i>
        </p>

        <p>
          Our Sites or Apps offer you the ability to register and login using your third party social media account
          details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile Information we receive may vary depending
          on the social media provider concerned, but will often include your name, e-mail address, friends list,
          profile picture as well as other information you choose to make public.
        </p>

        <p>
          We will use the information we receive only for the purposes that are described in this privacy policy or
          that are otherwise made clear to you on the Sites or Apps. Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third party social media provider. We
          recommend that you review their privacy policy to understand how they collect, use and share your personal
          information, and how you can set your privacy preferences on their sites and apps.
        </p>

        <h3 id="third-party">6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We are not responsible for the safety of any information that you share with third-party
            providers who advertise, but are not affiliated with, our websites.
          </i>
        </p>

        <p>
          The Sites or Apps may contain advertisements from third parties that are not affiliated with us and which may
          link to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of
          data you provide to any third parties. Any data collected by third parties is not covered by this privacy
          policy. We are not responsible for the content or privacy and security practices and policies of any third
          parties, including other websites, services or applications that may be linked to or from the Sites or Apps.
          You should review the policies of such third parties and contact them directly to respond to your
          questions.
        </p>

        <h3 id="info-time-kept">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We keep your information for as long as necessary to fulfill the purposes outlined in
            this privacy policy unless otherwise required by law.
          </i>
        </p>

        <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or
          other legal requirements). No purpose in this policy will require us keeping your personal information for
          longer than 90 days past the termination of the user’s account.
        </p>

        <p>
          When we have no ongoing legitimate business need to process your personal information, we will either delete
          or anonymize it, or, if this is not possible (for example, because your personal information has been stored
          in backup archives), then we will securely store your personal information and isolate it from any further
          processing until deletion is possible.
        </p>

        <h3 id="info-safety">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>
            We aim to protect your personal information through a system of organizational and
            technical security measures.
          </i>
        </p>

        <p>
          We have implemented appropriate technical and organizational security measures designed to protect the
          security of any personal information we process. However, please also remember that we cannot guarantee that
          the internet itself is 100% secure. Although we will do our best to protect your personal information,
          transmission of personal information to and from our Sites or Apps is at your own risk. You should only access
          the services within a secure environment.
          {' '}
        </p>

        <h3 id="info-minors">9. DO WE COLLECT INFORMATION FROM MINORS?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <u>
            The Sites are not directed to children under 13 (or other age as required by local law),
            and we do not knowingly collect personal information from children.
          </u>
        </p>

        <p>
          If you are a parent or guardian and believe your child has uploaded personal information to our site without
          your consent, you may contact us as described
          {' '}
          <a href="#contact">below</a>
          . If we become
          aware
          that a child has provided us with personal information in violation of applicable law, we will delete any
          personal information we have collected, unless we have a legal obligation to keep it, and terminate the
          child’s
          account, if applicable.
        </p>

        <h3 id="privacy-rights">10. WHAT ARE YOUR PRIVACY RIGHTS?</h3>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            In some regions, such as the European Economic Area, you have rights that allow you
            greater access to and control over your personal information. You may review, change, or terminate your
            account at any time.
          </i>
        </p>

        <p>
          In some regions (like the European Economic Area), you have certain rights under applicable data protection
          laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii)
          to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv)
          if applicable, to data portability. In certain circumstances, you may also have the right to object to the
          processing of your personal information. To make such a request, please use the contact details provided
          below. We will consider and act upon any request in accordance with applicable data protection laws.
        </p>

        <p>
          If we are relying on your consent to process your personal information, you have the right to withdraw your
          consent at any time. Please note however that this will not affect the lawfulness of the processing before its
          withdrawal.
        </p>

        <p>
          If you are resident in the European Economic Area and you believe we are unlawfully processing your personal
          information, you also have the right to complain to your local data protection supervisory authority. You can
          find their contact details here:
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </p>

        <h6>Account Information</h6>

        <p>
          If you would at any time like to review or change the information in your account or terminate your account,
          you can:
        </p>

        <ul>
          <li> Log into your account settings and update your user account.</li>
        </ul>

        <p>
          Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
        </p>

        <p>
          <b><u>Cookies and similar technologies:</u></b>
          {' '}
          Most Web browsers are set to accept cookies by default. If
          you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose
          to remove cookies or reject cookies, this could affect certain features or services of our Sites or Apps. To
          opt-out of interest-based advertising by advertisers on our Sites or Apps visit
          http://www.aboutads.info/choices/ .
        </p>

        <p>
          <b><u>Opting out of email marketing:</u></b>
          {' '}
          You can unsubscribe from our marketing email list at any time by
          clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided
          below. You will then be removed from the marketing email list – however, we will still need to send you
          service-related emails that are necessary for the administration and use of your account. To otherwise
          opt-out, you may:
        </p>

        <ul>
          <li> Note your preferences when you register an account with the site.</li>
        </ul>

        <h3 id="do-not-track">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
        <p>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”)
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. No uniform technology standard for recognizing and implementing
          DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be tracked online. If a standard for online
          tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
          version of this Privacy Policy.
        </p>

        <h3 id="do-not-track">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>

        <p>
          <b>In Short:</b>
          {' '}
          <i>
            Yes, if you are a resident of California, you are granted specific rights regarding
            access to your personal information.
          </i>
        </p>

        <p>
          California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are
          California residents to request and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third parties for direct marketing purposes and
          the names and addresses of all third parties with which we shared personal information in the immediately
          preceding calendar year. If you are a California resident and would like to make such a request, please submit
          your request in writing to us using the contact information provided below.
        </p>

        <p>
          If you are under 18 years of age, reside in California, and have a registered account with the Sites or Apps,
          you have the right to request removal of unwanted data that you publicly post on the Sites or Apps. To request
          removal of such data, please contact us using the contact information provided below, and include the email
          address associated with your account and a statement that you reside in California. We will make sure the data
          is not publicly displayed on the Sites or Apps, but please be aware that the data may not be completely or
          comprehensively removed from our systems.
        </p>

        <h3 id="privacy-updates">13. DO WE MAKE UPDATES TO THIS POLICY?</h3>
        <p>
          <b>In Short:</b>
          {' '}
          <i>Yes, we will update this policy as necessary to stay compliant with relevant laws.</i>
        </p>

        <p>
          We may update this privacy policy from time to time. The updated version will be indicated by an updated
          “Revised” date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy policy frequently to be informed
          of how we are protecting your information.
        </p>

        <h3 id="contact">14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h3>
        <p>
          If you have questions or comments about this policy, you may email us at support@dorian.live or by post to:
        </p>

        <p>
          Dorian Inc.
          <br />
          440 N BARRANCA AVE #5571
          <br />
          COVINA, CA 91723
          <br />
          United States
        </p>

      </div>
    );
  }
}
