import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';

export class ImportStories extends Component {
  constructor(...args) {
    super(...args);
    const { storyOverwrite } = this.props;

    this.state = {
      loading: false,
      validated: false,
      file: null,
      formError: null,
      title: !storyOverwrite ? '' : storyOverwrite.title,
      episodeErrorLimit: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
      loading: false,
    });

    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  handleSubmit(event) {
    const {
      limits, update, onHide, storyOverwrite, currentFolder, bookID,
    } = this.props;
    const { file } = this.state;

    this.setState({
      loading: true,
    });

    event.preventDefault();
    const form = event.currentTarget;
    const title = event.target.elements.title.value;

    if (title && title.length > Number(limits.story_title_max.value)) {
      this.setState({
        episodeErrorLimit: true,
        loading: false,
      });
      event.stopPropagation();
      return;
    }

    const formData = new FormData();
    formData.append('storyFile', file);
    formData.append('title', title);
    if (storyOverwrite) {
      formData.append('id', storyOverwrite.id);
    }

    if (currentFolder) {
      formData.append('folderId', currentFolder);
    }

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({
        loading: false,
      });
    } else {
      const url = bookID ? `/v1/books/${bookID}/chapters/import` : '/v1/stories/import';
      api.post(url, formData, config)
        .then(() => {
          update();
          onHide();
        })
        .catch((error) => {
          this.errorAlert(error.response.data.error);
          this.setState({
            loading: false,
          });
          if (error.response.status === 401) {
            window.location.assign('/logout');
          }
        });
    }
    this.setState({
      validated: true,
    });
    event.stopPropagation();
  }

  onChangeFile(e) {
    const file = e.target.files[0];
    this.setState({
      file,
    });
  }

  render() {
    const {
      onHide,
      limits,
      disabled,
      storyOverwrite,
      ...other
    } = this.props;
    const {
      validated,
      title,
      episodeErrorLimit,
      loading,
      formError,
    } = this.state;

    const modalTitle = storyOverwrite ? 'Overwrite Episode' : 'Import Episode';

    const maxTitleLength = Number(limits.story_title_max.value);
    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} md="6" controlId="title">
                <Form.Label>Episode Title</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Episode Title"
                  name="title"
                  pattern="^([A-Za-z]|[0-9]|_|-| )+$"
                  value={title}
                  className={title && title.length > maxTitleLength ? 'text-limit' : null}
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState({
                      episodeErrorLimit: value && value.length > maxTitleLength,
                      title: value,
                    });
                  }}
                />
                <Form.Text className="char-limit-info-box">
                  {maxTitleLength - (title ? title.length : 0)}
                </Form.Text>

                <Form.Control.Feedback
                  type="invalid"
                  className={episodeErrorLimit ? 'd-block' : 'd-none'}
                >
                  Episode Title is too long.
                </Form.Control.Feedback>

                <Form.Control.Feedback type="invalid">
                  Please choose a valid episode title.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} md="6" controlId="storyFile">
                <Form.Label>File</Form.Label>
                <Form.Control
                  required
                  type="file"
                  placeholder="File"
                  name="storyFile"
                  accept=".json"
                  onChange={(e) => this.onChangeFile(e)}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a episode file.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loading || disabled}
            >
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Import
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
