// @ts-ignore: no def file
import { omit } from 'lodash-es';
import { useState } from 'react';
// @ts-ignore: no def file
import { TableChangeState } from 'react-bootstrap-table-next';
import { FetchParams, formatFilterParams, formatOrderParams } from './performanceUtils';

export const usePerformanceTableChange = (pageSize: number) => {
  const initialParams: FetchParams = {
    offset: 0,
    limit: pageSize,
  };
  const [totalCount, setTotalCount] = useState(0);
  const [requestParams, setRequestParams] = useState(initialParams);

  const handlePageChange = (page: number) => {
    setRequestParams({ ...requestParams, offset: pageSize * (page - 1) });
  };

  const handleTableChange = (type: string, tableState: TableChangeState<[]>) => {
    switch (type) {
      case 'filter': {
        const filterParams = formatFilterParams(tableState.filters);
        const newParams = filterParams.length > 0
          ? { ...requestParams, search: filterParams.join(',') }
          : omit(requestParams, 'search');
        setRequestParams({ ...newParams, offset: 0 });
        break;
      }
      case 'sort': {
        const sortParams = formatOrderParams(tableState);
        const newParams = sortParams.length > 1
          ? { ...requestParams, order: sortParams }
          : omit(requestParams, 'order');
        setRequestParams({ ...newParams, offset: 0 });
        break;
      }
      default:
        break;
    }
  };

  const [tableData, setTableData] = useState([]);

  const getCurrentPage = () => {
    const { limit, offset } = requestParams;
    if (!offset) {
      return 1;
    }
    const currentLimit = limit ?? pageSize;
    return offset / currentLimit + 1;
  };

  return {
    currentPage: getCurrentPage(),
    totalCount,
    setTotalCount,
    requestParams,
    handlePageChange,
    handleTableChange,
    tableData,
    setTableData,
  };
};
