import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { BookLocation } from '../../../../dorian-shared/types/bookLocation/bookLocation';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import { Character } from '../../../../dorian-shared/types/character/Character';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { MemoryDTO } from '../../Book/MemoryBank/memoryBankTypes';
import { EpisodeTextImport } from '../Models/EpisodeTextImport';
import { EpisodeTextImportHelper } from '../Models/EpisodeTextImportHelper';
import { useImportTextColumns } from './useImportTextColumns';

export function useImportTextTableData(
  nodes: Branch[],
  characters: Character[] | null,
  bookLocations: BookLocation[] | null,
  memoryBank: MemoryDTO[] | null,
) {
  const [tableData, setTableData] = useState<any[]>([]);
  const [text, setText] = useState<string>('');
  const [episodeTextImport, setEpisodeTextImport] = useState<EpisodeTextImport>();

  const handleValidateButton = () => {
    if (isEmpty(text)) {
      return;
    }

    const newEpisodeTextImport = EpisodeTextImportHelper.parseTextToEpisodeText(text);
    if (!newEpisodeTextImport) {
      showToast({ textMessage: 'Error in parsing text. Please check the format of the importing text.' });
      logger.error('[ImportTextModal] Error in parsing text. Please check the format of the importing text.');
      return;
    }
    setEpisodeTextImport(newEpisodeTextImport);
    logger.debug('newEpisodeTextImport', newEpisodeTextImport);

    const newTableData = newEpisodeTextImport.validateAndCreateTable(
      nodes,
      characters?.map((character) => character.alias) ?? [],
      bookLocations ?? [],
      memoryBank ?? [],
    );
    setTableData(newTableData);
    logger.debug('tableData', newTableData);
  };

  const columns = useImportTextColumns();
  return {
    tableData, setTableData, text, setText, episodeTextImport, handleValidateButton, columns,
  };
}
