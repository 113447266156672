import React from 'react';
import { Card } from 'react-bootstrap';
// @ts-ignore: no def file
import { RouteComponentProps } from 'react-router';
import { PageWrapper } from '../../../ui/PageWrapper';
import { showToast } from '../../../ui/utils';
import { EventForm, EventFormValues, createInitialFormValues } from '../components/EventForm/EventForm';
import { createPrizes } from '../prizesAPI';
import { useCreateEvent } from './useCreateEvent';

function toastError(textMessage: string) {
  showToast({
    textMessage,
    timeout: 20000,
  });
}

function getPageLayout(history: History) {
  return {
    header: {
      title: 'Create New Event',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
        {
          title: 'Events',
          href: '/events',
        },
      ],
    },
  };
}

export function NewEventItemPage(props: RouteComponentProps) {
  const {
    history,
  } = props;
  const pageLayout = getPageLayout(history);

  const { isLoading, createEvent } = useCreateEvent();

  const handleEventFormSubmit = (values: EventFormValues) => {
    const { detailedEventPrizes, ...eventValues } = values;
    createEvent(eventValues)
      .then((eventId) => createPrizes(detailedEventPrizes, eventId)
        .then(() => history.push(`/events/${eventId}`)))
      .catch((error) => toastError(error.message));
  };
  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <Card>
        <Card.Body>
          <Card.Title>
            Create new event
          </Card.Title>
          <EventForm
            eventFormValues={createInitialFormValues()}
            onSubmit={handleEventFormSubmit}
            isLoading={isLoading}
            submitButtonText="Create"
          />
        </Card.Body>
      </Card>
    </PageWrapper>
  );
}
