// eslint-disable-next-line no-shadow
export enum TagGroupId {
    ArtStyle = 4,
    MainCharacter = 3,
    LoveInterest = 2,
    Genre = 1,
}

export type StoryTag = {
    id: number;
    title: string;
    createdAt: string;
    updatedAt: string;
    groupId: TagGroupId
}
