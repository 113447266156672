import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Button, Col, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEditStory from '../../../assets/images/edit-story.png';
import styles from '../Characters/Characters.scss';
import { PreviewImg } from '../Characters/PreviewImg';
import { LocationsDelete } from './LocationsDelete';

const cs = classNames.bind(styles);

export class LocationsTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteLocationsShow: false,
    };
  }

  static imageAlias(obj) {
    return obj.image ? obj.image.alias : null;
  }

  render() {
    const {
      obj, args, disabled,
    } = this.props;
    const { modalDeleteLocationsShow } = this.state;

    const modalDeleteLocationsClose = () => {
      this.setState({ modalDeleteLocationsShow: false });
      args.update();
    };

    const isDeleteButtonDisabled = disabled
        || args.deleteDisabled
        || obj.branches_count > 0
        || obj.stories_count > 0
        || obj.isDefault;
    return (
      <Row className="py-2 border-bottom">
        <Col md={1} className="text-center">
          {obj.isDefault}
          <div className={cs(obj.isDefault === true ? 'checked' : null, 'circleBox')} />
        </Col>
        <Col md={4}>
          {obj.title}
        </Col>
        <Col md={3}>
          {LocationsTableRow.imageAlias(obj)}
        </Col>
        <Col md={2}>
          <PreviewImg
            className="PreviewImg-36"
            style={{ maxWidth: '36px', maxHeight: '36px', overflow: 'hidden' }}
            character={obj}
          />
        </Col>
        <Col md={2} className="text-right">
          <OverlayTrigger
            overlay={(
              <Tooltip id="tooltip-top">
                Edit
              </Tooltip>
            )}
          >
            <Button
              disabled={disabled || args.editDisabled}
              onClick={() => {
                args.onStartEdit(obj);
              }}
              variant="secondary"
              size="sm"
              className="mx-1"
            >
              <img src={imgEditStory} className="btnImg" alt="Edit" />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={(
              <Tooltip id="tooltip-top">
                Delete
              </Tooltip>
            )}
          >
            <Button
              disabled={isDeleteButtonDisabled}
              onClick={() => this.setState({ modalDeleteLocationsShow: true })}
              variant="secondary"
              size="sm"
              className="mx-1"
            >
              <img src={imgDelete} className="btnImg" alt="Delete" />
            </Button>
          </OverlayTrigger>
        </Col>
        {modalDeleteLocationsShow === true && (
          <LocationsDelete
            show={modalDeleteLocationsShow}
            onHide={modalDeleteLocationsClose}
            obj={obj}
            args={args}
          />
        )}
      </Row>
    );
  }
}
