import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { markupToHTML } from '../../../../helpers/markupToHTML';
import { api } from '../../../api';
import './ApproveDocumentModal.scss';

export function ApproveDocumentModal(props) {
  const {
    document,
    onCancel,
    onSuccess,
    onError,
  } = props;

  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const onAcceptClick = () => {
    setToLoading();
    api.put('v1/user/accept', { uuids: [document.uuid] })
      .then(() => {
        setToSuccess();
        onSuccess();
      })
      .catch(() => {
        setToError();
        onError();
      });
  };

  return (
    <Modal
      show
      size="xl"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <Modal.Title style={{ whiteSpace: 'normal', textAlign: 'center' }}>
          {document.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="terms">
          {document && document.title && (
            <>
              <h1>{document.title}</h1>
              {document.summary && (
              <div
                style={{ padding: '1em 0' }}
                          // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: markupToHTML(document.summary) }}
              />
              )}
            </>
          )}
          {document && document.body && (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: markupToHTML(document.body) }} />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="primary"
          onClick={onAcceptClick}
        >
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
