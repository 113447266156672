import arrayMove from 'array-move';
import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Row,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import imgEdit from '../../../../../assets/images/edit-branch.svg';
import { api } from '../../../../api';
import styles from '../../ValidatePanel.scss';

const cs = classNames.bind(styles);

const SortableItem = SortableElement(({
  object, i, disabledRow, showIndex, ...props
}) => {
  const style = !disabledRow ? { cursor: 'move' } : {};
  return (
    <Row
      disabled={disabledRow}
      key={i}
      className="align-items-start"
    >

      {showIndex ? (
        <Col sm={1} className="mr-0 pr-0 pt-2" style={style}>
          {i}
        </Col>
      ) : null}

      <Col className="mr-0 pr-0" style={style}>
        <Alert
          key={i}
          variant="secondary"
          className={props.lastEdit === object.id ? 'lastEdit my-1' : 'my-1'}
          style={style}
          onClick={() => {
            props.validateAction(object.id, null);
          }}
        >
          {object.title}
        </Alert>
      </Col>
      <Col sm={2}>
        <Button
          title="Open"
          size="sm"
          block
          variant="primary"
          className="my-1"
          onClick={() => {
            props.actionEditBranch(object);
            props.onHide();
          }}
        >
          <img src={imgEdit} className="btnImg" alt="Open" />
        </Button>
      </Col>
    </Row>
  );
});

const SortableList = SortableContainer(({ items, ...props }) => (
  <div>
    {items.map((value, index) => (
      <SortableItem
        disabled={props.disabledRow}
        // eslint-disable-next-line react/no-array-index-key
        key={`item-${index}`}
        index={index}
        object={value}
        i={index}
        {...props}
      />
    ))}
  </div>
));

export class BranchesListPass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branches: [],
      branchList: [],
      validated: null,
      filter: '',
      searchInit: false,
    };
  }

  walk = (data, node, arr) => {
    if (!arr.includes(node.id)) {
      arr.push(node.id);
    }

    let tm = [];

    if (node.gotoBranchId && !arr.includes(node.gotoBranchId)) {
      if (!tm.includes(node.id)) {
        tm.push(node.id);
      }
      const nn = data[node.gotoBranchId];
      tm = tm.concat(this.walk(data, nn, arr));
    } else {
      node.links.reverse().forEach((link) => {
        if (!arr.includes(link)) {
          const nn = data[link];
          tm = this.walk(data, nn, arr).concat(tm);
        }
      });
      if (!tm.includes(node.id)) {
        tm.unshift(node.id);
      }
    }
    return tm;
  };

  createPass = (data) => {
    const newData = {};
    data.forEach((node) => {
      newData[node.id] = node;
    });
    const arr = [];
    const intro = data.find((el) => el.title === 'intro');
    const tm = intro ? this.walk(newData, intro, arr) : [];
    return tm.map((x) => newData[x]);
  };

  filterType = (e) => {
    const { type } = this.props;

    if (type) {
      return e.filter((item) => item.type === type);
    }
    return e;
  };

  filterList = (e) => {
    const { branches } = this.state;
    const { loadingFunc } = this.props;

    const currentList = branches;
    const filter = e.toLowerCase();
    const newList = e ? currentList.filter((item) => {
      const lc = item.title.toLowerCase();
      return lc.includes(filter);
    }) : branches;
    this.setState({
      filter: e,
      branchList: newList,
    });

    loadingFunc(false);
  };

  componentDidMount() {
    const { branch } = this.props;

    const newBranches = this.createPass(branch);
    this.setState({
      branches: newBranches,
      branchList: newBranches,
    });
  }

  componentDidUpdate(prevProps) {
    const { branch } = this.props;

    if (branch !== prevProps.branch) {
      const branchList = this.filterType(branch);
      this.setState({
        branches: branchList,
        branchList,
      });
    }
  }

  handleSubmit = (event) => {
    const { loadingFunc, branch } = this.props;
    const { filter } = this.state;

    this.setState({
      branchList: [],
    });
    loadingFunc(true);
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
      return;
    }

    this.setState({
      searchInit: !!obj.search,
    });

    if (obj.search) {
      this.handleSearch(obj.search, validated);
    } else {
      this.setState({
        branches: this.filterType(branch),
        validated: null,
      }, () => {
        this.filterList(filter);
      });
    }
    event.stopPropagation();
  };

  handleSearch(value, validated) {
    const { loadingFunc, storyId } = this.props;
    const { filter } = this.state;

    if (validated === true) {
      api.get(`/v1/stories/${storyId}/search/${value}`)
        .then((res) => {
          this.setState({
            branches: this.filterType(res.data.branches),
          }, () => {
            this.filterList(filter);
          });
        })
        .catch(() => {
          loadingFunc(false);
        });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { storyId } = this.props;
    const { branchList } = this.state;

    const newList = arrayMove(branchList, oldIndex, newIndex);
    const newBranchList = newList.map((obj, index) => ({ ...obj, pos: index + 1 }));

    const branches = newBranchList.map((obj) => ({
      id: obj.id,
      data: {
        pos: obj.pos,
      },
    }));

    api.patch(`/v1/stories/${storyId}/branches`, { branches })
      .then(() => {
        this.setState({
          branchList: newBranchList,
        });
      });
  };

  render() {
    const { disabledRow } = this.props;
    const {
      validated, filter, branchList, searchInit,
    } = this.state;

    const disabled = !!(disabledRow || filter || searchInit);

    return (
      <>
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
          className="mb-1"
        >
          <Form.Group as={Col}>
            <Row>
              <Col sm={8} className={cs('mb-0 px-0')}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Search Text"
                  name="search"
                />
              </Col>
              <Col sm={4} className={cs('mb-0 px-1')}>
                <Button
                  size="sm"
                  type="submit"
                  variant="primary"
                  block
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form>

        <Form.Group className="mb-1">
          <Form.Control
            size="sm"
            type="text"
            value={filter}
            placeholder="Filter by Node Name"
            onChange={(e) => {
              this.filterList(e.target.value);
            }}
            name="title"
          />
        </Form.Group>

        <div className="BranchesListBoxContent">
          <p>{branchList.length < 1 ? 'No nodes found' : `found ${branchList.length} node(s):`}</p>

          <SortableList
            {...this.props}
            currentItem={this}
            onSortEnd={this.onSortEnd}
            helperClass="sortItem"
            distance={1}
            lockAxis="y"
            items={branchList}
            disabledRow={disabled}
            showIndex={!disabled}
          />

        </div>
      </>
    );
  }
}
