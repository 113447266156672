import React from 'react';
import { Pagination } from 'react-bootstrap';

export function Paginator(props) {
  const {
    update,
    totalCount,
    limit,
    current_page: currentPage,
  } = props;

  const requestWithPage = (number) => {
    const val = (limit * number) - limit;
    update(val, number);
  };

  if (Math.ceil(totalCount / limit) < 2) {
    return null;
  }

  let renderPageNumbers;

  const pageNumbers = [];
  if (totalCount !== null) {
    for (let i = 1; i <= Math.ceil(totalCount / limit); i++) {
      pageNumbers.push(i);
    }
    renderPageNumbers = pageNumbers.map((number) => {
      const active = currentPage === number;
      if (number === Number(currentPage) - 3) {
        return (
          <Pagination.Item
            key={number}
            disabled
          >
            ...
          </Pagination.Item>
        );
      }
      if (number < Number(currentPage) - 3) {
        return null;
      }
      if (number === Number(currentPage) + 3) {
        return (
          <Pagination.Item
            key={number}
            disabled
          >
            ...
          </Pagination.Item>
        );
      }
      if (number > Number(currentPage) + 3) {
        return null;
      }

      return (
        <Pagination.Item
          key={number}
          onClick={() => {
            requestWithPage(number);
          }}
          active={active}
        >
          {number}
        </Pagination.Item>
      );
    });
  }

  return (
    <Pagination className="justify-content-end mt-4">
      <Pagination.First
        disabled={(currentPage === 1)}
        onClick={() => requestWithPage(1)}
      />
      {renderPageNumbers}
      <Pagination.Last
        disabled={(currentPage === Math.ceil(totalCount / limit))}
        onClick={() => requestWithPage(Math.ceil(totalCount / limit))}
      />
    </Pagination>
  );
}
