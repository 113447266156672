import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import TiktokPixel from 'tiktok-pixel';
import { ReactComponent as Logo } from '../../../../assets/images/dorian-white.svg';
import { SignUpForm } from '../../../ui/SignUpForm';
import { Invite } from '../invite';
import './style.scss';

// Legacy. Leave for compatibility with /invite route
export class WriteWithUs extends Component {
  constructor(props, context) {
    super(props, context);
    try {
      if (process.env.REACT_APP_TIKTOK_AD_CODE && window.location.hostname.endsWith('dorian.live')) {
        TiktokPixel.init(process.env.REACT_APP_TIKTOK_AD_CODE);
      }
    } catch {
      // TODO report to sentry
    }
    this.state = {
      congratulations: false,
      redirect: false,
    };
  }

  render() {
    const { location } = this.props;
    const {
      redirect,
      congratulations,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    return (
      <div className={`WriteWithUs-page ${congratulations ? 'external' : ''}`}>
        <Container className="">
          <div className="logo">
            <Logo />
          </div>

          <h2 className="title">Write with us</h2>
          {
              location.pathname === '/invite'
                ? (
                  <Invite
                    {...this.props}
                    update={(val) => {
                      this.setState({
                        redirect: '/#congratulations',
                        congratulations: val,
                      });
                    }}
                  />
                )
                : (
                  <SignUpForm
                    {...this.props}
                    update={(val) => {
                      this.setState({
                        redirect: '/#congratulations',
                        congratulations: val,
                      });
                    }}
                    type="external"
                  />
                )
            }
        </Container>
      </div>
    );
  }
}
