import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class AddCode extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addCodeType(obj, validated) {
    const { update } = this.props;
    if (validated === true) {
      const data = {
        code: obj.code,
      };
      api.post('/v1/signup/codes', data)
        .then(() => {
          update();
        })
        .catch((error) => {
          this.errorAlert(error.response.data.error);
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addCodeType(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const {
      update, show, onHide, ...other
    } = this.props;
    const { validated, formError } = this.state;

    if (!show) {
      return null;
    }

    const title = 'Add New Code';

    return (
      <Modal
        {...other}
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>
            <Form.Row>
              <Form.Group as={Col} controlId="Code">
                <Form.Label>Code</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Code"
                  name="code"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter code.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
