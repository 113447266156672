import async from 'async';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { createStoryTagsValidationSchema } from '../../../dorian-shared/model-related-helpers/story/validateStoryTags';
import { api } from '../../api';
import { BookTags } from '../Books/BookTags/BookTags';

export class BookTagsModal extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
      book: null,
      tags: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const { book } = this.props;

    async.parallel({
      book: (callback) => {
        api.get(`/v1/books/${book.id}`)
          .then((res) => {
            callback(null, res.data.book);
          }).catch((error) => {
            callback(error, null);
          });
      },
      tagGroupsByTitle: (callback) => {
        api.get('/v1/getGroupedBookTags')
          .then((res) => {
            callback(null, res.data.tagGroups);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, response) => {
      const { book: bookFromResponse, tagGroupsByTitle } = response;
      const tagValidationSchema = createStoryTagsValidationSchema(tagGroupsByTitle);
      if (err) {
        this.errorAlert(err);
      } else {
        this.setState({
          book: bookFromResponse,
          tags: bookFromResponse.tags,
          tagValidationSchema,
        });
      }
    });
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  updateBook(value, validated) {
    const { book } = this.state;
    const { update } = this.props;

    if (validated === true) {
      if (book) {
        api.put(`/v1/books/${book.id}`, value)
          .then(() => update())
          .catch((error) => this.errorAlert(error.response.data.error));
      }
    }
  }

  handleSubmit(event) {
    const { tagValidationSchema } = this.state;
    event.preventDefault();
    const form = event.currentTarget;
    let validated = form.checkValidity();
    const obj = serialize(form, { hash: true, empty: true });

    let errorText = '';
    try {
      tagValidationSchema.validateSync(obj.tags);
    } catch (error) {
      errorText = error.message;
    }

    if (errorText) {
      validated = false;
      this.setState({
        validated: false,
      });
      this.errorAlert(errorText);
    }

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.updateBook(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const {
      validated, tags, book, wizardStep, formError,
    } = this.state;

    const {
      update, disabled, onHide, ...props
    } = this.props;

    const title = `${book ? book.title : ''} Tags`;
    return (
      <Modal
        {...props}
        onHide={onHide}
        size="lg"
        backdrop="static"
        keyboard={(wizardStep === 3)}
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body className="overflow-visible">

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <BookTags
              tags={tags || []}
              disabled={disabled}
            />

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={disabled}
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
