import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Button, Col, Dropdown, Form, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import imgReplace from '../../../assets/images/replace.svg';
import { getCharacterLockingDisabled, getCharacterLockingVisible } from '../Book/utils';
import styles from './Characters.scss';
import { CharactersDelete } from './CharactersDelete';
import { CharactersReplace } from './CharactersReplace';
import { ImageDownload } from './ImageDownload';
import { PreviewImg } from './PreviewImg';

const cs = classNames.bind(styles);

const showReplace = process.env.REACT_APP_EXTENDED_ACCESS || false;

export class CharactersTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteCharactersShow: false,
      modalReplaceCharactersShow: false,
      imageDownload: false,
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onStopEdit() {
    const { args } = this.props;
    args.onStopEdit();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onStartEdit() {
    const { args } = this.props;
    args.onStartEdit();
  }

  update() {
    const { args } = this.props;
    args.update();
  }

  imageAlias(obj) {
    return obj.imageAlias ? obj.imageAlias : null;
  }

  imageObject(obj) {
    return obj.avatar;
  }

  render() {
    const {
      args,
      edit,
      expressions,
      showImagePath,
      character,
      disabled,
      characters,
      userCanEditCustom,
      userRole,
      bookType,
    } = this.props;

    const modalDeleteCharactersClose = () => {
      this.setState({
        modalDeleteCharactersShow: false,
      }, () => {
        this.update();
      });
    };

    const deleteCharacters = () => {
      const { modalDeleteCharactersShow } = this.state;

      if (modalDeleteCharactersShow === true) {
        return (
          <CharactersDelete
            show={modalDeleteCharactersShow}
            onHide={() => {
              this.setState({
                modalDeleteCharactersShow: false,
              });
            }}
            update={modalDeleteCharactersClose}
            obj={character}
            args={args}
          />
        );
      }
      return null;
    };

    const modalReplaceCharactersClose = () => {
      this.setState({ modalReplaceCharactersShow: false });
      this.update();
    };

    const replaceCharacters = () => {
      const { modalReplaceCharactersShow } = this.state;
      if (modalReplaceCharactersShow === true) {
        return (
          <CharactersReplace
            show={modalReplaceCharactersShow}
            onHide={modalReplaceCharactersClose}
            obj={character}
            args={args}
            characters={characters}
          />
        );
      }
      return null;
    };

    const imageDownload = () => {
      const { imageDownload: isImageDownload } = this.state;

      if (isImageDownload) {
        return (
          <ImageDownload
            expressions={expressions}
            character={{ image: this.imageObject(character) }}
            aws
            show={isImageDownload}
            onHide={() => {
              this.setState({
                imageDownload: false,
              });
            }}
          />
        );
      }
      return null;
    };

    const isAdminRole = userRole === 'admin';
    const {
      isCharacterLockingForAdminVisible,
      isCharacterLockingForNonAdminVisible,
    } = getCharacterLockingVisible(userRole, bookType);

    const isCharacterLockingDisabled = getCharacterLockingDisabled(userRole, bookType, character);

    return (
      <Row className="py-2 border-bottom">
        <Col md={1} className="text-center px-0">
          {character.isPlayer}
          <div className={cs(character.isPlayer === true ? 'checked' : null, 'circleBox')} />
        </Col>
        <Col>
          {character.name}
        </Col>
        <Col md={2}>
          {character.alias}
        </Col>
        <Col md={1}>
          {character.type}
        </Col>
        {showImagePath
          && (
            <Col>
              {character.imagePath}
            </Col>
          )}
        <Col md={1}>
          <span className="text-ellipsis">
            {this.imageAlias(character)}
          </span>
        </Col>
        <Col md={1} className="pr-0 d-flex justify-content-center">
          <PreviewImg
            className="PreviewImg-36"
            style={{ maxWidth: '36px', maxHeight: '36px', overflow: 'hidden' }}
            character={{ image: this.imageObject(character) }}
            aws
          />
        </Col>
        {isCharacterLockingForNonAdminVisible && (
          <Col md={1} className="d-flex justify-content-center">
            <Form.Check
              custom
              disabled
              checked={character.lockName || character.lockArt}
              type="checkbox"
            />
          </Col>
        )}
        {isCharacterLockingForAdminVisible && (
          <Col md={1} className="d-flex justify-content-center">
            <Form.Check
              custom
              disabled
              checked={character.hasForceAuthorsChoiceName}
              type="checkbox"
            />
          </Col>
        )}
        <Col className="text-right px-0">
          {isAdminRole && showReplace
            && (
              <OverlayTrigger
                overlay={(
                  <Tooltip id="tooltip-top">
                    Replace
                  </Tooltip>
                )}
              >
                <Button
                  disabled={disabled || args.deleteDisabled || isCharacterLockingDisabled}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={() => this.setState({ modalReplaceCharactersShow: true })}
                  variant="secondary"
                  size="sm"
                  className="mx-1"
                >
                  <img src={imgReplace} className="btnImg" alt="Replace" />
                </Button>
              </OverlayTrigger>
            )}
          <Dropdown
            className="mx-1 d-inline-block"
          >
            <Dropdown.Toggle
              variant="secondary"
              id="ellipsis"
              className="mx-1"
              size="sm"
              style={{ height: '36px' }}
            >
              ...
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                disabled={disabled
                  || args.editDisabled
                  || (character.type === 'custom' && !userCanEditCustom)
                  || isCharacterLockingDisabled}
                onClick={() => {
                  args.onStartEdit();
                  edit(character);
                }}
              >
                Edit
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                disabled={disabled || args.deleteDisabled || isCharacterLockingDisabled}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => this.setState({ modalDeleteCharactersShow: true })}
              >
                Delete
              </Dropdown.Item>

              <Dropdown.Divider />
              <Dropdown.Item
                as="button"
                disabled={disabled || !this.imageObject(character)}
                onMouseDown={() => {
                  this.setState({
                    imageDownload: true,
                  });
                }}
              >
                Image Download
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        {deleteCharacters()}
        {replaceCharacters()}
        {imageDownload()}
      </Row>
    );
  }
}
