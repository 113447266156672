import { useHistory } from 'react-router-dom';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';

export interface SetPasswordFormValues {
  code: string;
  username: string;
  password: string
  confirmPassword: string
}
export function useSetPasswordForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      setToSuccess,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const history = useHistory();
  const handleSubmit = async (values: SetPasswordFormValues) => {
    // Moved from legacy code
    const createTemplateInLocalStorage = localStorage.getItem('createTemplate');
    const createTemplate = createTemplateInLocalStorage
      ? JSON.parse(createTemplateInLocalStorage)
      : null;
    if (createTemplate) {
      createTemplate.setpassword = true;
    }
    // End of moved from legacy code

    const payload = {
      username: values.username,
      newPassword: values.password,
      verificationCode: values.code,
    };

    setToLoading();
    try {
      await userApi.resetConfirm(payload);
      localStorage.setItem('createTemplate', JSON.stringify(createTemplate));
      setToSuccess();
      const successMessage = 'Your password has been reset!  Please sign in with your new password.';
      history.push(`/sign-in?username=${encodeURIComponent(values.username)}&successMessage=${encodeURIComponent(successMessage)}`);
    } catch (error) {
      const message = 'Sorry, it seems that the code is invalid. Please try again.';
      setToError(message);
      logger.error(error);
    }
  };
  return {
    isLoading,
    isError,
    setToError,
    statusMessage,
    handleSubmit,
  };
}
