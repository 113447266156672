import React from 'react';
import { Card, Form } from 'react-bootstrap';

export function OptionDisplaySetings(props) {
  const { error, defaultValue } = props;

  return (
    <Card className="view p-3">
      <Form.Group controlId="displaySettings">
        <Form.Label>Display Settings</Form.Label>
        <Form.Control
          as="textarea"
          size="sm"
          rows={6}
          name="displaySettings"
          defaultValue={defaultValue}
          isInvalid={!!error}
        />
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </Form.Group>
    </Card>
  );
}
