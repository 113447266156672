import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { PremiumIpDisabledApprovedEdit } from '../../../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../../../utils/premiumIpDisabledEdit';
import '../../News.scss';

export class NewsItemDropdown extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;

    this.user = auth.getUser();
  }

  render() {
    const {
      share, type, data, obj, changeGroup, editBook, groups,
    } = this.props;

    if (type !== 'books' || (this.user.role !== 'admin' && data.book_role !== 'owner')) {
      return null;
    }

    return (
      <Dropdown
        className="mx-1 newsItemMenu"
      >
        <Dropdown.Toggle
          variant="secondary"
          id="ellipsis"
          className="mx-1 newsItemMenu-btn"
          size="sm"
        >
          &#8942;
        </Dropdown.Toggle>
        <Dropdown.Menu>

          <Dropdown.Item
            as="button"
            onMouseDown={() => {
              editBook(data);
            }}
          >
            Edit
          </Dropdown.Item>

          <Dropdown.Item
            as="button"
            disabled={
              PremiumIpDisabledEdit(this.user.role, data)
              || PremiumIpDisabledApprovedEdit(this.user.role, data)
            }
            onMouseDown={() => {
              share(data);
            }}
          >
            Share
          </Dropdown.Item>

          <Dropdown.Divider />

          {groups && groups.map((group) => obj && obj.type && obj.type !== group.type
              && (
              <Dropdown.Item
                key={group.id}
                as="button"
                disabled={
                  PremiumIpDisabledEdit(this.user.role, data)
                  || PremiumIpDisabledApprovedEdit(this.user.role, data)
                }
                onMouseDown={() => changeGroup(data.id, group.id)}
              >
                {group.title}
              </Dropdown.Item>
              ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
