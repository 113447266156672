import axios from 'axios';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import imgNoImage from '../../../assets/images/no_image.svg';

export class PreviewImage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      url: imgNoImage,
      imageValidate: true,
    };
  }

  componentDidMount() {
    this.imageLoader();
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    if (prevProps.src !== src) {
      this.imageLoader();
    }
  }

  imageLoader = () => {
    this.setState({
      loading: true,
      url: imgNoImage,
    });
    // eslint-disable-next-line react/destructuring-assignment
    const url = this.props.src;
    axios
      .get(
        url,
        { responseType: 'arraybuffer' },
      )
      .then((response) => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        this.setState({
          imageValidate: true,
          loading: false,
          url: `data:;base64,${base64}`,
        });
      }).catch(() => {
        this.setState({
          url: imgNoImage,
          loading: false,
          imageValidate: false,
        });
      });
  };

  render() {
    return (
      <>
        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.loading
          && <div className="imageSpinner"><Spinner animation="border" variant="primary" /></div>
        }
        <img
          // eslint-disable-next-line react/destructuring-assignment
          src={this.state.url}
          // eslint-disable-next-line react/destructuring-assignment
          alt={this.state.url}
        />
        {
          // eslint-disable-next-line react/destructuring-assignment
          !this.state.imageValidate
          && (
          <div className="imageNotValidate">
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="primary"
            />
            {' '}
            image not validate
          </div>
          )
        }
      </>
    );
  }
}
