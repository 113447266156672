import React from 'react';
import { Page01Step01 } from './Page-1-step-1';
import { Page01Step02 } from './Page-1-step-2';
import './style.scss';

export function Wizard(props) {
  const { wizard } = props;
  switch (wizard) {
    case 1:
      return (
        <Page01Step01 {...props} />
      );
    case 2:
      return (
        <Page01Step02 {...props} />
      );
    default:
      return null;
  }
}
