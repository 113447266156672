import React, { Component } from 'react';
import {
  Button, Col, Dropdown, Form, InputGroup, Row,
} from 'react-bootstrap';
import './style.scss';
// eslint-disable-next-line import/order
import './avatar.scss';

export class AvatarDropdownGroups extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      active: null,
      value: '',
      groups: ['Uncategorized'],
    };
  }

  componentDidMount() {
    const { charactersGroups, obj } = this.props;
    if (charactersGroups) {
      const groups = ['Uncategorized'];
      charactersGroups.forEach((a) => {
        if (a && a !== 'Uncategorized' && a !== 'null') {
          groups.push(a);
        }
      });
      this.setState({
        groups,
      });
    }
    if (obj) {
      this.setState({
        active: obj.group || 'select a group',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { charactersGroups } = this.props;
    if (charactersGroups !== prevProps.charactersGroups) {
      const groups = ['Uncategorized'];
      charactersGroups.forEach((a) => {
        if (a && a !== 'Uncategorized' && a !== 'null') {
          groups.push(a);
        }
      });
      this.setState({
        groups,
      });
    }
  }

  render() {
    const { update, disabled } = this.props;
    const { value, groups, active } = this.state;

    const list = groups.filter((a) => !value || a.toLowerCase().startsWith(value));
    return (
      <Row>
        <Col>
          <Dropdown
            className={`AvatarDropdownGroupsDropdown ${disabled ? 'disabled' : ''}`}
          >
            <Dropdown.Toggle
              id="dropdown-groups"
              disabled={disabled}
            >
              {active || 'select a group'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>
                <InputGroup>
                  <Form.Control
                    value={value}
                    disabled={disabled}
                    size="sm"
                    type="text"
                    onChange={(e) => {
                      this.setState({
                        value: e.target.value,
                      });
                    }}
                  />
                  {
                      list.length < 1
                        ? (
                          <InputGroup.Append>
                            <Button
                              size="sm"
                              variant="secondary"
                              onClick={() => {
                                groups.push(value);
                                this.setState({
                                  groups,
                                  active: value,
                                }, () => {
                                  update(value);
                                });
                              }}
                            >
                              Add New
                            </Button>
                          </InputGroup.Append>
                        )
                        : null
                    }
                </InputGroup>
              </Dropdown.Header>
              {
                  list.map((obj, i) => (
                    <Dropdown.Item
                      eventKey={obj}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      active={(active === obj)}
                      onClick={() => {
                        this.setState({
                          active: obj,
                        }, () => {
                          update(obj);
                        });
                      }}
                    >
                      {obj}
                    </Dropdown.Item>
                  ))
                }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    );
  }
}
