import React, { ReactElement, ReactNode } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';

interface ImagePopoverProps {
  previewImageUrl: string;
  children: ReactNode;
  delay?: {
    show: number,
    hide: number,
  };
  placement?: Placement;
  overlay?: ReactElement;
  width?: string;
  label?: string;
}

export function ImagePopoverPreview(props: ImagePopoverProps) {
  const {
    previewImageUrl,
    children,
    delay = { show: 500, hide: 100 },
    placement = 'auto',
    overlay,
    width = '200px',
    label,
  } = props;

  if (!previewImageUrl) {
    return children as ReactElement;
  }

  const popover = (
    <Popover id="popover-basic" style={{ zIndex: 100000, width }}>
      {label && (
        <Popover.Title as="h3">{label}</Popover.Title>
      )}
      <Popover.Content>
        <img src={previewImageUrl} alt="Preview" style={{ width: '100%' }} />
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      placement={placement}
      overlay={overlay ?? popover}
      delay={delay}
    >
      <span>
        {children}
      </span>
    </OverlayTrigger>
  );
}
