import {
  array,
  string,
} from 'yup';
import { StoryTag } from '../../types/story/StoryTag';
import { TagGroupByGroupTitle } from '../../types/tag/TagGroupByGroupTitle';

export const maxTagNumber = 11;

const createHasAtLeastOneTagOf = (
  categoryTags: StoryTag[],
) => (
  selectedTagTitles: (string | undefined)[] = [],
) => selectedTagTitles.some(
  (tagTitle) => categoryTags.some(
    (categoryTag) => categoryTag.title === tagTitle,
  ),
);

const createValidateMaxTags = (
  maximumNumber: number,
  categoryTags: StoryTag[],
) => (
  selectedTagTitles: (string | undefined)[] = [],
) => selectedTagTitles.filter(
  (tagTitle) => categoryTags.find(
    (categoryTag) => categoryTag.title === tagTitle,
  ),
).length <= maximumNumber;

export function createStoryTagsValidationSchema(tagGroupByGroupTitle: TagGroupByGroupTitle) {
  return array().of(string())
    .test(
      'has-art-style',
      'Please select one of the art style tags',
      createHasAtLeastOneTagOf(tagGroupByGroupTitle.ArtStyle.tags),
    )
    .test(
      'has-main-character-type',
      'Please select one of the main character tags',
      createHasAtLeastOneTagOf(tagGroupByGroupTitle.Protagonist.tags),
    )
    .test(
      'has-love-interest',
      'Please select one of the love interest tags',
      createHasAtLeastOneTagOf(tagGroupByGroupTitle.LoveInterest.tags),
    )
    .test(
      'has-genre',
      'Please select one of the genre tags',
      createHasAtLeastOneTagOf(tagGroupByGroupTitle.Genre.tags),
    )
    .test(
      'maximum-genre',
      'Please select 5 or less genre tags',
      createValidateMaxTags(5, tagGroupByGroupTitle.Genre.tags),
    );
}
