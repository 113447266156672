import React, {
  ReactNode, createContext, useContext, useEffect, useState,
} from 'react';
import { ExpressionConfig } from '../../dorian-shared/types/character/ExpressionConfig';
import { useApiService } from '../ApiServiceContext/ApiServiceContext';

export const CharacterExpressionsContext = createContext<ExpressionConfig[]>([]);

export function CharacterExpressionsContextProvider({ children }: { children: ReactNode }) {
  const [possibleExpressions, setPossibleExpressions] = useState<ExpressionConfig[]>([]);
  const apiService = useApiService();

  useEffect(() => {
    apiService.fetchExpressionNames().then(
      (expressions) => {
        setPossibleExpressions(expressions);
      },
    );
  }, [apiService]);

  return (
    <CharacterExpressionsContext.Provider value={possibleExpressions}>
      {children}
    </CharacterExpressionsContext.Provider>
  );
}

export const useCharacterExpressionsContext = () => useContext(CharacterExpressionsContext);
