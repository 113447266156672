import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
// @ts-ignore: no def
import DatePicker from 'react-datepicker';

interface DatePickerWithPrevNextProps {
  filterByDate: Date,
  onPrevClick?: () => void,
  onNextClick?: () => void,
  onSelect: (date: Date) => void,
  maxDate: Date,
    dateFormat?: string,
}

export function DatePickerWithPrevNext(props: DatePickerWithPrevNextProps) {
  const {
    filterByDate,
    onPrevClick,
    onNextClick,
    onSelect,
    maxDate,
    dateFormat = 'yyyy-MM-dd',
  } = props;

  const handlePrevClick = () => {
    if (onPrevClick) {
      onPrevClick();
      return;
    }
    const newDate = moment(filterByDate).subtract(1, 'd').toDate();
    onSelect(newDate);
  };

  const handleNextClick = () => {
    if (onNextClick) {
      onNextClick();
      return;
    }
    const newDate = moment(filterByDate).add(1, 'd').toDate();
    onSelect(newDate);
  };

  const isMaxDate = filterByDate ? filterByDate >= maxDate : true;
  return (
    <div className="d-flex justify-content-center mt-4">
      <Button
        size="sm"
        onClick={handlePrevClick}
      >
        PREV
      </Button>
      <div className="mx-1 my-auto">
        <DatePicker
          className="text-center"
          placeholderText="Pick Date"
          selected={filterByDate}
          onChange={onSelect}
          dateFormat={dateFormat}
          maxDate={maxDate}
        />
      </div>
      <Button
        size="sm"
        disabled={isMaxDate}
        onClick={handleNextClick}
      >
        NEXT
      </Button>
    </div>
  );
}
