import classNames from 'classnames/bind';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageHeader } from '../../ui/PageHeader';
import styles from './PageNotFound.css';

const cs = classNames.bind(styles);

export function PageNotFound() {
  return (
    <>
      <Redirect to="/" />
      <PageHeader />
      <div>
        <h1 className={cs('PageNotFoundH1')}>404</h1>
        <h2 className={cs('PageNotFoundH2')}>Page Not Found</h2>
      </div>
    </>
  );
}
