import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { User } from '../../../dorian-shared/types/user/User';
import { userApi } from '../../../services/userApi/UserApi';
import { auth } from '../../Auth/Auth';

interface submitValues {
  firstName: string;
    lastName: string;
}

export function useProvideNameForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const handleSubmit = async (values: submitValues) => {
    setToLoading();
    try {
      const partialProfile: Partial<User> = values;
      await userApi.putAndUpdateProfileLegacy(partialProfile);
      window.location.assign('/');
    } catch (error) {
      const message = auth.getErrorMessage(error) ?? 'Error occurred while trying to login.';
      setToError(message);
    }
  };
  return {
    isLoading, isError, statusMessage, handleSubmit,
  };
}
