import React, { Component } from 'react';
import {
  Button, Dropdown, Form, FormControl, InputGroup,
} from 'react-bootstrap';
import { api } from '../../api';
import { Auth } from '../../Auth/Auth';

const auth = new Auth();

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    variant="secondary"
    className="form-control form-control-sm text-left border arr-right addTagButton"
    onMouseDown={(e) => e.stopPropagation()}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const CustomMenu = React.forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledBy, addTag,
  }, ref) => {
    const user = auth.getUser();
    const [value, setValue] = React.useState('');

    const list = React.Children.toArray(children).filter(
      (child) => !value || child.props.children.toLowerCase().startsWith(value),
    );

    return (
      <div
        ref={ref}
        style={{
          ...style,
          minWidth: '25vw',
          width: '25vw !important',
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="mx-3 my-2">
          <InputGroup>
            <FormControl
              autoFocus
              placeholder="Type to filter Tags..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            {
              ((list.length < 1) && (user.role === 'admin'))
              && (
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  onClick={() => addTag(value)}
                  style={{
                    fontSize: '.7em',
                    borderRadius: 0,
                    padding: '0.25em',
                  }}
                >
                  Add New
                </Button>
              </InputGroup.Append>
              )
            }
          </InputGroup>
        </div>
        <ul className="list-unstyled">
          {list}
        </ul>
      </div>
    );
  },
);

export class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/destructuring-assignment
      tags: this.props.tags || [],
      savedTags: [],
      // eslint-disable-next-line react/no-unused-state
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      tags: nextProps.tags,
    };
  }

  componentDidMount() {
    this.loadTags();
  }

  loadTags = () => {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ loading: true });
    api.get('/v1/backgroundtags')
      .then((res) => {
        this.setState({
          savedTags: res.data.tags,
        });
      })
      .catch(() => {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ loading: false });
      });
  };

  addTag = (title) => {
    const { tags } = this.state;
    tags.push(title);
    this.setState({
      tags,
    });
  };

  deleteTag(i) {
    const { tags } = this.state;
    tags.splice(i, 1);
    this.setState({
      tags,
    });
  }

  activeTagsList() {
    const { tags } = this.state;
    tags.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    if (tags.length < 1) {
      return (
        <Form.Text className="text-muted px-3 py-2">
          No tags added
        </Form.Text>
      );
    }
    return tags.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Form.Check type="checkbox" key={i} className="tagItem">
        <Form.Check.Label>{object}</Form.Check.Label>
        <Button
          variant="secondary"
          size="sm"
          className="close"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
          <Form.Check.Input
            name="tags[]"
            type="checkbox"
            checked
            value={object}
            onChange={() => {
              this.deleteTag(i);
            }}
          />
        </Button>
      </Form.Check>
    ));
  }

  tagOptions() {
    const { tags } = this.state;

    // eslint-disable-next-line react/destructuring-assignment
    return this.state.savedTags.map((object, i) => {
      const title = object.title || '';
      const disabled = tags.find((el) => el === object.title);

      return (
        <Dropdown.Item
          className={disabled ? 'd-none' : null}
          as="button"
          eventKey={title}
          /* eslint-disable-next-line react/no-array-index-key */
          key={i}
          onClick={() => {
            this.addTag(title);
          }}
        >
          {title}
        </Dropdown.Item>
      );
    });
  }

  render() {
    return (
      <div className="pt-2">
        <Form.Label>Tags - Select Between 1 and 10</Form.Label>
        <div className="tagsBox">
          {this.activeTagsList()}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {this.state.tags.length < 10
            ? (
              <Dropdown
                disabled
                className="w-auto CustomSelect"
                onSelect={(e, event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                <Dropdown.Toggle
                  as={CustomToggle}
                  className="w-100"
                  /* eslint-disable-next-line react/destructuring-assignment */
                  disabled={!!this.props.disabled}
                >
                  Add Tag
                </Dropdown.Toggle>

                <Dropdown.Menu
                  as={CustomMenu}
                  loadTags={this.loadTags}
                  addTag={this.addTag}
                  className="w-100 mw-75 overflow-hidden"
                >
                  {this.tagOptions()}
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
        </div>
      </div>
    );
  }
}

CustomToggle.displayName = 'CustomToggle in Tags';
CustomMenu.displayName = 'CustomMenu in Tags';
