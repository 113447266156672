import { Book } from '../../../../dorian-shared/types/book/book';
import { User } from '../../../../dorian-shared/types/user/User';
import { api } from '../../../api';

export const hasBookAnalyticAccess = (
  user: User,
  book: Book,
) => {
  const userInBook = book.users.find((bookUser) => bookUser.id === user.id);
  const bookRole = book.book_role;
  const userRole = user.role;
  const hasRoleAnalyticsAccess = userRole === 'admin' || bookRole === 'owner';
  const hasUserAnalyticsAccess = Boolean(userInBook?.analyticsAccess) && bookRole === 'co-author';

  return hasRoleAnalyticsAccess || hasUserAnalyticsAccess;
};

export const isStoryAnalyticsVisible = (
  user: User,
  book: Book,
  storyGroup: string,
) => {
  const isAnalyticsAccess = hasBookAnalyticAccess(user, book);
  return (storyGroup === 'live' || storyGroup === 'liveprompt') && isAnalyticsAccess;
};

export const fetchIsStoryAnalyticsVisible = async (
  user: User,
  bookId: number,
  storyGroup: string,
) => {
  const response = await api.get(`/v1/books/${bookId}`);
  return isStoryAnalyticsVisible(user, response.data.book, storyGroup);
};
