import React from 'react';
import {
  Comparator, FILTER_TYPES, customFilter, textFilter,
} from 'react-bootstrap-table2-filter';
import { FormatDate } from '../../../Book/FormatDate';
import { DateFilter } from '../DateFilter';
import { PositiveNumberFilter } from '../PositiveNumberFilter';
import {
  authorsFormatter,
  bookTitleFormatter, floatFormatter,
  nodeTitleFormatter,
  ownerFormatter,
  storyTitleFormatter,
} from '../tableFormatters';

export const choicesColumns = [{
  dataField: 'bookTitle',
  text: 'Story',
  formatter: bookTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'owner',
  text: 'Lead Author',
  formatter: ownerFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'authors',
  text: 'Co-Authors',
  formatter: authorsFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'storyTitle',
  text: 'Episode',
  formatter: storyTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'storyChapter',
  text: 'Episode#',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
      comparator={Comparator.EQ}
    />
  ),
}, {
  dataField: 'storyRevision',
  text: 'Version',
  sort: true,
  headerStyle: () => ({ width: '9%' }),
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'storyCreatedAt',
  text: 'Released',
  sort: true,
  formatter: (cell) => <FormatDate className="d-inline-block p-1" date={cell} />,
  filter: customFilter({
    type: FILTER_TYPES.DATE,
  }),
  filterRenderer: (onFilter, column) => <DateFilter onFilter={onFilter} column={column} />,
}, {
  dataField: 'title',
  text: 'Node',
  sort: true,
  formatter: nodeTitleFormatter,
  filter: textFilter(),
}, {
  dataField: 'users',
  text: 'Users',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'hearts',
  text: 'Hearts',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'heartsPerUser',
  text: 'Hearts/Users',
  formatter: floatFormatter,
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}];

export const choicesHeaders = [
  { label: 'Story', key: 'bookTitle' },
  { label: 'Lead Author', key: 'owner' },
  { label: 'Co-Authors', key: 'authors' },
  { label: 'Episode', key: 'storyTitle' },
  { label: 'Version', key: 'storyRevision' },
  { label: 'Released', key: 'storyCreatedAt' },
  { label: 'Node', key: 'title' },
  { label: 'Users', key: 'users' },
  { label: 'Hearts', key: 'hearts' },
  { label: 'Hearts/Users', key: 'heartsPerUser' },
];
