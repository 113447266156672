import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddModifierType } from './AddModifierType';
import './ModifierTypes.css';
import { ModifierTypesTableRow } from './ModifierTypesTableRow';

export class AnswerModifiersTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      editModifierTypeData: null,
      listModifierTypesActive: false,
      addModifierTypeActive: false,
      // eslint-disable-next-line react/no-unused-state
      editModifierTypeActive: false,
      loading: true,
    };

    const { auth, history } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.user = auth.getUser();

    this.page = {
      header: {
        title: 'Modifiers',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Modifier',
            action: () => this.initAddModifierType(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      listModifierTypesActive: true,
    });
    this.loadModifierTypesList();
  }

  loadModifierTypesList = () => {
    api.get('/v1/answers/modifiers/types')
      .then((res) => {
        this.setState({
          types: res.data.types,
          listModifierTypesActive: true,
          addModifierTypeActive: false,
          // eslint-disable-next-line react/no-unused-state
          editModifierTypeActive: false,
          loading: false,
        });
      });
  };

  initAddModifierType = (obj) => {
    let editModifierTypeData = null;
    if (obj) {
      editModifierTypeData = obj;
    }
    this.setState({
      editModifierTypeData,
      addModifierTypeActive: true,
    });
  };

  cancelAddModifierType = () => {
    this.setState({
      editModifierTypeData: null,
      addModifierTypeActive: false,
    });
  };

  modifierTypesTableRow() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.types.length < 1) {
      return (
        <tr>
          <td colSpan={3}>There are no modifiers</td>
        </tr>
      );
    }
    const action = this.initAddModifierType;
    const updateModifierTypes = () => {
      this.loadModifierTypesList();
    };
    // eslint-disable-next-line react/destructuring-assignment
    return this.state.types.map(
      // eslint-disable-next-line react/no-array-index-key, max-len
      (object, i) => <ModifierTypesTableRow obj={object} key={i} actionEditModifierType={action} update={updateModifierTypes} />,
    );
  }

  render() {
    const { listModifierTypesActive } = this.state;
    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card className={listModifierTypesActive ? 'd-block' : 'd-none'}>
            <Card.Body>
              <Card.Title>List of Modifiers</Card.Title>

              {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>ID</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.modifierTypesTableRow()}
                      </tbody>
                    </Table>
                  )
              }

            </Card.Body>
          </Card>
        </PageWrapper>

        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.addModifierTypeActive
            ? (
              <AddModifierType
                // eslint-disable-next-line react/destructuring-assignment
                data={this.state.editModifierTypeData}
                // eslint-disable-next-line react/destructuring-assignment
                show={this.state.addModifierTypeActive}
                onHide={this.cancelAddModifierType}
                update={this.loadModifierTypesList}
              />
            )
            : null
        }
      </>
    );
  }
}
