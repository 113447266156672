export function getBookEconomyPage(bookId: string) {
  return {
    header: {
      title: 'Book Economy',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back To Book',
          href: `/book/${bookId}`,
          variant: 'secondary',
          disabled: false,
        },
      ],
    },
  };
}
