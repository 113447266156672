import React, {
  useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { PageWrapper } from '../../ui/PageWrapper';
import { AudioLibraryContainer } from './AudioLibraryContainer/AudioLibraryContainer';
import { AudiosProvider } from './AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { UploadAudio } from './AudioLibraryContainer/UploadAudio/UploadAudio';

export function AudioLibraryPage(props) {
  const {
    history,
  } = props;

  const [isUploadModalShown, setIsUploadModalShown] = useState(false);

  const showUploadModal = () => setIsUploadModalShown(true);

  const pageLayout = {
    header: {
      title: 'Audio library',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
        {
          title: 'Upload audio',
          action: showUploadModal,
          variant: 'primary',
        },
      ],
    },
  };

  const handleUpload = () => {
    setIsUploadModalShown(false);
  };

  const handleCancel = () => {
    setIsUploadModalShown(false);
  };

  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <AudiosProvider>
        <Card className="d-block">
          <Card.Body>
            <Card.Title className="text-center">
              Audio library
            </Card.Title>
            <AudioLibraryContainer />
          </Card.Body>
        </Card>
        {
          isUploadModalShown && (
            <UploadAudio
              onUpload={handleUpload}
              onCancel={handleCancel}
            />
          )
        }
      </AudiosProvider>
    </PageWrapper>
  );
}
