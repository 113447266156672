/* eslint-disable max-len,react/no-unescaped-entities */
import React, { Component } from 'react';
import { checkExternal } from '../PrivacyPolicy';

export class ConfirmTermContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const { type } = this.props;
    return (
      <>
        <div className="title">INDEPENDENT WRITER AGREEMENT</div>
        <div className="subtitle">Last update January 7, 2021</div>
        <br />
        <p>
          Welcome to Dorian! We’re thrilled that you’ve chosen to collaborate with us to create amazing content on
          Dorian.
        </p>
        <p>
          This Independent Writer Agreement ("
          <b>Agreement</b>
          ") is a binding contract between you and Dorian,
          Inc. ("
          <b>Dorian</b>
          ", "
          <b>we</b>
          ", "
          <b>us</b>
          ", "
          <b>our</b>
          "). It contains the rules and restrictions
          that govern your use of Dorian’s products, services and offerings, including our interactive platform
          (the "
          <b>Platform</b>
          "), our website available at www.Dorian.live (the "
          <b>Site</b>
          ") and our mobile
          application (the "
          <b>App</b>
          ") (collectively the "
          <b>Services</b>
          ") as a Writer, and your collaboration
          with Dorian for that purpose. You will be considered a "Writer" if you use the Services to create,
          develop, contribute or otherwise make available to Dorian content to be distributed via the Platform.
        </p>
        <div className="section-title"> 1. Acceptance and Changes to Agreements</div>
        <br />
        <p>
          By using the Services as a Writer, you agree to the terms and conditions of this Agreement, and this
          Agreement will remain in effect so long as you use the Services. This Agreement incorporates our Terms
          of Use (
          <a href={checkExternal(type, 'terms-of-use')}>https://dorian.live/terms-of-use</a>
          ),
          Privacy Policy (
          <a
            href={checkExternal(type, 'privacy-policy')}
          >
            https://dorian.live/privacy-policy
          </a>
          ),
          Copyright Policy (
          <a
            href={checkExternal(type, 'copyright-policy')}
          >
            https://dorian.live/copyright-policy
          </a>
          ),
          Community Guidelines , and all
          other rules and procedures
          that may be published from time to time by us on the Platform, the Site or the App. In the event of any
          conflict between this Agreement and any other terms and conditions between you and Dorian, this
          Agreement will prevail with respect to its subject matter.
        </p>
        <p>
          You must be at least 13 years old to use the Services. If you are between the ages of 13 and 18 (or
          between 13 and the age of legal majority in your country of residence), you need to get your parent’s or
          guardian’s permission, and your parent or legal guardian must read and agree to this Agreement on your
          behalf. Parents and guardians must supervise your use of our Platform, and are responsible for the acts
          of their children using our Services. Please see our Terms of Use for more details.
        </p>
        <p>
          If you’re agreeing to this Agreement on behalf of an organization or entity, including a group of other
          Writers on the Services, you represent that you have the authority and right to agree to this Agreement
          on that organization or entity’s behalf and automatically bind them to this Agreement (in which case,
          the references to "you" and "your" throughout this document refer to that organization or entity). In
          other words, while you are free to work with others to contribute to your Writer Content (as defined in
          Section 4 below), you are solely responsible for managing your relationships with these contributors,
          and must have their permission before submitting the Writer Content to us. Dorian’s relationship is only
          with you, and we will not be responsible in the event of any disputes between you and other contributors
          to your Writer Content.
        </p>
        <p>
          If you don’t agree to the terms and conditions of this Agreement, you may not use or access the Services
          in any manner. If you have any questions, comments, or concerns regarding the Agreement or the Services,
          please contact us at
          {' '}
          <a href="mailto:support@dorian.live">support@dorian.live</a>
          .
        </p>
        <p>
          We are constantly trying to improve the Services, so this Agreement may need to change, along with the
          Services. We reserve the right to change the Agreement at any time and unless provided otherwise, the
          changes will be effective immediately. If we do, we will use reasonable efforts to bring the changes to
          your attention by placing a notice on the website, by sending you an email, and/or by some other means.
          If you don’t agree with the revised Agreement, you are free to reject them, but you will no longer be
          able to use the Services. If you use the Services in any way after a change to the Agreement is
          effective, that means you agree to the changes. Except for changes by us as described here, no other
          amendment or modification of this Agreement will be effective unless in writing and signed by both you
          and us.
        </p>
        <div className="section-title">2. Account Creation, Suspension and Termination</div>
        <br />
        <p>
          To access certain parts of the Services, you will need to create an account (
          <b>"Account</b>
          "). When you
          register for an Account or update your information, you agree to provide accurate information that you
          will keep up-to-date at all times. You may never allow anyone else to use your Account (except your
          parents or legal guardian if you are under the age of 18). If you have reasons to believe that your
          Account is no longer secure, then you must immediately notify us at
          {' '}
          <a
            href="mailto:support@dorian.live"
          >
            support@dorian.live
          </a>
          . You agree that we may take steps to verify the
          accuracy of information you provide, including contact information for a parent or guardian. If you
          violate any provision of this Agreement we may, in our sole discretion, suspend or terminate your
          Account and/or your access to the Services. To the maximum extent permitted by law, we are not
          responsible or liable as a result of any suspension or termination of your Account or access to or use
          of the Services.
        </p>
        <p>
          You may terminate your Account at any time directly via the Site or the App or by contacting us at
          {' '}
          <a
            href="mailto:support@dorian.live"
          >
            support@dorian.live
          </a>
          . If you are under 18, your parent/guardian may
          contact us to request the termination of your Account.
        </p>
        <div className="section-title">
          3. Content Removal
        </div>
        <br />
        <p>
          We may, in our sole discretion and for any reason, including if you violate any provision of this
          Agreement, remove any of your Writer Content (as defined below), at any time and without notice. To the
          maximum extent permitted by law, we are not responsible or liable as a result of such removal.
        </p>
        <p>
          You can remove your Writer Content at any time by specifically deleting it or by contacting us at
          {' '}
          <a
            href="mailto:support@dorian.live"
          >
            support@dorian.live
          </a>
          . You understand that it may take us up to thirty
          (30) days to process your request. You should also know that in certain instances, some of your Writer
          Content may not be removed or completely removed and copies may continue to exist as part of the
          Services. To the maximum extent permitted by law, we are not responsible or liable for the removal or
          deletion of, or the failure to remove or delete, any of your Writer Content. You or your parent/guardian
          may request the removal of your Writer Content as permitted under applicable law by contacting us at
          {' '}
          <a
            href="mailto:support@dorian.live"
          >
            support@dorian.live
          </a>
          .
        </p>
        <div className="section-title">4. Ownership and Licenses</div>
        <br />
        <p>
          Any original content you create, post, upload, share, store, or otherwise provided to Dorian, excluding
          any and all Dorian Technology (as defined below) ("
          <b>Writer Content</b>
          ") and all intellectual property
          and proprietary rights contained therein remain yours and are protected by copyright and any other
          applicable intellectual property laws. You hereby grant Dorian a worldwide, nonexclusive, sublicensable,
          royalty-free, fully paid-up, transferable, irrevocable and perpetual right and license to use, post,
          publish, share, store, reproduce, alter, modify and create derivative works based upon your Writer
          Content solely in order to enable us to operate the Services, and to permit other Dorian users
          ("
          <b>Users</b>
          ") to use, access, and download your Writer Content.
        </p>
        <p>
          We offer the ability to create "prompts" via the Services, where you can create a writing prompt or
          begin a story and allow other Writers to create Writer Content the prompt idea. If you use the "prompt"
          feature, you additionally grant other Writers the right to create similar or derivative works based upon
          your prompts, solely within our Service. This means that other Writers may create new pieces of Writer
          Content based upon your Writer Content, and may monetize that new Writer Content without any obligation
          to compensate you. We may also offer the ability within the Services to designate a given piece of
          Writer Content as free for other Writers to create derivative works from, in which case the same right
          to create derivative works described above will apply. If your prompt is used by another Writer to
          create Writer Content, our Services include a feature indicating that the Writer Content was inspired by
          your prompt; however, you acknowledge that by creating and sharing a prompt, you will not be entitled to
          any compensation and/or royalty from us or any other Writer based on their use of your prompt. For
          clarity, the licenses described in this section do not grant any rights or permissions for a Writer or
          anyone else to make use of your Writer Content independent of our Services.
        </p>
        <p>
          Dorian retains all ownership, right, and title to the Services (including the Platform) and all related
          assets and technology including, without limitation, the Dorian character creation engine, all art and
          sound assets and libraries, character models, background libraries, story templates, "look and feel",
          and any and all additions, extensions, modifications, and/or improvements thereto, as well as all
          trademark, patent, copyright and any other intellectual property rights relating thereto (collectively,
          the "
          <b>Dorian Technology</b>
          "). Dorian hereby grants you a limited, nonexclusive, royalty-free,
          worldwide license to use the Dorian Technology solely in connection with the development of Writer
          Content to be distributed on the Platform, in accordance with the terms of this Agreement. Both during
          the term of this Agreement and thereafter, each copy of Writer Content which incorporates the Dorian
          Technology shall prominently display the following notice: "This game is powered by Dorian. © 2021
          Dorian, Inc. All Rights Reserved." or such other notice as Dorian may designate from time to time. This
          notice shall be included whether or not other proprietary notices are appropriate or necessary.
        </p>
        <p>
          Dorian can use or not use the Writer Content as it sees fit and for any purpose. Dorian will have final
          creative control over the final marketing and commercial exploitation of the Writer Content to the
          extent such Writer Content or any part thereof forms part or is otherwise incorporated into or used with
          the Services including, without limitation, any and all cross-promotions and viral promotion with other
          products and services and any other methods of promotion.
        </p>
        <div className="section-title">5. Credits and Name and Likeness</div>
        <br />
        <p>
          You will receive appropriate credit for your contribution. The size and placement of any such credit
          will be determined by Dorian in its sole discretion and the failure to provide any such credit will not
          be deemed a material breach of this Agreement.
        </p>
        <p>
          You hereby grant Dorian a worldwide, irrevocable, fully paid-up, royalty-free, and fully sublicensable
          right and license and all necessary permission and consent to use, reproduce, publish, distribute,
          transmit, display, and otherwise use your name, voice, signature, image and likeness and related
          statements, comments and remarks and all other persona or other attributable rights ("
          <b>
            Writer
            Likeness
          </b>
          ") in connection with Dorian’s use of the Writer Content as part of or in connection with
          the Services and in connection with advertising and promotion of the Services and/or Dorian’s business,
          in any and all media, now known or hereafter devised.
        </p>
        <div className="section-title">6. Pricing and Payments</div>
        <br />
        <p>
          We offer Users the possibility to acquire and use virtual entitlements such as digital coins or hearts
          (collectively, "
          <b>Virtual Entitlements</b>
          ") in connection with their use of the Services, as permitted
          by us, and subject to this Agreement. While most Writer Content on our Services is provided and shared
          for free, we may, in our discretion and based on our internal algorithms, allow some Writers to
          designate some of their Writer Content as "paid," ("
          <b>Paid Writer Content</b>
          ") meaning that Users may
          be required to redeem Virtual Entitlements to access it. (For avoidance of doubt, references to "
          <b>
            Writer
            Content
          </b>
          " in this Agreement include Paid Writer Content.) Writers whose Paid Writer Content generates
          revenue may be entitled to receive a portion of that revenue, subject to Dorian’s internal compliance
          determinations, applicable law, and the provisions of this Agreement.
        </p>
        <p><i>Eligibility</i></p>
        <p>
          If selected to create Paid Writer Content, you agree to give Dorian final decision-making power over all
          monetization and pricing decisions within the Services. In other words, Dorian may offer you choices
          and/or consult with you regarding your preferences, however, Dorian will have final say over the
          quantity and types of Virtual Entitlements that must be redeemed to access your Paid Writer Content, and
          you agree Dorian may distribute your Paid Writer Content for free in its discretion (for example, for
          promotional purposes).
        </p>
        <p><i>Payouts</i></p>
        <p className="margin-left">

          (a)&nbsp;&nbsp;On a monthly basis, Dorian will send you reports detailing the amount of Virtual
          Entitlements redeemed by Users to access your Paid Writer Content (each a "
          <b>Report</b>
          "), and the
          corresponding net revenue for Dorian at the then current exchange rate, after deduction of all charges
          payable by Dorian on such revenue (the "
          <b>Net Revenue</b>
          "). Upon receiving such report, and provided
          that the global Net Revenue attributable to your Paid Writer Content (as visible in your Account) is
          above $100, you will be eligible to receive payments by Dorian in U.S. dollars equivalent to 50% of such
          Net Revenue minus any transaction fee payable by Dorian.
        </p>
        <p className="margin-left">

          (b)&nbsp;&nbsp;Only users who acquire Virtual Entitlements in compliance with this Agreement may redeem
          Virtual Entitlements using the Services. You are not eligible to redeem any Virtual Entitlement that was
          purchased by you or any third party for the purpose of redeeming such purchased Virtual Entitlement for
          cash.
        </p>
        <p className="margin-left">

          (c)&nbsp;&nbsp;Users must be in good standing with Dorian, including by not engaging in any activities
          that are intended, are likely to, or in fact violate this Agreement, including, without limitation,
          engaging in any of the following: any actual or attempted fraud, unlawful behavior as applicable to the
          Services, unfair or otherwise deceptive activity, or any conduct that is deemed a violation of this
          Agreement, as determined by Dorian in its sole discretion.
        </p>
        <p className="margin-left">

          (d)&nbsp;&nbsp;You must provide all reasonably requested information for Dorian to process any payouts
          including without limitation, any information requested to complete an applicable tax filing report.
        </p>
        <p className="margin-left">

          (e)&nbsp;&nbsp;Dorian may not be able to remit payouts to users in certain countries, entities, or
          individuals because of legal or other considerations.
        </p>
        <p>
          Payments will be made on a no more frequently than monthly basis, within [45] days of issuance of a
          Report to you provided that the global Net Revenue attributable to your Paid Writer Content exceeds
          $100. No payment will be made in the months when the monthly Net Revenue associated with your Paid
          Writer Content is below $100.
        </p>
        <p>
          We may suspend, deny or report any payment if we reasonably suspect activity which would violate, or
          assist in violation of, any law, statute, ordinance, or regulation, or sanctions programs administered
          in the countries where we conduct business, or which would involve proceeds of any unlawful
          activity.
          {' '}
          <b>
            Any fraudulent or unauthorized use of Virtual Entitlements is strictly prohibited and may
            result in termination or disqualification from the Services.
          </b>
        </p>
        <p>
          We are constantly trying to improve our Services and consider our users’ feedback. In that regard, we
          reserve the right to change our monetization methods and the rules applicable to the use of Virtual
          Entitlements by our users at any time and for any reason. You further understand that Dorian reserves
          the right to amend the percentage of Net Revenue payable to you under this Agreement, provided that
          Dorian will use commercially reasonable efforts to notify you of such changes.
        </p>
        <p>
          Prior to any payments being made to you, we require you to have an IRS form W-9 (for U.S. taxpayers) or
          W-8 (for non-U.S. taxpayers) on file with us. If you are between the ages of 13 and 18, your parent or
          legal guardian must supervise your preparation of these materials. All payments made to U.S. users
          (other than for certain limited exceptions due to reporting requirements) will be reported to the IRS
          and to you using a form 1099. Payments made to non-U.S. users will not be reported to their home
          countries. You are responsible for any taxes that may be owed in connection with any payment made to you
          in accordance with this Section. You are also responsible for ensuring that all payments received are
          reported to the proper taxing authorities. You understand that we may not be able to remit payments to
          certain countries, entities, or individuals because of legal or other considerations. If you agree to
          this Agreement in your capacity as a Parent or legal guardian of a user, you understand that income
          earned by minors can impact their parents’ tax returns.
        </p>
        <p>
          We may use one or more third-party payment processors for the remission of payment in accordance with
          this Agreement or to provide the Services to you. Such third-party payment processors may include
          Tipalti Payments, Inc. ("Tipalti"). Tipalti as a third-party payment processor may charge a processing
          fee each time it processes a payment. Please review Tipalti’s Terms of Use available at
          https://tipalti.com/terms-of-use/ to understand how Tipalti processes payments, and Tipalti’s privacy
          policy available at https://tipalti.com/privacy/ to understand Tipalti’s privacy practices.
        </p>
        <div className="section-title">7. Confidentiality</div>
        <br />
        <p>
          Each party agrees to keep in confidence the other party’s confidential or proprietary information that
          is designated in writing to the other party as confidential or which should otherwise be reasonably
          deemed confidential unless (i) such information becomes public or is otherwise received by the receiving
          party from a third party without a duty of confidentiality; (ii) is independently developed without
          breach of this Agreement; or (iii) is required to be disclosed by law, in which case the receiving party
          shall notify the disclosing party and only disclose the minimum amount of information necessary. Each
          party agrees that the terms of this Agreement shall be deemed confidential and may not be disclosed
          without the prior written consent of the other party.
        </p>
        <div className="section-title">8. Representations and Warranties</div>
        <br />
        <p>
          You represent and warrant that:
        </p>
        <p className="margin-left">
          (a)&nbsp;&nbsp;you are free to enter into this Agreement and that your performance thereunder will not
          conflict with any other agreement to which you may be a party;
        </p>
        <p className="margin-left">
          (b)&nbsp;&nbsp;the Writer Content is unique and original, clear of any claims or encumbrances, you own
          and/or are fully authorized to use all source materials included in, used in the creation of or from
          which the Writer Content is derived, and the Writer Content does not infringe, misappropriate or violate
          a third party’s intellectual property rights, or rights of publicity or privacy, or result in the
          violation of any applicable law or regulation;
        </p>
        <p className="margin-left">
          (c)&nbsp;&nbsp;you will not use the Services in a manner that is or may be considered harmful,
          fraudulent, deceptive, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous,
          objectionable, or in any way violates Dorian’s Community Guidelines;
        </p>
        <p className="margin-left">
          (d)&nbsp;&nbsp;you will not interfere with, disrupt, damage, or access in an unauthorized manner the
          devices, servers, networks, or other properties or services of Dorian or any third party;
        </p>
        <p className="margin-left">
          (e)&nbsp;&nbsp;you will not spread a virus, trojan horse, worm, time bomb, or other harmful computer
          code, file, or program;
        </p>
        <p className="margin-left">
          (f)&nbsp;&nbsp;you will not attempt to identify any individual Writer and/or User, including from
          aggregated user reports provided to you by us; and
        </p>
        <p className="margin-left">
          (g)&nbsp;&nbsp;you will not encourage or enable anyone else to violate the terms of this Agreement or
          the requirements above.
        </p>
        <div className="section-title">9. Indemnity</div>
        <br />
        <p>
          To the fullest extent permitted by applicable law, you agree to indemnify and hold Dorian, its
          affiliates, officers, agents, employees, and partners (the "Dorian Parties") harmless from and against
          any and all claims, liabilities, damages (actual and consequential), losses and expenses (including
          attorneys’ fees) arising from or in any way related to any third-party claims (including but not limited
          to claims from others who may have contributed to the development of your Writer Content) relating to
          (a) your use of the Services (including any actions taken by a third party using your account); (b) your
          Writer Content; (c) any breach or alleged breach of your representations and warranties or of any of
          your obligations under this Agreement (d) any violation of applicable law; and (e) any negligence or
          willful misconduct.
        </p>
        <div className="section-title">10. Third-Party Materials</div>
        <br />
        <p>
          The Services may include, display, or make available content, data, information, applications or
          materials from third parties, including open source software or other products released under an
          open-source license ("Third-Party Materials"). You understand that we provide access only as a
          convenience and are not responsible for such Third-Party Materials and you acknowledge sole
          responsibility for and assume all risk arising from your use of any Third-Party Materials.
        </p>
        <div className="section-title">11. Disclaimers</div>
        <br />
        <p>
          THE SERVICES ARE PROVIDED TO YOU ON AN "AS-IS" BASIS. THIS MEANS WE PROVIDE THEM TO YOU WITHOUT ANY
          EXPRESS OR IMPLIED WARRANTIES OF ANY KIND. THAT INCLUDES ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
          WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR ANY WARRANTY THAT THE USE OF THE
          SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
        </p>
        <div className="section-title">12. Limitation of Liability</div>
        <br />
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES WILL DORIAN BE LIABLE FOR ANY
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND. EXCEPT FOR THE AMOUNTS DUE
          BY DORIAN UNDER SECTION 6 OF THIS AGREEMENT, DORIAN’S TOTAL AGGREGATE LIABILITY WILL NOT EXCEED THE
          GREATER OF (1) $100 OR (2) THE AMOUNTS PAID BY DORIAN TO YOU IN CONNECTION WITH THE PAID WRITER CONTENT
          IN THE SIX (6) MONTHS PERIOD PRECEDING THE APPLICABLE CLAIM.
        </p>
        <div className="section-title">13. Term; Termination; Survival</div>
        <br />
        <p>
          This Agreement is valid until terminated by either party in accordance with the terms of this Agreement.
          Either party may terminate this Agreement at any time for any reason. You understand however that as
          long as you use the Services to create content to be distributed via the Platform and therefore act as a
          Writer, you will be subject to the terms of this Agreement. The rights and obligations contained in 4,
          5, 7, 8, 9, 11, 12, 13, 14 and 15 will survive any termination or expiration of this Agreement.
        </p>
        <div className="section-title">14. Governing Law; Dispute Resolution</div>
        <br />
        <p>
          The parties agree that this Agreement will be governed in accordance with the laws of the State of
          California, without giving effect to its conflict of law and/or choice of law principles.
        </p>
        <p>
          The parties further agree that any dispute arising under this Agreement shall be settled by confidential
          arbitration before an arbitrator pursuant to the rules of JAMS Streamlined Arbitration Rules and
          Procedures (for claims under $250,000) or the JAMS Comprehensive Arbitration Rules and Procedures (for
          claims over $250,000), conducted with a single arbitrator. The parties to such arbitration shall each
          bear their own costs and expenses related thereto. Any such arbitration shall be conducted in accordance
          with the laws of the State of California and shall take place in Los Angeles County, California.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, THE PARTIES AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
          ONLY IN ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING. Further, if the parties’ dispute is resolved through arbitration, the
          arbitrator may not consolidate another person’s claims with your claims, and may not otherwise preside
          over any form of a representative or class proceeding. If this specific provision is found to be
          unenforceable, then the entirety of this Section shall be null and void.
        </p>
        <div className="section-title">15. General</div>
        <br />
        <p>
          This Agreement represents the entire agreement between the parties concerning the subject matter herein
          and supersedes any other oral or written agreements between the parties regarding this matter. You
          hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Dorian
          and you do not have the authority to bind Dorian in any respect whatsoever. The parties agree there are
          no third-party beneficiaries intended under this Agreement. If any part, term, or provision of this
          Agreement is held by an arbitrator or a court of competent jurisdiction to be illegal, unenforceable, or
          in conflict with any relevant law, the remaining portions or provisions shall still remain valid and
          continue in full force and effect. No waiver, express or implied, by either party of any term or
          condition or of any breach by the other party of any of the provisions of this Agreement shall operate
          as a waiver of any breach of the same or any other provision of this Agreement. This Agreement shall be
          binding upon and inure to the benefit of each of the parties hereto and their respective heirs,
          successors and assigns. Writer may not assign his/her rights or obligations hereunder without Dorian’s
          prior written consent and any assignment or attempted assignment without such prior written consent
          shall be null and void. Dorian may assign this Agreement for any reason.
        </p>
      </>
    );
  }
}
