import React, { PropsWithChildren, useState } from 'react';
// @ts-ignore: no def file
import { useHistory } from 'react-router';
import { PageWrapper } from 'components/ui/PageWrapper';
import { AuthProps } from '../../../dorian-shared/types/auth/AuthProps';
import { PageLayout } from '../../../dorian-shared/types/pageLayout/PageLayout';
import { PerformanceProfiler } from '../PerformanceProfiler/PerformanceProfiler';
import { BugTracking } from './BugTracking/BugTracking';
import { StreamTest } from './StreamTest/StreamTest';

export interface AdminSupportPanelProps extends PropsWithChildren {
  auth: AuthProps
}

enum AdminSupportPanelAction {
  BugTracker = 'Bug tracking',
  WebClient = 'Web client',
}

export function AdminSupportPanel(props: AdminSupportPanelProps) {
  const { auth } = props;
  const user = auth.getUser();
  const history = useHistory();
  const [currentAction, setCurrentAction] = useState(AdminSupportPanelAction.BugTracker);

  const pageLayout: PageLayout = {
    header: {
      title: 'Admin support panel',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          action: () => history.goBack(),
          variant: 'secondary',
          disabled: false,
        },
        {
          title: AdminSupportPanelAction.BugTracker,
          action: () => setCurrentAction(AdminSupportPanelAction.BugTracker),
          variant: currentAction === AdminSupportPanelAction.BugTracker ? 'primary' : 'secondary',
        },
        {
          title: AdminSupportPanelAction.WebClient,
          action: () => setCurrentAction(AdminSupportPanelAction.WebClient),
          variant: currentAction === AdminSupportPanelAction.WebClient ? 'primary' : 'secondary',
        },
      ],
    },
  };

  return (
    <PerformanceProfiler profilerId="Admin support panel component" mount>
      <PageWrapper user={user} page={pageLayout}>
        {currentAction === AdminSupportPanelAction.BugTracker && (
        <BugTracking />
        )}
        {currentAction === AdminSupportPanelAction.WebClient && (
        <StreamTest />
        )}
      </PageWrapper>
    </PerformanceProfiler>
  );
}
