import async from 'async';
import classNames from 'classnames/bind';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Container, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { api } from '../../api';
import { AvatarToolModal } from '../../ui/AvatarTool/AvatarToolModal';
import { getBookType, getCharacterLockingVisible } from '../Book/utils';
import styles from './Characters.scss';
import { CharactersCatList } from './CharactersCatList';
import { createGroupsWithCharacters } from './utils';

const cs = classNames.bind(styles);

const SortableItemCharacter = SortableElement(({
  characterGroup, i, disabledRow, ...props
}) => (
  <CharactersCatList
    key={i}
    index={i + 1}
    {...props}
    characterGroup={characterGroup}
    disabled={disabledRow || characterGroup.disabled}
  />
));

const SortableListCharacters = SortableContainer(({ items, ...props }) => {
  if (items.length > 0) {
    return (
      <div>
        {
          items.map((characterGroup, index) => {
            // eslint-disable-next-line no-param-reassign
            characterGroup.disabled = props.disabled;
            return (
              <SortableItemCharacter
                {...props}
                disabled={props.disabled}
                /* eslint-disable-next-line react/no-array-index-key */
                key={`item-${index}`}
                index={index}
                characterGroup={characterGroup}
                i={index}
                characters={items}
              />
            );
          })
}
      </div>
    );
  }

  return (
    <Row className="py-2 border-bottom">
      <Col md={12}>
        No characters
      </Col>
    </Row>
  );
});

export class Characters extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      characters: [],
      charactersGroups: [],
      // eslint-disable-next-line react/no-unused-state
      validated: false,
      showEditor: false,
      editObject: null,
      loading: false,
      edit: false,
      // eslint-disable-next-line react/no-unused-state
      addDisabled: false,
      editDisabled: false,
      deleteDisabled: false,
      artLoading: false,
      // eslint-disable-next-line react/no-unused-state
      wizardStep: null,
      redirectTo: null,
    };
  }

  componentDidMount() {
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    if (wizardStep === 7) {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        wizardStep,
      }, () => {
        localStorage.setItem('wizardStep', wizardStep);
      });
    }
    this.loadCharacters();
  }

  loadCharacters = () => {
    this.setState({
      loading: true,
      edit: false,
    });
    async.parallel({
      characters: (callback) => {
        const { id } = this.props;
        api.get(`/v1/books/${id}/characters`)
          .then((res) => {
            callback(null, res.data.characters);
          }).catch((error) => {
            callback(error, null);
          });
      },
      originalBook: (callback) => {
        const { book } = this.props;

        if ((book.sourceBookId || book.isPrompt)
          && book.originalBookId) {
          api.get(`/v1/books/${book.originalBookId}`)
            .then((res) => {
              callback(null, res.data.book);
            }).catch((error) => {
              callback(error, null);
            });
        } else {
          callback(null, book);
        }
      },
    }, (err, res) => {
      if (!err) {
        const createCharacters = () => {
          const createdCharacters = [];
          res.characters.sort((a, b) => (a && a.group
            ? a.group.localeCompare(b.group)
            : 1)).map(
            (value) => {
              const { book } = this.props;
              if (res.originalBook?.uuid) {
                // eslint-disable-next-line no-param-reassign
                value.imageUrl = value.imageUrl
                  .replace(book.uuid, res.originalBook.uuid);
              }
              if (value.properties) {
                // eslint-disable-next-line no-param-reassign
                value.avatar = {
                  properties: value.properties,
                  imageName: value.imageName,
                  updatedAt: value.updatedAt,
                };
                // eslint-disable-next-line no-param-reassign
                value.avatar.alias = value.imageAlias;
                // eslint-disable-next-line no-param-reassign
                value.avatar.imageUrl = value.imageUrl;
                // eslint-disable-next-line no-param-reassign
                value.avatar.book_uuid = res.originalBook?.uuid || book.uuid;
              }
              const group = !value.group
                || value.group === 'null'
                || value.group === 'Uncategorized'
                ? 'Uncategorized' : value.group;
                // eslint-disable-next-line no-param-reassign
              value.group = group;
              const catName = createdCharacters.find((c) => c.category === group);
              if (!catName) {
                createdCharacters.push({ category: group, list: [] });
              }
              return createdCharacters.forEach((c) => {
                if (c.category === group) {
                  c.list.push(value);
                }
              });
            },
          );
          return createdCharacters;
        };
        this.setState({
          characters: createCharacters(),
          charactersGroups: createGroupsWithCharacters(res.characters),
          showEditor: false,
          editObject: null,
          loading: false,
        });
      }
    });
  };

  onSortEnd = () => null;

  charactersAlert() {
    const {
      characters,
      loading,
    } = this.state;

    if (characters.length < 1 && loading === false) {
      return (
        <Alert variant="info">
          Characters still don&apos;t exist. Please Add Character.
        </Alert>
      );
    }
    return null;
  }

  onStopEdit = () => {
    this.setState({
      showEditor: false,
      editObject: null,
      edit: false,
    });
  };

  onStartEdit = () => {
    this.setState({
      edit: true,
    });
  };

  showEditModal = (obj) => {
    this.setState({
      showEditor: true,
      editObject: obj || null,
      edit: true,
    });
  };

  render() {
    const {
      update,
      id,
      title,
      showImagePath,
      book,
      disabledEdit,
      user,
      disabled,
      limits,
      context,
      ...other
    } = this.props;

    const {
      showEditor,
      loading,
      edit,
      artLoading,
      charactersGroups,
      deleteDisabled,
      editObject,
      editDisabled,
      characters,
      redirectTo,
    } = this.state;

    if (redirectTo) {
      return <Redirect push to={redirectTo} />;
    }

    const { characterExpressions } = context;

    const modalTitle = title ? `: ${title}` : `# ${id}`;

    const args = {
      book,
      loading,
      edit,
      onStartEdit: this.onStartEdit,
      onStopEdit: this.onStopEdit,
      update: this.loadCharacters,
      id,
      editDisabled,
      deleteDisabled,
    };

    const bookType = getBookType(book);
    const {
      isCharacterLockingForAdminVisible,
      isCharacterLockingForNonAdminVisible,
    } = getCharacterLockingVisible(user.role, bookType);

    return (
      <>
        <Modal
          {...other}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >

          <Modal.Header closeButton>
            <Modal.Title>
              Characters for
              {' '}
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">

            {this.charactersAlert()}

            <div className={cs('wrapperTable')}>

              <Container>
                <Row>
                  <Col className="text-right">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="px-4 my-0"
                      onClick={() => this.setState({ redirectTo: '/character-art-library' })}
                    >
                      Art Library
                    </Button>
                    <Button
                      disabled={
                        showEditor
                        || edit
                        || disabled
                        || disabledEdit
                      }
                      className="my-0 ml-2"
                      size="sm"
                      variant="primary"
                      onClick={() => {
                        this.showEditModal();
                      }}
                    >
                      + Add Character
                    </Button>
                  </Col>
                </Row>
                <Row className="py-2 border-bottom  align-items-center">
                  <Col sm={1} className="h6 mb-0 text-nowrap text-center px-0">Player</Col>
                  <Col className="h6 mb-0 text-nowrap ">Name</Col>
                  <Col md={2} className="h6 mb-0 text-nowrap">Alias</Col>
                  <Col md={1} className="h6 mb-0 text-nowrap">Type</Col>
                  {showImagePath && <Col className="h6 mb-0 text-nowrap">Image Path</Col>}
                  <Col md={2} className="h6 mb-0 text-nowrap text-center">Image</Col>
                  { isCharacterLockingForNonAdminVisible && (
                  <Col md={1} className="h6 mb-0 text-nowrap text-center">
                    Locked In
                    <br />
                    Spin-Offs
                  </Col>
                  )}
                  { isCharacterLockingForAdminVisible && (
                  <Col md={1} className="h6 mb-0 text-nowrap text-center">
                    Locked
                    <br />
                    Nameplate
                  </Col>
                  )}
                  <Col className="h6 mb-0 text-nowrap text-right">
                    Actions
                  </Col>
                </Row>
                <SortableListCharacters
                  disabled={
                    disabled
                    || disabledEdit
                  }
                  items={characters}
                  itemsList={charactersGroups}
                  currentItem={this}
                  onSortEnd={this.onSortEnd}
                  helperClass="sort-item"
                  distance={1}
                  lockAxis="y"
                  args={args}
                  limits={limits}
                  showImagePath={showImagePath}
                  artLoading={artLoading}
                  userCanEditCustom={user.role === 'admin' || (user.actions && user.actions.includes('characters'))}
                  edit={this.showEditModal}
                  expressions={characterExpressions}
                  userRole={user.role}
                  bookType={bookType}
                />
              </Container>

              <div className={cs(loading === false ? 'd-none' : 'd-block', 'wrapperTableLoader')}>
                <div className={cs('wrapperTableSpinner')}><Spinner animation="border" variant="primary" /></div>
              </div>
              <div className={cs(edit === false ? 'd-none' : 'd-block', 'wrapperTableLoader')} />
            </div>
          </Modal.Body>
        </Modal>
        <AvatarToolModal
          charactersGroups={charactersGroups}
          show={showEditor}
          bookId={book.id}
          character={editObject}
          update={() => {
            this.setState({
              showEditor: false,
              editObject: null,
              edit: false,
            }, () => {
              this.loadCharacters();
            });
          }}
          onHide={() => {
            this.setState({
              showEditor: false,
              editObject: null,
              edit: false,
            });
          }}
        />
      </>
    );
  }
}
