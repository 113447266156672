import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { DeleteArticle } from '../Articles/DeleteArticle';
import { EditArticle } from '../Articles/EditArticle';
import { Comments } from './comments';

export class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {
        title: '',
        body: '',
      },
      editArticle: false,
      deleteArticle: false,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  // eslint-disable-next-line react/sort-comp
  loadData() {
    // eslint-disable-next-line react/destructuring-assignment
    api.get(`v1/blog/posts/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.post,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  editArticle = () => {
    this.setState({
      editArticle: true,
    });
  };

  deleteArticle = () => {
    this.setState({
      deleteArticle: true,
    });
  };

  render() {
    const page = {
      header: {
        title: 'Article',
        settings: this.user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to Articles List',
            action: '',
            href: '/articles',
            variant: 'secondary',
            disabled: false,
          },
          (this.user.role === 'admin')
          && {
            title: 'Edit Article',
            action: () => this.editArticle(),
            variant: 'primary',
            // eslint-disable-next-line react/destructuring-assignment
            disabled: this.state.loading || this.user.role !== 'admin',
          },
          (this.user.role === 'admin')
          && {
            title: 'Delete Article',
            action: () => this.deleteArticle(),
            variant: 'primary',
            // eslint-disable-next-line react/destructuring-assignment
            disabled: this.state.loading || this.user.role !== 'admin',
          },
        ],
      },
    };

    const addArticle = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.editArticle) {
        return (
          <EditArticle
            show
            user={this.user}
            onHide={() => {
              this.setState({
                editArticle: false,
              });
            }}
            // eslint-disable-next-line react/destructuring-assignment
            obj={this.state.data}
            update={() => {
              this.setState({
                editArticle: false,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    const deleteArticle = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.deleteArticle) {
        return (
          <DeleteArticle
            {...this.props}
            show
            onHide={() => {
              this.setState({
                deleteArticle: false,
              });
            }}
            // eslint-disable-next-line react/destructuring-assignment
            obj={this.state.data}
            update={() => {
              window.location.replace('/articles');
            }}
          />
        );
      }
      return null;
    };

    const { data } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
        >
          <Card className="my-3">
            <Card.Title className="px-3 pt-3">
              <Row>
                <Col>
                  <h3>{data.title}</h3>
                </Col>
              </Row>
            </Card.Title>

            <Card.Body className="py-1 pb-4">
              { /* eslint-disable-next-line react/no-danger */ }
              <div dangerouslySetInnerHTML={{ __html: data.body }} />
            </Card.Body>
          </Card>

          <Comments
            {...this.props}
          />

        </PageWrapper>

        {addArticle()}
        {deleteArticle()}
      </>
    );
  }
}
