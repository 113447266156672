import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { api } from '../../../api';
import { EventFormValues, createFormDataFromFormValues } from '../components/EventForm/EventForm';

export function useUpdateEvent(eventId: number) {
  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const updateEvent = (values: Omit<EventFormValues, 'detailedEventPrizes'>) => {
    setToLoading();
    const formData = createFormDataFromFormValues(values);
    return api.put(`/v1/events/${eventId}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then(() => {
        setToSuccess();
      })
      .catch((error) => {
        const possibleErrorFromServer = error.response.data?.error ?? 'There was issue when creating event, please contact support or try again later.';
        setToError();
        throw new Error(possibleErrorFromServer);
      });
  };
  return { isLoading, updateEvent };
}
