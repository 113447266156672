import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import './SelectAsyncPaginate.scss';

const CACHE = {};

const defaultRenderMenuItem = (option) => (
  <div key={option.id}>
    <span>{option.fullname}</span>
  </div>
);

/**
 * Wrapper for AsyncTypeahead from react-bootstrap-typeahead
 * Used as select component with search/filter/paginate/cache and async request
 * @param {*} props
 * @returns
 */
export function SelectAsyncPaginate(props) {
  const {
    placeholder,
    showPerPage,
    labelKey,
    onSearch,
    renderMenuItem = defaultRenderMenuItem,
    onSelect,
    ...otherProps
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState('');

  const handlePagination = (e, shownResults) => {
    const cachedQuery = CACHE[searchText];

    // Don't make another request if:
    // - the cached results exceed the shown results
    // - we've already fetched all possible results
    if (
      cachedQuery.options.length > shownResults
      || cachedQuery.options.length === cachedQuery.totalCount
    ) {
      return;
    }

    setIsLoading(true);

    const page = cachedQuery.page + 1;

    onSearch(searchText, page).then((resp) => {
      const allOptions = cachedQuery.options.concat(resp.options);
      CACHE[searchText] = { ...cachedQuery, options: allOptions, page };

      setIsLoading(false);
      setOptions(allOptions);
    });
  };

  // `handleSearch` updates state and triggers a re-render, so
  // use `useCallback` to prevent the debounced search handler from
  // being cancelled.
  const handleSearch = useCallback((searchTerm) => {
    if (CACHE[searchTerm]) {
      setOptions(CACHE[searchTerm].options);
      return;
    }

    setIsLoading(true);

    onSearch(searchTerm).then((resp) => {
      CACHE[searchTerm] = { ...resp, page: 1 };

      setIsLoading(false);
      setOptions(resp.options);
    });
  }, [onSearch]);

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  // const filterBy = () => true;

  return (
    <AsyncTypeahead
      inputProps={{ id: 'select_id', name: 'select_name' }}
      promptText="Type to search users ..."
      id="async-pagination"
      isLoading={isLoading}
      labelKey={labelKey}
      maxResults={showPerPage - 1}
      minLength={3}
      onInputChange={(text) => setSearchText(text)}
      onPaginate={handlePagination}
      onSearch={handleSearch}
      delay={1000}
      options={options}
      placeholder={placeholder}
      renderMenuItemChildren={renderMenuItem}
      useCache={false}
      filterBy={() => true}
      onChange={onSelect}
      paginationText="Show more results ..."
      paginate
      clearButton
      {...otherProps}
    />
  );
}

SelectAsyncPaginate.propTypes = {
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  showPerPage: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};
