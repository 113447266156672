import React, {
  createContext, ReactNode, useCallback, useContext, useMemo, useRef, useState,
} from 'react';
import { BookLocation } from '../../dorian-shared/types/bookLocation/bookLocation';
import { useApiService } from '../ApiServiceContext/ApiServiceContext';

export type BookLocationsContextType = {
  bookLocations: BookLocation[] | null;
  fetchBookLocations: (bookId: number) => Promise<void>;
};

export const BookLocationsContext = createContext<BookLocationsContextType>({
  bookLocations: null,
  fetchBookLocations: async () => undefined,
});

export function BookLocationsContextProvider({ children }: { children: ReactNode }) {
  const [bookLocations, setBookLocations] = useState<BookLocation[] | null>(null);
  const loadingRef = useRef(false);
  const apiService = useApiService();

  const fetchBookLocations = useCallback(async (bookId: number) => {
    if (loadingRef.current) {
      return;
    }
    loadingRef.current = true;
    try {
      const locations = await apiService.fetchBookLocations(bookId);
      setBookLocations(locations);
    } finally {
      loadingRef.current = false;
    }
  }, [apiService]);

  const value = useMemo(() => ({
    bookLocations,
    fetchBookLocations,
  }), [bookLocations, fetchBookLocations]);

  return (
    <BookLocationsContext.Provider value={value}>
      {children}
    </BookLocationsContext.Provider>
  );
}

export const useBookLocationsContext = () => useContext(BookLocationsContext);
