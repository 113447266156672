import * as QueryString from 'query-string';
import React, { Component } from 'react';
import {
  Badge, Card, Col, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, Redirect } from 'react-router-dom';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { StoryCreationCheckBox } from '../StoryCreation/StoryCreationCheckBox';
import { getUserDisplayName } from '../Users';

const titleFormatter = (cell, row) => (
  <Link
    className="books-list-link"
    to={`/stories/${row.id}/branches`}
  >
    {cell}
  </Link>
);

const bookFormatter = (cell, row) => (
  <>
    <Link
      className="books-list-link"
      to={`/book/${cell.id}`}
    >
      {cell.title}
    </Link>
    {row.book && row.book.isPrompt && <Badge variant="secondary" style={{ marginLeft: '.5em' }}>Prompt</Badge>}
  </>
);

const revisionFormatter = (cell) => <span className="d-inline-block p-1">{cell}</span>;

function Caption(props) {
  const { title } = props;

  return <h2>{title}</h2>;
}

export class StoryCreationDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      created: [],
      completed: [],
      listActive: false,
      loading: true,
    };

    const { match, location, history } = this.props;

    this.date = match.params.date;

    this.params = (location && location.search) && QueryString.parse(location.search, { arrayFormat: 'index' });

    this.page = {
      header: {
        title: `Episode Creation on ${match.params.date}`,
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/creation',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    const { match } = this.props;

    this.date = match.params.date;
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { match, location } = this.props;

    if (location?.search
        && (JSON.stringify(location.search) !== JSON.stringify(prevProps.location.search))
    ) {
      this.date = match.params.date;
      this.params = (
        location
        && location.search
      ) && QueryString.parse(location.search, { arrayFormat: 'index' });
      this.setState({
        loading: true,
        redirect: false,
      }, () => {
        this.loadData();
      });
    }
  }

  loadData = () => {
    const { location } = this.props;
    const paramsSearch = location?.search && QueryString.parse(location.search, { arrayFormat: 'index' });

    const limitV1 = (paramsSearch.limitV1 && paramsSearch.limitV1 !== 'false') && paramsSearch.limitV1;

    this.setState({
      loading: true,
    });

    const params = {};
    if (limitV1) {
      params.revlimit = 1;
    }

    api.get(`v1/analytics/creation/${this.date}`, { params })
      .then((res) => {
        this.setState({
          created: res.data.created,
          completed: res.data.completed,
          listActive: true,
          loading: false,
        });
      })
      .catch(() => this.setState({ loading: false }));
  };

  handleChangeCheckBox = (e) => {
    const { location } = this.props;

    const obj = location?.search ? QueryString.parse(location.search) : [];
    obj[e.target.name] = e.target.checked;
    const redirect = `/creation/${this.date}?${QueryString.stringify(obj, { arrayFormat: 'index' })}`;
    this.setState({
      redirect,
    });
  };

  render() {
    const {
      redirect, created, loading, listActive, completed,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const columns = [{
      dataField: 'title',
      text: 'Title',
      formatter: titleFormatter,
    }, {
      dataField: 'writer',
      text: 'Lead Author',
      formatter: (cell) => getUserDisplayName(cell),
    }, {
      dataField: 'coAuthors',
      text: 'Co-Authors',
      formatter: (cell) => cell.map((el) => getUserDisplayName(el)).join(','),
    }, {
      dataField: 'book',
      text: 'Story',
      formatter: bookFormatter,
    }, {
      dataField: 'revision',
      text: 'Version',
      headerStyle: () => ({ width: '9%' }),
      formatter: revisionFormatter,
    }];

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card className={listActive ? 'd-block' : 'd-none'}>
          <Card.Body>
            <Row className="py-3">
              <StoryCreationCheckBox
                handleChangeCheckBox={this.handleChangeCheckBox}
                params={this.params}
              />
            </Row>

            {loading && (
            <Row>
              <Col>
                <div
                  className="text-center"
                  style={{ padding: '2em' }}
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="loadingSpinner justify-content-center"
                  />
                </div>
              </Col>
            </Row>
            )}
            {(!loading && created.length > 0) && (
            <Row>
              <Col>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  caption={<Caption title="Created" />}
                  data={created}
                  columns={columns}
                  striped
                  hover
                  condensed
                  bordered={false}
                />
              </Col>
            </Row>
            )}
            {(!loading && completed.length > 0) && (
            <Row>
              <Col>
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  caption={<Caption title="Released" />}
                  data={completed}
                  columns={columns}
                  striped
                  hover
                  condensed
                  bordered={false}
                />
              </Col>
            </Row>
            )}
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
