export class UndoRedo<T> {
  private undoStack: T[];
  private currentIndex: number;

  constructor(initialState: T[] = [], initialIndex = undefined) {
    this.undoStack = initialState;
    this.currentIndex = initialIndex ?? initialState.length - 1;
  }

  public do(state: T): void {
    this.currentIndex += 1;
    this.undoStack = this.undoStack.slice(0, this.currentIndex);
    this.undoStack.push(state);
  }

  public undo(): T | undefined {
    if (this.canUndo()) {
      this.currentIndex -= 1;
      return this.undoStack[this.currentIndex];
    }
    return undefined;
  }

  public redo(): T | undefined {
    if (this.canRedo()) {
      this.currentIndex += 1;
      return this.undoStack[this.currentIndex];
    }
    return undefined;
  }

  public canUndo(): boolean {
    return this.currentIndex > 0;
  }

  public canRedo(): boolean {
    return this.currentIndex < this.undoStack.length - 1;
  }

  public getCurrentState(): T {
    return this.undoStack[this.currentIndex];
  }

  public getStack(): T[] {
    return this.undoStack;
  }

  public getCurrentIndex(): number {
    return this.currentIndex;
  }
}
