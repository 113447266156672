import isEqual from 'lodash-es/isEqual';
import {
  CardMemorySlotData, MemoryDTO, MemoryFormDTO, MemoryShowIn, MemoryValue, StepCheck,
} from './memoryBankTypes';

export function getMemoryBankSlotDataById(
  memoryBank: MemoryDTO[] | null,
  checkData?: StepCheck,
): CardMemorySlotData | null {
  if (!memoryBank || !checkData) {
    return null;
  }
  const slot = memoryBank.find(
    (memoryBankSlot) => memoryBankSlot.id === Number(checkData.variableId),
  );

  if (!slot) {
    return null;
  }

  return {
    name: slot.name,
    value: checkData.value as MemoryValue,
    operator: checkData.operator,
  };
}

export const getMemoryViewValue = (showIn: Array<MemoryShowIn>): MemoryShowIn => {
  if (showIn.length === 0) {
    return MemoryShowIn.Hide;
  }
  if (showIn.includes(MemoryShowIn.Hud)) {
    return MemoryShowIn.Hud;
  }
  if (showIn.includes(MemoryShowIn.Panel)) {
    return MemoryShowIn.Panel;
  }
  if (showIn.includes(MemoryShowIn.Alert)) {
    return MemoryShowIn.Alert;
  }
  return MemoryShowIn.Hide;
};

export function convertMemoryDTOToFormDTO(memoryDTO: MemoryDTO): MemoryFormDTO {
  return {
    id: memoryDTO.id,
    type: memoryDTO.type,
    name: memoryDTO.name,
    value: memoryDTO.defaultValue,
    bookId: memoryDTO.bookId,
    displayName: memoryDTO.displayName,
    defaultChangeDescription: memoryDTO.defaultChangeDescription,
    icon: memoryDTO.icon,
    showIn: [...memoryDTO.showIn],
  };
}

export function convertMemoryFormDTOToMemoryDTO(memoryFormDTO: MemoryFormDTO): MemoryDTO {
  return {
    id: memoryFormDTO.id,
    type: memoryFormDTO.type,
    name: memoryFormDTO.name,
    defaultValue: memoryFormDTO.value,
    bookId: memoryFormDTO.bookId,
    displayName: memoryFormDTO.displayName,
    defaultChangeDescription: memoryFormDTO.defaultChangeDescription,
    icon: memoryFormDTO.icon,
    showIn: [...memoryFormDTO.showIn],
  };
}

// export const getMemoryShowInByView = (visible: boolean, viewValue: MemoryShowIn): Array<MemoryShowIn> => {
//   if (!visible) {
//     return [];
//   }
//   switch (viewValue) {
//     case MemoryShowIn.Alert:
//       return [MemoryShowIn.Alert];
//     case MemoryShowIn.Hide:
//       return [MemoryShowIn.Hide];
//     case MemoryShowIn.Panel:
//       return [MemoryShowIn.Alert, MemoryShowIn.Panel];
//     case MemoryShowIn.Hud:
//       return [MemoryShowIn.Alert, MemoryShowIn.Panel, MemoryShowIn.Hud];
//     default:
//       return [];
//   }
// };

export function isMemoryDTOEqual(memoryDTOA: MemoryDTO, memoryDTOB: MemoryDTO) {
  return isEqual(memoryDTOA, memoryDTOB);
}
