import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useState,
} from 'react';
import { auth } from '../../components/Auth/Auth';
import { MemoryIcon } from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { showToast } from '../../components/ui/utils';
import { MemoryIconPostRequest } from '../../services/memoryBankService/types';
import { useApiService } from '../ApiServiceContext/ApiServiceContext';

type MemoryIconsContextType = {
  isLoading: boolean,
  bookId?: number,
  setBookId: (bookId: number) => void,
  memoryIcons: MemoryIcon[],
  fetchMemoryIcons: () => Promise<MemoryIcon[]>,
  createMemoryIcon: (image: File, label: string) => Promise<MemoryIcon>,
};

const initialContext: MemoryIconsContextType = {
  isLoading: false,
  bookId: undefined,
  setBookId: () => {},
  memoryIcons: [],
  fetchMemoryIcons: () => Promise.resolve([]),
  createMemoryIcon: (image: File, label: string) => Promise.resolve({} as MemoryIcon),
};

export const MemoryIconsContext = createContext<MemoryIconsContextType>(initialContext);

export function MemoryIconsContextProvider({ children, initialBookId }: { children: ReactNode, initialBookId?: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [bookId, setBookId] = useState<number | undefined>(initialBookId);
  const [memoryIcons, setMemoryIcons] = useState<MemoryIcon[]>([]);

  const apiService = useApiService();

  const fetchMemoryIcons = useCallback(async () => {
    if (!bookId) {
      return [];
    }
    const memoryIconsData = await apiService.fetchMemoryIconsByBookId(bookId);
    setMemoryIcons(memoryIconsData);
    return memoryIconsData;
  }, [apiService, bookId]);

  const createMemoryIcon = useCallback(async (image: File, label: string): Promise<MemoryIcon> => {
    const userId = auth.getUser()?.id;

    if (!userId) {
      throw new Error(`User ${userId} not found`);
    }

    if (!bookId) {
      throw new Error(`Book ${bookId} not found`);
    }

    const memoryIconPostRequest: MemoryIconPostRequest = {
      label,
      bookId,
      image,
    };
    const memoryIconResponse = await apiService.createMemoryIconByUserId(userId, memoryIconPostRequest);
    setMemoryIcons([...memoryIcons, memoryIconResponse]);
    return memoryIconResponse;
  }, [apiService, bookId, memoryIcons]);

  useEffect(() => {
    if (!bookId) {
      return;
    }
    const fetchPromises = [
      fetchMemoryIcons(),
    ];
    setIsLoading(true);
    Promise.all(fetchPromises)
      .catch((error) => {
        showToast({ textMessage: 'Failed to fetch achievements' });
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [bookId, fetchMemoryIcons]);

  return (
    <MemoryIconsContext.Provider value={
      {
        isLoading,
        bookId,
        setBookId,
        memoryIcons,
        fetchMemoryIcons,
        createMemoryIcon,
      }
    }
    >
      {children}
    </MemoryIconsContext.Provider>
  );
}

export const useMemoryIconsContext = () => useContext(MemoryIconsContext);
