import { AxiosError } from 'axios';
import { User } from '../../dorian-shared/types/user/User';
import { logger } from '../../services/loggerService/loggerService';
import { userApi } from '../../services/userApi/UserApi';

export class Auth {
  constructor() {
    this.initialise = this.initialise.bind(this);
    this.getUser = this.getUser.bind(this);
    this.setUser = this.setUser.bind(this);
    this.login = this.login.bind(this);
  }

  async initialise(): Promise<void> {
    const isAuthenticated = await userApi.checkAuthentication();
    if (!isAuthenticated) {
      return;
    }
    try {
      // This method fetch legacy profile and save
      await userApi.fetchProfileLegacy();
    } catch (error) {
      const message = this.getErrorMessage(error) || 'Error occurred while trying to login.';
      logger.error(message);
    }

    try {
      // This method fetch profile and save
      await userApi.fetchProfile();
    } catch (error) {
      const message = this.getErrorMessage(error) || 'Error occurred while trying to login.';
      logger.error(message);
    }
  }

  setUser(user: User): void {
    userApi.updateProfileLegacy(user);
  }

  getUser(): User | null {
    const profile = userApi.getProfileLegacy();
    if (profile) {
      return profile;
    }
    return null;
  }

  // Legacy login
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login(response: any): void | null {
    const createTemplate = localStorage.getItem('createTemplate');
    const deviceId = localStorage.getItem('deviceId');

    localStorage.clear();
    localStorage.setItem('deviceId', deviceId || '');
    localStorage.setItem('isToken', response.success);
    localStorage.setItem('token', response.token);
    localStorage.setItem('documents', JSON.stringify(response.documents));
    userApi.updateProfileLegacy(response.user);
    localStorage.setItem('createTemplate', createTemplate || '');

    if (sessionStorage.getItem('delayRegistration') && createTemplate) {
      sessionStorage.clear();
      return window.location.replace(`/create/template/${JSON.parse(createTemplate).id}`);
    }
    sessionStorage.clear();
    window.location.reload();
    return null;
  }

  getErrorMessage(error: AxiosError | Error | string | unknown): string | null {
    if (error instanceof AxiosError) {
      return error.response?.data?.error;
    } if (error instanceof Error) {
      return error.message;
    } if (typeof error === 'string') {
      return error;
    }
    return null;
  }
}

export const auth = new Auth();
