import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class AddStatType extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
    };
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addStatType(obj, validated) {
    const { update, data } = this.props;
    if (validated === true) {
      const newStats = {
        title: obj.title,
        value: obj.value,
        trait: !!obj.trait,
        require: !!obj.require,
      };

      if (data !== null) {
        api.put(`/v1/stats/types/${data.id}`, newStats)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/stats/types', newStats)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addStatType(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const {
      onHide, data, update, ...other
    } = this.props;
    const { validated, formError } = this.state;

    let title = 'Add New Stat Type';
    let defTitle = '';
    let defValue = '';
    let defIsTrait = false;
    if (data !== null) {
      title = `Edit StatType ${data.title}`;
      defTitle = data.title;
      defValue = data.value;
      defIsTrait = data.trait;
    }

    return (
      <Modal
        {...other}
        onHide={onHide}
        data={data}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} md="5" controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  defaultValue={defTitle}
                  name="title"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose Title.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="5" controlId="Value">
                <Form.Label>Value</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Value"
                  defaultValue={defValue}
                  name="value"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a value.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="2" controlId="Password">
                <Form.Label>Trait</Form.Label>
                <Form.Check
                  custom
                  name="trait"
                  defaultChecked={defIsTrait}
                  label=""
                />
              </Form.Group>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
