import async from 'async';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Card, Form, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { PageWrapper } from '../../../ui/PageWrapper';
import { recBonusContent, recChoices, recDecisionPoints } from '../constants';
import { OptionAdminRequirements } from './OptionAdminRequirements';

export class ChapterRequirements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      alertText: null,
      success: false,
      loading: true,
      types: [],
      recommendations: {
        decisionPoints: recDecisionPoints,
        choices: recChoices,
        bonus_content: recBonusContent,
        stats: [],
      },
    };

    const { history, auth } = this.props;

    this.user = auth.getUser();

    this.page = {
      header: {
        title: 'Episode Requirements',
        settings: 'admin',
      },
      sidebar: {
        title: 'Dorian',
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.setState({
      loading: true,
    });
    async.parallel({
      types: (callback) => {
        api.get('/v1/stats/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      settings: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
          if (err.response) {
            this.errorAlert(err.response.data.error);
          }
        } else {
          this.setState({
            types: res.types,
            recommendations: res.settings?.requirements?.chapter_requirements
              ? JSON.parse(res.settings.requirements.chapter_requirements.value)
              : {},
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
        if (e.response) {
          this.errorAlert(e.response.data.error);
        }
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const obj = serialize(form, { hash: true });
    const value = {
      requirements: {
        chapter_requirements: JSON.stringify(obj.recommendations),
      },
    };
    this.saveData(value);
    event.stopPropagation();
  }

  saveData(value) {
    api.put('/v1/settings', value)
      .then((res) => {
        this.setState({
          loading: false,
        });
        this.showAlert(res.data.success, 'Saved');
        this.loadData();
      })
      .catch((error) => {
        this.showAlert(false, error);
      });
  }

  showAlert = (status, text) => {
    this.setState({
      alertText: text,
      success: status,
    });
    setTimeout(() => {
      this.setState({
        alertText: null,
      });
    }, 5000);
  };

  render() {
    const {
      validated, recommendations, types, loading, alertText, success,
    } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Alert variant={success ? 'success' : 'danger'} show={alertText != null}>
          {alertText}
        </Alert>
        <Card>
          <Card.Body>
            <div style={{ padding: '4em 0', margin: '0 auto', maxWidth: '50em' }}>
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => this.handleSubmit(e)}
                className={loading !== false ? 'd-none' : 'd-block'}
              >
                <OptionAdminRequirements
                  user={this.user}
                  recommendations={recommendations}
                  types={types}
                  show
                  hideTemplate
                />
                <div className="text-center">
                  <Button
                    disabled={(loading)}
                    type="submit"
                    variant="primary"
                  >
                    Save
                  </Button>
                </div>
              </Form>
              {loading && (
                <div className="text-center">
                  <Spinner
                    variant="primary"
                    animation="border"
                    className="loadingSpinner justify-content-center"
                  />
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
