import { TagGroupId } from '../../../../dorian-shared/types/story/StoryTag';

export function getTagGroupPrefix(groupId: TagGroupId) {
  switch (groupId) {
    case TagGroupId.ArtStyle:
      return 'Art Style: ';
    case TagGroupId.Genre:
      return 'Genre: ';
    case TagGroupId.LoveInterest:
      return 'Love Interest: ';
    case TagGroupId.MainCharacter:
      return 'Main Character: ';
    default:
      return '';
  }
}
