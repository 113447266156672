import React from 'react';
import { Col, Form } from 'react-bootstrap';

export function StoryCreationCheckBox(props) {
  const { params, handleChangeCheckBox } = props;

  const limitV1 = Boolean(params.limitV1 && (params.limitV1 === 'true' || params.limitV1 === true));

  return (
    <Col md={2}>
      <Form.Check
        custom
        id="checkboxlimitV1"
        name="limitV1"
        type="checkbox"
        checked={limitV1}
        onChange={handleChangeCheckBox}
        label="Limit V1"
      />
    </Col>
  );
}
