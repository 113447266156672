import React, { Component } from 'react';
import {
  Button, Form, Modal, Spinner,
} from 'react-bootstrap';
import { doubleQuote } from '../../../../helpers/html';
import { api } from '../../../api';
import { getUserDisplayName } from '../../Users';

export class DeleteFolderShare extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      allFolders: false,
    };
  }

  deleteAction() {
    const {
      onHide,
      folderID,
      obj,
      onError,
    } = this.props;
    const { allFolders } = this.state;

    const stories = allFolders || null;
    this.setState({ showLoading: true });
    onHide();

    api.delete(`v1/folders/${folderID}/share/${obj.id}`, {
      params: {
        stories,
      },
    })
      .then(() => onHide())
      .catch((error) => onError(error.response.data.error));
  }

  render() {
    const { obj, onHide } = this.props;

    const { showLoading, allFolders } = this.state;
    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        className="zIndexModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Stop sharing with:
            <span>
              {doubleQuote}
              {getUserDisplayName(obj)}
              {doubleQuote}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="text-center">
              Do you really want to cancel sharing the folder?
            </p>
            <Form>
              <Form.Check
                custom
                type="checkbox"
                label="Stop sharing all episodes in the folder"
                defaultChecked={allFolders}
                onChange={() => { this.setState({ allFolders: !allFolders }); }}
              />
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {showLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
