import async from 'async';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert,
  Badge,
  Button,
  ButtonGroup,
  Container,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Nav,
  Tab,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddBook } from '../Books/AddBook';
import { Share } from '../Stories/Share';
import './News.scss';
import { NewsFrame } from './NewsFrame/NewsFrame';

export class News extends Component {
  constructor(props) {
    super(props);
    const { auth } = this.props;
    this.state = {
      loading: true,
      getInviteLinkModal: false,
      editBook: false,
      limits: [],
      groups: [],
      template: null,
      search: '',
      editShare: {},
      editShareActive: false,
      // eslint-disable-next-line react/no-unused-state
      pending: 0,
      // eslint-disable-next-line react/no-unused-state
      granted: 5,
      type: '',
      unreadCount: 0,
    };
    this.limit = 6;

    this.user = auth.getUser();
    this.mainContent = React.createRef();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    async.parallel({
      info: (callback) => {
        api.get('/v1/user/info')
          .then((res) => {
            callback(null, res.data.user);
          }).catch((error) => {
            callback(error, null);
          });
      },
      groups: (callback) => {
        api.get('/v1/groups/books')
          .then((res) => {
            callback(null, res.data.groups);
          }).catch((error) => {
            callback(error, null);
          });
      },
      limits: (callback) => {
        api.get('/v1/settings')
          .then((res) => {
            callback(null, res.data.settings.limits);
          }).catch((error) => {
            callback(error, null);
          });
      },
      invites: (callback) => {
        api.get('/v1/invites')
          .then((res) => {
            callback(null, res.data.invites);
          }).catch((error) => {
            callback(error, null);
          });
      },
      unreadCount: (callback) => {
        api.get('/v1/blog/countposts')
          .then((res) => {
            callback(null, res.data.unreadCount);
          }).catch((error) => {
            callback(error, null);
          });
      },
      recent: (callback) => {
        api.get('/v1/recentchapters', { params: { limit: 1 } })
          .then((res) => {
            callback(null, res.data.chapters);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (error, res) => {
      try {
        if (error) {
          if (error && error.response && error.response.data && error.response.data.error) {
            this.errorAlert(error.response.data.error);
          } else if (error && error.message) {
            this.errorAlert(error.message);
          }

          this.setState({
            loading: false,
          });
        } else {
          let pending = 0;
          let grantedCnt = 0;
          res.invites.forEach((obj) => {
            if (obj.granted === false) {
              pending += 1;
            }
            if (obj.granted === true) {
              grantedCnt += 1;
            }
          });
          const recent = res.recent ? res.recent[0] : null;
          this.setState({
            limits: res.limits,
            groups: res.groups,
            loading: false,
            // eslint-disable-next-line react/no-unused-state
            granted: grantedCnt,
            // eslint-disable-next-line react/no-unused-state
            pending,
            recent,
            unreadCount: res.unreadCount,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  };

  articleBadge = () => {
    const { unreadCount } = this.state;

    return (
      <>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Writer's Corner
        <span className="boxBadge">
          {(unreadCount || unreadCount > 0) && (
            <Badge pill variant="warning">
              {unreadCount || 0}
            </Badge>
          )}
        </span>
      </>
    );
  };

  pendingPlayersBadge = () => (
    <>
      Author Signups
    </>
  );

  actionShare = (obj) => {
    this.setState({
      editShare: obj,
      editShareActive: true,
    });
  };

  closeShare = () => {
    this.setState({
      editShare: {},
      editShareActive: false,
    });
    this.loadData();
  };

  changeGroup = (s, g) => {
    api.put(`/v1/books/${s}`, { groupId: g })
      .then(() => {
        this.loadData();
      })
      .catch((error) => {
        this.errorAlert(error.response.data.error);
      });
  };

  booksShare() {
    const { editShareActive, editShare } = this.state;

    return (
      <Share
        type="book"
        obj={editShare}
        show={editShareActive}
        onHide={this.closeShare}
        user={this.user}
      />
    );
  }

  renderRecent = () => {
    const { recent } = this.state;

    if (!recent) {
      return null;
    }
    return (
      <Link
        className="recentEpisodeLink"
        to={`/stories/${recent.id}/branches`}
      >
        <img
          alt=""
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAk1BMVEUOcqwkn94al9oEhNA3r+Qvp+ERkNdHcEwpi7cChdMDgcw0o9QCfMY3vv06wP4Nne8Glu0xufsttfoUovIZpvMzu/wDl/AQn/E+xf8msfkJmu/5+fr///38+/oeqvYlnNsvpN+Fw+Lu8/b6+voOk93u9Pb2+PkcoOKPyOYRn+p5veAEleVptd4pqegapuyj0ers8vVKmU/5AAAADXRSTlMN8fHv8fDxAAzx7F1ccR0tIgAAAUJJREFUOMt9kwuTgiAQgKmRMHv4SCIfR1lcno+6/v+vu11Q027wAwd39xtWHSGMuTMwRtiGOlbohhGXnmagLnGd097KyUFhP4MRdlb22IJ+Zrme5oYSQuiOcx6OBhR0BtjBDoRCMIY/Lo8+xfUOH3VZZ0U5EcJoTPj8yfOiNMkQhUX0T7gPxsIISZQMRBUIGRiY7oQkeVzenH9BQANtLSTJs72/rh2v7+wGozM6AXbNvgZu2rjndWWEpU1oQViiIGUFLfoer2vfQkkpjRDL8vxGdQ8J9bgXYpg9sin0ayrMaWEVT9BCXisTrbRwGBM38KmhbgItHKY0bVarPjCCQCrh60sIpZToMILwp4A13KKwhrDyLWjBT9MARhr4MHFNDbiuUUgDK0YIZgDB9Y7B0VY/ei5hW29txdvC6Wbw69uA4/8Hw9hIjjQpy7AAAAAASUVORK5CYII="
        />
        {`Jump to most recent episode: ${recent.book.title} - ${recent.title}`}
      </Link>
    );
  };

  render() {
    const { match, location, auth } = this.props;
    const {
      loading,
      search,
      limits,
      groups,
      copyText,
      template,
      getInviteLinkModal,
      original,
      editBook,
      type,
    } = this.state;

    let path = '/home';
    let pageName = 'Home';

    if (match && match.path && match.path !== '/') {
      path = match.path;
      if (match.path === '/mystories') {
        pageName = 'My Stories';
      }
    }

    const user = auth.getUser();

    const page = {
      header: {
        title: pageName,
        type: 'episodes',
        settings: (user.role === 'admin') && 'admin',
        contentRef: this.mainContent,
      },
      sidebar: {
        nav: [
          {
            title: 'Stories List',
            action: '',
            href: '/books',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Create Story',
            action: () => {
              this.setState({
                editBook: [],
              });
            },
            variant: 'primary',
            disabled: loading /* || this.user.role !== "admin" */,
          },
          {
            title: 'Backgrounds',
            action: '',
            href: '/backgrounds',
            variant: 'secondary',
            disabled: this.user.role !== 'admin' && !this.user.actions?.includes('backgrounds'),
            NotAdminPermissions: this.user.role !== 'admin' && !this.user.actions?.includes('backgrounds'),
          },
          {
            title: 'Character Art Library',
            action: '',
            href: '/character-art-library',
            variant: 'secondary',
            disabled: this.user.role !== 'admin' && !this.user.actions?.includes('characters'),
            NotAdminPermissions: this.user.role !== 'admin' && !this.user.actions?.includes('characters'),
          },
          ...this.user.role === 'admin'
            ? [{
              title: 'Audio library',
              action: '',
              href: '/audio-library',
              variant: 'secondary',
              disabled: this.user.role !== 'admin' && !this.user.actions?.includes('audioLibrary'),
              NotAdminPermissions: this.user.role !== 'admin' && !this.user.actions?.includes('audioLibrary'),
            }] : [],
          {
            title: 'Search',
            action: '',
            href: '/search',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Invite Writers',
            action: () => {
              this.setState({
                getInviteLinkModal: true,
              });
            },
            variant: 'success',
          },
          {
            title: this.pendingPlayersBadge(),
            variant: 'secondary',
            href: '/fan',
          },
          {
            title: this.articleBadge(),
            action: '',
            href: '/articles',
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Story Activity',
            action: '',
            href: '/activity',
            variant: 'secondary',
            disabled: this.user.role !== 'admin',
            NotAdminPermissions: this.user.role !== 'admin',
          },
          {
            title: 'Episode Creation',
            action: '',
            href: '/creation',
            variant: 'secondary',
            disabled: this.user.role !== 'admin',
            NotAdminPermissions: this.user.role !== 'admin',
          },
          {
            title: 'Story Performance',
            action: '',
            href: '/performance',
            variant: 'secondary',
          },
          {
            title: 'Documents',
            action: '',
            href: '/documents',
            variant: 'secondary',
          },
        ],
      },
    };

    const linkUrl = document.location.origin.replace('www.', '');

    const handleCopy = () => {
      const linkShare = document.getElementById('linkShare');
      linkShare.select();
      linkShare.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.setState({
        copyText: linkShare.value,
      }, () => {
        setTimeout(() => {
          this.setState({
            copyText: null,
          });
        }, 1000);
      });
    };

    const addBook = () => !!editBook && (
    <AddBook
      show
      limits={limits}
      user={this.user}
      onHide={() => {
        this.setState({
          template: null,
          editBook: null,
          type: '',
        });
      }}
      type={type}
      original={original}
      book={editBook}
      items={groups}
      template={template}
      update={() => {
        this.setState({
          editBook: null,
          type: '',
        }, () => {
          this.loadData();
        });
      }}
    />
    );

    const values = queryString.parse(location.search);

    const handleCreatePromptClick = (val, promptType) => {
      this.setState({
        type: promptType,
        template: val,
        editBook: [],
      });
    };
    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
        >
          <Container
            fluid
            className="Recent-Container"
          >
            {this.renderRecent()}
          </Container>
          <Container
            fluid
            className="NewsItem-Container"
          >
            <Tab.Container defaultActiveKey={path}>
              <Nav className="nav nav-tabs">
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/home"
                    eventKey="/home"
                  >
                    Home
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    to="/mystories"
                    eventKey="/mystories"
                  >
                    My Stories
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="/home">
                  <div style={{ paddingTop: '2em' }} />
                  <NewsFrame
                    {...this.props}
                    title="Events"
                    type="prompts"
                    limit={8}
                    update={(promptId) => handleCreatePromptClick(promptId, 'prompts')}
                    contentRef={this.mainContent}
                  />

                  <NewsFrame
                    {...this.props}
                    title="Trending prompts"
                    type="trending"
                    limit={4}
                    update={(promptId) => handleCreatePromptClick(promptId, 'trending')}
                    contentRef={this.mainContent}
                  />

                </Tab.Pane>
                <Tab.Pane eventKey="/mystories">
                  {
                    (!loading && groups && groups.length > 0)
                    && (
                    <>
                      <div className="NewsItem-main">
                        <Container
                          fluid
                          className="NewsItem-Container"
                          style={{ paddingBottom: 0 }}
                        >
                          <Form
                            className="my-3"
                            onSubmit={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Story Title"
                              value={search}
                              onChange={(e) => {
                                const val = e.target.value;
                                this.setState(() => ({ search: val }));
                              }}
                            />
                          </Form>
                          <div className="text-right mt-3">
                            <ButtonGroup size="sm">
                              <Button
                                variant={
                                  !values
                                  || !values.story_list
                                  || (
                                    values
                                    && values.story_list
                                    && values.story_list === 'all'
                                  )
                                    ? 'primary' : 'secondary'
}
                                as={Link}
                                to={`${path}?story_list=all`}
                              >
                                All
                              </Button>
                              <Button
                                variant={values?.story_list === 'written' ? 'primary' : 'secondary'}
                                to={`${path}?story_list=written`}
                                as={Link}
                              >
                                Written Stories
                              </Button>
                              <Button
                                variant={values?.story_list === 'prompts' ? 'primary' : 'secondary'}
                                to={`${path}?story_list=prompts`}
                                as={Link}
                              >
                                Prompts Stories
                              </Button>
                              <Button
                                variant={values?.story_list === 'exclusive' ? 'primary' : 'secondary'}
                                to={`${path}?story_list=exclusive`}
                                as={Link}
                              >
                                Exclusive templates
                              </Button>
                            </ButtonGroup>
                          </div>

                        </Container>
                      </div>
                      {groups.map((group, i) => (
                        <NewsFrame
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          {...this.props}
                          title={group.title ? `My ${group.title}` : ''}
                          type="books"
                          obj={group}
                          share={this.actionShare}
                          editBook={(obj) => {
                            this.setState({
                              editBook: obj,
                            });
                          }}
                          changeGroup={this.changeGroup}
                          groups={groups}
                          search={search}
                          limit={this.limit}
                          contentRef={this.mainContent}
                        />
                      ))}
                    </>
                    )
                  }
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>
        </PageWrapper>

        {this.booksShare()}
        {addBook()}

        <Modal
          show={getInviteLinkModal}
          onHide={() => {
            this.setState({
              getInviteLinkModal: false,
            });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Invite Writers</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {copyText
            && (
            <Alert variant="success">
              copy to clipboard
            </Alert>
            )}

            <InputGroup className="mb-3">
              <FormControl
                id="linkShare"
                aria-label="Share"
                defaultValue={`${linkUrl}/invite?token=${btoa(this.user.id)}`}
              />
              <InputGroup.Append>
                <Button
                  variant="dark"
                  onClick={handleCopy}
                >
                  Copy
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
