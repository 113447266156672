import { isEmpty } from 'lodash-es';
import shortUUID from 'short-uuid';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import {
  CheckOperator,
  MemoryArrayLink,
  MemoryCheckValueType,
  MemoryDTO,
  MemorySingleLink,
  MemoryType,
  StepCheck,
  StepCheckOperatorType,
  StepCheckSwitch,
} from '../../Book/MemoryBank/memoryBankTypes';
import { EpisodeTextImportCommandKey, TEpisodeTextImportCommands } from '../types/episodeTextImportCommandTypes';
import { EpisodeTextImportCrossCommands } from '../types/EpisodeTextImportCrossCommands';
import {
  EpisodeTextColumnKey,
  EpisodeTextEntityType,
  EpisodeTextImportStepValidationConfig,
} from '../types/episodeTextImportTypes';
import { EpisodeTextImportHelper } from './EpisodeTextImportHelper';
import { EpisodeTextImportNode } from './EpisodeTextImportNode';

export class EpisodeTextImportStep {
  public id: string;
  public type: EpisodeTextEntityType | undefined = undefined;
  public check: StepCheck | undefined = undefined;
  public switch: StepCheckSwitch[] | undefined = undefined;
  public speakerName: string;
  public speakerText: string;
  public description: string;
  public errors: string[] = [];
  public commands: TEpisodeTextImportCommands = new Map();

  constructor(data: string[], stepType: EpisodeTextEntityType) {
    this.id = shortUUID.generate();
    this.speakerName = data[EpisodeTextColumnKey.NodeOrSpeaker]?.trim() ?? '';
    this.speakerText = data[EpisodeTextColumnKey.Text]?.trim() ?? '';
    this.description = data[EpisodeTextColumnKey.Description]?.trim() ?? '';
    this.type = stepType;
    this.parseCommands();
  }

  private parseCommands() {
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, this.speakerName, new Map());
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Text, this.speakerText, this.commands);
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Description, this.description, this.commands);
  }

  public validate(config?: EpisodeTextImportStepValidationConfig): string[] {
    const nodesInImport = config?.nodesInImport ?? [];
    const maxLengthName = config?.maxLengthName ?? 100;
    const maxLengthText = config?.maxLengthText ?? 120;
    const nodesInEpisode = config?.nodesInEpisode ?? [];
    const characterNamesInEpisode = config?.characterNamesInBook ?? [];
    const memoryNames = config?.memoriesInBook ?? [];

    const speakerNamePattern = /^[0-9A-z\-_]+$/;
    const speakerName = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
    const speakerText = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerText);

    this.errors = [];

    this._validateCrossCommands();
    this._validateGotoCommand(nodesInImport, nodesInEpisode);
    this._validateChoiceCommand(nodesInImport);
    this._validateAnswerCommand();
    this._validatePremiumAnswerCommand();
    this._validateChatCommand();
    this._validateRememberCommand(memoryNames);
    this._validateCheckCommand(memoryNames, nodesInImport, nodesInEpisode);
    this._validateInvalidCommands();

    /* Validate OTHERS */
    if (this.type === EpisodeTextEntityType.EmptyStep) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Row should not be empty`);
    }

    if (speakerName.length > maxLengthName) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Character alias exceeds the limit of ${maxLengthName} characters`);
    }

    if (!isEmpty(speakerName) && !speakerNamePattern.test(speakerName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Invalid character alias. Allowed characters: A-Z, a-z, 0-9, _, -`);
    }

    if (speakerText.length > maxLengthText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Speaker text exceeds the limit of ${maxLengthText} characters`);
    }

    if ((this.type === EpisodeTextEntityType.Dialogue || this.type === EpisodeTextEntityType.Choice)
      && !EpisodeTextImportHelper.isNameExists(this.speakerName, characterNamesInEpisode)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Character alias does not exist in the episode`);
    }

    return this.errors;
  }

  private _validateCrossCommands() {
    const usedCommands = [...this.commands.keys()];
    this.commands.forEach((_, key) => {
      const canBeUsedWithCommands = EpisodeTextImportCrossCommands.commands[key];
      const isError = usedCommands.some((usedCommand) => usedCommand !== key && !canBeUsedWithCommands.includes(usedCommand));
      if (isError) {
        const usedCommandsText = usedCommands.filter((usedCommand) => usedCommand !== key).map((usedCommand) => `#${usedCommand.toString()}`).join(', ');
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #${key.toString()} command cannot be used with ${usedCommandsText}`);
      }

      const canBeInEntityType = EpisodeTextImportCrossCommands.entityType[key];
      const isErrorEntityType = !canBeInEntityType.includes(this.type!);
      if (isErrorEntityType) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #${key.toString()} command cannot be used in ${this.type} step type.`);
      }
    });

    const usedPremiumAnswerCommand = this.commands.get(EpisodeTextImportCommandKey.PremiumAnswer);
    const usedBonusContentCommand = this.commands.get(EpisodeTextImportCommandKey.BonusContent);

    for (const columnKey of usedBonusContentCommand?.keys() ?? []) {
      if (!usedPremiumAnswerCommand) {
        this.errors.push(`[${columnKey}] #BONUS command cannot be used without #PREMIUM: command`);
      }
    }
  }

  private _validateGotoCommand(nodesInImport: EpisodeTextImportNode[], nodesInEpisode: Branch[]) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const gotoCommandInColumnKey = commands.goto.keys().next().value;
    const gotoCommandValue = commands.goto.values().next().value?.[2] ?? '';

    if (!gotoCommandInColumnKey) {
      return;
    }

    const speakerName = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
    if (!isEmpty(speakerName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #GOTO: Character alias cannot be used with this command`);
    }

    const hasGotoCommandInSpeaker = commands.goto.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasGotoCommandInDescription = commands.goto.has(EpisodeTextColumnKey.Description);

    if (hasGotoCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #GOTO: cannot be used in this column`);
    }

    if (hasGotoCommandInDescription && this.type !== EpisodeTextEntityType.Answer) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command in description should be in ANSWER step type`);
    }

    if (!hasGotoCommandInDescription && this.type === EpisodeTextEntityType.Answer) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command missing. It should be in ANSWER step type, or make sure you wrote the command correctly`);
    }

    if (isEmpty(gotoCommandValue)) {
      this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The node name should be provided`);
    }

    const existingNodeNames = nodesInImport.map((node) => node.title);
    if (!EpisodeTextImportHelper.isNameExists(gotoCommandValue, [...existingNodeNames, ...nodesInEpisode.map((node) => node.title)])) {
      this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The name does not exist in the importing nodes or the existing episode.`);
    }

    const currentNode = nodesInImport.find((node) => node.steps.some((step) => step.id === this.id));
    if (currentNode) {
      const currentStepIndex = currentNode.steps.findIndex((step) => step.id === this.id);
      const prevStep = currentNode.steps[currentStepIndex - 1];
      const nextStep = currentNode.steps[currentStepIndex + 1];
      if (this.type !== EpisodeTextEntityType.Answer) {
        if (!prevStep) {
          this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The command must not be on the first step of the node`);
        }
        if (nextStep) {
          this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The command must be on the last step of the node`);
        }
      }

      const hasCheckStep = currentNode.steps.some((step) => step.type === EpisodeTextEntityType.Check);
      if (hasCheckStep) {
        this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The command cannot be used with CHECK step type`);
      }
      const hasChoice = currentNode.steps.some((step) => step.type === EpisodeTextEntityType.Choice);
      if (hasChoice && gotoCommandInColumnKey !== EpisodeTextColumnKey.Description) {
        this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The command cannot be used with CHOICE step type`);
      }
      const hasAnswer = currentNode.steps.some((step) => step.type === EpisodeTextEntityType.Answer);
      if (hasAnswer && gotoCommandInColumnKey !== EpisodeTextColumnKey.Description) {
        this.errors.push(`[${gotoCommandInColumnKey}] #GOTO: The command cannot be used with ANSWER step type`);
      }
    }
  }

  private _validateChoiceCommand(nodesInImport: EpisodeTextImportNode[]) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const speakerNameCleared = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
    const speakerTextCleared = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerText);

    const columnKey = commands.choice.keys().next().value;

    if (!columnKey) {
      return;
    }

    if (columnKey === EpisodeTextColumnKey.NodeOrSpeaker) {
      this.errors.push(`[${columnKey}] #CHOICE: command cannot be used in this cell`);
      return;
    }

    const currentNode = nodesInImport.find((node) => node.steps.some((step) => step.id === this.id));
    if (currentNode) {
      const currentStepIndex = currentNode.steps.findIndex((step) => step.id === this.id);
      const prevStep = currentNode.steps[currentStepIndex - 1];
      const nextStep = currentNode.steps[currentStepIndex + 1];
      const answersCount = currentNode.steps.filter((step) => step.type === EpisodeTextEntityType.Answer).length;

      if (prevStep) {
        this.errors.push(`[${columnKey}] #CHOICE: Choice command should be the first step in the node`);
        return;
      }

      if (nextStep?.type === EpisodeTextEntityType.Answer) {
        if (answersCount !== currentNode.steps.length - 1) {
          this.errors.push(`[${columnKey}] #CHOICE: only ANSWER steps should be present`);
        }
        if (answersCount > 3) {
          this.errors.push(`[${columnKey}] #CHOICE: Only THREE answers should be present`);
        }
      } else {
        if (!nextStep) {
          this.errors.push(`[${columnKey}] #CHOICE: ] At least ONE answer step should be present`);
          return;
        }
        this.errors.push(`[${columnKey}] #CHOICE: Only ANSWER steps should be present`);
        return;
      }
    }

    if (isEmpty(speakerNameCleared)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Choice step should have a character alias`);
    }
    if (speakerTextCleared.length > 40) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Choice text exceeds the limit of 40 characters`);
    }
  }

  private _validateAnswerCommand() {
    const isAnswerStepType = this.type === EpisodeTextEntityType.Answer;

    if (!isAnswerStepType) {
      return;
    }

    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const speakerNameCleared = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
    const hasGotoCommand = commands.goto.size > 0;

    if (!isEmpty(speakerNameCleared)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Answer step should not have a character alias`);
    }

    if (!hasGotoCommand) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #GOTO: command should be used`);
    }
  }

  private _validatePremiumAnswerCommand() {
    if (this.type !== EpisodeTextEntityType.Answer) {
      return;
    }
    if (!this.commands.has(EpisodeTextImportCommandKey.PremiumAnswer)) {
      return;
    }

    const premiumAnswerCommand = this.commands.get(EpisodeTextImportCommandKey.PremiumAnswer);
    const hasPremiumAnswerCommandInSpeaker = premiumAnswerCommand?.get(EpisodeTextColumnKey.NodeOrSpeaker);

    const speakerName = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
    if (!isEmpty(speakerName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Answer step should not have a character alias`);
      return;
    }

    const speakerText = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerText);
    if (speakerText.length > 40) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Answer text exceeds the limit of 40 characters`);
      return;
    }

    if (hasPremiumAnswerCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #PREMIUM: command cannot be used in this cell`);
      return;
    }

    const premiumAnswerValue = premiumAnswerCommand?.values().next().value?.[2] ?? '';
    if (isEmpty(premiumAnswerValue)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #PREMIUM: value should be provided`);
      return;
    }
    if (Number.isNaN(Number(premiumAnswerValue))) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] #PREMIUM: value should be a number. Value: ${premiumAnswerValue}`);
    }
  }

  private _validateChatCommand() {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);

    const hasChatCommandInSpeaker = commands.chat.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasChatCommandInText = commands.chat.has(EpisodeTextColumnKey.Text);
    const hasChatCommandInDescription = commands.chat.has(EpisodeTextColumnKey.Description);

    if (hasChatCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #CHAT: command should be in the DESCRIPTION column`);
    }
    if (hasChatCommandInText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #CHAT: command should be in the DESCRIPTION column`);
    }

    if (hasChatCommandInDescription
      && this.type !== EpisodeTextEntityType.Chat) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #CHAT: command cannot be used in this step`);
    }
  }

  private _validateRememberCommand(memoryBank: MemoryDTO[]) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const hasRememberCommandInSpeaker = commands.remember.has(EpisodeTextColumnKey.NodeOrSpeaker);
    const hasRememberCommandInText = commands.remember.has(EpisodeTextColumnKey.Text);
    const hasRememberCommandInDescription = commands.remember.has(EpisodeTextColumnKey.Description);

    if (hasRememberCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #REMEMBER: command cannot be used in this cell`);
      return;
    }
    if (hasRememberCommandInText || hasRememberCommandInDescription) {
      const memoryName = commands.remember.values().next().value?.[2]?.toLocaleLowerCase().trim() ?? '';
      const operator = commands.remember.values().next().value?.[3]?.toLocaleLowerCase().trim() ?? '';
      const value = commands.remember.values().next().value?.[4]?.toLocaleLowerCase().trim() ?? '';

      const columnKey = commands.remember.keys().next().value;

      const speakerName = EpisodeTextImportHelper.removeAllCommandsFromString(this.speakerName);
      if (!isEmpty(speakerName)) {
        this.errors.push(`[${columnKey}] #REMEMBER: Character alias cannot be used with this command`);
        return;
      }

      if (isEmpty(memoryName)) {
        this.errors.push(`[${columnKey}] #REMEMBER: Memory name does not recognized. Please check the command syntax`);
        return;
      }
      if (isEmpty(operator)) {
        this.errors.push(`[${columnKey}] #REMEMBER: Operator should be provided`);
        return;
      }
      if (isEmpty(value)) {
        this.errors.push(`[${columnKey}] #REMEMBER: Value should be provided`);
        return;
      }

      const memory = memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === memoryName);
      if (!memory) {
        this.errors.push(`[${columnKey}] #REMEMBER: Memory name does not exist in Memory Bank: ${memoryName}`);
        return;
      }

      const isNumber = memory.type === MemoryType.Number;
      const isBoolean = memory.type === MemoryType.Boolean;

      if (isNumber && Number.isNaN(Number(value))) {
        this.errors.push(`[${columnKey}] #REMEMBER: Value should be a number. Value: ${value}`);
        return;
      }

      if (!isNumber && ['+', '-'].includes(operator)) {
        this.errors.push(`[${columnKey}] #REMEMBER: Operator should be '=' for non-number memory. Operator: ${operator}`);
        return;
      }
      if (isBoolean && !['yes', 'no'].includes(value.toLocaleLowerCase().trim())) {
        this.errors.push(`[${columnKey}] #REMEMBER: Value should be 'yes' or 'no' for boolean memory. Value: ${value}`);
      }
    }
  }

  private _validateCheckCommand(memoryBank: MemoryDTO[], nodesInImport: EpisodeTextImportNode[], nodesInEpisode: Branch[]) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const hasCheckCommandInSpeaker = commands.check.has(EpisodeTextColumnKey.NodeOrSpeaker);

    if (hasCheckCommandInSpeaker) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #CHECK: command cannot be used in this cell`);
      return;
    }
    const columnKey = commands.check.keys().next().value;

    if (!columnKey) {
      return;
    }

    const currentNode = nodesInImport.find((node) => node.steps.some((step) => step.id === this.id));
    if (currentNode) {
      const currentStepIndex = currentNode.steps.findIndex((step) => step.id === this.id);
      const prevStep = currentNode.steps[currentStepIndex - 1];
      const nextStep = currentNode.steps[currentStepIndex + 1];
      if (prevStep) {
        this.errors.push(`[${EpisodeTextColumnKey.Text}] #CHECK: Check command should be the first step in the node`);
        return;
      }
      if (nextStep) {
        this.errors.push(`[${EpisodeTextColumnKey.Text}] #CHECK: The “check” step type must be the only type in the node`);
        return;
      }
    }

    const checkEntityType = EpisodeTextImportHelper.getCheckEntityTypes(this.commands);

    if (checkEntityType === 'none') {
      this.errors.push(`[${columnKey}] #CHECK: Error in operation syntax. Please check the command syntax`);
      return;
    }

    const nodeNamesInImport = nodesInImport.map((node) => node.title);
    const nodeNames = [...nodeNamesInImport, ...nodesInEpisode.map((node) => node.title)];

    if (checkEntityType === 'single') {
      const {
        singleCheckTypeMemoryName,
        singleCheckTypeOperator,
        singleCheckTypeTextOperator,
        singleCheckTypeValue,
        singleCheckTypeTrueGoto,
        singleCheckTypeFalseGoto,
      } = EpisodeTextImportHelper.getSingleCheckCommandValues(this.commands);

      if (isEmpty(singleCheckTypeMemoryName)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in memory name. Please check the command syntax`);
        return;
      }

      const isEmptyOperator = isEmpty(singleCheckTypeOperator) && isEmpty(singleCheckTypeTextOperator);

      if (isEmptyOperator) {
        this.errors.push(`[${columnKey}] #CHECK: Error in operator. Please check the command syntax`);
        return;
      }

      if (isEmpty(singleCheckTypeValue)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in value. Please check the command syntax`);
        return;
      }

      if (isEmpty(singleCheckTypeTrueGoto)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in True goto. Please check the command syntax`);
        return;
      }

      if (!EpisodeTextImportHelper.isNameExists(singleCheckTypeTrueGoto, nodeNames)) {
        this.errors.push(`[${columnKey}] #CHECK: True goto node does not exist. Node: ${singleCheckTypeTrueGoto}`);
        return;
      }

      if (isEmpty(singleCheckTypeFalseGoto)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in false goto. Please check the command syntax`);
        return;
      }

      if (!EpisodeTextImportHelper.isNameExists(singleCheckTypeFalseGoto, nodeNames)) {
        this.errors.push(`[${columnKey}] #CHECK: False goto node does not exist. Node: ${singleCheckTypeFalseGoto}`);
        return;
      }

      const memory = memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === singleCheckTypeMemoryName);
      if (!memory) {
        this.errors.push(`[${columnKey}] #CHECK: Memory name does not exist in Memory Bank: ${singleCheckTypeMemoryName}`);
        return;
      }

      const operators = [
        StepCheckOperatorType.Equal,
        StepCheckOperatorType.NotEqual,
        StepCheckOperatorType.Greater,
        StepCheckOperatorType.AtLeast,
        StepCheckOperatorType.Less,
        StepCheckOperatorType.AtMost,
      ];

      const allowedNonNumericOperators = [StepCheckOperatorType.Equal, StepCheckOperatorType.NotEqual];

      const operator = operators.find((operatorKey) => {
        const checkOperator = CheckOperator[operatorKey];
        return checkOperator.short === singleCheckTypeOperator || checkOperator.title.toLocaleLowerCase() === singleCheckTypeTextOperator;
      });

      if (!operator) {
        this.errors.push(`[${columnKey}] #CHECK: Operator is not recognized. Please check the command syntax`);
        return;
      }

      if (memory.type !== MemoryType.Number && !allowedNonNumericOperators.includes(operator)) {
        this.errors.push(`[${columnKey}] #CHECK: Operator should be '=' or '<>' for boolean memory. Operator: ${singleCheckTypeOperator ?? singleCheckTypeTextOperator}`);
        return;
      }

      const isCompereMemory = memory.type === MemoryType.Number && Number.isNaN(Number(singleCheckTypeValue));

      if (memory.type === MemoryType.Number && isCompereMemory) {
        const compareMemory = memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === singleCheckTypeValue);
        if (!compareMemory) {
          this.errors.push(`[${columnKey}] #CHECK: Compare memory name does not exist in Memory Bank: ${singleCheckTypeValue}`);
          return;
        }
        if (compareMemory.type !== MemoryType.Number) {
          this.errors.push(`[${columnKey}] #CHECK: Compare memory should be a numeric type. Memory: ${singleCheckTypeValue}`);
          return;
        }
        if (memory.id === compareMemory.id) {
          this.errors.push(`[${columnKey}] #CHECK: Memory and compare memory should be different.`);
          return;
        }
      }

      if (memory.type === MemoryType.Boolean) {
        if (!['yes', 'no'].includes(singleCheckTypeValue)) {
          this.errors.push(`[${columnKey}] #CHECK: Value should be 'yes' or 'no' for boolean memory. Value: ${singleCheckTypeValue}`);
          return;
        }
      }

      if (isCompereMemory) {
        const value: MemorySingleLink = {
          type: MemoryCheckValueType.Variable,
          variableId: memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === singleCheckTypeValue)?.id.toString() ?? '',
        };

        this.check = {
          variableId: memory.id,
          operator,
          value,
        };
      } else {
        this.check = {
          variableId: memory.id,
          operator,
          value: memory.type === MemoryType.Boolean
            ? singleCheckTypeValue.toLocaleLowerCase() === 'yes'
            : singleCheckTypeValue,
        };
      }
      this.switch = [
        {
          value: true,
          gotoBranchId: nodesInEpisode.find((node) => node.title.toLocaleLowerCase().trim() === singleCheckTypeTrueGoto)?.id ?? -1,
        },
        {
          value: false,
          gotoBranchId: nodesInEpisode.find((node) => node.title.toLocaleLowerCase().trim() === singleCheckTypeFalseGoto)?.id ?? -1,
        },
      ];
    }

    if (checkEntityType === 'multi') {
      const {
        multiCheckTypeFuncName,
        multiCheckTypeGotoTextValues,
        multiCheckTypeGotoValues,
        multiCheckTypeMemoryNames,
        multiCheckTypeMemoryTextNames,
      } = EpisodeTextImportHelper.getMultiCheckCommandValues(this.commands);

      if (isEmpty(multiCheckTypeFuncName)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in function name. Please check the command syntax`);
        return;
      }

      if (isEmpty(multiCheckTypeMemoryTextNames) || isEmpty(multiCheckTypeMemoryNames)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in memory names. Please check the command syntax`);
        return;
      }

      if (isEmpty(multiCheckTypeGotoTextValues) || isEmpty(multiCheckTypeGotoValues)) {
        this.errors.push(`[${columnKey}] #CHECK: Error in goto values. Please check the command syntax`);
        return;
      }

      for (let i = 0; i < multiCheckTypeGotoValues.length; i++) {
        const gotoValue = multiCheckTypeGotoValues[i];
        if (!EpisodeTextImportHelper.isNameExists(gotoValue, nodeNames)) {
          this.errors.push(`[${columnKey}] #CHECK: Goto node does not exist. Node: ${gotoValue}`);
          return;
        }
      }

      if (multiCheckTypeMemoryNames.length < 1) {
        this.errors.push(`[${columnKey}] #CHECK: Memory names should be 2 or more`);
        return;
      }

      if (multiCheckTypeGotoValues.length < 2) {
        this.errors.push(`[${columnKey}] #CHECK: Goto values should be 2 or more`);
        return;
      }

      if (multiCheckTypeMemoryNames.length !== multiCheckTypeGotoValues.length) {
        this.errors.push(`[${columnKey}] #CHECK: Memory and Goto values should be equal in count`);
        return;
      }

      for (let i = 0; i < multiCheckTypeMemoryNames.length; i++) {
        const memoryName = multiCheckTypeMemoryNames[i];
        const memory = memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === memoryName);
        if (!memory) {
          this.errors.push(`[${columnKey}] #CHECK: Memory name does not exist in Memory Bank: ${memoryName}`);
          return;
        }
        if (memory.type !== MemoryType.Number) {
          this.errors.push(`[${columnKey}] #CHECK: Memory should be a numeric type. Memory: ${memoryName}`);
          return;
        }
      }

      const operators = [
        StepCheckOperatorType.Min,
        StepCheckOperatorType.Max,
      ];

      const operator = operators.find((operatorKey) => {
        const checkOperator = CheckOperator[operatorKey];
        return checkOperator.short.toLocaleLowerCase().trim() === multiCheckTypeFuncName
          || checkOperator.title.toLocaleLowerCase().trim() === multiCheckTypeFuncName;
      });

      if (!operator) {
        this.errors.push(`[${columnKey}] #CHECK: Operator is not recognized. Please check the command syntax`);
        return;
      }

      const validateIds = multiCheckTypeMemoryNames.map((memoryName: string) => memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === memoryName)?.id.toString() ?? '');

      const value: MemoryArrayLink = {
        type: MemoryCheckValueType.VariableArray,
        variableId: validateIds,
      };

      this.check = {
        variableId: -1,
        operator,
        value,
      };

      this.switch = multiCheckTypeMemoryNames.map((memoryName: string, index: number) => ({
        value: memoryBank.find((memoryItem) => memoryItem.name.toLocaleLowerCase().trim() === memoryName)?.id.toString() ?? '',
        gotoBranchId: nodesInEpisode.find((node) => node.title.toLocaleLowerCase().trim() === multiCheckTypeGotoValues?.[index])?.id ?? -1,
      }));
    }
  }

  private _validateInvalidCommands() {
    const invalidCommandInTitle = EpisodeTextImportHelper.getInvalidValidCommand(this.speakerName);
    if (invalidCommandInTitle) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Invalid command: ${invalidCommandInTitle}`);
    }
    const invalidCommandInText = EpisodeTextImportHelper.getInvalidValidCommand(this.speakerText);
    if (invalidCommandInText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Invalid command: ${invalidCommandInText}`);
    }
    const invalidCommandInDescription = EpisodeTextImportHelper.getInvalidValidCommand(this.description);
    if (invalidCommandInDescription) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] Invalid command: ${invalidCommandInDescription}`);
    }
  }
}
