import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import { DeleteGenre } from './DeleteGenre';
import styles from './Genres.css';

const cs = classNames.bind(styles);

export class GenresTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const modalDeleteClose = () => {
      const { update } = this.props;

      this.setState({ modalDeleteShow: false });
      update();
    };

    const deleteRow = () => {
      const { modalDeleteShow } = this.state;

      if (modalDeleteShow === true) {
        const { obj } = this.props;
        return (
          <DeleteGenre
            show={modalDeleteShow}
            onHide={modalDeleteClose}
            obj={obj}
          />
        );
      }
      return null;
    };

    const {
      obj: obj1,
      actionEditGenre,
    } = this.props;

    return (
      <tr>
        <td>
          {obj1.title}
        </td>
        <td>
          {obj1.value}
        </td>
        <td className={cs('text-right')}>
          <Button
            onClick={() => actionEditGenre(obj1)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEdit} className="btnImg" alt="Edit" />
          </Button>
          <Button
            onClick={() => this.setState({ modalDeleteShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
          {deleteRow()}

        </td>
      </tr>
    );
  }
}
