import async from 'async';
import React, { Component } from 'react';
import {
  Button, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { AvatarHelper } from '../../ui/AvatarTool/AvatarHelper';

export class BookCoverGenerate extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      characters: [],
      expression: [],
      backgrounds: [],
      loading: false,
    };
    this.helper = new AvatarHelper();
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
      // eslint-disable-next-line react/no-unused-state
      edit: false,
    });
    async.parallel({
      backgrounds: (callback) => {
        api.get('/v1/backgrounds')
          .then((res) => {
            const { user } = this.props;
            const images = user.role === 'admin'
              ? res.data.images
              : res.data.images.filter(
                (bg) => bg.policyId === 1 || (bg.policyId === 2 && bg.authorId === user.id),
              );
            callback(null, images);
          }).catch((error) => {
            callback(error, null);
          });
      },
      expressions: (callback) => {
        api.get('/v1/characters/expressions')
          .then((res) => {
            callback(null, res.data.expressions);
          }).catch((error) => {
            callback(error, null);
          });
      },
      characters: (callback) => {
        const { book } = this.props;
        api.get(`/v1/books/${book.id}/characters`)
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      if (!err) {
        const chars = res.characters.characters.map((el) => {
          if (el.properties) {
            // eslint-disable-next-line no-param-reassign
            el.avatar = { properties: el.properties, imageName: el.imageName };
            // eslint-disable-next-line no-param-reassign
            el.avatar.alias = el.imageAlias;
            // eslint-disable-next-line no-param-reassign
            const { book } = this.props;
            // eslint-disable-next-line no-param-reassign
            el.avatar.book_uuid = book.uuid;
          }
          return el;
        });
        const defExp = res.expressions.find((ex) => ex.def === true);

        this.setState({
          characters: chars,
          expression: res.expressions,
          backgrounds: res.backgrounds,
          expressionId: defExp ? defExp.id : '',
          loading: false,
        });
      }
    });
  };

  // eslint-disable-next-line react/sort-comp
  listCharacters() {
    const {
      coverCharacterId,
      characters,
    } = this.state;

    if (characters.length > 0) {
      const counts = {};
      characters.forEach((obj) => {
        counts[obj.name] = (counts[obj.name] || 0) + 1;
      });

      const res = [];
      if (!coverCharacterId) {
        res.push(<option value="" key="def">Select character</option>);
      }
      res.push(...characters.map((object, i) => !object.isPlayer && (
      <option
        value={object.id}
        /* eslint-disable-next-line react/no-array-index-key */
        key={i}
      >
        {`${object.name}${counts[object.name] > 1 ? ` (${object.alias})` : ''}`}
      </option>
      )));
      return res;
    }
    return (
      <option value="" disabled>No characters</option>
    );
  }

  characterExpressions() {
    const { expression } = this.state;

    return expression.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <option value={object.id} key={i}>
        {object.title}
      </option>
    ));
  }

  sceneOptions() {
    const { backgrounds } = this.state;
    if (backgrounds.length > 0) {
      const res = [<option value="" key="def">Select background</option>];
      res.push(...backgrounds.map((object, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <option value={object.id} key={i}>
          {object.label}
        </option>
      )));
      return res;
    }
    return <option disabled value="">No Backgrounds</option>;
  }

  propsCanvas = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.characterId || this.state.characters.length < 1) {
      return null;
    }
    // eslint-disable-next-line max-len,react/destructuring-assignment
    const character = this.state.characters.find((obj) => Number(obj.id) === Number(this.state.characterId));
    const characterProperties = !character
      || !character.properties
      || character.properties.length < 0
      ? null
      : { ...character.properties };
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.expressionId && characterProperties) {
      // eslint-disable-next-line max-len,react/destructuring-assignment
      const expression = this.state.expression.find((obj) => Number(obj.id) === Number(this.state.expressionId));
      if (expression.value) {
        characterProperties.expression = expression.value;
      }
    }
    return characterProperties;
  };

  hasExpressions = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.characterId || this.state.characters.length < 1) {
      return false;
    }
    // eslint-disable-next-line max-len,react/destructuring-assignment
    const character = this.state.characters.find((obj) => Number(obj.id) === Number(this.state.characterId));
    if (!character || !character.properties || character.properties.type === 'custom') {
      return false;
    }
    return true;
  };

  bgImage = () => {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.backgroundId) {
      return null;
    }
    // eslint-disable-next-line max-len,react/destructuring-assignment
    const background = this.state.backgrounds.find((a) => Number(a.id) === Number(this.state.backgroundId));
    return `${background.imageUrl}?bc=${Date.now()}`;
  };

  /// /
  updateData = () => {
    const properties = this.propsCanvas();
    if (!properties) {
      return;
    }
    if (properties.type === 'custom') {
      // eslint-disable-next-line react/destructuring-assignment
      if (!this.state.characterId || this.state.characters.length < 1) {
        return;
      }
      // eslint-disable-next-line max-len,react/destructuring-assignment
      const character = this.state.characters.find((obj) => Number(obj.id) === Number(this.state.characterId));
      if (!character || !character.imageUrl) {
        return;
      }
      this.drawCustom(character.imageUrl);
      return;
    }

    const data = { properties };
    this.setState({
      loading: true,
    });
    api.post('/v1/avatar/prepare', data)
      .then((res) => {
        this.setState({
          loading: false,
          // eslint-disable-next-line react/no-unused-state
          data: res.data,
        });
        window.requestAnimationFrame(() => this.draw(res.data));
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  done(canvas) {
    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      blob.name = 'cover.png';
      // eslint-disable-next-line no-param-reassign
      blob.lastModifiedDate = new Date();
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onDone(blob, canvas.width, canvas.height);
    }, 'image/png');
  }

  postDraw(charCanvas) {
    const bgImg = this.bgImage();
    this.loadImage(bgImg).then((img) => {
      if (img) {
        const canvas = document.createElement('canvas');
        canvas.width = charCanvas.width;
        canvas.height = charCanvas.height;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const scale = Math.min(charCanvas.width / img.width, charCanvas.height / img.height);
        const x = (charCanvas.width / 2) - (img.width / 2) * scale;
        const y = (charCanvas.height / 2) - (img.height / 2) * scale;
        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
        ctx.drawImage(charCanvas, 0, 0);
        this.done(canvas);
      } else {
        this.done(charCanvas);
      }
    });
  }

  draw(res) {
    const charCanvas = document.createElement('canvas');
    this.helper.draw(charCanvas, res, () => {
      this.postDraw(charCanvas);
    });
  }

  drawCustom(imageUrl) {
    this.loadImage(imageUrl).then((characterImage) => {
      const charCanvas = document.createElement('canvas');
      charCanvas.width = 843;
      charCanvas.height = 1200;
      const canvasContext = charCanvas.getContext('2d');
      canvasContext.drawImage(characterImage, 0, 0);
      this.postDraw(charCanvas);
    });
  }

  loadImage = (src) => new Promise((resolve) => {
    if (!src) resolve(null);
    const img = new Image();
    img.setAttribute('crossorigin', 'Anonymous');
    img.src = `${src}`;
    img.addEventListener('load', () => resolve(img));
    img.addEventListener('error', () => resolve(null));
  });

  render() {
    return (
      <>
        <Row>
          <Form.Group
            as={Col}
            controlId="Character"
          >
            <Form.Label>Character</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              /* eslint-disable-next-line react/destructuring-assignment */
              disabled={this.state.loading}
              /* eslint-disable-next-line react/destructuring-assignment */
              value={this.state.characterId || ''}
              onChange={(e) => {
                this.setState({
                  characterId: e.target.value,
                });
              }}
            >
              {this.listCharacters()}
            </Form.Control>
          </Form.Group>
        </Row>

        {this.hasExpressions() && (
        <Row>
          <Form.Group
            as={Col}
            controlId="Expression"
          >
            <Form.Label>Character Expression</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              /* eslint-disable-next-line react/destructuring-assignment */
              disabled={this.state.loading}
              /* eslint-disable-next-line react/destructuring-assignment */
              value={this.state.expressionId || ''}
              onChange={(e) => {
                this.setState({
                  expressionId: e.target.value,
                });
              }}
            >
              {this.characterExpressions()}
            </Form.Control>
          </Form.Group>
        </Row>
        )}

        <Row>
          <Form.Group
            as={Col}
            controlId="background"
          >
            <Form.Label>Background</Form.Label>
            <Form.Control
              size="sm"
              as="select"
              /* eslint-disable-next-line react/destructuring-assignment */
              disabled={this.state.loading}
              /* eslint-disable-next-line react/destructuring-assignment */
              value={this.state.backgroundId || ''}
              onChange={(e) => {
                this.setState({
                  backgroundId: e.target.value,
                });
              }}
            >
              {this.sceneOptions()}
            </Form.Control>
          </Form.Group>
        </Row>
        <Button
          /* eslint-disable-next-line react/destructuring-assignment */
          disabled={this.state.loading}
          variant="primary"
          onClick={this.updateData}
        >
          {
            // eslint-disable-next-line react/destructuring-assignment
            this.state.loading
            && (
            <Spinner
              size="sm"
              animation="border"
            />
            )
          }
          Done
        </Button>
      </>
    );
  }
}
