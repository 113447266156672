import React, { PureComponent } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { AvatarCanvas } from './AvatarCanvas';
import './PreviewImg.css';

function isEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export class ImageDownload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      show: false,
      imgSrc: null,
      expression: 'neutral',
      disabled: true,
    };
  }

  componentDidMount() {
    this.updateImageUrl();
  }

  componentDidUpdate(prevProps) {
    const { character } = this.props;
    if (JSON.stringify(prevProps.character) !== JSON.stringify(character)) {
      this.updateImageUrl();
    }
  }

  downloadUrl = (src, type) => {
    const link = document.createElement('a');
    const {
      character,
      onHide,
    } = this.props;

    const { expression } = this.state;

    link.download = `${character.image.imageName}-${expression}.png`;
    link.href = src;
    link.target = '_blank';
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      if (type === 'custom') {
        onHide();
      }
    }, 100);
  };

  updateImageUrl = () => {
    const { aws, character } = this.props;

    if (!character || !character.image) return;
    const { properties } = character.image;
    if (properties && !isEmpty(properties) && properties.type !== 'custom') return;

    const src = aws
      ? `${character.image.imageUrl}?updated=${encodeURIComponent(character.image.updatedAt)}`
      : character.image.url;
    this.downloadUrl(src, properties.type);
  };

  render() {
    const { onHide, character, expressions } = this.props;

    if (!character || !character.image) {
      onHide();
      return null;
    }

    const {
      expression,
      disabled,
      imgSrc,
    } = this.state;

    const src = imgSrc;
    const { properties } = character.image;
    properties.expression = expression;

    if (properties.type === 'custom') {
      return null;
    }

    return (
      <>
        {(properties && !isEmpty(properties) && (properties.type !== 'custom')) && (
        <AvatarCanvas
          properties={properties}
          expression={expression}
          update={(data) => {
            this.setState({
              disabled: false,
              imgSrc: data,
            });
          }}
        />
        )}

        <Modal
          show
          onHide={onHide}
          size="sm"
          className="deleteModal"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Download Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form.Control
              as="select"
              value={expression}
              onChange={(e) => {
                this.setState({
                  disabled: true,
                  expression: e.target.value,
                });
              }}
            >
              {
                expressions.map((obj) => (
                  <option value={obj.value} key={obj.id}>{obj.title}</option>
                ))
              }
            </Form.Control>

          </Modal.Body>
          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>
            <Button
              disabled={!imgSrc || disabled}
              type="submit"
              variant="primary"
              onClick={() => {
                this.downloadUrl(src);
              }}
            >
              Download
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
