import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { doubleQuote } from '../../../helpers/html';
import { api } from '../../api';
// eslint-disable-next-line import/no-cycle
import { getUserName } from '.';

export class DeleteUser extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      error: null,
    };
  }

  deleteUserAction() {
    const { obj, onHide } = this.props;
    this.setState({ showLoading: true });
    api.delete(`/v1/users/${obj.id}`)
      .then(() => onHide())
      .catch((error) => this.setState({
        showLoading: false,
        error: error.response.data.error,
      }));
  }

  render() {
    const { obj, onHide } = this.props;
    const { error, showLoading } = this.state;
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete User:
            <span>
              {doubleQuote}
              {getUserName(obj)}
              {doubleQuote}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error
            ? (
              <Alert variant="danger">
                {error}
              </Alert>
            )
            : (
              <p className="text-center">
                Do you really want to delete the user?
              </p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            disabled={(error !== null)}
            type="submit"
            variant="primary"
            onClick={() => this.deleteUserAction()}
          >
            {showLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
