// Copied from /shared/types/User.ts, because when importing from there, it causes a dev server
export enum UserLoginProvider {
    Cognito = 'COGNITO',
    Google = 'Google',
    Facebook = 'Facebook',
    Apple = 'SignInWithApple',
    Amazon = 'LoginWithAmazon',
    Legacy = 'LEGACY',
}

export enum UserApiErrorCode {
    IS_LEGACY = 'IS_LEGACY',
    IS_EXTERNAL = 'IS_EXTERNAL',
    IS_UNVERIFIED = 'IS_UNVERIFIED',
    EMAIL_EXISTS = 'EMAIL_EXISTS',
    NO_SUCH_EMAIL = 'NO_SUCH_EMAIL',
}
