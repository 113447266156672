import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MemoryDTO, MemoryType, MemoryValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { Character } from 'dorian-shared/types/character/Character';
import { BranchStepMemoryAction, BranchStepMemoryActionType } from '../../../../../dorian-shared/types/branch/BranchStep';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';
import { MemorySlotCompareValueForm } from './MemorySlotCompareValueForm';

export const RememberActionType: Record<string, { name: string, title: string}> = {
  SET: {
    name: BranchStepMemoryActionType.Set,
    title: 'is now',
  },
  INCREASE: {
    name: BranchStepMemoryActionType.Increase,
    title: 'went up by',
  },
  DECREASE: {
    name: BranchStepMemoryActionType.Decrease,
    title: 'went down by',
  },
  VIEW: {
    name: BranchStepMemoryActionType.View,
    title: 'show/hide',
  },
};

type StepTypeRememberProps = {
  stepIndex: number,
  memoryBankSlots: MemoryDTO[],
  handleChangeStepAction: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  action: BranchStepMemoryAction,
  addStepAction: (
    stepIndex: number,
    variableId: number,
    variable: string,
    type: string,
    value: MemoryValue,
  ) => void,
  characters: Character[],
  isDisabled?: boolean,
}

export function StepTypeRemember(props: StepTypeRememberProps) {
  const {
    stepIndex, memoryBankSlots, handleChangeStepAction, action, addStepAction, characters,
    isDisabled,
  } = props;

  const variableInputValue = action && action.variableId ? action.variableId : 0;
  const actionType = action && action.type ? action.type : '';
  const isActionAvailable = Object.values(BranchStepMemoryActionType).includes(actionType as BranchStepMemoryActionType);
  const typeInputValue = isActionAvailable ? actionType : '';
  const valueInputValue = action && action.value ? action.value : '';

  const isRememberStepPossible = memoryBankSlots && memoryBankSlots.length > 0;
  const memoryBankSlot = memoryBankSlots.find((slot) => slot.id === Number(variableInputValue));

  useEffect(() => {
    if (!isRememberStepPossible) {
      return;
    }

    // prevent re-adding action object if already exists
    if (action?.variableId !== undefined && action?.type !== undefined) {
      return;
    }

    const variable = memoryBankSlots[0];
    const type = RememberActionType.INCREASE.name;
    const value = '';
    addStepAction(stepIndex, variable.id, variable.name, type, value);
  }, [action, addStepAction, isRememberStepPossible, memoryBankSlots, stepIndex]);

  const handleVariableChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const values = [
      {
        name: 'variableId',
        value: event.target.value,
      },
    ];

    const newMemory = memoryBankSlots.find((slot) => slot.id === Number(event.target.value));
    if (memoryBankSlot?.type !== newMemory?.type) {
      if (newMemory?.type === MemoryType.Number) {
        values.push({ name: 'type', value: RememberActionType.INCREASE.name });
      } else {
        values.push({ name: 'type', value: '' });
      }
      values.push({ name: 'value', value: '' });
    }
    handleChangeStepAction(values, stepIndex);
  };

  const handleActionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    const values = [{
      name: 'type',
      value: newValue,
    }];

    const isViewTypeChanged = newValue === BranchStepMemoryActionType.View
      || (typeInputValue === BranchStepMemoryActionType.View && newValue !== BranchStepMemoryActionType.View);

    if (isViewTypeChanged) {
      values.push({ name: 'value', value: '' });
    }
    handleChangeStepAction(values, stepIndex);
  };

  const availableActions = Object.keys(RememberActionType).filter((actionTypeKey) => {
    const inputAction = RememberActionType[actionTypeKey];
    return !(memoryBankSlot?.type !== MemoryType.Number
      && inputAction.name !== RememberActionType.SET.name);
  });

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { isRememberStepPossible
        ? (
          <Col>
            <Row
              xs={1}
              sm={1}
              lg={3}
            >
              <Form.Group
                as={Col}
                className="mb-0 px-1 d-block"
              >
                <Row>
                  <Col md={4} lg={3}>
                    <Form.Label column>
                      That
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      disabled={isDisabled}
                      size="sm"
                      as="select"
                      name={`steps[${stepIndex}][action][variableId]`}
                      value={variableInputValue}
                      onChange={handleVariableChange}
                    >
                      {memoryBankSlots.map((slot) => (
                        <option key={slot.id} value={slot.id}>
                          {slot.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group
                as={Col}
                className="mb-0 px-1 d-block"
              >
                <Form.Control
                  disabled={isDisabled}
                  size="sm"
                  as="select"
                  name={`steps[${stepIndex}][action][type]`}
                  value={typeInputValue}
                  onChange={handleActionChange}
                >
                  {availableActions.map((actionTypeKey) => {
                    const inputAction = RememberActionType[actionTypeKey];
                    const willActionDisabled = memoryBankSlot?.showIn.length === 0
                      && inputAction.name === RememberActionType.VIEW.name;

                    const title = willActionDisabled ? 'This memory visibility disabled in Memory Bank' : '';

                    return (
                      <option key={inputAction.name} disabled={willActionDisabled} title={title} value={inputAction.name}>
                        {RememberActionType[actionTypeKey].title}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Col className="mb-0 px-1 d-block">
                <MemorySlotCompareValueForm
                  onChangeValue={handleChangeStepAction}
                  stepIndex={stepIndex}
                  valueInputValue={valueInputValue}
                  characters={characters}
                  memoryBankSlot={memoryBankSlot}
                  inputName={`steps[${stepIndex}][action][value]`}
                  isDisabled={isDisabled}
                  actionType={typeInputValue}
                />
              </Col>
            </Row>
          </Col>
        )
        : <Row>Memory bank is empty.</Row>}
    </>
  );
}
