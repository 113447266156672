import React, { Component } from 'react';
import {
  Button, Col, Dropdown, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImgArr from '../../../assets/images/arr-002.svg';
import imgDelete from '../../../assets/images/delete.svg';
import imgExport from '../../../assets/images/export.svg';
import { api } from '../../api';
import { showToast } from '../../ui/utils';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import { PremiumIpDisabledEdit } from '../../utils/premiumIpDisabledEdit';
import '../Books/books.scss';
import { Export } from '../Stories/Export';
import { AuthorProfile } from '../Users/AuthorProfile';
import { ChapterCommentsBtn } from './ChapterCommentsBtn';
import { ChapterRestore } from './ChapterRestore';
import { FormatDate } from './FormatDate';
import { Loading } from './Loading';

export class ChapterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUserShow: null,
      isCloning: false,
      wizardStep: null,
    };
  }

  componentDidUpdate() {
    const wizardStep = JSON.parse(localStorage.getItem('wizardStep'));
    const { wizardStep: wizardStep1 } = this.state;
    if (wizardStep !== wizardStep1) {
      this.setState({
        wizardStep,
      });
    }
  }

  clone(asTest = false) {
    const {
      obj,
      match,
      update,
      user: { role },
    } = this.props;

    const isAdmin = role === 'admin';

    if (!isAdmin && asTest) {
      showToast({ textMessage: 'Sorry. You do not have permission to clone as test'});
      return;
    }

    this.setState({ isCloning: true });

    const value = {
      copyFromId: obj.id,
      isTest: asTest,
    };
    api.post(`/v1/books/${match.params.id}/chapters`, value)
      .then(() => {
        update();
        this.setState({ isCloning: false });
      })
      .catch(() => {
        this.setState({ isCloning: false });
      });
  }

  renderActions() {
    const {
      modeEdit,
      book,
      obj,
      edit,
      group,
      user,
      deleteChapter,
      unDeleteChapter,
    } = this.props;

    const { wizardStep } = this.state;
    const isAdmin = user?.role === 'admin';

    const approvedDisabled = () => !modeEdit
      || PremiumIpDisabledEdit(user.role, book, group.type)
      || PremiumIpDisabledApprovedEdit(user.role, book);

    if (['approved', 'pending', 'live', 'liveprompt'].includes(group.type) && user.role !== 'admin') {
      return null;
    }

    const wizardDisabled = wizardStep > 0;
    if (['submitted', 'rejected', 'approved', 'pending', 'live', 'liveprompt', 'prompt'].includes(group.type)) {
      const disabled = (['approved', 'pending', 'live', 'liveprompt'].includes(group.type) && user.role !== 'admin') || (user.role !== 'admin' && obj.story_role !== 'owner');

      return (
        <>

          {
            !['prompt'].includes(group.type)
            && (
            <ChapterRestore
              {...this.props}
              disabled={approvedDisabled()}
              type="tooltip"
            />
            )
          }

          <ChapterCommentsBtn
            {...this.props}
            disabled={approvedDisabled()}
            type="tooltip"
          />

          <span className="tooltip-box">
            <Button
              onClick={() => {
                deleteChapter(obj);
              }}
              disabled={
                disabled
                || wizardDisabled
                || approvedDisabled()
              }
              variant="secondary"
              size="sm"
              className="ml-1"
            >
              <img
                src={imgDelete}
                className="btnImg"
                alt="Delete Episode"
              />
            </Button>
            {user.role !== 'admin' ? null
              : (
                <div className="tooltip">
                  <div className="arrow" />
                  <div className="tooltip-inner">Delete</div>
                </div>
              )}
          </span>
        </>
      );
    }
    return (
      <>
        {user.role !== 'admin' ? null
          : (
            <span className="tooltip-box">
              <Button
                disabled={(user.role !== 'admin' && obj.story_role !== 'owner') || wizardDisabled}
                onClick={() => Export.exportStory(obj.id, obj.title)}
                variant="secondary"
                size="sm"
                className="ml-1"
              >
                <img src={imgExport} className="btnImg" alt="Export" />
              </Button>
              {user.role !== 'admin' && obj.story_role !== 'owner' ? null
                : (
                  <div className="tooltip">
                    <div className="arrow" />
                    <div className="tooltip-inner">Export</div>
                  </div>
                )}
            </span>
          )}

        <Dropdown
          className="d-inline-block"
        >
          <Dropdown.Toggle
            variant="secondary"
            id="ellipsis"
            className="ml-1"
            size="sm"
          >
            ...
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              as="button"
              disabled={
                  wizardDisabled
                  || approvedDisabled()
                }
              onMouseDown={() => {
                const newObj = JSON.parse(JSON.stringify(obj));
                newObj.episodeGroupId = group.id;
                edit(newObj);
              }}
            >
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              disabled={
                  wizardDisabled
                  || !modeEdit
                  || approvedDisabled()
                }
              onMouseDown={() => {
                this.clone();
              }}
            >
              Clone
            </Dropdown.Item>

            {isAdmin && (
              <Dropdown.Item
                as="button"
                disabled={
                  wizardDisabled
                  || !modeEdit
                  || approvedDisabled()
                }
                onMouseDown={() => {
                  this.clone(true);
                }}
              >
                Clone as test
              </Dropdown.Item>
            )}

            <Dropdown.Divider />
            {obj && obj.deleted
              && (
              <Dropdown.Item
                as="button"
                disabled={
                  wizardDisabled
                  || !modeEdit
                  || PremiumIpDisabledEdit(user.role, book)
                  || PremiumIpDisabledApprovedEdit(user.role, book)
                }
                onMouseDown={() => {
                  unDeleteChapter(obj);
                }}
              >
                Un-Delete
              </Dropdown.Item>
              ) }
            <Dropdown.Item
              as="button"
              disabled={
                  wizardDisabled
                  || !modeEdit
                  || PremiumIpDisabledEdit(user.role, book)
                  || PremiumIpDisabledApprovedEdit(user.role, book)
                }
              onMouseDown={() => {
                deleteChapter(obj);
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }

  render() {
    const authorProfile = () => {
      const { modalUserShow } = this.state;
      if (modalUserShow) {
        return (
          <AuthorProfile
            show={!!modalUserShow}
            onHide={() => {
              this.setState({ modalUserShow: null });
            }}
            user={modalUserShow}
          />
        );
      }
      return null;
    };

    const {
      disabled,
      index,
      obj,
      itemIndex,
      group,
      isAnalyticsVisible,
    } = this.props;
    const {
      isCloning,
      wizardStep,
    } = this.state;

    const itemStyle = obj.deleted ? { backgroundColor: '#D7DBDD' } : {};

    return (
      <Row
        className="books-list-item d-flex flex-nowrap "
        style={itemStyle}
        disabled={disabled}
        key={index}
      >
        <Loading show={isCloning} />

        <Col
          className="m-0 pr-0"
          sm={isAnalyticsVisible ? 3 : 5}
          xl={isAnalyticsVisible ? 4 : 6}
        >
          <i
            className="indicator"
            style={{ backgroundColor: group.color }}
          />
          <span className="colNum">{itemIndex + 1}</span>

          <div className="books-list-titleBox">
            <span
              className={`${wizardStep && wizardStep === 8 ? 'wizardActive' : ''}`}
            >
              <Link
                className="books-list-link"
                to={`/stories/${obj.id}/branches`}
              >
                {obj.title}
              </Link>
              {wizardStep && wizardStep === 8
                ? (
                  <div className="sidebarArr">
                    <img src={ImgArr} alt="" />
                    <span>click to continue</span>
                  </div>
                )
                : null}
            </span>
          </div>
        </Col>
        <Col
          sm={1}
          xl={1}
          className="px-0 text-center books-list-additionalBox"
        >
          {obj.chapter}
        </Col>
        {isAnalyticsVisible && (
        <>
          <Col
            sm={1}
            xl={1}
            className="px-0 text-center books-list-additionalBox"
          >
            {
            obj.analytics && obj.analytics.dropoff ? obj.analytics.dropoff.toFixed(2) : '0.0'
          }
          </Col>
          <Col
            sm={1}
            xl={1}
            className="px-0 text-center books-list-additionalBox"
          >
            { /* FIXME: AUT-898 */
            obj.analytics && obj.analytics.users ? obj.analytics.users : '0'
          }
          </Col>
        </>
        )}

        <Col
          sm={1}
          xl={1}
          className="px-0 text-center books-list-additionalBox"
        >
          <span className="d-inline-block p-1">
            {obj.revision}
          </span>
        </Col>
        <Col
          sm={3}
          xl={3}
          className="px-0 text-center books-list-additionalBox"
        >
          <FormatDate className="d-inline-block p-1" date={obj.updatedAt} />
        </Col>

        <Col className="text-right px-0 books-list-actionBox">
          {this.renderActions()}
        </Col>

        {authorProfile()}
      </Row>
    );
  }
}
