import React from 'react';
import { BugTrackingSettings } from '../BugTrackingSettings/BugTrackingSettings';
import { BugTrackingTest } from '../BugTrackingTest/BugTrackingTest';
import { PerformanceTest } from '../PerformanceTest/PerformanceTest';
import { SampleRateTest } from '../SampleRateTest/SampleRateTest';

export const LINK_HOW_TO_CHECK_ISSUE = 'https://www.notion.so/dorian-live/How-to-check-issue-in-the-Sentry-64c255114d794e4d8367859d988edae8';
export const LINK_SENTRY_PERFORMANCE = 'https://dorian-zb.sentry.io/performance/?landingDisplay=all&project=4504278910500864&query=';
export const LINK_SENTRY_ISSUE = 'https://dorian-zb.sentry.io/issues';

export function BugTracking() {
  return (
    <div>
      <BugTrackingSettings />
      <SampleRateTest />
      <BugTrackingTest />
      <PerformanceTest />
    </div>
  );
}
