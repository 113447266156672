import { useLocation } from 'react-router';
import { isValidEmail } from '../../../helpers/validate';
import { SetPasswordFormValues } from './useSetPasswordForm';

export const useSetPasswordFormParams = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';
  const username = searchParams.get('username') ?? '';
  const isParameterValidEmail = isValidEmail(username);

  const setPasswordInitialValues: SetPasswordFormValues = {
    code,
    username,
    password: '',
    confirmPassword: '',
  };
  return {
    setPasswordInitialValues,
    code,
    username,
    isParameterValidEmail,
  };
};
