import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { api } from '../../api';

export class DeleteExpression extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
    };
  }

  deleteAction() {
    this.setState({ showLoading: true });
    const {
      onHide,
      obj,
    } = this.props;

    onHide();
    api.delete(`/v1/characters/expressions/${obj.id}`)
      .then(() => {
        onHide();
      });
  }

  render() {
    const {
      obj,
      onHide,
    } = this.props;

    const { showLoading } = this.state;

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete
            {obj.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">
            Do you really want to delete this character expression?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={() => this.deleteAction()}
          >
            {showLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
