import React from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import {
  ChoiceMemoryContext,
  ChoiceMemoryProvider,
} from '../../../../contexts/ChoiceMemory/ChoiceMemoryContext';
import { Preview } from '../../../ui/Preview/Preview';
import './Preview.scss';

/**
 * DetachablePreview component
 * @param {boolean} show - show/hide detachable preview
 * @param {string} storyUuid - story uuid
 * @param {string} bookStyle - book style
 * @param {object} store - store object
 * @param {function} onClose - function to close detachable preview
 * @param {function} onPreviewExit - function to exit preview
 * @param {boolean} isBranchDetailsCacheDisabled - flag to disable branch details cache
 * @param {boolean} hideDetach - flag to hide detach button
 * @param {boolean} showAnalyticsPanel - flag to show analytics panel
 * @param {string} displayAnalyticsOption - display analytics option
 * @param {string} bookId - book id
 * @param {function} update - function to update detachable preview
 * @param {function} scrollToBranch - function to scroll to branch
 */
export class DetachablePreview extends React.PureComponent {
  constructor(props) {
    super(props);
    const { displayAnalyticsOption } = props;
    this.externalWindow = null;
    this.state = {
      portal: false,
      displayAnalyticsOption,
      branchId: null,
    };
  }

  closeWindow = () => {
    this.setState({ portal: false });
    if (this.externalWindow) this.externalWindow.close();
    this.externalWindow = null;
  };

  onExternalClose = () => {
    const { portal } = this.state;

    if (portal && this.externalWindow) {
      this.setState({ portal: false });
      this.externalWindow = null;
    }
  };

  openWindow = () => {
    const { storyUuid } = this.props;
    this.externalWindow = window.open(`/play/${storyUuid}`, '_blank');

    this.externalWindow.document.title = document.title;

    // NOTE: Different browser and also different versions of same browser
    // have strange behaviour for this events.
    // Dirty fix.
    this.externalWindow.addEventListener('beforeunload', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('close', this.onExternalClose, { capture: true });
    this.externalWindow.addEventListener('unload', this.onExternalClose, { capture: true });

    this.externalWindow.addEventListener('load', () => {
      const { portal } = this.state;

      if (!portal) {
        this.setState({ portal: true });
      }
    });
  };

  beforeUnload = () => {
    this.closeWindow();
  };

  async componentDidMount() {
    const { store, displayAnalyticsOption, bookId } = this.props;
    if (store) {
      store.setDisplayAnalytics(displayAnalyticsOption);
    }
    if (bookId) {
      const choiceMemory = this.context;
      await choiceMemory.fetchVariables(bookId);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      update, show, bookId, store, displayAnalyticsOption: displayAnalyticsOptionProps, reFetchMemoriesTrigger,
    } = this.props;

    const { portal, displayAnalyticsOption: displayAnalyticsOptionState } = this.state;

    if (prevState.portal !== portal) {
      update(!!(show && !portal));
    }

    if (prevProps.show !== show) {
      this.closeWindow();
      update(!!(show && !portal));
      if (show) {
        window.addEventListener('beforeunload', this.beforeUnload, { passive: true });
        if (bookId) {
          const choiceMemory = this.context;
          choiceMemory.fetchVariables(bookId);
        }
      } else {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    }

    if (displayAnalyticsOptionState !== prevState.displayAnalyticsOption) {
      if (store) {
        store.setDisplayAnalytics(displayAnalyticsOptionState);
      }
    }

    if (displayAnalyticsOptionProps !== prevProps.displayAnalyticsOption) {
      this.setState({ displayAnalyticsOption: displayAnalyticsOptionProps });
    }

    if (prevProps.bookId !== bookId) {
      const choiceMemory = this.context;
      await choiceMemory.fetchVariables(bookId);
    }

    // TODO: Remove this after refactoring
    if (prevProps.reFetchMemoriesTrigger !== reFetchMemoriesTrigger) {
      const choiceMemory = this.context;
      await choiceMemory.fetchVariables(bookId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnload);
    this.closeWindow();
  }

  handleShowNodeClick = () => {
    const { branchId } = this.state;
    const { scrollToBranch } = this.props;

    if (branchId) {
      scrollToBranch(branchId, true);
    }
  };

  handleEditNodeClick = () => {
    const { store, onEditStep } = this.props;
    const currentStepId = store?.currentStep?.id;
    const currentBranchId = store?.currentBranchId;
    onEditStep?.(currentBranchId, currentStepId);
  };

  renderPanel() {
    const { hideDetach, onClose } = this.props;

    return (
      <div className="navi">
        <Row className="d-flex justify-content-center">
          {!hideDetach
          && (
          <Col>
            <Button
              block
              onClick={this.openWindow}
              variant="primary"
              size="sm"
            >
              Detach
            </Button>
          </Col>
          )}
          <Col>
            <Button
              disabled={!onClose}
              block
              onClick={onClose}
              variant="secondary"
              size="sm"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row className="mt-2 mr-2 d-flex justify-content-center">
          <Col sm="auto">
            <Button
              onClick={this.handleShowNodeClick}
              variant="outline-primary"
              size="sm"
            >
              Show this node
            </Button>
          </Col>
          <Col md="auto">
            <Button
              onClick={this.handleEditNodeClick}
              variant="outline-primary"
              size="sm"
            >
              Edit this node
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

  handleAnalyticOptionChanged(event) {
    this.setState({ displayAnalyticsOption: event.target.value });
  }

  handleScrollToBranch = (branchId) => {
    const { scrollToBranch } = this.props;
    this.setState({ branchId });
    scrollToBranch(branchId);
  };

  render() {
    const {
      show,
      storyUuid,
      bookStyle,
      store,
      onPreviewExit,
      isBranchDetailsCacheDisabled,
      showAnalyticsPanel,
      bookId,
    } = this.props;

    const {
      portal,
      displayAnalyticsOption,
    } = this.state;

    if (portal || !storyUuid) {
      return (
        <div className="previewDock" />
      );
    }

    return (
      <div
        className="previewDock"
      >
        {this.renderPanel()}
        { showAnalyticsPanel && (
        <div className="analyticsPanelInPreview">
          <div className="analyticsPanelInPreviewTitle">
            Performance:
          </div>
          <Form.Check
            custom
            type="radio"
            inline
            label="Hide"
            id="Hide"
            value="hide"
            checked={displayAnalyticsOption === 'hide'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
          />
          <Form.Check
            custom
            type="radio"
            inline
            label="as count"
            value="count"
            checked={displayAnalyticsOption === 'count'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
            id="count"
          />
          <Form.Check
            custom
            type="radio"
            inline
            label="as percent"
            value="percentage"
            checked={displayAnalyticsOption === 'percentage'}
            onChange={(event) => this.handleAnalyticOptionChanged(event)}
            id="percentage"
          />
        </div>
        )}
        <ChoiceMemoryProvider>
          {storyUuid && (
            <Preview
              bookId={bookId}
              episodeUuid={storyUuid}
              bookStyle={bookStyle}
              // TODO: make width flexible on GR side.
              // 75% of width of previewDock which is 30em, where font-size is 16px
              width={345}
              store={store}
              onPreviewExit={onPreviewExit}
              isBranchDetailsCacheDisabled={isBranchDetailsCacheDisabled}
              scrollToBranch={this.handleScrollToBranch}
            />
          )}
        </ChoiceMemoryProvider>
      </div>
    );
  }
}

DetachablePreview.contextType = ChoiceMemoryContext;
