import { api } from '../../api';

export async function fetchClientVariables() {
  const params = {
    order: 'createdAt:asc',
  };

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.get('/v1/clientconfig', { params });
    return response.data.configs;
  } catch (error) {
    throw error;
  }
}

export async function deleteClientVariableById(id) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.delete(`/v1/clientconfig/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateClientVariable(clientVariable) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.put(`/v1/clientconfig/${clientVariable.id}`, clientVariable);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addClientVariable(clientVariable) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.post('/v1/clientconfig/', clientVariable);
    return response.data;
  } catch (error) {
    throw error;
  }
}
