import classNames from 'classnames/bind';
import React, { Component } from 'react';
import styles from './PageHeader.scss';

const cs = classNames.bind(styles);

export class BtnMenuCollapsible extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      MenuCollapsible: localStorage.getItem('MenuCollapsible') !== null ? JSON.parse(localStorage.getItem('MenuCollapsible')) : false,
    };
  }

  componentDidMount() {
    const { MenuCollapsible } = this.state;

    const elm = document.getElementById('root');
    const w = window.innerWidth;
    if (w < 992 && !MenuCollapsible) {
      localStorage.setItem('MenuCollapsible', String(true));
      this.setState({
        MenuCollapsible: true,
      }, () => elm.classList.add('menuCollapsed'));
    }
    if (elm) {
      if (MenuCollapsible || MenuCollapsible === 'true') {
        elm.classList.add('menuCollapsed');
      } else {
        elm.classList.remove('menuCollapsed');
      }
    }
  }

  menuCollapsibleAction() {
    const { MenuCollapsible } = this.state;
    const hasMenuCollapsible = Boolean(MenuCollapsible);
    localStorage.setItem('MenuCollapsible', String(!hasMenuCollapsible));
    this.setState({
      MenuCollapsible: !hasMenuCollapsible,
    });
    const elm = document.getElementById('root');
    if (elm) {
      if (hasMenuCollapsible) {
        elm.classList.remove('menuCollapsed');
      } else {
        elm.classList.add('menuCollapsed');
      }
    }
  }

  render() {
    const { MenuCollapsible } = this.state;
    return (
      <button
        type="button"
        onClick={() => this.menuCollapsibleAction()}
        className={cs('btnMenuCollapsible')}
      >
        <svg
          className={cs(MenuCollapsible ? 'd-inline-block' : 'd-none')}
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="bars"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="currentColor"
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            className=""
          />
        </svg>

        <svg
          className={cs(MenuCollapsible ? 'd-none' : 'd-inline-block')}
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-left"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 512"
        >
          <path
            fill="currentColor"
            d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
            className=""
          />
        </svg>
      </button>
    );
  }
}
