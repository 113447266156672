import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export function NotFoundModal(props) {
  const { onHide } = props;

  return (
    <Modal
      {...props}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>
          Not Found
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          Selected сhapter does not exist. You will be redirected to сhapters list.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          block
          type="reset"
          variant="secondary"
          onClick={onHide}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
