import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../../../assets/images/dorian-white.svg';
import { PrivacyPolicyContent } from './PrivacyPolicyContent';
import './PrivacyPolicyPage.scss';

export const checkExternal = (t, a) => {
  if (t === 'external') {
    return `/${a}`;
  }
  if (t === 'documents') {
    return `/documents/${a}`;
  }
  return `/#${a}`;
};

export class PrivacyPolicyPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div className="privacy-policy-page">
        <Container className="">
          <div className="logo">
            <Logo />
          </div>
          <h2 className="privacy-policy-page-title">PRIVACY POLICY</h2>
          <PrivacyPolicyContent />
        </Container>
      </div>
    );
  }
}
