import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddGenre } from './AddGenre';
import './Genres.css';
import { GenresTableRow } from './GenresTableRow';

export class Genre extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;

    this.state = {
      genres: [],
      editGenreData: null,
      // eslint-disable-next-line react/no-unused-state
      pageTitle: 'Genres',
      // eslint-disable-next-line react/no-unused-state
      listGenresActive: false,
      addGenreActive: false,
      // eslint-disable-next-line react/no-unused-state
      editGenreActive: false,
      loading: true,
    };

    this.page = {
      header: {
        title: 'Genres',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Genre',
            action: () => this.initAddGenre(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line react/no-unused-state
      listGenresActive: true,
    });
    this.loadGenresList();
  }

  loadGenresList = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/genres')
      .then((res) => {
        this.setState({
          genres: res.data.genres,
          // eslint-disable-next-line react/no-unused-state
          listGenresActive: true,
          addGenreActive: false,
          // eslint-disable-next-line react/no-unused-state
          editGenreActive: false,
        });
      });
    this.setState({
      loading: false,
    });
  };

  GenresTableRow() {
    const { genres } = this.state;

    const action = this.initAddGenre;
    const updateGenres = () => {
      this.loadGenresList();
    };

    return genres.map((object, i) => (
      <GenresTableRow
        obj={object}
        /* eslint-disable-next-line react/no-array-index-key */
        key={i}
        actionEditGenre={action}
        update={updateGenres}
      />
    ));
  }

  initAddGenre = (obj) => {
    let editGenreData = null;
    if (obj) {
      editGenreData = obj;
    }
    this.setState({
      editGenreData,
      addGenreActive: true,
    });
  };

  cancelAddGenre = () => {
    this.setState({
      editGenreData: null,
      addGenreActive: false,
    });
  };

  render() {
    const {
      loading,
      addGenreActive,
      editGenreData,
    } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card>
            <Card.Body>
              <Card.Title>List of Genres</Card.Title>

              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>ID</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.GenresTableRow()}
                      </tbody>
                    </Table>
                  )
              }
            </Card.Body>
          </Card>
        </PageWrapper>

        {addGenreActive
          ? (
            <AddGenre
              data={editGenreData}
              show={addGenreActive}
              onHide={this.cancelAddGenre}
              update={this.loadGenresList}
            />
          )
          : null}
      </>
    );
  }
}
