import { useEffect, useState } from 'react';
import { useMemoryBankServiceContext } from '../../../../contexts/MemoryBankContext/MemoryBankServiceContext';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';
import { MemoryDTO } from '../../Book/MemoryBank/memoryBankTypes';

export function useImportTextMemoryBank(bookId: number) {
  const { fetchMemoryBank } = useMemoryBankServiceContext();
  const [
    requestStatus,
    {
      setToError,
      setToLoading,
      setToSuccess,
    },
  ] = useAsyncOperationState();

  const [memoryBank, setMemoryBank] = useState<MemoryDTO[] | null>(null);

  useEffect(() => {
    setToLoading();
    fetchMemoryBank(bookId)
      .then((fetchedMemoryBank) => {
        setMemoryBank(fetchedMemoryBank);
        setToSuccess();
      })
      .catch((error) => {
        logger.error('[ImportTextModal] Error in fetching Memory Bank', error);
        showToast({ textMessage: 'Error in fetching Memory Bank' });
        setToError('Error in fetching characters');
      });
  }, [bookId, fetchMemoryBank, setToError, setToLoading, setToSuccess]);
  return {
    memoryBank,
    requestStatus,
  };
}
