import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { MemoryDTO, MemoryLinkValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';
import { MemorySlotCompareVariableFormList } from './MemorySlotCompareVariableFormList';

type MemorySlotCompareVariableFormProps = {
  valueInputValue: MemoryLinkValue | undefined,
  stepIndex: number,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  inputName: string,
  isDisabled?: boolean,
  variables: MemoryDTO[] | undefined,
}

export function MemorySlotCompareVariableForm(props: MemorySlotCompareVariableFormProps) {
  const {
    valueInputValue, stepIndex, onChangeValue, inputName,
    isDisabled, variables,
  } = props;

  return (
    <Form.Group as={Row}>
      <Col>
        <MemorySlotCompareVariableFormList
          isDisabled={isDisabled}
          stepIndex={stepIndex}
          onChangeValue={onChangeValue}
          valueInputValue={valueInputValue}
          inputName={inputName}
        >
          <option value="" disabled>[select]</option>
          { variables?.map((variable) => (
            <option
              key={variable.id}
              value={variable.id}
            >
              {variable.name}
            </option>
          ))}
        </MemorySlotCompareVariableFormList>
      </Col>
    </Form.Group>
  );
}
