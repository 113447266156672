import { api } from '../../api';

export function getDocumentIdsForPrompt(prompt) {
  return prompt?.permissionModifiers?.prompt.requireLegal ?? [];
}

export function fetchAgreementForPrompt(documentIdsForPrompt, onAgreementFetched) {
  if (documentIdsForPrompt.length > 0) {
    Promise.all(
      documentIdsForPrompt.map((id) => api.get(`/v1/documents/${encodeURI(id)}`)),
    ).then((responses) => {
      const { document } = responses[0].data;
      onAgreementFetched(document);
    });
  }
}
