import { IS_MAC } from '../../../../../helpers/os';

export function detectMouseButton(evt, buttonNumber, options) {
  if (buttonNumber === undefined) {
    // eslint-disable-next-line no-param-reassign
    buttonNumber = 1;
  }
  if (!options) {
    // eslint-disable-next-line no-param-reassign
    options = {};
  }

  const isMetaKey = IS_MAC ? evt.metaKey && !options.allowCtrlClick : evt.metaKey;

  if (isMetaKey
      || (evt.ctrlKey && !options.allowCtrlClick)
      || evt.altKey
      || (evt.shiftKey && !options.allowShiftClick)
  ) {
    return false;
  }
  if ('buttons' in evt) {
    return evt.buttons === buttonNumber;
  }
  if ('which' in evt) {
    return evt.which === buttonNumber;
  }
  return evt.button === buttonNumber - 1;
}
