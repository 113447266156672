import React, { ChangeEvent } from 'react';
import { ButtonAction } from '../../../../../../dorian-shared/types/event/Event';
import { TextField } from '../../../../../ui/_fields/TextField/TextField';

interface ButtonTargetInputProps {
    error?: string
    buttonAction: ButtonAction,
    value: string,
    onChange: (value: string) => void,
}

export function ButtonTargetInput(props: ButtonTargetInputProps) {
  const {
    buttonAction,
    value,
    onChange,
    error,
  } = props;
  switch (buttonAction) {
    case 'story': {
      let storyUuid = '';
      try {
        storyUuid = JSON.parse(value).story_uuid;
      } catch {
        // TODO: report to sentry
      }

      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = JSON.stringify({
          story_uuid: event.target.value,
        });
        onChange(newValue);
      };
      return (
        <TextField
          label="Story UUID"
          name="storyUuid"
          placeholder="UUID of the story button should lead to (for example 6911589e-3567-43b5-ae8d-4a0ae3d7418c)"
          value={storyUuid}
          onChange={handleChange}
          error={error}
        />
      );
    }
    case 'chat': {
      let channelId = '';
      try {
        channelId = JSON.parse(value).channelId;
      } catch {
        // TODO: report to sentry
      }
      const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = JSON.stringify({
          channelId: event.target.value,
        });
        onChange(newValue);
      };
      return (
        <TextField
          label="Channel Id"
          name="channelId"
          placeholder="Channel id (ie announcements, new_releases, arcana_quest_info)"
          value={channelId}
          onChange={handleChange}
          error={error}
        />
      );
    }
    default:
      return null;
  }
}
