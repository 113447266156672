import React, { ChangeEvent, ReactNode } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

interface TextFieldProps {
    label: ReactNode
    value: string
    onChange: (event: ChangeEvent<HTMLInputElement>) => void
    name?: string
    placeholder?: string
    error?: string
    maxLength?: number
}

export function TextField(props: TextFieldProps) {
  const {
    label,
    name,
    value,
    onChange,
    error,
    maxLength = 0,
    placeholder,
  } = props;

  const hasMaxLength = Boolean(maxLength);
  const isTitleTooLong = hasMaxLength ? value.length > maxLength : false;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={!!error}
          className={isTitleTooLong ? 'text-limit' : ''}
          placeholder={placeholder}
        />
        {hasMaxLength && (
        <Form.Text className="char-limit-info-box" style={{ top: 0, bottom: 'auto' }}>
          {maxLength - value.length}
        </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
