import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { doubleQuote } from '../../../helpers/html';
import { api } from '../../api';

export class DeleteUser extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      success: false,
      loading: false,
      error: null,
    };
  }

  deleteUserAction() {
    this.setState({ loading: true });
    const { obj, onHide } = this.props;
    api.delete(`/v1/users/${obj.id}`)
      .then(() => {
        this.setState({
          success: true,
          loading: false,
          error: null,
        }, () => {
          setTimeout(() => {
            this.setState({
              success: false,
            }, () => {
              onHide();
            });
          }, 1500);
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.error,
        });
      });
  }

  renderContent = () => {
    const { error, loading, success } = this.state;
    if (error) {
      return (
        <Alert
          show
          variant="danger"
        >
          {error}
        </Alert>
      );
    }

    if (success) {
      return (
        <Alert
          show
          variant="success"
        >
          user delete successfully
        </Alert>
      );
    }

    if (loading) {
      return (
        <Alert
          show
          variant="info"
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          Please wait ...
        </Alert>
      );
    }

    return (
      <p className="text-center">
        Do you really want to delete the user?
      </p>
    );
  };

  render() {
    const { obj, onHide } = this.props;
    const { error, loading, success } = this.state;
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            Delete User:
            <span>
              {doubleQuote}
              {obj.fullname}
              {doubleQuote}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={(loading)}
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            disabled={(error !== null || loading || success)}
            type="submit"
            variant="primary"
            onClick={() => this.deleteUserAction()}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
