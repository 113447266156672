import React, { ReactNode, createContext, useContext } from 'react';
import { ChoiceMemory } from '../../services/choiceMemory/ChoiceMemory';

export const ChoiceMemoryContext = createContext<ChoiceMemory>(new ChoiceMemory());

export function ChoiceMemoryProvider({ children }: { children: ReactNode }) {
  const choiceMemory = useContext(ChoiceMemoryContext);

  return (
    <ChoiceMemoryContext.Provider value={choiceMemory}>
      {children}
    </ChoiceMemoryContext.Provider>
  );
}
