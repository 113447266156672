import React, { useState } from 'react';
import {
  Button, Col, Form, Row,
} from 'react-bootstrap';
import { StoryState } from '../../../../dorian-shared/types/story/Story';
import { MemoryDTO } from '../../Book/MemoryBank/memoryBankTypes';

export type BookEconomySettingsProps = {
  memoryBank: MemoryDTO[],
  onLoad: (storyType: StoryState) => void,
}

export function BookEconomySettings(props: BookEconomySettingsProps) {
  const { onLoad, memoryBank } = props;
  const [storyState, setStoryState] = useState<StoryState>(StoryState.Active);

  return (
    <>
      <Form.Group as={Row}>
        <Col sm={3}>
          <Form.Check
            custom
            type="radio"
            label="Live economy"
            id="storyStateLive"
            checked={storyState === StoryState.Live}
            onChange={(e) => setStoryState(StoryState.Live)}
          />
          <Form.Check
            custom
            type="radio"
            label="Active economy"
            id="storyStateActive"
            checked={storyState === StoryState.Active}
            onChange={(e) => setStoryState(StoryState.Active)}
          />
        </Col>

      </Form.Group>
      <Row>
        <Col sm={4} md={3} lg={2}>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => onLoad(storyState)}
            disabled={!storyState}
            className="w-100 m-0"
          >
            Load
          </Button>
        </Col>
      </Row>
    </>
  );
}
