import React from 'react';

export function useConfirmDeleteModal() {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [confirmDeleteAchievementId, setConfirmDeleteAchievementId] = React.useState<number| undefined>();
  const onConfirmRef = React.useRef<() => void>();
  const onCancelRef = React.useRef<() => void>();

  const handleDeleteConfirm = (
    achievementId: number,
    onConfirm: () => void,
    onCancel: () => void,
  ) => {
    setShowConfirm(true);
    setConfirmDeleteAchievementId(achievementId);
    onConfirmRef.current = onConfirm;
    onCancelRef.current = onCancel;
  };

  const clearConfirm = () => {
    setShowConfirm(false);
    setConfirmDeleteAchievementId(undefined);
    onConfirmRef.current = undefined;
    onCancelRef.current = undefined;
  };

  const handleOnConfirm = () => {
    onConfirmRef.current?.();
    clearConfirm();
  };

  const handleOnCancel = () => {
    onCancelRef.current?.();
    clearConfirm();
  };
  return {
    showConfirm, confirmDeleteAchievementId, handleDeleteConfirm, handleOnConfirm, handleOnCancel,
  };
}
