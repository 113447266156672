import React, { ReactNode, createContext, useContext } from 'react';
import { api } from '../../components/api';
import { ApiService } from '../../services/apiService/ApiService';

export const ApiServiceContext = createContext<ApiService>(new ApiService(api));

export function ApiServiceProvider({ children }: { children: ReactNode }) {
  const apiService = useContext(ApiServiceContext);

  return (
    <ApiServiceContext.Provider value={apiService}>
      {children}
    </ApiServiceContext.Provider>
  );
}

export const useApiService = () => useContext(ApiServiceContext);
