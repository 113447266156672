import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import '../Books/books.scss';
import { AuthorProfile } from '../Users/AuthorProfile';

export class Authors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalUserShow: null,
    };
  }

  render() {
    const {
      authors: authors1,
      user,
    } = this.props;

    const { modalUserShow } = this.state;

    const authorProfile = () => {
      if (modalUserShow) {
        return (
          <AuthorProfile
            show={!!modalUserShow}
            onHide={() => {
              this.setState({ modalUserShow: null });
            }}
            user={modalUserShow}
          />
        );
      }
      return null;
    };

    const authors = authors1 || [];

    return (
      <div className="BookAuthorsList">
        {
          authors.map((author, i) => (
            <Button
              disabled={(user.role !== 'admin')}
              className="p-1"
              variant="link"
              size="sm"
              /* eslint-disable-next-line react/no-array-index-key */
              key={i}
              onClick={() => {
                this.setState({ modalUserShow: author.id });
              }}
            >
              {author.name}
            </Button>
          ))
        }
        {authorProfile()}
      </div>
    );
  }
}
