import serialize from 'form-serialize';
import queryString from 'query-string';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Spinner,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { api } from '../../api';

export class Invite extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: '',
      validated: null,
      loading: false,
      terms: false,
      formData: {
        id: '',
      },
      // eslint-disable-next-line react/no-unused-state
      showLoading: false,
      // eslint-disable-next-line react/no-unused-state
      submitted: false,
      // eslint-disable-next-line react/no-unused-state
      rightBox: null,
      showForm: false,
      yourWriting: [],
    };

    this.Device = ['iOS', 'Android'];

    this.Countries = [
      'United States',
      'Canada',
      'United Kingdom',
      'Philippines',
      'Afghanistan',
      'Åland Islands',
      'Albania',
      'Algeria',
      'American Samoa',
      'Andorra',
      'Angola',
      'Anguilla',
      'Antarctica',
      'Antigua and Barbuda',
      'Argentina',
      'Armenia',
      'Aruba',
      'Australia',
      'Austria',
      'Azerbaijan',
      'Bahamas',
      'Bahrain',
      'Bangladesh',
      'Barbados',
      'Belarus',
      'Belgium',
      'Belize',
      'Benin',
      'Bermuda',
      'Bhutan',
      'Bolivia, Plurinational State of',
      'Bonaire, Sint Eustatius and Saba',
      'Bosnia and Herzegovina',
      'Botswana',
      'Bouvet Island',
      'Brazil',
      'British Indian Ocean Territory',
      'Brunei Darussalam',
      'Bulgaria',
      'Burkina Faso',
      'Burundi',
      'Cambodia',
      'Cameroon',
      'Cape Verde',
      'Cayman Islands',
      'Central African Republic',
      'Chad',
      'Chile',
      'China',
      'Christmas Island',
      'Cocos (Keeling) Islands',
      'Colombia',
      'Comoros',
      'Congo',
      'Congo, the Democratic Republic of the',
      'Cook Islands',
      'Costa Rica',
      'Côte d&#039;Ivoire',
      'Croatia',
      'Cuba',
      'Curaçao',
      'Cyprus',
      'Czech Republic',
      'Denmark',
      'Djibouti',
      'Dominica',
      'Dominican Republic',
      'Ecuador',
      'Egypt',
      'El Salvador',
      'Equatorial Guinea',
      'Eritrea',
      'Estonia',
      'Ethiopia',
      'Falkland Islands (Malvinas)',
      'Faroe Islands',
      'Fiji',
      'Finland',
      'France',
      'French Guiana',
      'French Polynesia',
      'French Southern Territories',
      'Gabon',
      'Gambia',
      'Georgia',
      'Germany',
      'Ghana',
      'Gibraltar',
      'Greece',
      'Greenland',
      'Grenada',
      'Guadeloupe',
      'Guam',
      'Guatemala',
      'Guernsey',
      'Guinea',
      'Guinea-Bissau',
      'Guyana',
      'Haiti',
      'Heard Island and McDonald Islands',
      'Holy See (Vatican City State)',
      'Honduras',
      'Hong Kong',
      'Hungary',
      'Iceland',
      'India',
      'Indonesia',
      'Iran, Islamic Republic of',
      'Iraq',
      'Ireland',
      'Isle of Man',
      'Israel',
      'Italy',
      'Jamaica',
      'Japan',
      'Jersey',
      'Jordan',
      'Kazakhstan',
      'Kenya',
      'Kiribati',
      'Korea, Democratic People&#039;s Republic of',
      'Korea, Republic of',
      'Kuwait',
      'Kyrgyzstan',
      'Lao People&#039;s Democratic Republic',
      'Latvia',
      'Lebanon',
      'Lesotho',
      'Liberia',
      'Libya',
      'Liechtenstein',
      'Lithuania',
      'Luxembourg',
      'Macao',
      'Macedonia, the former Yugoslav Republic of',
      'Madagascar',
      'Malawi',
      'Malaysia',
      'Maldives',
      'Mali',
      'Malta',
      'Marshall Islands',
      'Martinique',
      'Mauritania',
      'Mauritius',
      'Mayotte',
      'Mexico',
      'Micronesia, Federated States of',
      'Moldova, Republic of',
      'Monaco',
      'Mongolia',
      'Montenegro',
      'Montserrat',
      'Morocco',
      'Mozambique',
      'Myanmar',
      'Namibia',
      'Nauru',
      'Nepal',
      'Netherlands',
      'New Caledonia',
      'New Zealand',
      'Nicaragua',
      'Niger',
      'Nigeria',
      'Niue',
      'Norfolk Island',
      'Northern Mariana Islands',
      'Norway',
      'Oman',
      'Pakistan',
      'Palau',
      'Palestinian Territory, Occupied',
      'Panama',
      'Papua New Guinea',
      'Paraguay',
      'Peru',
      'Pitcairn',
      'Poland',
      'Portugal',
      'Puerto Rico',
      'Qatar',
      'Réunion',
      'Romania',
      'Russian Federation',
      'Rwanda',
      'Saint Barthélemy',
      'Saint Helena, Ascension and Tristan da Cunha',
      'Saint Kitts and Nevis',
      'Saint Lucia',
      'Saint Martin (French part)',
      'Saint Pierre and Miquelon',
      'Saint Vincent and the Grenadines',
      'Samoa',
      'San Marino',
      'Sao Tome and Principe',
      'Saudi Arabia',
      'Senegal',
      'Serbia',
      'Seychelles',
      'Sierra Leone',
      'Singapore',
      'Sint Maarten (Dutch part)',
      'Slovakia',
      'Slovenia',
      'Solomon Islands',
      'Somalia',
      'South Africa',
      'South Georgia and the South Sandwich Islands',
      'South Sudan',
      'Spain',
      'Sri Lanka',
      'Sudan',
      'Suriname',
      'Svalbard and Jan Mayen',
      'Swaziland',
      'Sweden',
      'Switzerland',
      'Syrian Arab Republic',
      'Taiwan, Province of China',
      'Tajikistan',
      'Tanzania, United Republic of',
      'Thailand',
      'Timor-Leste',
      'Togo',
      'Tokelau',
      'Tonga',
      'Trinidad and Tobago',
      'Tunisia',
      'Turkey',
      'Turkmenistan',
      'Turks and Caicos Islands',
      'Tuvalu',
      'Uganda',
      'Ukraine',
      'United Arab Emirates',
      'United States Minor Outlying Islands',
      'Uruguay',
      'Uzbekistan',
      'Vanuatu',
      'Venezuela, Bolivarian Republic of',
      'Viet Nam',
      'Virgin Islands, British',
      'Virgin Islands, U.S.',
      'Wallis and Futuna',
      'Western Sahara',
      'Yemen',
      'Zambia',
      'Zimbabwe',
    ];
  }

  isBase64(encodedString) {
    const regexBase64 = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return regexBase64.test(encodedString);
  }

  checkToken = () => {
    const { location } = this.props;
    const { formData } = this.state;

    const values = queryString.parse(location.search);
    const token = values.token && values.token;
    const a = this.isBase64(token) && window.atob(token) * 1;
    if (!values || !token || !a || !Number.isInteger(a)) {
      this.setState({
        showForm: false,
        error: 'You do not have an invitation. Please ask for a new link.',
      });
    } else {
      formData.id = token;
      this.setState({
        showForm: true,
        formData,
      });
    }
  };

  componentDidMount() {
    this.checkToken();
    setTimeout(() => {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        rightBox: 2,
      });
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location.pathname !== location.pathname) {
      this.checkToken();
    }
  }

  listDevice() {
    const res = [];
    res.push(...this.Device.map((object, i) => (
      <option
        value={object}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {object}
      </option>
    )));
    return res;
  }

  listCountries() {
    const res = [];
    res.push(...this.Countries.map((object, i) => (
      <option
        value={object}
        // eslint-disable-next-line react/no-array-index-key
        key={i}
      >
        {object}
      </option>
    )));
    return res;
  }

  loadData = (obj, validated) => {
    const {
      yourWriting,
      formData,
    } = this.state;

    if (validated === true) {
      this.setState({
        error: false,
        loading: true,
      });
      // eslint-disable-next-line no-param-reassign
      obj.yourWriting = JSON.stringify(yourWriting);
      // eslint-disable-next-line no-param-reassign
      obj.userId = atob(formData.id);
      api.post('/v1/invites', obj)
        .catch((error) => {
          this.setState({
            error: error.response && error.response.data && error.response.data.error
              ? error.response.data.error
              : (
                <>
                  This email is already in use. If you’ve already signed up click
                  {' '}
                  <Link
                    to="/sign-in"
                  >
                    here
                  </Link>
                  {' '}
                  to log in, or
                  <Link
                    to="/#lost-password"
                  >
                    here
                  </Link>
                  {' '}
                  to reset your password.
                  <br />
                  <br />
                  If you think this is in error, contact
                  {' '}
                  <a href="mailto:support@dorian.live">support@dorian.live</a>
                </>
              ),
            loading: false,
          });
        })
        .then((res) => {
          if (res) {
            const { update } = this.props;
            update(true);
          }
        });
    }
  };

  handleSubmit = (event) => {
    const { formData } = this.state;

    event.preventDefault();
    const form = event.currentTarget;
    let validated = form.checkValidity();

    if (!formData.id) {
      validated = false;
    }
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.loadData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  };

  renderError() {
    const {
      error,
      loading,
    } = this.state;

    if (error) {
      return (
        <Alert
          show
          variant="danger"
        >
          {error}
        </Alert>
      );
    } if (loading) {
      return (
        <Alert
          show
          variant="success"
        >
          Loading...
        </Alert>
      );
    }
    return null;
  }

  handleChange = (event) => {
    const {
      formData,
      validated,
    } = this.state;

    formData[event.target.name] = event.target.value;
    if (event.target.name === 'email') {
      localStorage.setItem('signUpEmail', event.target.value);
    }

    this.setState({
      formData,
      error: '',
      validated: event.target.name === 'password' ? true : validated,
    });
  };

  render() {
    const { location } = this.props;
    const {
      device,
      terms,
      loading,
      showForm,
    } = this.state;

    if (location.pathname !== '/invite') {
      return null;
    }
    const { validated } = this.state;

    return (
      <>
        {this.renderError()}

        {
          showForm
          && (
          <Form
            noValidate
            onSubmit={this.handleSubmit}
            validated={validated}
            type="external"
          >
            <Form.Row>
              <Form.Group
                as={Col}
                md={6}
                controlId="formFirstName"
                className="mt-input"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="firstName"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid First Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md={6}
                controlId="formLastName"
                className="mt-input"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Last Name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md={12}
                controlId="formEmailAddress"
                className="mt-input"
              >
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  pattern="\S+@\S+\.\S{2,}"
                />
                <Form.Text className="text-muted">
                  NOTE: You will receive login code by email
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid Email Address.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                as={Col}
                md={12}
                controlId="formCountry"
                className="mt-input"
              >
                <Form.Label>Country of Residence</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="country"
                >
                  {this.listCountries()}
                </Form.Control>
              </Form.Group>

              <Form.Group
                as={Col}
                md={12}
                controlId="formEmailAddress"
                className="mt-input"
              >
                <Form.Label>Device</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="phone"
                  value={device || ''}
                  onChange={(e) => {
                    this.setState({
                      device: e.target.value,
                    });
                  }}
                >
                  {this.listDevice()}
                </Form.Control>
              </Form.Group>

              <Col md={12}>
                <Form.Check
                  custom
                  required
                  id="check_terms"
                  type="checkbox"
                  name="terms"
                  className="mt-3 mb-3"
                  checked={terms}
                  onChange={
                    () => {
                      this.setState({
                        terms: !terms,
                        // eslint-disable-next-line react/no-unused-state
                        showErrorAlert: '',
                      });
                    }
                  }
                >
                  <Form.Check.Input
                    required
                    type="checkbox"
                  />
                  <Form.Check.Label>
                    I agree to Dorian&apos;s
                    {' '}
                    <Link
                      to="/privacy-policy"
                      className="mainPageLinks"
                    >
                      Privacy Policy
                    </Link>
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid">
                    this field is required.
                  </Form.Control.Feedback>
                </Form.Check>
              </Col>
            </Form.Row>

            <Form.Row>
              <Col md={12} className="text-center">
                <Button
                  variant="outline-primary"
                  type="submit"
                  className="mt-4 btnMainPage"
                >
                  {loading
                  && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Register for Writer Access
                </Button>
              </Col>

              <Col md={12}>
                <p className="text-center pt-3 pb-5 mainPageLinks-nav">
                  <Link
                    className="mr-2 mainPageLinks"
                    to="/#lost-password"
                  >
                    Lost Password?
                  </Link>
                  <Link
                    className="ml-2 mainPageLinks"
                    to="/sign-in"
                  >
                    Sign in
                  </Link>
                </p>
              </Col>
            </Form.Row>

          </Form>
          )
        }
      </>
    );
  }
}
