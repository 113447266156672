import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Image, Modal, Row, Spinner,
} from 'react-bootstrap';
import imgNoImage from '../../../assets/images/no_image.svg';
import { api } from '../../api';
import '../Book/BookCover.css';
import { BookCoverUploader } from '../FileUploaders/BookCoverUploader/BookCoverUploader';

export class PromptCover extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: false,
      validatedImage: false,
      // eslint-disable-next-line react/no-unused-state
      formError: null,
      file: null,
      fileUrl: null,
      // eslint-disable-next-line react/no-unused-state
      uploadForm: false,
      uploadFormLoading: false,
      uploadDone: false,
      uploader: false,
      uploaderComplete: false,
    };
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.btnSaveForm = React.createRef();
    this.btnSaveImageForm = React.createRef();
    this.uploaderRef = React.createRef();
  }

  handleImageSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData();
    const { file } = this.state;

    formData.append('image', file, file.name);
    const {
      update,
      book,
    } = this.props;

    formData.append('prompt_id', book.id);
    this.setState({
      uploadFormLoading: true,
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.setState({
        loading: true,
      });
      api.post(`/v1/bookprompts/${book.id}/images/cover`, formData, config)
        .then(() => {
          update();
          this.setState({
            uploadFormLoading: false,
            // eslint-disable-next-line react/no-unused-state
            uploadForm: false,
            uploadDone: 'Upload Successful',
            loading: false,
            file: null,
            fileUrl: null,
          }, () => {
            setTimeout(() => {
              this.setState({
                uploadDone: false,
              });
            }, 1500);
          });
        })
        .catch(() => {
          this.setState({
            uploadFormLoading: false,
            // eslint-disable-next-line react/no-unused-state
            uploadForm: false,
            loading: false,
            file: null,
            fileUrl: null,
          });
        });
    }
    this.setState({
      validatedImage: true,
    });
    event.stopPropagation();
  }

  handleImageDelete(event) {
    event.preventDefault();
    this.setState({
      uploadFormLoading: true,
      loading: true,
    });
    const {
      update,
      book,
    } = this.props;

    api.delete(`/v1/bookprompts/${book.id}/images/cover`)
      .then(() => {
        update();
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          uploadForm: false,
          uploadFormLoading: false,
          uploadDone: 'Delete Successful',
          fileUrl: null,
          loading: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              uploadDone: false,
            });
          }, 1500);
        });
      })
      .catch(() => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          uploadForm: false,
          uploadFormLoading: false,
          loading: false,
        });
      });
    event.stopPropagation();
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onChangeFile(e) {
    const file = e.target.files[0];
    this.setState({
      file,
      fileUrl: (file && file.type.startsWith('image')) && URL.createObjectURL(file),
    });
  }

  onUploadDone = (image) => {
    this.setState({
      uploader: false,
      fileUrl: URL.createObjectURL(image),
      file: image,
    });
  };

  btnShowUploader = () => {
    this.setState({
      uploader: true,
      uploaderComplete: false,
    });
  };

  renderImgVariants = () => {
    const { book } = this.props;
    const { file } = this.state;

    if (!book || !book.image || !book.image.images || file) {
      return null;
    }
    const obj = book.image.images;
    const res = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const prop in obj) {
      res.push({ key: prop, url: obj[prop] });
    }

    return res.map((el) => (
      <span key={el.key} style={{ margin: '0 1em' }}>
        <a
          href={el.url}
        >
          {el.key}
        </a>
      </span>
    ));
  };

  render() {
    const {
      book, update, onHide, disabled, ...props
    } = this.props;

    const {
      loading,
      fileUrl,
      uploader,
      uploadFormLoading,
      file,
      uploadDone,
      uploaderComplete,
      validatedImage,
    } = this.state;

    const imgUrl = fileUrl || (book.image && book.image.imageUrl
      ? book.image.imageUrl
      : null);

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onMouseDown={(e) => e.stopPropagation()}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Form
          noValidate
          validated={validatedImage}
          onSubmit={(e) => this.handleImageSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Cover Prompt -
              {book.title}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body
            onMouseDown={(e) => e.stopPropagation()}
          >
            <Alert show={!!uploadDone} variant="success" className="my-1">
              {uploadDone || null}
            </Alert>

            {uploader
              && (
              <BookCoverUploader
                onDone={this.onUploadDone}
                ref={this.uploaderRef}
                onComplete={() => this.setState({ uploaderComplete: true })}
                book={book.originalBook}
              />
              )}

            {!uploader
              && (
              <>
                <Row>
                  <Col className="text-center">
                    <Image
                      onClick={() => {
                        this.setState({
                          // eslint-disable-next-line react/no-unused-state
                          uploadForm: true,
                        });
                      }}
                      className="imgCoverBook"
                      src={imgUrl || imgNoImage}
                      fluid
                    />
                  </Col>
                </Row>
                <Row>
                  {this.renderImgVariants()}
                </Row>
              </>
              )}

            <Spinner
              variant="primary"
              animation="border"
              className={uploadFormLoading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
            />
          </Modal.Body>
          <Modal.Footer>
            {uploader
              ? (
                <>
                  <Button
                    size="sm"
                    type="reset"
                    variant="secondary"
                    className="mx-2"
                    onClick={() => this.setState({ uploader: false })}
                  >
                    Cancel
                  </Button>

                  <Button
                    size="sm"
                    type="reset"
                    variant="secondary"
                    className="mx-2"
                    disabled={!uploaderComplete || disabled}
                    onClick={() => this.uploaderRef.current.doCropImg()}
                  >
                    Done
                  </Button>
                </>
              )
              : (
                <>
                  <Button
                    variant="primary"
                    disabled={(loading || disabled)}
                    size="sm"
                    onClick={this.btnShowUploader}
                  >
                    New Cover
                  </Button>

                  <Button
                    disabled={(!book.image || loading || disabled)}
                    size="sm"
                    type="reset"
                    variant="secondary"
                    className="mx-2"
                    onClick={(e) => this.handleImageDelete(e)}
                  >
                    Delete
                  </Button>

                  <Button
                    variant="primary"
                    disabled={(!file || !fileUrl || loading || disabled)}
                    size="sm"
                    type="submit"
                    ref={this.btnSaveImageForm}
                  >
                    Save
                  </Button>

                  <Button
                    size="sm"
                    type="reset"
                    variant="secondary"
                    className="mx-2"
                    disabled={loading}
                    onClick={onHide}
                  >
                    Cancel
                  </Button>
                </>
              )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
