import React, { useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';

export function PerformanceLongCalculations() {
  const performanceName = 'Performance of the long run function with 4sec.';

  const [isMeasured, setIsMeasured] = useState(false);

  const longRunFunc = (sleepDuration) => {
    const now = new Date().getTime();
    while (new Date().getTime() < now + sleepDuration) { /* Do nothing */ }
  };

  const handleThrowErrorClick = () => {
    const timeout = 4000;
    setIsMeasured(true);
    showToast({
      textMessage: 'The long run function has been started.',
      variant: 'info',
      timeout,
    });
    // We need use setTimeout to give time for the toast to be shown.
    setTimeout(() => {
      const transaction = bugTracker().startTransaction({ name: performanceName });
      longRunFunc(timeout);
      setIsMeasured(false);
      transaction.finish();
    }, 500);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>Measuring the performance of the long run function with 4sec.</Card.Title>
          <Alert variant="info">
            Transaction name:
            {' '}
            <b>{performanceName}</b>
          </Alert>
          <Button
            size="sm"
            variant="primary"
            disabled={isMeasured}
            onClick={handleThrowErrorClick}
            aria-controls="example-collapse-text"
          >
            Measure it
          </Button>
        </Card.Body>
      </Card>
      {isMeasured && (
      <div className="overlay" />
      )}
    </>
  );
}
