import maxBy from 'lodash-es/maxBy';
import React, {
  ReactElement, forwardRef, useEffect, useRef, useState,
} from 'react';
import Button from 'react-bootstrap/esm/Button';
import Dropdown from 'react-bootstrap/esm/Dropdown';
import FormControl from 'react-bootstrap/esm/FormControl';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import { filterAndSortChildren } from '../utils';

function getMinWidthByChildren(children: React.ReactNode[]) {
  if (children.length === 0) {
    return '25vw';
  }

  // Filter children to only include ReactElements
  const elements = children.filter((child): child is ReactElement => React.isValidElement(child));

  const childWithMaxLength = maxBy(elements, (childrenItem) => childrenItem?.props.children.length);
  const length = childWithMaxLength?.props.children.length ?? 1;
  const maxLength = length / 1.2;

  if (maxLength < 15) {
    return '15vw';
  }
  return `${maxLength < 40 ? maxLength : 40}vw`;
}

interface CustomMemoryIconMenuProps extends React.ComponentProps<typeof Dropdown.Toggle> {
  onAddNewClick: () => void;
}

export const CustomMemoryIconMenu = forwardRef((
  props: CustomMemoryIconMenuProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  CustomMemoryIconMenu.displayName = 'CustomMenu in character steps';

  const {
    children, style, className, 'aria-labelledby': labeledBy, onAddNewClick,
  } = props;

  const [value, setValue] = useState('');
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const minWidth = getMinWidthByChildren(React.Children.toArray(children));
  const filteredAndSortedChildren = filterAndSortChildren(React.Children.toArray(children), value);

  return (
    <div
      ref={ref}
      style={{
        ...style,
        minWidth,
      }}
      className={className}
      aria-labelledby={labeledBy}
    >
      <div className="mx-3 my-2" style={{ position: 'sticky', top: '2px' }}>
        <InputGroup>
          <FormControl
            ref={searchRef}
            placeholder="Type to filter ..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          />
          <InputGroup.Append>
            <Button
              variant="secondary"
              onClick={() => onAddNewClick()}
              className="ml-2 py-2"
              tabIndex={-1}
              style={{
                fontSize: '.7em',
                borderRadius: 0,
                padding: '0.25em',
              }}
            >
              Add New
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </div>
      <ul className="list-unstyled">
        {filteredAndSortedChildren}
      </ul>
      {filteredAndSortedChildren.length === 0 && (
        <div className="text-center">No results found</div>
      )}
    </div>
  );
});
