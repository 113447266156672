import serialize from 'form-serialize';
import React from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

const title = 'Edit Mail Template';

export function MailTemplatesEdit(props) {
  const { obj, update, onHide } = props;

  const [loading, setLoading] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [formError, setFormError] = React.useState(null);
  const [data, setData] = React.useState(obj);

  const errorAlert = (error) => {
    setFormError(error);
    setTimeout(() => {
      setFormError(null);
    }, 5000);
  };

  const loadData = () => {
    if (!loading && obj && obj.id) {
      setLoading(true);
      api.get(`/v1/mailtemplates/${obj.id}`)
        .then((res) => {
          if (res.data && res.data.template) {
            setData(res.data.template);
          }
          setLoading(false);
        })
        .catch((error) => {
          if (error && error.response && error.response.data && error.response.data.error) {
            errorAlert(error.response.data.error);
          }
          setLoading(false);
        });
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveData = (value) => {
    if (obj.id) {
      api.put(`/v1/mailtemplates/${obj.id}`, value)
        .then(() => {
          update();
        })
        .catch((error) => {
          if (error && error.response && error.response.data && error.response.data.error) {
            errorAlert(error.response.data.error);
          }
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const isValid = form.checkValidity();
    const serializedForm = serialize(form, { hash: true, empty: true });
    if (isValid === false) {
      event.stopPropagation();
    } else {
      saveData(serializedForm);
    }
    setValidated(true);
    event.stopPropagation();
  };

  return (
    <Modal
      {...props}
      size="lg"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
    >

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >

        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
            <Alert variant="danger">
              {formError}
            </Alert>
          </Col>

          {!loading && (
            <>
              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  value={data.title || ''}
                  onChange={(e) => {
                    setData({ ...data, title: e.target.value });
                  }}
                  name="title"
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Subject"
                  value={data.subject || ''}
                  onChange={(e) => {
                    setData({ ...data, subject: e.target.value });
                  }}
                  name="subject"
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} xs={12}>
                <Form.Control
                  required
                  placeholder="body"
                  as="textarea"
                  value={data.body || ''}
                  name="body"
                  onChange={(e) => {
                    setData({ ...data, body: e.target.value });
                  }}
                  rows="5"
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            disabled={loading}
            onClick={onHide}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="primary"
            disabled={loading}
          >
            Save
          </Button>

        </Modal.Footer>

      </Form>
    </Modal>
  );
}
