import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css';
import React, { useRef } from 'react';
import { Cropper, ReactCropperProps } from 'react-cropper';
import './circleCropper.css';

export type { Cropper } from 'react-cropper';

// eslint-disable-next-line max-len
const canvasToBlob = (canvas: HTMLCanvasElement, type = 'image/png', quality = 1) => new Promise<Blob>((resolve, reject) => {
  canvas.toBlob((blob) => {
    if (blob) {
      resolve(blob);
    } else {
      reject(new Error('Error: Blob is null'));
    }
  }, type, quality);
});

export type GetBlobOptions = Partial<Cropper.GetCroppedCanvasOptions> & {
  croppedImageType?: 'image/png' | 'image/jpeg'
};

export function useImageCropper() {
  const cropperRef = useRef<Cropper>();
  const handleCropperInitialized = (cropper: Cropper) => {
    cropperRef.current = cropper;
  };

  const getBlob = async (options: GetBlobOptions = {}) => {
    const { croppedImageType, ...getCroppedCanvasOptions } = options;
    const cropperInstance = cropperRef.current;
    if (cropperInstance) {
      const croppedCanvas = cropperInstance.getCroppedCanvas(getCroppedCanvasOptions);
      const blob = await canvasToBlob(croppedCanvas, croppedImageType);
      return blob;
    }
    return null;
  };

  const getDataURL = (options?: Partial<Cropper.GetCroppedCanvasOptions>) => {
    const cropperInstance = cropperRef.current;
    if (cropperInstance) {
      return cropperInstance.getCroppedCanvas(options).toDataURL();
    }
    return null;
  };

  return {
    handleCropperInitialized,
    getBlob,
    getDataURL,
  };
}

// eslint-disable-next-line no-shadow
export enum Variant {
    square = 'square',
    circle = 'circle'
}

export interface ImageCropperProps extends Omit<ReactCropperProps,
    'autoCropArea' |
    'center' |
    'toggleDragModeOnDblclick' |
    'checkOrientation' |
    'restore' |
    'ready'
    >{
    onCropReady?: ReactCropperProps['ready'];
    variant?: Variant
}

export function ImageCropper(props: ImageCropperProps) {
  const {
    onCropReady,
    variant = Variant.square,
    ...otherProps
  } = props;
  return (
    <Cropper
      className={classNames({ circle: variant === 'circle' })}
      ready={onCropReady}
      // props below copied from
      // authoring-tool-frontend/src/components/pages/Book/BookCoverUploader.js
      autoCropArea={1} // no crop by default
      minContainerHeight={250}
      minContainerWidth={250}
      minCropBoxHeight={250}
      center={false} // no crop center indicator
      toggleDragModeOnDblclick={false} // no change of crop mode with double click
      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
      restore={false} // don't restore the cropped area after resizing the window.
      viewMode={1} // restrict the crop box not to exceed the size of the canvas.
      {...otherProps}
    />
  );
}
