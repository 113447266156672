import { Form, Formik } from 'formik';
import React from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { UserLoginProvider } from '../../../dorian-shared/types/user/UserApi';
import { userApi } from '../../../services/userApi/UserApi';
import { Line } from '../../ui/Line/Line';
import { AppleSignInButton } from '../AppleSignInButton/AppleSignInButton';
import { FacebookSignInButton } from '../FacebookSignInButton/FacebookSignInButton';
import { GoogleSignInButton } from '../GoogleSignInButton/GoogleSignInButton';
import { InputFormField } from '../InputFormField/InputFormField';
import { INVALID_PASSWORD_MESSAGE } from '../SetPasswordForm/SetPasswordForm';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './SignUpForm.module.scss';
import { SubmitValues, useSignUpForm } from './useSignUpForm';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter a valid email.'),
  password: Yup.string()
    .required('Please enter a password.')
    .min(8, INVALID_PASSWORD_MESSAGE)
    .matches(/[a-z]/, INVALID_PASSWORD_MESSAGE)
    .matches(/[A-Z]/, INVALID_PASSWORD_MESSAGE)
    .matches(/\d/, INVALID_PASSWORD_MESSAGE)
    .matches(/[!@#$%^&*(),.?":{}|<>]/, INVALID_PASSWORD_MESSAGE),
  confirmPassword: Yup.string()
    .required('Please enter a password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match.  Please check and try again.'),
  agreeToPrivacyPolicy: Yup.boolean()
    .oneOf([true], 'Please accept the privacy policy in order to continue'),
});

interface SignUpFormProps {
  title?: string,
  textComponent?: React.ReactNode,
  initialValues?: Partial<SubmitValues>,
  submitButtonLabel?: string,
}

export function SignUpForm(props: SignUpFormProps) {
  const {
    title = 'Sign-Up',
    textComponent = null,
    initialValues,
    submitButtonLabel = 'Sign Up',
  } = props;

  const {
    isLoading,
    isError,
    statusMessage,
    handleSubmit,
  } = useSignUpForm();

  const initialEmptyValues: SubmitValues = {
    email: '',
    password: '',
    confirmPassword: '',
    agreeToPrivacyPolicy: false,
  };

  return (
    <Container>
      <Row>
        {isError && (
        <Alert variant="danger" className={styles.formAlert}>
          {statusMessage}
        </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <h3 className={styles.formTitle}>{title}</h3>
          {textComponent}
          <Formik
            initialValues={{ ...initialEmptyValues, ...initialValues }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} label="Email Address" name="email" type="email" />
              <InputFormField disabled={isLoading} label="Password" name="password" type="password" autoComplete="on" />
              <InputFormField disabled={isLoading} label="Confirm password" name="confirmPassword" type="password" autoComplete="on" />
              <InputFormField
                label="I agree to Dorian’s"
                name="agreeToPrivacyPolicy"
                type="checkbox"
              >
                <span className={styles.formSubText}>
                  <Link
                    to="/privacy-policy"
                  >
                    <span> Privacy Policy</span>
                  </Link>
                </span>
              </InputFormField>
              <Col className={styles.externalButtonsContainer}>
                <SubmitButton label={submitButtonLabel} isLoading={isLoading} className="w-100" />
              </Col>
            </Form>
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col className={styles.orSection}>
          <Line width="100%" height="1px" />
          or
          <Line width="100%" height="1px" />
        </Col>
      </Row>
      <Col className={styles.externalButtonsContainer}>
        <Row className={styles.externalButtons}>
          <AppleSignInButton
            disabled={isLoading}
            onClick={() => userApi.login(UserLoginProvider.Apple)}
          />
        </Row>
        <Row className={styles.externalButtons}>
          <GoogleSignInButton
            disabled={isLoading}
            onClick={() => userApi.login(UserLoginProvider.Google)}
          />
        </Row>
        <Row className={styles.externalButtons}>
          <FacebookSignInButton
            disabled={isLoading}
            onClick={() => userApi.login(UserLoginProvider.Facebook)}
          />
        </Row>
      </Col>
      <div className={styles.formSubText}>
        <span>Already have a Dorian account? </span>
        <Link
          to="/sign-in"
        >
          Sign in
        </Link>
      </div>
    </Container>
  );
}
