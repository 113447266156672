import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ExpressionConfig } from '../../../../dorian-shared/types/character/ExpressionConfig';

interface PossibleExpressionsModalProps {
    expressions: ExpressionConfig[];
}

export function PossibleExpressionsModal(props: PossibleExpressionsModalProps) {
  const { expressions } = props;
  const [showPossibleExpressions, setShowPossibleExpressions] = useState(false);

  const handlePossibleExpressionsClick = () => {
    setShowPossibleExpressions(!showPossibleExpressions);
  };

  return (
    <>
      <Button
        className="ml-auto mb-1"
        size="sm"
        variant="outline-primary"
        onClick={handlePossibleExpressionsClick}
      >
        Approved expressions
      </Button>
      <Modal
        show={showPossibleExpressions}
        onHide={handlePossibleExpressionsClick}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Approved expressions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="shadow-lg">
          {expressions.map((expression) => (
            <div key={expression.value} className="text-center">
              {expression.title}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}
