import { getAssetsImageURL } from './globalStaticData';

export const convertAssetsImageToURL = (path) => {
  const assetImageURL = getAssetsImageURL();
  if (typeof path === 'string') return path.replace('assets/img/', assetImageURL);
  return null;
};

export const convertURLToAssetsImage = (path) => {
  const assetImageURL = getAssetsImageURL();
  if (typeof path === 'string') return path.replace(assetImageURL, 'assets/img/');
  return null;
};

export const parseURLSearchParams = (search) => {
  const params = {};

  if (search) {
    search
      .slice(1)
      .split('&')
      .forEach((param) => {
        const [key, value] = param.split('=');
        params[key] = value;
      });
  }
  return params;
};
