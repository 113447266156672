import React, { Component } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { api } from '../../api';

export class Export extends Component {
  static exportBook(id, title) {
    // TODO: https://dorian.atlassian.net/browse/DOR-3171
    ReactDOM.render(
      <Alert variant="info" className="mx-3 my-3">
        <Spinner animation="border" size="sm" className="mr-3" />
        please wait, export file is loading
      </Alert>,
      document.getElementById('errorsBox'),
    );
    return new Promise((resolve, reject) => {
      api.get(`/v1/books/${id}/export`, { responseType: 'blob' })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `Basil_Book_${title}_${+new Date()}.zip`;
          link.setAttribute('download', fileName.replace(/ /ig, '_'));
          document.body.appendChild(link);

          if (document.getElementById('errorsBox')) {
            ReactDOM.render(null, document.getElementById('errorsBox'));
          }

          link.click();
          link.remove();
          resolve(res);
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 401) {
            window.location.assign('/logout');
          }
          reject(error);
        });
    });
  }

  static exportStory(id, title) {
    api.get(`/v1/stories/${id}/export`, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        const fileName = `Basil_Story_${title}_${+new Date()}.json`;
        link.setAttribute('download', fileName.replace(/ /ig, '_'));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        if (error.response && error.response.status && error.response.status === 401) {
          window.location.assign('/logout');
        }
      });
  }
}
