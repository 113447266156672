import React from 'react';
import { Card, Row } from 'react-bootstrap';
import classNames from './StreamTest.module.scss';

export function StreamTest() {
  return (
    <div>
      <h2>Stream testing section</h2>
      <Card>
        <Card.Body>
          <Row className={classNames.streamRow}>
            <h4>Not implemented</h4>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}
