import React from 'react';
import { Form } from 'react-bootstrap';
import { Character } from '../../../../../dorian-shared/types/character/Character';
import { MemoryType, MemoryValue } from '../memoryBankTypes';
import classes from './MemoryField.module.scss';

interface MemoryValueFieldBase extends React.ComponentProps<typeof Form.Control> {
    id: string;
    value: MemoryValue;
}

interface MemoryValueFieldWithCharacters extends MemoryValueFieldBase {
    type: MemoryType.Character;
    characters: Character[] | null;
}

interface MemoryValueFieldW extends MemoryValueFieldBase {
    type: Exclude<MemoryType, MemoryType.Character>;
    characters: Character[] | null;
}

export type MemoryValueFieldProps = MemoryValueFieldWithCharacters | MemoryValueFieldW;

export function MemoryValueField(props: MemoryValueFieldProps) {
  const {
    type,
    id,
    value,
    characters,
    errorMessage,
    ...rest
  } = props;
  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Value
      </Form.Label>
      {(type === MemoryType.String || type === MemoryType.Number)
          && (
          <Form.Control
            id={id}
            type={type === MemoryType.Number ? 'number' : 'text'}
            value={value}
            placeholder={type === MemoryType.Number ? 'Enter number' : 'Enter string'}
            {...rest}
          />
          )}
      {type === MemoryType.Boolean
            && (
            <Form.Control
              as="select"
              required
              id={id}
              value={value}
              {...rest}
            >
              <option value="" disabled>[select default]</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </Form.Control>
            )}
      {type === MemoryType.Character
            && (
            <Form.Control
              as="select"
              required
              id={id}
              value={value}
              {...rest}
            >
              {!characters
                ? <option value="loading">loading...</option>
                : (
                  <>
                    <option value="" disabled>[select default]</option>
                    {characters.map((character) => (
                      <option value={character.id} key={character.id}>
                        {character.name}
                      </option>
                    ))}
                  </>
                )}
            </Form.Control>
            )}
      <Form.Control.Feedback type="invalid" tooltip>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
