import React, { Component } from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import imgDelete from '../../../assets/images/delete.svg';
import imgEditStory from '../../../assets/images/edit-story.png';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { Paginator } from '../../ui/Paginator';
import { DeleteArticle } from './DeleteArticle';
import { EditArticle } from './EditArticle';
import './style.scss';

export class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      limit: 10,
      totalCount: 0,
      editArticle: null,
      deleteArticle: null,
    };

    // eslint-disable-next-line react/destructuring-assignment
    this.user = this.props.auth.getUser();
  }

  componentDidMount() {
    this.loadData();
  }

  initAddArticle = () => {
    this.setState({
      editArticle: [],
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  editArticle = (obj) => {
    this.setState({
      editArticle: obj,
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  deleteArticle = (obj) => {
    this.setState({
      deleteArticle: obj,
    });
  };

  loadData() {
    // eslint-disable-next-line max-len, react/destructuring-assignment
    const offset = Math.ceil(this.state.limit * Number(this.props.match.params.id ? this.props.match.params.id : 1)) - this.state.limit;
    // eslint-disable-next-line react/destructuring-assignment
    api.get(`v1/blog/posts?&offset=${offset}&limit=${this.state.limit}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.posts,
          totalCount: res.data.totalCount,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const page = {
      header: {
        title: 'Articles',
        settings: this.user.role === 'admin' ? 'admin' : null,
      },
      sidebar: {
        nav: [
          {
            title: 'Back to Stories List',
            action: '',
            href: '/books',
            variant: 'secondary',
            disabled: false,
          },
          (this.user.role === 'admin')
          && {
            title: 'Add New Article',
            action: () => this.initAddArticle(),
            variant: 'primary',
            // eslint-disable-next-line react/destructuring-assignment
            disabled: this.state.loading || this.user.role !== 'admin',
          },
        ],
      },
    };

    const addArticle = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.editArticle) {
        return (
          <EditArticle
            show
            user={this.user}
            onHide={() => {
              this.setState({
                editArticle: null,
              });
            }}
            // eslint-disable-next-line react/destructuring-assignment
            obj={this.state.editArticle}
            update={() => {
              this.setState({
                editArticle: null,
              }, () => {
                this.loadData();
              });
            }}
          />
        );
      }
      return null;
    };

    const deleteArticle = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.deleteArticle) {
        return (
          <DeleteArticle
            {...this.props}
            show
            onHide={() => {
              this.setState({
                deleteArticle: null,
              });
            }}
            // eslint-disable-next-line react/destructuring-assignment
            obj={this.state.deleteArticle}
            update={() => {
              this.setState({
                deleteArticle: null,
              });
              this.loadData();
            }}
          />
        );
      }
      return null;
    };

    return (
      <>
        <PageWrapper
          {...this.props}
          page={page}
        >
          {
            // eslint-disable-next-line react/destructuring-assignment
            !this.state.loading
              // eslint-disable-next-line react/destructuring-assignment
              ? this.state.data.map((obj) => (
                <Card key={obj.id} className="my-3">
                  <Card.Title className="px-3 pt-3">
                    <Row>
                      <Col md={10} className="articles-box">
                        <h3>
                          <Link to={`/article/${obj.id}`}>{obj.title}</Link>
                        </h3>

                        <div className="text-content">
                          {
                            obj.body && obj.body
                          }
                          {
                            obj.body
                            && <>...</>
                          }
                        </div>
                      </Col>
                      {this.user.role === 'admin'
                        ? (
                          <Col className="justify-content-end text-right">
                            <span className="tooltip-box">
                              <Button
                                variant="secondary"
                                size="sm"
                                className="mx-1"
                                onClick={() => {
                                  this.setState({
                                    editArticle: obj,
                                  });
                                }}
                              >
                                <img src={imgEditStory} className="btnImg" alt="Edit" />
                              </Button>
                              <div className="tooltip">
                                <div className="arrow" />
                                <div className="tooltip-inner">Edit</div>
                              </div>
                            </span>

                            <span className="tooltip-box">
                              <Button
                                variant="secondary"
                                size="sm"
                                className="mx-1"
                                onClick={() => {
                                  this.setState({
                                    deleteArticle: obj,
                                  });
                                }}
                              >
                                <img src={imgDelete} className="btnImg" alt="Delete" />
                              </Button>
                              <div className="tooltip">
                                <div className="arrow" />
                                <div className="tooltip-inner">Delete</div>
                              </div>
                            </span>
                          </Col>
                        )
                        : null}
                    </Row>
                  </Card.Title>
                  <Card.Footer>
                    <Row>
                      <Col />
                      <Col className="justify-content-end text-right">
                        <Moment
                          element="span"
                          format="YYYY-MM-DD hh:mm A"
                          date={obj.createdAt}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </Card>
              ))
              : null
          }

          <Paginator
            // eslint-disable-next-line react/destructuring-assignment
            limit={this.state.limit}
            // eslint-disable-next-line react/destructuring-assignment
            totalCount={this.state.totalCount}
            // eslint-disable-next-line react/destructuring-assignment
            current_page={Number(this.props.match.params.id ? this.props.match.params.id : 1)}
            update={(val, number) => {
              window.location.href = `/articles/page/${number}`;
            }}
          />
        </PageWrapper>

        {addArticle()}
        {deleteArticle()}
      </>
    );
  }
}
