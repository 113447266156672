import { BugTrackerType, IFrontendBugTrackerConfig, createFrontendBugTracker } from '@dorian/frontend-bug-tracker';
import axios from 'axios';
// z@ts-ignore: no def file
import { isEqual } from 'lodash-es';
import { getPorpoiseSubdomain } from '../../components/api';
import { getRuntimeEnv } from '../../helpers/runtimeEnv';
import { logger } from '../loggerService/loggerService';

export const bugTrackerConfig = {
  bugTrackerType: BugTrackerType.Console,
  init: {
    environment: getRuntimeEnv(),
    // Low sample rates in case we use remote bug tracking and need to manage quota
    // Manually increase as needed for development when using local bug tracking
    tracesSampleRate: 0.001,
    sampleRate: 0.001,
    replaysSessionSampleRate: 0.0001,
    replaysOnErrorSampleRate: 0.01,
  },
};

class BugTrackerService {
  private bugTrackerService;

  private bugTrackerConfig: IFrontendBugTrackerConfig;

  constructor() {
    this.bugTrackerService = createFrontendBugTracker(bugTrackerConfig.bugTrackerType);
    this.bugTrackerConfig = bugTrackerConfig;
    this.fetchBugTrackerParams().then((config) => {
      if (
        config?.bugTrackerType
          && config.bugTrackerType !== this.bugTrackerConfig.bugTrackerType
      ) {
        this.initBugTrackerWithConfig(config);
      }
    });
    logger.info('[AT] Instantiate bug tracker service');
  }

  public getBugTrackerService() {
    return this.bugTrackerService;
  }

  public async getBugTrackerConfig() {
    const config = await this.fetchBugTrackerParams();
    return config;
  }

  public changeBugTracker(type: BugTrackerType) {
    this.bugTrackerService = createFrontendBugTracker(type);
    logger.info('[AT] Change bug tracker service to ', type);
  }

  private initBugTrackerWithConfig(config: IFrontendBugTrackerConfig | undefined) {
    const release = process.env.REACT_APP_RELEASE ?? '';
    const environment = getRuntimeEnv();

    if (isEqual(config, this.bugTrackerConfig)) {
      logger.info('[AT] Bug tracker config is the same');
      return;
    }
    if (config?.bugTrackerType) {
      this.changeBugTracker(config.bugTrackerType);
      const oldBugTrackerConfig = this.bugTrackerService.getConfig();
      const newBugTrackerConfig: IFrontendBugTrackerConfig = {
        ...oldBugTrackerConfig,
        init: {
          environment,
          ...config.init,
        },
      };
      this.bugTrackerService.init(release, newBugTrackerConfig);
      this.bugTrackerConfig = config;
      logger.info('[AT] Init bug tracker service');
    }
  }

  private async fetchBugTrackerParams() : Promise<IFrontendBugTrackerConfig | undefined> {
    const env = `${getRuntimeEnv()}`.toLowerCase();
    const envHost = getPorpoiseSubdomain('env');
    try {
      const response = await axios.get(`${envHost}/api/settings/?client=editor&env=${env}`);
      const { bugTracker } = response.data.data.config;
      const config: IFrontendBugTrackerConfig = {
        ...this.bugTrackerConfig,
        ...bugTracker,
      };
      logger.info('[AT] Fetch remote bug tracker config');
      return config;
    } catch (err) {
      logger.error('Error parsing bug tracker config.', err);
    }
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async updateBugTrackerConfig(config: IFrontendBugTrackerConfig) {
    throw Error('Not implemented');
  }
}

const bugTrackerInstance = new BugTrackerService();

const bugTracker = () => {
  if (bugTrackerInstance === undefined) {
    throw new Error('Instance not instantiated');
  }
  return bugTrackerInstance.getBugTrackerService();
};

export { bugTracker, bugTrackerInstance };
