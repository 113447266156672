import { useCallback, useMemo, useState } from 'react';

// eslint-disable-next-line no-shadow
export enum RequestState {
    None = 'None',
    Loading = 'Loading',
    Success = 'Success',
    Error = 'Error',
}

export function useAsyncOperationState() {
  const [requestState, setRequestState] = useState(RequestState.None);
  const [statusMessage, setStatusMessage] = useState('');

  const setToLoading = useCallback(
    (message?: string) => {
      setRequestState(RequestState.Loading);
      setStatusMessage(message ?? '');
    },
    [],
  );

  const setToError = useCallback(
    (message?: string) => {
      setRequestState(RequestState.Error);
      setStatusMessage(message ?? '');
    },
    [],
  );

  const setToSuccess = useCallback(
    (message?: string) => {
      setRequestState(RequestState.Success);
      setStatusMessage(message ?? '');
    },
    [],
  );

  const isSuccess = useMemo(() => requestState === RequestState.Success, [requestState]);
  const isError = useMemo(() => requestState === RequestState.Error, [requestState]);
  const isLoading = useMemo(() => requestState === RequestState.Loading, [requestState]);

  return [requestState, {
    isSuccess,
    isError,
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
    statusMessage: statusMessage ?? '',
  }] as const;
}

export function combineAsyncOperationStates(states: RequestState[]) {
  return states.reduce((accumulatedState, currentState) => {
    if (accumulatedState === RequestState.Error || currentState === RequestState.Error) {
      return RequestState.Error;
    }
    if (accumulatedState === RequestState.Loading || currentState === RequestState.Loading) {
      return RequestState.Loading;
    }
    if (accumulatedState === RequestState.None || currentState === RequestState.None) {
      return RequestState.None;
    }
    return RequestState.Success;
  });
}
