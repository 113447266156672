import React, { PureComponent } from 'react';
import { Button, Row } from 'react-bootstrap';

export class Zoom extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.zoom = [1, 0.75, 0.5, 0.25];
  }

  render() {
    const { update, branchesScale } = this.props;
    return (
      <>
        <div>Zoom:</div>
        <Row className="py-2 justify-content-center" style={{ flexWrap: 'nowrap' }}>
          {this.zoom.map((object, i) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              size="sm"
              className="mr-1"
              variant={branchesScale === object ? 'secondary' : 'primary'}
              onClick={() => update(object)}
            >
              x
              {object}
            </Button>
          ))}
        </Row>
      </>
    );
  }
}
