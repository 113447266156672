import React, { Component } from 'react';
import { PageWrapper } from '../../ui/PageWrapper';
import { Settings } from '../Settings';

export class SettingsTextLimits extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Text Limits',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  render() {
    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Settings sections={['limits']} />
      </PageWrapper>
    );
  }
}
