import React from 'react';
import { BranchAnswerAnalytics, BranchStepAnalytics } from 'dorian-shared/types/branch/BranchAnalytics';
import classNames from './BranchAnalytics.module.scss';

export type BranchAnalyticsProps = {
  steps: [BranchStepAnalytics]
}

export function BranchAnalytics(props: BranchAnalyticsProps) {
  const { steps } = props;

  const answers = steps[0]?.answers;

  return (
    <div>
      {answers
        ? answers.map((answer: BranchAnswerAnalytics) => (
          <div key={answer.answer}>
            {`Answer ${answer.answer}:`}
            <div>
              <span className={classNames.heartsTitle}>
                ♥
              </span>
              <span className={classNames.heartsValue}>
                {`: ${answer.hearts},`}
              </span>
              <span className={classNames.usersTitle}>
                users
              </span>
              {`:  ${answer.users} (${answer.percent_users}%)`}
            </div>
          </div>
        ))
        : steps.map((step: BranchStepAnalytics) => (
          <div key={step.step}>
            {`Step ${step.step?.toString().padStart(2, '0')}:`}
            <span className={classNames.stepTitle}>
              drop off
            </span>
            {`: ${step.dropoff}%`}
          </div>
        ))}
    </div>
  );
}
