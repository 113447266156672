import React from 'react';
import { Form } from 'react-bootstrap';
import classes from './MemoryField.module.scss';

export function MemoryDisplayNameField(props: React.ComponentProps<typeof Form.Control>) {
  const {
    id,
    value = '',
    disabled,
    placeholder = disabled ? '-' : '',
    errorMessage,
    ...rest
  } = props;

  return (
    <Form.Group className="position-relative">
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
      >
        Display Name
      </Form.Label>
      {disabled
        ? '-'
        : (
          <Form.Control
            id={id}
            type="text"
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            {...rest}
          />
        )}
      <Form.Control.Feedback type="invalid" tooltip>
        {errorMessage}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
