import { BookCreateLink } from '../pages/BookCreateLink';
import { ConfirmTerm } from '../pages/Home/ConfirmTerm';
import { PrivacyPolicyPage } from '../pages/Home/PrivacyPolicy';
import { CopyrightPolicyPage } from '../pages/Home/PrivacyPolicy/CopyrightPolicyPage';
import { TermsOfUsePage } from '../pages/Home/PrivacyPolicy/TermsOfUsePage';
import { SignUp } from '../pages/Logins/SignUp/SignUp';

export const routesConfirmTerm = [
  {
    path: '/:id',
    component: ConfirmTerm,
  },
  {
    path: '/',
    exact: true,
    component: ConfirmTerm,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: '/terms-of-use',
    component: TermsOfUsePage,
  },
  {
    path: '/copyright-policy',
    component: CopyrightPolicyPage,
  },
  {
    path: '/write-with-us-app',
    component: SignUp,
  },
  {
    path: '/create/template/:id',
    component: BookCreateLink,
  },
];
