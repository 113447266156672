import React from 'react';
import { ProvideNameForm } from '../../../uiV2/ProvideNameForm/ProvideNameForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function ProvideName() {
  return (
    <LoginLayout>
      <ProvideNameForm />
    </LoginLayout>
  );
}
