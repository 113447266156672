import cs from 'classnames';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { ButtonProps } from 'react-bootstrap/Button';
import { AppleLogo } from './AppleLogo';
import styles from './AppleSignInButton.module.scss';

export function AppleSignInButton({
  className, ...props
} : ButtonProps) {
  return (
    <div className={styles.container}>
      <Button
        className={cs(styles.submitButton, className)}
        variant="default"
        type="submit"
        {...props}
      >
        <Row className={styles.content}>
          <Col xs="auto" className={styles.logoWrapper}>
            <AppleLogo />
          </Col>
          <Col>
            Sign in with Apple
          </Col>
        </Row>
      </Button>
    </div>
  );
}
