import React, { Component } from 'react';
import {
  Col, Form, Row, Tab, Tabs,
} from 'react-bootstrap';
import { Auth } from '../../Auth/Auth';
import { PageWrapper } from '../../ui/PageWrapper';
import { ChaptersPerformanceTabContent } from './ChaptersPerformanceTabContent/ChaptersPerformanceTabContent';
import { ChoicesPerformanceTabContent } from './ChoicesPerformanceTabContent/ChoicesPerformanceTabContent';
import { CompletionPerformanceTabContent } from './CompletionPerformanceTabContent/CompletionPerformanceTabContent';
import {
  HeartsTopStoriesPerformanceTabContent,
} from './HeartsTopStoriesPerformanceTabContent/HeartsTopStoriesPerformanceTabContent';
import { MonetizationPerformanceTabContent } from './MonetizationPerformanceTabContent/MonetizationPerformanceTabContent';
import {
  MonetizationTopStoriesPerformanceTabContent,
} from './MonetizationTopStoriesPerformanceTabContent/MonetizationTopStoriesPerformanceTabContent';
import { NodesPerformanceTabContent } from './NodesPerformanceTabContent/NodesPerformanceTabContent';
import { PerformanceTabs } from './performanceUtils';
import './style.scss';

const auth = new Auth();
const initialPageSize = 10;

export class BookPerformance extends Component {
  constructor(props) {
    super(props);
    const {
      history,
      location,
    } = this.props;

    this.user = auth.getUser();
    const urlSearchParams = new URLSearchParams(location.search);
    if (!urlSearchParams.has('tabs')) {
      history.replace(`${location.pathname}?tabs=chapters`);
    }

    this.state = {
      pageSize: initialPageSize,
      isLatestVersion: false,
    };

    this.page = {
      header: {
        title: 'Story Performance',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== location.href
              ? () => {
                history.goBack();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  render() {
    const { location, history } = this.props;
    const {
      isLatestVersion,
      pageSize,
    } = this.state;

    const activeTab = new URLSearchParams(location.search).get('tabs') ?? PerformanceTabs.chapters;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        {this.user.role === 'admin' && (
          <Row className="py-3">
            <Col md={3}>
              <Form.Check
                custom
                id="checkboxLatest"
                name="latest"
                type="checkbox"
                checked={isLatestVersion}
                onChange={() => this.setState({ isLatestVersion: !isLatestVersion })}
                label="Latest live version"
              />
            </Col>
          </Row>
        )}
        <Tabs
          id="controlled-tab-example"
          activeKey={activeTab}
          onSelect={(selectedTab) => {
            history.replace({ search: `?tabs=${selectedTab}` });
          }}
        >
          <Tab eventKey={PerformanceTabs.Chapters} title="Chapters">
            {activeTab === PerformanceTabs.Chapters && (
              <ChaptersPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.Choices} title="Choices">
            {activeTab === PerformanceTabs.Choices && (
              <ChoicesPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.Nodes} title="Nodes">
            {activeTab === PerformanceTabs.Nodes && (
              <NodesPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.Monetization} title="Monetization">
            {activeTab === PerformanceTabs.Monetization && (
              <MonetizationPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.Completion} title="Completion">
            {activeTab === PerformanceTabs.Completion && (
              <CompletionPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.MonetizationTopStories} title="Daily Starts">
            {activeTab === PerformanceTabs.MonetizationTopStories && (
              <MonetizationTopStoriesPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
          <Tab eventKey={PerformanceTabs.HeartsTopStories} title="Daily Hearts">
            {activeTab === PerformanceTabs.HeartsTopStories && (
              <HeartsTopStoriesPerformanceTabContent
                pageSize={pageSize}
                activeTab={activeTab}
              />
            )}
          </Tab>
        </Tabs>
      </PageWrapper>
    );
  }
}
