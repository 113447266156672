import React, { Component } from 'react';
import {
  Button, Dropdown, Form, FormControl, InputGroup,
} from 'react-bootstrap';
import { maxTagNumber } from '../../../../dorian-shared/model-related-helpers/story/validateStoryTags';
import { api } from '../../../api';
import { Auth } from '../../../Auth/Auth';
import { getTagGroupPrefix } from './getTagGroupPrefix';

const auth = new Auth();

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    variant="secondary"
    className="form-control form-control-sm text-left border arr-right addTagButton"
    onMouseDown={(e) => e.stopPropagation()}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const CustomMenu = React.forwardRef(
  ({
    children, style, className, 'aria-labelledby': labeledBy, addTag,
  }, ref) => {
    const user = auth.getUser();
    const [value, setValue] = React.useState('');

    const list = React.Children.toArray(children).filter(
      (child) => !value || child.props.children.toLowerCase().startsWith(value),
    );

    return (
      <div
        ref={ref}
        style={{
          ...style,
          minWidth: '25vw',
          width: '25vw !important',
        }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className="mx-3 my-2">
          <InputGroup>
            <FormControl
              autoFocus
              placeholder="Type to filter Tags..."
              onChange={(e) => setValue(e.target.value)}
              value={value}
            />
            {
              ((list.length < 1) && (user.role === 'admin'))
              && (
              <InputGroup.Append>
                <Button
                  variant="secondary"
                  onClick={() => addTag(value)}
                  style={{
                    fontSize: '.7em',
                    borderRadius: 0,
                    padding: '0.25em',
                  }}
                >
                  Add New
                </Button>
              </InputGroup.Append>
              )
            }
          </InputGroup>
        </div>
        <ul className="list-unstyled">
          {list}
        </ul>
      </div>
    );
  },
);

export class BookTags extends Component {
  constructor(props) {
    super(props);
    const { tags } = this.props;

    this.state = {
      tags: tags || [],
      savedTags: [],
      // eslint-disable-next-line react/no-unused-state
      loading: false,
    };
    this.tagLimit = maxTagNumber;
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      tags: nextProps.tags,
    };
  }

  componentDidMount() {
    this.loadTags();
  }

  loadTags = () => {
    api.get('/v1/booktags')
      .then((res) => {
        this.setState({
          savedTags: res.data.tags,
        });
      })
      .catch(() => {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          loading: false,
        });
      });
  };

  // eslint-disable-next-line react/sort-comp
  activeTagsList() {
    const { tags } = this.state;
    tags.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
    if (tags.length < 1) {
      return (
        <Form.Text className="text-muted px-3 py-2">
          No tags added
        </Form.Text>
      );
    }
    return tags.map((tagTitle, i) => {
      if ((i + 1) > this.tagLimit) {
        return null;
      }
      const { disabled } = this.props;

      return (
        // eslint-disable-next-line react/no-array-index-key
        <Form.Check type="checkbox" key={i} className="tagItem">
          <Form.Check.Label>{tagTitle}</Form.Check.Label>
          <Button
            variant="secondary"
            size="sm"
            className="close"
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
            <Form.Check.Input
              name="tags[]"
              type="checkbox"
              disabled={!!disabled || (tagTitle === 'prompt')}
              checked
              value={tagTitle}
              onChange={() => {
                this.deleteTag(i);
              }}
            />
          </Button>
        </Form.Check>
      );
    });
  }

  deleteTag(i) {
    const { tags } = this.state;
    tags.splice(i, 1);
    this.setState({
      tags,
    });
  }

  addTag = (title) => {
    const { tags } = this.state;
    tags.push(title);
    this.setState({
      tags,
    });
  };

  tagOptions() {
    const {
      tags,
      savedTags,
    } = this.state;

    return savedTags
      .sort((tag1, tag2) => (tag1.groupId - tag2.groupId > 0 ? -1 : 1))
      .map((tag) => {
        const tagTitle = tag.title || '';
        const disabled = tags.find((el) => el === tag.title);

        const tagGroupPrefix = getTagGroupPrefix(tag.groupId);
        return (
          <Dropdown.Item
            key={tag.id}
            className={disabled ? 'd-none' : ''}
            as="button"
            eventKey={tagTitle}
            onClick={() => {
              this.addTag(tagTitle);
            }}
          >
            {`${tagGroupPrefix}${tagTitle}`}
          </Dropdown.Item>
        );
      });
  }

  render() {
    const { tags } = this.state;

    const { disabled } = this.props;

    return (
      <div className="pt-2">
        <Form.Label>
          Tags - Select one style tag,
          {' '}
          up to 3 main character tags, up to 2 love interest tags and up to 5 genre tags
        </Form.Label>
        <div className="tagsBox">
          {this.activeTagsList()}
          {
            (tags.length < this.tagLimit)
            && (
            <Dropdown
              disabled
              className="w-auto CustomSelect"
              onSelect={(e, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              <Dropdown.Toggle
                id="tag"
                as={CustomToggle}
                className="w-100"
                disabled={!!disabled}
              >
                Add Tag
              </Dropdown.Toggle>
              <Dropdown.Menu
                as={CustomMenu}
                loadTags={this.loadTags}
                addTag={this.addTag}
                className="w-100 mw-75 overflow-hidden"
              >
                {this.tagOptions()}
              </Dropdown.Menu>
            </Dropdown>
            )
          }
        </div>
      </div>
    );
  }
}

CustomToggle.displayName = 'CustomToggle in BookTags';
CustomMenu.displayName = 'CustomMenu in BookTags';
