import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { HorizontalSpace } from '../../ui/_spacing/HorizontalSpace';
import { VerticalSpace } from '../../ui/_spacing/VerticalSpace';
import { SelectAsyncPaginate } from '../../ui/SelectAsyncPaginate/SelectAsyncPaginate';
// eslint-disable-next-line import/no-cycle
import { getUserName } from '.';

export class Merge extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoading: false,
      error: null,
      userIdToMerge: '',
    };
  }

  searchUsers = async (searchString, page = 1) => {
    const { data } = await api.get('/v1/users', {
      params: {
        search: searchString,
        limit: 5,
        offset: 5 * (page - 1),
      },
    });
    return { options: data.users, totalCount: data.totalCount };
  };

  mergeAction() {
    const { obj, onHide } = this.props;
    const { userIdToMerge } = this.state;

    this.setState({ isLoading: true });
    api.post('/v1/users/merge', { userId: obj.id, withUserId: userIdToMerge })
      .then(() => {
        onHide(true);
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error: error.response.data.error,
        });
      });
  }

  render() {
    const { obj, onHide } = this.props;
    const { error, userIdToMerge, isLoading } = this.state;

    const title = obj && obj.id ? `Merge ${getUserName(obj)}` : 'Merge user';

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error
            ? (
              <Alert variant="danger">
                {error}
              </Alert>
            )
            : (
              <div className="text-center">
                Select user to merge with.
                {' '}
                Selected user will be deactivated. Action cannot be undone
                <VerticalSpace />
                <SelectAsyncPaginate
                  placeholder="Find user by name or email..."
                  showPerPage={5}
                  labelKey={(option) => `${option.fullname}`}
                  onSearch={this.searchUsers}
                  onSelect={(item) => this.setState({ userIdToMerge: item[0].id })}
                />
              </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={() => onHide()}
          >
            Cancel
          </Button>
          <Button
            disabled={(error !== null) || !userIdToMerge}
            type="submit"
            variant="primary"
            onClick={() => this.mergeAction()}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            <HorizontalSpace />
            Merge
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
