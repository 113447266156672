import { useCallback, useEffect, useRef } from 'react';

export const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>();

  useEffect(
    () => {
      abortControllerRef.current = new AbortController();
      return () => {
        abortControllerRef.current?.abort();
      };
    },
    [],
  );

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  const getAbortSignal = useCallback(() => getAbortController().signal, [getAbortController]);

  const sendAbortSignal = useCallback(
    (message: string) => getAbortController().abort(message),
    [getAbortController],
  );

  const newAbortSignal = useCallback(() => {
    getAbortController().abort();
    abortControllerRef.current = new AbortController();
  }, [getAbortController]);

  return {
    sendAbortSignal,
    getAbortSignal,
    newAbortSignal,
    getAbortController,
  };
};
