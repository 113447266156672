import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';

const displayLabels = {
  limits: 'Text limits',
  limits_branch_title_max: 'Node Title',
  limits_branch_description_max: 'Node Description',
  limits_answer_text_max: 'Answer Text',
  limits_character_name_max: 'Character Name',
  limits_scene_title_max: 'Location Title',
  limits_data_key_max: 'Data Key',
  limits_data_value_max: 'Data Value',
  limits_tag_value_max: 'Tag Value',
  limits_story_title_max: 'Episode Title',
  limits_story_author_max: 'Episode Author',
  limits_story_summary_max: 'Episode Description',
  limits_step_1_text_max: 'Dialogue Text',
  limits_step_2_text_max: 'Narrator Text',
  limits_step_3_text_max: 'Choice Text',
  limits_step_5_text_max: 'Ending Text',
  limits_step_6_text_max: 'Results Text',
  limits_step_7_text_max: 'PlayerSelect Text',
  limits_step_8_text_max: 'Texting Text',
  limits_step_9_text_max: 'Thinking Text',
  limits_book_title_max: 'Story Title',
  limits_story_temlate_title_max: 'Story Prompt Title',
  limits_story_temlate_description_max: 'Story Prompt Description',
  preferences: 'Preferences',
  preferences_show_character_image_path: 'Show character image path',
};

function getDisplayLabel(key) {
  // eslint-disable-next-line no-prototype-builtins
  if (displayLabels.hasOwnProperty(key)) {
    return displayLabels[key];
  }
  return key;
}

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: {},
      alertText: null,
      success: false,
      validated: false,
      loading: true,
    };
  }

  static renderSection(sect, data) {
    const ret = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const dataKey in data) {
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty(dataKey)) {
        const value = data[dataKey];
        // TODO: 1 is text type. Other type not yet supported
        if (value.type === 1) {
          ret.push(
            <Form.Group key={dataKey} as={Row} controlId={dataKey}>
              <Form.Label column sm="5" className="text-right">{getDisplayLabel(`${sect}_${dataKey}`)}</Form.Label>
              <Col sm="7">
                <Form.Control
                  required
                  name={`${sect}[${dataKey}]`}
                  type="number"
                  placeholder={getDisplayLabel(`${sect}_${dataKey}`)}
                  defaultValue={value.value}
                  min={value.min}
                  max={value.max}
                />
                <Form.Control.Feedback type="invalid">
                  Please fill in the field.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>,
          );
        } else if (value.type === 2) {
          const checked = !!(value.value && (value.value === 'on'));

          ret.push(
            <Form.Group key={dataKey} as={Row} controlId={dataKey}>
              <Col sm="5">
                <Form.Label className="text-right">{getDisplayLabel(`${sect}_${dataKey}`)}</Form.Label>
              </Col>
              <Col sm="7">
                <Form.Check
                  custom
                  name={`${sect}[${dataKey}]`}
                  type="checkbox"
                  defaultChecked={checked}
                  label=""
                />
              </Col>
            </Form.Group>,
          );
        }
      }
    }
    return ret;
  }

  componentDidMount() {
    this.loadSettings();
  }

  showAlert = (status, text) => {
    this.setState({
      alertText: text,
      success: status,
    });
    setTimeout(() => {
      this.setState({
        alertText: null,
      });
    }, 5000);
  };

  loadSettings() {
    api.get('/v1/settings')
      .then((res) => {
        this.setState({
          settings: res.data.settings,
          validated: false,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  storeSettings(data) {
    api.put('/v1/settings', data)
      .then((res) => {
        this.loadSettings();
        this.showAlert(res.data.success, 'Saved');
      })
      .catch((error) => {
        this.showAlert(false, error);
      });
  }

  saveSettings(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    if (validated) {
      const data = serialize(form, { hash: true, disabled: false, empty: true });
      this.storeSettings(data);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  renderSettings() {
    const ret = [];
    const { settings } = this.state;
    const { sections } = this.props;

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const settingsKey in settings) {
      const display = sections ? sections.includes(settingsKey) : true;
      // eslint-disable-next-line no-prototype-builtins
      if (settings.hasOwnProperty(settingsKey) && display) {
        ret.push(
          <Card key={settingsKey}>
            <Card.Header>{getDisplayLabel(settingsKey)}</Card.Header>
            <Card.Body>
              {Settings.renderSection(settingsKey, settings[settingsKey])}
            </Card.Body>
          </Card>,
        );
      }
    }
    return ret;
  }

  render() {
    const {
      validated, loading, alertText, success,
    } = this.state;

    return (
      <>
        <Alert variant={success ? 'success' : 'danger'} show={alertText != null}>
          {alertText}
        </Alert>
        <Card>
          <Card.Body>
            {loading ? (
              <div className="text-center">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="loadingSpinner justify-content-center"
                />
              </div>
            ) : (
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  this.saveSettings(e);
                }}
              >
                {this.renderSettings()}
                <Card className="mt-3">
                  <Card.Body>
                    <Form.Row>
                      <Form.Group as={Col} className="mb-0 pb-0 text-center">
                        <Button
                          size="sm"
                          type="submit"
                          variant="primary"
                        >
                          Save
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </Card.Body>
                </Card>
              </Form>
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}
