import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { Book, BookTypes } from '../../../../dorian-shared/types/book/book';
import { BookShareUser } from '../../../../dorian-shared/types/user/User';
import { api } from '../../../api';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import { showToast } from '../../../ui/utils';
import { getBookType } from '../../Book/utils';
import { FirstPublishCard } from './FirstPublishCard/FirstPublishCard';
import { fetchShareUsersWithExclusiveAccess } from './utils';

export enum PublishType {
  General = 'general',
  Exclusive = 'exclusive',
}

interface PublishModalProps {
    show: boolean
    book: Book
    title?: string
    onConfirm: () => void
    onCancel: () => void
    isLoading?: boolean
    willAlertShowForNewUser?: boolean
    publishTypeValue?: PublishType
}

export function PublishModal(props: PublishModalProps) {
  const {
    show,
    book,
    onConfirm,
    onCancel,
    isLoading: isLoadingValue,
    title = 'Publish Prompt',
    willAlertShowForNewUser,
    publishTypeValue = PublishType.General,
  } = props;

  const [hasPublicChapters, setHasPublicChapters] = useState<boolean>(true);
  const [bookShareUsers, setBookShareUsers] = useState<BookShareUser[]>([]);
  const [publishType, setPublishType] = useState<PublishType>(publishTypeValue);

  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const loadShareUsers = useCallback(async () => {
    setToLoading();
    try {
      const usersWithExclusiveAccess = await fetchShareUsersWithExclusiveAccess(book.id);
      setBookShareUsers(usersWithExclusiveAccess);
      setToSuccess();
    } catch (e) {
      showToast({ textMessage: 'A data write error has occurred' });
      setToError();
    }
  }, [book, setToError, setToLoading, setToSuccess]);

  // The best way to create book context in future
  const checkPublicEpisodes = useCallback(async () => {
    const responce = await api.get(`/v1/books/${book.id}/chapters/?groupId=10`);
    const { chapters } = responce.data;
    const hasPublic = chapters.length > 0;
    setHasPublicChapters(hasPublic);
  }, [book.id]);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        loadShareUsers(),
        checkPublicEpisodes(),
      ]);
      setToSuccess();
    } catch (e) {
      showToast({ textMessage: 'A data read error has occurred' });
      setToError();
    }
  }, [checkPublicEpisodes, loadShareUsers, setToError, setToSuccess]);

  useEffect(() => {
    if (isLoadingValue) {
      setToLoading();
    } else {
      setToSuccess();
    }
  }, [isLoadingValue, setToLoading, setToSuccess]);

  useEffect(() => {
    // To prevent load data when modal is hidden
    if (show) {
      fetchData();
    }
  }, [fetchData, show]);

  const handleConfirm = async () => {
    // Make share users promises
    const userSavePromises = bookShareUsers.map((bookShareUser) => {
      const userData = {
        user_id: bookShareUser.id,
        book_role: bookShareUser.book_role,
      };
      return api.post(`/v1/books/${book.id}/share`, userData);
    });

    // Make book access promise
    const data = {
      exclusiveAccess: publishType === PublishType.Exclusive,
      promptTrending: false,
      promptActiveContest: false,
    };

    const bookAccessPromise = api.put(`/v1/books/${book.id}`, data);
    userSavePromises.push(bookAccessPromise);

    // Save data
    setToLoading();
    try {
      await Promise.all(userSavePromises);
      setToSuccess();
      onConfirm();
    } catch (e) {
      showToast({ textMessage: 'Error on saving user access data' });
      setToError();
      loadShareUsers();
    }
  };

  const handlePublishChange = (newPublishType: PublishType) => {
    setPublishType(newPublishType);
  };

  const handleAddShareUser = (shareUser?: BookShareUser) => {
    const newShareUsers = [...bookShareUsers, shareUser] as BookShareUser[];
    setBookShareUsers(newShareUsers);
  };

  const handleDeleteShareUser = () => {
    loadShareUsers();
  };

  // eslint-disable-next-line max-len
  const isConfirmButtonDisabled = (publishType === PublishType.Exclusive && bookShareUsers.length === 0)
      || publishType === undefined;

  const bookType = getBookType(book);
  // Is approved_count > 0 as released?
  const isFirstPublishAndIsPrompt = book.approved_count === 0 && bookType === BookTypes.Prompt;
  const willShowFirstPublishCard = isFirstPublishAndIsPrompt && !hasPublicChapters;

  return (
    <ConfirmModal
      show={show}
      isLoading={isLoading || isLoadingValue}
      title={title}
      confirmButtonTitle={willShowFirstPublishCard ? 'Publish' : 'Update in the App'}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      size={willShowFirstPublishCard ? 'lg' : 'md'}
      isConfirmButtonDisabled={isConfirmButtonDisabled}
    >
      <Row>
        <Col>
          {willAlertShowForNewUser && (
          <Alert variant="warning">
            Your story is going live on our mobile app! Make sure to register
            your email on the Dorian app so you can collect followers and
            patrons and interact with your fans directly! If you don’t have
            our app, download Dorian: the future of fiction! on
            {' '}
            <Alert.Link
              href="https://apps.apple.com/us/app/dorian-the-future-of-fiction/id1529215455"
              target="_blank"
            >
              Apple App Store
            </Alert.Link>
            {' '}
            or
            {' '}
            <Alert.Link
              href="https://play.google.com/store/apps/details?id=com.dorian.playtogether"
              target="_blank"
            >
              Google Play
            </Alert.Link>
          </Alert>
          )}
        </Col>
      </Row>
      {willShowFirstPublishCard
        ? (
          <FirstPublishCard
            publishType={publishType}
            bookShareUsers={bookShareUsers}
            onPublishTypeChange={handlePublishChange}
            bookId={book.id}
            onAddShareUser={handleAddShareUser}
            onDeleteShareUser={handleDeleteShareUser}
          />
        )
        : <p className="text-center">Do you really want to Update in the app this episode?</p>}

    </ConfirmModal>
  );
}
