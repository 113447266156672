import { useEffect } from 'react';
import { useBookLocationsContext } from '../../../../contexts/BookLocationsContext/BookLocationsContext';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { logger } from '../../../../services/loggerService/loggerService';

export function useImportTextLocations(bookId: number) {
  const { bookLocations, fetchBookLocations } = useBookLocationsContext();

  const [
    requestStatus,
    {
      setToError,
      setToLoading,
      setToSuccess,
    },
  ] = useAsyncOperationState();

  useEffect(() => {
    if (!bookLocations) {
      setToLoading();
      fetchBookLocations(bookId)
        .then(() => setToSuccess())
        .catch((error) => {
          logger.error('[ImportTextModal] Error in fetching book locations', error);
          setToError('Error in fetching book locations');
        });
    }
  }, [bookId, bookLocations, fetchBookLocations, setToError, setToLoading, setToSuccess]);
  return {
    bookLocations,
    requestStatus,
  };
}
