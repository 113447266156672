import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Row from 'react-bootstrap/esm/Row';
import { StepTypeMode } from 'dorian-shared/types/branch/Branch';

interface AddAnswerFragmentProps {
    onAddAnswerClick: () => void;
    answersCount: number;
    stepTypeMode: StepTypeMode;
    onStepTypeModeChange: (mode: StepTypeMode) => void;
    competitiveMode: boolean;
}

export function AddAnswerHeader(props: AddAnswerFragmentProps) {
  const {
    onAddAnswerClick, answersCount, onStepTypeModeChange, stepTypeMode, competitiveMode,
  } = props;

  return (
    <Row>
      <Col md="auto" className="mr-3">
        <h5>
          Answers (
          {answersCount}
          )
          <Button
            size="sm"
            variant="secondary"
            className="ml-3"
            onClick={() => onAddAnswerClick()}
            disabled={(answersCount >= 3)}
            onMouseDown={(e) => e.stopPropagation()}
          >
            + Add Answer
          </Button>
        </h5>
      </Col>
      {competitiveMode && (
        <>
          <Col xs="auto" className="mr-3">
            <Form.Check
              custom
              id="regular-branch-type"
              type="radio"
              label="Regular"
              checked={stepTypeMode === StepTypeMode.Regular}
              onChange={() => onStepTypeModeChange(StepTypeMode.Regular)}
            />
          </Col>
          <Col xs="auto" className="mr-3">
            <Form.Check
              custom
              id="eliminationS-branch-type"
              type="radio"
              label="Elimination"
              checked={stepTypeMode === StepTypeMode.Elimination}
              onChange={() => onStepTypeModeChange(StepTypeMode.Elimination)}
            />
          </Col>
        </>
      )}
    </Row>
  );
}
