const propertiesToNormalize = ['pageX', 'pageY', 'clientX', 'clientY'];

function patchEventProperties(event, touchKey) {
  propertiesToNormalize.forEach((key) => {
    if (typeof event[key] === 'undefined') {
      // eslint-disable-next-line no-param-reassign
      event[key] = event[touchKey][0][key];
    }
  });
}

export function castTouchToMouseEvent(evt) {
  if (evt.type.includes('mouse')) {
    return evt;
  }
  try {
    if (evt.type === 'touchstart') {
      patchEventProperties(evt, 'targetTouches');
    } else if (evt.type === 'touchmove') {
      patchEventProperties(evt, 'changedTouches');
    }
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert(err.message);
  }
  return evt;
}
