import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import DeleteModifierType from './DeleteModifierType';
import styles from './ModifierTypes.css';

const cs = classNames.bind(styles);

export class ModifierTypesTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.update();
    };

    const deleteRow = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.modalDeleteShow === true) {
        return (
          <DeleteModifierType
            // eslint-disable-next-line react/destructuring-assignment
            show={this.state.modalDeleteShow}
            onHide={modalDeleteClose}
            // eslint-disable-next-line react/destructuring-assignment
            obj={this.props.obj}
          />
        );
      }
      return null;
    };

    const { obj, actionEditModifierType } = this.props;

    return (
      <tr>
        <td>
          {obj.title}
        </td>
        <td>
          {obj.value}
        </td>
        <td className={cs('text-right')}>
          <Button
            onClick={() => actionEditModifierType(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEdit} className="btnImg" alt="Edit" />
          </Button>
          <Button
            onClick={() => this.setState({ modalDeleteShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
          {deleteRow()}

        </td>
      </tr>
    );
  }
}
