import React, { Component } from 'react';
import {
  Accordion, Button, Card, Col, Row, Spinner, Table,
} from 'react-bootstrap';
import Moment from 'react-moment';
import { markupToHTML } from '../../../helpers/markupToHTML';
import { api } from '../../api';
import { FormDocuments } from './FormDocument';

export function DocumentChangeHistory(props) {
  const { auth } = props;
  const { getUser } = auth;
  const user = getUser();
  const [history, setHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleLoadHistory = () => {
    setLoading(true);
    const { historyUUID } = props;
    api.get(`v1/documents-history?history_uuid=${historyUUID}`)
      .then((res) => {
        setLoading(false);
        setHistory(res.data.documents);
      })
      .catch(() => {
        setLoading(false);
        setHistory(null);
      });
  };

  if (user.role !== 'admin') {
    return null;
  }

  if (!history || history.length < 1) {
    return (
      <div>
        <hr />
        <Button
          size="sm"
          variant="primary"
          disabled={loading}
          onClick={handleLoadHistory}
        >
          show document change history
          {
            loading
            && <Spinner animation="border" variant="primary" />
          }
        </Button>
      </div>
    );
  }

  return (
    <div>
      <hr />
      <h4>Document change history</h4>

      <Accordion
        className="mt-3"
        style={{
          fontSize: '.8em',
        }}
      >
        {
          history.map((o, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <AccordionItem obj={o} eventKey={i + 1} key={i} />
          ))
        }
      </Accordion>
    </div>
  );
}

export function AccordionItem(props) {
  const {
    obj,
    eventKey,
  } = props;

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={eventKey}
        className="accordion-button d-block"
        style={{
          cursor: 'pointer',
        }}
      >
        <Moment
          className="d-inline-block pr-5"
          format="YYYY-MM-DD hh:mm A"
        >
          {obj.createdAt}
        </Moment>
        {obj.uuid}
      </Accordion.Toggle>

      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body>

          <Table responsive>
            <tbody>
              <tr>
                <th style={{
                  width: '10em',
                }}
                >
                  Title
                </th>
                <td>
                  <div
                    style={{
                      padding: '1em',
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    }}
                  >
                    {obj.title}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Summary</th>
                <td>
                  <div
                    style={{
                      padding: '1em',
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    }}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: markupToHTML(obj.summary) }}
                  />
                </td>
              </tr>
              <tr>
                <th>Content:</th>
                <td>
                  <div
                    style={{
                      padding: '1em',
                      backgroundColor: 'rgba(0, 0, 0, 0.03)',
                    }}
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: obj.body }}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export class PageDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      edit: false,
      data: [],
    };
    const { auth } = this.props;
    this.user = auth.getUser();
  }

  componentDidUpdate(prevProps) {
    const { page_id: pageId } = this.props;
    if (prevProps.page_id !== pageId) {
      this.setState({
        loading: false,
        edit: false,
      }, () => {
        this.loadData();
      });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    const { page_id: pageId } = this.props;
    api.get(`v1/documents/${pageId}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.document,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      edit,
      loading,
      data,
    } = this.state;

    if (edit) {
      return (
        <FormDocuments
          {...this.props}
          obj={data}
          update={() => {
            this.setState({
              edit: false,
            }, () => {
              this.loadData();
            });
          }}
          onHide={() => {
            this.setState({
              edit: false,
            });
          }}
        />
      );
    }

    return (
      <>
        <Row>
          <Col md={this.user.role === 'admin' ? 10 : 12}>
            <h1>{data.title}</h1>
          </Col>
          {
            (this.user.role === 'admin' && this.user.canEditLegalText)
            && (
            <Col md={2} className="text-right">
              <Button
                size="sm"
                variant="primary"
                onClick={() => {
                  this.setState({
                    edit: true,
                  });
                }}
              >
                Edit
              </Button>
            </Col>
            )
          }
        </Row>
        {
          data.summary
          && (
          <div
            style={{ padding: '1em 0' }}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: markupToHTML(data.summary) }}
          />
          )
        }
        {
          data.body
          // eslint-disable-next-line react/no-danger
          && <div dangerouslySetInnerHTML={{ __html: data.body }} />
        }

        {
          !loading
          && (
          <DocumentChangeHistory
            {...this.props}
            alias={data.alias}
            historyUUID={data.history_uuid}
          />
          )
        }
      </>
    );
  }
}
