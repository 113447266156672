import React, { Profiler, PropsWithChildren } from 'react';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';

interface PerformanceProfilerProps extends PropsWithChildren {
 profilerId: string,
  mount?: boolean,
  update?: boolean,
}

export function PerformanceProfiler(props: PerformanceProfilerProps) {
  const {
    profilerId, children, mount, update,
  } = props;

  const clockPerformance = (
    id: string,
    phase: string,
    actualDuration: number,
  ) => {
    const finishTimeInMs = Date.now();
    const actualDurationInMs = Math.round(actualDuration);
    switch (phase) {
      case 'mount': {
        if (mount) {
          bugTracker().reportPerformance({
            name: `[Profiler][Mount]: ${id}`,
            message: `Mount in: ${actualDurationInMs}ms`,
            durationInMs: actualDurationInMs,
            finishTimeInMs,
          });
        }
        break;
      }
      case 'update': {
        if (update) {
          bugTracker().reportPerformance({
            name: `[Profiler][Update]: ${id}`,
            message: `Update in: ${actualDurationInMs}ms`,
            durationInMs: actualDurationInMs,
            finishTimeInMs,
          });
        }
        break;
      }
      default:
        break;
    }
  };

  /*
  Although you can set custom metrics in the SDK, the ability to see the data generated
  by these metrics is only available to organizations on our latest plans which include
  Dynamic Sampling.
  https://docs.sentry.io/platforms/javascript/guides/react/performance/instrumentation/performance-metrics/
  */
  return (
    <Profiler id={profilerId} onRender={clockPerformance}>
      {children}
    </Profiler>
  );
}
