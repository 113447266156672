import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Spinner,
} from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { PageWrapper } from '../../ui/PageWrapper';
import { ClientVariables } from './ClientVariables/ClientVariables';
import {
  addClientVariable,
  deleteClientVariableById,
  fetchClientVariables,
  updateClientVariable,
} from './clientVariablesAPI';
import classNames from './ClientVariablesPage.module.scss';

function getPageLayout(history) {
  return {
    header: {
      title: 'Client Variables Admin Panel',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
      ],
    },
  };
}

export const defaultClientVariable = {
  name: '',
  type: '',
  dataType: '',
  experiment: '',
  path: '',
  value: '',
};

export function ClientVariablesPage(props) {
  const {
    history,
  } = props;
  const pageLayout = getPageLayout(history);

  const [isLoading, setIsLoading] = useState(false);
  const [clientVariables, setClientVariables] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchClientVariables()
      .then((loadedClientVariables) => setClientVariables(loadedClientVariables))
      .finally(() => setIsLoading(false));
  }, []);

  const handleAddClientVariableClick = () => {
    const clientVariablesWithEmptyName = clientVariables.filter((clientVariable) => clientVariable.name === '');
    if (clientVariablesWithEmptyName.length === 0) {
      setClientVariables([...clientVariables, { ...defaultClientVariable }]);
    }
  };

  const handleClientVariableDelete = (variableToDeleteId, variableIndex) => {
    const newClientVariables = clientVariables.filter((_, index) => index !== variableIndex);
    if (variableToDeleteId) {
      setIsLoading(true);
      deleteClientVariableById(variableToDeleteId)
        .then(() => setClientVariables(newClientVariables))
        .finally(() => setIsLoading(false));
    } else {
      setClientVariables(newClientVariables);
    }
  };

  function updateLocalClientVariablesByIndex(variableIndex, variableToUpdate) {
    const newClientVariables = clientVariables.map(
      (oldVariable, index) => (variableIndex === index ? variableToUpdate : oldVariable),
    );
    setClientVariables(newClientVariables);
  }

  const handleClientVariableSave = (variableToSave, variableIndex) => {
    setIsLoading(true);
    if (variableToSave.id) {
      updateClientVariable(variableToSave)
        .then(() => updateLocalClientVariablesByIndex(variableIndex, variableToSave))
        .finally(() => setIsLoading(false));
    } else {
      addClientVariable(variableToSave)
        .then((responseData) => updateLocalClientVariablesByIndex(
          variableIndex,
          responseData.config,
        ))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <Card>
        <Card.Body>
          <Card.Title>
            Client Variables
          </Card.Title>
          <ClientVariables
            clientVariables={clientVariables}
            onSave={handleClientVariableSave}
            onDelete={handleClientVariableDelete}
          />
          <Col className={classNames.bottomButtons}>
            <Button
              variant="secondary"
              onClick={handleAddClientVariableClick}
            >
              Add variable
            </Button>
          </Col>
        </Card.Body>
        {isLoading && (
          <Spinner
            variant="primary"
            animation="border"
            size="md"
            className={classNames.spinner}
          />
        )}
      </Card>
    </PageWrapper>
  );
}
