import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { api } from '../../../api';

export class SaveAsTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      loading: false,
      errorMsg: null,
      successfulMsg: null,
      fromTemplate: false,
      title: '',
      titleValid: null,
      description: '',
      descriptionValid: null,
      redirect: null,
    };
  }

  componentDidMount() {
    const { limits, story } = this.props;

    this.setState({
      fromTemplate: story.fromTemplate || story.usedAsTemplate,
      title: story.title,
      description: story.description,
      titleValid: limits
        && limits.story_temlate_title_max
        && limits.story_temlate_title_max.value,
    });
  }

  componentDidUpdate(prevProps) {
    const { show, limits, story } = this.props;
    const { fromTemplate } = this.state;

    if (
      story.title !== prevProps.story.title
      || story.description !== prevProps.story.description
      || show !== prevProps.show
    ) {
      this.setState({
        title: story.title,
        description: story.description,
        titleValid: limits
          && limits.story_temlate_title_max
          && limits.story_temlate_title_max.value,
      });
    }

    if (!fromTemplate && (story.fromTemplate || story.usedAsTemplate) !== fromTemplate) {
      this.setState({ fromTemplate: story.fromTemplate || story.usedAsTemplate });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.saveData(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  saveData = (obj, validated) => {
    const { onValidationError, onSuccess, story } = this.props;

    if (validated === true) {
      this.setState({
        loading: true,
      });
      api.post(`/v1/books/${story.book.id}/chapters/${story.id}/saveastemplate`, obj)
        .then((res) => {
          this.setState({
            loading: false,
          }, () => {
            this.successfulAlert('New story prompt created');
            this.setState({
              fromTemplate: true,
              redirect: (res.data && res.data.prompt && res.data.prompt.id) && `/book/${res.data.prompt.id}`,
            });
            if (onSuccess && (!res.data || !res.data.prompt || !res.data.prompt.id)) {
              onSuccess();
            }
          });
        })
        .catch((error) => {
          if (String(error.response.status) === '418') {
            this.setState({
              loading: false,
            }, onValidationError);
          } else {
            this.setState({
              loading: false,
            }, () => {
              if (error?.response?.data?.error) {
                this.errorAlert(error.response.data.error);
              }
            });
          }
        });
    }
  };

  successfulAlert = (data) => {
    const { onHide } = this.props;

    this.setState({
      successfulMsg: data,
    }, () => {
      setTimeout(() => {
        this.setState({
          successfulMsg: null,
        }, () => {
          onHide();
        });
      }, 1500);
    });
  };

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    }, () => {
      setTimeout(() => {
        this.setState({
          errorMsg: null,
        });
      }, 3000);
    });
  };

  render() {
    const {
      show, onHide, limits, story,
    } = this.props;
    const {
      validated,
      loading,
      fromTemplate,
      titleValid,
      errorMsg,
      successfulMsg,
      redirect,
      title,
      description,
      descriptionValid,
    } = this.state;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const storyTemlateDescriptionMax = Number(
      limits
        && limits.story_temlate_description_max
        && limits.story_temlate_description_max.value
        ? limits.story_temlate_description_max.value : 0,
    );
    return (
      <Modal
        show={show}
        onHide={onHide}
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Save As Story Prompt</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row className={!errorMsg ? 'd-none' : 'd-block'}>
              <Col md={12}>
                <Alert variant="danger">
                  {errorMsg}
                </Alert>
              </Col>
            </Row>

            <Row className={!successfulMsg ? 'd-none' : 'd-block'}>
              <Col md={12}>
                <Alert variant="success">
                  {successfulMsg}
                </Alert>
              </Col>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                controlId="templateName"
              >
                <Form.Label>Story Prompt Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  disabled={loading}
                  placeholder="Story Prompt Name"
                  defaultValue={story.title}
                  name="title"
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value,
                      titleValid: limits
                          && limits.story_temlate_title_max
                          && limits.story_temlate_title_max.value,
                    });
                  }}
                  className={
                    title
                      && title.length > Number(
                        limits
                      && limits.story_temlate_title_max
                      && limits.story_temlate_title_max.value
                          ? limits.story_temlate_title_max.value : 0,
                      ) ? 'text-limit' : null
}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(
                    limits
                      && limits.story_temlate_title_max
                      && limits.story_temlate_title_max.value
                      ? limits.story_temlate_title_max.value : 0,
                  ) - (title ? title.length : 0)}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Story Prompt Name is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group
                as={Col}
                controlId="templateName"
              >
                <Form.Label>Story Prompt Description</Form.Label>
                <Form.Control
                  as="textarea"
                  disabled={loading}
                  placeholder="Description"
                  defaultValue={story.description}
                  name="description"
                  onChange={(e) => {
                    this.setState({
                      description: e.target.value,
                      descriptionValid:
                        limits
                          && limits.story_temlate_description_max
                          && limits.story_temlate_description_max.value,
                    });
                  }}
                  className={(
                    description
                      && description.length > Number(descriptionValid)
                      ? limits.story_temlate_description_max.value
                      : 0) ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">
                  {storyTemlateDescriptionMax - (description ? description.length : 0)}
                </Form.Text>
              </Form.Group>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={onHide}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                  loading
                  || fromTemplate
                  || (description && description.length > Number(descriptionValid))
                  || (title && title.length > Number(titleValid))
                }
            >
              {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              )}
              Save As Story Prompt
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
