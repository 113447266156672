import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Alert } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import styles from './ErrorAlert.css';

const cs = classNames.bind(styles);

const PortalComponent = ({ children }) => createPortal(
  <Alert variant="danger" className={cs('mx-3 my-3')}>
    {children}
  </Alert>,
  document.getElementById('errorsBox'),
);

export class ErrorAlert extends PureComponent {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      errorAlert: [],
    };
  }

  render() {
    const { error, close } = this.props;
    if (error && error.response && error.response.data) {
      return (
        <PortalComponent onClose={() => close()}>
          {error.response.data.error}
        </PortalComponent>
      );
    }
    return null;
  }
}
