import React from 'react';

export function InfoIcon({
  fill = 'white',
  height = '25px',
  width = '25px',
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path d="M12 0.26001C5.38 0.26001 0 5.64001 0 12.26C0 18.88 5.38 24.26 12 24.26C18.62 24.26 24 18.88 24 12.26C24 5.64001 18.62 0.26001 12 0.26001ZM12 22.26C6.49 22.26 2 17.77 2 12.26C2 6.75001 6.49 2.26001 12 2.26001C17.51 2.26001 22 6.75001 22 12.26C22 17.77 17.51 22.26 12 22.26Z" />
      <path d="M12.04 8.95001C11.49 8.95001 11.04 9.40001 11.04 9.95001V19.25C11.04 19.8 11.49 20.25 12.04 20.25C12.59 20.25 13.04 19.8 13.04 19.25V9.95001C13.04 9.40001 12.59 8.95001 12.04 8.95001Z" />
      <path d="M12 7.75C12.55 7.75 13 7.3 13 6.75C13 6.2 12.55 5.75 12 5.75C11.45 5.75 11 6.2 11 6.75C11 7.3 11.45 7.75 12 7.75Z" />
    </svg>
  );
}
