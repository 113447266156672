import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { userApi } from '../../../services/userApi/UserApi';
import { auth } from '../../Auth/Auth';
import { ConfirmEmailFormValues } from './ConfirmEmailForm';

export function useConfirmEmailForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const history = useHistory();

  const handleSubmit = useCallback(async (values: ConfirmEmailFormValues) => {
    // Moved from legacy code
    const createTemplateInLocalStorage = localStorage.getItem('createTemplate');
    const createTemplate = createTemplateInLocalStorage
      ? JSON.parse(createTemplateInLocalStorage)
      : null;
    if (createTemplate) {
      createTemplate.lostPassword = true;
    }
    // End of moved from legacy code

    const payload = {
      username: values.username,
      code: values.code,
    };

    setToLoading();
    try {
      await userApi.verify(payload);
      history.push('/verified-email', { replace: true });
    } catch (error) {
      const message = auth.getErrorMessage(error) ?? 'Error occurred while trying to verify email';
      setToError(message);
    }
  }, [history, setToError, setToLoading]);

  return {
    isLoading,
    setToLoading,
    isError,
    setToError,
    statusMessage,
    handleSubmit,
  };
}
