import React from 'react';

export function DorianLogo({
  fill = 'white',
  stroke = 'black',
  height = '25px',
  width = '25px',
}) {
  return (
    <svg
      height={height}
      viewBox="0 0 125 125"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        spreadMethod="pad"
        x1="52.782"
        x2="53.125"
        y1="297.519"
        y2="172.543"
      >
        <stop offset="0" stopColor="#6879a0" />
        <stop offset="1" stopColor="#559c9c" />
      </linearGradient>
      <path
        d="M59.245 217.072h-13.07l.268 13.63m18.493-51.554c30.687 0 55.563 24.876 55.563 55.562 0 30.687-24.877 55.563-55.563 55.563m3.472-111.119H5.524v36.426m60.639 74.657H5.5v-34.959m53.231-38.263c10.715 0 19.4 7.968 19.4 17.797 0 9.828-8.685 17.796-19.4 17.796m-.145-.026H46.775m31.473-18.305 41.694-.535m-65.48-54.517-.268 19.227m5.98.866c15.21 0 29.264 7.034 36.87 18.452m-.152 36.526c-7.605 11.418-21.66 18.452-36.87 18.452m.576-73.483-36.606-.011v73.237l36.03.257m-36.297-36.867-19.51-.268"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="7"
        transform="translate(0 -172)"
      />
    </svg>
  );
}
