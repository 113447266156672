import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface EliminationAnswerFieldsProps {
    stepIndex: number;
    answerIndex: number;
    disabled: boolean;
    activeStepsFunc?: (id: string) => void;
    value: string;
    onChange: (value: string) => void;
    textLengthLimit?: number;
}

export function AnswerEliminationText(props: EliminationAnswerFieldsProps) {
  const {
    answerIndex,
    disabled,
    value,
    onChange,
    activeStepsFunc,
    stepIndex,
    textLengthLimit = 120,
  } = props;

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const textValue = event.currentTarget.value ?? '';
    onChange(textValue.slice(0, textLengthLimit));
  };

  return (
    <Form.Group
      as={Col}
      controlId={`eliminationResultText-${answerIndex}`}
      className="my-0"
      sm={12}
      md={7}
      lg={4}
    >
      <Row className="d-flex">
        <Col
          className="d-flex mb-0 px-1"
          sm={12}
        >
          <Form.Label
            className="my-auto py-0"
            column
            sm={3}
            md="auto"
          >
            Elimination text
          </Form.Label>
          <Form.Control
            className="my-auto py-0"
            disabled={disabled}
            size="sm"
            type="text"
            value={value}
            name={`steps[${stepIndex}][answers][${answerIndex}][eliminationResultText]`}
            onFocus={() => {
              if (activeStepsFunc) {
                activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-eliminationResultText`);
              }
            }}
            onMouseDown={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
            onChange={handleTextChange}
          />
          <Form.Text className="char-limit-info-box">
            {Number(textLengthLimit) - value.length}
          </Form.Text>
        </Col>
      </Row>
    </Form.Group>
  );
}
