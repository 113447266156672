import async from 'async';
import React from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import Moment from 'react-moment';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AuthorProfile } from '../Users/AuthorProfile';

export function RecentlyUploaded(props) {
  const { auth } = props;

  const user = auth.getUser();

  const [loading, setLoading] = React.useState(false);
  const [bookCovers, setBookCovers] = React.useState([]);
  const [userAvatars, setUserAvatars] = React.useState([]);
  const [backgrounds, setBackgrounds] = React.useState([]);
  const [showUserAvatar, setShowUserAvatar] = React.useState(true);
  const [showBookCover, setShowBookCover] = React.useState(true);
  const [showBackgrounds, setShowBackgrounds] = React.useState(true);
  const [userProfile, setUserProfile] = React.useState(null);

  const loadData = () => {
    if (!loading) {
      setLoading(true);

      async.parallel({
        bookCoverData: (callback) => {
          api.get('v1/moderation/recentlyuploaded/bookcover')
            .then((res) => {
              callback(null, res.data);
            }).catch((error) => {
              callback(error, null);
            });
        },
        userAvatarData: (callback) => {
          api.get('v1/moderation/recentlyuploaded/useravatar')
            .then((res) => {
              callback(null, res.data);
            }).catch((error) => {
              callback(error, null);
            });
        },
        backgroundsData: (callback) => {
          api.get('v1/moderation/recentlyuploaded/backgrounds')
            .then((res) => {
              callback(null, res.data);
            }).catch((error) => {
              callback(error, null);
            });
        },
      }, (err, res) => {
        try {
          if (err) {
            setLoading(false);
          } else {
            setBookCovers(res.bookCoverData?.images);
            setUserAvatars(res.userAvatarData?.images);
            setBackgrounds(res.backgroundsData?.images);
            setLoading(false);
          }
        } catch (e) {
          setLoading(false);
        }
      });
    }
  };

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user.role !== 'admin') {
    return null;
  }

  const page = {
    header: {
      title: 'Recently Uploaded',
      settings: user.role === 'admin' ? 'admin' : null,
    },
    sidebar: {
      nav: [
        {
          title: 'Back to Stories List',
          action: '',
          href: '/books',
          variant: 'primary',
        },
      ],
    },
  };

  const modalAuthorProfileClose = () => {
    setUserProfile(null);
  };

  return (
    <PageWrapper
      {...props}
      page={page}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <Form.Check
                disabled={loading}
                checked={showBookCover}
                onChange={(e) => {
                  setShowBookCover(e.target.checked);
                }}
                id="showBookCover"
                name="showBookCover"
                type="switch"
                label="Book Covers"
              />
            </Col>

            <Col>
              <Form.Check
                disabled={loading}
                checked={showUserAvatar}
                onChange={(e) => {
                  setShowUserAvatar(e.target.checked);
                }}
                id="showUserAvatar"
                name="showUserAvatar"
                type="switch"
                label="User Avatars"
              />
            </Col>

            <Col>
              <Form.Check
                disabled={loading}
                checked={showBackgrounds}
                onChange={(e) => {
                  setShowBackgrounds(e.target.checked);
                }}
                id="showBackgrounds"
                name="showBackgrounds"
                type="switch"
                label="Backgrounds"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {(showBookCover && !loading) && (
      <>
        <h2 className="pt-4">Book Covers</h2>
        <Row style={{ alignItems: 'stretch' }}>
          {bookCovers?.length > 0
            ? bookCovers.map((bookCover, i) => (
            // eslint-disable-next-line react/no-array-index-key
              <Col key={i} sm={3} md={2}>
                <Card>
                  <Card.Link
                    style={{ opacity: bookCover.book ? 1 : 0.5 }}
                    href={bookCover.book ? `/book/${bookCover?.book?.id}` : '#'}
                  >
                    <Card.Img variant="top" src={bookCover.imageUrl} />
                    <Card.Body style={{ padding: '.75em' }}>
                      <Card.Title style={{ fontSize: '1em' }}>
                        {bookCover?.book?.title || '(deleted)'}
                      </Card.Title>
                    </Card.Body>
                  </Card.Link>
                  <Row>
                    <Col className="justify-content-end text-right" style={{ fontSize: '0.5em' }}>
                      Updated At:&nbsp;&nbsp;
                      <Moment
                        element="span"
                        format="YYYY-MM-DD hh:mm A"
                        date={bookCover.createdAt}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            )) : (
              <Col sm={12}>
                <h3>No new images</h3>
              </Col>
            )}
        </Row>
        <hr />
      </>
      )}

      {(showUserAvatar && !loading) && (
      <>
        <h2 className="pt-4">User Avatars</h2>
        <Row style={{ alignItems: 'stretch' }}>
          {userAvatars?.length > 0
            ? userAvatars.map((userAvatar, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={i} sm={3} md={2}>
                <Card>
                  <Card.Link
                    style={{
                      opacity: userAvatar.user ? 1 : 0.5,
                    }}
                    onClick={() => setUserProfile(userAvatar?.user?.id)}
                    href="#"
                  >
                    <Card.Img variant="top" src={userAvatar.imageUrl} />
                    <Card.Body style={{ padding: '.75em' }}>
                      <Card.Title style={{ fontSize: '1em' }}>
                        {userAvatar?.user?.fullname}
                      </Card.Title>
                    </Card.Body>
                  </Card.Link>
                  <Row>
                    <Col className="justify-content-end text-right" style={{ fontSize: '0.5em' }}>
                      Updated At:&nbsp;&nbsp;
                      <Moment
                        element="span"
                        format="YYYY-MM-DD hh:mm A"
                        date={userAvatar.createdAt}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            )) : (
              <Col sm={12}>
                <h3>No new images</h3>
              </Col>
            )}
        </Row>
        <hr />
      </>
      )}

      {(showBackgrounds && !loading) && (
      <>
        <h2 className="pt-4">Backgrounds</h2>
        <Row className="pt-4 CardColumns-avatar-prop">
          {backgrounds?.length > 0
            ? backgrounds.map((background, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={i} sm={3} md={2}>
                <Card>
                  <Card.Img variant="top" src={background.imageUrl} />
                  <Card.Body style={{ padding: '.75em' }}>
                    <Card.Title style={{ fontSize: '1em' }}>
                      {background.alias}
                    </Card.Title>
                  </Card.Body>
                  <Row>
                    <Col className="justify-content-end text-right" style={{ fontSize: '0.5em' }}>
                      Updated At:&nbsp;&nbsp;
                      <Moment
                        element="span"
                        format="YYYY-MM-DD hh:mm A"
                        date={background.createdAt}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            )) : (
              <Col sm={12}>
                <h3>No new images</h3>
              </Col>
            )}
        </Row>
        <hr />
      </>
      )}
      {userProfile && (
        <AuthorProfile
          show={!!userProfile}
          onHide={modalAuthorProfileClose}
          user={userProfile}
        />
      )}
    </PageWrapper>
  );
}
