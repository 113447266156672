import React from 'react';
import { SetPasswordForm } from '../../../uiV2/SetPasswordForm/SetPasswordForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function SetPassword() {
  return (
    <LoginLayout>
      <SetPasswordForm />
    </LoginLayout>
  );
}
