import React from 'react';

export function AppleLogo({
  height = '33px', width = '33px',
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" width={width} height={height}>
        <path fill="#e1ebf2" d="M71.793,57.336c-1.731,3.747-2.56,5.421-4.782,8.726c-3.105,4.622-7.493,10.39-12.916,10.431 c-4.82,0.037-6.066-3.044-12.61-2.993c-6.542,0.033-7.905,3.05-12.734,2.997c-5.419-0.045-9.572-5.243-12.679-9.853 c-5.312-7.908-7.721-16.645-7.566-24.087c0.099-4.739,1.237-8.954,3.327-12.094c3.8-5.723,9.814-8.963,15.46-8.963 c5.751,0,9.367,3,14.12,3c4.618,0,7.428-3,14.08-3c5.031,0,10.351,2.554,14.151,7.172c-5.665,3.034-8.11,8.289-8.145,13.55 C61.459,48.51,64.954,54.806,71.793,57.336z" />
        <path fill="#788b9c" d="M28.876,76.998l-0.13,0c-5.581-0.046-9.748-5.119-13.088-10.074 c-5.029-7.486-7.818-16.371-7.651-24.377c0.103-4.887,1.282-9.161,3.411-12.36C15.18,24.52,21.263,21,27.294,21 c3.095,0,5.543,0.831,7.911,1.633C37.277,23.336,39.233,24,41.415,24c2.012,0,3.646-0.587,5.538-1.267 C49.214,21.921,51.779,21,55.494,21c5.313,0,10.748,2.75,14.537,7.354l0.387,0.471l-0.538,0.288 C64.91,31.776,62.038,36.555,62,42.226c-0.044,6.638,3.868,12.385,9.966,14.641l0.507,0.188l-0.227,0.491 c-1.778,3.847-2.599,5.488-4.821,8.795c-3.523,5.245-7.862,10.611-13.327,10.652c-0.001,0-0.001,0-0.002,0 c-2.372,0-3.877-0.666-5.47-1.371C46.826,74.824,44.962,74,41.647,74l-0.157,0c-3.389,0.017-5.309,0.842-7.166,1.64 C32.699,76.338,31.164,76.998,28.876,76.998z M27.294,22c-5.7,0-11.464,3.349-15.043,8.739c-2.024,3.042-3.146,7.131-3.244,11.828 c-0.163,7.805,2.564,16.479,7.481,23.798c3.194,4.739,7.15,9.59,12.268,9.632l0.121,0c2.082,0,3.459-0.592,5.053-1.277 c1.858-0.798,3.964-1.703,7.554-1.721l0.164,0c3.527,0,5.577,0.907,7.385,1.708c1.559,0.69,2.905,1.286,4.978,1.286 c5.081-0.038,9.208-5.183,12.586-10.21c2.086-3.105,2.917-4.72,4.52-8.174c-6.208-2.528-10.161-8.51-10.116-15.39 c0.039-5.841,2.898-10.796,7.871-13.687C65.293,24.432,60.334,22,55.494,22c-3.542,0-5.912,0.852-8.204,1.675 C45.393,24.356,43.601,25,41.415,25c-2.346,0-4.379-0.689-6.531-1.419C32.593,22.804,30.224,22,27.294,22z" />
        <g>
          <path fill="#e1ebf2" d="M51.591,14.645c2.538-3.154,4.464-7.608,3.764-12.145c-4.138,0.276-8.983,2.836-11.811,6.15 c-2.564,3.02-4.69,7.504-3.862,11.844C44.2,20.633,48.876,18.029,51.591,14.645z" />
          <path fill="#788b9c" d="M40.027,21c-0.142,0.032-0.241-0.002-0.361-0.005l-0.401-0.013l-0.075-0.394 c-0.74-3.877,0.745-8.46,3.972-12.262c2.938-3.443,7.938-6.044,12.159-6.326l0.458-0.03l0.07,0.453 c0.636,4.12-0.774,8.688-3.869,12.534C49.037,18.628,44.345,21,40.027,21z M54.923,3.04c-3.871,0.416-8.331,2.808-10.999,5.936 c-2.92,3.439-4.329,7.527-3.819,11.024c3.945-0.029,8.394-2.3,11.095-5.668C52.543,12.664,55.527,8.292,54.923,3.04z" />
        </g>
      </svg>
    </div>
  );
}
