import classNames from 'classnames/bind';
import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import styles from './HelpSection.css';

const cs = classNames.bind(styles);

export class FaqForm extends Component {
  constructor(props) {
    super(props);
    const { obj } = this.props;

    this.state = {
      loading: false,
      validated: false,
      content: !obj ? '' : obj.content,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const value = serialize(form, { hash: true, disabled: false });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addData(value, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  addData(arr, validated) {
    const { obj, update } = this.props;

    if (validated === true) {
      this.setState({
        loading: true,
      });
      if (!obj || !obj.title) {
        api.post('/v1/help/pages/', arr)
          .then(() => {
            this.setState({
              loading: false,
              validated: false,
            });
            update();
          })
          .catch(() => {
            this.setState({
              loading: false,
            });
          });
      } else {
        api.put(`/v1/help/pages/${obj.title}`, arr)
          .then(() => {
            this.setState({
              loading: false,
              validated: false,
            });
            update();
          })
          .catch(() => {
            this.setState({
              loading: false,
            });
          });
      }
    }
  }

  render() {
    const { obj, formHide, index } = this.props;
    const { validated, loading, content } = this.state;

    return (
      <Card
        className={cs(!obj || !obj.title ? 'mb-3' : '')}
        key={index || ''}
      >
        <Form
          noValidate
          className={cs('m-2')}
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Row>
            <Col>
              <Form.Group controlId="Content">
                <Form.Control
                  required
                  size="sm"
                  as="textarea"
                  rows="3"
                  placeholder="Content"
                  value={content || ''}
                  name="content"
                  onChange={(e) => {
                    this.setState({
                      content: e.target.value,
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  Please choose a Content.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col className={cs('text-right')}>
              <Button
                disabled={loading}
                type="submit"
                className={cs('m-1')}
                variant="primary"
                size="sm"
              >
                Save
              </Button>

              <Button
                disabled={loading}
                type="reset"
                className={cs('m-1')}
                variant="secondary"
                size="sm"
                onClick={() => {
                  this.setState({
                    content: obj ? obj.content : '',
                  });
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={loading}
                type="reset"
                className={cs('m-1')}
                variant="secondary"
                size="sm"
                onClick={() => formHide()}
              >
                Сlose
              </Button>
            </Col>
          </Row>
        </Form>

        <Spinner
          animation="border"
          variant="primary"
          className={loading !== false ? 'loadingSpinner justify-content-center' : 'd-none '}
        />
      </Card>
    );
  }
}
