import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export enum EliminationAnswerResultType {
    Correct = 'correct',
    Incorrect = 'incorrect',
}

interface EliminationAnswerFieldsProps {
    stepIndex: number;
    answerIndex: number;
    disabled: boolean;
    activeStepsFunc?: (id: string) => void;
    value: EliminationAnswerResultType;
    onChange: (value: string) => void;
    isCorrectOptionDisabled?: boolean;
}

export function AnswerEliminationType(props: EliminationAnswerFieldsProps) {
  const {
    answerIndex,
    disabled,
    value,
    onChange,
    activeStepsFunc,
    stepIndex,
    isCorrectOptionDisabled = false,
  } = props;
  return (
    <Form.Group
      as={Col}
      controlId={`eliminationResultType-${answerIndex}`}
      className="my-0"
      sm={12}
      md="auto"
    >
      <Row className="d-flex">
        <Col
          className="d-flex mb-0 px-1"
          sm={12}
        >
          <Form.Label
            className="my-auto py-0"
            column
            sm={3}
            md="auto"
          >
            Elimination result
          </Form.Label>
          <Form.Control
            disabled={disabled}
            size="sm"
            as="select"
            value={value || 0}
            name={`steps[${stepIndex}][answers][${answerIndex}][eliminationResultType]`}
            onFocus={() => {
              if (activeStepsFunc) {
                activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-eliminationResultType`);
              }
            }}
            onMouseDown={(e: React.MouseEvent<HTMLSelectElement>) => e.stopPropagation()}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value={EliminationAnswerResultType.Incorrect}>Incorrect</option>
            <option
              disabled={isCorrectOptionDisabled}
              value={EliminationAnswerResultType.Correct}
            >
              Correct
            </option>

          </Form.Control>
        </Col>
      </Row>
    </Form.Group>
  );
}
