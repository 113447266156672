import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Audio } from '../Audio/Audio';
import classNames from './Audios.module.scss';
import { listColumnsSize } from './constants';

export function Audios({
  audios,
  onChange,
}) {
  return (
    <>
      <Row className={classNames.listTile}>
        <Col sm={listColumnsSize.title} className={classNames.listTileColumn}>
          Title / Alias
        </Col>
        <Col sm={listColumnsSize.duration} className={classNames.listTileColumn}>
          Duration
        </Col>
        <Col sm={listColumnsSize.type} className={classNames.listTileColumn}>
          Type
        </Col>
        <Col sm={listColumnsSize.actions} className={classNames.listTileColumn}>
          Actions
        </Col>
      </Row>
      <ul className={classNames.list}>
        {
        audios.map((audio) => (
          <li
            key={audio.id}
            className={classNames.listItem}
          >
            <Audio
              key={audio.id}
              audio={audio}
              onChange={onChange}
            />
          </li>
        ))
      }
      </ul>
    </>
  );
}
