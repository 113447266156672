import React, { ReactNode } from 'react';
import Form from 'react-bootstrap/Form';
// @ts-ignore: no def
import DatePicker from 'react-datepicker';

const createFilterTimeBefore = (earliestDate: Date) => (selected: Date) => {
  const selectedDate = new Date(selected);

  return earliestDate.getTime() < selectedDate.getTime();
};

interface DateTimeFieldProps {
    label: ReactNode,
    value: string
    onChange: (date: Date) => void,
    minDateTime: Date,
    error?: string,
}

export function DateTimeField(props: DateTimeFieldProps) {
  const {
    value,
    onChange,
    minDateTime,
    label,
    error,
  } = props;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <DatePicker
        showTimeSelect
        dateFormat="Pp"
        selected={value ? new Date(value) : null}
        onChange={onChange}
        filterTime={createFilterTimeBefore(minDateTime)}
        minDate={minDateTime}
        className="form-control"
      />
      {Boolean(error) && (
        <Form.Control.Feedback
          type="invalid"
          className="d-block"
        >
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
}
