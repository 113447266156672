import async from 'async';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { api } from '../../api';
import { Panel } from './Panel';
import './ValidatePanel.scss';

export class InfoPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: [],
      loading: true,
    };
  }

  componentDidUpdate(prevProps) {
    const { show } = this.props;

    if (show !== prevProps.show && show === true) {
      this.loadData();
    }
  }

  loadData() {
    const { storyId } = this.props;

    this.setState({
      loading: true,
    });
    async.parallel({
      types: (callback) => {
        api.get('/v1/stats/types')
          .then((res) => {
            callback(null, res.data.types);
          }).catch((error) => {
            callback(error, null);
          });
      },
      info: (callback) => {
        api.get(`/v1/stories/${storyId}/info`)
          .then((res) => {
            callback(null, res.data.info);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            info: res.info,
            hearts: res.info.hearts,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
        });
      }
    });
  }

  render() {
    const {
      show,
      onHide,
      isAnalyticsVisible,
    } = this.props;
    const {
      hearts, loading, info,
    } = this.state;

    return (
      <Panel
        pageTitle="Info"
        show={show}
        onHide={onHide}
        loading={loading}
      >

        <div className="WordCountBox">

          <Row>
            <Col>
              <b>Word Count:</b>
            </Col>
            <Col className="text-right">
              <b>{info.wordCount || '0'}</b>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col><h5>Choice Stats</h5></Col>
          </Row>
          <Row>
            <Col>
              <b>Decision Points:</b>
            </Col>
            <Col className="text-right">
              <b>{info.choiceStepsCount || '0'}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Choices:</b>
            </Col>
            <Col className="text-right">
              <b>{info.answersCount || '0'}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Bonus Content:</b>
            </Col>
            <Col className="text-right">
              <b>{info.bonusContent || '0'}</b>
            </Col>
          </Row>

          <hr />
          {isAnalyticsVisible && (
          <>
            <Row>
              <Col><h5>Analytics</h5></Col>
            </Row>
            <Row>
              <Col>
                <b>Completion Rate:</b>
              </Col>
              <Col className="text-right">
                <b>{info && info.analytics && info.analytics.dropoff && !!Number.parseFloat(info.analytics.dropoff) ? Number.parseFloat(info.analytics.dropoff).toFixed(2) : '0.00'}</b>
                %
              </Col>
            </Row>
            <Row>
              <>
                <Col>
                  <b>Total Hearts:</b>
                </Col>
                <Col className="text-right">
                  <b>{hearts && hearts.length ? hearts[0].totalHearts : '0'}</b>
                </Col>
              </>
            </Row>
            <hr />
          </>
          )}

        </div>

      </Panel>
    );
  }
}
