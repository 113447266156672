export function prepareForCopy(obj) {
  if (obj === null || undefined === obj || typeof obj !== 'object') return obj;

  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = prepareForCopy(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    const copy = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in obj) {
      if (obj.ind) {
        // eslint-disable-next-line no-param-reassign
        obj.ind = null;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key) && (key !== 'id') && key !== 'branchId') copy[key] = prepareForCopy(obj[key]);
    }
    return copy;
  }
  return undefined;
}

export function clearStepData() {
  localStorage.removeItem('copyStep');
}

export function setStepData(obj) {
  const tmp = [];
  tmp.push(obj);
  localStorage.setItem('copyStep', JSON.stringify(tmp));
}

export function getStepData() {
  const str = localStorage.getItem('copyStep');
  if (str) {
    return JSON.parse(str);
  }
  return null;
}
