import React from 'react';
import classNames from './InstructionDropZone.module.scss';

export function InstructionDropZoneCharacter() {
  return (
    <div className={classNames.container}>
      <h4 className={classNames.textHeader}>Quick Guidelines for Uploading Character Assets:</h4>
      <div className={classNames.text}>
        <h5 id="requirements">Requirements</h5>
        <ul>
          <li>Must use approved expressions</li>
          <li>
            {'File Names: '}
            <code>[characterName]_[expression].png</code>
            {'. Example: '}
            <code>DorianGray_sad.png</code>
          </li>
          <li>Size: Width x Height</li>
          <li>Minimum: 400px x 400px</li>
          <li>Recommended: 843px x 1200px </li>
          <li>
            {'Supported Formats: '}
            <code>*.png</code>
          </li>
          <li>You can upload a maximum of 100 files at a time</li>
        </ul>
        <h5 id="notes">Notes</h5>
        <ul>
          <li>Try a Sample: We recommend testing some sample images in the node-view emulator before bulk uploading</li>
          <li>
            No Editing Support: Once uploaded, assets cannot be edited, deleted, or
            <em>{' cropped'}</em>
          </li>
        </ul>
      </div>
      <h4 className={classNames.dropHere}>Drop the files here OR click to choose files...</h4>
    </div>
  );
}
