import React from 'react';
import './CoverBgImg.scss';

export function CoverBgImg(props) {
  const { obj } = props;

  if (!obj.coverLocationId) {
    return (<div className="CoverBgImgBox" />);
  }
  const location = obj.locations.find((a) => Number(a.id) === Number(obj.coverLocationId));
  if (!location || !location.image || !location.image.imageUrl) {
    return (<div className="CoverBgImgBox" />);
  }
  const src = location.image.imageUrl;
  return (
    <div className="CoverBgImgBox" style={{ backgroundImage: `url('${src}')` }} />
  );
}
