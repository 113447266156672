import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { auth } from '../../Auth/Auth';
import { InputFormField } from '../InputFormField/InputFormField';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './ConfirmEmailForm.module.scss';
import { useConfirmEmailForm } from './useConfirmEmailForm';

export interface ConfirmEmailFormValues {
  username: string;
  code: string;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Please enter a valid email.'),
  code: Yup.string()
    .required('Please enter a code.')
    .min(6, 'Please enter a valid code.')
    .max(6, 'Please enter a valid code.'),
});

export function ConfirmEmailForm() {
  const {
    isLoading,
    setToLoading,
    isError,
    statusMessage,
    handleSubmit,
    setToError,
  } = useConfirmEmailForm();

  const formikRef = useRef<FormikProps<ConfirmEmailFormValues>>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code') ?? '';
  const username = searchParams.get('username') ?? '';

  const initialValues: ConfirmEmailFormValues = {
    username,
    code,
  };

  const resendCode = async () => {
    const userName = formikRef.current?.values.username ?? '';
    if (userName) {
      setToLoading();
      try {
        await userApi.resendCode({
          username: userName,
          resetPassword: false,
          mobile: false,
        });
        setToError('New code sent to your email.');
      } catch (e) {
        const message = auth.getErrorMessage(e) ?? 'Error occurred while trying to resend code.';
        setToError(message);
        logger.error(message);
      }
    } else {
      setToError('Email or username is required.');
    }
  };

  return (
    <Container>
      <Row>
        {isError && (
        <Alert variant="danger" className={styles.formAlert}>
          {statusMessage}
        </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <Row className={styles.formTitle}>
            <Col>
              <h3>Verify your email</h3>
            </Col>
          </Row>
          <Row className={styles.formMessage}>
            <Col md={10}>
              Enter the confirmation code we sent to your email
              address to create a new password
            </Col>
          </Row>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} hidden={username} label="User name" name="username" type="text" />
              <InputFormField disabled={isLoading} label="Code" name="code" type="text" autoFocus />
              <SubmitButton label="Submit" isLoading={isLoading} />
            </Form>
          </Formik>
        </Col>
      </Row>
      <div className={styles.formSubText}>
        <span>Didn`t get a code?  Check your email address or  </span>
        <button
          disabled={isLoading}
          type="button"
          onClick={() => resendCode()}
        >
          try again.
        </button>
      </div>
    </Container>
  );
}
