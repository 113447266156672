import React, { useEffect, useRef } from 'react';
import { convertAssetsImageToURL } from '../../../utils/urlUtils';

function drawImageAreaCustomCharacter(avatarCanvasRef, expressionValue) {
  const avatarCanvas = avatarCanvasRef.current;
  const ctx = avatarCanvas.getContext('2d');
  ctx.imageSmoothingQuality = 'high';
  avatarCanvas.style.width = '100%';
  avatarCanvas.style.height = '100%';
  const img = new Image();
  img.src = convertAssetsImageToURL(expressionValue?.imageURL);
  img.onload = () => {
    avatarCanvas.width = img.width;
    avatarCanvas.height = img.height;
    ctx.clearRect(0, 0, avatarCanvas.width, avatarCanvas.height);
    ctx.drawImage(
      img,
      expressionValue?.portraitCoordinates?.x,
      expressionValue?.portraitCoordinates?.y,
      expressionValue?.portraitCoordinates?.size,
      expressionValue?.portraitCoordinates?.size,
      0,
      0,
      avatarCanvas.width,
      avatarCanvas.height,
    );
  };
}

/**
 * Draw image area
 * @param value - String. Expression value
 * @returns {JSX.Element}
 * @constructor
 */
export function ImageArea({
  value: expressionValue,
  children,
}) {
  const avatarCanvasRef = useRef(undefined);

  useEffect(() => {
    drawImageAreaCustomCharacter(avatarCanvasRef, expressionValue);
  }, [avatarCanvasRef, expressionValue]);

  return (
    <>
      <canvas
        ref={avatarCanvasRef}
        style={{
          transform: 'translate(0%, 0%)',
          position: 'absolute',
          borderRadius: '10px',
          top: 0,
          left: 0,
          padding: '1px 0.25rem 0 0.25rem',
        }}
      >
        Your browser does not support the HTML5 canvas tag.
      </canvas>
      { children }
    </>
  );
}
