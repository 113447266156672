import { BookLocation } from '../../../../dorian-shared/types/bookLocation/bookLocation';
import { Branch } from '../../../../dorian-shared/types/branch/Branch';
import { MemoryDTO } from '../../Book/MemoryBank/memoryBankTypes';
import { EpisodeTextImportNode } from '../Models/EpisodeTextImportNode';

export enum EpisodeTextColumnKey {
  NodeOrSpeaker = 0,
  Text = 1,
  Description = 2,
}

export enum EpisodeTextEntityType {
  Node = 'node',
  EmptyStep = 'emptyStep',
  Dialogue = 'dialogue',
  Thinking = 'thinking',
  Reaction = 'reaction',
  Narration = 'narration',
  Choice = 'choice',
  Answer = 'answer',
  Skip = 'skip',
  GotoFromNode = 'goto',
  Chat = 'chat',
  Remember = 'remember',
  Check = 'check',
}

export type EpisodeTextImportNodeValidationConfig = {
  nodesInImport: EpisodeTextImportNode[],
  maxLengthName?: number;
  maxLengthText?: number;
  nodeNamesInEpisode?: string[];
  bookLocations?: BookLocation[];
}

export type EpisodeTextImportStepValidationConfig = {
  nodesInImport: EpisodeTextImportNode[],
  maxLengthName?: number;
  maxLengthText?: number;
  nodesInEpisode?: Branch[];
  characterNamesInBook?: string[];
  memoriesInBook?: MemoryDTO[];
}
