import { isEqual } from 'lodash-es';
import React, {
  createContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';
import { PerformanceRanksModalContextProviderProps, PerformanceRanksModalContextType } from './PerformanceRanksModalContextTypes';

export const PerformanceRanksModalContext = createContext<PerformanceRanksModalContextType | undefined>(undefined);

export function PerformanceRanksModalContextProvider(props: PerformanceRanksModalContextProviderProps) {
  const {
    children, ranks, onHide, disabled,
  } = props;
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [performanceRanks, setPerformanceRanks] = useState<IPerformanceRankDTO[]>(ranks);

  useEffect(() => {
    setPerformanceRanks(ranks);
  }, [ranks]);

  const hasValueChanged = useCallback((value: IPerformanceRankDTO) => {
    const hasChanged = !isEqual(value, performanceRanks.find((a) => a.variable.id === value.variable.id));
    return hasChanged;
  }, [performanceRanks]);

  const haveValuesChanged = useCallback((values: IPerformanceRankDTO[]) => values.some((value) => hasValueChanged(value)), [hasValueChanged]);

  const value: PerformanceRanksModalContextType = useMemo(() => ({
    isFormLoading,
    setIsFormLoading,
    onHide,
    performanceRanks,
    setPerformanceRanks,
    hasValueChanged,
    haveValuesChanged,
    disabled,
  }), [isFormLoading, onHide, performanceRanks, hasValueChanged, haveValuesChanged, disabled]);

  return (
    <PerformanceRanksModalContext.Provider value={value}>
      {children}
    </PerformanceRanksModalContext.Provider>
  );
}

export const usePerformanceRanksModalContext = () => {
  const context = React.useContext(PerformanceRanksModalContext);
  if (context === undefined) {
    throw new Error('usePerformanceRanksModalContext must be used within a PerformanceRanksModalContextProvider');
  }
  return context;
};
