import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { Event } from '../../../../dorian-shared/types/event/Event';
import { api } from '../../../api';
import { EventFormValues, createFormDataFromFormValues } from '../components/EventForm/EventForm';

export function useCreateEvent() {
  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const createEvent = (values: Omit<EventFormValues, 'detailedEventPrizes'>) => {
    setToLoading();
    const formData = createFormDataFromFormValues(values);

    return api.post<{ event: Event }>('/v1/events/', formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setToSuccess();
        return response.data.event.id;
      })
      .catch((error) => {
        const possibleErrorFromServer = error.response.data?.error ?? 'There was issue when creating event, please contact support or try again later.';
        setToError();
        throw new Error(possibleErrorFromServer);
      });
  };
  return { isLoading, createEvent };
}
