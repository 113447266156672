import React from 'react';
import { SignInForm } from '../../../uiV2/SignInForm/SignInForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';

export function SignIn() {
  return (
    <LoginLayout>
      <SignInForm />
    </LoginLayout>
  );
}
