import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import Arr from '../../../assets/images/arr.svg';
import './CharactersCatList.scss';
import { CharactersTableRow } from './CharactersTableRow';

export class CharactersCatList extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      activeKey: true,
    };
  }

  render() {
    const {
      index,
      characterGroup,
      disabledRow,
      disabled,
    } = this.props;

    const { activeKey } = this.state;

    return (
      <div>
        <Accordion defaultActiveKey={index}>
          <Accordion.Toggle
            as="h5"
            className="CharactersCatListTitle"
            eventKey={index}
            onClick={() => {
              this.setState({
                activeKey: !activeKey,
              });
            }}
          >
            <i className="icon">
              <img
                className={`list-column-header-toggle-img ${activeKey ? 'active' : 'inactive'}`}
                src={Arr}
                alt=">"
              />
            </i>
            {characterGroup.category}
          </Accordion.Toggle>

          <div
            className={!activeKey ? 'collapse' : 'collapse show'}
          >
            {
              characterGroup.list.map((character) => (
                <CharactersTableRow
                  key={character.id}
                  character={character}
                  {...this.props}
                  disabled={disabledRow || disabled}
                />
              ))
            }
          </div>
        </Accordion>

      </div>
    );
  }
}
