import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormFile,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  Row,
  Spinner,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import Cropper from 'react-cropper';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';
import { MemoryIcon } from '../../Book/MemoryBank/memoryBankTypes';
import { calculateMaxSideByAspectRatio, convertCanvasToFileWithCropper } from '../utils';
import classes from './MemoryIconUploader.module.scss';

export type MemoryIconUploaderProps = {
  imageSrc: string;
  onSave: (imageSrc: File, label: string) => Promise<MemoryIcon>
  onCancel: () => void,
  onLabelValidate: (label: string) => string | undefined,
  isLoading: boolean,
}

const acceptFileTypes = '.png';

export function MemoryIconUploader(props: MemoryIconUploaderProps) {
  const {
    imageSrc, onSave, onCancel, isLoading, onLabelValidate,
  } = props;

  const [cropper, setCropper] = useState<Cropper>();
  const [label, setLabel] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      return;
    }

    if (!cropper) {
      showToast({ textMessage: 'Error: Cropper initialisation error.', variant: 'danger' });
      return;
    }
    const { aspectRatio } = cropper.getImageData();
    const { maxWidth, maxHeight } = calculateMaxSideByAspectRatio(aspectRatio, 360, 360);

    try {
      const file = await convertCanvasToFileWithCropper(
        cropper,
        {
          maxWidth,
          maxHeight,
          imageSmoothingQuality: 'high',
          imageSmoothingEnabled: true,
        },
        'newIcon.png',
        'image/png',
      );

      await onSave(file, label);
      showToast({ textMessage: 'Memory Icon saved', variant: 'success' });
    } catch (error) {
      if (error instanceof Error) {
        showToast({ textMessage: error.message, variant: 'danger' });
        bugTracker().reportError({ name: 'MemoryIconUploader', message: error.message });
      } else {
        showToast({ textMessage: 'An error occurred while saving the image', variant: 'danger' });
        bugTracker().reportError({ name: 'MemoryIconUploader', message: 'An error occurred while saving the image' });
      }
    }
  };

  const onChangeFile = (event: { target: HTMLInputElement}) => {
    if (!event.target.files) {
      showToast({ textMessage: 'File not selected', variant: 'danger' });
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      showToast({ textMessage: 'File not selected', variant: 'danger' });
      return;
    }
    if (!file.type.includes('image/')) {
      showToast({ textMessage: 'File type not supported', variant: 'danger' });
      return;
    }

    setSelectedFile(file);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileImageSrc = fileReader.result?.toString() ?? '';
      cropper?.replace(fileImageSrc);
    };
    fileReader.readAsDataURL(file);
  };

  const isLabelValid = !onLabelValidate(label);
  const isFileSelected = !!selectedFile;
  const isValidated = isLabelValid && isFileSelected;

  return (
    <Modal
      show
      onHide={onCancel}
      backdrop="static"
      keyboard={false}
      centered
      backdropClassName={classes.memoryIconUploaderBackdrop}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader>
          <ModalTitle className="m-auto">
            Memory Icon Image
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className={classes.memoryIconFieldCropContainer}>
            <Cropper
              style={{ height: '100%', width: '100%' }}
              zoomTo={0}
              zoomable={false}
              zoomOnWheel={false}
              dragMode="crop"
              aspectRatio={1}
            // minContainerHeight={600}
            // minContainerWidth={600}
              minCropBoxHeight={100}
              minCanvasHeight={300}
              autoCropArea={1}
              restore={false}
              center={false}
              toggleDragModeOnDblclick={false}
              initialAspectRatio={1}
              src={imageSrc}
              viewMode={1}
              background
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance: Cropper) => setCropper(instance)}
            />
          </div>
          <Row>
            <Col sm={6}>
              <FormGroup className="position-relative">
                <FormLabel
                  htmlFor="label"
                  className={classes.memoryLabel}
                >
                  File
                </FormLabel>
                <FormFile
                  id="memoryIconFile"
                  disabled={isLoading}
                  accept={acceptFileTypes}
                  onChange={onChangeFile}
                  required
                />
                {/* Need to validating */}
                <FormControl
                  type="hidden"
                  isInvalid={!isFileSelected}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  Required
                </FormControl.Feedback>
              </FormGroup>

            </Col>
            <Col sm={6}>
              <FormGroup className="position-relative">
                <FormLabel
                  htmlFor="label"
                  className={classes.memoryLabel}
                >
                  Name
                </FormLabel>
                <FormControl
                  id="memoryIconLabel"
                  value={label}
                  onChange={(event) => setLabel(event.target.value)}
                  placeholder="Label"
                  disabled={isLoading}
                  isInvalid={!isLabelValid}
                  required
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {onLabelValidate(label)}
                </FormControl.Feedback>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isLoading || !isValidated}
          >
            {isLoading && (
            <Spinner
              size="sm"
              variant="secondary"
              animation="border"
            />
            )}
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
