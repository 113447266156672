import React, { PureComponent } from 'react';
import {
  Alert, Button, Col, ListGroup, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../../api';
import { BranchModalPrevNextSteps } from './BranchModalPrevNextSteps';

export class BranchModalPrevNext extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      loading: false,
      data: [],
      curBranch: 0,
      warningAlert: false,
    };
    const { type } = this.props;

    this.title = type === 'prev' ? 'Previous Nodes' : 'Next Nodes';
    this.disabled = true;
    this.branch = 0;
  }

  loadBranch() {
    const { storyId } = this.props;
    const { curBranch } = this.state;

    this.setState({
      loading: true,
    });
    api.get(`/v1/stories/${storyId}/branches/${this.branch[curBranch]}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.branch,
        });
      });
  }

  render() {
    const {
      actionEditBranch,
      type,
      initEditBranch,
      data,
    } = this.props;
    const {
      edit,
      loading,
      warningAlert,
      curBranch,
      data: dataFromState,
    } = this.state;

    if (data) {
      this.disabled = !(data.links_to && data.links_to.length > 0);
      this.branch = data.links_to;

      if (type === 'prev') {
        this.disabled = !(data.links_from && data.links_from.length > 0);
        this.branch = data.links_from;
      }
    }

    if (warningAlert !== false) {
      return (
        <Alert variant="warning" style={{ width: '100%' }}>

          <p className="text-center my-0 py-0">
            All unsaved data will be lost. Continue?
            <br />

            <Button
              size="sm"
              className="mx-1"
              onClick={() => {
                actionEditBranch(dataFromState);
                this.setState({
                  warningAlert: !warningAlert,
                });
              }}
              variant="secondary"
            >
              Yes
            </Button>
            <Button
              size="sm"
              className="mx-3"
              onClick={() => this.setState({ warningAlert: !warningAlert })}
              variant="secondary"
            >
              No
            </Button>
          </p>

        </Alert>
      );
    }

    if (loading !== false) {
      return (
        <Row style={{ width: '95%' }}>
          <Col className="text-center">
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
              className="justify-content-center"
              style={{ margin: '0 auto' }}
            />
          </Col>
        </Row>
      );
    }

    if (!edit) {
      return (
        <Row style={{ width: '95%' }}>
          <Col className="text-center">
            <Button
              disabled={this.disabled}
              onClick={() => {
                this.setState({
                  edit: !edit,
                });
                this.loadBranch();
                initEditBranch(type);
              }}
              size="sm"
              type="submit"
              variant="primary"
            >
              {this.title}
            </Button>
          </Col>
        </Row>
      );
    }

    return (
      <Row style={{ width: '95%' }}>
        <Col sm={2} className="text-left">
          <Button
            size="sm"
            disabled={(curBranch < 1)}
            variant="secondary"
            onClick={() => {
              this.setState({
                curBranch: curBranch - 1,
              }, () => this.loadBranch());
            }}
          >
            &lt;
          </Button>
          <span className="px-3">
            <span className="px-1">{curBranch + 1}</span>
            /
            <span className="px-1">{this.branch.length}</span>
          </span>
        </Col>
        <Col className="text-center">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => this.setState({ warningAlert: !warningAlert })}
          >
            {dataFromState.title}
          </Button>
        </Col>
        <Col sm={2} className="text-right">
          <span className="px-3">
            <span className="px-1">{curBranch + 1}</span>
            /
            <span className="px-1">{this.branch.length}</span>
          </span>
          <Button
            size="sm"
            disabled={!((curBranch + 1) < this.branch.length)}
            variant="secondary"
            onClick={() => {
              this.setState({
                curBranch: curBranch + 1,
              }, () => this.loadBranch());
            }}
          >
            &gt;
          </Button>
        </Col>
        <Col sm={1} className="text-right">
          <Button
            onClick={() => {
              this.setState({
                edit: !edit,
              });
              initEditBranch(type);
            }}
            size="sm"
            type="reset"
            variant="secondary"
          >
            Close
          </Button>
        </Col>

        <Col sm={{ span: 11, offset: 1 }}>
          <ListGroup
            as="ol"
            start="0"
            variant="flush"
            className="my-1 ml-4 mr-1 branchesList modalStepList-ol"
          >
            {dataFromState.steps.map((value, i) => (
              <BranchModalPrevNextSteps
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                {...this.props}
                step={value}
              />
            ))}
          </ListGroup>
        </Col>

      </Row>
    );
  }
}
