import moment from 'moment';
import React, { Component } from 'react';
import {
  Alert, Button, Card, Col, Form, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';

const HoursScale = new Array(24).fill(0).map((item, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <option key={index}>
    {index}
  </option>
));
const MinutesScale = new Array(60).fill(0).map((item, index) => (
  // eslint-disable-next-line react/no-array-index-key
  <option key={index} value={index}>
    {index}
  </option>
));

export class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertText: null,
      success: false,
      loading: true,
      maintenance_active: false,
      // eslint-disable-next-line react/no-unused-state
      maintenance_timer: null,
      Hours: 0,
      Minutes: 0,
    };

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Maintenance',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  showAlert = (status, text) => {
    this.setState({
      alertText: text,
      success: status,
    });
    setTimeout(() => {
      this.setState({
        alertText: null,
      });
    }, 5000);
  };

  componentDidMount() {
    this.loadMaintenance();
  }

  loadMaintenance() {
    api.get('/v1/maintenance')
      .then((res) => {
        this.setState({
          loading: false,
          maintenance_active: res.data.config.maintenance_active,
          // eslint-disable-next-line react/no-unused-state
          maintenance_timer: res.data.config.maintenance_timer,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  updateMaintenance() {
    const {
      Minutes,
      maintenance_active: maintenanceActive,
      Hours,
    } = this.state;

    const data = {
      maintenance_active: maintenanceActive,
      maintenance_timer: maintenanceActive ? moment().add(Hours, 'hours').add(Minutes, 'minutes').valueOf() : null,
    };
    api.put('/v1/maintenance', data)
      .then((res) => {
        this.showAlert(res.data.success, 'Saved');
      })
      .catch((error) => {
        this.showAlert(false, error);
      });
  }

  render() {
    const {
      loading,
      maintenance_active: maintenanceActive,
      success,
      Hours: Hours1,
      alertText,
      Minutes,
    } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Alert variant={success ? 'success' : 'danger'} show={alertText != null}>
          {alertText}
        </Alert>

        <Card>
          <Card.Body>
            <Form.Row>
              <Form.Group as={Col} controlId="Hours">
                <Form.Label>Hours</Form.Label>
                <Form.Control
                  disabled={maintenanceActive || !localStorage.getItem('maintenance_active')}
                  as="select"
                  placeholder="Hours"
                  name="Hours"
                  value={Hours1 || 0}
                  onChange={(e) => {
                    this.setState({
                      Hours: e.target.value,
                    });
                  }}
                >
                  {HoursScale}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="Minutes">
                <Form.Label>Min</Form.Label>
                <Form.Control
                  disabled={maintenanceActive || !localStorage.getItem('maintenance_active')}
                  as="select"
                  placeholder="Minutes"
                  name="Minutes"
                  value={Minutes || 0}
                  onChange={(e) => {
                    this.setState({
                      Minutes: e.target.value,
                    });
                  }}
                >
                  {MinutesScale}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <div className="text-center">
              <Button
                disabled={maintenanceActive}
                variant="outline-success"
                className="mx-2"
                onClick={() => {
                  this.setState({
                    maintenance_active: !maintenanceActive,
                  }, () => {
                    this.updateMaintenance();
                  });
                }}
              >
                Start
              </Button>
              <Button
                disabled={!maintenanceActive}
                variant="outline-danger"
                className="mx-2"
                onClick={() => {
                  this.setState({
                    maintenance_active: !maintenanceActive,
                  }, () => {
                    this.updateMaintenance();
                  });
                }}
              >
                Stop
              </Button>
            </div>

            {loading && (
            <div className="text-center">
              <Spinner
                variant="primary"
                animation="border"
                className="loadingSpinner justify-content-center"
              />
            </div>
            )}

          </Card.Body>
        </Card>

      </PageWrapper>
    );
  }
}
