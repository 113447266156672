import classNames from 'classnames/bind';
import React from 'react';
import { Col, Form } from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export function StepsTypeFieldsRelationships(props) {
  const {
    disabled,
    handleChangeSteps,
    activeStepsFunc,
    stepClass,
    characterRelationship,
    stepIndex,
    displayControl,
  } = props;

  if (displayControl !== true) {
    return null;
  }
  return (
    <Form.Group
      as={Col}
      md={1}
      controlId={`characterRelationship${stepIndex}`}
      className={cs('mb-0 px-1', 'd-block')}
    >
      <Form.Control
        disabled={disabled}
        size="sm"
        type="text"
        placeholder="Relationship"
        name={`steps[${stepIndex}][characterRelationship]`}
        value={characterRelationship || ''}
        onMouseDown={(e) => e.stopPropagation()}
        onFocus={() => activeStepsFunc(stepClass)}
        onChange={(e) => handleChangeSteps(e.target.value, stepIndex, 'characterRelationship')}
      />
      <Form.Control.Feedback type="invalid">
        Please add Relationship.
      </Form.Control.Feedback>
    </Form.Group>
  );
}
