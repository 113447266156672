import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class EditPrompt extends Component {
  constructor(...args) {
    super(...args);
    const {
      limits,
      data,
    } = this.props;

    this.state = {
      validated: false,
      formError: null,
      disabled: data ? data.disabled : false,
      activeContest: data ? data.activeContest : false,
      trending: data ? data.trending : false,
      title: data !== null && data.title ? data.title : '',
      titleValid: limits
        && limits.story_temlate_title_max
        && limits.story_temlate_title_max.value,
      description: data !== null && data.description ? data.description : '',
      descriptionValid: limits
        && limits.story_temlate_description_max
        && limits.story_temlate_description_max.value,
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  savePrompt(obj, validated) {
    if (validated === true) {
      const data = {
        title: obj.title,
        description: obj.description,
        disabled: !(!obj.disabled || obj.disabled !== 'on'),
        activeContest: !(!obj.activeContest || obj.activeContest !== 'on'),
        trending: !(!obj.trending || obj.trending !== 'on'),
        priority: obj.priority || 0,
      };

      const {
        update,
        data: data1,
      } = this.props;

      if (data1 !== null) {
        api.put(`/v1/bookprompts/${data1.id}`, data)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.savePrompt(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  render() {
    const {
      update,
      show,
      onHide,
      limits,
      data,
      ...other
    } = this.props;

    const {
      activeContest,
      loading,
      disabled,
      titleValid,
      title: title1,
      formError,
      description,
      trending,
      descriptionValid,
    } = this.state;

    if (!show) {
      return null;
    }
    const { validated } = this.state;
    let title = 'Edit Story Prompt';
    let defTitle = '';
    let defDescr = '';
    let defPriority = 0;
    if (data !== null && data.title) {
      title = `Edit Story Prompt ${data.title}`;
      defTitle = data.title;
      defDescr = data.description;
      defPriority = data.priority;
    }

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  defaultValue={defTitle}
                  name="title"
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value,
                      titleValid: limits
                          && limits.story_temlate_title_max
                          && limits.story_temlate_title_max.value,
                    });
                  }}
                  className={
                    title1
                      && title1.length > Number(
                        limits
                      && limits.story_temlate_title_max
                      && limits.story_temlate_title_max.value
                          ? limits.story_temlate_title_max.value : 0,
                      ) ? 'text-limit' : null
}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(
                    limits
                      && limits.story_temlate_title_max
                      && limits.story_temlate_title_max.value
                      ? limits.story_temlate_title_max.value : 0,
                  ) - (title1 ? title1.length : 0)}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  Please choose Title.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="Description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Description"
                  defaultValue={defDescr}
                  name="description"
                  onChange={(e) => {
                    this.setState({
                      description: e.target.value,
                      descriptionValid:
                        limits
                          && limits.story_temlate_description_max
                          && limits.story_temlate_description_max.value,
                    });
                  }}
                  className={
                    description
                      && description.length > Number(
                        limits
                      && limits.story_temlate_description_max
                      && limits.story_temlate_description_max.value
                          ? limits.story_temlate_description_max.value : 0,
                      ) ? 'text-limit' : null
}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(
                    limits
                      && limits.story_temlate_description_max
                      && limits.story_temlate_description_max.value
                      ? limits.story_temlate_description_max.value : 0,
                  ) - (description
                    ? description.length
                    : 0)}
                </Form.Text>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="Title">
                <Form.Label>Priority</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Priority"
                  defaultValue={defPriority}
                  name="priority"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="Value">
                <Form.Check
                  disabled={activeContest || trending}
                  custom
                  id="disabled"
                  name="disabled"
                  checked={disabled}
                  onChange={(e) => {
                    this.setState({ disabled: e.target.checked });
                  }}
                  label="Disabled"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="activeContestValue">
                <Form.Check
                  disabled={disabled}
                  custom
                  id="activeContest"
                  name="activeContest"
                  checked={activeContest}
                  onChange={(e) => {
                    this.setState({ activeContest: e.target.checked });
                  }}
                  label="Active Contest"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="trendingValue">
                <Form.Check
                  disabled={disabled}
                  custom
                  id="trending"
                  name="trending"
                  checked={trending}
                  onChange={(e) => {
                    this.setState({ trending: e.target.checked });
                  }}
                  label="Trending"
                />
              </Form.Group>

            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={
                  loading
                  || (description.length > Number(descriptionValid))
                  || (title1.length > Number(titleValid))
                }
            >
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}
