import { storyTitleFormatter } from '../tableUtils/tableFormatters';

export const heartsTopStoriesColumns = [{
  dataField: 'story.title',
  text: 'Episode / Story',
  sort: false,
  formatter: storyTitleFormatter,
}, {
  dataField: 'hearts_spent',
  text: 'Hearts',
  sort: false,
},
];

export const heartsTopStoriesHeaders = [
  { label: 'Started', key: 'hearts_spent' },
  { label: 'Story', key: 'story.title' },
];
