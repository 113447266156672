import React, { Component } from 'react';

export class AvatarTab extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {
      obj, update, avatar, tab,
    } = this.props;

    if (!avatar.type || !obj) {
      return null;
    }

    if (!obj.type.find((item) => item === avatar.type)) {
      return null;
    }

    return (
      // eslint-disable-next-line max-len
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={() => {
          update(obj.id);
        }}
        className={`tab ${obj.id === tab ? 'selected' : ''}`}
      >
        <div
          className="tabLabel"
        >
          {obj.title}
        </div>
      </div>
    );
  }
}
