import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { UserApiErrorCode, UserLoginProvider } from '../../../dorian-shared/types/user/UserApi';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { IdentityProvider } from '../../../shared/types';
import { auth } from '../../Auth/Auth';
import { LoginFormValues } from './SignInForm';

export interface AuthenticateResponseErrorData {
  errorCode: UserApiErrorCode,
  data: {
    identityProvider: IdentityProvider
  }
}

export function useSignInForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      statusMessage,
      setToSuccess,
      isSuccess,
    },
  ] = useAsyncOperationState();

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const successMessage = searchParams.get('successMessage');
    if (successMessage) {
      setToSuccess(successMessage);
    }

    const error = searchParams.get('errorMessage');
    if (error) {
      setToError(error);
    }
  }, [location.search, setToError, setToSuccess]);

  function authenticate(values: LoginFormValues) {
    // Moved from legacy code
    const createTemplateInLocalStorage = localStorage.getItem('createTemplate');
    const createTemplate = createTemplateInLocalStorage
      ? JSON.parse(createTemplateInLocalStorage)
      : null;
    if (createTemplate) {
      createTemplate.signIn = true;
    }
    // End of moved from legacy code

    localStorage.setItem('createTemplate', JSON.stringify(createTemplate));

    auth.initialise()
      .then(() => {
        const profile = userApi.getProfile();
        if (profile?.login_provider === UserLoginProvider.Legacy) {
          history.push(`/sign-up?type=register&username=${encodeURIComponent(values.email)}`);
          return;
        }
        // Used assign because we want to reload page
        window.location.assign('/');
      })
      .catch((initialiseError) => {
        const message = auth.getErrorMessage(initialiseError) ?? 'Error occurred while trying to login.';
        setToError(message);
        logger.error(initialiseError);
      });
  }

  function catchAuthenticate(
    responseData: AuthenticateResponseErrorData,
    values: LoginFormValues,
    authenticateError: AxiosError,
  ) {
    const { errorCode, data } = responseData;
    switch (errorCode) {
      case UserApiErrorCode.IS_EXTERNAL: {
        const { identityProvider }: { identityProvider: IdentityProvider } = data;
        if (identityProvider) {
          userApi.login(identityProvider);
        }
        break;
      }
      case UserApiErrorCode.IS_UNVERIFIED: {
        userApi.resendCode({
          username: values.email,
          resetPassword: false,
          mobile: false,
        })
          .then(() => {
            history.push(`/confirm-email?username=${encodeURIComponent(values.email)}`);
          })
          .catch((resendCodeError) => {
            const message = auth.getErrorMessage(resendCodeError) ?? 'Resend code failed.';
            setToError(message);
            logger.error(resendCodeError);
          });

        break;
      }
      case UserApiErrorCode.NO_SUCH_EMAIL: {
        const message = 'Sign-in failed. Please check email and password and try again.';
        setToError(message);
        logger.error(authenticateError);
        break;
      }
      default: {
        const message = auth.getErrorMessage(authenticateError) ?? 'Sign-in failed. Something went wrong. Please try again later.';
        setToError(message);
        logger.error(authenticateError);
        break;
      }
    }
  }

  const handleSubmit = (values: LoginFormValues) => {
    setToLoading();

    userApi.authenticate({
      username: values.email,
      password: values.password,
      legacy: false,
    })
      .then((response) => {
        const { documents } = response.data;
        if (documents) {
          localStorage.setItem('documents', JSON.stringify(documents));
        }
        authenticate(values);
      })
      .catch((authenticateError) => {
        const { data } : { data : AuthenticateResponseErrorData} = authenticateError.response;
        if (!data) {
          const message = auth.getErrorMessage(authenticateError) ?? 'Sign-in failed. No data returned.';
          setToError(message);
          logger.error(authenticateError);
          return;
        }
        catchAuthenticate(data, values, authenticateError);
      });
  };
  return {
    statusMessage,
    handleSubmit,
    isError,
    isSuccess,
    isLoading,
  };
}
