import React, { useEffect, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { bugTracker } from '../../../../services/bugTracker/BugTrackerService';
import { showToast } from '../../../ui/utils';

export function BrokenTryCatchComponent() {
  const [isBrokenComponentShown, setIsBrokenComponentShown] = useState(false);

  useEffect(
    () => {
      if (isBrokenComponentShown) {
        try {
          throw new Error('Try to call error during testing of try-catch error');
        } catch (error) {
          showToast({
            textMessage: 'Error thrown. Check the error on the bug tracker service',
            variant: 'info',
            timeout: 4000,
          });
          bugTracker().reportError({ name: 'Error during testing of try-catch error', message: error.message });
        }
      }
    },
    [isBrokenComponentShown],
  );

  const handleThrowErrorClick = () => {
    setIsBrokenComponentShown(true);
    setTimeout(() => setIsBrokenComponentShown(false), 3000);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Throw error in broken try-catch block</Card.Title>
        <Alert variant="info">
          Error name:
          {' '}
          <b>Error during testing of try-catch error </b>
        </Alert>
        <Button
          size="sm"
          variant="primary"
          onClick={handleThrowErrorClick}
          disabled={isBrokenComponentShown}
          aria-controls="example-collapse-text"
          aria-expanded={isBrokenComponentShown}
        >
          Throw error
        </Button>
      </Card.Body>
    </Card>
  );
}
