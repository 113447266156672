import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { DeleteTag } from './DeleteTag';
import './Tag.css';

export class TagTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.update();
    };

    const deleteRow = () => {
      // eslint-disable-next-line react/destructuring-assignment
      if (this.state.modalDeleteShow === true) {
        return (
          <DeleteTag
            /* eslint-disable-next-line react/destructuring-assignment */
            show={this.state.modalDeleteShow}
            onHide={modalDeleteClose}
            /* eslint-disable-next-line react/destructuring-assignment */
            obj={this.props.obj}
          />
        );
      }
      return null;
    };

    return (
      <div className="tagItem">
        <span>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {this.props.obj.title}
        </span>
        <Button
          onClick={() => this.setState({ modalDeleteShow: true })}
          variant="primary"
          size="sm"
          className="close"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </Button>
        {deleteRow()}
      </div>
    );
  }
}
