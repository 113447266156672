import {Branch, MemoryBank, Story} from './bookEconomy';

export enum MemoryGrantType {
  Increase = 'increase',
  Decrease = 'decrease',
  Set = 'set',
}

export type BranchList = Branch[];

export type MemoryGrant = {
  id: number;
  branch: string;
  step: number;
  memory: string;
  points: number;
  type: MemoryGrantType; // increase, decrease, set
}

export type MemoryCheck = {
  id: number;
  branch: string;
  step: number;
  memory: string;
  operator: string;
}

// flow-memory structure
export type MemoryEconomy = {
  totalPoints?: number;
  grantIds: number[];
  checkIds: number[];
}

const grantToPoints = (grant: MemoryGrant): number => (
    grant.type === MemoryGrantType.Decrease ? -grant.points : grant.points
);

const reduceGrantsToPoints = (grants: MemoryGrant[]): number => (
    grants.map(grantToPoints).reduce((acc, points) => acc + points, 0)
);

// resulting structure, after all flows are processed
export type MemoryEconomyStats = {
  // MAX FREE POINTS
  freeModeMaxPoints: number;
  // MAX PREMIUM POINTS
  paidModeMaxPoints: number;
  // MIN POINTS
  minPoints: number;

  // MIN - DECREASE GRANTS
  minDecreaseGrants: number;
  // MIN - DECREASE POINTS
  minDecreasePoints: number;

  // MAX FREE - TOTAL GRANTS
  freeModeMaxGrants: number;
  // MAX PREMIUM - TOTAL GRANTS
  paidModeMaxGrants: number;
  // MIN - TOTAL GRANTS #
  minTotalGrants: number;

  // MAX PREMIUM - FREE GRANTS
  paidModeMaxFreeGrants: number;
  // MIN - FREE GRANTS #
  freeModeMinGrants: number;

  // MAX PREMIUM - PREMIUM GRANTS
  paidModeMaxPaidGrants: number;
  // MAX PREMIUM - FREE NESTED GRANTS
  paidModeMaxFreeNestedGrants: number;

  // MAX FREE ROUTE - CHECKS
  maxFreeModeMaxChecks: number;
  // MAX PREMIUM ROUTE - CHECKS
  paidModeMaxChecks: number;

  // MIN CHECK POINT AMOUNT
  minChecks: number;
  // MAX CHECK POINT AMOUNT
  maxChecks: number;
}

export type MemoriesEconomyStats = Record<string, MemoryEconomyStats>;

export type MemoryStorySummary = Partial<MemoryEconomyStats>;
export type MemoriesStorySummary = Record<string, MemoryStorySummary>;

export type TotalMemoryEconomy = {
  stats: MemoriesEconomyStats;
}

export type MemoriesEconomy = Record<string, MemoryEconomy>;

export type StoryFlow = {
  memories: MemoriesEconomy;
  branches?: string[];
  paid: boolean;
  memoryBank?: MemoryBank;
}
export type StoryFlowDraft = Omit<StoryFlow, 'flows' | 'memories'> & {
  branches: string[];
  isDone: boolean;
}

const createDefaultMemoryEconomyStats = (): MemoryEconomyStats => ({
  freeModeMaxPoints: 0,
  paidModeMaxPoints: 0,
  minPoints: 1000000,

  minDecreaseGrants: 1000000,
  minDecreasePoints: 1000000,

  freeModeMaxGrants: 0,
  paidModeMaxGrants: 0,
  minTotalGrants: 1000000,

  paidModeMaxFreeGrants: 0,
  freeModeMinGrants: 1000000,

  paidModeMaxPaidGrants: 0,
  paidModeMaxFreeNestedGrants: 0,

  maxFreeModeMaxChecks: 0,
  paidModeMaxChecks: 0,

  minChecks: 1000000,
  maxChecks: 0,
});

export const getMemoriesByStoryFlow = (story: Story, branches: string[], affectionPointsMemories: string[]): MemoriesEconomy => {
  const memories: MemoriesEconomy = {};

  for (const memory of affectionPointsMemories) {
    const grants: MemoryGrant[] = story.grants?.filter(grant => grant.memory === memory && branches.includes(grant.branch)) ?? [];
    const checks: MemoryCheck[] = story.checks?.filter(check => check.memory === memory && branches.includes(check.branch)) ?? [];

    if (grants.length > 0 || checks.length > 0) {
      memories[memory] = {
        grantIds: grants.map(grant => grant.id),
        checkIds: checks.map(check => check.id),
      }
    }
  }

  return memories;
};

export class AnalyseStoryFlowsEconomyManager {
  public allFreeBranchesNames: Set<string> = new Set();
  public paidBranchesNames: Set<string> = new Set();
  public memoriesEconomyStats: MemoriesEconomyStats = {};
  public memoryNames: string[] = [];
  public freeFlowsGrantIds: Set<number> = new Set<number>();

  constructor(private _story: Story, private _onlyFree: boolean) {}

  public pushFlow(flow: StoryFlow) {
    if (this._onlyFree && !flow.paid) {
      for (const branch of flow.branches!) {
        this.allFreeBranchesNames.add(branch);
      }

      for (const memory of this.memoryNames) {
        const memoryEconomy = flow.memories[memory];
        if (!memoryEconomy) {
          continue;
        }

        for (const grantId of memoryEconomy.grantIds) {
          this.freeFlowsGrantIds.add(grantId);
        }
      }
    }

    for (const memory of Object.keys(flow.memories)) {
        if (!this.memoryNames.includes(memory)) {
            this.memoryNames.push(memory);
        }
        if (!this.memoriesEconomyStats[memory]) {
            this.memoriesEconomyStats[memory] = createDefaultMemoryEconomyStats();
        }
    }

    for (const memory of this.memoryNames) {
      const memoryEconomy = flow.memories[memory];
      if (!memoryEconomy) {
        continue;
      }

      const resultMemory = this.memoriesEconomyStats[memory];
      const memoryGrants = this._story.grants!.filter(grant => memoryEconomy.grantIds.includes(grant.id));
      const totalGrants = memoryGrants.length;
      const totalDecreaseGrants = memoryGrants.filter(grant => grant.type === MemoryGrantType.Decrease);
      const freeGrants = memoryGrants.filter(grant => !this.paidBranchesNames.has(grant.branch));
      const paidGrants = memoryGrants.filter(grant => this.paidBranchesNames.has(grant.branch));
      // final points in this particular flow
      const freePoints = reduceGrantsToPoints(freeGrants);
      const paidPoints = reduceGrantsToPoints(paidGrants);
      const totalPoints = paidPoints + freePoints;
      memoryEconomy.totalPoints = totalPoints;

      if (flow.paid) {
        // paid mode
        resultMemory.paidModeMaxGrants = Math.max(resultMemory.paidModeMaxGrants, totalGrants);
        resultMemory.paidModeMaxPoints = Math.max(resultMemory.paidModeMaxPoints, totalPoints);
        resultMemory.paidModeMaxFreeGrants = Math.max(resultMemory.paidModeMaxFreeGrants, freeGrants.length);
        resultMemory.paidModeMaxPaidGrants = Math.max(resultMemory.paidModeMaxPaidGrants, paidGrants.length);
        const freeNestedGrants = freeGrants.filter(grant => !this.freeFlowsGrantIds.has(grant.id));
        resultMemory.paidModeMaxFreeNestedGrants = Math.max(resultMemory.paidModeMaxFreeNestedGrants, freeNestedGrants.length);
      } else {
        // free mode
        resultMemory.freeModeMaxGrants = Math.max(resultMemory.freeModeMaxGrants, totalGrants);
        resultMemory.freeModeMaxPoints = Math.max(resultMemory.freeModeMaxPoints, totalPoints);
        resultMemory.freeModeMinGrants = Math.min(resultMemory.freeModeMinGrants, totalGrants);
      }
      resultMemory.minPoints = Math.min(resultMemory.minPoints, totalPoints);
      resultMemory.minDecreaseGrants = Math.min(resultMemory.minDecreaseGrants, totalDecreaseGrants.length);
      const decreasePoints = reduceGrantsToPoints(totalDecreaseGrants);
      resultMemory.minDecreasePoints = Math.min(resultMemory.minDecreasePoints, decreasePoints);
      resultMemory.minTotalGrants = Math.min(resultMemory.minTotalGrants, totalGrants);
      resultMemory.minChecks = Math.min(resultMemory.minChecks, memoryEconomy.checkIds.length);
      resultMemory.maxChecks = Math.max(resultMemory.maxChecks, memoryEconomy.checkIds.length);

      // find max free routes
      if (!flow.paid) {
        if (resultMemory.freeModeMaxPoints === memoryEconomy.totalPoints) {
          resultMemory.maxFreeModeMaxChecks = Math.max(resultMemory.maxFreeModeMaxChecks, memoryEconomy.checkIds.length);
        }
      } else {
        // find max paid routes
        if (resultMemory.paidModeMaxPoints === memoryEconomy.totalPoints) {
          resultMemory.paidModeMaxChecks = Math.max(resultMemory.paidModeMaxChecks, memoryEconomy.checkIds.length);
        }
      }
    }
  }
}
