import React, {
  ReactNode, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Card, Col, Spinner,
} from 'react-bootstrap';
// @ts-ignore: no def file
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'react-moment';
// @ts-ignore: no def file
import { RouteComponentProps } from 'react-router';
// @ts-ignore: no def file
import { Link } from 'react-router-dom';
import imgDelete from '../../../../assets/images/delete.svg';
import { RequestState, useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { Event } from '../../../../dorian-shared/types/event/Event';
import { api } from '../../../api';
import { PageWrapper } from '../../../ui/PageWrapper';
import { showToast } from '../../../ui/utils';

function getPageLayout(history: RouteComponentProps['history']) {
  return {
    header: {
      title: 'Events Admin Panel',
      settings: 'admin',
    },
    sidebar: {
      nav: [
        {
          title: 'Back',
          href: '/home/',
          action: history.length > 1 && document.referrer !== window.location.href
            ? () => {
              window.history.back();
            } : null,
          variant: 'secondary',
        },
      ],
    },
  };
}

const dateTimeFormatter = (value: string) => (
  <Moment
    element="span"
    format="YYYY-MM-DD hh:mm A"
    date={value}
  />
);

interface Entity {
  id: string | number
}

const createLinkFormatter = (pathPrefix: string) => function linkFormatter(
  value: ReactNode,
  entity: Entity,
) {
  const { id } = entity;
  return (
    <Link
      to={`/${pathPrefix}/${id}`}
    >
      {value}
    </Link>
  );
};

const useColumns = (onDeleteSuccess: (eventId: number) => void) => useMemo(
  () => [
    {
      dataField: 'title',
      text: 'Title',
      formatter: createLinkFormatter('events'),
    },
    {
      dataField: 'priority',
      text: 'Priority',
    },
    {
      dataField: 'active',
      text: 'Is Active?',
    },
    {
      dataField: 'startTime',
      text: 'Start Time',
      formatter: dateTimeFormatter,
    },
    {
      dataField: 'endTime',
      text: 'End Time',
      formatter: dateTimeFormatter,
    },
    {
      dataField: 'updatedAt',
      text: 'Updated',
      formatter: dateTimeFormatter,
    },
    {
      dataField: 'id',
      text: 'Delete',
      formatter: (eventId: number) => {
        const handleDeleteClick = () => {
          api
            .delete(`/v1/events/${eventId}`)
            .then(() => onDeleteSuccess(eventId))
            .catch(() => {
              showToast({
                textMessage: 'There was an issue when deleting your event, please try again later or contact support.',
                timeout: 20000,
              });
            });
        };

        return (
          <Button
            onClick={handleDeleteClick}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
        );
      },
    },
  ],
  [onDeleteSuccess],
);

function useEvents() {
  const [requestState, {
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();
  const [events, setEvents] = useState<Event[]>([]);
  useEffect(() => {
    setToLoading();
    api.get('/v1/events')
      .then((response) => {
        setEvents(response.data.events);
      })
      .then(() => setToSuccess())
      .catch(() => setToError());
  }, [setToError, setToLoading, setToSuccess]);

  function onEventsTableChange() {
    // TODO: implement
  }

  const deleteEventLocally = useCallback(
    (eventId: number) => setEvents(
      (currentEvents) => currentEvents.filter((event) => event.id !== eventId),
    ),
    [],
  );

  return {
    requestState,
    events,
    onEventsTableChange,
    deleteEventLocally,
  };
}

function EventList() {
  const {
    requestState,
    events,
    onEventsTableChange,
    deleteEventLocally,
  } = useEvents();

  const columns = useColumns(deleteEventLocally);
  switch (requestState) {
    case RequestState.Success:
      return (
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={events}
          columns={columns}
          onTableChange={onEventsTableChange}
          filterPosition="top"
          remote={{
            filter: true,
            pagination: true,
            sort: true,
          }}
        />
      );
    case RequestState.Loading:
      return (
        <div className="text-center">
          <Spinner
            variant="primary"
            animation="border"
            className="loadingSpinner justify-content-center"
          />
        </div>
      );
    case RequestState.Error:
      return (
        <div className="text-center">
          Error occurs during events loading. Please try again later or contact support.
        </div>
      );
    default:
      return null;
  }
}

export function EventListPage(props: RouteComponentProps) {
  const {
    history,
  } = props;
  const pageLayout = getPageLayout(history);

  return (
    <PageWrapper
      {...props}
      page={pageLayout}
    >
      <Card>
        <Card.Body>
          <Card.Title>
            Events
          </Card.Title>
          <EventList />
          <Col>
            <Button
              variant="secondary"
              to="/events/new"
              as={Link}
            >
              Add event
            </Button>
          </Col>
        </Card.Body>
      </Card>
    </PageWrapper>
  );
}
