import React from 'react';
import { Col, Form } from 'react-bootstrap';

interface StepsTypeFieldsSideProps {
  disabled: boolean,
  handleChangeSteps: (
      value: string,
      stepIndex: number,
      field: string
  ) => void,
  stepIndex: number,
  characterPlacementSide: 'default' | 'left' | 'right',
  defaultPlacementSide: 'left' | 'right',
}

export function StepsTypeFieldsSide(props: StepsTypeFieldsSideProps) {
  const {
    disabled,
    handleChangeSteps,
    stepIndex,
    characterPlacementSide,
    defaultPlacementSide,
  } = props;

  return (
    <Form.Group
      md="auto"
      as={Col}
      className="mb-0 px-1 d-block"
    >
      <Form.Control
        disabled={disabled}
        size="sm"
        as="select"
        name={`steps[${stepIndex}][characterPlacementSide]`}
        value={characterPlacementSide}
        onChange={(event) => handleChangeSteps(event.target.value, stepIndex, 'characterPlacementSide')}
      >
        <option value="default">{`Default (${defaultPlacementSide === 'left' ? 'Left' : 'Right'})`}</option>
        <option value="left">Left</option>
        <option value="right">Right</option>
      </Form.Control>
    </Form.Group>
  );
}
