import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAchievementsContext } from '../../../../contexts/AchievementsContext/AchievementsContext';
import { useAchievementsModalContext } from '../context/AchievementsModalContext';
import { useAchievementModalHotkeys } from './useAchievementModalHotkeys';

export function AchievementModalHeader() {
  const handleAchievementCreate = useAchievementModalHotkeys();
  const { isFormLoading } = useAchievementsModalContext();
  const { isLoading } = useAchievementsContext();

  return (
    <Modal.Header>
      <Modal.Title className="d-flex">
        Achievements
        <div className="ml-3">
          <Button
            size="sm"
            variant="secondary"
            onClick={handleAchievementCreate}
            title="Ctrl(Cmd)+Shift+A"
            disabled={isFormLoading || isLoading}
          >
            + Add
          </Button>
        </div>
      </Modal.Title>
    </Modal.Header>
  );
}
