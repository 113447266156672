import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddTagType } from './AddTagType';
import { TagTypesTableRow } from './TagTypesTableRow';

export class TagTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
      loading: false,
      editTagTypeData: null,
      addTagTypeActive: false,
    };

    const { history } = this.props;
    this.page = {
      header: {
        title: 'Tags',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Tag',
            action: () => this.initAddTagType(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.loadTagTypesList();
  }

  loadTagTypesList = () => {
    api.get('/v1/answers/tags/types')
      .then((res) => {
        this.setState({
          loading: false,
          types: res.data.types,
          addTagTypeActive: false,
        });
      })
      .catch(() => this.setState({
        loading: false,
      }));
  };

  tagTypesTableRow = () => {
    const { types } = this.state;
    const action = this.initAddTagType;
    const updateTagTypes = () => {
      this.loadTagTypesList();
    };
    return types.map((object, i) => (
      <TagTypesTableRow
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        obj={object}
        actionEditTagType={action}
        update={updateTagTypes}
      />
    ));
  };

  initAddTagType = (obj) => {
    let editTagTypeData = null;
    if (obj) {
      editTagTypeData = obj;
    }
    this.setState({
      editTagTypeData,
      addTagTypeActive: true,
    });
  };

  cancelAddTagType = () => {
    this.setState({
      editTagTypeData: null,
      addTagTypeActive: false,
    });
  };

  render() {
    const { loading, addTagTypeActive, editTagTypeData } = this.state;

    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >

          <Card>
            <Card.Body>
              <Card.Title>List of Tags</Card.Title>

              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <Table striped hover size="md">
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>ID</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.tagTypesTableRow()}
                      </tbody>
                    </Table>
                  )
              }

            </Card.Body>
          </Card>

        </PageWrapper>

        {
          addTagTypeActive
            ? (
              <AddTagType
                data={editTagTypeData}
                show={addTagTypeActive}
                onHide={this.cancelAddTagType}
                update={this.loadTagTypesList}
              />
            )
            : null
        }
      </>
    );
  }
}
