import axios from 'axios';
import serialize from 'form-serialize';
import React, { PureComponent } from 'react';
import {
  Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import imgNoImage from '../../../assets/images/no_image.svg';
import { api } from '../../api';
import { AvatarAssetsDeleteValue } from './AvatarAssetsDeleteValue';
import './style.scss';
import { ValuesFormImageModal } from './ValuesFormImageModal';

export class ValuesForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      imageLoading: true,
      url: null,
      imgUrl: null,
      imageValidate: true,
      loading: false,
      showImageModal: false,
      showDelete: false,
    };
  }

  componentDidMount() {
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment
      imgUrl: `${this.props.imageUrl}img/avatar/icon/${this.props.type.ident}/${this.props.property.ident}/${this.props.data.ident}.jpg`,
      imageLoading: true,
      url: imgNoImage,
    }, () => {
      this.imageLoader();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      this.props.i !== prevProps.i
    ) {
      this.imageLoader();
    }
  }

  componentWillUnmount() {
    this.setState({
      imgUrl: null,
      imageLoading: true,
      url: imgNoImage,
    });
  }

  handleEdit = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      (prevState) => ({ edit: !prevState.edit }),
      // eslint-disable-next-line react/destructuring-assignment
      () => { this.props.isEdit(this.state.edit); },
    );
  };

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const obj = serialize(form, { hash: true, empty: true });
    obj.disabled = obj.disabled === 'on';
    obj.isDefault = obj.isDefault === 'on';
    this.updateData(obj);
    event.stopPropagation();
  }

  imageLoader = () => {
    this.setState({
      imageLoading: true,
      url: imgNoImage,
    });
    // eslint-disable-next-line react/destructuring-assignment
    const url = this.state.imgUrl;
    axios
      .get(
        url,
        { responseType: 'arraybuffer' },
      )
      .then((response) => {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
        );
        this.setState({
          imageValidate: true,
          imageLoading: false,
          url: `data:;base64,${base64}`,
        });
      }).catch(() => {
        this.setState({
          url: imgNoImage,
          imageLoading: false,
          imageValidate: false,
        });
      });
  };

  updateData = (value) => {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.put(`/v1/avatar/config/values/${this.props.data.id}`, value)
      .then((res) => {
        this.setState({
          loading: false,
        }, () => {
          const { data } = this.props;
          const { value: newValue } = res.data;
          data.disabled = newValue.disabled;
          data.isDefault = newValue.isDefault;
          data.label = newValue.label;
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update(data, this.props.index);
          this.handleEdit();
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    if (
      // eslint-disable-next-line react/destructuring-assignment
      !this.props.data
      // eslint-disable-next-line react/destructuring-assignment
      || this.props.data.typeId !== this.props.type.id
      // eslint-disable-next-line react/destructuring-assignment
      || this.props.data.propertyId !== this.props.property.id
    ) {
      return null;
    }

    return (
      <Card>
        <Form
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Row>
            <Col
              sm={4}
              className="col"
              onClick={
                () => {
                  this.setState({
                    showImageModal: true,
                  });
                }
              }
            >
              <div className="imageClickToEdit">click here to edit image</div>
              {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.imageLoading
                && <div className="imageSpinner"><Spinner animation="border" variant="primary" /></div>
              }
              <Card.Img
                variant="left"
                // eslint-disable-next-line react/destructuring-assignment
                src={this.state.url}
              />
              {
                // eslint-disable-next-line react/destructuring-assignment
                !this.state.imageValidate
                && (
                <div className="imageNotValidate">
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                  {' '}
                  image not validate
                </div>
                )
              }
            </Col>
            <Col sm={8} className="col">
              <Card.Body>
                <Card.Title>
                  {
                    // eslint-disable-next-line react/destructuring-assignment
                    this.state.edit
                      ? (
                        <Form.Group controlId="TypeForm_Label">
                          <Form.Label>Label</Form.Label>
                          <Form.Control
                            // eslint-disable-next-line react/destructuring-assignment
                            disabled={!this.state.edit || this.state.loading}
                            type="text"
                            name="label"
                            // eslint-disable-next-line react/destructuring-assignment
                            defaultValue={this.props.data.label || ''}
                          />
                        </Form.Group>
                      )
                      : (
                        <>
                          { /* eslint-disable-next-line react/destructuring-assignment */ }
                          {this.props.data.label && this.props.data.label}
                        </>
                      )
                  }
                </Card.Title>
                <Card.Text as="div">
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    // eslint-disable-next-line react/destructuring-assignment
                    defaultChecked={this.props.data.disabled}
                    // eslint-disable-next-line react/destructuring-assignment
                    id={`ValueForm_isDisabled_${this.props.type.ident}_${this.props.property.ident}_${this.props.data.ident}`}
                    name="disabled"
                    type="switch"
                    label="Disabled"
                  />
                </Card.Text>
                <Card.Text as="div">
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    // eslint-disable-next-line react/destructuring-assignment
                    defaultChecked={this.props.data.isDefault}
                    // eslint-disable-next-line react/destructuring-assignment
                    id={`ValueForm_isDefault_${this.props.type.ident}_${this.props.property.ident}_${this.props.data.ident}`}
                    name="isDefault"
                    type="switch"
                    label="isDefault"
                  />
                </Card.Text>
                <Card.Footer className="text-right">
                  {
                    // eslint-disable-next-line react/destructuring-assignment
                    this.state.edit
                      ? (
                        <>
                          <Button
                            // eslint-disable-next-line react/destructuring-assignment
                            disabled={this.state.loading}
                            size="sm ml-1"
                            onClick={this.handleEdit}
                            variant="secondary"
                          >
                            Cancel

                          </Button>
                          <Button
                            // eslint-disable-next-line react/destructuring-assignment
                            disabled={this.state.loading}
                            size="sm ml-1"
                            type="submit"
                          >
                            { /*  eslint-disable-next-line react/destructuring-assignment */ }
                            {this.state.loading ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : null}
                            Save
                          </Button>
                        </>
                      )
                      : (
                        <>
                          <Button
                            // eslint-disable-next-line react/destructuring-assignment
                            disabled={this.props.disabled}
                            size="sm ml-1"
                            onClick={this.handleEdit}
                          >
                            Edit

                          </Button>
                          <Button
                            // eslint-disable-next-line react/destructuring-assignment
                            disabled={this.props.disabled}
                            size="sm ml-1"
                            onClick={() => {
                              this.setState({
                                showDelete: true,
                              });
                            }}
                          >
                            Delete

                          </Button>
                        </>
                      )
                  }
                </Card.Footer>
              </Card.Body>
            </Col>
          </Row>
        </Form>
        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.showDelete
          && (
          <AvatarAssetsDeleteValue
            // eslint-disable-next-line react/destructuring-assignment
            show={this.state.showDelete}
            // eslint-disable-next-line react/destructuring-assignment
            data={this.props.data}
            // eslint-disable-next-line react/destructuring-assignment
            update={this.props.loadValues}
            onHide={
              () => {
                this.setState({
                  showDelete: false,
                });
              }
            }
          />
          )
        }
        {
          // eslint-disable-next-line react/destructuring-assignment
          this.state.showImageModal
          && (
          <ValuesFormImageModal
            src={{
              // eslint-disable-next-line react/destructuring-assignment
              id: `img/avatar/icon/${this.props.type.ident}/${this.props.property.ident}/${this.props.data.ident}.jpg`,
              // eslint-disable-next-line react/destructuring-assignment
              src: `${this.props.imageUrl}img/avatar/icon/${this.props.type.ident}/${this.props.property.ident}/${this.props.data.ident}.jpg`,
            }}
            // eslint-disable-next-line react/destructuring-assignment
            data={this.props.data}
            // eslint-disable-next-line react/destructuring-assignment
            show={this.state.showImageModal}
            onHide={
              () => {
                this.setState({
                  showImageModal: false,
                });
              }
            }
          />
          )
        }
      </Card>
    );
  }
}
