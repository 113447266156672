import cs from 'classnames';
import { useFormikContext } from 'formik';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PiStarDuotone, PiStarFill } from 'react-icons/pi';
import { Rating } from 'react-simple-star-rating';
import { IPerformanceRankDTO } from '../../../../dorian-shared/types/performanceRank/performanceRank';
import { AchievementField } from '../../AchievementsModal/AchievementRow/AchievementField';
import { usePerformanceRanksModalContext } from '../context/PerformanceRanksModalContext';
import css from './PerformanceRanksRow.module.scss';

export type EpisodeGoalRowProps = {
  index: number,
}

export function PerformanceRanksRow(props: EpisodeGoalRowProps) {
  const { index: rowIndex } = props;

  const { hasValueChanged, disabled } = usePerformanceRanksModalContext();
  const { values, isSubmitting } = useFormikContext<IPerformanceRankDTO[]>();
  const value = values[rowIndex];

  const minRequirement = value.ranks.map((rank, rankIndex) => ({
    field: `[${rowIndex}].ranks[${rankIndex}].minRequirement`,
    label: rank.rank,
  }));

  // Since the last rank is not required, we remove it from the minRequirement array
  minRequirement.length -= 1;

  return (
    <Row
      id={`episode-goal-row-${rowIndex}`}
      className={cs(css.row, hasValueChanged(value) && css.changed)}
    >
      <Col sm="auto">
        <img
          src={value.variable?.icon?.url}
          title={value.variable?.icon?.label}
          alt={value.variable?.icon?.label}
          className={css.icon}
        />
      </Col>
      <Col sm md={5}>
        <Row className={css.memoryNameRow}>
          <Col className={css.memoryName}>
            <h4 className="text-truncate" title={value.variable.name}>
              {value.variable.name}
            </h4>
          </Col>
        </Row>
        <div className={css.infoContainer}>
          <Row className={cs(css.infoTitleRow, css.whiteBorder)}>
            <Col>
              {/* Median user data for end of this episode */}
            </Col>
          </Row>
          <Row className={cs(css.infoValuesRow, css.whiteBorder)}>
            <Col md={4} className={cs(css.whiteBorder, css.infoValuesCol)}>
              <div className={css.infoLabel}>
                {/* Top 10%: */}
              </div>
              <div className={cs(css.infoValue, 'text-truncate')}>
                {/* 32 pts */}
              </div>
            </Col>
            <Col md={4} className={cs(css.whiteBorder, css.infoValuesCol)}>
              <div className={css.infoLabel}>
                {/* All: */}
              </div>
              <div className={cs(css.infoValue, 'text-truncate')}>
                {/* 17 pts */}
              </div>
            </Col>
            <Col md={4} className={cs(css.whiteBorder, css.infoValuesCol)}>
              <div className={css.infoLabel}>
                {/* Non Player: */}
              </div>
              <div className={cs(css.infoValue, 'text-truncate')}>
                {/* 3 pts */}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col>
        <Row>
          {minRequirement.map((requirement, i) => (
            <div
              key={requirement.field}
              className="d-flex justify-content-end"
            >
              <Col sm={5} className="d-flex mt-1 justify-content-end">
                <Rating
                  initialValue={i + 1}
                  iconsCount={minRequirement.length}
                  readonly
                  emptyIcon={<PiStarDuotone size={24} />}
                  fillIcon={<PiStarFill size={24} />}
                />
              </Col>
              <Col sm={5}>
                <AchievementField
                  id={requirement.field}
                  label=""
                  type="number"
                  className="mb-1 text-right"
                  hidden={i === value.ranks.length}
                  disabled={disabled || isSubmitting}
                />
              </Col>
            </div>
          ))}
        </Row>
      </Col>
    </Row>
  );
}
