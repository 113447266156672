import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { markupToHTML } from '../../../helpers/markupToHTML';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { api } from '../../api';
import { auth } from '../../Auth/Auth';
import { ConfirmTermContent } from '../../pages/Home/ConfirmTerm/ConfirmTermContent';
import './ConfirmTermModal.scss';

export class ConfirmTermModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accepted: false,
      loading: false,
      data: [],
      uuids: [],
    };
    this.documents = [];
    const documents = localStorage.getItem('documents');
    if (documents) {
      try {
        this.documents = JSON.parse(documents);
      } catch (e) {
        logger.error(e);
      }
    } else {
      logger.error('No documents found in local storage');
    }
  }

  componentDidMount() {
    const { uuids } = this.state;
    const accepted = true;
    this.setState({
      accepted,
    });
    if (this.documents && this.documents.length > 0 && accepted) {
      this.setState({
        uuids: [...uuids, this.documents[0].uuid],
      });
      this.loadData(this.documents[0].alias);
    }
  }

  loadData = (id) => {
    this.setState({ loading: true });
    api.get(`v1/documents/${id}`)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.document,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  action = () => {
    const { update } = this.props;
    const { accepted, uuids, data } = this.state;

    if (!accepted) {
      this.setState({
        accepted: true,
        uuids: this.documents[0] ? [...uuids, this.documents[0].uuid] : [...uuids],
      }, () => this.documents.length && this.loadData(this.documents[0].alias));
    }

    if (this.documents && this.documents.length > 1) {
      const d = [];
      this.documents.forEach((a) => {
        if (a.alias !== data.alias) {
          d.push(a);
        }
      });
      this.documents = d;
      this.setState({
        uuids: [...uuids, this.documents[0].uuid],
      });
      this.loadData(this.documents[0].alias);
    } else {
      this.setState({ loading: true });
      api.put('v1/user/accept', { uuids })
        .then((res) => {
          if (res.data?.user?.accepted) {
            const user = auth.getUser();
            if (user) {
              user.accepted = res.data.user.accepted;
              auth.setUser(user);
            }
            localStorage.setItem('documents', JSON.stringify(res.data.documents || null));
            if (update) {
              update();
            } else if (window.location.href.search('setpassword') > 0) {
              window.location.assign('/');
            } else {
              window.location.reload();
            }
          }
        });
    }
  };

  render() {
    // To prevent accept empty document
    if (this.documents.length === 0) {
      return null;
    }
    const title = () => {
      const { data } = this.state;
      if (data) {
        return 'Please agree to these terms to continue.';
      }
      return null;
    };
    const content = () => {
      const { accepted, data } = this.state;

      if (!accepted) {
        return <ConfirmTermContent {...this.props} />;
      }

      if (data) {
        return (
          <>
            {data && data.title && (
            <>
              <h1>{data.title}</h1>
              {data.summary && (
              <div
                style={{ padding: '1em 0' }}
                  // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: markupToHTML(data.summary) }}
              />
              )}
            </>
            )}
            {data && data.body && (
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{ __html: markupToHTML(data.body) }} />
            )}
          </>
        );
      }

      return null;
    };

    const { loading } = this.state;
    return (
      <Modal
        show
        size="xl"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >
        {title() && (
          <Modal.Header>
            <Modal.Title style={{ whiteSpace: 'normal', textAlign: 'center' }}>
              {title()}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="terms">
            {content()}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              const { close } = this.props;
              if (close) {
                close();
              } else {
                userApi.logout();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            onClick={this.action}
          >
            {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
