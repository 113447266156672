import { isEmpty } from 'lodash-es';
import React, { Component } from 'react';
import { api } from '../../api';
import { AvatarHelper } from './AvatarHelper';
import { defaultExpressionValue } from './CharacterExpressions/constants';
import { ImageArea } from './ImageArea/ImageArea';

export class AvatarCanvasWithCustomCharacterSupport extends Component {
  constructor(props, context) {
    super(props, context);

    this.helper = new AvatarHelper();
    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const { properties } = this.props;

    if (JSON.stringify(prevProps.properties) !== JSON.stringify(properties)) {
      this.updateData();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { properties } = this.props;

    return JSON.stringify(nextProps.properties) !== JSON.stringify(properties);
  }

  updateData() {
    const { properties } = this.props;

    if (!properties) return;

    const isCustomCharacter = properties.type === 'custom';

    if (isCustomCharacter) return;

    const data = { properties };
    api.post('/v1/avatar/prepare', data)
      .then((res) => {
        window.requestAnimationFrame(() => this.draw(res.data));
      });
  }

  draw(res) {
    const { properties } = this.props;
    const isCustomCharacter = properties?.type === 'custom';

    if (isCustomCharacter) return;

    this.helper.draw(this.canvas.current, res);
  }

  render() {
    const { properties } = this.props;

    if (!properties) return null;

    const isCustomCharacter = properties.type === 'custom';

    const { expression } = properties;
    const { expressions } = properties;
    const expressionValue = expression && expressions && expressions[expression]
      ? expressions[expression]
      : { ...defaultExpressionValue };

    if (isCustomCharacter && isEmpty(expressionValue.imageURL)) {
      expressionValue.imageURL = properties.image;
    }

    return (
      <div>
        { isCustomCharacter
          ? (
            <ImageArea
              value={expressionValue}
            />
          )
          : (
            <canvas
              className="avatarCanvas"
              ref={this.canvas}
            />
          )}
      </div>
    );
  }
}
