import React from 'react';
import { ListGroup } from 'react-bootstrap';
import classNames from './CharacterImageItem.module.scss';

export function CharacterImageItem({
  activeCharacterId,
  character,
  onClick,
}) {
  return (
    <ListGroup.Item
      className={classNames.listItem}
      as="div"
      action
      active={activeCharacterId === character.id}
      onClick={() => onClick(character)}
    >
      <div className={classNames.cardImageContainer}>
        <img
          className={classNames.cardImage}
          src={character.imageUrl}
          alt={character.alias}
        />
      </div>
      <div className={classNames.cardTitleContainer} title={character.title}>
        {character.title}
      </div>
      <div className={classNames.cardAlias} title={character.alias}>
        {character.alias}
      </div>
    </ListGroup.Item>
  );
}
