import React, { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { MemoryCheckValueType, MemoryLinkValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';

type MemorySlotCompareValueFormListProps = {
  stepIndex: number,
  valueInputValue: MemoryLinkValue | undefined,
  children?: React.ReactNode,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  inputName: string,
  isDisabled?: boolean,
}

export function MemorySlotCompareVariableFormList(props: MemorySlotCompareValueFormListProps) {
  const {
    children, stepIndex, valueInputValue, onChangeValue, inputName, isDisabled,
  } = props;

  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const variableId = valueInputValue?.variableId && !Array.isArray(valueInputValue?.variableId) ? valueInputValue.variableId : '';

  // Because used uncontrolled input we need update hidden input value
  useEffect(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = JSON.stringify(valueInputValue);
    }
  }, [valueInputValue]);

  return (
    <>
      <Form.Control
        disabled={isDisabled}
        size="sm"
        as="select"
        required
        value={variableId}
        onChange={(event) => {
          const value: MemoryLinkValue = {
            type: MemoryCheckValueType.Variable,
            variableId: event.target.value,
          };
          onChangeValue([{
            name: 'value',
            value,
          }], stepIndex);
        }}
      >
        {children}
      </Form.Control>
      <input
        ref={hiddenInputRef}
        type="hidden"
        name={inputName}
        defaultValue={JSON.stringify(valueInputValue)}
      />
    </>
  );
}
