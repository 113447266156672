const readFile = (file: Blob) => new Promise<string | ArrayBuffer>((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => {
    if (reader.readyState === 2) {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(new Error('Reader result is null'));
      }
    }
  };
  reader.onerror = reject;

  reader.readAsDataURL(file);
});
const normalizeDataURLWithCanvas = (dataURL: string) => new Promise<string>((resolve, reject) => {
  const img = new Image();
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const width = img.naturalWidth;
    const height = img.naturalHeight;
    canvas.width = width;
    canvas.height = height;
    const canvasRenderingContext2D = canvas.getContext('2d');
    canvasRenderingContext2D?.drawImage(
      img,
      0,
      0,
      img.naturalWidth,
      img.naturalHeight,
      0,
      0,
      width,
      height,
    );
    const imgDataURL = canvas.toDataURL();
    resolve(imgDataURL);
  };

  img.onerror = reject;

  img.src = dataURL;
});

export const imageFileToDataURL = async (file: Blob) => {
  const readerResult = await readFile(file);
  return normalizeDataURLWithCanvas(readerResult as string);
};
