import React, { useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { convertURLToAssetsImage } from '../../../utils/urlUtils';
import { CustomCharacterSelect } from '../CustomCharacterSelect/CustomCharacterSelect';
import classNames from './ImagePicker.module.scss';

/**
 * Allow select custom character image from library
 * @param title - String. Title for CustomCharacterSelect
 * @param value - HashMap. { imageURL, customCharacterId }
 * @param disabled - Bool.
 * @param onChange(imageUrl) - callback on save selected image
 * @param children - JSX.Element. Button content
 * @param show - Bool. Show modal
 * @param pageSize - Number. Number of characters per page
 * @param limit - Number. Limit of founded characters
 * @param onSearch - Function. Callback on search. Return array of characters
 * @param onClose - Function. Callback on close modal
 * @returns {JSX.Element}
 */
export function ImagePicker({
  title,
  value,
  disabled,
  onChange,
  children,
  show = false,
  pageSize = 9,
  bulkSelectionLimit = undefined,
  onSearch = undefined,
  onClose = undefined,
}) {
  const [selectedCharacter, setSelectedCharacter] = useState(value);
  const [isModalShown, setIsModalShown] = useState(show);

  const isBulkSelectionLimit = Boolean(bulkSelectionLimit);

  const handleChooseClick = () => {
    onChange(selectedCharacter);
    setIsModalShown(false);
  };

  const handleSelectCharacterClick = (newCharacter) => {
    if (isBulkSelectionLimit) {
      return;
    }
    const newSelectedCharacter = {
      alias: newCharacter.alias,
      title: newCharacter.title,
      imageURL: convertURLToAssetsImage(newCharacter.imageUrl),
      customCharacterId: newCharacter.id,
    };
    setSelectedCharacter(newSelectedCharacter);
  };

  const handleCloseModal = () => {
    setIsModalShown(false);
    onClose?.();
  };

  return (
    <>
      <Button
        className={classNames.buttonExternal}
        disabled={disabled}
        variant={disabled ? 'outline-secondary' : 'secondary'}
        onClick={() => setIsModalShown(true)}
      >
        {children || 'Image'}
      </Button>
      <Modal
        className={classNames.modal}
        size="lg"
        show={isModalShown}
        onHide={handleCloseModal}
        centered
        fullscreen="sm-down"
      >
        <Modal.Body className="show-grid">
          <Row>
            <Col xs={12}>
              <CustomCharacterSelect
                title={title}
                activeCharacterId={selectedCharacter?.customCharacterId}
                onClick={handleSelectCharacterClick}
                pageSize={pageSize}
                onSearch={onSearch}
                bulkSelectionLimit={bulkSelectionLimit}
              />
            </Col>
          </Row>
          <Row className={classNames.modalButtons}>
            <Button
              className={classNames.button}
              variant="secondary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              className={classNames.button}
              variant="primary"
              onClick={handleChooseClick}
            >
              Choose
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
