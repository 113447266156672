import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export class AnswerTextFields extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      oldText: '',
    };
    this.textInput = React.createRef();
  }

  componentDidMount() {
    const { activeSteps, stepIndex, answerIndex } = this.props;

    if (activeSteps === `steps-${stepIndex}-answers-${answerIndex}`) {
      if (this.textInput && this.textInput.current) {
        this.textInput.current.focus();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      activeSteps, object, stepIndex, answerIndex,
    } = this.props;

    if (prevProps.activeSteps !== activeSteps && activeSteps === `steps-${stepIndex}-answers-${answerIndex}`) {
      if ((prevProps.object === object) && this.textInput && this.textInput.current) {
        this.textInput.current.focus();
      }
    }
  }

  handleChange = (e) => {
    const { handleTextChange } = this.props;

    handleTextChange(String(e.target.value));
  };

  addAnswer(index) {
    const { addNewAnswer } = this.props;

    addNewAnswer(index);
  }

  stringDiffIndex = (oldText, newText) => {
    let s = 0;
    while (s < oldText.length && s < newText.length && oldText[s] === newText[s]) {
      s += 1;
    }
    return s;
  };

  render() {
    const {
      answerIndex, text, limits, activeStepsFunc, stepIndex, activeSteps,
    } = this.props;
    const { oldText } = this.state;

    const stepClass = `steps-${stepIndex}-answers-${answerIndex}`;
    return (
      <Form.Group sm={12} md={7} lg={5} as={Col} controlId={`answerText${answerIndex}`} className={cs('mb-0')}>
        <Row>
          <Col className={cs('mb-0 px-1')}>
            <Form.Control
              ref={this.textInput}
              autoFocus={(activeSteps === stepClass || !activeSteps)}
              size="sm"
              type="text"
              placeholder="Text"
              value={text || ''}
              name={`steps[${stepIndex}][answers][${answerIndex}][text]`}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.target.blur();
                  ev.preventDefault();
                  this.addAnswer(answerIndex);
                  activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex + 1}`);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === '-') {
                  this.setState({
                    oldText: event.target.value,
                  });
                }
              }}
              onKeyUp={(event) => {
                if (event.key === '-') {
                  let newText = event.target.value;

                  const idx = this.stringDiffIndex(oldText || '', newText || '');
                  if (newText[idx - 1] === '-') {
                    newText = `${newText.substring(0, idx - 1)}—${newText.substring(idx + 1, newText.length)}`;
                    // eslint-disable-next-line no-param-reassign
                    event.target.value = newText;
                    this.handleChange(event);
                    event.target.setSelectionRange(idx, idx);
                  }
                }
              }}
              onFocus={() => {
                activeStepsFunc(stepClass);
              }}
              onMouseDown={(e) => e.stopPropagation()}
              onChange={(e) => {
                this.handleChange(e);
              }}
              className={text && text.length > Number(limits.answer_text_max.value) ? 'text-limit' : null}
            />
            <Form.Text className="char-limit-info-box">
              {Number(limits.answer_text_max.value) - (text ? text.length : 0)}
            </Form.Text>
          </Col>
        </Row>
      </Form.Group>
    );
  }
}
