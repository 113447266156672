import React, { Component } from 'react';
import {
  Badge, Card, Col, Collapse, Form,
} from 'react-bootstrap';
import imgNoImage from '../../../../assets/images/NoStoryPrompt.png';

export class PromptSelectItem extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      book,
      bookId,
      selectedPromptId,
      update,
    } = this.props;
    const { open } = this.state;

    const imgUrl = book.imageAlt && book.imageAlt.imageUrl
      ? book.imageAlt.imageUrl
      : (book.image && book.image.imageUrl) && book.image.imageUrl;
    const author = (book.users
        && book.users[0]
        && book.users[0].fullname)
          && book.users[0].fullname;

    return (
      <Col sm={3}>
        <Card
          className={`checkboxStoryTemplate ${selectedPromptId === book.id ? 'checked' : ''} ${!!bookId && bookId !== book.id ? 'disabled' : ''}`}
        >
          <label htmlFor="checkboxStoryTemplate">
            <Card.Body className="checkboxStoryTemplate-top">
              <Card.Img variant="top" src={imgUrl || imgNoImage} />
            </Card.Body>
            <Form.Check
              type="radio"
              id="checkboxStoryTemplate"
              name="StoryTemplate"
              disabled={(!!bookId && bookId !== book.id)}
              onClick={() => {
                update(book.id);
              }}
            />
            <Card.Title
              style={{
                position: 'relative',
                zIndex: '99',
                cursor: 'pointer',
              }}
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                (book.description || author)
                && this.setState({
                  open: !open,
                });
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  padding: '0 1.5em',
                }}
              >
                {book.title}
                {(book.description || author)
                && (
                <Badge
                  pill
                  variant="secondary"
                  style={{
                    fontStyle: 'italic',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  i
                </Badge>
                )}
              </span>
            </Card.Title>

            {(book.description || author)
            && (
            <Collapse in={open}>
              <div style={{
                padding: ' 0 0 .5em 0',
              }}
              >
                {book.description
                && (
                <Card.Text style={{
                  fontSize: '.8em',
                  lineHeight: '1.2em',
                  margin: '.25em 0',
                }}
                >
                  {book.description}
                </Card.Text>
                )}
                {author
                && (
                <Card.Text style={{
                  fontSize: '.7em',
                  lineHeight: '1.2em',
                  textAlign: 'right',
                  fontStyle: 'italic',
                  margin: '.25em 0',
                }}
                >
                  -
                  {' '}
                  {author}
                </Card.Text>
                )}
              </div>
            </Collapse>
            )}
          </label>
        </Card>
      </Col>
    );
  }
}
