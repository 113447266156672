import React from 'react';
import { Form } from 'react-bootstrap';
import classNames from './ImagePageSizeSelector.module.scss';

type PageSelectorProps = {
  value: number;
  onChange: (value: number) => void;
  size: 'sm' | 'lg' | undefined;
}

export function ImagePageSizeSelector(props: PageSelectorProps) {
  const { value, onChange, size } = props;

  return (
    <Form.Control
      className={classNames.selector}
      as="select"
      size={size}
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
    >
      <option value="7">7</option>
      <option value="20">20</option>
      <option value="50">50</option>
    </Form.Control>
  );
}
