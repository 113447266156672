import React, {
  createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { MemoryDTO } from '../../components/pages/Book/MemoryBank/memoryBankTypes';
import { showToast } from '../../components/ui/utils';
import { useMemoryBankServiceContext } from './MemoryBankServiceContext';

type MemoryBankContextType = {
  isLoading: boolean,
  memories: MemoryDTO[],
  fetchMemories: () => Promise<MemoryDTO[]>,
  bookId: number | undefined,
  setBookId: (bookId: number) => void,
};

const initialContext: MemoryBankContextType = {
  isLoading: false,
  memories: [],
  fetchMemories: () => Promise.resolve([]),
  bookId: undefined,
  setBookId: () => {},
};

export const MemoryBankContext = createContext<MemoryBankContextType>(initialContext);

export function MemoryBankContextProvider({ children, initialBookId }: { children: ReactNode, initialBookId?: number }) {
  const [isLoading, setIsLoading] = useState(false);
  const [bookId, setBookId] = useState<number | undefined>(initialBookId);
  const [memories, setMemories] = useState<MemoryDTO[]>([]);

  const memoryBankService = useMemoryBankServiceContext();

  const fetchMemories = useCallback(async () => {
    if (!bookId) {
      return [];
    }
    const memoriesData = await memoryBankService.fetchMemoryBank(bookId);
    setMemories(memoriesData);
    return memoriesData;
  }, [bookId, memoryBankService, setMemories]);

  useEffect(() => {
    setIsLoading(true);
    fetchMemories()
      .catch(() => {
        showToast({ textMessage: 'Failed to fetch achievements' });
      })
      .finally(() => setIsLoading(false));
  }, [bookId, fetchMemories]);

  const value = useMemo(() => ({
    isLoading,
    bookId,
    setBookId,
    memories,
    fetchMemories,
  }), [isLoading, bookId, memories, fetchMemories]);

  return (
    <MemoryBankContext.Provider value={value}>
      {children}
    </MemoryBankContext.Provider>
  );
}

export const useMemoryBankContext = () => useContext(MemoryBankContext);
