import { EpisodeTextImportCommandKey } from './episodeTextImportCommandTypes';
import { EpisodeTextEntityType } from './episodeTextImportTypes';

export type EpisodeTextImportCrossCommandType = {
  commands: Record<EpisodeTextImportCommandKey, EpisodeTextImportCommandKey[]>,
  entityType: Record<EpisodeTextImportCommandKey, EpisodeTextEntityType[]>,
}

const crossCommandKeysStepsForExpression = [
  EpisodeTextImportCommandKey.StepReact,
  EpisodeTextImportCommandKey.StepThink,
  EpisodeTextImportCommandKey.StepChoice,
];

const crossEntityTypesForExpression = [
  EpisodeTextEntityType.Choice,
  EpisodeTextEntityType.Dialogue,
  EpisodeTextEntityType.Reaction,
  EpisodeTextEntityType.Thinking,
];

export const crassCommandKeysExpressionsForStep = [
  EpisodeTextImportCommandKey.ExpressionAnxious,
  EpisodeTextImportCommandKey.ExpressionBlush,
  EpisodeTextImportCommandKey.ExpressionBored,
  EpisodeTextImportCommandKey.ExpressionDelight,
  EpisodeTextImportCommandKey.ExpressionDisgust,
  EpisodeTextImportCommandKey.ExpressionFlirting,
  EpisodeTextImportCommandKey.ExpressionHappy,
  EpisodeTextImportCommandKey.ExpressionNeutral,
  EpisodeTextImportCommandKey.ExpressionRage,
  EpisodeTextImportCommandKey.ExpressionSad,
  EpisodeTextImportCommandKey.ExpressionScared,
  EpisodeTextImportCommandKey.ExpressionShock,
  EpisodeTextImportCommandKey.ExpressionSmile,
  EpisodeTextImportCommandKey.ExpressionThinking,
  EpisodeTextImportCommandKey.ExpressionWicked,
];

export const EpisodeTextImportCrossCommands: EpisodeTextImportCrossCommandType = {
  commands: {
    [EpisodeTextImportCommandKey.Goto]: [EpisodeTextImportCommandKey.PremiumAnswer, EpisodeTextImportCommandKey.BonusContent],
    [EpisodeTextImportCommandKey.Location]: [],
    [EpisodeTextImportCommandKey.StepChoice]: [...crassCommandKeysExpressionsForStep],
    [EpisodeTextImportCommandKey.StepChat]: [],
    [EpisodeTextImportCommandKey.StepThink]: [...crassCommandKeysExpressionsForStep],
    [EpisodeTextImportCommandKey.StepReact]: [...crassCommandKeysExpressionsForStep],
    [EpisodeTextImportCommandKey.StepRemember]: [],
    [EpisodeTextImportCommandKey.StepCheck]: [],
    [EpisodeTextImportCommandKey.ExpressionAnxious]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionBlush]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionBored]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionDelight]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionDisgust]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionFlirting]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionHappy]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionNeutral]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionRage]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionSad]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionScared]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionShock]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionSmile]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionThinking]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.ExpressionWicked]: [...crossCommandKeysStepsForExpression],
    [EpisodeTextImportCommandKey.PremiumAnswer]: [EpisodeTextImportCommandKey.Goto, EpisodeTextImportCommandKey.BonusContent],
    [EpisodeTextImportCommandKey.BonusContent]: [EpisodeTextImportCommandKey.Goto, EpisodeTextImportCommandKey.PremiumAnswer],
  },
  entityType: {
    [EpisodeTextImportCommandKey.Goto]: [EpisodeTextEntityType.Answer, EpisodeTextEntityType.GotoFromNode],
    [EpisodeTextImportCommandKey.StepChoice]: [EpisodeTextEntityType.Choice],
    [EpisodeTextImportCommandKey.Location]: [EpisodeTextEntityType.Node],
    [EpisodeTextImportCommandKey.StepChat]: [EpisodeTextEntityType.Chat],
    [EpisodeTextImportCommandKey.StepThink]: [EpisodeTextEntityType.Thinking],
    [EpisodeTextImportCommandKey.StepReact]: [EpisodeTextEntityType.Reaction],
    [EpisodeTextImportCommandKey.StepRemember]: [EpisodeTextEntityType.Remember],
    [EpisodeTextImportCommandKey.StepCheck]: [EpisodeTextEntityType.Check],
    [EpisodeTextImportCommandKey.ExpressionAnxious]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionBlush]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionBored]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionDelight]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionDisgust]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionFlirting]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionHappy]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionNeutral]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionRage]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionSad]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionScared]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionShock]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionSmile]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionThinking]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.ExpressionWicked]: crossEntityTypesForExpression,
    [EpisodeTextImportCommandKey.PremiumAnswer]: [EpisodeTextEntityType.Answer],
    [EpisodeTextImportCommandKey.BonusContent]: [EpisodeTextEntityType.Answer],
  },
};
