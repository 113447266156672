import React from 'react';
import {
  Comparator, FILTER_TYPES, customFilter, textFilter,
} from 'react-bootstrap-table2-filter';
import { FormatDate } from '../../../Book/FormatDate';
import { DateFilter } from '../DateFilter';
import { PositiveNumberFilter } from '../PositiveNumberFilter';
import {
  authorsFormatter, bookTitleFormatter, ownerFormatter, storyTitleFormatter,
} from '../tableFormatters';

export const chaptersColumns = [{
  dataField: 'bookTitle',
  text: 'Story',
  formatter: bookTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'owner',
  text: 'Lead Author',
  formatter: ownerFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'authors',
  text: 'Co-Authors',
  formatter: authorsFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'title',
  text: 'Episode',
  formatter: storyTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'chapter',
  text: 'Episode#',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
      comparator={Comparator.EQ}
    />
  ),
}, {
  dataField: 'revision',
  text: 'Version',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'createdAt',
  text: 'Released',
  sort: true,
  formatter: (cell) => (
    <FormatDate
      className="d-inline-block p-1"
      date={cell}
    />
  ),
  filter: customFilter({
    type: FILTER_TYPES.DATE,
  }),
  filterRenderer: (onFilter, column) => (
    <DateFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'free',
  text: 'Free Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'paid',
  text: 'Paid Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'users',
  text: 'Users',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'dropoff',
  text: 'Completion Rate %',
  sort: true,
  formatter: (cell) => {
    const val = cell || 0;
    const ret = Number.isInteger(val) ? val : val.toFixed(2);
    // eslint-disable-next-line no-nested-ternary
    return `${(100 - (ret < 0 ? 0 : ret > 100 ? 100 : ret)).toFixed(2)}%`;
  },
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'active',
  text: 'Basic Paid',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'romantic',
  text: 'Romantic',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'bonus',
  text: 'Bonus',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}];

export const chaptersHeaders = [
  { label: 'Story', key: 'bookTitle' },
  { label: 'Lead Author', key: 'owner.name' },
  { label: 'Co-Authors', key: 'authors' },
  { label: 'Episode', key: 'title' },
  { label: 'Episode #', key: 'chapter' },
  { label: 'Version', key: 'revision' },
  { label: 'Released', key: 'createdAt' },
  { label: 'Free Choices', key: 'free' },
  { label: 'Paid Choices', key: 'paid' },
  { label: 'Users', key: 'users' },
  { label: 'Completion Rate %', key: 'dropoff' },
  { label: 'Basic Paid', key: 'active' },
  { label: 'Romantic', key: 'romantic' },
  { label: 'Bonus', key: 'bonus' },
];
