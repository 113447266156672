import React from 'react';
import {
  Alert, Button, Col, Modal,
} from 'react-bootstrap';
import { DropZone } from './DropZone/DropZone';
import { InstructionDropZoneBackground } from './InstructionDropZone/InstructionDropZoneBackground';
import { InstructionDropZoneCharacter } from './InstructionDropZone/InstructionDropZoneCharacter';
import { PossibleExpressionsModal } from './PossibleExpressionsModal/PossibleExpressionsModal';
import { FileToUpload, LibraryType, UploadFilesModalStatus } from './types';
import { UploadFilesList } from './UploadFilesList/UploadFilesList';
import classNames from './UploadFilesModal.module.scss';
import { useUploadFilesModal } from './useUploadFiles';

export interface UploadFilesModalProps {
  title?: string
  libraryType: LibraryType;
  accept?: {
    [key: string]: string[],
  },
  onUploaded?: (files: FileToUpload[]) => void;
  onClose?: () => void;
  maxFiles?: number;
}

export function UploadFilesModal(props: UploadFilesModalProps) {
  const {
    title = 'Upload Files',
    libraryType,
    accept,
    onUploaded,
    onClose,
    maxFiles = 100,
  } = props;

  const {
    isLoading,
    filesToUpload,
    handleDropZoneChange,
    manageListCallbacks,
    handleUploadClick,
    isUploadFinished,
    expressionNames,
    errorMessage,
  } = useUploadFilesModal(libraryType, onUploaded, maxFiles);

  const handleCloseClick = () => {
    if (isUploadFinished) {
      manageListCallbacks.onUploadFinished(filesToUpload);
    } else {
      onClose?.();
    }
  };

  const dialogClassName = libraryType === LibraryType.Character
    ? classNames.modalCharacterDialog
    : classNames.modalBackgroundDialog;

  const hasErrorStatus = filesToUpload.some((file) => file.status === UploadFilesModalStatus.Error);
  const isUploadButtonDisabled = isLoading
      || hasErrorStatus
      || !filesToUpload.length
      || isUploadFinished
      || !!errorMessage;

  return (
    <div className={classNames.container}>
      <Modal
        show
        centered
        scrollable
        contentClassName={classNames.modalContent}
        dialogClassName={dialogClassName}
      >
        <Modal.Header className={classNames.modalHeader}>
          <Modal.Title className={classNames.modalTitle}>
            <Col className="text-left">
              {title}
            </Col>
            {libraryType === LibraryType.Character && (
            <Col xs="auto">
              <PossibleExpressionsModal expressions={expressionNames} />
            </Col>
            )}
          </Modal.Title>
          {errorMessage && (
          <Alert variant="danger">{`You can upload a maximum of ${maxFiles} files at a time`}</Alert>
          )}
        </Modal.Header>
        <Modal.Body className={classNames.modalBody}>
          {filesToUpload.length
            ? (
              <UploadFilesList
                libraryType={libraryType}
                filesToUpload={filesToUpload}
                manageListCallbacks={manageListCallbacks}
                isLoading={isLoading}
                isUploadFinished={isUploadFinished}
              />
            )
            : (
              <DropZone
                disabled={!!filesToUpload.length}
                accept={accept}
                onChange={(files) => handleDropZoneChange(files)}
                InstructionComponent={
                  libraryType === LibraryType.Character
                    ? InstructionDropZoneCharacter
                    : InstructionDropZoneBackground
                }
              />
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={handleCloseClick}
          >
            Close
          </Button>
          <Button
            disabled={isUploadButtonDisabled}
            variant="primary"
            onClick={handleUploadClick}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
