import { useHistory } from 'react-router-dom';
import * as shortUUID from 'short-uuid';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { UserApiErrorCode } from '../../../dorian-shared/types/user/UserApi';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { IdentityProvider, UserRegisterProps } from '../../../shared/types';
import { auth } from '../../Auth/Auth';

export interface SubmitValues {
  email: string;
  password: string;
  confirmPassword: string;
  agreeToPrivacyPolicy: boolean;
}

interface RegisterResponseErrorData {
 errorCode: UserApiErrorCode,
  data: {
    identityProvider: IdentityProvider
  }
}

export function useSignUpForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const history = useHistory();

  const handleSubmit = (values: SubmitValues) => {
    // Moved from legacy code
    const createTemplateInLocalStorage = localStorage.getItem('createTemplate');
    const createTemplate = createTemplateInLocalStorage
      ? JSON.parse(createTemplateInLocalStorage)
      : null;
    if (createTemplate) {
      createTemplate.writeWithUs = true;
    }
    // End of moved from legacy code

    const uuid = shortUUID.generate();
    const userName = `Dorian_${uuid}`;

    const payload : UserRegisterProps = {
      method: 'EMAIL',
      email: values.email,
      username: userName,
      password: values.password,
      mobile: false,
    };

    setToLoading();
    userApi.register(payload)
      .then(() => {
        history.push(`/confirm-email?username=${encodeURIComponent(userName)}`);
      })
      .catch((registerError) => {
        const { data } : { data : RegisterResponseErrorData} = registerError.response;
        if (!data) {
          const message = auth.getErrorMessage(registerError) ?? 'Sign-in failed. Please check email and password and try again.';
          setToError(message);
          logger.error(registerError);
          return;
        }

        const { errorCode }: { errorCode: UserApiErrorCode } = data;
        switch (errorCode) {
          case UserApiErrorCode.EMAIL_EXISTS:
          {
            const message = 'We found an account already registered with this email. Please sign in.';
            history.push(`/sign-in?username=${encodeURIComponent(values.email)}&errorMessage=${encodeURIComponent(message)}`, { replace: true });
            break;
          }
          case UserApiErrorCode.IS_EXTERNAL: {
            const { identityProvider } = data.data;
            const message = `Looks like you are already using that email for your ${identityProvider ?? ''} login.  Please sign in using that method.`;
            history.push(`/sign-in?errorMessage=${encodeURIComponent(message)}`, { replace: true });
            break;
          }
          default:
          {
            const message = auth.getErrorMessage(registerError) ?? 'Error occurred while trying to login.';
            setToError(message);
            break;
          }
        }
      });
  };
  return {
    isLoading, isError, statusMessage, handleSubmit,
  };
}
