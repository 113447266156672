import {
  episodeTextImportCommandKeyExpressionAnxiousValue,
  episodeTextImportCommandKeyStepChatValue,
  episodeTextImportCommandKeyStepChoiceValue,
  episodeTextImportCommandKeyGotoValue,
  episodeTextImportCommandKeyLocationValue,
  episodeTextImportCommandKeyStepReactValue,
  episodeTextImportCommandKeyStepThinkValue,
  EpisodeTextImportCommandKeyValue,
  episodeTextImportCommandKeyExpressionBlushValue,
  episodeTextImportCommandKeyExpressionBoredValue,
  episodeTextImportCommandKeyExpressionDelightValue,
  episodeTextImportCommandKeyExpressionDisgustValue,
  episodeTextImportCommandKeyExpressionFlirtingValue,
  episodeTextImportCommandKeyExpressionHappyValue,
  episodeTextImportCommandKeyExpressionNeutralValue,
  episodeTextImportCommandKeyExpressionRageValue,
  episodeTextImportCommandKeyExpressionSadValue,
  episodeTextImportCommandKeyExpressionScaredValue,
  episodeTextImportCommandKeyExpressionShockValue,
  episodeTextImportCommandKeyExpressionSmileValue,
  episodeTextImportCommandKeyExpressionThinkingValue,
  episodeTextImportCommandKeyExpressionWickedValue,
  episodeTextImportCommandKeyStepRememberValue,
  episodeTextImportCommandKeyStepCheckValue,
  episodeTextImportCommandKeyPremiumAnswerValue,
  episodeTextImportCommandKeyBonusContentValue,
} from './episodeTextImportCommandKeyValues';
import { EpisodeTextColumnKey } from './episodeTextImportTypes';

export enum EpisodeTextImportCommandKey {
  Goto = 'goto',
  Location = 'location',
  StepChoice = 'choice',
  StepChat = 'chat',
  StepThink = 'think',
  StepReact = 'react',
  StepRemember = 'remember',
  StepCheck = 'check',
  ExpressionAnxious = 'anxious',
  ExpressionBlush = 'blush',
  ExpressionBored = 'bored',
  ExpressionDelight = 'delight',
  ExpressionDisgust = 'disgust',
  ExpressionFlirting = 'flirting',
  ExpressionHappy = 'happy',
  ExpressionNeutral = 'neutral',
  ExpressionRage = 'rage',
  ExpressionSad = 'sad',
  ExpressionScared = 'scared',
  ExpressionShock = 'shock',
  ExpressionSmile = 'smile',
  ExpressionThinking = 'thinking',
  ExpressionWicked = 'evil',
  PremiumAnswer = 'premium',
  BonusContent = 'bonusContent',
}

export type TEpisodeTextImportCommandValue = Map<EpisodeTextColumnKey, string[]>;

export type TEpisodeTextImportCommands = Map<EpisodeTextImportCommandKey, TEpisodeTextImportCommandValue>;

export const EpisodeTextImportCommands: Record<EpisodeTextImportCommandKey, EpisodeTextImportCommandKeyValue[]> = {
  [EpisodeTextImportCommandKey.Goto]: episodeTextImportCommandKeyGotoValue,
  [EpisodeTextImportCommandKey.Location]: episodeTextImportCommandKeyLocationValue,
  [EpisodeTextImportCommandKey.StepChoice]: episodeTextImportCommandKeyStepChoiceValue,
  [EpisodeTextImportCommandKey.StepChat]: episodeTextImportCommandKeyStepChatValue,
  [EpisodeTextImportCommandKey.StepThink]: episodeTextImportCommandKeyStepThinkValue,
  [EpisodeTextImportCommandKey.StepReact]: episodeTextImportCommandKeyStepReactValue,
  [EpisodeTextImportCommandKey.ExpressionAnxious]: episodeTextImportCommandKeyExpressionAnxiousValue,
  [EpisodeTextImportCommandKey.ExpressionBlush]: episodeTextImportCommandKeyExpressionBlushValue,
  [EpisodeTextImportCommandKey.ExpressionBored]: episodeTextImportCommandKeyExpressionBoredValue,
  [EpisodeTextImportCommandKey.ExpressionDelight]: episodeTextImportCommandKeyExpressionDelightValue,
  [EpisodeTextImportCommandKey.ExpressionDisgust]: episodeTextImportCommandKeyExpressionDisgustValue,
  [EpisodeTextImportCommandKey.ExpressionFlirting]: episodeTextImportCommandKeyExpressionFlirtingValue,
  [EpisodeTextImportCommandKey.ExpressionHappy]: episodeTextImportCommandKeyExpressionHappyValue,
  [EpisodeTextImportCommandKey.ExpressionNeutral]: episodeTextImportCommandKeyExpressionNeutralValue,
  [EpisodeTextImportCommandKey.ExpressionRage]: episodeTextImportCommandKeyExpressionRageValue,
  [EpisodeTextImportCommandKey.ExpressionSad]: episodeTextImportCommandKeyExpressionSadValue,
  [EpisodeTextImportCommandKey.ExpressionScared]: episodeTextImportCommandKeyExpressionScaredValue,
  [EpisodeTextImportCommandKey.ExpressionShock]: episodeTextImportCommandKeyExpressionShockValue,
  [EpisodeTextImportCommandKey.ExpressionSmile]: episodeTextImportCommandKeyExpressionSmileValue,
  [EpisodeTextImportCommandKey.ExpressionThinking]: episodeTextImportCommandKeyExpressionThinkingValue,
  [EpisodeTextImportCommandKey.ExpressionWicked]: episodeTextImportCommandKeyExpressionWickedValue,
  [EpisodeTextImportCommandKey.StepRemember]: episodeTextImportCommandKeyStepRememberValue,
  [EpisodeTextImportCommandKey.StepCheck]: episodeTextImportCommandKeyStepCheckValue,
  [EpisodeTextImportCommandKey.PremiumAnswer]: episodeTextImportCommandKeyPremiumAnswerValue,
  [EpisodeTextImportCommandKey.BonusContent]: episodeTextImportCommandKeyBonusContentValue,
};
