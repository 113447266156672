import { BranchStep, StepTypes } from '../../../../dorian-shared/types/branch/BranchStep';
import { ChoiceMemory } from '../../../../services/choiceMemory/ChoiceMemory';
import { ChoiceMemoryStack } from '../../../../services/choiceMemory/types/ChoiceMemoryStack';
import { logger } from '../../../../services/loggerService/loggerService';
import { MemoryShowIn, MemoryValue } from '../../../pages/Book/MemoryBank/memoryBankTypes';
import { RememberActionType } from '../../../pages/StoryBranches/BranchEdit/Steps/StepTypeRemember';

export async function onGoForwardStack(
  choiceMemory: ChoiceMemory | undefined,
  episodeId: number,
  step: BranchStep | undefined,
  stepNum: number,
  popupComponent: (text: string, buttonText: string, iconId: number) => Promise<void>,
): Promise<number | 'goAhead' | 'goBack' | undefined> {
  if (step === undefined) {
    return undefined;
  }

  const item: ChoiceMemoryStack = {
    episodeId,
    step,
    stepNum,
    variables: choiceMemory?.currentVariables ?? {},
  };

  if (step.stepTypeId === StepTypes.Remember) {
    const { action } = item.step;

    switch (action?.type) {
      case RememberActionType.SET.name: {
        const variable = choiceMemory?.getVariableById(action.variableId);
        if (variable !== undefined && variable.value) {
          choiceMemory?.setValueById(
            Number(action.variableId),
            action.value as MemoryValue,
          );
          if (variable.type === 'number' && variable.showIn.includes(MemoryShowIn.Alert)) {
            await popupComponent(
              `${variable.displayName} changed!`,
              `Changes: =${action.value}, Total: ${action.value}`,
              variable.icon,
            );
          }
        }
        item.variables = choiceMemory?.currentVariables ?? {};
        break;
      }
      case RememberActionType.INCREASE.name: {
        const variable = choiceMemory?.getVariableById(action.variableId);
        if (variable !== undefined) {
          if (variable.type === 'number') {
            const total = Number(variable.value) + Number(action.value);
            choiceMemory?.setValueById(
              action.variableId,
              total,
            );
            if (variable.showIn.includes(MemoryShowIn.Alert)) {
              await popupComponent(
                `${variable.displayName} changed!`,
                `Changes: +${action.value}, Total: ${total}`,
                variable.icon,
              );
            }
          } else {
            throw new Error(`Variable ${variable.name} is not a number, can't use`);
          }
        }
        item.variables = choiceMemory?.currentVariables ?? {};
        break;
      }
      case RememberActionType.DECREASE.name: {
        const variable = choiceMemory?.getVariableById(action.variableId);
        if (variable !== undefined) {
          if (variable.type === 'number') {
            const total = Number(variable.value) - Number(action.value);
            choiceMemory?.setValueById(
              action.variableId,
              total,
            );
            if (variable.showIn.includes(MemoryShowIn.Alert)) {
              await popupComponent(
                `${variable.displayName} changed!`,
                `Changes: -${action.value}, Total: ${total}`,
                variable.icon,
              );
            }
          } else {
            throw new Error(`Variable ${variable.name} is not a number, can't use`);
          }
        }
        item.variables = choiceMemory?.currentVariables ?? {};
        break;
      }
      case RememberActionType.VIEW.name: {
        const variable = choiceMemory?.getVariableById(action.variableId);
        if (variable !== undefined) {
          choiceMemory?.setVariableById(variable.id, { ...variable, showIn: action.value as MemoryShowIn[] });
        }
        item.variables = choiceMemory?.currentVariables ?? {};
        break;
      }
      default:
        throw new Error(`Unknown action type ${action?.type}`);
    }

    choiceMemory?.push(item);
    return 'goAhead';
  }
  if (step.stepTypeId === StepTypes.Check) {
    choiceMemory?.push(item);

    const { check, switch: gotoSwitches } = step;

    if (!check) {
      logger.error(`[AT-CM] Check is undefined in stepId=${step.id}`);
      return undefined;
    }

    if (!gotoSwitches) {
      logger.error(`[AT-CM] Switch is undefined in stepId=${step.id}`);
      return undefined;
    }
    if (gotoSwitches.some((gotoSwitch) => !gotoSwitch.gotoBranchId || gotoSwitch.gotoBranchId.toString() === '0')) {
      return undefined;
    }
    return choiceMemory?.getGotoBranchIdByCheck(check, gotoSwitches);
  }

  choiceMemory?.push(item);
  return undefined;
}
