import { AxiosError } from 'axios';
import { useHistory } from 'react-router';
import { useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { UserApiErrorCode } from '../../../dorian-shared/types/user/UserApi';
import { logger } from '../../../services/loggerService/loggerService';
import { userApi } from '../../../services/userApi/UserApi';
import { auth } from '../../Auth/Auth';
import { resetPasswordFormValues } from './ResetPasswordForm';

export function useResetPasswordForm() {
  const [,
    {
      isLoading,
      setToLoading,
      isError,
      setToError,
      statusMessage,
    },
  ] = useAsyncOperationState();

  const history = useHistory();

  const handleSubmit = async (values: resetPasswordFormValues) => {
    // Moved from legacy code
    const createTemplateInLocalStorage = localStorage.getItem('createTemplate');
    const createTemplate = createTemplateInLocalStorage
      ? JSON.parse(createTemplateInLocalStorage)
      : null;
    if (createTemplate) {
      createTemplate.lostPassword = true;
    }
    // End of moved from legacy code

    setToLoading();
    try {
      await userApi.reset({ username: values.email, mobile: false });
      history.push(`/set-password?username=${encodeURIComponent(values.email)}`, { replace: true });
    } catch (e) {
      if (e instanceof AxiosError && e.response?.data) {
        const { errorCode } = e.response.data;

        switch (errorCode) {
          case UserApiErrorCode.IS_LEGACY: {
            history.push(`/sign-up?type=register&username=${encodeURIComponent(values.email)}`, { replace: true });
            return;
          }
          case UserApiErrorCode.IS_EXTERNAL: {
            const message = 'It looks like you are using this email address as an external provider. You can change the password in your external account.';
            setToError(message);
            break;
          }
          default: {
            const message = auth.getErrorMessage(e) || 'Error occurred while trying to send reset password password';
            setToError(message);
            logger.error(e);
            break;
          }
        }
      }
    }
  };
  return {
    isLoading, isError, statusMessage, handleSubmit,
  };
}
