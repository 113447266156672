import serialize from 'form-serialize';
import JoditEditor from 'jodit-react';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { bugTracker } from '../../../services/bugTracker/BugTrackerService';
import { api } from '../../api';

export class EditArticle extends Component {
  constructor(props) {
    super(props);
    const { obj: articleData } = props;
    this.state = {
      validated: false,
      formError: null,
      articleData,
    };

    this.contentRef = React.createRef();
  }

  loadData() {
    this.setState({ loading: true });
    const { obj: articleData } = this.props;
    api.get(`v1/blog/posts/${articleData.id}`)
      .then((res) => {
        this.setState({
          articleData: res.data.post,
        });
      })
      .catch((error) => {
        bugTracker().reportError(error);
        this.errorAlert(error.response.data.error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentDidMount() {
    const { articleData } = this.state;
    if (articleData.id) {
      this.loadData();
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const articleData = serialize(form, { hash: true, empty: true });
    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addArticle(articleData, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addArticle(value, validated) {
    if (validated === true) {
      const { articleData } = this.state;

      const { update } = this.props;

      if (articleData.id) {
        api.put(`/v1/blog/posts/${articleData.id}`, value)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      } else {
        api.post('/v1/blog/posts', value)
          .then(() => {
            update();
          })
          .catch((error) => {
            this.errorAlert(error.response.data.error);
          });
      }
    }
  }

  render() {
    const {
      validated,
      articleData,
    } = this.state;

    const title = articleData.id ? 'Edit Article' : 'Create Article';
    const config = {
      readonly: false,
      disablePlugins: 'table,tablekeyboardnavigation,tooltip,symbols,sticky,stat,search,resizer,media,limit,justify,iframe',
      buttons: ',,,,,,|,,ul,ol,,,,,font,fontsize,,paragraph,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr',
    };
    const { update, onHide, ...props } = this.props;
    const { formError, loading } = this.state;

    return (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton onHide={onHide}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            <Col md={12} className={formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {formError}
              </Alert>
            </Col>

            {
              loading ? null
                : (
                  <>
                    <Form.Group as={Col} sm={12} xs={12}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Title"
                        defaultValue={articleData?.title ?? ''}
                        name="title"
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} xs={12}>
                      <Form.Control
                        required
                        type="number"
                        placeholder="order"
                        defaultValue={articleData?.order ?? ''}
                        name="order"
                      />
                    </Form.Group>

                    <Form.Group as={Col} sm={12} xs={12}>
                      <JoditEditor
                        ref={this.contentRef}
                        value={articleData?.body ?? ''}
                        name="body"
                        config={config}
                        tabIndex={0}
                      />
                    </Form.Group>
                  </>
                )
              }
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              onClick={() => onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              disabled={loading}
            >
              Save
            </Button>

          </Modal.Footer>

        </Form>
      </Modal>
    );
  }
}
