import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './EmailVerifiedForm.module.scss';

export function VerifiedEmailForm() {
  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Using assign because we need to reload the router page
    window.location.assign('/sign-in');
  };

  return (
    <Container>
      <Row>
        <Col>
          <h3 className={styles.formTitle}>Success!</h3>
          <div className={styles.formText}>Your email has been verified!</div>
          {/* TODO: Remove unused styles */}
          <SubmitButton
            label="Continue to Dorian"
            isLoading={false}
            onClick={handleSubmit}
          />
        </Col>
      </Row>
    </Container>
  );
}
