import classNames from 'classnames/bind';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import imgEdit from '../../../assets/images/edit-story.png';
import { DeleteExpression } from './DeleteExpression';
import styles from './Expressions.css';

const cs = classNames.bind(styles);

export class ExpressionTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const {
      update,
      obj,
      actionEditExpression,
    } = this.props;

    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      update();
    };

    const deleteRow = () => {
      const { modalDeleteShow } = this.state;
      if (modalDeleteShow === true) {
        return (
          <DeleteExpression
            show={modalDeleteShow}
            onHide={modalDeleteClose}
            obj={obj}
          />
        );
      }
      return null;
    };

    return (
      <tr>
        <td>
          {obj.title}
        </td>
        <td>
          {obj.value}
        </td>
        <td>
          {obj.def}
          <div className={cs(obj.def === true ? 'checked' : null, 'circleBox')} />
        </td>
        <td className={cs('text-right')}>
          <Button
            onClick={() => actionEditExpression(obj)}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgEdit} className="btnImg" alt="Edit" />
          </Button>
          <Button
            onClick={() => this.setState({ modalDeleteShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete" />
          </Button>
          {deleteRow()}

        </td>
      </tr>
    );
  }
}
