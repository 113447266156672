import cs from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import { ButtonProps } from 'react-bootstrap/Button';
import styles from './SubmitButton.module.scss';

interface SubmitButtonProps extends ButtonProps{
    label: string;
    isLoading: boolean;
}

export function SubmitButton({
  label, isLoading, className, ...props
} : SubmitButtonProps) {
  return (
    <div className="text-center">
      <Button
        className={cs(styles.submitButton, className)}
        variant="default"
        type="submit"
        disabled={isLoading}
        {...props}
      >
        {label}
      </Button>
    </div>
  );
}
