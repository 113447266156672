import React, { Component } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/dorian-white.svg';
import './PageHeader.scss';

export class PageHeader extends Component {
  render() {
    const { rightBox } = this.props;
    return (
      <header className="pageHeader pageHeader-home">
        <Navbar collapseOnSelect expand="lg" bg="light">
          <Container className="mainPageContainer ">
            <Navbar.Brand as={Link} to="/" className="site-logo">
              <Logo />
            </Navbar.Brand>
            <Nav className="mr-auto" />
            <Nav className="mainPageNav">
              {
                (Number(rightBox) < 2)
                && (
                <>
                  Have invite?&nbsp;
                  <Link
                    to="/sign-in"
                    className="mx-0 mainPageLinks"
                  >
                    Log In here!
                  </Link>
                </>
                )
              }
            </Nav>
          </Container>
        </Navbar>
      </header>
    );
  }
}
