import React, { PureComponent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { logger } from '../../../services/loggerService/loggerService';
import { auth } from '../../Auth/Auth';

export class FirstConnection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  updateUser = () => {
    const user = auth.getUser();
    if (user) {
      user.latestActivity = true;
      auth.setUser(user);
    } else {
      logger.error('User data is empty');
    }
  };

  openTab(a) {
    window.open(a);
  }

  render() {
    const { redirect } = this.state;
    const { show } = this.props;

    if (redirect) {
      return (<Redirect to={redirect} />);
    }
    return (
      <Modal
        show={show}
        size="xl"
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title>
            Welcome to Dorian!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              Here for a writing event? Check the home page to enter!
            </p>
            <p>
              Want to learn the basics? Check the
              {' '}
              <a
                href="/articles"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.updateUser();
                  this.openTab('/articles');
                }}
              >
                Writer’s Corner
              </a>
              {' '}
              for basics tutorials and our advice on designing the best stories!
            </p>
            <p>
              Make sure to
              {' '}
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.openTab('https://discord.gg/G5Wzetv');
                }}
              >
                join our Discord community
              </a>
              {' '}
              for the best tutorials and events!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            variant="primary"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.updateUser();
              this.setState({
                redirect: '/',
              });
            }}
          >
            Start Writing!
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
