import React from 'react';
import FrontImg003 from '../../../assets/images/02_clearglass_flip_reducedheight.png';
import FrontImg004 from '../../../assets/images/04_flip_opensmile.png';

export function HomeRightImages(props) {
  const { rightBox } = props;

  return (
    <div className="mainPage-img-right-box">
      <div
        className={`frontImgBox FrontImg003 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
      >
        <img src={FrontImg003} alt="" />
      </div>
      <div
        className={`frontImgBox FrontImg004 ${rightBox === 2 || rightBox === 3 || rightBox === 4 || rightBox === 7 ? 'active' : ''}`}
      >
        <img src={FrontImg004} alt="" />
      </div>
    </div>
  );
}
