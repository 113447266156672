import React from 'react';
import { ApiServiceContext } from 'contexts/ApiServiceContext/ApiServiceContext';
import { MemoryBankService } from '../../services/memoryBankService/MemoryBankService';
import { BookLocationsContext, BookLocationsContextProvider } from '../BookLocationsContext/BookLocationsContext';
import { CharacterExpressionsContext, CharacterExpressionsContextProvider } from '../CharacterExpressionsContext/CharacterExpressionsContext';
import { MemoryBankContext, MemoryBankContextProvider } from '../MemoryBankContext/MemoryBankContext';
import { MemoryBankServiceContext } from '../MemoryBankContext/MemoryBankServiceContext';
import { MemoryIconsContext, MemoryIconsContextProvider } from '../MemoryIconsContext/MemoryIconsContext';

type CombinedContextType = {
  MemoryBankService: MemoryBankService;
};

interface WithContextProps {
    context: CombinedContextType;
}

export function BookWithContext<T extends WithContextProps = WithContextProps>(
  Component: React.ComponentType<T>,
) {
  // eslint-disable-next-line react/display-name
  return function (props: Omit<T, keyof WithContextProps>) {
    return (
      <MemoryBankContextProvider>
        <MemoryIconsContextProvider>
          <CharacterExpressionsContextProvider>
            <BookLocationsContextProvider>
              <ApiServiceContext.Consumer>
                {(apiService) => (
                  <MemoryBankServiceContext.Consumer>
                    {(memoryBankService) => (
                      <MemoryBankContext.Consumer>
                        {(memoryBankContext) => (
                          <MemoryIconsContext.Consumer>
                            {(memoryIconsContext) => (
                              <CharacterExpressionsContext.Consumer>
                                {(characterExpressions) => (
                                  <BookLocationsContext.Consumer>
                                    {(bookLocationContext) => (
                                      <Component
                                        {...(props as T)}
                                        context={{
                                          apiService,
                                          memoryBankService,
                                          characterExpressions,
                                          memoryBankContext,
                                          memoryIconsContext,
                                          bookLocationContext,
                                        }}
                                      />
                                    )}
                                  </BookLocationsContext.Consumer>
                                )}
                              </CharacterExpressionsContext.Consumer>
                            )}
                          </MemoryIconsContext.Consumer>
                        )}
                      </MemoryBankContext.Consumer>

                    )}
                  </MemoryBankServiceContext.Consumer>
                )}
              </ApiServiceContext.Consumer>
            </BookLocationsContextProvider>
          </CharacterExpressionsContextProvider>
        </MemoryIconsContextProvider>
      </MemoryBankContextProvider>
    );
  };
}
