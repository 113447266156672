export const Roles = {
  book: [
    { role: 'owner', label: 'Lead Author' },
    { role: 'viewer', label: 'Viewer' },
    { role: 'editor', label: 'Editor' },
    { role: 'co-author', label: 'Co-Author' },
    { role: 'co-author-pending', label: 'Co-Author(pending)', hide: true },
    { role: 'exclusive-access', label: 'Exclusive Access' },
  ],
  story: [
    { role: 'owner', label: 'Lead Author' },
    { role: 'editor', label: 'Editor' },
    { role: 'co-author', label: 'Co-Author' },
  ],
  folder: [
    { role: 'owner', label: 'Lead Author' },
    { role: 'viewer', label: 'Viewer' },
  ],
};
