import React from 'react';
import { Modal } from 'react-bootstrap';
import { usePerformanceRanksContext } from '../../../../contexts/PerformanceRanksContext/PerformanceRanksContext';
import css from './PerformanceRanksModalHeader.module.scss';
import { usePerformanceRanksModalHotkeys } from './usePerformanceRanksModalHotkeys';

export function PerformanceRanksModalHeader() {
  const { episodeTitle } = usePerformanceRanksContext();

  usePerformanceRanksModalHotkeys();

  return (
    <Modal.Header>
      <Modal.Title>
        Episode Goals
        <div className={css.subTitle}>
          {episodeTitle}
        </div>
      </Modal.Title>
    </Modal.Header>
  );
}
