import React from 'react';
import { Form } from 'react-bootstrap';

export function ClientVariableValueInput({
  dataType,
  onChange,
  value,
}) {
  switch (dataType) {
    case 'string':
      return (
        <Form.Control
          required
          size="sm"
          type="text"
          name="value"
          onChange={onChange}
          value={value}
        />
      );
    case 'number':
      return (
        <Form.Control
          required
          size="sm"
          type="number"
          name="value"
          onChange={onChange}
          value={value}
        />
      );
    case 'boolean':
      return (
        <Form.Control
          required
          as="select"
          size="sm"
          type="boolean"
          name="value"
          onChange={onChange}
          value={value}
        >
          <option value="">Select</option>
          <option value="true">true</option>
          <option value="false">false</option>
        </Form.Control>
      );
    default:
      return null;
  }
}
