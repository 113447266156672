import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';

const quote = () => '"';

export class DeleteGenre extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showLoading: false,
      error: null,
    };
  }

  deleteAction() {
    const {
      obj,
      onHide,
    } = this.props;

    this.setState({ showLoading: true });

    api.delete(`/v1/genres/${obj.id}`)
      .then(() => {
        onHide();
      })
      .catch((error) => {
        this.setState({
          showLoading: false,
          error: error.response.data.error,
        });
      });
  }

  render() {
    const {
      obj,
      onHide,
    } = this.props;
    const {
      error,
      showLoading,
    } = this.state;

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Delete
            {quote}
            {obj.title}
            {quote}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error
            ? (
              <Alert variant="danger">
                {error}
              </Alert>
            )
            : (
              <p className="text-center">
                Do you really want to delete this genre?
              </p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={error}
            onClick={() => this.deleteAction()}
          >
            {showLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
