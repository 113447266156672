import React from 'react';
import { Alert } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

export function MobileAlert(props) {
  const {
    content, className, action, user,
  } = props;
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    setShow(isMobile && ((content && !user.email) || content));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  if (!show) {
    return null;
  }

  return (
    <div
      className={className}
    >
      <Alert
        variant="success"
        dismissible
        onClose={() => {
          setShow(false);
          if (action && content) {
            action();
          }
        }}
      >
        {content
        // eslint-disable-next-line react/no-danger
          ? <div dangerouslySetInnerHTML={{ __html: content }} />
          : (
            <div>
              <b>
                Want Advanced Features?
              </b>
              <p>
                Open your story on a computer at dorian.live
                to see your story as a flowchart and access the full
                creation engine!
              </p>
            </div>
          )}
      </Alert>
    </div>
  );
}
