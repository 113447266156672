import React from 'react';
import {
  Button,
  Card,
  ListGroup, Modal,
} from 'react-bootstrap';
import './ConfirmTermModal.scss';

function ItemPromptCardList({ title, text }) {
  return (
    <ListGroup.Item className="book_card-list_item">
      <div className="book_card-list_item__title">{title}</div>
      <div className="book_card-list_item__text">{text}</div>
    </ListGroup.Item>
  );
}

function PromptCard({ promptBook }) {
  return (
    <Card className="book_card-container">
      <Card.Body>
        <Card.Title className="book_card-title">
          Create SPIN-OFF from PROMPT
        </Card.Title>
        <ListGroup className="book_card-list_group">
          <ItemPromptCardList title="PROMPT NAME:" text={promptBook.title} />
          {promptBook.description && !!promptBook.description.length && <ItemPromptCardList title="PROMPT DESCRIPTION:" text={promptBook.description} />}
          {
              promptBook.spinOffRevenue && (promptBook.users && ![22, 284388].includes(promptBook.users.find((user) => user.story_role === 'owner').id)) && (
                <ListGroup.Item className="royalty-container">
                  <div>
                    <div className="royalty-title">ROYALTY:</div>
                    <div className="royalty-text">
                      If you use this prompt,
                      <span className="royalty-text__revenue">
                        {` ${promptBook.spinOffRevenueShare * 100}% of NET Revenue `}
                      </span>
                      will be shared with the Prompt Creator.
                    </div>
                  </div>
                </ListGroup.Item>
              )
            }
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

function ConfirmButtons({
  cancelLabel, confirmLabel, onCancel, onConfirm,
}) {
  return (
    <div className="confirm-buttons-container">
      <Button
        className="confirm-button-cancel"
        type="button"
        variant="secondary"
        onClick={onCancel}
      >
        {cancelLabel || 'Cancel'}
      </Button>
      <Button
        className="confirm-button-confirm"
        type="button"
        variant="primary"
        onClick={onConfirm}
      >
        {confirmLabel || 'Accept'}
      </Button>
    </div>
  );
}

export function ConfirmSpinOffRevenueModal({
  onClose,
  onConfirm,
  promptBook,
}) {
  const confirmLabelText = promptBook.spinOffRevenue || (promptBook.users && [22, 284388].includes(promptBook.users.find((user) => user.story_role === 'owner').id))
    ? 'Use this Prompt'
    : 'Use this Prompt: FREE';

  return (
    <Modal
      show
      size="xl"
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Body>
        <div className="modal-body-container">
          <PromptCard promptBook={promptBook} />
        </div>

      </Modal.Body>
      <Modal.Footer className="modal-footer-confirm">
        <ConfirmButtons
          confirmLabel={confirmLabelText}
          onConfirm={onConfirm}
          onCancel={onClose}
        />
        <div className="modal-footer-confirm-agreement">
          Your use of this Prompt is subject to the
          {' '}
          <a
            href="/documents/Independent%20Writer%20Agreement"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creator Agreement
          </a>
          .
        </div>
      </Modal.Footer>
    </Modal>
  );
}
