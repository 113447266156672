import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Branch } from 'dorian-shared/types/branch/Branch';
import { AddStepGotoBranchSelect } from '../../AddStepGotoBranchSelect';

type MultiMemoryCheckSwitchFormProps = {
  stepIndex: number,
  switchIndex: number,
  gotoBranchId: number,
  currentBranchId: number,
  branches: Branch[],
  label: string,
  value: string,
  handleChangeStepSwitch: (gotoBranchId: number, stepIndex: number, switchIndex: number) => void,
  isDisabled?: boolean,
}

export function MultiMemoryCheckSwitchForm(props: MultiMemoryCheckSwitchFormProps) {
  const {
    stepIndex, switchIndex, gotoBranchId, currentBranchId, handleChangeStepSwitch, branches,
    isDisabled, value, label,
  } = props;

  const gotoBranchName = `steps[${stepIndex}][switch][${switchIndex}]`;
  return (
    <Col>
      <Form.Group as={Row}>
        <Col lg={5}>
          <Row>
            <Col xs="auto">
              If
            </Col>
            <Col xs={8}>
              <strong>{label}</strong>
            </Col>
            <Col xs="auto">
              {' goto branch '}
            </Col>
          </Row>
        </Col>
        <Col lg={6}>
          <AddStepGotoBranchSelect
            inputName={`${gotoBranchName}[gotoBranchId]`}
            gotoBranchId={gotoBranchId}
            isDisabled={isDisabled}
            currentBranchId={currentBranchId}
            branches={branches}
            onBranchChange={(selectedGotoBranchId) => {
              const gotoBranchIdValue = selectedGotoBranchId ? Number(selectedGotoBranchId) : 0;
              handleChangeStepSwitch(gotoBranchIdValue, stepIndex, switchIndex);
            }}
          />
          <input type="hidden" value={value} name={`${gotoBranchName}[value]`} />
        </Col>
      </Form.Group>
    </Col>
  );
}
