import React from 'react';
import Form from 'react-bootstrap/esm/Form';
import classes from './MemoryField.module.scss';

export function MemoryShowInAlertField(props: React.ComponentProps<typeof Form.Check>) {
  const {
    id,
    checked,
    ...rest
  } = props;

  const title = checked ? 'Visible' : 'Never Visible';

  return (
    <Form.Group>
      <Form.Label
        htmlFor={id}
        className={classes.memoryLabel}
        title={title}
      >
        {title}
      </Form.Label>
      <Form.Check
        id={id}
        checked={checked}
        title={title}
        {...rest}
      />
    </Form.Group>
  );
}
