import { isEmpty } from 'lodash-es';
import shortUUID from 'short-uuid';
import { BookLocation } from '../../../../dorian-shared/types/bookLocation/bookLocation';
import { TEpisodeTextImportCommands } from '../types/episodeTextImportCommandTypes';
import { EpisodeTextImportCrossCommands } from '../types/EpisodeTextImportCrossCommands';
import {
  EpisodeTextColumnKey,
  EpisodeTextEntityType,
  EpisodeTextImportNodeValidationConfig,
} from '../types/episodeTextImportTypes';
import { EpisodeTextImportHelper } from './EpisodeTextImportHelper';
import { EpisodeTextImportStep } from './EpisodeTextImportStep';

export class EpisodeTextImportNode {
  public id: string;
  public title: string;
  public text: string;
  public description: string;
  public steps: EpisodeTextImportStep[] = [];
  public lastStep: EpisodeTextImportStep | undefined = undefined;
  public errors: string[] = [];
  public commands: TEpisodeTextImportCommands = new Map();

  constructor(data: string[]) {
    this.id = shortUUID.generate();
    this.title = data[EpisodeTextColumnKey.NodeOrSpeaker]?.trim() ?? '';
    this.text = data[EpisodeTextColumnKey.Text]?.trim() ?? '';
    this.description = data[EpisodeTextColumnKey.Description]?.trim() ?? '';
    this.parseCommands();
  }

  private parseCommands() {
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.NodeOrSpeaker, this.title, new Map());
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Text, this.text, this.commands);
    this.commands = EpisodeTextImportHelper.parseCommands(EpisodeTextColumnKey.Description, this.description, this.commands);
  }

  public addStep(step: EpisodeTextImportStep) {
    this.steps.push(step);
    this.lastStep = step;
  }

  public validate(config?: EpisodeTextImportNodeValidationConfig): string[] {
    const importNodes = config?.nodesInImport ?? [];
    const maxLengthName = config?.maxLengthName ?? 64;
    const maxLengthText = config?.maxLengthText ?? 200;
    const nodeNamesInEpisode = config?.nodeNamesInEpisode ?? [];
    const nodeNamesInImport = importNodes.map((node) => node.title);
    const bookLocations = config?.bookLocations ?? [];

    const nodeNamePattern = /^[A-Za-z0-9_-]+$/; // allowed characters:
    const title = EpisodeTextImportHelper.removeAllCommandsFromString(this.title);
    const text = EpisodeTextImportHelper.removeAllCommandsFromString(this.text);
    const description = EpisodeTextImportHelper.removeAllCommandsFromString(this.description);

    this.errors = [];

    this._validateCommands(bookLocations);
    this._validateCrossCommands();
    this._validateInvalidCommands();

    if (title.length > Number(maxLengthName)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Node name exceeds the limit of ${maxLengthName} characters`);
    }

    if (description.length > Number(maxLengthText)) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] Node description exceeds the limit of ${maxLengthText} characters`);
    }

    if (!nodeNamePattern.test(title)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Check for spaces or invalid characters. Allowed characters: A-Z, a-z, 0-9, _, -`);
    }
    if (!isEmpty(text)) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] This cell should be empty for NODE row`);
    }

    const isIntro = title.toLocaleLowerCase() === 'intro';
    if (!isIntro && EpisodeTextImportHelper.isNameExists(title, nodeNamesInEpisode)) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] This node name is already used in existing episode`);
    }

    const nodeTitleCount = nodeNamesInImport.filter((name) => name.toLocaleLowerCase() === title.toLocaleLowerCase());
    if (nodeTitleCount.length > 1) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] This node name is duplicated in the import text`);
    }

    const choicesCount = this.steps.filter((step) => step.type === EpisodeTextEntityType.Choice).length;
    if (choicesCount > 1) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Only ONE CHOICE step should be present`);
    }

    return this.errors;
  }

  private _validateCommands(bookLocations: BookLocation[]) {
    const commands = EpisodeTextImportHelper.convertCommandsToRecordType(this.commands);
    const hasLocationCommandInText = commands.location.has(EpisodeTextColumnKey.Text);
    const hasLocationCommandInDescription = commands.location.has(EpisodeTextColumnKey.Description);
    const locationCommandValue = commands.location.values().next().value?.[2] ?? '';
    const locationNameNormalized = locationCommandValue.toLocaleLowerCase().trim();

    const titleCleared = EpisodeTextImportHelper.removeAllCommandsFromString(this.title);

    if (hasLocationCommandInText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] #LOCATION: Location command should be in the description`);
    }

    if (hasLocationCommandInDescription) {
      if (isEmpty(locationNameNormalized)) {
        this.errors.push(`[${EpisodeTextColumnKey.Description}] #LOCATION: Location name should be provided`);
      } else {
        const locations = bookLocations.reduce((acc, location) => {
          acc.push(location.alias);
          acc.push(location.title);
          return acc;
        }, [] as string[]);
        if (!EpisodeTextImportHelper.isNameExists(locationNameNormalized, locations)) {
          this.errors.push(`[${EpisodeTextColumnKey.Description}] #LOCATION: Location name does not exist in the episode. Value: ${locationNameNormalized}`);
        }
      }
    }

    const isRememberCommand = commands.remember.size > 0;

    if (!isEmpty(titleCleared) && isRememberCommand) {
      const rememberColumnKey = commands.remember.keys().next().value;
      this.errors.push(`[${rememberColumnKey}] #REMEMBER: Remember cannot be used with a Character alias`);
    }
  }

  private _validateCrossCommands() {
    const usedCommands = [...this.commands.keys()];

    this.commands.forEach((_, key) => {
      const canBeUsedWithCommands = EpisodeTextImportCrossCommands.commands[key];
      const isError = usedCommands.some((usedCommand) => usedCommand !== key && !canBeUsedWithCommands.includes(usedCommand));
      if (isError) {
        const usedCommandsText = usedCommands.filter((usedCommand) => usedCommand !== key).map((usedCommand) => usedCommand).join(', #');
        this.errors.push(`[${EpisodeTextColumnKey.Description}] #${key.toString()} command cannot be used with #${usedCommandsText}`);
      }

      const canBeInEntityType = EpisodeTextImportCrossCommands.entityType[key];
      const isErrorEntityType = !canBeInEntityType.includes(EpisodeTextEntityType.Node);
      if (isErrorEntityType) {
        this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] #${key.toString()} command cannot be used in ${EpisodeTextEntityType.Node} step type.`);
      }
    });
  }

  private _validateInvalidCommands() {
    const invalidCommandInTitle = EpisodeTextImportHelper.getInvalidValidCommand(this.title);
    if (invalidCommandInTitle) {
      this.errors.push(`[${EpisodeTextColumnKey.NodeOrSpeaker}] Invalid command: ${invalidCommandInTitle}`);
    }
    const invalidCommandInText = EpisodeTextImportHelper.getInvalidValidCommand(this.text);
    if (invalidCommandInText) {
      this.errors.push(`[${EpisodeTextColumnKey.Text}] Invalid command: ${invalidCommandInText}`);
    }
    const invalidCommandInDescription = EpisodeTextImportHelper.getInvalidValidCommand(this.description);
    if (invalidCommandInDescription) {
      this.errors.push(`[${EpisodeTextColumnKey.Description}] Invalid command: ${invalidCommandInDescription}`);
    }
  }
}
