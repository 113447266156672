import { ExpressionValue } from '../../../../dorian-shared/types/character/ExpressionValue';

export const emptyKey = 'empty';
export const defaultExpressionName: ExpressionValue = 'neutral';
export const defaultExpressionValue = {
  imageURL: '',
  alias: '',
  title: '',
  portraitCoordinates: {
    x: 240,
    y: 100,
    size: 350,
  },
};
