import { ErrorMessage, Field, FieldAttributes } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface InputFormFieldProps extends FieldAttributes<any> {
    label: string;
}

export function InputFormField({
  label,
  name,
  hidden = false,
  type,
  children,
  ...props
}: InputFormFieldProps) {
  return (
    <Form.Group hidden={hidden}>
      {type === 'checkbox'
        ? (
          <>
            <Field
              id={name}
              type={type}
              name={name}
              style={{ width: '0.9   rem', height: '0.9rem' }}
              {...props}
            />
            <Form.Label htmlFor={name} className="ml-1 mt-0 mb-0">
              {label}
            </Form.Label>
          </>
        ) : (
          <>
            <Form.Label htmlFor={name} className="mb-0">
              {label}
            </Form.Label>
            <Field
              className="form-control"
              id={name}
              type={type}
              name={name}
              {...props}
            />
          </>
        )}
      {children}
      <ErrorMessage name={name} component="div" className="text-danger font-italic" />
    </Form.Group>
  );
}
