import React from 'react';
import { useLocation } from 'react-router-dom';
import { SignUpForm } from '../../../uiV2/SignUpForm/SignUpForm';
import { LoginLayout } from '../../LoginLayout/LoginLayout';
import styles from './SignUp.module.scss';

const registerTextComponent = (
  <div className={styles.textComponent}>
    <h6>Welcome back!</h6>
    We’ve made some changes to our system.
    <br />
    Please create a new password for your account to proceed.
  </div>
);

const updateTextComponent = (
  <div className={styles.textComponent}>
    We’ve made some changes to our system.
    <br />
    Please create a new password for your account to proceed.
  </div>
);

export function SignUp() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get('type');
  const username = searchParams.get('username') ?? '';

  return (
    <LoginLayout>
      {!type && (
      <SignUpForm
        initialValues={{ email: username }}
      />
      )}
      {type === 'register' && (
      <SignUpForm
        initialValues={{ email: username }}
        title="Register Your Account"
        textComponent={registerTextComponent}
        submitButtonLabel="Register Account"
      />
      )}
      {type === 'update' && (
        <SignUpForm
          initialValues={{ email: username }}
          title="Update Password"
          textComponent={updateTextComponent}
          submitButtonLabel="Update Password"
        />
      )}
    </LoginLayout>
  );
}
