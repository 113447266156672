import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import './style.scss';

export class TypeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      loading: false,
      data: {
        disabled: false,
        isDefault: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.data !== this.props.data || this.state.edit !== prevState.edit) {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        data: JSON.parse(JSON.stringify(this.props.data)),
      });
    }
  }

  handleEdit = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      (prevState) => ({ edit: !prevState.edit }),
      // eslint-disable-next-line react/destructuring-assignment
      () => { this.props.isEdit(this.state.edit); },
    );
  };

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const obj = serialize(form, { hash: true, empty: true });
    obj.disabled = obj.disabled === 'on';
    obj.isDefault = obj.isDefault === 'on';
    this.updateData(obj);
    event.stopPropagation();
  }

  updateData = (value) => {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.put(`/v1/avatar/config/types/${this.props.data.id}`, value)
      .then(() => {
        this.setState(
          { loading: false },
          () => {
            this.handleEdit();
            // eslint-disable-next-line react/destructuring-assignment
            this.props.update();
          },
        );
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.checked;
    this.setState({
      data,
    });
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.data) {
      return null;
    }

    const { data } = this.state;

    return (
      <Col sm={4}>
        <Form
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Card className="mt-4">
            <Card.Body>

              <Card.Title>
                Properties for
                {' '}
                { /*  eslint-disable-next-line react/destructuring-assignment */ }
                {this.props.data.label && this.props.data.label}
              </Card.Title>

              <Row>
                <Col>
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    checked={data.disabled}
                    onChange={this.handleChange}
                    id={`TypeForm_${data.ident}_isDisabled`}
                    name="disabled"
                    type="switch"
                    label="Disabled"
                  />
                </Col>

                <Col>
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    checked={data.isDefault}
                    onChange={this.handleChange}
                    id={`TypeForm_${data.ident}_isDefault`}
                    name="isDefault"
                    type="switch"
                    label="isDefault"
                  />
                </Col>
              </Row>

            </Card.Body>
            <Card.Footer>
              {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.edit
                  ? (
                    <>
                      <Button
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled={this.state.loading}
                        size="sm ml-1"
                        onClick={this.handleEdit}
                        variant="secondary"
                      >
                        Cancel

                      </Button>
                      <Button
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled={this.state.loading}
                        size="sm ml-1"
                        type="submit"
                      >
                        { /*  eslint-disable-next-line react/destructuring-assignment */ }
                        {this.state.loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        Save
                      </Button>
                    </>
                  )
                  : (
                    <Button
                      // eslint-disable-next-line react/destructuring-assignment
                      disabled={this.props.disabled}
                      size="sm ml-1"
                      onClick={this.handleEdit}
                    >
                      Edit

                    </Button>
                  )
              }
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    );
  }
}
