import React from 'react';
import { Form } from 'react-bootstrap';
import { MemoryValue } from 'components/pages/Book/MemoryBank/memoryBankTypes';
import { MemoryBankCheckValue } from './MemorySlotCompareForm';

type MemorySlotCompareValueFormListProps = {
  stepIndex: number,
  valueInputValue: MemoryValue,
  children?: React.ReactNode,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
  inputName: string,
  isDisabled?: boolean,
}

export function MemorySlotCompareValueFormList(props: MemorySlotCompareValueFormListProps) {
  const {
    children, stepIndex, valueInputValue, onChangeValue, inputName, isDisabled,
  } = props;

  return (
    <Form.Control
      disabled={isDisabled}
      size="sm"
      as="select"
      required
      name={inputName}
      value={valueInputValue}
      onChange={(event) => onChangeValue([{
        name: 'value',
        value: event.target.value,
      }], stepIndex)}
    >
      {children}
    </Form.Control>
  );
}
