import React from 'react';
import { noop } from '../../../../helpers/noop';

export const SelectableGroupContext = React.createContext({
  selectable: {
    register: noop,
    unregister: noop,
    selectAll: noop,
    clearSelection: noop,
    getScrolledContainer() {
      return null;
    },
  },
});
