import React from 'react';
import {
  Button, Col, Form, Modal, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { RequestState, useAsyncOperationState } from '../../../dorian-shared/hooks/useAsyncOperationState';
import { Branch } from '../../../dorian-shared/types/branch/Branch';
import { showToast } from '../../ui/utils';
import { useImportTextCharacters } from './hooks/useImportTextCharacters';
import { useImportTextLocations } from './hooks/useImportTextLocations';
import { useImportTextMemoryBank } from './hooks/useImportTextMemoryBank';
import { useImportTextNodeManage } from './hooks/useImportTextNodeManage';
import { useImportTextTableData } from './hooks/useImportTextTableData';
import css from './ImportTextModal.module.scss';
import { textPlaceholder } from './textPlaceholder';

type ImportTextModalProps = {
  bookId: number;
  episodeId: number;
  nodes: Branch[],
  onCancel: () => void;
  onSuccess: (nodeId: number) => void;
}

export function ImportTextModal(props: ImportTextModalProps) {
  const {
    bookId, episodeId, onCancel, onSuccess, nodes,
  } = props;

  const [
    requestStatus,
    {
      setToError,
      setToLoading,
      setToSuccess,
    },
  ] = useAsyncOperationState();

  const { bookLocations, requestStatus: locationRequestStatus } = useImportTextLocations(bookId);
  const { characters, requestStatus: characterRequestStatus } = useImportTextCharacters(bookId);
  const { memoryBank, requestStatus: memoryRequestStatus } = useImportTextMemoryBank(bookId);

  const {
    createEpisodeNodes,
    updateEpisodeNodes,
  } = useImportTextNodeManage(nodes, bookLocations, episodeId, characters, memoryBank);

  const {
    tableData,
    setTableData,
    text,
    setText,
    episodeTextImport,
    handleValidateButton,
    columns,
  } = useImportTextTableData(nodes, characters, bookLocations, memoryBank);

  const handleImportButton = async () => {
    if (!episodeTextImport) {
      showToast({ textMessage: 'No nodes to import.' });
      return;
    }

    setToLoading();
    const episodeTextImportNodes = episodeTextImport.nodes;
    const createdNodes = await createEpisodeNodes(episodeTextImportNodes);

    if (createdNodes.length === 0) {
      showToast({ textMessage: 'No nodes created' });
      setToError('No nodes created');
      return;
    }

    await updateEpisodeNodes(createdNodes, episodeTextImportNodes);

    setToSuccess();
    onSuccess(createdNodes[0].id ?? 0);
  };

  const handleCancelButton = () => {
    onCancel();
  };

  const isImportDisabled = tableData.length === 0
  || tableData.some((data) => data.errors?.length > 0);

  const isLoading = requestStatus === RequestState.Loading
    || locationRequestStatus === RequestState.Loading
    || characterRequestStatus === RequestState.Loading
    || memoryRequestStatus === RequestState.Loading;

  return (
    <>
      <Modal
        show
        size="xl"
        scrollable
        dialogClassName={css.modalDialog}
      >
        <Modal.Header>
          <Modal.Title>
            Import as Text
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tableData.length === 0
            ? (
              <Form.Group className="mb-3">
                <Form.Label>
                  Please paste the text with nodes and steps
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={20}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder={textPlaceholder}
                />
              </Form.Group>
            )
            : (
              <BootstrapTable
                hover
                condensed
                bootstrap4
                keyField="id"
                columns={columns}
                data={tableData}
                noDataIndication="Table is Empty"
              />
            )}
        </Modal.Body>
        <Modal.Footer>
          <Col>
            {tableData.length ? `Total nodes: ${episodeTextImport?.nodes.length ?? 0}` : ''}
          </Col>
          <Col md="auto">
            <Button
              onClick={handleCancelButton}
              className="mr-2"
            >
              Close
            </Button>
            <Button
              variant="secondary"
              className="mr-2"
              onClick={() => {
                setText('');
                setTableData([]);
              }}
            >
              Clear
            </Button>
            {tableData.length === 0
              ? (<Button onClick={handleValidateButton}>              Validate            </Button>)
              : (<Button disabled={isImportDisabled} onClick={handleImportButton}>Import</Button>)}
          </Col>
        </Modal.Footer>
      </Modal>
      {isLoading && (
        <div className="text-center loadingSpinner" style={{ zIndex: 100000 }}>
          <Spinner variant="primary" animation="border" role="status" />
          <div className="overlay" />
        </div>
      )}
    </>
  );
}
