import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export class AnswerFormData extends PureComponent {
  deleteAnswerData = (i) => {
    const { handleChangeAnswerData, data } = this.props;

    const dataCopy = data.slice();
    dataCopy.splice(i, 1);
    handleChangeAnswerData(dataCopy);
  };

  addAnswerData = () => {
    const { handleChangeAnswerData, data } = this.props;

    const newData = {
      key: '',
      value: '',
    };
    const dataCopy = data.slice();
    dataCopy.push(newData);
    handleChangeAnswerData(dataCopy);
  };

  changeAnswerData = (e, index, name) => {
    const { handleChangeAnswerData, data } = this.props;

    const dataCopy = data.slice();
    dataCopy[index][name] = e.target.value;
    handleChangeAnswerData(dataCopy);
  };

  answerDataList = () => {
    const {
      answerIndex, activeSteps, limits, activeStepsFunc, data, stepIndex,
    } = this.props;

    return data.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ListGroup.Item key={i} as="li" className={cs('py-0 pl-3 pr-1 branchesList-li')}>
        <Row
          className={cs('py-1 justify-content-between', activeSteps === `steps-${stepIndex}-answers-${answerIndex}-data-${i}` ? 'active-item' : null)}
        >

          <Form.Group
            as={Col}
            controlId={`answer_${answerIndex}_data${i}_key`}
            className={cs('mb-0')}
          >
            <Row>
              <Form.Label className={cs('text-right px-1')} column sm={2}>Key</Form.Label>
              <Col className={cs('mb-0 px-1')}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Key"
                  value={object.key || ''}
                  name={`steps[${stepIndex}][answers][${answerIndex}][data][${i}][key]`}
                  onFocus={() => {
                    activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-data-${i}`);
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onChange={(e) => this.changeAnswerData(e, i, 'key')}
                  className={object.key && object.key.length > Number(limits.data_key_max.value) ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(limits.data_key_max.value) - (object.key ? object.key.length : 0)}
                </Form.Text>
              </Col>
            </Row>
          </Form.Group>

          <Form.Group
            as={Col}
            controlId={`answer_${answerIndex}_data${i}_value`}
            className={cs('mb-0')}
          >
            <Row>
              <Form.Label className={cs('text-right px-1')} column sm={2} xl={1}>Value</Form.Label>
              <Col className={cs('mb-0 px-1')}>
                <Form.Control
                  size="sm"
                  type="text"
                  placeholder="Value"
                  value={object.value || ''}
                  name={`steps[${stepIndex}][answers][${answerIndex}][data][${i}][value]`}
                  onFocus={() => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-data-${i}`)}
                  onMouseDown={(e) => e.stopPropagation()}
                  onChange={(e) => this.changeAnswerData(e, i, 'value')}
                  className={object.value && object.value.length > Number(limits.data_value_max.value) ? 'text-limit' : null}
                />
                <Form.Text className="char-limit-info-box">
                  {Number(limits.data_value_max.value) - (object.value ? object.value.length : 0)}
                </Form.Text>
              </Col>
            </Row>
          </Form.Group>

          <Col sm={2} xl={1} className="text-right">

            <Button
              className="ml-1 btnDeleteImgSm"
              size="sm"
              variant="secondary"
              onClick={() => this.deleteAnswerData(i)}
              onFocus={() => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-data-${i}`)}
              onMouseDown={(e) => e.stopPropagation()}
            />
          </Col>

        </Row>
      </ListGroup.Item>
    ));
  };

  render() {
    const { showAnswerData } = this.props;

    return (
      <Row className={cs('py-0 justify-content-between')}>
        <Col
          md={12}
          className={cs('mx-0 pb-0 pt-2 border-top', showAnswerData === true ? 'd-block' : 'd-none')}
        >
          <Row>
            <Col md={12}>
              <h6>
                Data
                <Button
                  size="sm"
                  variant="secondary"
                  className="ml-3"
                  onClick={() => this.addAnswerData()}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  + Add Data
                </Button>
              </h6>
            </Col>
          </Row>

          <ListGroup as="ul" start="0" variant="flush" className="my-1 mx-1 px-0 branchesList">
            {this.answerDataList()}
          </ListGroup>

        </Col>
      </Row>
    );
  }
}
