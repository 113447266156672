import React, { Component } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { AddTag } from './AddTag';
import './Tag.css';
import { TagTableRow } from './TagTableRow';

export class StoryTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      loading: true,
      addTag: false,
    };

    const { history } = this.props;

    this.page = {
      header: {
        title: 'Chapter Tags',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
          {
            title: 'Add New Tag',
            action: () => this.initAddTag(),
            variant: 'primary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/tags')
      .then((res) => this.setState({
        loading: false,
        addTag: false,
        tags: res.data.tags,
      }))
      .catch(() => this.setState({
        loading: false,
      }));
  };

  initAddTag = () => {
    this.setState({
      addTag: true,
    });
  };

  cancelAddTag = () => {
    this.setState({
      addTag: false,
    });
  };

  tagTableRow() {
    const { tags } = this.state;
    const update = () => {
      this.loadData();
    };
    // eslint-disable-next-line react/no-array-index-key
    return tags.map((object, i) => <TagTableRow obj={object} key={i} update={update} />);
  }

  render() {
    const { loading, addTag } = this.state;
    return (
      <>
        <PageWrapper
          {...this.props}
          page={this.page}
        >
          <Card>
            <Card.Body>
              <Card.Title>List of Tags</Card.Title>

              {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <div className="storytags-content">
                      {this.tagTableRow()}
                    </div>
                  )
              }

            </Card.Body>
          </Card>
        </PageWrapper>

        {addTag && (
          <AddTag
            show={addTag}
            onHide={this.cancelAddTag}
            update={this.loadData}
          />
        )}
      </>
    );
  }
}
