import React, { Component } from 'react';
import { api } from '../../api';
import { AvatarHelper } from '../../ui/AvatarTool/AvatarHelper';

export class AvatarCanvas extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      data: {},
    };
    this.helper = new AvatarHelper();
    this.canvas = React.createRef();
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    const {
      properties,
      expression,
    } = this.props;

    if (prevProps.properties !== properties || expression !== prevProps.expression) {
      this.updateData();
    }
  }

  updateData() {
    const { properties } = this.props;
    const avatarInput = { properties };

    this.setState({
      loading: true,
    });
    api.post('/v1/avatar/prepare', avatarInput)
      .then((res) => {
        this.setState({
          data: res.data,
        }, () => {
          const { data: avatarOutput } = this.state;

          this.draw(avatarOutput);
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  draw(res) {
    const canvas = this.canvas.current;
    this.helper.draw(canvas, res, () => this.setState({
      loading: false,
    }, () => {
      this.imgObj();
    }));
  }

  imgObj = () => {
    const { loading } = this.state;
    const { update } = this.props;

    if (!loading) {
      const canvas = this.canvas.current;
      update(canvas.toDataURL('image/png'));
    }
  };

  render() {
    return (
      <canvas id="avatar" className="avatarCanvas" ref={this.canvas} />
    );
  }
}
