import React, { Component } from 'react';
import {
  Card, Col, Row, Spinner,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { ReactComponent as IconNo } from '../../../assets/images/no.svg';
import { ReactComponent as IconYes } from '../../../assets/images/yes.svg';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import { getUserName } from '../Users';
import './style.scss';

const getGrantedIcon = (cell) => (cell
  ? <IconYes className="icon-yes" />
  : <IconNo className="icon-no" />
);

export class GlobalInvitationStatus extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;

    this.state = {
      users: [],
      loading: true,
    };
    this.page = {
      header: {
        title: 'Global Invitation Status',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadUsersList();
  }

  loadUsersList = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/invites/status')
      .then((res) => {
        this.setState({
          users: res.data.invites,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const {
      users,
      loading,
    } = this.state;

    const columns = [{
      dataField: 'email',
      sort: false,
      text: 'User email',
    }, {
      dataField: 'user',
      sort: false,
      text: 'Who they applied to',
      formatter: (cell) => getUserName(cell),
    }, {
      dataField: 'granted',
      sort: false,
      text: 'approved for access to the engine',
      className: 'text-center',
      formatter: (cell) => getGrantedIcon(cell),
    }];

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card className={users ? 'd-block' : 'd-none'}>
          <Card.Body>
            <Card.Title>
              <Row>
                <Col>
                  Global Invitation Status
                </Col>
              </Row>
            </Card.Title>
            {
                loading
                  ? (
                    <div className="text-center">
                      <Spinner
                        variant="primary"
                        animation="border"
                        className="loadingSpinner justify-content-center"
                      />
                    </div>
                  )
                  : (
                    <BootstrapTable
                      striped
                      hover
                      size="md"
                      bordered={false}
                      bootstrap4
                      keyField="idx"
                      data={users}
                      columns={columns}
                    />
                  )
              }
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
