import async from 'async';
import React, { Component } from 'react';
import {
  Accordion, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Arr from '../../../assets/images/arr.svg';
import { api } from '../../api';
import { Paginator } from '../../ui/Paginator';
import { PremiumIpDisabledApprovedEdit } from '../../utils/premiumIpDisabledApprovedEdit';
import '../Books/books.scss';
import { isStoryAnalyticsVisible } from '../StoryBranches/BranchAnalytics/utils';
import { ChapterItem } from './ChapterItem';

const SortableItem = SortableElement(({
  object, i, itemIndex, disabledRow, showIndex, ...props
}) => (
  <ChapterItem
    {...props}
    disabled={disabledRow}
    obj={object}
    itemIndex={i}
    index={itemIndex}
  />
));

const SortableList = SortableContainer(({ items, ...props }) => {
  // eslint-disable-next-line no-param-reassign
  items = items.sort((a, b) => a.pos - b.pos);

  if (items.length < 1) {
    return (
      <div className="books-list">
        <Row className="books-list-item">
          <Col>
            <i
              className="indicator-disabled"
              style={{ backgroundColor: props.obj.color }}
            />
            No episodes
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className={`books-list ${props.dragBookItem.groupId === props.obj.id ? 'books-list-active' : ''}`}>
      {items.map((value, index) => (
        <SortableItem
          disabled={props.disabledRow}
          /* eslint-disable-next-line react/no-array-index-key */
          key={`item-${index}`}
          index={index}
          itemIndex={index}
          object={value}
          i={((props.current_page - 1) * props.limit) + index}
          {...props}
        />
      ))}
    </div>
  );
});

export class ChapterGroups extends Component {
  constructor(props) {
    super(props);
    const {
      obj,
      auth,
    } = this.props;

    this.state = {
      loading: true,
      // eslint-disable-next-line react/no-unused-state
      hover: false,
      chapters: [],
      activeKey: obj.id,
      orderField: null,
      orderDesc: true,
      limit: 10,
      offset: 0,
      totalCount: 0,
      current_page: 1,
      showLatest: obj && ['live'].includes(obj.type),
    };

    this.user = auth.getUser();
  }

  // eslint-disable-next-line react/sort-comp
  componentDidUpdate(prevProps, prevState) {
    const { loading } = this.props;
    const { current_page: currentPage } = this.state;

    if (prevProps.loading !== loading
      || prevState.current_page !== currentPage) {
      this.loadData();
    }
  }

  componentDidMount() {
    const {
      match,
      obj,
    } = this.props;

    if (sessionStorage.getItem(`booksChapter_${match.params.id}_${obj.id}`)) {
      const value = JSON.parse(sessionStorage.getItem(`booksChapter_${match.params.id}_${obj.id}`));
      this.setState({
        activeKey: value.activeKey,
      });
    }

    if (
      sessionStorage.getItem(`book_${match.params.id}_group_${obj.id}`)
      || localStorage.getItem(`latest_only_${obj.id}`)
    ) {
      const value = JSON.parse(sessionStorage.getItem(`book_${match.params.id}_group_${obj.id}`));
      const showLatest = JSON.parse(localStorage.getItem(`latest_only_${obj.id}`));
      this.setState({
        showLatest,
        // eslint-disable-next-line react/no-unused-state
        value,
      }, () => {
        this.loadData();
      });
    } else {
      this.loadData();
    }
  }

  loadData() {
    this.setState({ loading: true });
    const {
      obj,
      match,
    } = this.props;

    const {
      limit,
      offset,
      showLatest,
      orderField,
      current_page: currentPage,
      orderDesc,
    } = this.state;

    const params = {
      groupId: obj.id,
      offset,
      limit,
      latest: showLatest ? '1' : '0',
    };

    if (orderField) {
      params.order = `${orderField}:${orderDesc === true ? 'desc' : 'asc'}`;
    }

    async.parallel({
      chapters: (callback) => {
        api.get(`/v1/books/${match.params.id}/chapters/`, { params })
          .then((res) => {
            callback(null, res.data);
          }).catch((error) => {
            callback(error, null);
          });
      },
    }, (err, res) => {
      try {
        if (err) {
          this.setState({
            loading: false,
          });
        } else if (currentPage !== 1
          && (Math.ceil(res.chapters.totalCount / limit) - currentPage) < 0) {
          this.setState({
            offset: 0,
            current_page: 1,
          }, () => {
            this.loadData();
          });
        } else {
          this.setState({
            chapters: res.chapters.chapters,
            totalCount: res.chapters.totalCount,
            loading: false,
          });
        }
      } catch (e) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    const handleOrdering = (field) => {
      this.setState((state) => {
        sessionStorage.setItem('orderField', field);
        sessionStorage.setItem('orderDesc', !state.orderDesc);
        return { orderField: field, orderDesc: !state.orderDesc };
      }, () => this.loadData());
    };

    const {
      modeEdit,
      dragBookItem,
      book,
      obj,
      match,
      groupHover,
      user,
      dragBook,
    } = this.props;

    const {
      activeKey,
      totalCount,
      chapters,
      limit,
      orderField,
      orderDesc,
      showLatest,
      loading,
      current_page: currentPage,
    } = this.state;

    const hoverGroup = dragBookItem.groupId
      && (dragBookItem.groupId !== obj.id)
      ? 'd-block' : 'd-none';

    const disabledRow = (
      this.user.role !== 'admin'
        && book.book_role !== 'owner'
        && book.book_role !== 'editor'
        && book.book_role !== 'co-author'
    )
      || (
        ['submitted', 'rejected', 'approved', 'pending', 'live'].includes(obj.type)
        && this.user.role !== 'admin'
      )
      || (book.original && book.book_role === 'editor')
      || !book.original
      || !modeEdit
      || PremiumIpDisabledApprovedEdit(this.user.role, book);

    const isAnalyticsVisible = isStoryAnalyticsVisible(user, book, obj.type);

    return (
      <div
        className="books-list-box"
        onFocus={() => {
          groupHover('over', obj.id);
          this.setState({
            hover: true,
          });
        }}
        onMouseOver={() => {
          groupHover('over', obj.id);
          this.setState({
            hover: true,
          });
        }}
        onBlur={() => {
          groupHover('out', null);
          this.setState({
            hover: false,
          });
        }}
        onMouseOut={() => {
          groupHover('out', null);
          this.setState({
            hover: false,
          });
        }}
      >
        <Accordion defaultActiveKey={obj.id}>
          <Row className="books-list-item books-list-header d-flex flex-nowrap">
            <Col
              className="p-0"
              sm={12}
              xl={12}
            >
              <h3
                className="list-column-header"
                style={{ color: obj.color }}
              >
                <Accordion.Toggle
                  className="list-column-header-toggle"
                  eventKey={obj.id}
                  style={{ backgroundColor: obj.color }}
                  onClick={() => {
                    const value = { activeKey: Number(activeKey) > 0 ? 0 : obj.id };
                    sessionStorage.setItem(`booksChapter_${match.params.id}_${obj.id}`, JSON.stringify(value));
                    this.setState({
                      activeKey: Number(activeKey) > 0 ? 0 : obj.id,
                    });
                  }}
                >
                  <img
                    className={`list-column-header-toggle-img ${activeKey ? 'active' : 'inactive'}`}
                    src={Arr}
                    alt=">"
                  />
                </Accordion.Toggle>
                {obj.title}
                {' '}
                (
                {totalCount || 0}
                )
              </h3>
            </Col>
          </Row>

          <Accordion.Collapse
            eventKey={activeKey}
            className={Number(activeKey) === 0 ? '' : 'show'}
          >
            <>
              <Row
                className="books-list-item books-list-header d-flex flex-nowrap"
              >
                {chapters.length < 1 ? null
                  : (
                    <>
                      <Col
                        className={`p-0  ${activeKey ? 'visible' : 'invisible'}`}
                        sm={isAnalyticsVisible ? 3 : 5}
                        xl={isAnalyticsVisible ? 4 : 6}
                        onClick={() => {
                          handleOrdering('title');
                        }}
                      >
                        <h4
                          className={`books-list-additionalBox-title text-center ordering  ${orderField === 'title' ? `ordering-${orderDesc}` : ''}`}
                        >
                          <span>Title</span>
                        </h4>
                      </Col>

                      <Col
                        sm={1}
                        xl={1}
                        className={`px-0 text-center books-list-additionalBox ${activeKey ? 'visible' : 'invisible'}`}
                        onClick={() => {
                          handleOrdering('chapter');
                        }}
                      >
                        <h4
                          className={`books-list-additionalBox-title ordering ${orderField === 'chapter' ? `ordering-${orderDesc}` : ''}`}
                        >
                          <span>Episode #</span>
                        </h4>
                      </Col>

                      {isAnalyticsVisible && (
                      <>
                        <Col
                          sm={1}
                          xl={1}
                          className="px-0 text-center books-list-additionalBox"
                        >
                          <h4 className="books-list-additionalBox-title">
                            <span>Completion %</span>
                          </h4>
                        </Col>
                        <Col
                          sm={1}
                          xl={1}
                          className="px-0 text-center books-list-additionalBox"
                        >
                          <h4 className="books-list-additionalBox-title">
                            <span>Playthrough count</span>
                          </h4>
                        </Col>
                      </>
                      )}

                      <Col
                        sm={1}
                        xl={1}
                        className={`px-0 text-center books-list-additionalBox ${activeKey ? 'visible' : 'invisible'}`}
                      >
                        <h4 className="books-list-additionalBox-title">
                          <span>Version</span>
                        </h4>
                      </Col>
                      <Col
                        sm={3}
                        xl={3}
                        className={`px-0 text-center books-list-additionalBox ${activeKey ? 'visible' : 'invisible'}`}
                        onClick={() => {
                          handleOrdering('updatedAt');
                        }}
                      >
                        <h4
                          className={`books-list-additionalBox-title ordering  ${orderField === 'updatedAt' ? `ordering-${orderDesc}` : ''}`}
                        >
                          <span>Modified</span>
                        </h4>
                      </Col>

                      <Col className="px-0 text-right books-list-actionBox">
                        {obj && ['live', 'submitted', 'pending', 'approved'].includes(obj.type)
                      && (
                      <h4 className="books-list-additionalBox-title">
                        <span>
                          <Form.Check
                            custom
                            id={`checkboxLatestOnly_${obj.id}`}
                            type="checkbox"
                            checked={showLatest}
                            onChange={() => {
                              this.setState({
                                showLatest: !showLatest,
                              }, () => {
                                const { showLatest: newShowLatest } = this.state;
                                localStorage.setItem(`latest_only_${obj.id}`, newShowLatest);
                                this.loadData();
                              });
                            }}
                            label="Latest only"
                          />
                        </span>
                      </h4>
                      )}
                      </Col>
                    </>
                  )}
              </Row>

              <div className={`hoverGroup ${hoverGroup}`} />

              {loading
                ? (
                  <div className="text-center boxSpinner">
                    <Spinner
                      variant="primary"
                      animation="border"
                      className="loadingSpinner justify-content-center"
                    />
                  </div>
                )
                : null}

              <SortableList
                {...this.props}
                helperClass="sortItem"
                distance={1}
                lockAxis="y"
                disabledRow={disabledRow}
                items={chapters}
                limit={limit}
                group={obj}
                current_page={currentPage}
                totalCount={totalCount}
                onSortStart={(e) => {
                  dragBook('start', chapters[e.index]);
                }}
                onSortEnd={(e) => {
                  dragBook('stop', chapters[e.oldIndex]);
                }}
                isAnalyticsVisible={isAnalyticsVisible}
              />

              <Paginator
                limit={limit}
                totalCount={totalCount}
                current_page={currentPage}
                update={(val, number) => {
                  const value = { offset: val, current_page: number };
                  sessionStorage.setItem(`book_${match.params.id}_group_${obj.id}`, JSON.stringify(value));
                  this.setState(() => value);
                }}
              />

            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
    );
  }
}
