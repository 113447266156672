import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export class AnswerFormModifiers extends PureComponent {
  deleteAnswerModifier = (i) => {
    const { handleChangeAnswerModifiers, modifiers } = this.props;

    const modifiersCopy = modifiers ? modifiers.slice() : [];
    modifiersCopy.splice(i, 1);
    handleChangeAnswerModifiers(modifiersCopy);
  };

  addAnswerModifier = () => {
    const { handleChangeAnswerModifiers, modifiers } = this.props;

    const newModifiers = {
      answerModifierTypeId: '',
      value: '',
    };
    const modifiersCopy = modifiers ? modifiers.slice() : [];
    modifiersCopy.push(newModifiers);
    handleChangeAnswerModifiers(modifiersCopy);
  };

  changeAnswerModifier = (e, index, name) => {
    const { handleChangeAnswerModifiers, modifiers } = this.props;

    const modifiersCopy = modifiers ? modifiers.slice() : [];
    modifiersCopy[index][name] = e.target.value;
    handleChangeAnswerModifiers(modifiersCopy);
  };

  modifiersOptions = () => {
    const { modifiersTypes } = this.props;

    return modifiersTypes.map((object, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <option value={object.id} key={i}>
        {object.title}
      </option>
    ));
  };

  modifiersList = () => {
    const {
      answerIndex,
      activeSteps,
      activeStepsFunc,
      modifiers = [],
      stepIndex,
      restrictedEdit,
    } = this.props;

    return modifiers.map((modifier, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <ListGroup.Item key={i} as="li" className={cs('py-0 pl-3 pr-1 branchesList-li')}>
        <Row
          className={cs('py-1 justify-content-between', activeSteps === `steps-${stepIndex}-answers-${answerIndex}-modifiers-${i}` ? 'active-item' : null)}
        >

          <Form.Group
            as={Col}
            sm={10}
            controlId={`answer_${answerIndex}_modifiers${i}_modifierTypeId`}
            className={cs('mb-0')}
          >
            <Row>
              <Form.Label className={cs('text-right px-1')} column sm={1}>Modifiers</Form.Label>
              <Col className={cs('mb-0 px-1')}>
                <Form.Control
                  disabled={restrictedEdit}
                  size="sm"
                  as="select"
                  value={modifier.answerModifierTypeId || ''}
                  name={`steps[${stepIndex}][answers][${answerIndex}][modifiers][${i}][answerModifierTypeId]`}
                  onFocus={() => {
                    activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-modifiers-${i}`);
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onChange={(e) => this.changeAnswerModifier(e, i, 'answerModifierTypeId')}
                >
                  {this.modifiersOptions()}
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>

          <Col sm={2} xl={1} className="text-right">
            {!restrictedEdit && (
              <Button
                className="ml-1 btnDeleteImgSm"
                size="sm"
                title="Delete"
                variant="secondary"
                onClick={() => this.deleteAnswerModifier(i)}
                onFocus={() => activeStepsFunc(`steps-${stepIndex}-answers-${answerIndex}-modifiers-${i}`)}
                onMouseDown={(e) => e.stopPropagation()}
              />
            )}
          </Col>

        </Row>
      </ListGroup.Item>
    ));
  };

  render() {
    const { restrictedEdit, showAnswerModifiers } = this.props;

    return (
      <Row className={cs('py-0 justify-content-between')}>
        <Col
          md={12}
          className={cs('mx-0 pb-0 pt-2 border-top', showAnswerModifiers === true ? 'd-block' : 'd-none')}
        >
          <Row>
            <Col md={12}>
              <h6>
                Modifiers
                {!restrictedEdit && (
                  <Button
                    size="sm"
                    variant="secondary"
                    className="ml-3"
                    onClick={() => this.addAnswerModifier()}
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    + Add Modifier
                  </Button>
                )}
              </h6>
            </Col>
          </Row>

          <ListGroup as="ul" start="0" variant="flush" className="my-1 mx-1 px-0 branchesList">
            {this.modifiersList()}
          </ListGroup>
        </Col>
      </Row>
    );
  }
}
