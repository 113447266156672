import React, { Component } from 'react';
import { Card, Spinner, Table } from 'react-bootstrap';
import { api } from '../../api';
import { PageWrapper } from '../../ui/PageWrapper';
import './Request.css';
import { RequestTableRow } from './RequestTableRow';

export class SignUpCodeRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      loading: true,
    };

    const { history } = this.props;
    this.page = {
      header: {
        title: 'Sign Up Code Requests',
        settings: 'admin',
      },
      sidebar: {
        nav: [
          {
            title: history.length > 1 && document.referrer !== window.location.href ? 'Back' : 'Back to Stories List',
            href: history.length > 1 && document.referrer !== window.location.href ? '' : '/books/',
            action: history.length > 1 && document.referrer !== window.location.href
              ? () => {
                window.history.back();
              } : null,
            variant: 'secondary',
            disabled: false,
          },
        ],
      },
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.setState({
      loading: true,
    });
    api.get('/v1/signup/requests')
      .then((res) => {
        this.setState({
          loading: false,
          requests: res.data.requests,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  codeTableRow() {
    const { requests } = this.state;

    const update = () => {
      this.loadData();
    };
    // eslint-disable-next-line react/no-array-index-key
    return requests.map((object, i) => <RequestTableRow obj={object} key={i} update={update} />);
  }

  render() {
    const { loading } = this.state;

    return (
      <PageWrapper
        {...this.props}
        page={this.page}
      >
        <Card className="d-block">
          <Card.Body>
            <Card.Title>List of Code Requests</Card.Title>
            {loading ? (
              <div className="text-center">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="loadingSpinner justify-content-center"
                />
              </div>
            ) : (
              <Table striped hover size="md">
                <thead>
                  <tr>
                    <th>Fullname</th>
                    <th>E-mail</th>
                    <th>Country</th>
                    <th>Device</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {this.codeTableRow()}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </PageWrapper>
    );
  }
}
