import React, { Component } from 'react';
import { Comparator } from 'react-bootstrap-table2-filter';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }

  getValue = () => {
    const { value } = this.state;
    return value;
  };

  filter = () => {
    const { onFilter } = this.props;
    onFilter({ comparator: Comparator.GT, date: this.getValue() });
  };

  onChange = (value) => {
    this.setState({ value }, this.filter);
  };

  render() {
    const { value } = this.state;
    return (
      <DatePicker
        isClearable
        onChange={this.onChange}
        selected={value}
        className="filter text-filter form-control"
        placeholderText="Select Date"
      />
    );
  }
}
