import React, { Component } from 'react';
import {
  Alert, Button, Modal, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';

const doubleQuote = '"';

export class ApproveUser extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      success: false,
      loading: false,
      error: null,
    };
  }

  loadData = (i) => {
    const { obj, update, onHide } = this.props;
    api.post(`/v1/users/${obj[i].id}/approve3`)
      .then(() => {
        this.setState({
          success: true,
          loading: false,
          error: null,
        }, () => {
          if (i < (obj.length - 1)) {
            this.loadData(i + 1);
          } else {
            setTimeout(() => {
              this.setState({
                success: false,
              }, () => {
                onHide();
                update();
              });
            }, 1500);
          }
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.response.data.error,
        });
      });
  };

  action = () => {
    const { obj } = this.props;
    if (obj.length > 0) {
      this.setState({
        loading: true,
      }, () => {
        this.loadData(0);
      });
    }
  };

  renderContent = () => {
    const { error, loading, success } = this.state;
    if (error) {
      return (
        <Alert
          show
          variant="danger"
        >
          {error}
        </Alert>
      );
    }

    if (success) {
      return (
        <Alert
          show
          variant="success"
        >
          user successfully approved
        </Alert>
      );
    }

    if (loading) {
      return (
        <Alert
          show
          variant="info"
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          Please wait ...
        </Alert>
      );
    }

    return (
      <p className="text-center">
        Do you really want to approve the user(s)?
      </p>
    );
  };

  render() {
    const { obj, onHide } = this.props;
    const { error, loading, success } = this.state;

    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            Approve User(s):
            <span>
              {doubleQuote}
              {obj.map((a) => <i key={a.id}>{a.fullname}</i>)}
              {doubleQuote}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderContent()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={(loading)}
            type="reset"
            variant="secondary"
            onClick={onHide}
          >
            Cancel
          </Button>
          <Button
            disabled={(
                error !== null
                || loading
                || success
              )}
            type="submit"
            variant="primary"
            onClick={this.action}
          >
            {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            )}
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
