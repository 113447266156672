import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ConfirmTermModal } from '../../../ui/PageWrapper/ConfirmTermModal';
import { CopyrightPolicyContent } from '../PrivacyPolicy/CopyrightPolicyContent';
import { PrivacyPolicyContent } from '../PrivacyPolicy/PrivacyPolicyContent';
import { TermsOfUseContent } from '../PrivacyPolicy/TermsOfUseContent';
import './ConfirmTerm.scss';

export class ConfirmTerm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    const { match } = this.props;

    if (match.params.id) {
      this.checkHash();
    }
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (!match.params.id && match.params.id !== prevProps.match.params.id) {
      this.setState({
        show: false,
      });
    }
    if (match.params.id && match.params.id !== prevProps.match.params.id) {
      this.checkHash();
    }
  }

  checkHash = () => {
    const { match } = this.props;

    const p = ['terms-of-use', 'privacy-policy', 'copyright-policy'];
    this.setState({
      show: p.includes(match.params.id),
    });
  };

  content = () => {
    const { match } = this.props;

    if (match.params.id === 'terms-of-use') {
      return <TermsOfUseContent type="external" />;
    }
    if (match.params.id === 'privacy-policy') {
      return <PrivacyPolicyContent type="external" />;
    }
    if (match.params.id === 'copyright-policy') {
      return <CopyrightPolicyContent type="external" />;
    }
    return null;
  };

  title = () => {
    const { match } = this.props;

    if (match.params.id === 'terms-of-use') {
      return 'TERMS OF USE';
    }
    if (match.params.id === 'privacy-policy') {
      return 'PRIVACY POLICY';
    }
    if (match.params.id === 'copyright-policy') {
      return 'COPYRIGHT POLICY';
    }
    return null;
  };

  onHide = () => {
    this.setState({
      //  show: false,
    });
    window.location.assign('/');
  };

  render() {
    const { show } = this.state;

    return (
      <>
        <ConfirmTermModal {...this.props} type="external" />

        <Modal
          show={show}
          onHide={this.onHide}
          keyboard
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          className="ConfirmTermModal"
        >
          {
            this.title()
            && (
            <Modal.Header closeButton>
              <Modal.Title>{this.title()}</Modal.Title>
            </Modal.Header>
            )
          }
          <Modal.Body>
            {this.content()}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.onHide}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
