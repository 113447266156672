import { Form, Formik } from 'formik';
import React from 'react';
import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';
import * as Yup from 'yup';
import { userApi } from '../../../services/userApi/UserApi';
import { InputFormField } from '../InputFormField/InputFormField';
import { SubmitButton } from '../SubmitButton/SubmitButton';
import styles from './ProvideNameForm.module.scss';
import { useProvideNameForm } from './useProvideNameForm';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(30, 'Must be at most 30 characters')
    .required('Please enter your first name'),
  lastName: Yup.string()
    .min(3, 'Must be at least 3 characters')
    .max(30, 'Must be at most 30 characters')
    .required('Please enter your last name'),
});

export function ProvideNameForm() {
  const {
    isLoading,
    isError,
    statusMessage,
    handleSubmit,
  } = useProvideNameForm();

  const userProfileLegacy = userApi.getProfileLegacy();
  const userProfile = userApi.getProfile();

  const initialValues = {
    firstName: userProfileLegacy?.firstName || userProfile?.given_name || '',
    lastName: userProfileLegacy?.lastName || userProfile?.family_name || '',
  };

  return (
    <Container>
      <Row>
        {isError && (
        <Alert variant="danger" className={styles.formAlert}>
          {statusMessage}
        </Alert>
        )}
      </Row>
      <Row>
        <Col>
          <h3 className={styles.formTitle}>Ready to Write?</h3>
          <div className={styles.formText}>
            Almost there!  Just provide your name in order to join the community.
          </div>
          <div className={styles.formText}>
            Don’t worry - you can still create a user name for public use.
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form noValidate>
              <InputFormField disabled={isLoading} label="First Name" name="firstName" type="text" />
              <InputFormField disabled={isLoading} label="Last Name" name="lastName" type="text" />
              <SubmitButton label="Submit" isLoading={isLoading} />
            </Form>
          </Formik>
        </Col>
      </Row>
      <Row>
        <Col>
          <SubmitButton
            className={styles.logoutButton}
            type="button"
            label="Logout"
            isLoading={isLoading}
            onClick={() => userApi.logout()}
          />
        </Col>
      </Row>
    </Container>
  );
}
