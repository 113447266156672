import React, { Component } from 'react';
import { SelectableGroupContext } from './Context';

export class DeselectAll extends Component {
  componentDidMount() {
  }

  render() {
    const {
      component, children, className, ...rest
    } = this.props;
    const { selectable } = this.context;

    const ButtonComponent = component || 'div';

    return (
      <ButtonComponent
        className={`selectable-select-all ${className}`}
        onClick={selectable.clearSelection}
        {...rest}
      >
        {children}
      </ButtonComponent>
    );
  }
}

DeselectAll.contextType = SelectableGroupContext;
