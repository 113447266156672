import { FileToUpload, UploadFilesModalStatus } from '../../types';
import classNames from './UploadFilesCharacterItem/UploadFilesCharacterItem.module.scss';

export const getRemoveButtonStateByStatus = (status: UploadFilesModalStatus) => {
  if (status === UploadFilesModalStatus.Ready) {
    return false;
  }
  if (status === UploadFilesModalStatus.Error) {
    return false;
  }
  return true;
};

export const getClassNameAndText = (
  fileToUpload: FileToUpload,
) : { statusClassName: string, statusText: string} => {
  const { status, errors } = fileToUpload;
  switch (status) {
    case UploadFilesModalStatus.Ready:
      return {
        statusClassName: classNames.uploadStatusReady,
        statusText: 'Ready',
      };
    case UploadFilesModalStatus.Uploading:
      return {
        statusClassName: classNames.uploadStatusUploading,
        statusText: 'Uploading...',
      };
    case UploadFilesModalStatus.Error:
      return {
        statusClassName: classNames.uploadStatusError,
        statusText: `${errors?.[0]?.message ?? 'Unknown error'}`,
      };
    case UploadFilesModalStatus.Uploaded:
      return {
        statusClassName: classNames.uploadStatusUploaded,
        statusText: 'Uploaded',
      };
    default:
      return {
        statusClassName: classNames.Error,
        statusText: 'Unknown error',
      };
  }
};
