export const textPlaceholder = `Example:
                
Intro\t\t
\tExample of NARRATOR step type\t
\tWhen character alias is empty - it will be NARRATOR step type\t
\t#GOTO: location_2\t
Location_2\t\t
Anna\tExample of DIALOGUE step type\t
player\tWhen character alias and text is present - it will be DIALOGUE step type\t
Serge\tExample 1 of TEXTING step type\t#CHAT
player\tExample 2 of TEXTING step type\t#CHAT
\t#-> choice_1\t
Choice_1\t\t
anna\tExample of CHOICE step type\t#CHOICE
\tExample of answer 1\t#GOTO: choice_1_a1
\tExample of answer 2\t#GOTO: choice_1_a2
\tExample of answer 3\t#GOTO: choice_1_a3
Choice_1_A1\t\t
anna\tYou choose answer 1, Will go to location_4\t
\t#-> location_4\t
Choice_1_A2\t\t
serge\tYou choose answer 2, Will go to location_4\t
\t#-> location_4\t
Choice_1_A3\t\t
\tYou choose answer 3, Will go to end\t
\t#-> end\t
Location_4\t\t#LOCATION: car1
\tExample of #LOCATION command\t
End\t\t
\tTo be continued\t`;
