import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

export type CSVLinkRefType = CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement };

export interface ExportCSVData {
  data: []
  headers: []
  fileName: string

}

export interface ExportAsyncCSVProps {
  asyncExportMethod: () => Promise<ExportCSVData>
  children: React.ReactNode
  disabled: boolean
}

export function ExportAsyncCSV(props: ExportAsyncCSVProps) {
  const { asyncExportMethod, children, disabled } = props;

  const [csvData, setCsvData] = useState<ExportCSVData | null>(null);

  const csvInstance = useRef<CSVLinkRefType>(null);

  const handleDownloadClick = async () => {
    const newCsvData = await asyncExportMethod();
    if (newCsvData) {
      setCsvData(newCsvData);
      csvInstance.current?.link.click();
    }
  };

  return (
    <>
      <Button
        size="sm"
        variant="outline-primary"
        disabled={disabled}
        onClick={() => handleDownloadClick()}
      >
        {children}
      </Button>
      <CSVLink
        data={csvData?.data ?? []}
        headers={csvData?.headers}
        filename={csvData?.fileName ?? 'export.csv'}
        ref={csvInstance}
      />
    </>
  );
}
