import React, { useState } from 'react';
import {
  Button, Col, Modal, Row,
} from 'react-bootstrap';
import { Cropper } from 'react-cropper';
import { convertAssetsImageToURL } from '../../../utils/urlUtils';
import './cropper.scss';
import classNames from './ImageAreaPicker.module.scss';

/**
 * Allows you to select the displayed area of the image
 * @param title - String. Title for modal window
 * @param value - Expression value
 * @param imageURL - String
 * @param disabled - Bool
 * @param onChange(newValue) - Expression value
 * @returns {JSX.Element} - Button
 */
export function ImageAreaPicker({
  title,
  value,
  imageURL,
  disabled,
  onChange,
}) {
  const [isModalShown, setIsModalShown] = useState(false);

  let cropperInstance = null;

  const handleInitializedCropper = (instance) => {
    cropperInstance = instance;
  };

  const handleReadyCropper = () => {
    const newData = cropperInstance.getData();
    newData.x = value?.x;
    newData.y = value?.y;
    newData.width = value?.size;
    newData.height = value?.size;
    cropperInstance.setData(newData);
  };

  const handleSaveClick = () => {
    setIsModalShown(false);
    const cropperData = cropperInstance.getData();
    const newPortraitCoordinates = {
      x: Math.round(cropperData.x),
      y: Math.round(cropperData.y),
      size: Math.round(cropperData.width),
    };
    onChange(newPortraitCoordinates);
  };

  const handleCancelClick = () => setIsModalShown(false);

  const absoluteImageURL = convertAssetsImageToURL(imageURL);

  return (
    <>
      <Button
        className={classNames.buttonExternal}
        disabled={disabled}
        variant={disabled ? 'outline-secondary' : 'secondary'}
        onClick={() => setIsModalShown(true)}
      >
        Portrait
      </Button>
      <Modal
        className={classNames.modal}
        size="md"
        show={isModalShown}
        onHide={() => setIsModalShown(false)}
        backdrop="static"
      >
        <Modal.Title className={classNames.modalTitle}>
          {title}
        </Modal.Title>
        <Modal.Body>
          <Row>
            <Col xs={12} className={classNames.cropperContainer}>
              <Cropper
                className="cropper-area-picker"
                viewMode={1}
                initialAspectRatio={1}
                aspectRatio={1}
                minCropBoxWidth={50}
                minCropBoxHeight={50}
                zoomOnWheel={false}
                center={false}
                toggleDragModeOnDblclick={false}
                src={absoluteImageURL}
                background={false}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={handleInitializedCropper}
                ready={handleReadyCropper}
              />
            </Col>
          </Row>
          <Row className={classNames.modalButtons}>
            <Button
              className={classNames.button}
              onClick={handleCancelClick}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className={classNames.button}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
