import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class CongratulationsContent extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <>
        <h2 className="title">Congrats, You’re In!</h2>
        <p>Check your email! We’ve sent you a code that will give you access right away!</p>
        <p>
          <Link
            className="mainPageLinks"
            to="/setpassword"
          >
            Click here to complete registration
          </Link>
          !
        </p>
      </>
    );
  }
}
