import React from 'react';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { api } from '../../../api';
import { ConfirmModal } from '../../../ui/ConfirmModal/ConfirmModal';
import { showToast } from '../../../ui/utils';
import { fetchShareUsersWithExclusiveAccess } from '../../StoryBranches/PublishModal/utils';

interface ExclusivityModalProps {
  bookId: number
  show: boolean
  onConfirm: () => void
  onCancel: () => void
}

export function ExclusivityModal(props: ExclusivityModalProps) {
  const {
    bookId, show, onConfirm, onCancel,
  } = props;
  const [, {
    isLoading,
    setToLoading,
    setToError,
    setToSuccess,
  }] = useAsyncOperationState();

  const deleteExclusiveAccess = async () => {
    // Get users with exclusive access
    const usersWithExclusiveAccess = await fetchShareUsersWithExclusiveAccess(bookId);
    const promisesToDelete = usersWithExclusiveAccess.map(
      (user) => api.delete(`v1/books/${bookId}/share/${user.id}`),
    );
    await Promise.all(promisesToDelete);
  };

  const handleConfirm = async () => {
    setToLoading();
    // Remove users with exclusive access
    try {
      await deleteExclusiveAccess();
    } catch (e) {
      showToast({ textMessage: 'Error on deleting exclusive access' });
      setToError();
      return;
    }

    // Update book
    const data = { exclusiveAccess: false };
    try {
      await api.put(`/v1/books/${bookId}`, data);
      showToast({ textMessage: 'Exclusive access saved', variant: 'success' });
      setToSuccess();
    } catch (e) {
      showToast({ textMessage: 'Error on saving Exclusive access data' });
      setToError();
      return;
    }

    onConfirm();
  };

  return (
    <ConfirmModal
      show={show}
      isLoading={isLoading}
      title="Open template for public use"
      confirmButtonTitle="Accept"
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      <p>
        By accepting,you are removing the exclusivity of this template, and agreeing to let
        all Dorian users access this template from this point forward.
      </p>
    </ConfirmModal>
  );
}
