import serialize from 'form-serialize';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Alert, Button, Col, Form, Modal,
} from 'react-bootstrap';
import { api } from '../../api';

export class AddTag extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      validated: false,
      formError: null,
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const validated = form.checkValidity();
    const obj = serialize(form, { hash: true });

    if (validated === false) {
      event.stopPropagation();
    } else {
      this.addTagType(obj, validated);
    }
    this.setState({ validated: true });
    event.stopPropagation();
  }

  errorAlert = (error) => {
    this.setState({
      formError: error,
    });
    setTimeout(() => {
      this.setState({
        formError: null,
      });
    }, 5000);
  };

  addTagType(obj, validated) {
    if (validated === true) {
      const data = {
        title: obj.title,
      };
      api.post('/v1/backgroundtags', data)
        .then(() => {
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
        })
        .catch((error) => {
          this.errorAlert(error.response.data.error);
        });
    }
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.show) {
      return null;
    }

    const { validated } = this.state;
    const { update, ...other } = this.props;
    const title = 'Add New Tag';
    const defTitle = '';

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >

        <Form
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>

            {/* eslint-disable-next-line react/destructuring-assignment */}
            <Col md={12} className={this.state.formError === null ? 'd-none' : 'd-block'}>
              <Alert variant="danger">
                {/* eslint-disable-next-line react/destructuring-assignment */}
                {this.state.formError}
              </Alert>
            </Col>

            <Form.Row>
              <Form.Group as={Col} controlId="Title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title"
                  defaultValue={defTitle}
                  name="title"
                />
                <Form.Control.Feedback type="invalid">
                  Please choose Title.
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              variant="secondary"
              /* eslint-disable-next-line react/destructuring-assignment */
              onClick={() => this.props.onHide()}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
            >
              Save
            </Button>

          </Modal.Footer>
        </Form>

      </Modal>
    );
  }
}

AddTag.propTypes = {
  onHide: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
