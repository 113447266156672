import arrayMove from 'array-move';
import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import {
  Button, Col, Form, ListGroup, Row,
} from 'react-bootstrap';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

/* Data */
const SortableItemData = SortableElement(({ object, i, ...props }) => {
  const index = i;
  const dataValueMax = Number(props.limits.data_value_max.value);
  return (
    <ListGroup.Item as="li" className={cs('py-0 pl-3 pr-1 branchesList-li')}>
      <Row
        className={cs('py-1 justify-content-between', props.activeSteps === `steps-${props.stepIndex}-data-${index}` ? 'active-item' : null)}
      >

        <Form.Group as={Col} controlId={`dataKey${index}`} className={cs('mb-0')}>
          <Row>
            <Form.Label className={cs('text-right px-1')} column sm={2} xl={1}>Key</Form.Label>
            <Col className={cs('mb-0 px-1')}>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Key"
                value={object.key || ''}
                name={`steps[${props.stepIndex}][data][${index}][key]`}
                onFocus={() => {
                  props.activeStepsFunc(`steps-${props.stepIndex}-data-${index}`);
                }}
                onMouseDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  props.handleChangeData(e, index, 'key');
                }}
                className={object.key && object.key.length > Number(props.limits.data_key_max.value) ? 'text-limit' : null}
              />
              <Form.Text className="char-limit-info-box">
                {Number(props.limits.data_key_max.value) - (object.key ? object.key.length : 0)}
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group as={Col} controlId={`dataValue${index}`} className={cs('mb-0')}>
          <Row>
            <Form.Label className={cs('text-right px-1')} column sm={2} xl={1}>Value</Form.Label>
            <Col className={cs('mb-0 px-1')}>
              <Form.Control
                size="sm"
                type="text"
                placeholder="Value"
                value={object.value || ''}
                name={`steps[${props.stepIndex}][data][${index}][value]`}
                onFocus={() => {
                  props.activeStepsFunc(`steps-${props.stepIndex}-data-${index}`);
                }}
                onMouseDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  props.handleChangeData(e, index, 'value');
                }}
                className={object.value && object.value.length > dataValueMax ? 'text-limit' : null}
              />
              <Form.Text className="char-limit-info-box">
                {dataValueMax - (object.value ? object.value.length : 0)}
              </Form.Text>
            </Col>
          </Row>
        </Form.Group>

        <Col sm={2} xl={1} className="text-right">
          <Button
            className="ml-1 btnDeleteImgSm"
            size="sm"
            variant="secondary"
            onClick={() => {
              props.deleteData(index);
            }}
            onFocus={() => {
              props.activeStepsFunc(`steps-${props.stepIndex}-data-${index}`);
            }}
            onMouseDown={(e) => e.stopPropagation()}
          />
        </Col>

      </Row>
    </ListGroup.Item>
  );
});

const SortableListData = SortableContainer(({ items, currentItem, ...props }) => (
  <ListGroup as="ol" start="0" variant="flush" className="my-1 ml-4 mr-1 branchesList">
    {items.map((value, index) => (
      <SortableItemData
        disabled={props.disabledSortable}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        object={value}
        currentItem={currentItem}
        i={index}
        {...props}
      />
    ))}
  </ListGroup>
));

export class StepsFormData extends PureComponent {
  onSortDataEnd = ({ oldIndex, newIndex }) => {
    const {
      stepIndex, handleDataChange, activeStepsFunc, data,
    } = this.props;

    let dataCopy = data.slice();
    const stepClass = `steps-${stepIndex}-data-${newIndex}`;
    dataCopy = arrayMove(dataCopy, oldIndex, newIndex);
    handleDataChange(dataCopy);
    activeStepsFunc(stepClass);
  };

  handleChangeData = (e, index, name) => {
    const { handleDataChange, data } = this.props;

    const dataCopy = data.slice();
    dataCopy[index][name] = e.target.value;
    handleDataChange(dataCopy);
  };

  deleteData = (i) => {
    const { handleDataChange, data } = this.props;

    const dataCopy = data.slice();
    dataCopy.splice(i, 1);
    handleDataChange(dataCopy);
  };

  addData = () => {
    const { handleDataChange, data } = this.props;

    const newData = {
      key: '',
      value: '',
    };
    const dataCopy = data.slice();
    dataCopy.push(newData);
    handleDataChange(dataCopy);
  };

  /* End Data */

  render() {
    const {
      activeSteps,
      limits,
      activeStepsFunc,
      data,
      stepIndex,
      disabledSortable,
      showData,
    } = this.props;

    return (
      <div className={cs('tableSubItems pt-2', showData ? 'd-block' : 'd-none')}>
        <Row>
          <Col md={12}>
            <h5>
              Data
              <Button
                size="sm"
                variant="secondary"
                className="ml-3"
                onClick={() => {
                  this.addData();
                }}
                onMouseDown={(e) => e.stopPropagation()}
              >
                + Add Data
              </Button>
            </h5>
          </Col>
        </Row>
        <SortableListData
          items={data}
          currentItem={this}
          onSortEnd={this.onSortDataEnd}
          helperClass="sort-item"
          disabledSortable={disabledSortable}
          limits={limits}
          activeStepsFunc={activeStepsFunc}
          activeSteps={activeSteps}
          stepIndex={stepIndex}
          handleChangeData={this.handleChangeData}
          deleteData={this.deleteData}
        />
      </div>
    );
  }
}
