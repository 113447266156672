import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { DeleteTag } from './DeleteTag';
import './Tag.css';

export class TagTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = { modalDeleteShow: false };
  }

  render() {
    const { modalDeleteShow } = this.state;
    const { update, obj } = this.props;

    const modalDeleteClose = () => {
      this.setState({ modalDeleteShow: false });
      update();
    };

    return (
      <div className="tagItem">
        <span>
          {obj.title}
        </span>
        <Button
          onClick={() => this.setState({ modalDeleteShow: true })}
          variant="primary"
          size="sm"
          className="close"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </Button>
        {modalDeleteShow && (
        <DeleteTag
          show={modalDeleteShow}
          onHide={modalDeleteClose}
          obj={obj}
        />
        )}
      </div>
    );
  }
}
