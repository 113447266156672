import React, { useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import {
  MemoryArrayLink,
  MemoryCheckValueType,
  MemoryDTO,
  MemoryLinkValue,
} from '../../../../Book/MemoryBank/memoryBankTypes';
import { MemoryBankCheckValue } from '../MemorySlotCompareForm';

interface MemoryMultiSelectFormProps {
  memories: MemoryDTO[],
  name: string,
  stepIndex: number,
  value: MemoryArrayLink | undefined,
  onChangeValue: (
    values: MemoryBankCheckValue[],
    stepIndex: number,
  ) => void,
}

export function MemoryMultiSelectForm(props: MemoryMultiSelectFormProps) {
  const {
    memories, onChangeValue, stepIndex, name, value,
  } = props;

  const selectedMemoriesRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedMemoriesRef.current) {
      selectedMemoriesRef.current.value = JSON.stringify(value);
    }
  }, [value]);

  const selectedMemoriesIds = value?.variableId ?? [];

  const handleSelect = (selectedId: string) => {
    const newMemoriesIds = selectedMemoriesIds.includes(selectedId)
      ? selectedMemoriesIds.filter((id) => id !== selectedId)
      : [...selectedMemoriesIds, selectedId];

    const memoryLinkValue: MemoryLinkValue = {
      type: MemoryCheckValueType.VariableArray,
      variableId: newMemoriesIds.map((id) => String(id)),
    };
    onChangeValue([{
      name: 'value',
      value: memoryLinkValue,
    }], stepIndex);
  };

  const isInvalid = selectedMemoriesIds.length < 2;

  return (
    <Form.Group className="position-relative">
      <Dropdown>
        <Dropdown.Toggle size="sm" variant="light" className="w-100">
          {selectedMemoriesIds.length === 0 ? 'Select memories' : `Selected: ${selectedMemoriesIds.length}`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {memories.map((memory) => (
            <div
              key={memory.id}
              className="m-0 p-0 text-truncate"
            >
              <Form.Check
                custom
                type="checkbox"
                id={`dropdown-check-${memory.id}`}
                label={memory.name}
                onChange={() => handleSelect(memory.id.toString())}
                checked={selectedMemoriesIds.includes(memory.id.toString())}
                className="mx-2"
              />
            </div>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {/* Needed for form validation */}
      <Form.Control
        ref={selectedMemoriesRef}
        type="hidden"
        name={name}
        isInvalid={isInvalid}
      />
      <Form.Control.Feedback type="invalid" tooltip>
        At least 2 memories
      </Form.Control.Feedback>
    </Form.Group>
  );
}
