import React from 'react';
import { Button } from 'react-bootstrap';

export function Page01Step01(props) {
  const { update } = props;
  return (
    <div className="WizardWrapper">
      <div className="WizardWelcomeBox">
        <h1>Welcome to Dorian</h1>
        <h6>We will help you create your first story.</h6>
        <div className="pt-4">
          <Button
            className="m-1"
            variant="primary"
            onClick={() => update(2)}
          >
            Continue
          </Button>
          <Button
            className="m-1"
            variant="link"
            onClick={() => update(0)}
          >
            Skip
          </Button>
        </div>
      </div>
    </div>
  );
}
