import { useEffect, useState } from 'react';
import { useApiService } from '../../../../contexts/ApiServiceContext/ApiServiceContext';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { Character } from '../../../../dorian-shared/types/character/Character';
import { logger } from '../../../../services/loggerService/loggerService';
import { showToast } from '../../../ui/utils';

export function useImportTextCharacters(bookId: number) {
  const apiService = useApiService();
  const [
    requestStatus,
    {
      setToError,
      setToLoading,
      setToSuccess,
    },
  ] = useAsyncOperationState();

  const [characters, setCharacters] = useState<Character[] | null>(null);
  useEffect(() => {
    setToLoading();

    apiService.fetchCharactersByBookId(bookId)
      .then((fetchedCharacters) => {
        setCharacters(fetchedCharacters);
        setToSuccess();
      })
      .catch((error) => {
        logger.error('[ImportTextModal] Error in fetching characters', error);
        showToast({ textMessage: 'Error in fetching characters' });
        setToError('Error in fetching characters');
      });
  }, [apiService, bookId, setToError, setToLoading, setToSuccess]);
  return {
    characters, requestStatus,
  };
}
