import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useAchievementsContext } from '../../../../../contexts/AchievementsContext/AchievementsContext';
import { BranchStep } from '../../../../../dorian-shared/types/branch/BranchStep';

type StepsTypeAchievementProps = {
  stepIndex: number,
  step: BranchStep,
  handleChangeSteps: (value: number, stepIndex: number, key: string) => void,
  isDisabled?: boolean,
}

export function StepsTypeAchieve(props: StepsTypeAchievementProps) {
  const {
    stepIndex, handleChangeSteps, step, isDisabled,
  } = props;

  const { achievements } = useAchievementsContext();

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Col>
        <Row>
          <Col md="auto">
            <Form.Label column>
              That
            </Form.Label>
          </Col>
          <Col md={3}>
            <Form.Control
              disabled={isDisabled}
              size="sm"
              as="select"
              name={`steps[${stepIndex}][achievementId]`}
              value={step.achievementId ?? ''}
              onChange={(event) => handleChangeSteps(Number(event.target.value), stepIndex, 'achievementId')}
            >
              <option value="" disabled>[Select]</option>
              {achievements.map((achievement) => (
                <option key={achievement.id} value={achievement.id}>
                  {achievement.name}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </Col>
    </>
  );
}
