import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ClientVariable } from '../ClientVariable/ClientVariable';
import classNamesColumnsWidth from '../ClientVariablesColumnsWidth.module.scss';
import classNames from './ClientVariables.module.scss';

export function ClientVariables({
  clientVariables,
  onSave,
  onDelete,
}) {
  return (
    <div>
      <Row className={classNames.header}>
        <Col sm={12} md className={classNamesColumnsWidth.name}>
          Name variable
        </Col>
        <Col sm={12} md className={classNamesColumnsWidth.type}>
          Type
        </Col>
        <Col sm={12} md className={classNamesColumnsWidth.experiment}>
          Experiment name
        </Col>
        <Col className={classNamesColumnsWidth.path}>
          Path
        </Col>
        <Col className={classNamesColumnsWidth.dataType}>
          Data type
        </Col>
        <Col className={classNamesColumnsWidth.value}>
          Value
        </Col>
        <Col sm={12} md className={classNamesColumnsWidth.actions}>
          Actions
        </Col>
      </Row>
      {clientVariables.map(
        (variable, index) => (
          <ClientVariable
            key={variable.name}
            initialClientVariable={variable}
            onSave={(clientVariableToSave) => onSave(clientVariableToSave, index)}
            onDelete={(clientVariableToDeleteId) => onDelete(clientVariableToDeleteId, index)}
          />
        ),
      )}
    </div>
  );
}
