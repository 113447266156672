import React, { Component } from 'react';
import {
  Alert, Button, Col, Image, Modal, Row, Spinner,
} from 'react-bootstrap';
import imgNoImage from '../../../assets/images/no_image.svg';
import { api } from '../../api';

export class DetailsPrompt extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      details: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  // eslint-disable-next-line react/sort-comp
  loadData() {
    this.setState({
      loading: true,
    });

    const { obj } = this.props;

    api.get(`/v1/bookprompts/${obj.id}/details`)
      .then((res) => {
        this.setState({
          details: res.data.details,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  listUsedByBooks = () => {
    const { details } = this.state;

    const books = details.usedBy ? details.usedBy : [];
    return (
      books.length > 0
        ? books.map((obj, i) => (
          <Alert
            /* eslint-disable-next-line react/no-array-index-key */
            key={i}
            variant="secondary"
            className="p-0 my-1"
          >
            <Alert.Link
              href={`/book/${obj.id}`}
              className="px-3 py-1 d-block"
            >
              {obj.title}
            </Alert.Link>
          </Alert>
        ))
        : null
    );
  };

  render() {
    const {
      show,
      obj: obj1,
      editCover: editCover1,
      onHide,
    } = this.props;

    if (!show) {
      return null;
    }
    const title = `Story Prompt ${obj1.title} Details`;

    const {
      obj, update, editCover, ...other
    } = this.props;
    const { loading } = this.state;

    const imgUrl = obj.image && obj.image.imageUrl ? obj.image.imageUrl : null;

    return (
      <Modal
        {...other}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading
            ? (
              <Row>
                <Col className="text-center imgPromptCover-col">

                  <div
                    className="imgAvatar-wrap"
                  >
                    <Image
                      onClick={() => {
                        this.setState({
                          // eslint-disable-next-line react/no-unused-state
                          uploadForm: true,
                        });
                      }}
                      className="imgPromptCover"
                      src={imgUrl || imgNoImage}
                      fluid
                    />
                  </div>
                </Col>
                <Col>
                  <h3>Used By:</h3>
                  <div className="pt-2 listUsedByBooksBox">
                    {this.listUsedByBooks()}
                  </div>
                </Col>
              </Row>
            )
            : (
              <div className="text-center">
                <Spinner
                  variant="primary"
                  animation="border"
                  className="justify-content-center"
                />
              </div>
            )}
        </Modal.Body>

        <Modal.Footer>
          <Row style={{ width: '100%' }}>
            <Col className="text-left">
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                onClick={() => editCover1()}
              >
                Edit Cover
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                size="sm"
                type="reset"
                variant="secondary"
                onClick={() => onHide()}
              >
                Сlose
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}
