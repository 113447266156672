import classNames from 'classnames/bind';
import React, { PureComponent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { StepTypes } from '../../../../../dorian-shared/types/branch/BranchStep';
import styles from './Steps.scss';

const cs = classNames.bind(styles);

export class StepsTypeFieldsText extends PureComponent {
  constructor(...args) {
    super(...args);
    this.state = {
      oldText: '',
    };
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { stepClass, stepClassActive } = this.props;

    if (stepClassActive === stepClass && prevProps.stepClassActive !== stepClassActive) {
      if (this.textInput && this.textInput.current) {
        this.textInput.current.focus();
      }
    }
  }

  stringDiffIndex = (oldText, newText) => {
    let s = 0;
    while (s < oldText.length && s < newText.length && oldText[s] === newText[s]) {
      s += 1;
    }
    return s;
  };

  render() {
    const {
      stepType,
      stepText,
      SelectStepsCopy,
      activeStepsFunc,
      stepClass,
      handleChangeSteps,
      stepClassActive,
      stepIndex,
      addStep,
      displayControl,
      stepTextMax,
    } = this.props;
    const { oldText } = this.state;

    if (displayControl !== true) {
      return null;
    }

    return (
      <Form.Group
        as={Col}
        controlId={`stepText-${stepIndex}`}
        className={cs('mb-0 px-1', 'd-block')}
      >
        <Form.Control
          ref={this.textInput}
          autoFocus={(!SelectStepsCopy && stepClassActive === stepClass)}
          size="sm"
          type="text"
          placeholder="Text"
          name={`steps[${stepIndex}][text]`}
          value={stepText || ''}
          onFocus={() => {
            activeStepsFunc(stepClass);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.target.blur();
              ev.preventDefault();
              if (stepType !== StepTypes.Choice) {
                addStep(stepIndex);
              }
            }
          }}
          onKeyDown={(ev) => {
            if (ev.key === '-') {
              const text = ev.target.value;
              this.setState({
                oldText: text,
              });
            }
          }}
          onKeyUp={(ev) => {
            if (ev.key === '-') {
              let text = ev.target.value;

              const idx = this.stringDiffIndex(oldText || '', text || '');
              if (text[idx - 1] === '-') {
                text = `${text.substring(0, idx - 1)}—${text.substring(idx + 1, text.length)}`;
                // eslint-disable-next-line no-param-reassign
                ev.target.value = text;
                handleChangeSteps(ev.target.value, stepIndex, 'text');
                ev.target.setSelectionRange(idx, idx);
              }
            }
          }}
          onChange={(e) => handleChangeSteps(e.target.value, stepIndex, 'text')}
          className={stepText && stepText.length > Number(stepTextMax) ? 'text-limit' : null}
        />
        <Form.Text className="char-limit-info-box">
          {Number(stepTextMax) - (stepText ? stepText.length : 0)}
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          Please add Step Text.
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
}
