import React, { Component } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { api } from '../../api';
import { ConfirmOriginalModal } from '../PageWrapper/ConfirmOriginalModal';

export class BoxNotifications extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      acceptedOriginalShow: false,
      acceptedOriginal: false,
      loading: false,
      show: true,
    };
  }

  loadAccept(val) {
    const { obj } = this.props;
    const { acceptedOriginalShow, acceptedOriginal } = this.state;

    if (
      obj.original
      && !acceptedOriginal
      && !acceptedOriginalShow
    ) {
      this.setState({
        acceptedOriginalShow: true,
      });
    } else {
      api.post('v1/books/coauthoring/control', { response: [{ bookId: obj.bookId, accepted: val }] })
        .then(() => {
          this.setState({
            loading: false,
            show: false,
          });
        })
        .catch(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  render() {
    const {
      show, loading, acceptedOriginal, acceptedOriginalShow,
    } = this.state;
    const { obj } = this.props;
    return (
      <>
        <Alert
          show={show}
          variant="info"
          className="my-1"
          onClose={() => {
            this.setState({
              show: false,
            });
          }}
          dismissible
        >
          <p>
            You have been added as a co-author to the story
            <Alert.Link
              href={`/book/${obj.bookId}`}
            >
              {obj.bookTitle}
            </Alert.Link>
          </p>
          <div className="d-flex justify-content-end">
            <Button
              disabled={loading}
              className="mx-2"
              onClick={() => {
                this.loadAccept(false);
              }}
              size="sm"
              variant="primary"
            >
              Reject
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                this.loadAccept(true);
              }}
              size="sm"
              variant="success"
            >
              Accept
            </Button>
          </div>
        </Alert>

        {obj && obj.original && acceptedOriginalShow && !acceptedOriginal && (
          <ConfirmOriginalModal
            close={() => {
              this.setState({
                acceptedOriginal: false,
                acceptedOriginalShow: false,
              });
            }}
            update={() => {
              this.setState({
                acceptedOriginal: true,
                acceptedOriginalShow: false,
              }, () => {
                this.loadAccept(true);
              });
            }}
          />
        )}
      </>
    );
  }
}
