import { FileError } from 'react-dropzone';

export enum UploadFilesModalStatus {
    Ready = 'Ready',
    Uploading = 'Uploading',
    Uploaded = 'Uploaded',
    Error = 'Error',
}

export interface SelectedFilesToUpload {
    file: File;
    status: UploadFilesModalStatus;
    progress: number;
    errors: FileError[] | undefined;
}

export type FileToUploadBase = {
    id: number;
    name: string;
    aliasName: string;
    expressionName?: string | null;
    imageUrl: string;
}

export type FileToUpload = SelectedFilesToUpload & FileToUploadBase;

export interface ManageListCallbacks {
    onDelete?: (fileToUpload: FileToUpload) => void;
}

export enum LibraryType {
    Character = 'character',
    Background = 'background',
}
