import serialize from 'form-serialize';
import React, { Component } from 'react';
import {
  Button, Card, Col, Form, Row, Spinner,
} from 'react-bootstrap';
import { api } from '../../api';
import './style.scss';

export class PropertyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      loading: false,
      data: {
        disabled: false,
        isConfigurable: false,
        isCustomizable: false,
        isRequired: false,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // eslint-disable-next-line react/destructuring-assignment
    if (prevProps.data !== this.props.data || this.state.edit !== prevState.edit) {
      this.setState({
        // eslint-disable-next-line react/destructuring-assignment
        data: JSON.parse(JSON.stringify(this.props.data)),
      });
    }
  }

  handleEdit = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    this.setState(
      (prevState) => ({ edit: !prevState.edit }),
      // eslint-disable-next-line react/destructuring-assignment
      () => { this.props.isEdit(this.state.edit); },
    );
  };

  handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    const obj = serialize(form, { hash: true, empty: true });
    obj.disabled = obj.disabled === 'on';
    obj.isConfigurable = obj.isConfigurable === 'on';
    obj.isCustomizable = obj.isCustomizable === 'on';
    obj.isRequired = obj.isRequired === 'on';
    this.updateData(obj);
    event.stopPropagation();
  }

  updateData = (value) => {
    this.setState({
      loading: true,
    });
    // eslint-disable-next-line react/destructuring-assignment
    api.put(`/v1/avatar/config/properties/${this.props.data.id}`, value)
      .then(() => {
        this.setState({
          loading: false,
        }, () => {
          this.handleEdit();
          // eslint-disable-next-line react/destructuring-assignment
          this.props.update();
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.checked;
    this.setState({
      data,
    });
  };

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.props.data) {
      return null;
    }

    const { data } = this.state;

    return (
      <Col sm={8}>
        <Form
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>
                {
                  // eslint-disable-next-line react/destructuring-assignment
                  this.state.edit
                    ? (
                      <Form.Group controlId="PropertyForm_Label">
                        <Form.Label>Label</Form.Label>
                        <Form.Control
                          type="text"
                          name="label"
                          // eslint-disable-next-line react/destructuring-assignment
                          disabled={!this.state.edit || this.state.loading}
                          // eslint-disable-next-line react/destructuring-assignment
                          defaultValue={this.props.data.label || ''}
                        />
                      </Form.Group>
                    )
                    : (
                      <>
                        Properties for
                        {' '}
                        { /* eslint-disable-next-line react/destructuring-assignment */}
                        {this.props.data.label && this.props.data.label}
                        {' '}
                        (
                        { /* eslint-disable-next-line react/destructuring-assignment */}
                        {this.props.data.ident}
                        )
                      </>
                    )
                }
              </Card.Title>
              <Row>
                <Col>
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    checked={data.isCustomizable}
                    onChange={this.handleChange}
                    id={`PropertyForm_${data.ident}_isCustomizable`}
                    type="switch"
                    label="isCustomizable"
                    name="isCustomizable"
                  />
                </Col>
                <Col>
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    checked={data.isConfigurable}
                    onChange={this.handleChange}
                    id={`PropertyForm_${data.ident}_isConfigurable`}
                    type="switch"
                    label="isConfigurable"
                    name="isConfigurable"
                  />
                </Col>
                <Col>
                  <Form.Check
                    // eslint-disable-next-line react/destructuring-assignment
                    disabled={!this.state.edit || this.state.loading}
                    checked={data.isRequired}
                    onChange={this.handleChange}
                    id={`PropertyForm_${data.ident}_isRequired`}
                    type="switch"
                    label="isRequired"
                    name="isRequired"
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              {
                // eslint-disable-next-line react/destructuring-assignment
                this.state.edit
                  ? (
                    <>
                      <Button
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled={this.state.loading}
                        size="sm ml-1"
                        onClick={this.handleEdit}
                        variant="secondary"
                      >
                        Cancel

                      </Button>
                      <Button
                        // eslint-disable-next-line react/destructuring-assignment
                        disabled={this.state.loading}
                        size="sm ml-1"
                        type="submit"
                      >
                        { /* eslint-disable-next-line react/destructuring-assignment */}
                        {this.state.loading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        Save
                      </Button>
                    </>
                  )
                  : (
                    <Button
                      // eslint-disable-next-line react/destructuring-assignment
                      disabled={this.props.disabled}
                      size="sm ml-1"
                      onClick={this.handleEdit}
                    >
                      Edit

                    </Button>
                  )
              }
            </Card.Footer>
          </Card>
        </Form>
      </Col>
    );
  }
}
