import moment from 'moment';
import React, { useRef, useState } from 'react';
import { api } from '../../../api';
import { DatePickerWithPrevNext } from '../../../ui/DatePickerWithPrevNext/DatePickerWithPrevNext';
import { PerformanceTabContentProps } from '../ChaptersPerformanceTabContent/ChaptersPerformanceTabContent';
import { PerformanceTabContent } from '../PerformanceTabContent/PerformanceTabContent';
import { FetchParams, PerformanceTabs } from '../performanceUtils';
import { HandlePageChange } from '../types/HandlePageChange';
import { heartsTopStoriesColumns, heartsTopStoriesHeaders } from './heartsTopStoriesColumns';

export function HeartsTopStoriesPerformanceTabContent(props: PerformanceTabContentProps) {
  const { pageSize } = props;
  const [filterByDate, setFilterByDate] = useState<Date>(new Date());
  const handlePageChangeRef = useRef<HandlePageChange>();
  const totalCountRef = useRef<number>(100);

  const fetchPerformanceData = async (
    params: FetchParams,
    signal: AbortSignal,
  ) => {
    const filterDate = moment(filterByDate).format('YYYY-MM-DD');
    const { limit: limitParam } = params;
    const limit = limitParam ?? totalCountRef.current;
    const fetchParams = { ...params, limit, date: filterDate };
    const response = await api.get(
      '/v2/analytics/performance/hearts/topStoriesByDate',
      { params: fetchParams, signal },
    );
    totalCountRef.current = response.data.count;
    return response.data;
  };

  const handleSelect = (date: Date) => {
    setFilterByDate(date);
    handlePageChangeRef.current?.(1);
  };

  return (
    <>
      <DatePickerWithPrevNext
        filterByDate={filterByDate}
        onSelect={handleSelect}
        maxDate={moment().startOf('day').toDate()}
      />
      <PerformanceTabContent
        tabTitle={PerformanceTabs.MonetizationTopStories}
        csvHeaders={heartsTopStoriesHeaders}
        columns={heartsTopStoriesColumns}
        getData={fetchPerformanceData}
        pageSize={pageSize}
        handlePageChangeCallback={(handlePageChange: HandlePageChange) => {
          handlePageChangeRef.current = handlePageChange;
        }}
      />
    </>
  );
}
