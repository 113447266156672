import React from 'react';
import { FILTER_TYPES, customFilter, textFilter } from 'react-bootstrap-table2-filter';
import { PositiveNumberFilter } from '../PositiveNumberFilter';
import { bookTitleFormatter, storyTitleFormatter } from '../tableFormatters';

export const monetizationColumns = [{
  dataField: 'bookTitle',
  text: 'Story',
  formatter: bookTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'title',
  text: 'Episode',
  formatter: storyTitleFormatter,
  sort: true,
  filter: textFilter(),
}, {
  dataField: 'free',
  text: 'Free Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'paid',
  text: 'All Paid Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'basic',
  text: 'Basic Paid Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'other',
  text: 'Romantic /Erotic',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'bonus',
  text: 'Bonus Choices',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}, {
  dataField: 'hearts',
  text: 'Hearts',
  sort: true,
  filter: customFilter({
    type: FILTER_TYPES.NUMBER,
  }),
  filterRenderer: (onFilter, column) => (
    <PositiveNumberFilter
      onFilter={onFilter}
      column={column}
    />
  ),
}];

export const monetizationHeaders = [
  { label: 'Story', key: 'bookTitle' },
  { label: 'Episode', key: 'title' },
  { label: 'Free Choices', key: 'free' },
  { label: 'Paid Choices', key: 'paid' },
  { label: 'Basic Choices', key: 'basic' },
  { label: 'Other Choices', key: 'other' },
  { label: 'Bonus Choices', key: 'bonus' },
  { label: 'Hearts', key: 'hearts' },
];
