import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import imgDelete from '../../../assets/images/delete.svg';
import { DeleteUser } from './DeleteUser';

export class UsersTableRow extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      modalDeleteUserShow: false,
    };
  }

  render() {
    const { update, obj } = this.props;
    const handleHide = () => {
      this.setState({
        modalDeleteUserShow: false,
      });
      update(obj);
    };

    const deleteUser = () => {
      const { modalDeleteUserShow } = this.state;
      const { obj: _obj } = this.props;

      if (modalDeleteUserShow !== true) {
        return null;
      }
      return (
        <DeleteUser
          show={modalDeleteUserShow}
          onHide={handleHide}
          obj={_obj}
        />
      );
    };
    return (
      <div style={{ textAlign: 'right' }}>
        <OverlayTrigger
          overlay={(
            <Tooltip id="tooltip-top">
              Delete User
            </Tooltip>
          )}
        >
          <Button
            onClick={() => this.setState({ modalDeleteUserShow: true })}
            variant="secondary"
            size="sm"
            className="mx-1"
          >
            <img src={imgDelete} className="btnImg" alt="Delete User" />
          </Button>
        </OverlayTrigger>
        {deleteUser()}
      </div>
    );
  }
}
