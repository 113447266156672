import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useAsyncOperationState } from '../../../../dorian-shared/hooks/useAsyncOperationState';
import { markupToHTML } from '../../../../helpers/markupToHTML';
import { api } from '../../../api';

interface CreditsProps {
    onBackClick: () => void;
}

export function Credits(props: CreditsProps) {
  const { onBackClick } = props;

  const [, {
    isLoading, isSuccess, isError, setToLoading, setToError, setToSuccess,
  }] = useAsyncOperationState();
  const [documentContent, setDocumentContent] = useState();

  useEffect(() => {
    const source = axios.CancelToken.source();

    setToLoading();
    api.get('/v1/documents/Credits', { cancelToken: source.token })
      .then((response) => {
        setDocumentContent(response.data.document.body);
        setToSuccess();
      }).catch((error) => {
        if (!axios.isCancel(error)) {
          setToError();
        }
      });
    return () => {
      source.cancel('Component unmounted');
    };
  }, [setToError, setToLoading, setToSuccess]);

  return (
    <div className="mainPage-content-wrapper slide-5">
      <div className="">
        <div className="mainPage-right-text-box-title">
          <Button
            className="closeBtn"
            variant="outline-primary"
            href="/#"
          >
            X
          </Button>
          <Button
            className="backBtn"
            variant="outline-primary"
            onClick={onBackClick}
          >
            {' '}
            {'< Back'}
            {' '}
          </Button>
          <h2 className="text-center py-3 modal-title">CREDITS</h2>
        </div>

        <div className="mainPage-right-text-box-content">
          {isLoading && (
          <h3 className="text-center">
            <Spinner
              variant="primary"
              animation="border"
            />
            Retrieving document...
          </h3>
          )}
          { isSuccess && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: markupToHTML(documentContent) }}
          />
          )}
          { isError && (
          <h3 className="text-center">
            Error retrieving document.
          </h3>
          )}
        </div>
      </div>
    </div>
  );
}
