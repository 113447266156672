import React, { Component } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { CirclePicker } from 'react-color';
import { doubleQuote } from '../../../helpers/html';
import { api } from '../../api';
import { ErrorAlert } from '../../ui/Errors/ErrorAlet';

export const colors = ['#ffc4e4', '#ffbccf', '#ffc6be', '#ffe7bc', '#e7dec0', '#bcccc1', '#bccee2', '#c6e7ff', '#d7c4ee', '#cdcdcd'];

export class ChangeColor extends Component {
  constructor(props, context) {
    super(props, context);
    const { obj } = this.props;

    this.state = {
      showLoading: false,
      errorMsg: null,
      color: obj.bgColor ? obj.bgColor : '',
    };
  }

  saveColorAction() {
    const { obj, storyId, onHide } = this.props;
    const { color } = this.state;

    this.setState({ showLoading: true });
    const value = {
      bgColor: color === '' ? null : color,
    };

    api.patch(`/v1/stories/${storyId}/branches/${obj.id}`, value)
      .then(() => {
        onHide();
      })
      .catch((error) => {
        this.errorAlert(error);
        this.setState({ showLoading: false });
      });
  }

  errorAlert = (error) => {
    this.setState({
      errorMsg: error,
    });
    setTimeout(() => {
      this.errorAlertClose();
    }, 3000);
  };

  errorAlertClose = () => {
    this.setState({
      errorMsg: null,
    });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };

  render() {
    const {
      obj, onHide, storyId, ...other
    } = this.props;
    const { errorMsg, color, showLoading } = this.state;

    return (
      <>
        <ErrorAlert error={errorMsg} close={this.errorAlertClose} />

        <Modal
          obj={obj}
          onHide={onHide}
          {...other}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Change Color:
              {' '}
              {doubleQuote}
              {obj.title}
              {doubleQuote}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CirclePicker
              width="100%"
              colors={colors}
              color={color}
              onChange={this.handleChange}
            />
          </Modal.Body>
          <Modal.Footer>

            <Button
              variant="secondary"
              className="mr-auto"
              onClick={() => {
                this.setState({ color: '' });
              }}
            >
              Clear
            </Button>

            <Button
              type="reset"
              variant="secondary"
              onClick={onHide}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="primary"
              onClick={() => this.saveColorAction()}
              disabled={showLoading}
            >
              {showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
